import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  paperCard: {
    padding: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'translateY(-10px)',
    },
  },
  cardcontent: {
    padding: theme.spacing(4),
  },
  timeText: {
    color: '#607d8b',
  },
  linkText: {
    color: localStorage.getItem('theme') === 'dark' ? '#c9c9df' : '#005eb2',
  },
  menu: {
    marginTop: 55,
  },
  sortByStatus: {
    display: 'inline-flex',
    [theme.breakpoints.down('xs')]: {
      gap: 27,
    },
  },
  moreIcon: {
    padding: 0,
    left: 140,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  papers: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 115,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  dateRange: {
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingRight: 10,
    },
  },
  avatarContact: {
    fontSize: '10px',
    backgroundColor: '#5F87AA',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  avatarContactChat: {
    fontSize: '5px',
    backgroundColor: '#5F87AA',
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
  schedule: {
    width: '18%',
    [theme.breakpoints.down('xs')]: {
      width: '18%',
    },
  },
  btnNextBack: {
    display: 'flex',
    marginLeft: '45%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '34%',
    },
  },
  text: {
    color: '#9e9e9e',
  },
  tabButton: {
    marginTop: 2,
    marginBottom: 5,
    marginLeft: 5,
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
      fontSize: 8,
      marginRight: 10,
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  timeTable: {
    padding: 20,
  },
  url: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  button: {
    marginRight: '20px',
    borderRadius: '50px',
    color: 'white',
  },
  backMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  mainDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '30px 0',
  },
  Button: {
    maxWidth: '15px',
    minWidth: '10px',
  },
  placeholderIcon: {
    fontSize: 70,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  Book: {
    display: 'flex',
    padding: 5,
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  tabs: {
    minWidth: 200,
    borderBottom: '2px solid #e8e8e8',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '20px',
      fontSize: 10,
    },
  },
  Calendar: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  placeholder: {
    marginTop: '100px',
    color: '#5F87AA',
    marginBottom: '100px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '100px',
      marginTop: '17px',
    },
  },
  chilDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '85%',
    marginBottom: '10px',
  },
  bookBtn: {
    minWidth: '40%',
    maxWidth: '40%',
  },
  appBar: {
    position: 'relative',
  },
  ExpansionPanelDetails: {
    display: 'block',
  },
  titles: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  contentImg: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    aspectRatio: 'auto',
    marginRight: '30px',
  },
  contentImgs: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    backgroundColor: 'white',
    border: '3px solid #005EB2',
    aspectRatio: 'auto',
    marginRight: '30px',
  },
  scheduleTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttons: {
    marginRight: 20,
    padding: 8,
  },
  buttonsR: {
    marginRight: 20,
    padding: 8,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  iosIcon: {
    marginLeft: 22,
    fontSize: 40,
    color: '#607d8b',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 5,
    },
  },
  SocialIcon: {
    margin: 5,
    padding: 20,
  },
  selectuser: {
    width: '150px',
  },
  hostingBox: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  hostingimg: {
    width: 100,
    marginBottom: 10,
    height: 100,
    borderRadius: '50%',
  },
  hostInfo: {
    [theme.breakpoints.up('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 0,
    },
  },
  hostedText: {
    textAlign: 'center',
  },
  hostname: {
    fontSize: 40,
  },
  componentHeading: {
    fontSize: '14px',
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  docTitle: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  phoneR: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  publichostingimg: {
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  publichostingbox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
  },
  publichostingname: {
    marginLeft: 15,
  },
  publicicons: {
    marginTop: 13,
    color: 'grey',
  },
  publiclink: {
    marginTop: 11,
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
  },
  coverImg: {
    width: '80%',
    height: 'auto',
    aspectRatio: 'auto',
    marginTop: 23,
    [theme.breakpoints.down('md')]: {
      height: '450px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '230px',
    },
  },
  locationImg: {
    height: '27px',
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    // marginLeft: '8%',
    // marginTop: '3%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
  },
  cal: (props) => ({
    width: '60%',
    margin: 'auto',
    height: 'auto',
    padding: 37,
    justifyContent: 'center',
    background: props.background,
    [theme.breakpoints.down('xs')]: {
      padding: 15,
      width: '100%',
      height: 'auto',
    },
    border: '1px solid lightgrey',
    boxShadow: 'none',
  }),
  styledpaper: {
    background: 'transparent',
  },
  container: {
    width: '99%',
    padding: '20px 50px 20px 100px',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0px 20px 20px',
    },
  },
  eventBg: {
    backgroundImage: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/ErrorPage.png',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subcontainer: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: '0px 10px 0px 10px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px',
    },
  },
  emailpaper: {
    backgroundColor: '#e8e8ee',
    color: '#000000',
    padding: '0px 0px 0px 0px',
    fontWeight: 500,
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e8e8ee',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  field: {
    width: '100%',
    fontFamily: 'open sans',
  },
  emailbtn: {
    fontSize: 12,
    color: '#fff',
    float: 'left',
    backgroundColor: '#005EB2',
  },
  erroricons: {
    color: 'red',
    marginTop: 13,
  },
  errorlink: {
    color: 'red',
    fontWeight: 'bold',
    marginTop: 11,
  },
  payment: {
    background: 'none',
  },
  errormsg: {
    marginLeft: 24,
    marginRight: 10,
    color: 'red',
  },
  successmsg: {
    marginLeft: 24,
    marginRight: 10,
    color: 'green',
  },
  meetingLink: {
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      wordBreak: 'break-all',
    },
  },
  iconWrapper: {
    marginTop: '2.5rem',
  },
  paper1: {
    padding: theme.spacing(3),
    maxWidth: 1000,
    maxHeight: 500,
  },
  eventName: {
    color: '#1e0a3c',
    fontSize: '3.25rem',
    lineHeight: '4rem',
    fontWeight: '800',
    marginBottom: '25px',
    wordBreak: ' break-word',
    textTransform: 'capitalize',
  },
  iconTheme: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  horizontalLine: {
    width: '100%',
    height: '1px',
    backgroundColor: '#000',
    margin: '20px 0',
  },
  btnSubmit: {
    backgroundColor: '#005eb2 !important',
    color: 'white !important',
    borderRadius: '5px !important',
    border: 'none !important',
    height: '30px !important',
  },
  btnClose: {
    backgroundColor: '#ff5c93 !important',
    color: 'white !important',
    borderRadius: '5px !important',
    border: 'none !important',
    height: '30px !important',
  },
}));
