import React from 'react';
import gql from 'graphql-tag';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import moment from 'moment';
import config from '../config';
import { Contactsbypaging } from '../Api Mutation/Contact';
import { NewsletterEmailByLocationId, DeleteNewsletterEmail } from '../Api Mutation/NewsLetterApi';
import { limitErrorCode, limitErrorMessage } from '../common/const';
import { showNotification } from '../components/Notification/ToastifyNotifications';
import { postDataRequest, dataRequest, handleUndefined } from '../common/commonfunctions';
import Message from '../pages/Messages/Message';

const CampaginContext = React.createContext();
const locationId = JSON.parse(localStorage?.getItem('location_Id'));

const customerLifeCycleStage = 6;
const CREATE_DOCUMENT = gql`
  mutation
  uploadcontactformdocument($document: Upload!, $locationid:ID!){
  uploadcontactformdocument(document:$document, locationid: $locationid )
  }`;
const rootReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_BUSINESS_DATA': {
      return {
        ...state,

        businessData: {
          ...state.businessData,
          data: payload,
        },
      };
    }
    case 'SET_COMMUNICATION_TYPE': {
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          reviewInfo: {
            ...state.createCampaignInfo.reviewInfo,
            selectedType: '',
          },
          communicationInfo: {
            ...state.createCampaignInfo.communicationInfo,
            selectedType: {
              type: payload.type,
              communicationtypeid: payload.id,
            },
          },
        },
      };
    }
    case 'GET_COMMUNICATION_TYPE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          communicationInfo: {
            ...state.createCampaignInfo.communicationInfo,
            list: payload,
          },
        },
      };
    case 'COMMUNICATION_LOADING':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          communicationInfo: {
            ...state.createCampaignInfo.communicationInfo,
            isLoading: payload,
          },
        },
      };
    case 'REVIEW_LOADING':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          reviewInfo: {
            ...state.createCampaignInfo.reviewInfo,
            isLoading: payload,
          },
        },
      };
    case 'GET_REVIEW_TYPE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          reviewInfo: {
            ...state.createCampaignInfo.reviewInfo,
            list: payload,
          },
        },
      };
    case 'SET_REVIEW_TYPE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          reviewInfo: {
            ...state.createCampaignInfo.reviewInfo,
            selectedType: {
              type: payload.type,
              reviewtypeid: payload.id,
            },
          },
        },
      };
    case 'CUSTOMER_LOADING': {
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          customersInfo: {
            ...state.createCampaignInfo.customersInfo,
            isLoading: payload,
          },
        },
      };
    }
    case 'GET_CUSTOMER_LIST': {
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          customersInfo: {
            ...state.createCampaignInfo.customersInfo,
            contact: payload,
          },
        },
      };
    }
    case 'SELECTED_CUSTOMERS': {
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          customersInfo: {
            ...state.createCampaignInfo.customersInfo,
            selectedCustomers: payload,
          },
        },
      };
    }
    case 'SELECTED_CUSTOMERSEDIT': {
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          customersInfo: {
            ...state.createCampaignInfo.customersInfo,
            selectedCustomersEdit: payload,
          },
        },
      };
    }
    case 'CAMAING_SETTINGS_LOADING': {
      if (payload.type === 'submit') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            isSubmitting: payload.value,
          },
        };
      }
      return {
        ...state,
        campaginSettings: {
          ...state.campaginSettings,
          isLoading: payload.value,
        },
      };
    }
    case 'SET_CAMPAGIN_TITLE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          campaginTitle: payload,
        },
      };
    case 'SET_EMAILCAMPAGIN_TITLE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          emailCampaignTitle: payload,
        },
      };
    case 'SET_TEXTCAMPAGIN_TITLE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          textCampaginTitle: payload,
        },
      };
    case 'SET_CONTACTMARKETING_TITLE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          contactMarketingTitle: payload,
        },
      };
    case 'SET_TEXTBODYCAMPAGIN':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          textBodyCampagin: payload,
        },
      };
    case 'SET_TEXTBODYLINKCAMPAGIN':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          textBodyLinkCampagin: payload,
        },
      };
    case 'SET_TEXTBODYFILECAMPAGIN':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          textBodyFileCampagin: payload,
        },
      };
    case 'SET_NPS_SUBJECT': {
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          NPSSubject: payload,
        },
      };
    }
    case 'SET_NEWSLETTER_SUBJECT': {
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          NewsLetterSubject: payload,
        },
      };
    }
    case 'CAMAING_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'GET_CAMPAING_LIST':
      return {
        ...state,
        campaginList: {
          ...state.campaginList,
          totalCount: payload?.count,
          list: payload?.rows,
        },
      };
    case 'GET_EMAILCAMPAING_LIST':
      return {
        ...state,
        emailCampaginList: {
          ...state.emailCampaginList,
          totalCount: payload?.count,
          list: payload?.rows,
        },
      };
    case 'GET_CONTECTMARKETING_LIST':
      return {
        ...state,
        contectMarketingList: {
          ...state.contectMarketingList,
          totalCount: payload?.count,
          list: payload?.rows,
        },
      };
    case 'GET_NEWSLETTER_LIST':
      return {
        ...state,
        newsLetterList: {
          ...state.newsLetterList,
          totalCount: payload?.count,
          list: payload?.rows,
        },
      };
    case 'GET_TEXTEMAILCAMPAING_LIST':
      return {
        ...state,
        textemailCampaginList: {
          ...state.textemailCampaginList,
          totalCount: payload?.count,
          list: payload?.rows,
        },
      };
    case 'SET_POSITIVE_REVIEW_MESSAGE':
      return {
        ...state,
        positiveComment: {
          ...state.positiveComment,
          reviewMessage: payload,
        },
      };
    case 'SET_POSITIVE_REVIEW_TITLE':
      return {
        ...state,
        positiveComment: {
          ...state.positiveComment,
          reviewTitle: payload,
        },
      };
    case 'SHOW_FIRSTTIME_POPUP':
      return {
        ...state,
        emailMarketingFirstPopup: action.payload,
      };
    case 'SHOW_REVIEWCAMPAIGNFIRSTTIME_POPUP':
      return {
        ...state,
        ReviewCampaignFirstPopup: action.payload,
      };
    case 'SET_TEMPLATE_SUBJECT': {
      if (payload.type === 'feedback-request') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            feedbackrequest: {
              ...state.campaginSettings.feedbackrequest,
              subject: payload.value,
            },
          },
        };
      } if (payload.type === 'feedback-first-reminders') {
        const { reminders } = state.campaginSettings;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 1,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 1,
                subject: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 1) {
                return {
                  ...data,
                  reminderSequence: 1,
                  subject: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 1,
              subject: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reminders: newreminders,
          },
        };
      } if (payload.type === 'feedback-second-reminders') {
        const { reminders } = state.campaginSettings;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 2,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 2,
                subject: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 2) {
                return {
                  ...data,
                  reminderSequence: 2,
                  subject: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 2,
              subject: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reminders: newreminders,
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_RATING_TEMPLATE_SUBJECT': {
      if (payload.type === 'feedback-request') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewrequestemail: {
              ...state.campaginSettings.reviewrequestemail,
              subject: payload.value,
            },
          },
        };
      } if (payload.type === 'feedback-first-reminders') {
        const reminders = state.campaginSettings.reviewreminders;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 1,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 1,
                subject: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 1) {
                return {
                  ...data,
                  reminderSequence: 1,
                  subject: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 1,
              subject: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewreminders: newreminders,
          },
        };
      } if (payload.type === 'feedback-second-reminders') {
        const reminders = state.campaginSettings.reviewreminders;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 2,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 2,
                subject: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 2) {
                return {
                  ...data,
                  reminderSequence: 2,
                  subject: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 2,
              subject: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewreminders: newreminders,
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_TEMPLATE_BODY': {
      if (payload.type === 'feedback-request') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            feedbackrequest: {
              ...state.campaginSettings.feedbackrequest,
              body: payload.value,
            },
          },
        };
      } if (payload.type === 'feedback-first-reminders') {
        const { reminders } = state.campaginSettings;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 1,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 1,
                body: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 1) {
                return {
                  ...data,
                  reminderSequence: 1,
                  body: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 1,
              body: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reminders: newreminders,
          },
        };
      } if (payload.type === 'feedback-second-reminders') {
        const { reminders } = state.campaginSettings;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 2,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 2,
                body: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 2) {
                return {
                  ...data,
                  reminderSequence: 2,
                  body: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 2,
              body: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reminders: newreminders,
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_RATING_TEMPLATE_BODY': {
      if (payload.type === 'feedback-request') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewrequestemail: {
              ...state.campaginSettings.reviewrequestemail,
              body: payload.value,
            },
          },
        };
      } if (payload.type === 'feedback-first-reminders') {
        const reminders = state.campaginSettings.reviewreminders;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 1,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 1,
                body: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 1) {
                return {
                  ...data,
                  reminderSequence: 1,
                  body: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 1,
              body: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewreminders: newreminders,
          },
        };
      } if (payload.type === 'feedback-second-reminders') {
        const reminders = state.campaginSettings.reviewreminders;
        let newreminders;
        if (reminders) {
          const findIndex = reminders.findIndex(
            (data) => data.reminderSequence === 2,
          );
          if (findIndex === -1) {
            newreminders = [
              ...reminders,
              {
                reminderSequence: 2,
                body: payload.value,
              },
            ];
          } else {
            newreminders = reminders.map((data) => {
              if (data.reminderSequence === 2) {
                return {
                  ...data,
                  reminderSequence: 2,
                  body: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newreminders = [
            {
              reminderSequence: 2,
              body: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewreminders: newreminders,
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_NAGATIVE_APOLOGY': {
      if (payload.type === 'nagative_apology_switch') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            apologyemail: {
              ...state.campaginSettings.apologyemail,
              active: payload.value,
            },
          },
        };
      } if (payload.type === 'nagative_apology_body') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            apologyemail: {
              ...state.campaginSettings.apologyemail,
              body: payload.value,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_RATING_NAGATIVE_APOLOGY': {
      if (payload.type === 'nagative_apology_switch') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewapologyemail: {
              ...state.campaginSettings.reviewapologyemail,
              active: payload.value,
            },
          },
        };
      } if (payload.type === 'nagative_apology_body') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewapologyemail: {
              ...state.campaginSettings.reviewapologyemail,
              body: payload.value,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_RATING_REQUEST_SETTINGS_SWITCH': {
      if (payload.type === 'feedback-first-reminders') {
        const reminders = state?.campaginSettings?.reviewreminders;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
              reminderSequence: 1,
              active: payload.value,
            },
            {
              ...reminders[1],
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reviewreminders: newreminders,
            },
          };
        }
      } else if (payload.type === 'feedback-second-reminders') {
        const reminders = state.campaginSettings.reviewreminders;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
            },
            {
              ...reminders[1],
              reminderSequence: 2,
              active: payload.value,
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reviewreminders: newreminders,
            },
          };
        }
      }
      return {
        ...state,
      };
    }
    case 'SET_FEEDBACK_REQUEST_SETTINGS_SWITCH': {
      if (payload.type === 'feedback-first-reminders') {
        const reminders = state?.campaginSettings?.reminders;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
              reminderSequence: 1,
              active: payload.value,
            },
            {
              ...reminders[1],
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reminders: newreminders,
            },
          };
        }
      } else if (payload.type === 'feedback-second-reminders') {
        const { reminders } = state.campaginSettings;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
            },
            {
              ...reminders[1],
              reminderSequence: 2,
              active: payload.value,
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reminders: newreminders,
            },
          };
        }
      }
      return {
        ...state,
      };
    }
    case 'SET_REMINDERS_TIMING': {
      if (payload.type === 'feedback-first-reminders') {
        const { reminders } = state.campaginSettings;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
              daysAfter: payload.value,
              reminderSequence: 1,
            },
            {
              ...reminders[1],
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reminders: newreminders,
            },
          };
        }
      } else if (payload.type === 'feedback-second-reminders') {
        const { reminders } = state.campaginSettings;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
            },
            {
              ...reminders[1],
              daysAfter: payload.value,
              reminderSequence: 2,
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reminders: newreminders,
            },
          };
        }
      } else if (payload.type === 'positive-first-reminders') {
        const { positiveemails } = state.campaginSettings;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 1,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 1,
                daysAfter: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 1) {
                return {
                  ...data,
                  daysAfter: payload.value,
                  emailSequence: 1,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            { daysAfter: payload.value, emailSequence: 1 },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positiveemails: newpositiveemails,
          },
        };
      } else if (payload.type === 'positive-second-reminders') {
        const { positiveemails } = state.campaginSettings;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 2,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 2,
                daysAfter: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 2) {
                return {
                  ...data,
                  emailSequence: 2,
                  daysAfter: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            { daysAfter: payload.value, emailSequence: 2 },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positiveemails: newpositiveemails,
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_RATING_REMINDERS_TIMING': {
      if (payload.type === 'feedback-first-reminders') {
        const reminders = state.campaginSettings.reviewreminders;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
              daysAfter: payload.value,
              reminderSequence: 1,
            },
            {
              ...reminders[1],
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reviewreminders: newreminders,
            },
          };
        }
      } else if (payload.type === 'feedback-second-reminders') {
        const reminders = state.campaginSettings.reviewreminders;
        if (reminders) {
          const newreminders = [
            {
              ...reminders[0],
            },
            {
              ...reminders[1],
              daysAfter: payload.value,
              reminderSequence: 2,
            },
          ];
          return {
            ...state,
            campaginSettings: {
              ...state.campaginSettings,
              reviewreminders: newreminders,
            },
          };
        }
      } else if (payload.type === 'positive-first-reminders') {
        const positiveemails = state.campaginSettings.reviewpositiveemails;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 1,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 1,
                daysAfter: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 1) {
                return {
                  ...data,
                  daysAfter: payload.value,
                  emailSequence: 1,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            { daysAfter: payload.value, emailSequence: 1 },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositiveemails: newpositiveemails,
          },
        };
      } else if (payload.type === 'positive-second-reminders') {
        const positiveemails = state.campaginSettings.reviewpositiveemails;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 2,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 2,
                daysAfter: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 2) {
                return {
                  ...data,
                  emailSequence: 2,
                  daysAfter: payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            { daysAfter: payload.value, emailSequence: 2 },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositiveemails: newpositiveemails,
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_POSITIVE_SETTINGS_SWITCH':
      if (payload.type === 'landing-show-online-review') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positivelanding: {
              ...state.campaginSettings.positivelanding,
              showOnlineReviewLinks: payload.value,
            },
          },
        };
      } if (payload.type === 'first-email-show-online-review') {
        const { positiveemails } = state.campaginSettings;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 1,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 1,
                emailReminderToLeaveOnlineReview: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 1) {
                return {
                  ...data,
                  emailSequence: 1,
                  emailReminderToLeaveOnlineReview:
                    payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            {
              emailSequence: 1,
              emailReminderToLeaveOnlineReview: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positiveemails: newpositiveemails,
          },
        };
      } if (payload.type === 'second-email-show-online-review') {
        const { positiveemails } = state.campaginSettings;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 2,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 2,
                emailReminderToLeaveOnlineReview: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 2) {
                return {
                  ...data,
                  emailSequence: 2,
                  emailReminderToLeaveOnlineReview:
                    payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            {
              emailSequence: 2,
              emailReminderToLeaveOnlineReview: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positiveemails: newpositiveemails,
          },
        };
      } if (payload.type === 'first-email-active') {
        const { positiveemails } = state.campaginSettings;
        const findIndex = positiveemails
          ? positiveemails.findIndex((data) => data.emailSequence === 1)
          : -1;
        let newpositiveemails;
        if (findIndex === -1) {
          if (positiveemails) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 1,
                active: payload.value,
              },
            ];
          } else {
            newpositiveemails = [
              {
                emailSequence: 1,
                active: payload.value,
              },
            ];
          }
        } else {
          newpositiveemails = positiveemails.map((data) => {
            if (data.emailSequence === 1) {
              return {
                ...data,
                emailSequence: 1,
                active: payload.value,
              };
            }
            return data;
          });
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positiveemails: newpositiveemails,
          },
        };
      } if (payload.type === 'second-email-active') {
        const { positiveemails } = state.campaginSettings;
        const findIndex = positiveemails
          ? positiveemails.findIndex((data) => data.emailSequence === 2)
          : -1;
        let newpositiveemails;
        if (findIndex === -1) {
          if (positiveemails) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 2,
                active: payload.value,
              },
            ];
          } else {
            newpositiveemails = [
              {
                emailSequence: 2,
                active: payload.value,
              },
            ];
          }
        } else {
          newpositiveemails = positiveemails.map((data) => {
            if (data.emailSequence === 2) {
              return {
                ...data,
                active: payload.value,
              };
            }
            return data;
          });
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positiveemails: newpositiveemails,
          },
        };
      }
      return {
        ...state,
      };
    case 'SET_RATING_POSITIVE_SETTINGS_SWITCH':
      if (payload.type === 'landing-show-online-review') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositivelanding: {
              ...state.campaginSettings.reviewpositivelanding,
              showOnlineReviewLinks: payload.value,
            },
          },
        };
      } if (payload.type === 'first-email-show-online-review') {
        const positiveemails = state.campaginSettings.reviewpositiveemails;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 1,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 1,
                emailReminderToLeaveOnlineReview: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 1) {
                return {
                  ...data,
                  emailSequence: 1,
                  emailReminderToLeaveOnlineReview:
                    payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            {
              emailSequence: 1,
              emailReminderToLeaveOnlineReview: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositiveemails: newpositiveemails,
          },
        };
      } if (payload.type === 'second-email-show-online-review') {
        const positiveemails = state.campaginSettings.reviewpositiveemails;
        let newpositiveemails;
        if (positiveemails) {
          const findIndex = positiveemails.findIndex(
            (data) => data.emailSequence === 2,
          );
          if (findIndex === -1) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 2,
                emailReminderToLeaveOnlineReview: payload.value,
              },
            ];
          } else {
            newpositiveemails = positiveemails.map((data) => {
              if (data.emailSequence === 2) {
                return {
                  ...data,
                  emailSequence: 2,
                  emailReminderToLeaveOnlineReview:
                    payload.value,
                };
              }
              return data;
            });
          }
        } else {
          newpositiveemails = [
            {
              emailSequence: 2,
              emailReminderToLeaveOnlineReview: payload.value,
            },
          ];
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositiveemails: newpositiveemails,
          },
        };
      } if (payload.type === 'first-email-active') {
        const positiveemails = state.campaginSettings.reviewpositiveemails;
        const findIndex = positiveemails
          ? positiveemails.findIndex((data) => data.emailSequence === 1)
          : -1;
        let newpositiveemails;
        if (findIndex === -1) {
          if (positiveemails) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 1,
                active: payload.value,
              },
            ];
          } else {
            newpositiveemails = [
              {
                emailSequence: 1,
                active: payload.value,
              },
            ];
          }
        } else {
          newpositiveemails = positiveemails.map((data) => {
            if (data.emailSequence === 1) {
              return {
                ...data,
                emailSequence: 1,
                active: payload.value,
              };
            }
            return data;
          });
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositiveemails: newpositiveemails,
          },
        };
      } if (payload.type === 'second-email-active') {
        const positiveemails = state.campaginSettings.reviewpositiveemails;
        const findIndex = positiveemails
          ? positiveemails.findIndex((data) => data.emailSequence === 2)
          : -1;
        let newpositiveemails;
        if (findIndex === -1) {
          if (positiveemails) {
            newpositiveemails = [
              ...positiveemails,
              {
                emailSequence: 2,
                active: payload.value,
              },
            ];
          } else {
            newpositiveemails = [
              {
                emailSequence: 2,
                active: payload.value,
              },
            ];
          }
        } else {
          newpositiveemails = positiveemails.map((data) => {
            if (data.emailSequence === 2) {
              return {
                ...data,
                active: payload.value,
              };
            }
            return data;
          });
        }
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositiveemails: newpositiveemails,
          },
        };
      }
      return {
        ...state,
      };
    case 'SET_NEGATIVE_SETTINGS_SWITCH': {
      if (payload.type === 'landing-show-online-review') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            negativelanding: {
              ...state.campaginSettings.negativelanding,
              showOnlineReviewLinks: payload.value,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_RATING_NEGATIVE_SETTINGS_SWITCH': {
      if (payload.type === 'landing-show-online-review') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewnegativelanding: {
              ...state.campaginSettings.reviewnegativelanding,
              showOnlineReviewLinks: payload.value,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_FEEDBACK_REQUEST_SETTINGS':
      return {
        ...state,
      };
    case 'GET_CAMAING_SETTINGS':
      return {
        ...state,
        campaginSettings: {
          ...state.campaginSettings,
          ...payload,
        },
      };
    case 'SET_THANK_YOU_MESSAGE':
      if (payload.type === 'positive') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            thanksemail: {
              body: payload.value,
            },
          },
        };
      } if (
        payload.type === 'positive-review-submission-confirmation'
      ) {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            positivesubmissionconfirmation: {
              body: payload.value,
            },
          },
        };
      } if (
        payload.type === 'negative-review-submission-confirmation'
      ) {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            negativesubmissionconfirmation: {
              body: payload.value,
            },
          },
        };
      }
      return {
        ...state,
      };
    case 'SET_RATING_THANK_YOU_MESSAGE':
      if (payload.type === 'positive') {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewthanksemail: {
              body: payload.value,
            },
          },
        };
      } if (
        payload.type === 'positive-review-submission-confirmation'
      ) {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewpositivesubmissionconfirmation: {
              body: payload.value,
            },
          },
        };
      } if (
        payload.type === 'negative-review-submission-confirmation'
      ) {
        return {
          ...state,
          campaginSettings: {
            ...state.campaginSettings,
            reviewnegativesubmissionconfirmation: {
              body: payload.value,
            },
          },
        };
      }
      return {
        ...state,
      };
    case 'SET_NPS_BODY':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          NPSBody: payload,
        },
      };
    case 'SET_EMAILCAMPAGIN_TEMPLATE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          emailtemplate: payload,
        },
      };
    case 'SET_NEWSLETTER_TEMPLATE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          emailhtml: payload,
        },
      };
    case 'SET_CONTECTCAMPAGIN_TEMPLATE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          contenttemplate: payload,
        },
      };
    case 'SET_EMAILCAMPAGIN_TEMPLATEDESIGN':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          templatedesign: payload,
        },
      };
    case 'SET_EMAILCAMPAGIN_FROMEMAIL':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          fromemail: payload,
        },
      };
    case 'SET_EMAILCAMPAGIN_FROMNAME':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          fromname: payload,
        },
      };
    case 'SET_NPS_EMAIL_TEMPLATE':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          NPSSubject: payload ? payload.subject : '',
          NPSBody: payload ? payload.body : '',
        },
      };
    case 'SET_BRAND_DETAILS':
      return {
        ...state,
        brandInfo: payload,
      };
    case 'REMOVE_CAMPAGIN':
      return {
        ...state,
        campaginList: {
          ...state.campaginList,
          list: state.campaginList.list.filter(
            (campagin) => campagin.campaignid !== payload,
          ),
        },
      };
    case 'REMOVE_EMAILCAMPAGIN':
      return {
        ...state,
        emailCampaginList: {
          ...state.emailCampaginList,
          list: state.emailCampaginList.list.filter(
            (campagin) => campagin.emailcampaignid !== payload,
          ),
        },
      };
    case 'REMOVE_CONTECTMARKETING':
      return {
        ...state,
        contectMarketingList: {
          ...state.contectMarketingList,
          list: state.contectMarketingList.list.filter(
            (campagin) => campagin.contentmarketingnid !== payload,
          ),
        },
      };
    case 'REMOVE_TEXTEMAILCAMPAGIN':
      return {
        ...state,
        textemailCampaginList: {
          ...state.textemailCampaginList,
          list: state.textemailCampaginList.list.filter(
            (campagin) => campagin.smscampaignid !== payload,
          ),
        },
      };
    case 'REMOVE_NEWSLETTER':
      return {
        ...state,
        newsLetterList: {
          ...state.newsLetterList,
          list: state.newsLetterList.list.filter(
            (campagin) => campagin.newsletteremailid !== payload,
          ),
        },
      };
    case 'GET_CAMAING_BY_ID': {
      let checkedSelected = [];
      let selectedData = [];
      if (payload?.customers) {
        selectedData = JSON.parse(payload?.customers);
      }
      if (state.createCampaignInfo.customersInfo.list || selectedData) {
        checkedSelected = state.createCampaignInfo.customersInfo.list
          && state?.createCampaignInfo?.customersInfo?.list?.rows?.map(
            (selection) => {
              const findIndex = selectedData
                && selectedData.findIndex(
                  (data) => data.customerid === selection.entityid,
                );
              if (findIndex !== -1) {
                return {
                  ...selection,
                  tableData: { checked: true },
                };
              }
              return selection;
            },
          );
      }
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          campaginTitle: payload?.name,
          emailCampaignTitle: payload?.name,
          textCampaginTitle: payload?.name,
          contactMarketingTitle: payload?.name,
          textBodyCampagin: payload?.smstext,
          textBodyLinkCampagin: payload?.link,
          textBodyFileCampagin: payload?.file,
          NewsLetterSubject: payload?.subject,
          NPSSubject:
            payload?.subject
            || state.createCampaignInfo.NPSSubject.split().join('\\n').split('"').join('\\"'),
          NPSBody:
            payload?.body
            || state.createCampaignInfo.NPSBody.split().join('\\n').split('"').join('\\"'),
          campaignid: payload?.campaignid,
          emailtemplate: payload?.emailtemplate || state.createCampaignInfo.emailtemplate,
          emailhtml: payload?.emailhtml || state.createCampaignInfo.emailhtml,
          contenttemplate: payload?.contenttemplate || state.createCampaignInfo.contenttemplate,
          templatedesign: payload?.templatedesign,
          fromemail: payload?.fromemail,
          fromname: payload?.fromname,
          emailCampaignid: payload?.emailCampaignid,
          contentmarketingnid: payload?.contentmarketingnid,
          smscampaignid: payload?.smscampaignid,
          createdby: payload?.createdby,
          locationid: payload?.locationid,
          senditnow: payload?.senditnow,
          scheduledatetime: payload?.scheduledatetime,
          status: payload?.status,
          category: payload?.category,
          customersInfo: {
            ...state.createCampaignInfo.customersInfo,
            list: checkedSelected || [],
            selectedCustomers: selectedData
              ? selectedData.map((data) => ({
                firstname: data.name,
                contactid: data.customerid || data.id,
                primaryemail: data.email,
                phonenumber: data.phonenumber,
              }))
              : [],
            selectedCustomersEdit: selectedData
              ? selectedData.map((data) => ({
                firstname: data.name,
                contactid: data.customerid || data.id,
                primaryemail: data.email,
                phonenumber: data.phonenumber,
              }))
              : [],
          },
        },
      };
    }
    case 'GET_COMMUNICATION_TYPE_BYID':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          communicationInfo: {
            ...state.createCampaignInfo.communicationInfo,
            selectedType: payload,
          },
        },
      };
    case 'GET_REVIEW_TYPE_BYID':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          reviewInfo: {
            ...state.createCampaignInfo.reviewInfo,
            selectedType: payload,
          },
        },
      };
    case 'SET_SCHEDULE_CAMPAIGN':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          status: payload ? 1 : 3,
        },
      };
    case 'SET_SCHEDULE_EMAIL_CAMPAIGN':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          status: payload ? 1 : 3,
        },
      };
    case 'SET_SCHEDULEDATETIME_CAMPAIGN':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          scheduledatetime: payload,
        },
      };
    case 'SET_CAMPAGIN_ID':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          campaignid: payload,
        },
      };
    case 'SET_EMAILCAMPAGIN_ID':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          emailCampaignid: payload,
        },
      };
    case 'SET_NEWSLETTER_ID':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          newsletteremailid: payload,
        },
      };
    case 'SET_CONTECTMARKETING_ID':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          contentmarketingnid: payload,
        },
      };
    case 'SET_TEXTCAMPAGIN_ID':
      return {
        ...state,
        createCampaignInfo: {
          ...state.createCampaignInfo,
          smscampaignid: payload,
        },
      };
    case 'GET_ALL_REVIEW_TYPES':
      return {
        ...state,
        reviewtypeList: payload,
      };
    case 'GET_ALL_COMMUNICATION_TYPES':
      return {
        ...state,
        communicationtypeList: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const CampaginProvider = ({ children }) => {
  const [campagin, setCampagin] = React.useReducer(rootReducer, {
    isLoading: false,
    communicationtypeList: [],
    reviewtypeList: [],
    createCampaignInfo: {
      NPSBody: '',
      NPSSubject: '',
      NewsLetterSubject: '',
      campaginTitle: '',
      emailCampaignTitle: '',
      textCampaginTitle: '',
      contactMarketingTitle: '',
      textBodyCampagin: '',
      textBodyLinkCampagin: '',
      textBodyFileCampagin: '',
      fromemail: '',
      fromname: '',
      scheduledatetime: '',
      emailtemplate: '',
      emailhtml: '',
      contenttemplate: '',
      templatedesign: '',
      senditnow: true,
      status: 1,
      communicationInfo: {
        selectedType: '',
        list: [],
        isLoading: false,
      },
      reviewInfo: {
        selectedType: '',
        list: [],
        isLoading: false,
      },
      customersInfo: {
        list: [],
        selectedCustomers: [],
        isLoading: false,
      },
    },
    campaginList: {
      list: [],
      isLoading: false,
    },
    emailCampaginList: {
      list: [],
      isLoading: false,
    },
    textemailCampaginList: {
      list: [],
      isLoading: false,
    },
    contectMarketingList: {
      list: [],
      isLoading: false,
    },
    newsLetterList: {
      list: [],
      isLoading: false,
    },
    positiveComment: {
      reviewMessage: '',
      reviewTitle: '',
    },
  });
  return (
    <CampaginContext.Provider value={{ campagin, setCampagin }}>
      {children}
    </CampaginContext.Provider>
  );
};

const useCampaginState = () => {
  const context = React.useContext(CampaginContext);
  return context;
};
export const setBusinessData = async (id, dispatch) => {
  if (handleUndefined(id)) {
    await postDataRequest(config.baseURLApi, {
      query: `{
      entity(entityid:"${id}"){entityid,businessname,brandname
        firstname,lastname,email,phonenumber,entitytypeid,businesstypeid,jobid,tags,website,shortdesc,fulldesc,policy,terms
        }}`,
    }).then(async (res) => {
      await dispatch({ type: 'SET_BUSINESS_DATA', payload: res.data.entity });
    });
  }
};

export const getReviewtypeByCommunicationType = async (id, dispatch) => {
  dispatch({ type: 'REVIEW_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{reviewtypeByCommunicationType(communicationtypeid: "${id}"){
          reviewtypeid,
          displaytext,
          icon,
          desc,
          createdby,
          updatedby,
          communicationtypeid,
        }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_REVIEW_TYPE',
        payload: res.data.reviewtypeByCommunicationType,
      });
      dispatch({ type: 'REVIEW_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'REVIEW_LOADING', payload: false });
    });
};

export const getCommunicationTypeById = async (id, dispatch) => {
  dispatch({ type: 'COMMUNICATION_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{communicationtype(communicationtypeid: "${id}"){
          communicationtypeid,
          displaytext,
          icon,
          desc,
          createdby,
          updatedby,
        }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_COMMUNICATION_TYPE_BYID',
        payload: {
          communicationtypeid:
            res.data.communicationtype.communicationtypeid,
          type: res.data.communicationtype.displaytext,
        },
      });
      dispatch({ type: 'COMMUNICATION_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'COMMUNICATION_LOADING', payload: false });
    });
};

export const getReviewtypeById = async (id, dispatch) => {
  dispatch({ type: 'REVIEW_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{reviewtype(reviewtypeid: "${id}"){
          reviewtypeid,
          displaytext,
          icon,
          desc,
          createdby,
          updatedby,
          communicationtypeid,
        }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_REVIEW_TYPE_BYID',
        payload: {
          type: res.data.reviewtype.displaytext,
          reviewtypeid: res.data.reviewtype.reviewtypeid,
        },
      });
      dispatch({ type: 'REVIEW_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'REVIEW_LOADING', payload: false });
    });
};

export const getCampaignsByid = async (id, dispatch) => {
  await dataRequest(config.baseURLApi, {
    query: `{campaign(campaignid: "${id}"){
        name, campaignid,subject, body,customers,senditnow,scheduledatetime,locationid, createdby,
        communicationtypeid, reviewtypeid status,
      }}`,
  })
    .then(async (res) => {
      await dispatch({
        type: 'GET_CAMAING_BY_ID',
        payload: res.data.campaign,
      });
      await getReviewtypeByCommunicationType(
        res.data.campaign.communicationtypeid,
        dispatch,
      );
      await getCommunicationTypeById(
        res.data.campaign.communicationtypeid,
        dispatch,
      );
      await getReviewtypeById(res.data.campaign.reviewtypeid, dispatch);
      await dispatch({ type: 'CAMAING_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};
export const getEmailCampaignsByid = async (id, dispatch) => {
  await dataRequest(config.baseURLApi, {
    query: `{smsCampaign(smscampaignid: "${id}"){
      smscampaignid,name,fromnumber,fromname,customers,scheduledatetime,smstext,senditnow,sendtime,delevertime,
      locationid,createdby,updatedby,status,createdAt,updatedAt
      }}`,
  })
    .then(async (res) => {
      await dispatch({
        type: 'GET_CAMAING_BY_ID',
        payload: res.data.smsCampaign,
      });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};

export const getEmailMarketingCampaignsByid = async (id, dispatch) => {
  await dataRequest(config.baseURLApi, {
    query: `{emailCampaign(emailcampaignid: "${id}"){
      name, emailcampaignid,subject,fromemail,fromname,templatedesign, emailtemplate,customers,senditnow,scheduledatetime,locationid, createdby,
      status,
      }}`,
  })
    .then(async (res) => {
      await dispatch({
        type: 'GET_CAMAING_BY_ID',
        payload: res.data.emailCampaign,
      });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};

export const getContectMarketingByid = async (id, dispatch) => {
  await dataRequest(config.baseURLApi, {
    query: `{contentMarketing(contentmarketingnid: "${id}"){
      contentmarketingnid,name,category,author,contenttemplate,templatedesign,locationid,status,createdby,updatedby,createdAt,updatedAt,
      }}`,
  })
    .then(async (res) => {
      await dispatch({
        type: 'GET_CAMAING_BY_ID',
        payload: res.data.contentMarketing,
      });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};

export const getCommunicationType = async (dispatch) => {
  dispatch({ type: 'COMMUNICATION_LOADING', payload: true });
  await dataRequest(config.baseURLApi, {
    query: `{communicationtypes{
        communicationtypeid,
        displaytext,
        icon,
        desc,
        createdby,
        updatedby,
      }}`,
  })
    .then((res) => {
      const value = res?.data?.communicationtypes[0]?.displaytext;
      const id = res?.data?.communicationtypes[0]?.communicationtypeid;
      dispatch({ type: 'SET_COMMUNICATION_TYPE', payload: { type: value, id } });

      dispatch({
        type: 'GET_COMMUNICATION_TYPE',
        payload: res.data.communicationtypes,
      });
      dispatch({ type: 'COMMUNICATION_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'COMMUNICATION_LOADING', payload: false });
    });
};

export const getCustomerListByFilters = async (props, dispatch) => {
  await dispatch({ type: 'CUSTOMER_LOADING', payload: true });
  await postDataRequest(config.baseURLApi, {
    query: ` query contactlistbyfilters(
      $tags: [String!],
      $status: [Int!],
      $lifecyclestage: [Int!],
      $source: [String!],
      $emailcampaignids: [ID!]
      $startDate : String,
      $endDate : String,
      $opendemailcampaignids: [ID!]
      $receivedemailcampaignids: [ID!]
      $clickedemailcampaignids: [ID!]
      $isphoneno: Boolean
      $smscampaignids: [ID!]
      $employeesize: [String!]
      $yearsinbusiness: [Int!]
      $revenue: [Int!]
      $businessstartdate: [String!]
      $typeofentity: [String!]
      $deliveredsmscampaignids: [ID!]
      $pageNumber: Int!, $pageSize: Int!, $locationid: ID!, $searchText: String){
              contactlistbyfilters(
                pageNumber: $pageNumber,
                pageSize: $pageSize,
                searchtext: $searchText,
                locationid: $locationid,
                smscampaignids: $smscampaignids
                deliveredsmscampaignids:$deliveredsmscampaignids
                tags: $tags,
                status: $status,
                lifecyclestage: $lifecyclestage,
                source: $source,
                startdate:$startDate,
                enddate:$endDate,
                employeesize: $employeesize
                yearsinbusiness: $yearsinbusiness
                revenue: $revenue
                businessstartdate: $businessstartdate
                typeofentity: $typeofentity
                emailcampaignids:$emailcampaignids,
                opendemailcampaignids: $opendemailcampaignids
                receivedemailcampaignids: $receivedemailcampaignids
                clickedemailcampaignids: $clickedemailcampaignids
                isphoneno: $isphoneno
                ){
                  count,rows{contactid,
                    primaryemail
                    secondaryemail
                    firstname
                    lastname
                    contactavatar
                    officephonenumber
                    phonenumber
                    homephonenumber
                    lifecyclestage
                    locationid
                    createdby
                    updatedby
                    deleted
                    jobid
                    tags
                    ownerid
                    source
                    twitter
                    createdAt
                    updatedAt
                    phonecountryid
                    linkedin
                    facebook
                    status
                    totalcount
                    delivered
                    opened
                    clicked
              }}}`,
    variables: {
      pageNumber: props?.pageNumber,
      pageSize: props?.pageSize,
      isphoneno: props?.isphoneno,
      locationid: props.locationId,
      startDate: handleUndefined(props?.selectedStartDate) ? props?.selectedStartDate : null,
      endDate: handleUndefined(props?.selectedEndDate) ? props?.selectedEndDate : null,
      searchText: props.searchText || undefined,
      tags: props?.tags?.length > 0 ? props?.tags : undefined,
      status: props?.status?.length > 0 ? props?.status : undefined,
      source: props?.source?.length > 0 ? props?.source : undefined,
      revenue: props?.revenue?.length > 0 ? props?.revenue : undefined,
      employeesize: props?.employeesize?.length > 0 ? props?.employeesize : undefined,
      yearsinbusiness: props?.yearsinbusiness?.length > 0
        ? props?.yearsinbusiness : undefined,
      typeofentity: props?.typeofentity?.length > 0 ? props?.typeofentity : undefined,
      lifecyclestage: props?.lifecyclestage?.length > 0 ? props.lifecyclestage : undefined,
      emailcampaignids: props?.emailcampaignids?.length > 0
        ? props?.emailcampaignids : undefined,
      opendemailcampaignids: props?.opendemailcampaignids?.length > 0
        ? props?.opendemailcampaignids : undefined,
      receivedemailcampaignids: props?.receivedemailcampaignids?.length > 0
        ? props?.receivedemailcampaignids : undefined,
      clickedemailcampaignids: props?.clickedemailcampaignids?.length > 0
        ? props?.clickedemailcampaignids : undefined,
      smscampaignids: props?.smscampaignids?.length > 0
        ? props?.smscampaignids : undefined,
      deliveredsmscampaignids: props?.deliveredsmscampaignids?.length > 0
        ? props?.deliveredsmscampaignids : undefined,
    },
  })
    .then((res) => {
      dispatch({
        type: 'GET_CUSTOMER_LIST',
        payload: res?.data?.contactlistbyfilters,
      });
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    });
};

export const getCustomerListByFilter = async (props, dispatch) => {
  await dispatch({ type: 'CUSTOMER_LOADING', payload: true });
  await postDataRequest(config.baseURLApi, {
    query: Contactsbypaging,
    variables: {
      pageNumber: props?.pageNumber,
      pageSize: props?.pageSize,
      advancedfilter: props?.advancedfilter,
      filterid: props?.filterid,
      sortby: props?.sortby,
      isphonenumber: props?.isphoneno,
      startDate: handleUndefined(props?.selectedStartDate) ? moment(props?.selectedStartDate).format('YYYY-MM-DD') : null,
      endDate: handleUndefined(props?.selectedEndDate) ? moment(props?.selectedEndDate).format('YYYY-MM-DD') : null,
      searchText: props.searchText || undefined,
      tags: props?.tags?.length > 0 ? props?.tags : undefined,
      istargetlist: props?.istargetlist,
      status: props?.status?.length > 0 ? props?.status : undefined,
      source: props?.source?.length > 0 ? props?.source : undefined,
      state: props?.stateid?.length > 0 ? props?.stateid : undefined,
      countryid: props?.countryid?.length > 0 ? props?.countryid : undefined,
      employeesize: props?.employeesize?.length > 0 ? props?.employeesize : undefined,
      yearsinbusiness: props?.yearsinbusiness?.length > 0
        ? props?.yearsinbusiness : undefined,
      typeofentity: props?.typeofentity?.length > 0 ? props?.typeofentity : undefined,
      businessstartdate: props?.businessstartdate?.length > 0
        ? props?.businessstartdate : undefined,
      city: props?.city?.length > 0 ? props?.city : undefined,
      lifecyclestage: props?.lifecyclestage?.length > 0 ? props.lifecyclestage : undefined,
      jobid: props?.jobid?.length > 0 ? props.jobid : undefined,
      locationid: props?.locationId || locationId,
    },
  })
    .then((res) => {
      dispatch({
        type: 'GET_CUSTOMER_LIST',
        payload: res?.data?.contactsbypaging?.rows?.length >= 0
          ? res?.data?.contactsbypaging : [],
      });
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    });
};

export const createContactFormSetting = async (props) => {
  const apiHeader = {
    Authorization: `Bearer ${localStorage?.getItem('token')}`,
    businessid: JSON.parse(localStorage?.getItem('business_Id')),
    locationid: JSON.parse(localStorage?.getItem('location_Id')),
    'Apollo-Require-Preflight': 'true',
  };
  const httpLink = createUploadLink({
    uri: config.contactApi,
    headers: apiHeader,
  });
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: apiHeader,
    });

    return forward(operation);
  });
  const client = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache: new InMemoryCache(),
  });
  const {
    setFormId, setCallOutImage, setInitialFile, setCallOutFileObj, setCallOutFileUrl,
    setThankYouFileObj, setThankYouFileUrl, locationid,
  } = props;
  let result;
  let callOutFileUpload;
  let thankYouFileUpload;
  if (props?.fileObj) {
    result = await client.mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        document: props?.fileObj,
        locationid: locationId || locationid,
      },
    });
  }
  if (props?.callOutFileObj) {
    callOutFileUpload = await client.mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        document: props?.callOutFileObj,
        locationid: locationId || locationid,
      },
    });
  }
  if (props?.thankYouFileObj) {
    thankYouFileUpload = await client.mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        document: props?.thankYouFileObj,
        locationid: locationId || locationid,
      },
    });
  }
  await postDataRequest(config.baseURLApi, {
    query: `mutation createcontactformsetting(
        $formType: String!,
        $formName: String,
        $isShowFiledSelection: Boolean,
        $callOutButtonType: String,
        $callOutButtonLink: String,
        $featureImageUrl: String,
        $calloutText: String!,
        $calloutBody: String,
        $calloutButtonText: String,
        $callOutButtonFileUrl: String,
        $themeColor: String,
        $textColor: String,
        $backgroundColor: String
        $formBody: String,
        $isFirstameShow: Boolean,
        $islastnameShow: Boolean,
        $isEmailShow: Boolean,
        $isPhoneShow: Boolean,
        $formSubmitButtonText: String,
        $contactLifecycleStage: Int,
        $thanksMessage: String,
        $isShowThanksButton: Boolean,
        $thanksButtonText: String,
        $thanksButtonType: String,
        $thanksButtonLink: String,
        $formposition: String,
        $thanksButtonFileUrl: String,
        $termenable:Boolean,
        $termdesc: String,
        $captchaenable:Boolean,
        $isshowfooterlogo:Boolean,
        $calloutbtnposition:String,
        $locationid: ID!,
        $createdby: ID!
        $workflowid: ID
      ){
        createcontactformsetting(formtype: $formType
    name: $formName
    isshowfiledselection: $isShowFiledSelection
    featureimageurl: $featureImageUrl
    callouttext: $calloutText
    calloutbody: $calloutBody
    calloutbuttontext: $calloutButtonText
    themecolor: $themeColor
    textcolor: $textColor
    backgroundcolor: $backgroundColor
    calloutbuttontype: $callOutButtonType
    calloutbuttonlink: $callOutButtonLink
    calloutbuttonfileurl: $callOutButtonFileUrl
    formbody: $formBody
    isfirstnameshow: $isFirstameShow
    islastnameshow: $islastnameShow
    isemailshow: $isEmailShow
    isphoneshow: $isPhoneShow
    formsubmitbuttontext: $formSubmitButtonText
    contactlifecyclestage: $contactLifecycleStage
    thanksmessage: $thanksMessage
    isshowthanksbutton: $isShowThanksButton
    thanksbuttontext: $thanksButtonText
    thanksbuttontype: $thanksButtonType
    thanksbuttonlink: $thanksButtonLink
    thanksbuttonfileurl: $thanksButtonFileUrl
    termenable:$termenable
    termdesc: $termdesc
    captchaenable:$captchaenable
    isshowfooterlogo:$isshowfooterlogo
    locationid: $locationid
    calloutbtnposition: $calloutbtnposition,
    formposition: $formposition,
    createdby: $createdby
    workflowid: $workflowid){
      formid
    }
      }`,
    variables: {
      formType: props?.formType,
      formName: props?.formName,
      textColor: props?.textColor,
      formposition: props?.formposition,
      isShowFiledSelection: props?.fieldSelection,
      featureImageUrl: handleUndefined(result?.data?.uploadcontactformdocument),
      calloutText: props?.calloutText,
      calloutBody: props?.calloutBody,
      calloutButtonText: props?.calloutButtonText,
      themeColor: props?.themeColor,
      backgroundColor: props?.backgroundColor,
      callOutButtonType: props?.callOutButtonType,
      callOutButtonLink: props?.callOutButtonLink,
      callOutButtonFileUrl: callOutFileUpload?.data?.uploadcontactformdocument
        || undefined,
      formBody: props?.formBody,
      isFirstameShow: props?.isFirstameShow,
      islastnameShow: props?.islastnameShow,
      isEmailShow: props?.isEmailShow,
      isPhoneShow: props?.isPhoneShow,
      formSubmitButtonText: props?.formSubmitButtonText,
      contactLifecycleStage: props?.contactLifecycleStage,
      thanksMessage: props?.thanksMessage,
      isShowThanksButton: props?.isShowThanksButton,
      thanksButtonText: props?.thanksButtonText,
      thanksButtonType: props?.thanksButtonType,
      thanksButtonLink: props?.thanksButtonLink,
      thanksButtonFileUrl: thankYouFileUpload?.data?.uploadcontactformdocument || undefined,
      termenable: props?.termenable,
      termdesc: props?.termdesc,
      captchaenable: props?.captchaenable,
      isshowfooterlogo: props?.isshowfooterlogo,
      locationid: props?.locationid,
      calloutbtnposition: props?.calloutbtnposition,
      createdby: props?.createdby,
      workflowid: props?.workflowid,
    },
  }).then((res) => {
    setFormId(res?.data?.createcontactformsetting?.formid);
    if (result?.data) {
      setCallOutImage(result?.data?.uploadcontactformdocument);
      setInitialFile(null);
    }
    if (callOutFileUpload?.data) {
      setCallOutFileUrl(callOutFileUpload?.data?.uploadcontactformdocument);
      setCallOutFileObj(null);
    }
    if (thankYouFileUpload?.data) {
      setThankYouFileUrl(thankYouFileUpload?.data?.uploadcontactformdocument);
      setThankYouFileObj(null);
    }
  });
};

export const editContactFormSetting = async (props) => {
  const apiHeader = {
    Authorization: `Bearer ${localStorage?.getItem('token')}`,
    businessid: JSON.parse(localStorage?.getItem('business_Id')),
    locationid: JSON.parse(localStorage?.getItem('location_Id')),
    'Apollo-Require-Preflight': 'true',
  };
  const httpLink = createUploadLink({
    uri: config.contactApi,
    headers: apiHeader,
  });
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: apiHeader,
    });

    return forward(operation);
  });
  const client = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache: new InMemoryCache(),
  });
  const {
    setCallOutImage, setInitialFile, setCallOutFileObj, setCallOutFileUrl,
    setThankYouFileObj, setThankYouFileUrl, locationid,
  } = props;
  let result;
  let callOutFileUpload;
  let thankYouFileUpload;
  if (props?.callOutFileObj) {
    callOutFileUpload = await client.mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        document: props?.callOutFileObj,
        locationid: locationId || locationid,
      },
    });
  }
  if (props?.fileObj) {
    result = await client.mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        document: props?.fileObj,
        locationid: locationId || locationid,
      },
    });
  }
  if (props?.thankYouFileObj) {
    thankYouFileUpload = await client.mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        document: props?.thankYouFileObj,
        locationid: locationId || locationid,
      },
    });
  }
  await postDataRequest(config.baseURLApi, {
    query: `mutation editcontactformsetting(
        $formId: ID!,
        $formType: String!,
        $formName: String,
        $isShowFiledSelection: Boolean,
        $callOutButtonType: String,
        $callOutButtonLink: String,
        $featureImageUrl: String,
        $calloutText: String!,
        $calloutBody: String,
        $calloutButtonText: String,
        $callOutButtonFileUrl: String,
        $themeColor: String,
        $textColor: String,
        $backgroundColor: String
        $formBody: String,
        $isFirstameShow: Boolean,
        $islastnameShow: Boolean,
        $isEmailShow: Boolean,
        $isPhoneShow: Boolean,
        $formSubmitButtonText: String,
        $contactLifecycleStage: Int,
        $thanksMessage: String,
        $isShowThanksButton: Boolean,
        $thanksButtonText: String,
        $thanksButtonType: String,
        $thanksButtonLink: String,
        $thanksButtonFileUrl: String,
        $locationid: ID!,
        $updatedby: ID!,
        $onHalfScroll:Boolean,
        $triggerOnLoad: Boolean,
        $isFixedPosition: Boolean,
        $triggerPosition: String,
        $onPageLeave:Boolean,
        $afterSelectedTime:Boolean,
        $seconds: Int,
        $status: Int,
        $activeDate: String,
        $formposition: String,
        $thankssmsconfig: String,
 $thanksemailconfig: String,
  $issendthankssms: Boolean,
   $issendthanksemail: Boolean
   $termenable:Boolean
   $termdesc: String
   $captchaenable:Boolean
   $isshowfooterlogo:Boolean
   $calloutbtnposition:String
   $workflowid: ID
      ){
        editcontactformsetting(
    formid: $formId
    formtype: $formType
    name: $formName
    isshowfiledselection: $isShowFiledSelection
    featureimageurl: $featureImageUrl
    callouttext: $calloutText
    calloutbody: $calloutBody
    calloutbuttontext: $calloutButtonText
    calloutbuttonfileurl: $callOutButtonFileUrl
    themecolor: $themeColor
    textcolor: $textColor
    backgroundcolor: $backgroundColor
    calloutbuttontype: $callOutButtonType
    calloutbuttonlink: $callOutButtonLink,
    formbody: $formBody
    isfirstnameshow: $isFirstameShow
    islastnameshow: $islastnameShow
    isemailshow: $isEmailShow
    isphoneshow: $isPhoneShow
    formsubmitbuttontext: $formSubmitButtonText
    contactlifecyclestage: $contactLifecycleStage
    thanksmessage: $thanksMessage
    isshowthanksbutton: $isShowThanksButton
    thanksbuttontext: $thanksButtonText
    thanksbuttontype: $thanksButtonType
    thanksbuttonlink: $thanksButtonLink
    thanksbuttonfileurl: $thanksButtonFileUrl
    triggeronhalfpagecroll:$onHalfScroll
    triggeronload: $triggerOnLoad
    isfixedposition: $isFixedPosition
    triggerposition: $triggerPosition
    triggeronexitintent:$onPageLeave
    triggerafterelapsedtime:$afterSelectedTime
    triggertime: $seconds
    status: $status
    activedate: $activeDate
    locationid: $locationid
    thankssmsconfig: $thankssmsconfig,
    formposition: $formposition,
   thanksemailconfig: $thanksemailconfig,
    issendthankssms: $issendthankssms,
     issendthanksemail: $issendthanksemail,
     termenable:$termenable,
      termdesc: $termdesc,
      captchaenable:$captchaenable,
      isshowfooterlogo:$isshowfooterlogo,
      calloutbtnposition:$calloutbtnposition,
    updatedby: $updatedby
    workflowid: $workflowid)
      }`,
    variables: {
      formId: props?.formId,
      formType: props?.formType,
      formName: props?.formName,
      formposition: props?.formposition,
      isShowFiledSelection: props?.fieldSelection,
      featureImageUrl: props?.fileObj
        ? result?.data?.uploadcontactformdocument : props.callOutImage,
      calloutText: props?.calloutText,
      calloutBody: props?.calloutBody,
      calloutButtonText: props?.calloutButtonText,
      callOutButtonFileUrl: callOutFileUpload?.data?.uploadcontactformdocument
        || undefined,
      themeColor: props?.themeColor,
      textColor: props?.textColor,
      backgroundColor: props?.backgroundColor,
      callOutButtonType: props?.callOutButtonType,
      callOutButtonLink: props?.callOutButtonLink,
      formBody: props?.formBody,
      isFirstameShow: props?.isFirstameShow,
      islastnameShow: props?.islastnameShow,
      isEmailShow: props?.isEmailShow,
      isPhoneShow: props?.isPhoneShow,
      formSubmitButtonText: props?.formSubmitButtonText,
      contactLifecycleStage: props?.contactLifecycleStage,
      thanksMessage: props?.thanksMessage,
      isShowThanksButton: props?.isShowThanksButton,
      thanksButtonText: props?.thanksButtonText,
      thanksButtonType: props?.thanksButtonType,
      thanksButtonLink: props?.thanksButtonLink,
      thanksButtonFileUrl: thankYouFileUpload?.data?.uploadcontactformdocument || undefined,
      locationid: props?.locationid,
      updatedby: props?.updatedby,
      onHalfScroll: props?.onHalfScroll,
      triggerOnLoad: props?.triggerOnLoad,
      isFixedPosition: props?.isFixedPosition,
      triggerPosition: props?.triggerPosition,
      onPageLeave: props?.onPageLeave,
      afterSelectedTime: props?.afterSelectedTime,
      seconds: props?.afterSelectedTime === true ? parseInt(props?.seconds, 10) : undefined,
      status: props?.status,
      activeDate: props?.activeDate,
      thankssmsconfig: props?.thankssmsconfig,
      thanksemailconfig: props?.thanksemailconfig,
      issendthankssms: props?.issendthankssms,
      issendthanksemail: props?.issendthanksemail,
      termenable: props?.termenable,
      termdesc: props?.termdesc,
      captchaenable: props?.captchaenable,
      isshowfooterlogo: props?.isshowfooterlogo,
      calloutbtnposition: props?.calloutbtnposition,
      workflowid: props?.workflowid,
    },
  }); if (result?.data) {
    setCallOutImage(result?.data?.uploadcontactformimage);
    setInitialFile(null);
  }
  if (callOutFileUpload?.data) {
    setCallOutFileUrl(callOutFileUpload?.data?.uploadcontactformdocument);
    setCallOutFileObj(null);
  }
  if (thankYouFileUpload?.data) {
    setThankYouFileUrl(thankYouFileUpload?.data?.uploadcontactformdocument);
    setThankYouFileObj(null);
  }
};

export const getCustomerList = async (props, dispatch) => {
  dispatch({ type: 'CUSTOMER_LOADING', payload: true });
  await postDataRequest(config.baseURLApi, {
    query: ` query contactsbypaging(
      $pageNumber: Int!,
       $pageSize: Int!,
        $locationid: ID!
        ){
              contactsbypaging(
                pageNumber: $pageNumber,
                 ${props.contact ? '' : `lifecyclestage:${customerLifeCycleStage}`},
                  pageSize: $pageSize, locationid:$locationid){
                  count,rows{contactid,
                    organizationname
                      primaryemail,
                      firstname
                      lastname
                      phonenumber
                      lifecyclestage
                      source
                      tags
                      createdAt
                      status
                      createdby
              }}}`,
    variables: {
      pageNumber: props?.pageNumber,
      pageSize: props?.pageSize,
      locationid: props.locationId,
    },
  })
    .then((res) => {
      dispatch({
        type: 'GET_CUSTOMER_LISTS',
        payload: res?.data?.contactsbypaging,
      });
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    });
};

export const CustomersByDate = async (
  props,
  dispatch,
) => {
  dispatch({ type: 'CUSTOMER_LOADING', payload: true });
  postDataRequest(config.baseURLApi, {
    query: ` query contactsbypaging($pageNumber: Int!, $pageSize: Int!, $locationid: ID!, $startDate : String, $endDate : String){
            contactsbypaging(pageNumber: $pageNumber, pageSize: $pageSize, ${props.contact ? '' : `lifecyclestage:${customerLifeCycleStage}`}, locationid:$locationid, startdate:$startDate, enddate:$endDate){
                count,rows{contactid,
                  organizationname
                primaryemail,
                firstname
                lastname
                phonenumber
                lifecyclestage
                source
                tags
                createdAt
                status
                createdby
            }}}`,
    variables: {
      locationid: props?.locationId,
      startDate: props?.startDate,
      endDate: props?.endDate,
      pageNumber: props?.pageNumber,
      pageSize: props?.pageSize,
    },
  })
    .then((res) => {
      if (res.data.contactsbypaging) {
        dispatch({
          type: 'GET_CUSTOMER_LISTS',
          payload: res.data.contactsbypaging,
        });
        dispatch({ type: 'CUSTOMER_LOADING', payload: false });
      } else {
        getCustomerList({ locationId: props?.locationId, pageNumber: 1, pageSize: 10 }, dispatch);
      }
    })
    .catch(() => {
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    });
};

export const CustomersFilterByDate = async (
  props,
  dispatch,
) => {
  dispatch({ type: 'CUSTOMER_LOADING', payload: true });
  postDataRequest(config.baseURLApi, {
    query: ` query contactlistbyfilters(
              $pageNumber: Int!,
              $pageSize: Int!,
              $locationid: ID!,
              $isphoneno: Boolean
              $emailcampaignids: [ID!]
              $startDate : String,
              $endDate : String
              $opendemailcampaignids: [ID!]
              $receivedemailcampaignids: [ID!]
              $clickedemailcampaignids: [ID!]
              $smscampaignids: [ID!]
      $deliveredsmscampaignids: [ID!]
          ){
      contactlistbyfilters(
            pageNumber: $pageNumber,
            pageSize: $pageSize,
            isphoneno:$isphoneno
            ${props.contact ? '' : `lifecyclestage:${customerLifeCycleStage}`},
            locationid:$locationid,
             startdate:$startDate,
            enddate:$endDate,
            smscampaignids: $smscampaignids
      deliveredsmscampaignids: $deliveredsmscampaignids
            emailcampaignids:$emailcampaignids,
            opendemailcampaignids: $opendemailcampaignids
            receivedemailcampaignids: $receivedemailcampaignids
            clickedemailcampaignids: $clickedemailcampaignids
            ){
        count,rows{contactid,
          primaryemail
          secondaryemail
          firstname
          lastname
          contactavatar
          officephonenumber
          phonenumber
          homephonenumber
          lifecyclestage
          locationid
          createdby
          updatedby
          deleted
          jobid
          tags
          ownerid
          source
          twitter
          createdAt
          updatedAt
          phonecountryid
          linkedin
          facebook
          status
          totalcount
          delivered
          opened
          clicked
            }}}`,
    variables: {
      locationid: props?.locationId,
      startDate: props?.startDate,
      endDate: props?.endDate,
      isphoneno: props?.isphoneno,
      pageNumber: props?.pageNumber,
      pageSize: props?.pageSize,
      emailcampaignids: props?.emailcampaignids,
      opendemailcampaignids: props?.opendemailcampaignids,
      receivedemailcampaignids: props?.receivedemailcampaignids,
      clickedemailcampaignids: props?.clickedemailcampaignids,
      smscampaignids: props?.smscampaignids,
      deliveredsmscampaignids: props?.deliveredsmscampaignids,
    },
  })
    .then((res) => {
      if (res.data.contactlistbyfilters) {
        dispatch({
          type: 'GET_CUSTOMER_LIST',
          payload: res.data.contactlistbyfilters,
        });
        dispatch({ type: 'CUSTOMER_LOADING', payload: false });
      } else {
        getCustomerListByFilter({
          locationId: props?.locationId,
          pageNumber: 1,
          pageSize: 100,
        }, dispatch);
      }
    })
    .catch(() => {
      dispatch({ type: 'CUSTOMER_LOADING', payload: false });
    });
};

export const getCampaigns = async (
  props,
  dispatch,
) => {
  dispatch({ type: 'CAMAING_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{campaignsbylocation(
          locationid: "${props?.locationId}",
          pageNumber: ${props?.pageNumber},
          pageSize: ${props?.pageSize},
          ${props?.selectedStartDate ? `startdate:"${props?.selectedStartDate}",` : ''}
          ${props?.selectedEndDate ? `enddate:"${props?.selectedEndDate}",` : ''}
          ${props?.status ? `status: ${props?.status},` : ''}
          ${props?.communicationTypeId ? `communicationtypeid: "${props?.communicationTypeId}",` : ''}
          ${props?.reviewtypeid ? `reviewtypeid: "${props?.reviewTypeId}",` : ''}
        ){
        count,
        rows {
          campaignid,
          name,
          communicationtypeid,
          subject,
          body,
          customers,
          scheduledatetime,
          locationid,
          status,
          reviewtypeid,
        }
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_CAMPAING_LIST',
        payload: res.data.campaignsbylocation,
      });
      dispatch({ type: 'SHOW_REVIEWCAMPAIGNFIRSTTIME_POPUP', payload: res?.data?.campaignsbylocation?.rows?.length === 0 });
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};
export const getEmailCampaigns = async (
  props,
  dispatch,
) => {
  dispatch({ type: 'CAMAING_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{emailCampaignsbylocation(
            locationid: "${props?.locationId}",
            pageNumber: ${props?.pageNumber},
            pageSize: ${props?.pageSize},
            ${props?.timezone ? `timezone: ${props?.timezone},` : ''}
            ${props?.campaginStatus ? `status:${props?.campaginStatus},` : ''}
            ${props?.selectedStartDate ? `startdate:"${props?.selectedStartDate}",` : ''}
            ${props?.selectedEndDate ? `enddate:"${props?.selectedEndDate}",` : ''}
            ${props?.searchText ? `searchtext:"${props?.searchText}",` : ''}
            ${props?.fromemail ? `fromemail:"${props?.fromemail}",` : ''}
        ){
        count,
        rows {
            emailcampaignid,
          name,
          subject,
          fromname,
          fromemail,
          templatedesign,
          emailtemplate,
          customers,
          scheduledatetime,
          locationid,
          status,
          totaldelivered
          totalopened
          totalsentemail
          totalclicked
          totalbounced
          totalunsubscribed
        }
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_EMAILCAMPAING_LIST',
        payload: res.data.emailCampaignsbylocation,
      });
      dispatch({ type: 'SHOW_FIRSTTIME_POPUP', payload: res?.data?.emailCampaignsbylocation?.rows?.length === 0 });
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};

export const getContactMarketing = async (
  props,
  dispatch,
) => {
  dispatch({ type: 'CAMAING_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{contentMarketingbylocation(
            locationid: "${props?.locationId}",
            pageNumber: ${props?.pageNumber},
            pageSize: ${props?.pageSize},
            ${props?.campaginStatus ? `status:${props?.campaginStatus},` : ''}
            ${props?.selectedStartDate ? `startdate:"${props?.selectedStartDate}",` : ''}
            ${props?.selectedEndDate ? `enddate:"${props?.selectedEndDate}",` : ''}
            ${props?.searchText ? `searchtext:"${props?.searchText}",` : ''}
        ){
        count,
        rows {
          contentmarketingnid
          name
          category
          author
          contenttemplate
          templatedesign
          locationid
          status
          isfree
          createdby
          updatedby
          createdAt
          updatedAt
          openeddata
          clickeddata
          sharecount
          shorturl
          businessid
          listpageimage
          ispublish
        }
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_CONTECTMARKETING_LIST',
        payload: res.data.contentMarketingbylocation,
      });
      dispatch({ type: 'SHOW_FIRSTTIME_POPUP', payload: res?.data?.contentMarketingbylocation?.rows?.length === 0 });
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};

export const getNewsLetterByData = async (
  props,
  dispatch,
) => {
  dispatch({ type: 'CAMAING_LOADING', payload: true });
  postDataRequest(config.baseURLApi, {
    query: NewsletterEmailByLocationId,
    variables: {
      locationid: props?.locationid,
      pageNumber: props?.pageNumber,
      pageSize: props?.pageSize,
      status: props?.status,
      startdate: props?.startdate,
      enddate: props?.enddate,
      searchtext: props?.searchtext,
    },
  })
    .then((res) => {
      dispatch({
        type: 'GET_NEWSLETTER_LIST',
        payload: res?.data?.newsletterEmailByLocationId,
      });
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};

export const SmsCampainByLocation = `query SmsCampaignsbylocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!, $searchtext: String) {
  smsCampaignsbylocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize, searchtext: $searchtext) {
    count
    rows {
      smscampaignid
      name
      fromnumber
      fromname
      customers
      scheduledatetime
      smstext
      senditnow
      sendtime
      delevertime
      mmsurl
      locationid
      createdby
      updatedby
      status
      createdAt
      updatedAt
    }
  }
}`;

export const getTextEmailCampaigns = async (
  props,
  dispatch,
) => {
  dispatch({ type: 'CAMAING_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{smsCampaignsbylocation(
            locationid: "${props?.locationId}",
            pageNumber: ${props?.pageNumber},
            pageSize: ${props?.pageSize},
            ${props?.campaginStatus ? `status:${props?.campaginStatus},` : ''}
            ${props?.selectedStartDate ? `startdate:"${props?.selectedStartDate}",` : ''}
            ${props?.selectedEndDate ? `enddate:"${props?.selectedEndDate}",` : ''}
            ${props?.searchText ? `searchtext:"${props?.searchText}",` : ''}
        ){
        count,
        rows {
          smscampaignid
          name
          fromnumber
          fromname
          customers
          scheduledatetime
          smstext
          senditnow
          sendtime
          delevertime
          locationid
          createdby
          updatedby
          status
          createdAt
          updatedAt
        }
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_TEXTEMAILCAMPAING_LIST',
        payload: res.data.smsCampaignsbylocation,
      });
      dispatch({ type: 'SHOW_FIRSTTIME_POPUP', payload: res?.data?.smsCampaignsbylocation?.rows?.length === 0 });
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'CAMAING_LOADING', payload: false });
    });
};
export function setRatingFeedbackRequestSettings(
  locationid,
  updatedby,
  subject,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewrequestemail: {
            subject: "${subject}",
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}",
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setFeedbackRequestSettings(
  locationid,
  updatedby,
  subject,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          feedbackrequest: {
            subject: "${subject}",
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}",
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setFeedbackRequestSettingsFirstReminder(
  locationid,
  updatedby,
  reviewtypeid,
  subject,
  body,
  active,
  daysAfter,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reminders: [{
            reminderSequence: 1,
            active: ${!!handleUndefined(active)},
            daysAfter: ${daysAfter},
            subject: "${subject}",
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}",
          }],
        )}`,
  })
    .then((res) => {
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingFeedbackRequestSettingsFirstReminder(
  locationid,
  updatedby,
  reviewtypeid,
  subject,
  body,
  active,
  daysAfter,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewreminders: [{
            reminderSequence: 1,
            active: ${!!handleUndefined(active)},
            daysAfter: ${daysAfter},
            subject: "${subject}",
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}",
          }],
        )}`,
  })
    .then((res) => {
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setFeedbackRequestSettingsSecondReminder(
  locationid,
  updatedby,
  reviewtypeid,
  subject1,
  body1,
  active1,
  daysAfter1,
  subject2,
  body2,
  active2,
  daysAfter2,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reminders: [{
              reminderSequence: 1,
              active: ${!!handleUndefined(active1)},
              daysAfter: ${daysAfter1},
              subject: "${subject1}",
              body: "${body1.split('\n').join('\\n').split('"').join('\\"')}",
            },{
              reminderSequence: 2,
              active: ${!!handleUndefined(active2)},
              daysAfter: ${daysAfter2},
              subject: "${subject2}",
              body: "${body2.split('\n').join('\\n').split('"').join('\\"')}",
          }],
      )}`,
  })
    .then((res) => {
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingFeedbackRequestSettingsSecondReminder(
  locationid,
  updatedby,
  reviewtypeid,
  subject1,
  body1,
  active1,
  daysAfter1,
  subject2,
  body2,
  active2,
  daysAfter2,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewreminders: [{
              reminderSequence: 1,
              active: ${!!handleUndefined(active1)},
              daysAfter: ${daysAfter1},
              subject: "${subject1}",
              body: "${body1.split('\n').join('\\n').split('"').join('\\"')}",
            },{
              reminderSequence: 2,
              active: ${!!handleUndefined(active2)},
              daysAfter: ${daysAfter2},
              subject: "${subject2}",
              body: "${body2.split('\n').join('\\n').split('"').join('\\"')}",
          }],
      )}`,
  })
    .then((res) => {
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setPositiveLandingSettings(
  locationid,
  updatedby,
  reviewtypeid,
  showOnlineReview,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          positivelanding: {
            showOnlineReviewLinks: ${showOnlineReview},
            includeYourRatings: true,
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingPositiveLandingSettings(
  locationid,
  updatedby,
  reviewtypeid,
  showOnlineReview,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewpositivelanding: {
            showOnlineReviewLinks: ${showOnlineReview},
            includeYourRatings: true,
          },
        )}`,
  })
    .then((res) => {
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setNegativeLandingSettings(
  locationid,
  updatedby,
  reviewtypeid,
  showOnlineReview,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          negativelanding: {
            showOnlineReviewLinks: ${showOnlineReview},
            includeYourRatings: true,
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingNegativeLandingSettings(
  locationid,
  updatedby,
  reviewtypeid,
  showOnlineReview,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewnegativelanding: {
            showOnlineReviewLinks: ${showOnlineReview},
            includeYourRatings: true,
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setPositiveScenarioFirstEmail(
  locationid,
  updatedby,
  reviewtypeid,
  active,
  daysAfter,
  onlineReview,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          positiveemails: [{
            emailSequence: 1,
            active: ${active},
            daysAfter: ${daysAfter},
            emailReminderToLeaveOnlineReview: ${onlineReview}
          }],
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setPositiveScenarioSecondEmail(
  locationid,
  updatedby,
  reviewtypeid,
  active1,
  daysAfter1,
  onlineReview1,
  active,
  daysAfter,
  onlineReview,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          positiveemails: [{
              emailSequence: 1,
              active: ${active1},
              daysAfter: ${daysAfter1},
              emailReminderToLeaveOnlineReview: ${onlineReview1}
            }{
            emailSequence: 2,
            active: ${active},
            daysAfter: ${daysAfter},
            emailReminderToLeaveOnlineReview: ${onlineReview}
          }],
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setPositiveThankYou(
  locationid,
  updatedby,
  reviewtypeid,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          thanksemail: {
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}"
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingPositiveThankYou(
  locationid,
  updatedby,
  reviewtypeid,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewthanksemail: {
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}"
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setPositiveReviewSubmissionConfirmation(
  locationid,
  updatedby,
  reviewtypeid,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          positivesubmissionconfirmation: {
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}"
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingPositiveReviewSubmissionConfirmation(
  locationid,
  updatedby,
  reviewtypeid,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewpositivesubmissionconfirmation: {
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}"
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function setNegativeReviewSubmissionConfirmation(
  locationid,
  updatedby,
  reviewtypeid,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          negativesubmissionconfirmation: {
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}"
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingNegativeReviewSubmissionConfirmation(
  locationid,
  updatedby,
  reviewtypeid,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewnegativesubmissionconfirmation: {
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}"
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setNagativeApology(
  locationid,
  updatedby,
  reviewtypeid,
  active,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          apologyemail: {
            active: ${active},
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}",
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}
export function setRatingNegativeApology(
  locationid,
  updatedby,
  reviewtypeid,
  active,
  body,
  dispatch,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{createOrEditCampaignSettings(
          locationid: "${locationid}",
          updatedby: "${updatedby}",
          reviewtypeid: "${reviewtypeid}",
          reviewapologyemail: {
            active: ${active},
            body: "${body.split('\n').join('\\n').split('"').join('\\"')}",
          },
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.data?.createOrEditCampaignSettings === 1) {
        showNotification(
          Message.entityDataChangeMessage,
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export function getCampaignSettings(locationid, dispatch) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: undefined, value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `{
        campaignsettingByLocation(locationid: "${locationid}") {
        campaignsettingid,
        reviewtypeid,
        feedbackrequest { subject, body },
        reminders { reminderSequence, active, daysAfter, subject, body },
        positiveemails { emailSequence, active, daysAfter, subject, body, emailReminderToLeaveOnlineReview },
        thanksemail { body },
        apologyemail { active, from, body },
        positivelanding { showOnlineReviewLinks, includeYourRatings, includeButtonForDirectFeedbacks, body },
        negativelanding { showOnlineReviewLinks, includeYourRatings, includeButtonForDirectFeedbacks },
        positivesubmissionconfirmation { body },
        negativesubmissionconfirmation { body },
        reviewrequestemail { subject, body },
        reviewreminders { reminderSequence, active, daysAfter, subject, body },
        reviewpositiveemails { emailSequence, active, daysAfter, subject, body, emailReminderToLeaveOnlineReview },
        reviewthanksemail { body },
        reviewapologyemail { active, from, body },
        reviewpositivelanding { showOnlineReviewLinks, includeYourRatings, includeButtonForDirectFeedbacks, body },
        reviewnegativefeedbackpage,
        reviewpositivesubmissionconfirmation { body },
        reviewnegativesubmissionconfirmation { body },
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_CAMAING_SETTINGS',
        payload: res.data.campaignsettingByLocation,
      });
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: undefined, value: false },
      });
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: undefined, value: false },
      });
    });
}
export const getNPSEmailTemplate = async (locationid, dispatch) => {
  await dataRequest(config.baseURLApi, {
    query: `{
        campaignsettingByLocation(locationid: "${locationid}") {
        feedbackrequest { subject, body },
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'SET_NPS_EMAIL_TEMPLATE',
        payload: res?.data?.campaignsettingByLocation?.feedbackrequest,
      });
    });
};

export function sendTestEmail(
  subject,
  body,
  recipient,
  businessid,
  locationid,
  customerfirstname,
  customerlastname,
  companyname,
  companyaddress,
  setLimitError,
  dispatch,
  isRatingEmail,
) {
  dispatch({
    type: 'CAMAING_SETTINGS_LOADING',
    payload: { type: 'submit', value: true },
  });
  dataRequest(config.baseURLApi, {
    query: `
        mutation{sendTestEmail(
          subject: "${subject}",
          recipient: "${recipient}",
          body: "${body.split('\n').join('\\n').split('"').join('\\"')}",
          businessid :"${businessid}"
          locationid: "${locationid}"
          customername: "${customerfirstname} ${customerlastname}",
          companyname: "${companyname}",
          companyaddress:"${companyaddress}",
          isratingemail:${!!handleUndefined(isRatingEmail)}
        )}`,
  })
    .then((res) => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
      if (res?.errors && res?.errors[0]?.message.includes(limitErrorCode)) {
        setLimitError(true);
        showNotification(limitErrorMessage, 'error');
      } else if (res?.data?.sendTestEmail) {
        showNotification(
          'Email sent successfully. Please check your inbox',
          'success',
        );
      }
    })
    .catch(() => {
      dispatch({
        type: 'CAMAING_SETTINGS_LOADING',
        payload: { type: 'submit', value: false },
      });
    });
}

export const getBrandInfo = async (id, dispatch) => {
  if (handleUndefined(id)) {
    await dataRequest(config.baseURLApi, {
      query: `{
        brandSettingByLocation(locationid: "${handleUndefined(id)}") {
          brandsettingid,
          logourl,
          color,
          timeformat,
          dateformat,
          locationid,
          createdby,
          updatedby,
      }}`,
    })
      .then((res) => {
        dispatch({
          type: 'SET_BRAND_DETAILS',
          payload: res.data.brandSettingByLocation,
        });
      })
      .catch(() => { });
  }
};

export const getAllReviewTypes = async (dispatch) => {
  dataRequest(config.baseURLApi, {
    query: `{
        reviewtypes {
          reviewtypeid,
          displaytext,
          icon,
          desc,
          createdby,
          updatedby,
          communicationtypeid,
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_ALL_REVIEW_TYPES',
        payload: res.data.reviewtypes,
      });
    })
    .catch(() => { });
};

export const getAllCommunicationTypes = async (dispatch) => {
  dataRequest(config.baseURLApi, {
    query: `{
        communicationtypes {
          communicationtypeid,
          displaytext,
          icon,
          desc,
      }}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_ALL_COMMUNICATION_TYPES',
        payload: res.data.communicationtypes,
      });
    })
    .catch(() => { });
};

export function removeCampagin(id, dispatch) {
  dataRequest(config.baseURLApi, {
    query: `mutation{ deleteCampaign(campaignid: "${id}") }`,
  })
    .then(() => {
      dispatch({ type: 'REMOVE_CAMPAGIN', payload: id });
    })
    .catch(() => { });
}
export function removeEmailCampagin(id, dispatch) {
  dataRequest(config.baseURLApi, {
    query: `mutation{ deleteEmailCampaign(emailcampaignid: "${id}") }`,
  })
    .then(() => {
      dispatch({ type: 'REMOVE_EMAILCAMPAGIN', payload: id });
    })
    .catch(() => { });
}

export function removeContectMarketing(id, dispatch) {
  dataRequest(config.baseURLApi, {
    query: `mutation{ deleteContentMarketing(contentmarketingnid: "${id}") }`,
  })
    .then(() => {
      dispatch({ type: 'REMOVE_CONTECTMARKETING', payload: id });
      showNotification('Data Deleted Successfully', 'success');
    })
    .catch(() => { });
}

export function removeNewsLetter(id, dispatch) {
  dataRequest(config.baseURLApi, {
    query: DeleteNewsletterEmail,
    variables: {
      newsletteremailid: id,
    },
  })
    .then(() => {
      dispatch({ type: 'REMOVE_NEWSLETTER', payload: id });
      showNotification('Data Deleted Successfully', 'success');
    })
    .catch(() => { });
}

export function removeTextEmailCampagin(id, dispatch) {
  dataRequest(config.baseURLApi, {
    query: `mutation{ deleteSmsCampaign(smscampaignid: "${id}") }`,
  })
    .then(() => {
      dispatch({ type: 'REMOVE_TEXTEMAILCAMPAGIN', payload: id });
    })
    .catch(() => { });
}

const setCampaginTitle = (value, dispatch) => {
  dispatch({ type: 'SET_CAMPAGIN_TITLE', payload: value });
};

const setEmailCampaginTitle = (value, dispatch) => {
  dispatch({ type: 'SET_EMAILCAMPAGIN_TITLE', payload: value });
};

const setTextCampaginTitle = (value, dispatch) => {
  dispatch({ type: 'SET_TEXTCAMPAGIN_TITLE', payload: value });
};
const setContectMarketingTitle = (value, dispatch) => {
  dispatch({ type: 'SET_CONTACTMARKETING_TITLE', payload: value });
};
const setTextBodyCampagin = (value, dispatch) => {
  dispatch({ type: 'SET_TEXTBODYCAMPAGIN', payload: value });
};
const setTextBodyLinkCampagin = (value, dispatch) => {
  dispatch({ type: 'SET_TEXTBODYLINKCAMPAGIN', payload: value });
};
const setTextBodyFileCampagin = (value, dispatch) => {
  dispatch({ type: 'SET_TEXTBODYFILECAMPAGIN', payload: value });
};
const setThankYouMessage = (type, value, dispatch) => {
  dispatch({ type: 'SET_THANK_YOU_MESSAGE', payload: { type, value } });
};
const setRatingThankYouMessage = (type, value, dispatch) => {
  dispatch({ type: 'SET_RATING_THANK_YOU_MESSAGE', payload: { type, value } });
};

const setNPSSubject = (value, dispatch) => {
  dispatch({ type: 'SET_NPS_SUBJECT', payload: value });
};

const setNewsLetterSubject = (value, dispatch) => {
  dispatch({ type: 'SET_NEWSLETTER_SUBJECT', payload: value });
};

const setReviewType = (value, id, dispatch) => {
  dispatch({ type: 'SET_REVIEW_TYPE', payload: { type: value, id } });
};

const setCommunicationType = (value, id, dispatch) => {
  dispatch({ type: 'SET_COMMUNICATION_TYPE', payload: { type: value, id } });
};

const setSelectedCustomers = (value, dispatch) => {
  dispatch({ type: 'SELECTED_CUSTOMERS', payload: value });
};

const setSelectedCustomersEdit = (value, dispatch) => {
  dispatch({ type: 'SELECTED_CUSTOMERSEDIT', payload: value });
};

const setPositiveReviewMessage = (value, dispatch) => {
  dispatch({ type: 'SET_POSITIVE_REVIEW_MESSAGE', payload: value });
};

const setPositiveReviewTitle = (value, dispatch) => {
  dispatch({ type: 'SET_POSITIVE_REVIEW_TITLE', payload: value });
};

const setPositiveSettingsSwitch = (type, value, dispatch) => {
  dispatch({ type: 'SET_POSITIVE_SETTINGS_SWITCH', payload: { type, value } });
};
const setRatingPositiveSettingsSwitch = (type, value, dispatch) => {
  dispatch({ type: 'SET_RATING_POSITIVE_SETTINGS_SWITCH', payload: { type, value } });
};

const setCampaginLoading = (type, dispatch) => {
  dispatch({ type: 'CAMAING_LOADING', payload: type });
};

const setNegativeSettingsSwitch = (type, value, dispatch) => {
  dispatch({ type: 'SET_NEGATIVE_SETTINGS_SWITCH', payload: { type, value } });
};
const setRatingNegativeSettingsSwitch = (type, value, dispatch) => {
  dispatch({ type: 'SET_RATING_NEGATIVE_SETTINGS_SWITCH', payload: { type, value } });
};

const setTemplateSubject = (type, value, dispatch) => {
  dispatch({ type: 'SET_TEMPLATE_SUBJECT', payload: { type, value } });
};
const setRatingTemplateSubject = (type, value, dispatch) => {
  dispatch({ type: 'SET_RATING_TEMPLATE_SUBJECT', payload: { type, value } });
};

const setTemplateBody = (type, value, dispatch) => {
  dispatch({ type: 'SET_TEMPLATE_BODY', payload: { type, value } });
};
const setRatingTemplateBody = (type, value, dispatch) => {
  dispatch({ type: 'SET_RATING_TEMPLATE_BODY', payload: { type, value } });
};

const setFeedbackRequestSettingsSwitch = (type, value, dispatch) => {
  dispatch({
    type: 'SET_FEEDBACK_REQUEST_SETTINGS_SWITCH',
    payload: { type, value },
  });
};
const setRatingRequestSettingsSwitch = (type, value, dispatch) => {
  dispatch({
    type: 'SET_RATING_REQUEST_SETTINGS_SWITCH',
    payload: { type, value },
  });
};

const handleNagativeApology = (type, value, dispatch) => {
  dispatch({ type: 'SET_NAGATIVE_APOLOGY', payload: { type, value } });
};
const handleRatingNagativeApology = (type, value, dispatch) => {
  dispatch({ type: 'SET_RATING_NAGATIVE_APOLOGY', payload: { type, value } });
};

const setRemindersTiming = (type, value, dispatch) => {
  dispatch({ type: 'SET_REMINDERS_TIMING', payload: { type, value } });
};
const setRatingRemindersTiming = (type, value, dispatch) => {
  dispatch({ type: 'SET_RATING_REMINDERS_TIMING', payload: { type, value } });
};

const setNPSBody = (value, dispatch) => {
  dispatch({ type: 'SET_NPS_BODY', payload: value });
};
const setEmailTemplateDesign = (value, dispatch) => {
  dispatch({ type: 'SET_EMAILCAMPAGIN_TEMPLATEDESIGN', payload: value });
};
const setEmailTemplate = (value, dispatch) => {
  dispatch({ type: 'SET_EMAILCAMPAGIN_TEMPLATE', payload: value });
};

const setNewsLetterEmailTemplate = (value, dispatch) => {
  dispatch({ type: 'SET_NEWSLETTER_TEMPLATE', payload: value });
};
const setContectTemplate = (value, dispatch) => {
  dispatch({ type: 'SET_CONTECTCAMPAGIN_TEMPLATE', payload: value });
};
const setEmailCampaignFromEmail = (value, dispatch) => {
  dispatch({ type: 'SET_EMAILCAMPAGIN_FROMEMAIL', payload: value });
};
const setEmailCampaignFromName = (value, dispatch) => {
  dispatch({ type: 'SET_EMAILCAMPAGIN_FROMNAME', payload: value });
};
const setScheduleCampaign = (value, dispatch) => {
  dispatch({ type: 'SET_SCHEDULE_CAMPAIGN', payload: value });
};
const setScheduleEmailCampaign = (value, dispatch) => {
  dispatch({ type: 'SET_SCHEDULE_EMAIL_CAMPAIGN', payload: value });
};

const setScheduleDateTimeCampaign = (value, dispatch) => {
  dispatch({ type: 'SET_SCHEDULEDATETIME_CAMPAIGN', payload: value });
};

const setCampaginId = (id, dispatch) => {
  dispatch({ type: 'SET_CAMPAGIN_ID', payload: id });
};
const setEmailCampaginId = (id, dispatch) => {
  dispatch({ type: 'SET_EMAILCAMPAGIN_ID', payload: id });
};
const setNewsLetterId = (id, dispatch) => {
  dispatch({ type: 'SET_NEWSLETTER_ID', payload: id });
};
const setContectMarketingId = (id, dispatch) => {
  dispatch({ type: 'SET_CONTECTMARKETING_ID', payload: id });
};
const setTextCampaginId = (id, dispatch) => {
  dispatch({ type: 'SET_TEXTCAMPAGIN_ID', payload: id });
};

export {
  CampaginProvider,
  setCampaginTitle,
  setContectTemplate,
  setContectMarketingId,
  setContectMarketingTitle,
  setTextCampaginTitle,
  setEmailCampaginId,
  setTextBodyLinkCampagin,
  setTextBodyCampagin,
  setTextCampaginId,
  setScheduleEmailCampaign,
  setScheduleDateTimeCampaign,
  CampaginContext,
  setEmailTemplate,
  setEmailTemplateDesign,
  setEmailCampaignFromEmail,
  setEmailCampaignFromName,
  useCampaginState,
  setScheduleCampaign,
  setReviewType,
  setTextBodyFileCampagin,
  setCommunicationType,
  setSelectedCustomers,
  setSelectedCustomersEdit,
  setCampaginId,
  setTemplateBody,
  setEmailCampaginTitle,
  setRatingTemplateBody,
  setPositiveReviewTitle,
  setPositiveSettingsSwitch,
  setRatingPositiveSettingsSwitch,
  setNPSSubject,
  setPositiveReviewMessage,
  setNegativeSettingsSwitch,
  setRatingNegativeSettingsSwitch,
  setTemplateSubject,
  setRatingTemplateSubject,
  setFeedbackRequestSettingsSwitch,
  setRatingRequestSettingsSwitch,
  handleNagativeApology,
  handleRatingNagativeApology,
  setRemindersTiming,
  setRatingRemindersTiming,
  setThankYouMessage,
  setRatingThankYouMessage,
  setNPSBody,
  setCampaginLoading,
  setNewsLetterId,
  setNewsLetterEmailTemplate,
  setNewsLetterSubject,
};
