import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  snackbarStyleViaContentProps: {
    backgroundColor: '#42ba96',
    cursor: 'pointer',
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    flexGrow: 1,
    width: '100%',
  },
  Widgetup: {
    margin: '0 auto',
    marginTop: 20,
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  forgotGrid: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  forgotBixLogo: {
    display: 'none',
    height: 'auto',
    width: 250,
    borderRadius: 0,
    margin: '50px 0',
  },
  WidgetTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '30px',
    letterSpacing: '0.05em',
    color: '#004482',
    margin: '5px 0',
  },
  WidgetSubTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '140%',
    color: '#004482',
    margin: '15px 0',
  },
  logo: {
    height: 50,
    display: 'none',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 30,
      marginLeft: theme.spacing(2),
      display: 'none',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
    },
  },
  textarea: {
    maxWidth: '100%',
    width: '100%',
  },
  margin: {
    marginBottom: 24,
  },

  typograp: {
    marginLeft: theme.spacing(40),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 530,
    maxHeight: 500,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  field: {
    width: '100%',
  },
  PhoneInput: {
    width: '40%',
    marginLeft: '6%',

    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  dropDown: {
    fontSize: 17,
    color: '#6E6E6E',
    padding: '8px 0 6px',
    width: '100%',
    marginTop: '11px',
    fontStyle: 'transparent',
    borderLeft: 'hidden',
    borderRight: 'hidden',
    borderTop: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  buttonSave: {
    marginTop: 10,
  },
  buttonBack: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'right',
      fontSize: 8,
      width: 'auto',
    },
  },
  componentHeading: {
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 10,
  },
  backMenuLink: {
    fontSize: 17,
    marginTop: 10,
    alignItems: 'center',
    color: '#004482',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignStandaloneElement: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableWidget: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: '100%',
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: `${theme.palette.text.hint}80 !important`,
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: '14px !important',
    marginLeft: theme.spacing(1),
  },
  table: {
    minWidth: 550,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  fixIconRight: {
    '& .MuiSelect-icon': {
      right: 4,
    },
  },
  checkboxColor: {
    color: 'rgb(185, 185, 185)',
  },
}));
