import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableTitle: {
    marginTop: '1.5rem',
    padding: 3,
    paddingLeft: 13,
    marginBottom: '1rem',
  },
  userContainer: {
    marginTop: '1.5rem',
    padding: 13,
  },
  ticketTitle: {
    margin: '1.5rem',
    padding: 10,
    [theme.breakpoints.up('lg')]: {
      width: '75%',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  titleText: {
    fontWeight: 600,
    fontSize: 20,
    color: '#005eb2',
  },
  hrStyle: {
    backgroundColor: '#005eb2',
    height: 1,
  },
  assigneeHr: {
    backgroundColor: '#0c7ee5',
    height: '0.8px',
  },
  commonPadding: {
    padding: 10,
  },
  gridContainer: {
    width: '100%',
    padding: '10px',
  },
  fieldBox: {
    display: 'flex',
    alignItems: 'start',
    gap: '2px',
    marginBottom: 10,
    flexDirection: 'column',
  },
  btnBox: {
    padding: 10,
    textAlign: 'end',
  },
  ticketBtn: {
    height: 30,
  },
  divider: {
    height: '1.2px',
    backgroundColor: '#005eb2',
    width: '97%',
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  featureBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  themeIcon: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  btnSubmit: {
    height: '30px !important',
    borderRadius: '6px !important',
    boxShadow: '0px 0px 2px 1px #005eb2 !important',
    width: 102,
    display: 'flex',
    fontSize: 13,
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    fontWeight: 600,
    color: '#005eb2',
    marginRight: 10,
    '&:hover': {
      textDecoration: 'none !important',
    }
  },
  copyText: {
    borderRadius: 5,
    padding: '10px 13px',
    marginTop: '1rem',
    backgroundColor: '#cbcbcb',
    color: '#4A4A4A',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 6,
    paddingRight: 15,
  },
  grid: {
    display: 'flex',
    alignItems: 'cente',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  gridItem1: {
    width: '70%',
    height: '90vh',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
    },
  },
  gridItem2: {
    width: '30%',
    height: '90vh',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
    },
  },
  paperTitle: {
    padding: 3,
    paddingLeft: 13,
  },
  wrapper: {
    border: '1px solid #005eb2',
    borderRadius: '5px',
    padding: 5,
    minHeight: 330,
  },
  subjectLink: {
    color: '#005eb2',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  ticketHead: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    height: 80,
    boxShadow: '1px 0px 6px 1px gray',
  },
  ticketHeadText: {
    fontSize: 20,
    fontWeight: 600,
    color: 'black',
    marginLeft: 10,
  },
  requester: {
    fontSize: 20,
  },
  mainGrid: {
    marginTop: 11,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    gap: 10,
    [theme.breakpoints.down('md')]: {
      height: '100%',
      flexDirection: 'column-reverse',
    },
  },
  grid1: {
    width: '77%',
    backgroundColor: '#e2e4f1',
    borderRadius: 10,
    padding: 10,
    [theme.breakpoints.down('md')]: {
      width: '96%',
      margin: 'auto',
      marginBottom: 10,
    },
  },
  grid2: {
    width: '20%',
    backgroundColor: '#e2e4f1',
    borderRadius: 10,
    padding: 10,
    [theme.breakpoints.down('md')]: {
      width: '96%',
      margin: 'auto',
    },
  },
  msgBox: {
    width: '100%',
    height: 407,
    marginBottom: 5,
    paddingRight: 8,
    overflow: 'auto',
  },
  msgWrapper: {
    width: '100%',
  },
  replyBox: {
    backgroundColor: 'white',
    borderRadius: 10,
    border: '1px solid #ccc4c4fa',
  },
  msg: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    marginRight: 0,
    gap: 7,
    backgroundColor: 'white',
    borderRadius: 6,
    color: 'black',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  msgStartWrapper: {
    color: 'white',
    padding: 10,
    width: '100%',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'start',
    gap: 7,
  },
  reply: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    marginRight: 0,
    gap: 7,
    backgroundColor: '#9ca8f6',
    borderRadius: 6,
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  msgText: {
    backgroundColor: 'white',
    padding: 10,
    width: '100%',
    borderRadius: 6,
  },
  replyText: {
    color: 'white',
    padding: 10,
    width: '100%',
    borderRadius: 6,
  },
  replyWrapper: {
    color: 'white',
    padding: 10,
    width: '100%',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'end',
    gap: 7,
  },
  attachmentBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  attachmentTextBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '96%',
    margin: 'auto',
  },
  Dropzone: {
    height: '150px',
    marginBottom: '30px',
    textAlign: 'center',
    padding: '17px',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
    border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
    [theme.breakpoints.down('md')]: {
      width: 250,
      textAlign: 'center',
      padding: '17px',
      backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
      color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
      border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
      marginBottom: 5,
    },
  },
  UploadDrop: {
    height: '120px',
    marginBottom: 20,
    textAlign: 'center',
    padding: '8px',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
    border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
  },
  alignContant: {
    display: 'flex',
    alignItems: 'center',
  },
  assignName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 3,
    marginTop: '1rem',
  },
  avatar: {
    backgroundColor: 'white',
    // color: '#7283f2',
    color: '#9ca8f6',
    fontWeight: 600,
  },
  userAvatar: {
    color: 'white',
    // backgroundColor: '#7283f2',
    backgroundColor: '#9ca8f6',
    fontWeight: 600,
    fontSize: 17,
    width: 35,
    height: 35,
  },
  adminAvatar: {
    // color: '#7283f2',
    color: '#9ca8f6',
    backgroundColor: 'white',
    fontWeight: 600,
    fontSize: 17,
    width: 35,
    height: 35,
  },
  userName: {
    borderBottom: '1px soild greay',
    fontSize: 18,
  },
  nameHr: {
    height: 1,
    width: '60%',
    // backgroundColor: '#7283f2',
    backgroundColor: '#9ca8f6',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
  },
  featureBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 5,
  },
  sendBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#005eb2',
    padding: 5,
    '&:hover': {
      backgroundColor: '#004578 !important',
      color: 'white !important',
    },
  },
  sendIcon: {
    color: 'white',
    fontSize: 18,
  },
  detailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  detailBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailHead: {
    fontWeight: 600,
    color: '#4e4e4e',
    padding: 10,
    paddingRight: 5,
  },
  detailText: {
    fontWeight: 400,
    color: '#4e4e4e',
  },
  assigneeBox: {
    fontWeight: 400,
    color: '#4e4e4e',
    marginTop: 13,
  },
  historyBox: {
    backgroundColor: 'white',
    margin: 5,
    borderRadius: 10,
    border: '1px solid #ccc4c4fa',
    overflow: 'auto',
    height: 250,
  },
  listItemText: {
    margin: 0,
    marginLeft: 9,
    marginTop: 3,
  },
  iconBtn: {
    padding: '15px 6px',
  },
  featureBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    marginRight: 10,
    margin: '2px 0px',
  },
  iconBtnBox: {
    display: 'flex',
    alignItems: 'center',
  },
  renderA: {
    display: 'block',
    margin: '5px',
    border: '1px solid #ccc4c4fa',
    borderRadius: 5,
    boxShadow: '0px 0px 1px 1px grey',
    width: '99%',
    textAlign: 'center',
    padding: '5px',
    textDecoration: 'none',
    backgroundColor: 'white',
    color: 'black',
  },
  renderEmbed: {
    marginLeft: '20px',
    borderRadius: 5,
  },
  renderImg: {
    width: '95%',
    height: 'auto',
    margin: '10px',
    borderRadius: 5,
    [theme.breakpoints.up('lg')]: {
      width: '50% !important',
    },
  },
  searchField: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  attachments: {
    borderTop: '1px solid #ccc4c4fa',
    padding: 10,
  },
  attachmentHeading: {
    fontSize: 16,
    borderBottom: '1px solid #005eb2',
    marginBottom: 4,
  },
  renderTag: {
    width: '100%',
    textAlign: 'center',
    alignContent: 'center',
    border: '1px solid #ccc9c9fa',
    marginBottom: 9,
    borderRadius: 4,
    padding: 2,
  },
  fileText: {
    textAlign: 'center',
    alignContent: 'center',
    width: '100%',
    textDecoration: 'none',
    ":hover": {
      textDecoration: 'none',
    },
  },
  renderItem: {
    alignContent: 'center',
  },
  renderAttachEmbed: {
    width: '100%',
    height: 'auto',
    borderRadius: 5,
  },
  renderAttachImg: {
    width: '89%',
    height: 'auto',
    margin: '10px',
    borderRadius: 5,
  },
  renderAttachmentText: {
    display: 'block',
    borderRadius: 5,
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    textDecoration: 'none',
    backgroundColor: 'white',
    color: 'black',
    height: '90%',
    alignContent: 'center',
  },
  renderBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 10,
    backgroundColor: '#f5f5fb',
    border: '1px solid #ccc4c4fa',
    position: 'relative',
  },
  removeBtn: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    color: '#4a4a4a',
  },
  removeBtnIcon: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#4a4a4a',
  },
  ticketThanksTitle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '75%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  thanksMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    padding: 7,
  },
  mainSpan: {
    fontWeight: 600,
    marginTop: 10,
  },
  submitIcon: {
    fontSize: 50,
    color: 'green',
  },
  filterValues: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    gap: 5,
  },
  multilineField: {
    border: 'none',
    padding: 10,
    width: '100%',
    resize: 'none',
    borderRadius: 10,
    paddingBottom: 4,
    fontWeight: 'bold',
    fontSize: 14,
  },
  disableBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'lightgrey !important',
    padding: 5,
  },
  disableIcon: {
    color: 'lightgrey',
  },
}));
