export const ReviewsAnalyticsByLocation = `query ReviewsAnalyticsByLocation($locationid: ID!, $ispublic: Boolean) {
  reviewsAnalyticsByLocation(locationid: $locationid, ispublic: $ispublic) {
    reviewanalyticid
    reportdata
    locationid
    ispublic
    createdby
  }
}`;
export const GetReviewsWithCustomer = `query GetReviewsWithCustomer($locationid: ID!, $status: [Int!], $displayinwidget: Boolean) {
  getReviewsWithCustomer(locationid: $locationid, status: $status, displayinwidget: $displayinwidget) {
    title
    tags
    status
    sourcereviewid
    sourceid
    sharedcount
    reviewtype
    reviewstatusbyreplied
    reviewreplyid
    reviewid
    reviewer {
      profilePhotoUrl
      displayName
    }
    reviewdatetime
    reviewaction
    review
    replytext
    replydate
    replied
    rating
    providedby
    placeid
    likecount
    phonenumber
    lastname
    firstname
    emailcode
    email
    displayinwidget
    createdAt
    contactavatar
    communicationtype
  }
}`;

export const GetReviewsWithCustomerPublic = `query getReviewsWithCustomerPublic(
            $locationid:ID!,
            $minrating:Int,
            $maxrating:Int,
            $startDate: String,
            $endDate: String,
            $sortorder: String,
    ){
    getReviewsWithCustomerPublic(
        locationid:$locationid,
        minrating:$minrating,
        maxrating:$maxrating,
        startDate:$startDate,
        endDate:$endDate,
        sortorder:$sortorder,
    ){
      review
      placeid
      campaignid
      customerid
      title
      status
      reviewdatetime
      reviewreplyid
      providedby
      rating
      isreported
      replydate
      replytext
      locationid
      displayinwidget
      reviewid
      sourcereviewid
      replied
      deleted
      contactavatar
      sourceid
      createdby
      sharedcount
      likecount
      firstname
      communicationtype
      reviewstatusbyreplied
      reviewtype
      lastname
      reviewer{ displayName, profilePhotoUrl }
      email
      jobid
      tags
      phonenumber
    }}`;
