import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '60vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'black',
  },
  wrapper: {
    padding: '5px',
    margin: theme.spacing(1),
    position: 'relative',
  },
  themeText: {
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
  },
  fabProgress: {
    color: '#005EB2',
    position: 'relative',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loadingLogo: {
    height: '55px',
    width: '55px',
    display: 'none',
  },
}));
