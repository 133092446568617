/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  AssignmentTurnedInOutlined,
  Person as AccountIcon,
  PersonAddOutlined,
  PhonelinkLockOutlined,
  Security as SecurityIcon,
  VerifiedUser as VerifiedUserIcon,
  Brightness4,
  LocalAtm,
  Brightness6,
} from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import moment from 'moment-timezone';
import {
  AppBar, Toolbar, IconButton, Menu, MenuItem, Button, Tabs,
  Typography, DialogActions, Box, Tab, ListItemSecondaryAction,
  Tooltip, List, ListItem, ListItemText, Divider, Avatar as AvatarMui, Checkbox,
} from '@material-ui/core';
import {
  useSubscription, gql,
} from '@apollo/client';
import { useTheme } from '@material-ui/styles';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Snackbar from '@material-ui/core/Snackbar';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import { LoadingPage } from '../../pages/LoadingPage/LoadingPage';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import useStyles from './styles';
import { Avatar } from '../Wrappers/Wrappers';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  togglePermanent,
  setLocationModal,
} from '../../context/LayoutContext';
import {
  useGlobalState,
  setLoginUserInfo,
  getCurrentLocationInfo,
} from '../../context/GlobalContext';
import {
  useUserDispatch,
  useUserState,
  signOut,
} from '../../context/UserContext';
import {
  useEntitiesState,
  getLocations,
  getBusinessData,
  getAgencyByUser,
  getBusinessByUser,
} from '../../context/EntityContext';
import { showNotification } from '../Notification/ToastifyNotifications';
import { BixFavIcon, EntityType, GlobeIcon, pathValidation, Roles } from '../../common/const';
import { useThemeDispatch } from '../../context/ThemeContext';
import Themes from '../../themes';
import { Entity, EntityPolicy } from '../../Api Mutation/EntityApi';
import Refercode from './ReferCodeDialog';
import Location from './Location';
import BusinessFavicon from '../../common/BusinessFavicon';

const SvgStyle = {
  color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  '@media only screen and(maxWidth: 1420px)': {
    width: 20,
  },
};

const iconStyle = {
  color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  width: '20px',
  height: '20px',
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { isMasterUser, history, locationSeletion } = props;
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const entitiesState = useEntitiesState();
  const [dataLoading, setDataLoading] = useState(false);
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();
  const [iconMenu, setIconMenu] = useState(false);
  const [openRefer, setOpenRefer] = useState(false);
  const [isSmall, setSmall] = useState(false);
  const businessData = JSON.parse(localStorage.getItem("business_Data"));
  const toggleState = localStorage.getItem("toggleSidebar");

  const handleClickMenu = (event) => {
    setIconMenu(event.currentTarget);
  };

  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;
    setSmall(isSmallScreen);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const [profileMenu, setProfileMenu] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [privacyUrl, setPrivacyUrl] = useState(null);
  const [termUrl, setTermUrl] = useState(null);
  const [whiteLabelStatus, setWhiteLabelStatus] = useState(null);
  const { userInfo } = useUserState();
  const [notificationData, setNotificationData] = useState(null);
  const locationId = JSON.parse(localStorage.getItem('location_Id'));
  const [anchorEl, setAnchorEl] = useState(false);

  const [notificationDataCount, setNotificationDataCount] = useState([]);
  const [contactTask, setContactTask] = useState();
  const [contactAppointment, setContactAppointment] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [appMenuOpen, setAppMenuOpen] = useState(false);

  const businessId = handleUndefined(JSON.parse(localStorage?.getItem('business_Id'))) || (entitiesState?.entities?.businessData
    && entitiesState?.entities?.businessData[0]?.entityid);
  const themeDispatch = useThemeDispatch();

  const handleCloseNotification = () => {
    setAnchorEl(null);
  };

  const getNotificationData = () => {
    if (locationId) {
      postDataRequest(config.baseURLApi, {
        query: `query notificationsbylocation($locationid: ID!){
          notificationsbylocation(locationid: $locationid){
            notificationid
            locationid
            objectid
            userid
            contactid
            contactname
            username
            title
            subtitle
            status
            createdby
            updatedby
            createdAt
            updateAt
          }
                }`,
        variables: {
          locationid: locationId,
        },
      }).then((res) => {
        if (res?.data?.notificationsbylocation) {
          setNotificationData(res?.data?.notificationsbylocation);
          setNotificationDataCount(res.data.notificationsbylocation.filter(
            (item) => item.status === 1,
          ));
        }
      });
    }
  };

  const getTask = () => {
    if (locationId && JSON.parse(localStorage.getItem('userid'))) {
      postDataRequest(config.baseURLApi, {
        query: `query contacttasksreportbylocation(
          $locationid: ID!, $userids: [ID!], $startdate: String, $enddate: String, $timezone: String
        ){
          contacttasksreportbylocation(locationid: $locationid, userids: $userids, startdate: $startdate, enddate: $enddate, timezone: $timezone){
            todaytask,lastfivedaystask, pasttask}}`,
        variables: {
          locationid: locationId,
          userids: [JSON.parse(localStorage.getItem('userid'))],
        },
      }).then((res) => {
        if (res?.data?.contacttasksreportbylocation) {
          setContactTask(res?.data?.contacttasksreportbylocation);
        }
      });
      postDataRequest(config.baseURLApi, {
        query: `query eventbookreportbylocation(
          $locationid: ID!,
          $ownerid: ID!,
          $currentDate: String,
          $listtype: Int,
          ){
          eventbookreportbylocation(
            locationid: $locationid
            ownerid:$ownerid,
            currentDate:$currentDate,
            listtype:$listtype
            ){
            todayevent,lastfivedaysevent,pastevent}}`,
        variables: {
          locationid: locationId,
          ownerid: JSON.parse(localStorage.getItem('userid')),
          currentDate: new Date(),
          listtype: 2,
        },
      }).then((res) => {
        if (res?.data?.eventbookreportbylocation) {
          setContactAppointment(res?.data?.eventbookreportbylocation);
        }
      });
    }
  };

  const notificationStatus = () => {
    if (locationId) {
      const NOTIFICATION_MUTATION = `
      mutation markareadnotifications($locationid: ID!,$status: Int!,$updatedby: ID!)
        {
          markareadnotifications(locationid:$locationid, status:$status, updatedby:$updatedby)
        }`;
      postDataRequest(config.baseURLApi, {
        query: NOTIFICATION_MUTATION,
        variables: {
          locationid: locationId,
          status: 2,
          updatedby: JSON.parse(localStorage.getItem('userid')),
        },
      }).then((res) => {
        if (res.data.markareadnotifications) {
          getNotificationData();
        }
      });
    }
  };

  const updateNotification = (id) => {
    if (id) {
      const NOTIFICATION_MUTATION = `
      mutation editnotification( 
          $notificationid: ID!
          $locationid: ID!
          $objectid: ID
          $userid: ID
          $contactid: ID
          $contactname: String
          $username: String
          $title: String
          $subtitle: String
          $status: Int
          $updatedby: ID!
        ){
          editnotification(
            notificationid:$notificationid
            locationid:$locationid
            objectid:$objectid
            userid:$userid
            contactid:$contactid
            contactname:$contactname
            username:$username
            title:$title
            subtitle:$subtitle
            status:$status
            updatedby:$updatedby
          )
        }`;
      postDataRequest(config.baseURLApi, {
        query: NOTIFICATION_MUTATION,
        variables: {
          notificationid: id,
          locationid: locationId,
          status: 2,
          updatedby: JSON.parse(localStorage.getItem('userid')),
        },
      }).then((res) => {
        if (res.data.editnotification) {
          getNotificationData();
        }
      });
    }
  };

  const handleClickNotification = (event) => {
    setAnchorEl(event?.currentTarget);
    getNotificationData();
  };

  const [open, setOpen] = React.useState(false);
  const [logoUrl, setLogoUrl] = useState();
  const [locationLogo, setLocationLogo] = useState();
  const handleClickOpen = () => {
    setOpen(false);
    signOut(userDispatch, props?.history);
  };
  const [openBusiness, setOpenBusiness] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const BackIconStyle = {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLocation = () => {
    setOpenLocation(false);
  };
  const handleCloseBusiness = () => {
    setOpenBusiness(false);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && userInfo?.roleid) {
      if (userInfo?.roleid === Roles?.AgencyManager || userInfo?.roleid === Roles?.AgencyAdmin) {
        getAgencyByUser(entitiesState.setEntities);
      }
      if (userInfo?.roleid === Roles?.BusinessAdmin
        || userInfo?.roleid === Roles?.BusinessManager) {
        getBusinessByUser(entitiesState.setEntities);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [userInfo?.roleid]);

  const [bar, setBar] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (businessId) {
        postDataRequest(config.baseURLApi, {
          query: `query brandSettingByLocation($locationid:ID!)
            {brandSettingByLocation(locationid: $locationid){brandsettingid,color,dateformat,timeformat,logourl}}
          `,
          variables: {
            locationid: businessId,
          },
        })
          .then((res) => {
            setLogoUrl(res?.data?.brandSettingByLocation?.logourl);
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [businessId]);

  const talkToSales = () => {
    if (businessId) {
      return postDataRequest(
        `${config.payment}v1/payments/contact-to-sales`,
        {
          businessid: businessId,
        },
      )
        .then((res) => {
          if (res) {
            showNotification('Your mail has been Successfully Sent to the Bixpand Support. Contact you Soon', 'success');
          }
        });
    }
    return '';
  };

  const NOTIFICATION = gql`
  subscription newNotifications($locationid: ID!){
    newNotifications(locationid:$locationid){ 
      notificationid,locationid,objectid,userid,contactid,contactname,username,title,subtitle,status,
      createdby,updatedby,createdAt,updateAt
 }}`;

  const dataNotification = useSubscription(
    NOTIFICATION,
    { variables: { locationid: locationId } },
  );

  const toggleDarkTheme = () => {
    if (localStorage.getItem('theme') === 'dark') {
      localStorage.setItem('theme', 'default');
      themeDispatch(Themes.default);
    } else {
      localStorage.setItem('theme', 'dark');
      themeDispatch(Themes.dark);
    }
  };

  const handleAddMenuOpen = (e) => {
    setMenuOpen(e.currentTarget);
  };
  const handleAppMenuOpen = (e) => {
    setAppMenuOpen(e.currentTarget);
  };
  const handleAddMenuClose = () => {
    setMenuOpen(false);
  };
  const handleAppMenuClose = () => {
    setAppMenuOpen(false);
  };
  const getEntityData = (entityId) => {
    if (entityId) {
      setDataLoading(true);
      postDataRequest(config.baseURLApi, {
        query: EntityPolicy,
        variables: { entityid: entityId },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message);
          setDataLoading(false);
        } else if (res?.data?.entity?.whitelabeltype === 'full') {
          setPrivacyUrl(res?.data?.entity?.policy);
          setTermUrl(res?.data?.entity?.terms);
          setWhiteLabelStatus(res?.data?.entity?.whitelabeltype);
          setDataLoading(false);
        }
      });
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (businessId) {
        getEntityData(businessId);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [businessId]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {  
      getTask();
      if (businessId && (JSON.parse(localStorage.getItem('popup')) === true || JSON.parse(localStorage.getItem('bar')) === true)) {
        postDataRequest(config.baseURLApi, {
          query: `query planavailablebalance($businessid:ID!){
            planavailablebalance(businessid: $businessid){
          businessid,trialplanid,curruntplanid,userslimit,textsmslimit,emaillimit,storagelimit,
          calltimelimit,textsms,users,email,storage,calltime,trialplanenddate,startdate,enddate, leadgathering, leadgatheringlimit,
            }
                  }`,
          variables: {
            businessid: businessId,
          },
        }).then((res) => {
          if ((res?.data?.planavailablebalance?.emaillimit
            - res?.data?.planavailablebalance?.email < 50)
            || (res?.data?.planavailablebalance?.textsmslimit
              - res?.data?.planavailablebalance?.textsms < 5
            )) {
            localStorage.setItem('bar', true);
            setBar(false);
          }
        });
      }
      getBusinessData(EntityType.Business, entitiesState.setEntities);
      getLocations(EntityType.Location, entitiesState.setEntities);
      setLoginUserInfo(globalContext.setGlobalState);
      if (!globalContext.globalState.currentLocation.entityid && localStorage.getItem('location_Id')) {
        if (handleUndefined(locationId)) {
          getCurrentLocationInfo(locationId, globalContext.setGlobalState);
        }
      }
      if (localStorage.getItem('userid')) {
        postDataRequest(config.baseURLApi, {
          query: `
                query userprofilebyuser($userId:ID!){userprofilebyuser(userid:$userId) {
                  userprofileid,
                  firstname,
                  lastname,
                  phonenumber,
                  profiletype,
               avatar
                }}`,
          variables: {
            userId: JSON.parse(localStorage.getItem('userid')),
          },
        })
          .then((res) => {
            setProfileData(res?.data?.userprofilebyuser);
          });
      }
      if (dataNotification) {
        getNotificationData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (locationId !== null) {
        setDataLoading(true);
        postDataRequest(config.baseURLApi, {
          query: Entity,
          variables: { entityid: locationId },
        }).then((res) => {
          setLocationLogo(res?.data?.entity?.logourl);
          setDataLoading(false);
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [locationId]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => {
              toggleSidebar(layoutDispatch);
              togglePermanent((!layoutState.isSidebarOpened && isSmall)
                || (layoutState.isSidebarOpened && !isSmall), layoutDispatch);
            }}
            className={classNames(
              classes.headerMenuButton,
              classes.headerMenuButtonCollapse,
            )}
          >
            <Box className={(!layoutState.isSidebarOpened && isSmall)
              || (layoutState.isSidebarOpened && !isSmall) ? classes.closeBox : classes.openBox}
            >
              {(!layoutState.isSidebarOpened && isSmall)
                || (layoutState.isSidebarOpened && !isSmall) ? (
                <ArrowBackIosIcon
                  style={BackIconStyle}
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              ) : (
                <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_1.svg" alt="header" onClick={() => localStorage.setItem('toggleSidebar', toggleState === 'true' ? false : true)} />
              )}
            </Box>
          </IconButton>
          <div className={classes.location}>
            <Dialog onClose={handleCloseBusiness} aria-labelledby="simple-dialog-title" open={openBusiness}>
              <DialogTitle id="simple-dialog-title">Selected Business</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Typography className={classes.selectLocationLabel} color="primary" variant="body1">{entityContext?.entitiesState?.entities?.businessname || 'Please select business'}</Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={handleCloseBusiness}
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog onClose={handleCloseLocation} aria-labelledby="simple-dialog-title" open={openLocation}>
              <DialogTitle id="simple-dialog-title">Selected Location</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Typography className={classes.selectLocationLabel} color="primary" variant="body1">
                    {globalContext?.globalState?.currentLocation?.businessname || 'Please select location'}
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={handleCloseLocation}
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {dataLoading ? <LoadingPage /> : <></>}
            <IconButton
              aria-haspopup="true"
              color="inherit"
              fontSize="large"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
            >
              {handleUndefined(locationLogo) || handleUndefined(logoUrl) ? (
                <Link to={`/app/home/business-client-details/?id=${locationId}`}>
                  {locationLogo || logoUrl ? (
                    <img
                      className={classes.brandLogo}
                      src={locationLogo || logoUrl}
                      alt="Profile"
                    />
                  ) : ''}
                </Link>
              )
                : (
                  <Link to={`/app/home/business-client-details/?id=${locationId}`} className={classes.headerMenuLink}>
                    <p className={classes.headerLogo}>Upload BrandLogo</p>
                  </Link>
                )}
            </IconButton>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={bar}
            severity="success"
            ContentProps={{
              'aria-describedby': 'message-id',
              className: classes.snackbarStyleViaContentProps,
            }}
            autoHideDuration={6000}
            message={(
              <>
                <a href style={{ color: 'white' }}>
                  <Typography variant="body1" onClick={() => talkToSales()}>
                    Increase your Text and Call limit.
                    <span><b>Contact Us</b></span>
                  </Typography>
                </a>
                <center>
                  <Link to="/app/topup" className={classes.headerMenuLink}>
                    <Typography variant="body1" style={{ color: 'white' }}>
                      Or Top-up
                    </Typography>
                  </Link>
                </center>

              </>
            )}
            action={(
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    setBar(false);
                    localStorage.setItem('bar', false);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            )}
          />
          {locationSeletion ? (
            <Typography
              className={classes.selectLocationLabel}
              onClick={() => {
                if ((userInfo?.roleid === Roles?.BusinessAdmin
                  || userInfo?.roleid === Roles?.BusinessManager
                  || userInfo?.roleid === Roles.LocationManager
                  || userInfo?.roleid === Roles.LocationUser)) {
                  setLocationModal(true, layoutDispatch);
                } else {
                  setLocationModal(false, layoutDispatch);
                }
              }}
              color="primary"
              variant="body1"
            >
              &nbsp;&nbsp;
              {globalContext?.globalState?.currentLocation?.businessname?.substring(0, 60) || 'Please select location'}
            </Typography>
          ) : (
            <Typography
              className={classes.selectLocationLabel}
              color="primary"
              variant="body1"
            >
              &nbsp;&nbsp;
              {globalContext?.globalState?.currentLocation?.businessname?.substring(0, 60) || 'Please select location'}
            </Typography>
          )}
          &nbsp;
          {
            locationSeletion ? (<IconButton
              variant="contained"
              color="primary"
              disabled={!locationSeletion}
              onClick={() => setLocationModal(true, layoutDispatch)}
            >
              {localStorage.getItem('theme') === 'dark' ? (
                <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_2.svg" alt="header" />

              )
                : (
                  <img
                  width="36px"
                 src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/analytics.svg"
                 alt="template"
                 />
                )}
            </IconButton>) : ''
          }
          <div className={classes.grow} />
          {whiteLabelStatus !== 'full' ? (
            <Box className={classes.referImg} onClick={() => setOpenRefer(true)}>
              {localStorage.getItem('theme') !== 'dark'
                ? (<img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/ReferHeader.gif" alt="refer" width="auto" height="52px" style={{ marginTop: 5 }} />)
                : (<img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/ReferVector.png" alt="refer" width="auto" height="60px" />)}
            </Box>
          ) : ''}
          <Refercode
            openRefer={openRefer}
            setOpenRefer={setOpenRefer}
          />
          &nbsp;&nbsp;
          <Box>
            <IconButton
              onClick={handleAppMenuOpen}
              className={classes.moreBtnR}
            >
              {localStorage.getItem('theme') === 'dark' ? (
                <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_3.svg" alt="header" />
              )
                : (
                  <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_4.svg" alt="header" />
                )}
            </IconButton>
            <Menu
              open={Boolean(appMenuOpen)}
              anchorEl={appMenuOpen}
              onClose={handleAppMenuClose}
              className={classes.menu}
              style={{
                paddingRight: '0px',
                paddingLeft: '0px',
              }}
            >
              <MenuItem
                className={classes.menuItem}
                style={{
                  paddingRight: '0px',
                  paddingLeft: '0px',
                }}
              >
                <Tooltip title="Add">
                  <IconButton onClick={handleAddMenuOpen} className={classes.addIcon}>
                    {localStorage.getItem('theme') === 'dark' ? (
                      <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_5.svg" alt="header" />
                    ) : (
                      <img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/add-quick.svg" alt="header" style={SvgStyle} />
                    )}
                  </IconButton>
                </Tooltip>
                <Menu
                  open={Boolean(menuOpen)}
                  anchorEl={menuOpen}
                  onClose={handleAddMenuClose}
                  className={classes.menu}
                >
                  <MenuItem className={classes.menuItem} onClick={handleAddMenuClose} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <Typography onClick={() => history.push('/app/contact-central?add=true')} className={classes.linkText}>
                      {localStorage.getItem('theme') === 'dark' ? (
                        <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_7.svg" alt="header" />
                      )
                        : (
                          <img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/add-contact.svg" alt="header"  />
                        )}
                      Add Contact
                    </Typography>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={handleAddMenuClose}>
                    <Typography onClick={() => history.push('/app/organizations')} className={classes.linkText}>
                      {localStorage.getItem('theme') === 'dark' ? (
                        <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_9.svg" alt="header" />
                      )
                        : (
                          <img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/add-organization.svg" alt="header" />
                        )}
                      Add Organization
                    </Typography>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={handleAddMenuClose}>
                    <Typography onClick={() => history.push('/app/deal-central')} className={classes.linkText}>
                      {localStorage.getItem('theme') === 'dark' ? (
                        <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_11.svg" alt="header" />
                      )
                        : (
                          <img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/add-deal.svg" alt="header"  />
                        )}
                      Add Deal
                    </Typography>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={handleAddMenuClose}>
                    <Typography onClick={() => history.push('/app/task')} className={classes.linkText}>
                      {localStorage.getItem('theme') === 'dark' ? (
                        <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_13.svg" alt="header" />
                      )
                        : (
                          <img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/add-task.svg" alt="header" />

                        )}
                      Add Task
                    </Typography>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={handleAddMenuClose}>
                    <Typography onClick={() => history.push('/app/setting-user-management-dashboard')} className={classes.linkText}>
                      {localStorage.getItem('theme') === 'dark' ? (
                        <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_14.svg" alt="header" />
                      )
                        : (
                          <img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/add-user.svg" alt="header" />
                        )}
                      Add User
                    </Typography>
                  </MenuItem>
                </Menu>
              </MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => setAppMenuOpen(false)}>
                <Tooltip title="Quick Menu">
                  <Link to="/app/home/dashboard" className={classes.favIcon}>
                    {localStorage.getItem('theme') === 'dark' ? (
                      <IconButton>
                        <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_15.svg" alt="header" />
                      </IconButton>
                    )
                      : (
                        <IconButton>
                          <img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/QuickMenu.svg" alt="header" style={SvgStyle} />
                        </IconButton>
                      )}
                  </Link>
                </Tooltip>
              </MenuItem>
              {/* <MenuItem className={classes.menuItem} onClick={() => setAppMenuOpen(false)}>
                <Tooltip title="BixBox">
                  <Link to="/app/chat" className={classes.favIcon}>
                    {localStorage.getItem('theme') === 'dark' ? (
                      <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_17.svg" alt="header" />
                    )
                      : (
                        <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_18.svg" alt="header" style={SvgStyle} />
                      )}
                  </Link>
                </Tooltip>
              </MenuItem> */}
              <MenuItem className={classes.menuItem}>
                <Tooltip title={localStorage.getItem('theme') === 'dark' ? 'Light Theme' : 'Dark Theme'}>
                  <IconButton
                    className={classes.removeSpace}
                    onClick={toggleDarkTheme}
                    color={localStorage.getItem('theme') === 'dark' ? 'primary' : 'default'}
                  >
                    <Checkbox
                      checked={localStorage.getItem('theme') === 'dark'}
                      icon={<img width="40" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/DarkTheme-quick.svg" alt="header" />}
                      checkedIcon={<Brightness4 className={classes.darkIcon} />}
                    />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            </Menu>

          </Box>
          <Menu
            id="profile-menu"
            open={Boolean(iconMenu)}
            anchorEl={iconMenu}
            onClose={() => setIconMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <Link
              style={{ textDecoration: 'none' }}
              to="/app/home/dashboard"
              onClick={() => setIconMenu(null)}
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                {localStorage.getItem('theme') === 'dark' ? (
                  <img
                    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_19.svg"
                    alt="header"
                    style={SvgStyle}
                    className={classes.profileMenuIcon}
                  />
                ) : (
                  <img
                    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_20.svg"
                    alt="header"
                    style={SvgStyle}
                    className={classes.profileMenuIcon}
                  />
                )}
                {' '}
                <span className={classes.pro}>Favorites</span>
              </MenuItem>
            </Link>
            {/* <Link
              style={{ textDecoration: 'none' }}
              to="/app/chat"
              onClick={() => setIconMenu(null)}
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                {localStorage.getItem('theme') === 'dark' ? (
                  <img
                    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_21.svg"
                    alt="header"
                    style={SvgStyle}
                    className={classes.profileMenuIcon}
                  />
                ) : (
                  <img
                    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_22.svg"
                    alt="header"
                    style={SvgStyle}
                    className={classes.profileMenuIcon}
                  />
                )}
                {' '}
                <span className={classes.pro}>BixBox</span>
              </MenuItem>
            </Link> */}
            <Link
              style={{ textDecoration: 'none' }}
              to="/app/HelpAdvisors"
              onClick={() => setIconMenu(null)}
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                {localStorage.getItem('theme') === 'dark' ? (
                  <img
                    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_23.svg"
                    alt="header"
                    style={SvgStyle}
                    className={classes.profileMenuIcon}
                  />
                ) : (
                  <img
                    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_24.svg"
                    alt="header"
                    style={SvgStyle}
                    className={classes.profileMenuIcon}
                  />
                )}
                {' '}
                <span className={classes.pro}>Professional Services</span>
              </MenuItem>
            </Link>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
              onClick={() => {
                setOpenRefer(true);
                setIconMenu(null);
              }}
            >
              <LocalAtm className={classes.iconTheme} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className={classes.pro}>Refer and Earn</span>
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
              onClick={() => {
                toggleDarkTheme();
                setIconMenu(null);
              }}
            >
              <Checkbox
                checked={localStorage.getItem('theme') === 'dark'}
                icon={<Brightness6 style={{ color: '#005eb2' }} />}
                checkedIcon={<Brightness4 style={{ color: 'white' }} />}
                style={{ padding: '0px' }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className={classes.pro}>Theme</span>
            </MenuItem>
          </Menu>
          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {profileData
                  && profileData.firstname && profileData.firstname !== 'undefined'
                  && profileData.firstname}
                &nbsp;
                {profileData
                  && profileData.lastname && profileData.lastname !== 'undefined'
                  && profileData.lastname}
              </Typography>
            </div>
            <Link
              style={{ textDecoration: 'none' }}
              to="/app/setting-my-profile"
              onClick={() => setProfileMenu(null)}
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <AccountIcon className={classes.profileIcon} />
                {' '}
                <span className={classes.pro}>Profile</span>
              </MenuItem>
            </Link>
            <Link
              style={{ textDecoration: 'none' }}
              to="/app/usage-report"
              onClick={() => setProfileMenu(null)}
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <DataUsageIcon className={classes.profileIcon} />
                {' '}
                <span className={classes.pro}>Usage Report</span>
              </MenuItem>
            </Link>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
              )}
              style={{ display: 'none' }}
            >
              <Tooltip title="Notifications">
                <a className={classes.chatIcons} style={{ marginRight: 0 }}>
                  <IconButton size="small">
                    {localStorage.getItem('theme') === 'dark' ? (
                      <img
                        src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_25.svg"
                        alt="header"
                      />
                    )
                      : (<NotificationsNoneOutlinedIcon className={classes.notification} />)}
                  </IconButton>
                </a>
              </Tooltip>
              <span className={classes.pro}>Notifications</span>
            </MenuItem>
            <Link
              style={{ textDecoration: 'none' }}
              to="/app/my-referral"
              onClick={() => setProfileMenu(null)}
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <SignalCellularAltIcon className={classes.profileIcon} />
                {' '}
                <span className={classes.pro}>My Referrals</span>
              </MenuItem>
            </Link>
            <a
              style={{ textDecoration: 'none' }}
              href={handleUndefined(termUrl) || config?.termsUrl}
              target="_blank"
              rel="noreferrer"
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <SecurityIcon className={classes.profileIcon} />
                {' '}
                <span className={classes.pro}>Terms and Conditions</span>
              </MenuItem>
            </a>
            <a
              style={{ textDecoration: 'none' }}
              href={handleUndefined(privacyUrl) || config?.bixPolicy}
              target="_blank"
              rel="noreferrer"
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <VerifiedUserIcon className={classes.profileIcon} />
                {' '}
                <span className={classes.pro}>Privacy Policy</span>
              </MenuItem>
            </a>
            {whiteLabelStatus !== 'full' ? (
              <a
                style={{ textDecoration: 'none' }}
                href="https://bixpand.ai/security.html"
                target="_blank"
                rel="noreferrer"
              >
                <MenuItem
                  className={classNames(
                    classes.profileMenuItem,
                    classes.headerMenuItem,
                  )}
                >
                  <PhonelinkLockOutlined className={classes.profileIcon} />
                  {' '}
                  <span className={classes.pro}>Security</span>
                </MenuItem>
              </a>
            ) : ''}
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => setOpen(true)}
              >
                <span className={classes.proSignout}>Sign Out</span>
              </Typography>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              maxWidth="xs"
            >
              <DialogTitle id="responsive-dialog-title">
                Are you sure to Sign out?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You will be logged out from App!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClickOpen}
                  size="small"
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Agree
                </Button>
                <Button
                  autoFocus
                  onClick={handleClose}
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  Disagree
                </Button>
              </DialogActions>
            </Dialog>
          </Menu>
          <Box onClick={handleClickNotification} className={classes.displayHide}>
            <Avatar
              className={classes.notificationCount}
            >
              {notificationDataCount?.length + Number(contactTask?.pasttask || 0)
                + Number(contactTask?.todaytask || 0)
                + Number(contactAppointment?.todayevent
                  || 0)
                || 0}
            </Avatar>
          </Box>

          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            <AvatarMui
              alt="Profile"
              src={profileData && profileData.avatar && profileData.avatar}
              classes={{ root: classes.headerProfile }}
            >
              {profileData && !profileData.avatar
                && profileData.firstname
                && profileData?.firstname?.toUpperCase().charAt(0)}
            </AvatarMui>
          </IconButton>
          <Menu
            id="simple-menu"
            aria-haspopup="true"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            autoFocus={false}
            className={classes.headerMenuNotifications}
            classes={{ paper: classes.profileMenuNotification }}
            onClose={handleCloseNotification}
          >
            <Box className={classes.notificationTitle}>
              <Typography color="primary" variant="h1" className={classes.profileMenuUserNotification}>
                Notifications
              </Typography>
              <Box>
                <center>
                  <CloseIcon
                    className={classes.notificationClose}
                    onClick={handleCloseNotification}
                  />
                  {value === 0 && (
                    <Box onClick={() => notificationStatus()}>
                      <Typography className={classes.notificationClose} variant="caption">Mark all as read</Typography>
                    </Box>
                  )}
                </center>
              </Box>
            </Box>
            <Divider variant="middle" />
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              size="small"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label={(
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">
                      Notification
                    </Typography>
                    &nbsp;
                    <Typography variant="body1" style={{ color: 'red' }}>
                      (
                      {notificationDataCount?.length}
                      )
                    </Typography>
                  </Box>
                )}
                {...a11yProps(0)}
              />
              <Tab
                label={(
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">
                      Task & Appointment
                    </Typography>
                    &nbsp;
                    <Typography variant="body1" style={{ color: 'red' }}>
                      (
                      {Number(contactTask?.pasttask || 0)
                        + Number(contactTask?.todaytask || 0)
                        + Number(contactAppointment?.todayevent || 0) || 0}
                      )
                    </Typography>
                  </Box>
                )}
                {...a11yProps(1)}
              />
            </Tabs>
            {value === 0 && (
              <List className={classes.root}>
                {notificationData && notificationData.map((item) => (
                  <ListItem key={item?.notificationid} role={undefined} dense button>
                    &nbsp;
                    &nbsp;
                    {/* <ListItemIcon onClick={() =>
                   // history.push(`/app/chat?cid=${item?.contactid}`)}>
                    <Avatar color="primary">
                      {handleUndefined(item?.contactname.charAt(0))}
                    </Avatar>
                  </ListItemIcon> */}
                    {/* <ListItemText onClick={() => history.push(`/app/chat?cid=${item?.contactid}`)}
                  // primary={item?.contactname === 'undefined' ? '' : item?.contactname}
                  secondary={item?.title} /> */}
                    <ListItemSecondaryAction>
                      <Typography style={{ fontSize: '10px' }} variant="caption">{moment(item?.createdAt, 'YYYY-MM-DDTHH:mm:ssZ')?.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)?.format('ddd, MMM DD, YYYY HH:mm')}</Typography>
                      <IconButton edge="end" aria-label="comments">
                        {item?.status === 2 ? (
                          <Tooltip title="Mark as unread">
                            <img
                              src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_26.svg"
                              alt="header"
                              onClick={() => updateNotification(item?.notificationid)}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Mark as read">
                            <img
                              src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/main_header_27.svg"
                              alt="header"
                              onClick={() => updateNotification(item?.notificationid)}
                            />
                          </Tooltip>
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
            {value === 1 && (
              <Box>
                <List className={classes.root}>
                  <Box className={classes.task}>
                    <Typography className={classes.taskTitle} color="primary">Task</Typography>
                  </Box>
                  <ListItem onClick={() => history.push('/app/task')} role={undefined} dense button>
                    &nbsp;
                    &nbsp;
                    <ListItemText primary="Over Due" />
                    <ListItemSecondaryAction>
                      {contactTask?.pasttask ? contactTask?.pasttask : 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem onClick={() => history.push('/app/task')} role={undefined} dense button>
                    &nbsp;
                    &nbsp;
                    <ListItemText primary="Due Today" />
                    <ListItemSecondaryAction>
                      {contactTask?.todaytask ? contactTask?.todaytask : 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem onClick={() => history.push('/app/task')} role={undefined} dense button>
                    &nbsp;
                    &nbsp;
                    <ListItemText primary="Due This Week" />
                    <ListItemSecondaryAction>
                      {contactTask?.lastfivedaystask ? contactTask?.lastfivedaystask : 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                <Divider variant="middle" />
                <List className={classes.root}>
                  <Box className={classes.task}>
                    <Typography className={classes.taskTitle} color="primary">Appointment</Typography>
                  </Box>
                  <ListItem onClick={() => history.push('/app/lead-central/appointment?sid=1')} role={undefined} dense button>
                    &nbsp;
                    &nbsp;
                    <ListItemText primary="Due Today" />
                    <ListItemSecondaryAction>
                      {contactAppointment?.todayevent ? contactAppointment?.todayevent : 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem onClick={() => history.push('/app/lead-central/appointment?sid=1')} role={undefined} dense button>
                    &nbsp;
                    &nbsp;
                    <ListItemText primary="Due This Week" />
                    <ListItemSecondaryAction>
                      {contactAppointment?.lastfivedaysevent
                        ? contactAppointment?.lastfivedaysevent
                        : 0}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Box>
            )}
          </Menu>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClickMenu}
            className={classes.moreVertIcon}
          >
            <MoreVertIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {(layoutState.locationModal || layoutState.businessModal) && (
        <Location
          locationSeletion={locationSeletion}
          isAgencyUser={userInfo?.roleid === Roles?.AgencyAdmin
            || userInfo?.roleid === Roles.AgencyManager}
          isMasterUser={isMasterUser}
          history={history}
        />
      )}
      <BusinessFavicon
        logo={pathValidation ? BixFavIcon : (whiteLabelStatus === 'full' ? (businessData?.favicon || businessData?.logourl) : GlobeIcon)}
        name={pathValidation ? "Bixpand" : businessData?.businessname}
      />
    </>
  );
}
