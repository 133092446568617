/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField as formTextField } from 'final-form-material-ui';
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import CampaignBrand from '../../CampaignBrand/CampaignBrand';
import Widget from '../../../components/Widget/Widget';
import useStyles from './Styles';
import '../../phoneInput.css';
import { CRMLifecyclestageByStatus, TochlessPubilcStatus, TouchLessPublicId } from '../../../common/const';
import config from '../../../config';
import { useEntitiesState } from '../../../context/EntityContext';
import { useGlobalState } from '../../../context/GlobalContext';
import Phonenumber from '../../Phonenumber/PhoneNoWithCountryCode';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import {
  handleUndefined,
  postDataRequestPublic,
} from '../../../common/commonfunctions';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import {
  required, maxString, mustBeString, emailValidate, composeValidators, phoneValidate,
} from '../../../common/FormValidation';
import PoweredBy from '../../../common/PoweredBy';
import { TouchlessEntity } from '../../../Api Mutation/TouchlessApi';

const StyledRating = withStyles({
  iconFilled: {
    color: '#74b6f7',
  },
  iconHover: {
    color: '#0259a8',
  },
  iconEmpty: {
    color: '#74b6f7',
  },
  sizeLarge: {
    fontSize: '3rem',
  },
})(Rating);
function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function TouchLessPublic(history, props) {
  const classes = useStyles();
  const theme = useTheme();
  const globalContext = useGlobalState();
  const addressEnable = true;
  const context = useEntitiesState();
  const { entities } = context;
  const { bixpandpage } = props;
  const [value, setValue] = React.useState(0);
  const {
    bid, lid, campid, emailcode, uid,
  } = useParams([]);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const publicPage = params.get('public');
  const [brandImage, setBrandImage] = useState();
  const [entityLocation, setEntityLocation] = useState([]);
  const [entity, setEntity] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [rating, setRating] = useState(5);

  const onSubmitEntity = async (values) => {
    if (!values.phonenumber) {
      showNotification('PhoneNumber is required', 'error');
    } else {
      const CREATE_CONTACT_MUTATION = `
            mutation 
            createOrGetContact(
              $primaryemail: String
              $secondaryemail: String
              $firstname: String!
              $lastname: String
              $locationid: ID!
              $officephonenumber: String
              $phonenumber: String
              $lifecyclestage: Int!
              $jobid: String
              $tags: [String!]
              $ownerid: ID!
              $status: Int!
              $source: [String!]
              $createdby: ID!
              $phonecountryid: ID
            ){ 
              createOrGetContact(
                      primaryemail: $primaryemail,
                      secondaryemail: $secondaryemail,
                      firstname: $firstname,
                      lastname: $lastname,
                      locationid: $locationid,
                      officephonenumber: $officephonenumber,
                      phonenumber: $phonenumber,
                      lifecyclestage: $lifecyclestage,
                      jobid: $jobid,
                      tags: $tags,
                      source: $source,
                      status: $status,
                      ownerid: $ownerid,
                      createdby: $createdby,
                      phonecountryid: $phonecountryid
                        ){
                          contactid
                          lifecyclestage
                          primaryemail
                          firstname
                          lastname
                          tags
                          ownerid
                          status
                          source
                          phonenumber  
                          locationid 
                          createdby
                        }
                }`;
      postDataRequestPublic(config.baseURLApi, {
        query: CREATE_CONTACT_MUTATION,
        variables: {
          primaryemail: values.primaryemail,
          firstname: values.FirstName,
          lastname: values.LastName,
          phonenumber: values.phonenumber,
          ownerid: JSON.parse(localStorage.getItem('userid')) || uid,
          locationid: lid,
          status: TochlessPubilcStatus.Warm,
          lifecyclestage: CRMLifecyclestageByStatus?.Customer,
          createdby: JSON.parse(localStorage.getItem('userid')) || uid,
          source: publicPage ? ['Public Page'] : ['Touchless Review'],
        },
      }).then((res) => {
        if (res?.data?.createOrGetContact) {
          setValue(res?.data?.createOrGetContact
            ? TouchLessPublicId.Rating
            : TouchLessPublicId.ReviewUs);
          setCustomerId(res?.data?.createOrGetContact?.contactid);
        } else {
          showNotification(res?.errors && res?.errors[0]?.message, 'error', 'error');
        }
      });
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (handleUndefined(bid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: `query entity($entityid:ID!){
                    entity(entityid:$entityid){
                      entityid
                      businessname
                      brandname
                      firstname
                      lastname
                      email
                      phonenumber
                      entitytypeid
                      businesstypeid
                      jobid
                      tags
                      website
                      shortdesc
                      fulldesc
                      policy
                      terms
                      }}`,
          variables: {
            entityid: bid,
          },
        }).then((res) => {
          if (res.data && res.data.entity) {
            setEntity(res.data.entity);
          }
        });
      }
      if (handleUndefined(lid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: TouchlessEntity,
          variables: {
            entityid: lid,
          },
        })
          .then((res) => {
            setEntityLocation(res?.data?.entity);
            setBrandImage(res?.data?.entity?.logourl);
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [bid, lid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (localStorage.getItem('userid')) {
        postDataRequestPublic(config.baseURLApi, {
          query: `query entity($entityId:ID!){entity(entityid:$entityId){entityid,businessname,brandname
                      firstname,lastname,email,phonenumber,entitytypeid,businesstypeid,jobid,tags,website,shortdesc,fulldesc
                      }}`,
          variables: {
            entityId: JSON.parse(localStorage.getItem('userid')) || uid,
          },
        });
      }
      if (handleUndefined(lid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: `query customersbylocation($locationId:ID!){
                      customersbylocation(locationid: $locationId) {
                        entityid
                          brandname
                        firstname
                        lastname
                        email
                        tags
                        jobid
                        phonenumber
                      }
                    }`,
          variables: {
            locationId: lid,
          },
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [globalContext.globalState.currentLocation.entityid, lid, uid]);

  return (
    <div>
      <>
        <div
          style={{
            marginTop: !bixpandpage ? '15px' : '',
            marginBottom: !bixpandpage ? '35px' : '',
          }}
        >
          <br />
          <br />
          <center>
            <div className={classes.root1}>
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
              >
                {entities.Loading ? (
                  <LoadingPage />
                ) : (
                  <>
                    <center>
                      <Typography variant="h4">{handleUndefined(entityLocation?.businessname)}</Typography>
                      <br />
                    </center>
                    {' '}
                    <br />
                    <Form
                      onSubmit={onSubmitEntity}
                      render={({
                        handleSubmit,
                      }) => (
                        <form noValidate>
                          <Widget
                            disablewidgetmenu="true"
                            inheritheight="true"
                          >
                            <label htmlFor className={classes.componentHeading}>
                              <b>
                                Write a Review
                              </b>
                            </label>
                            {' '}
                            <br />
                            <br />
                            <Grid
                              container
                              spacing={2}
                            >
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <Field
                                  variant="outlined"
                                  size="small"
                                  className={
                                    classes.field
                                  }
                                  required
                                  validate={
                                    composeValidators(required, mustBeString, maxString(20))
                                  }
                                  name="FirstName"
                                  component={
                                    formTextField
                                  }
                                  type="text"
                                  label="First Name"
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <Field
                                  variant="outlined"
                                  size="small"
                                  className={
                                    classes.field
                                  }
                                  validate={maxString(20)}
                                  name="LastName"
                                  component={
                                    formTextField
                                  }
                                  type="text"
                                  label="Last Name"
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <Field
                                  name="primaryemail"
                                  variant="outlined"
                                  size="small"
                                  className={
                                    classes.field
                                  }
                                  validate={composeValidators(required, emailValidate)}
                                  required
                                  component={
                                    formTextField
                                  }
                                  type="text"
                                  label="Email"
                                />
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                              >
                                <Field required name="phonenumber" validate={phoneValidate} component={Phonenumber} />
                              </Grid>
                            </Grid>
                          </Widget>
                          <br />
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.reviewBtn}
                            onClick={handleSubmit}
                            startIcon={<StarBorderIcon />}
                            disabled={
                              !addressEnable
                            }
                          >
                            Review Us
                          </Button>
                          <br />
                          <br />
                          <br />
                          <br />
                          <PoweredBy id={lid} />
                        </form>
                      )}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
              >
                <Box width="100%">
                  <Grid
                    container
                    justifyContent="center"
                    spacing={6}
                  >
                    <Grid item md={11} xs={12}>
                      <Card className={classes.cardMedia}>
                        <center>
                          <CardContent
                            style={{
                              paddingBottom: 16,
                            }}
                          >
                            <br />
                            {brandImage ? (
                              <img
                                className={
                                classes.large2
                              }
                                src={brandImage}
                                alt="brandImage"
                              />
                            ) : ''}
                            <br />
                            <Typography
                              className={
                                classes.templateText
                              }
                              variant="h6"
                              style={{
                                marginTop:
                                  '10px',
                              }}
                            >
                              <Box>
                                We love your
                                feedback!
                              </Box>
                            </Typography>
                            <Typography variant="body1">How would you rate us.</Typography>
                            <Box
                              display="flex"
                              flexDirection="column"
                              marginTop={2}
                              marginBottom={2}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-around"
                              >
                                <StyledRating
                                  name="customized-color size-large"
                                  value={rating}
                                  onChange={(values) => {
                                    if (values.target.value <= 3) {
                                      window.open(`${config.BixUrl}/rating-negative-comment?campid=${campid}&custid=${customerId}&bid=${bid}&lid=${lid}&ec=${emailcode}&r=${values.target.value}`, '_blank');
                                    } else {
                                      window.open(`${config.BixUrl}/rating-positive-comment?campid=${campid}&custid=${customerId}&bid=${bid}&lid=${lid}&ec=${emailcode}&r=${values.target.value}`);
                                    }
                                    setRating(values.target.value);
                                  }}
                                  precision={1}
                                  size="large"
                                />
                              </Box>
                            </Box>
                            <br />
                            <Box
                              className={
                                classes.text
                              }
                              marginBottom={1}
                              fontSize="1.142rem"
                            >
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize="1.142rem"
                              >
                                Sincerely,
                              </Box>
                              <b>
                                {(handleUndefined(
                                  entity.brandname,
                                )
                                  ? `${handleUndefined(
                                    entity.brandname,
                                  )}, `
                                  : '')
                                  + handleUndefined(
                                    entityLocation?.businessname,
                                  ) || ''}
                              </b>
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize="1.142rem"
                              >
                                {' '}
                                {handleUndefined(
                                  <CampaignBrand />,
                                ) && (
                                <Typography variant="h6">
                                  {handleUndefined(
                                    <CampaignBrand />,
                                  )}
                                </Typography>
                                )}
                              </Box>
                            </Box>
                            <br />
                            <br />
                            <hr
                              style={{
                                border:
                                  '1.0px solid#627FE3',
                              }}
                            />
                            <br />
                            <br />
                            <br />
                            <br />
                            <PoweredBy id={lid} />
                          </CardContent>
                        </center>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <br />
              <br />
            </div>
          </center>
        </div>
      </>
    </div>
  );
}
