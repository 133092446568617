import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import CampaignBrand from '../CampaignBrand/CampaignBrand';
import useStyles from './SmsReviewStyle';
import '../phoneInput.css';
import config from '../../config';
import BusinessFavicon from '../../common/BusinessFavicon';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import PoweredBy from '../../common/PoweredBy';
import { BrandSettingByLocation, Entity } from '../../Api Mutation/EntityApi';

const StyledRating = withStyles({
  iconFilled: {
    color: '#74b6f7',
  },
  iconHover: {
    color: '#0259a8',
  },
  iconEmpty: {
    color: '#74b6f7',
  },
  sizeLarge: {
    fontSize: '3rem',
  },
})(Rating);
export default function ReviewSms() {
  const classes = useStyles();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const bid = params.get('bid');
  const lid = params.get('lid');
  const emailcode = params.get('ec');
  const custid = params.get('custid');
  const campid = params.get('campid');
  const [entityLocation, setEntityLocation] = useState([]);
  const [brandImage, setBrandImage] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [entity, setEntity] = useState([]);
  const [rating, setRating] = useState(5);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (handleUndefined(bid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: bid,
          },
        }).then((res) => {
          if (res.data && res.data.entity) {
            setEntity(res.data.entity);
          }
        })
          .then((res) => {
            if (res?.data && res?.data?.entity) {
              setEntity(res?.data?.entity);
            }
          });
      }
      if (handleUndefined(bid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: BrandSettingByLocation,
          variables: {
            locationid: handleUndefined(bid),
          },
        }).then((res) => setBrandImage(res?.data?.brandSettingByLocation?.logourl));
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: bid,
          },
        });
      }
      if (handleUndefined(lid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: lid,
          },
        })
          .then((res) => {
            setEntityLocation(res?.data?.entity);
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [bid]);

  return (
    <div>
      <BusinessFavicon
        logo={favIcon || locationBrandImage || brandImage}
        name={entityLocation?.businessname}
      />
      <br />
      <br />
      <Box className={classes.width}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item md={11} xs={12}>
            <Card className={classes.cardMedia}>
              <center>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography variant="h4">{handleUndefined(entity?.brandname)}</Typography>
                  <br />
                  {brandImage ? (
                    <>
                      <img
                        className={classes.large2}
                        src={handleUndefined(brandImage)}
                        alt="brandImage"
                      />
                    </>
                  ) : ''}
                  <br />
                  <Typography
                    className={classes.templateText}
                    variant="h6"
                    style={{ marginTop: '10px' }}
                  >
                    <Box>We love your feedback!</Box>
                  </Typography>
                  <Typography variant="body1">How would you rate us.</Typography>
                  <br />
                  <Box display="flex" justifyContent="center">
                    <StyledRating
                      name="customized-color size-large"
                      value={rating}
                      onChange={(value) => {
                        if (value.target.value > 3) {
                          window.open(`${config.BixUrl}/rating-positive-comment?campid=${campid || null}&custid=${custid || null}&bid=${bid}&lid=${lid}&ec=${emailcode}&r=${value.target.value}`);
                        } else { window.open(`${config.BixUrl}/rating-negative-comment?campid=${campid || null}&custid=${custid || null}&bid=${bid}&lid=${lid}&ec=${emailcode}&r=${value.target.value}`); }
                        setRating(value.target.value);
                      }}
                      precision={1}
                      size="large"
                      readOnly={!handleUndefined(rating)}
                    />
                  </Box>
                  <br />
                  <Box
                    className={classes.text}
                    marginBottom={1}
                  >
                    <Typography variant="body1">Sincerely</Typography>
                    <Typography variant="body2">{(handleUndefined(entity?.brandname) ? `${handleUndefined(entity?.brandname)}, ` : '') + handleUndefined(entityLocation?.businessname) || ''}</Typography>
                    <Box>
                      {' '}
                      {handleUndefined(<CampaignBrand />) && (
                      <Typography variant="body2">
                        {handleUndefined(<CampaignBrand />)}
                      </Typography>
                      )}
                    </Box>
                  </Box>
                  <br />
                  <hr
                    style={{ border: '1.0px solid#627FE3' }}
                  />
                  <br />
                  <PoweredBy id={lid} />
                </CardContent>
              </center>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
