import React, { useEffect } from 'react';
import {
  Paper, Typography, Button,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LockIcon from '@material-ui/icons/Lock';
import { Form, Field } from 'react-final-form';
import { TextField as formTextField } from 'final-form-material-ui';
import config from '../../config';
import Message from '../Messages/Message';
import PoweredBy from '../../common/PoweredBy';
import {
  authValidate,
} from '../../common/FormValidation';
import { clearCookie, fieldLable } from '../../common/const';
import { Verifytwofactorauth, Resendtwofactorauthemail } from '../../Api Mutation/Login';
import { postDataRequest } from '../../common/commonfunctions';
import useStyles from './styles';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { receiveToken, useUserDispatch, useUserState } from '../../context/UserContext';

export default function TwofactorAuth({ user, userInfo }) {
  const classes = useStyles();
  const { isAuthenticated } = useUserState();
  const history = useHistory();
  const userDispatch = useUserDispatch();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!isAuthenticated) {
        history.push('/login');
      }
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated]);

  const userEmail = userInfo?.email?.substring(0, userInfo?.email?.indexOf('@'));
  const fetchData = async (values) => {
    if (!values?.code) {
      showNotification('Enter a valid Code', 'error');
    } else {
      await postDataRequest(config.baseURLApi, {
        query: Verifytwofactorauth,
        variables: {
          code: values?.code,
        },
      }).then((res) => {
        if (res?.data?.errors && res?.data?.errors[0]?.message) {
          showNotification(res?.data?.errors[0]?.message, 'error');
        } else if (res?.data?.verifytwofactorauth === null) {
          showNotification('You are entering invalid verification code', 'error');
        } else if (res?.data?.verifytwofactorauth) {
          const { token } = res?.data?.verifytwofactorauth;
          if (token) {
            receiveToken(token, userDispatch);
          }
          showNotification(Message?.loginSuccessMessage, 'success');
          history.push('/app/home/dashboard');
        }
      });
    }
  };
  const resendFetchData = async () => {
    const RESEND_VERIFYUSEREMAIL = Resendtwofactorauthemail;
    await postDataRequest(config.baseURLApi, {
      query: RESEND_VERIFYUSEREMAIL,
      variables: {
        userid: user,
      },
    }).then((res) => {
      if (res?.data?.errors && res?.data?.errors[0]?.message) {
        showNotification(res?.data?.errors[0]?.message, 'error');
      } else if (res?.data?.resendtwofactorauthemail) {
        showNotification('Check email in inbox/spam to Verify Email.', 'success');
      }
    });
  };
  return (
    isAuthenticated && (
      <div className={classes.verifyPage}>
        <Paper className={classes.verifyTwocode}>
          <img
            src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bixpand.png"
            className={classes.logo}
            alt="brand logo"
          />
          <br />
          <PhoneIphoneIcon color="primary" style={{ fontSize: 120 }} />
          <br />
          <br />
          <Typography variant="h1">We just sent you an Email</Typography>
          <br />
          <Typography variant="body2">
            To login, enter the security code we sent to
            {' '}
            {userInfo?.email?.slice(0, 3)}
            *****
            {userEmail?.slice(-2)}
            @gmail.com
          </Typography>
          <Typography variant="body2" color="primary">Learn more</Typography>
          <br />
          <Typography className={classes.textLabel} variant="body2" color="primary">Type your 6-digit security code here:</Typography>
          <Form
            onSubmit={fetchData}
            render={({
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  className={classes.field}
                  size="small"
                  variant="outlined"
                  name="code"
                  validate={authValidate}
                  InputLabelProps={fieldLable}
                  component={formTextField}
                  type="number"
                  label="Enter Code here"
                />
                <br />
                <br />
                <Button onClick={() => handleSubmit()} startIcon={<LockIcon />} size="small" fullWidth variant="contained" color="primary">Done</Button>
                <br />
                <br />
              </form>
            )}
          />
          <Link href onClick={() => resendFetchData()} variant="body1" color="primary">I didn&apos;t receive a code</Link>
          <br />
          <br />
          <Link
            href
            onClick={() => {
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              localStorage.removeItem('userid');
              clearCookie('user_Id');
              localStorage.removeItem('location_Id');
              clearCookie('location_Id');
              localStorage.clear();
              document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              history.push('/login');
              window.location.reload(false);
            }}
            variant="body1"
            color="primary"
          >
            Sign-in with another account
          </Link>
        </Paper>
        <br />
        <PoweredBy id={user} />
      </div>
    )
  );
}
