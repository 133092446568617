import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  filterDialog: {
    maxHeight: '400px',
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  closeBtn: {
    color: 'white',
  },
}));
