import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  InputAdornment, Input, Box, CircularProgress,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import {
  WhatsappShareButton,
  WhatsappIcon as WhatsappIconShare,
  TwitterShareButton,
  TwitterIcon as TwitterIconShare,
  FacebookShareButton,
  FacebookIcon as FacebookIconShare,
  EmailShareButton,
  EmailIcon as EmailIconShare,
  FacebookMessengerShareButton,
  FacebookMessengerIcon as FacebookMessengerIconShare,
  LinkedinShareButton,
  LinkedinIcon as LinkedinIconShare,
  RedditShareButton,
  RedditIcon as RedditIconShare,
  PocketShareButton,
  PocketIcon as PocketIconShare,
} from 'react-share';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PopupMessage from '../PopupMessage/PopupMessage';
import { useGlobalState } from '../../context/GlobalContext';
import { useEntitiesState } from '../../context/EntityContext';
import { postDataRequest, handleUndefined } from '../../common/commonfunctions';
import config from '../../config';
import useStyles from './styles';

export default function SocialShare(props) {
  const {
    Url, brandName, inPublicPage, title, event,
  } = props;
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [isLoading, setLoading] = useState(false);
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const classes = useStyles();
  const publicPageURL = `${config.BixUrl}/reviews/${entityContext?.entities?.businessData
    && entityContext?.entities?.businessData[0]?.businessname}/${globalContext?.globalState?.currentLocation?.businessname}`;
  const saveReviewChangeLog = (text) => {
    if (props?.handleShareReviewChangeLog && props?.reviewId) {
      props?.handleShareReviewChangeLog(text, props?.reviewId);
    }
  };
  const hashtags = ['locallyowned', 'localbusiness', 'localbusinessowners', 'onlinereviews', 'reviewmanagement', 'reviews', 'expandyourbrand', 'expandyourbusiness', 'bixpand'];
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setImageUrl(Url);
    }
    return () => {
      isMounted = false;
    };
  }, [Url]);

  const shareReviewPublicPage = () => {
    if (props?.inPublicPage === true) {
      const PUBLICPAGE_SHARE_REVIEW = `
            mutation
                increaseLikeOrShare(
                    $reviewid: ID!,
                    $type: String!,
                ){
                    increaseLikeOrShare(
                        reviewid: $reviewid,
                        type: $type, 
                    )
                }`;
      postDataRequest(config.reviewApi, {
        query: PUBLICPAGE_SHARE_REVIEW,
        variables: {
          reviewid: props?.reviewId,
          type: 'share',
        },
      });
    }
    setOpenShareDialog(true);
  };

  const shareReviewSanpshort = () => {
    if (props?.reviewBoard === true) {
      setLoading(true);
      const SHARE_REVIEW_SANPSSHORT = `
        mutation 
            generateAndSaveReviewSnapshot(
                $reviewid: ID!,
                ){
                    generateAndSaveReviewSnapshot(
                        reviewid: $reviewid
                    )
                }`;
      postDataRequest(config.reviewApi, {
        query: SHARE_REVIEW_SANPSSHORT,
        variables: {
          reviewid: props?.reviewId,
        },
      }).then((res) => {
        setLoading(false);
        setImageUrl(res?.data?.generateAndSaveReviewSnapshot);
        setLoading(false);
      });
    }
    setOpenShareDialog(true);
  };

  const DialogTitle = () => (
    <MuiDialogTitle
      disableTypography
      className={classes.rootDialogTitle}
    >
      <Typography variant="h6">Share</Typography>

      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => setOpenShareDialog(false)}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
  function CommonSocial() {
    return (
      <Dialog
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        size="xs"
      >
        <DialogTitle id="alert-dialog-title">Share</DialogTitle>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent dividers>
              <center>
                <FacebookShareButton
                  onClick={() => {
                    shareReviewPublicPage(props?.reviewId);
                    saveReviewChangeLog('Review shared on Facebook');
                  }}
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  quote={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                }
                  hashtag={
                    handleUndefined(brandName)
                      ? `#${brandName}`?.replace(/\s+/g, '')
                      : '#Bixpand'
                  }
                  className={classes.socialSvgContent}
                >
                  <FacebookIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                  onClick={() => {
                    shareReviewPublicPage(props?.reviewId);
                    saveReviewChangeLog(
                      'Review shared on Facebook Messenger',
                    );
                  }}
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  quote="Shared from BiXpand"
                  hashtag={
                    handleUndefined(brandName)
                      ? `#${brandName}`?.replace(/\s+/g, '')
                      : '#Bixpand'
                  }
                  className={classes.socialSvgContent}
                >
                  <FacebookMessengerIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </FacebookMessengerShareButton>

                <TwitterShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    shareReviewPublicPage(props?.reviewId);
                    saveReviewChangeLog('Review shared on Twitter');
                  }}
                  hashtags={
                    handleUndefined(brandName)
                      ? [
                        `${brandName}`?.replace(
                          /\s+/g,
                          '',
                        ), ...hashtags,
                      ]
                      : hashtags
                  }
                  className={classes.socialSvgContent}
                >
                  <TwitterIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </TwitterShareButton>

                <WhatsappShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    shareReviewPublicPage();
                    saveReviewChangeLog('Review shared on Whatsapp');
                  }}
                  separator=":: "
                  className={classes.socialSvgContent}
                >
                  <WhatsappIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    shareReviewPublicPage();
                    saveReviewChangeLog(
                      'Review shared on Linked In',
                    );
                  }}
                  quote={publicPageURL}
                  className={classes.socialSvgContent}
                >
                  <LinkedinIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </LinkedinShareButton>
                <RedditShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    shareReviewPublicPage();
                    saveReviewChangeLog('Review shared on Reddit');
                  }}
                  separator=":: "
                  className={classes.socialSvgContent}
                >
                  <RedditIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </RedditShareButton>
                <PocketShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    shareReviewPublicPage();
                    saveReviewChangeLog('Review shared on Pocket');
                  }}
                  separator=":: "
                  className={classes.socialSvgContent}
                >
                  <PocketIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </PocketShareButton>
                <EmailShareButton
                  subject={handleUndefined(title) ? 'Appointment Booking URL' : 'BiX-public page'}
                  url={
                    new URL(
                      handleUndefined(imageUrl) || window.location.href,
                    )?.href
                  }
                  className={classes.socialSvgContent}
                >
                  <EmailIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </EmailShareButton>
              </center>
              <br />
              <CopyToClipboard
                text={
                  new URL(
                    imageUrl || window.location.href,
                  )?.href
                }
              >
                <Input
                  className={classes.field}
                  id="standard-adornment-password"
                  type="text"
                  value={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  disabled
                  endAdornment={(
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        size="small"
                        startIcon={<FileCopyIcon />}
                        onClick={() => PopupMessage('Link Copied')}
                      >
                        Copy
                      </Button>
                    </InputAdornment>
                  )}
                />
              </CopyToClipboard>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => setOpenShareDialog(false)}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <>
      <center>
        {inPublicPage ? (
          <IconButton
            float="left"
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {
              shareReviewSanpshort();
            }}
          >
            <ShareIcon fontSize="large" />
          </IconButton>
        ) : event ? (
          <IconButton
            float="left"
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {
              shareReviewSanpshort();
            }}
          >
            <img
              style={{ height: '22px' }}
              src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/shareIcon.svg"
              alt="eventimage"
            />
          </IconButton>

        ) : (
          <Button
            className={
              classes.BottomBut
            }
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              shareReviewSanpshort();
            }}
            startIcon={
              <ShareIcon />
            }
          >
            Share
          </Button>
        )}
      </center>
      {' '}
      <CommonSocial />
    </>
  );
}
