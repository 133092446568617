import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Grid, Box, Link,
} from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { postDataRequestPublic } from '../../../common/commonfunctions';
import { Entity } from '../../../Api Mutation/EntityApi';
import PoweredBy from '../../../common/PoweredBy';
import { ProductServicesbylocation } from '../../../Api Mutation/Product&ServiceApi';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import config from '../../../config';
import useStyles from '../styles';

export default function Product() {
  const classes = useStyles();
  const { lid } = useParams([]);
  const [productData, setProductData] = useState([]);
  const [whiteLabelStatus, setWhiteLabelStatus] = useState();
  const [subDomain, setSubDomain] = useState();
  const [locationData, setLocationData] = useState();

  const getLocationByData = () => {
    if (lid) {
      const ENTITYDATA = Entity;
      postDataRequestPublic(config.baseURLApi, {
        query: ENTITYDATA,
        variables: { entityid: lid },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationData(res?.data?.entity);
        }
      });
    }
  };

  const getEntityData = () => {
    const entityIdValue = JSON.parse(localStorage?.getItem('location_Id'));
    if (entityIdValue) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: entityIdValue,
        },
      }).then((response) => {
        if (response?.data?.entity?.whitelabeltype) {
          setWhiteLabelStatus(response?.data?.entity?.whitelabeltype);
          setSubDomain(response?.data?.entity?.whitelabeldomain);
        }
      });
    }
  };

  const getInitialData = () => {
    postDataRequestPublic(config.baseURLApi, {
      query: ProductServicesbylocation,
      variables: {
        locationid: lid || locationData?.entityid,
      },
    }).then((response) => {
      if (response?.data?.productServicesbylocation) {
        setProductData(
          response?.data?.productServicesbylocation?.filter((i) => i?.ispublish === true),
        );
        getEntityData();
      } else if (response?.errors[0]?.message) {
        showNotification(response?.errors && response?.errors[0]?.message, 'error');
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getInitialData();
      getLocationByData();
    }
    return () => {
      isMounted = false;
    };
  }, [lid]);
  return (
    <>
      <Box className={classes.productCard}>
        <Grid container spacing={2}>
          {productData && productData.map((i) => (
            <Grid style={{ background: 'none' }} item xs={12} md={3} sm={4}>
              <Link
                style={{ textDecoration: 'none' }}
                target="_blank"
                href={
                  whiteLabelStatus === 'full' && subDomain && locationData?.subdomain
                    ? `https://${locationData?.subdomain}.${subDomain}/pd/${i?.productserviceid}/${locationData?.entityid}/${locationData?.parententityid}`
                    : locationData?.subdomain
                      ? `https://${locationData?.subdomain}${config.subDomainUrl}/pd/${i?.productserviceid}/${locationData?.entityid}/${locationData?.parententityid}`
                      : `${config.BixUrl}/pd/${i?.productserviceid}/${locationData?.entityid}/${locationData?.parententityid}`
                }
              >
                <Card boxShadow={3} className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={i?.imgurl}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {i?.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.action}>
                    <ShoppingCartOutlinedIcon color="primary" />
                    <Typography variant="body1">
                    USD &nbsp;
                      {i?.salesprice}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strike>
                      USD &nbsp;
                        {i?.actualprice}
                      </strike>
                    </Typography>
                  </CardActions>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
      <PoweredBy id={locationData?.entityid} />
    </>
  );
}
