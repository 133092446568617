import React, { useEffect, useState } from 'react';
import {
  Button, Grid, FormHelperText, FormControl, InputLabel, IconButton, InputAdornment, OutlinedInput,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link, useParams, useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import useStyles from './stylesforpassword';
import config from '../../config';
import Widget from '../../components/Widget/Widget';
import { bixpandDescription, validationErrorMsg } from '../../common/const';
import { passwordValidators } from '../../common/FormValidation';
import { decrypt } from '../../common/commonfunctions';

function ResetPassword() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { userid } = useParams();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const [passwordSignup, setPasswordSignup] = useState({
    password: '',
    confirmpass: '',
  });
  const [passwordErrorSignup, setPasswordErrorSignup] = useState();
  const [confirmPasswordErrorSignup, setConfirmPasswordErrorSignup] = useState();
  const [passwordPolicy, setPasswordPolicy] = useState(false);

  const header = {
    'Content-Type': 'application/json',
    'Apollo-Require-Preflight': 'true',
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const onSubmitEntity = () => {
    if (!passwordSignup.password) {
      setPasswordErrorSignup('required');
    }
    if (!passwordSignup.confirmpass) {
      setConfirmPasswordErrorSignup('required');
    }
    if (!passwordErrorSignup && !confirmPasswordErrorSignup) {
      axios({
        url: config.baseURLApi,
        method: 'post',
        headers: header,
        data: {
          query: `mutation{
                    resetPassword(userid:"${userid}",password:"${passwordSignup.password}")
                  }`,
        },
      }).then((res) => {
        if (res?.data?.data?.resetPassword) {
          setOpen(true);
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && localStorage.getItem('passlength')) {
      const decryptedPass = decrypt(bixpandDescription, localStorage.getItem('passlength'));
      if (decryptedPass.length < 12) {
        setPasswordPolicy(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('passlength')]);

  const classes = useStyles();
  return (
    <div>
      <AppBar position="static">
        <Typography
          variant="h6"
          weight="medium"
          className={classes.logo}
        >
          <img
            className={classes.logo}
            src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png"
            alt="Logo"
          />
        </Typography>
      </AppBar>
      <br />
      <Widget
        disablewidgetmenu="true"
        inheritheight="true"
        className={classes.Widgetup}
      >
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={passwordPolicy}
          severity="success"
          ContentProps={{
            'aria-describedby': 'message-id',
            className: classes.snackbarStyleViaContentProps,
          }}
          autoHideDuration={3000}
          message={(
            <center>
              <Typography variant="body1" style={{ color: 'white' }}>
                We have recently updated our password policy to further safeguard your account.
                <br />
                We kindly request you to change your password
                to align with the new security standards.
              </Typography>
            </center>
            )}
          action={(
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setPasswordPolicy(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
            )}
        />
        <Typography variant="h6">
          Change Password
        </Typography>
        <hr />
        <br />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                error={passwordErrorSignup}
                type={values.showPassword ? 'text' : 'password'}
                onBlur={(pass) => {
                  if (
                    !pass.target
                      .value
                  ) {
                    setPasswordErrorSignup('required');
                  }
                }}
                onChange={(pass) => {
                  setPasswordSignup({
                    ...passwordSignup,
                    password:
                                            pass.target
                                              .value,
                  });
                  if (
                    !pass.target
                      .value
                  ) {
                    setPasswordErrorSignup('Enter your password');
                  } else if (passwordValidators(pass.target.value)) {
                    setPasswordErrorSignup(validationErrorMsg);
                  } else {
                    setPasswordErrorSignup('');
                  }
                  if (
                    !passwordSignup.confirmpass
                  ) {
                    setConfirmPasswordErrorSignup('Enter Confirm password');
                  } else if (
                    passwordSignup.confirmpass
                                        && passwordSignup.confirmpass
                                        !== pass.target
                                          .value
                  ) {
                    setConfirmPasswordErrorSignup('Password and Confirm Password should be same');
                  } else {
                    setConfirmPasswordErrorSignup('');
                  }
                }}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                                            // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                                  )}
                labelWidth={70}
              />
            </FormControl>
            <FormHelperText
              error={
                                !!passwordErrorSignup
                            }
            >
              {
                                passwordErrorSignup
                            }
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm password *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                error={confirmPasswordErrorSignup}
                type={values.showPassword ? 'text' : 'password'}
                onBlur={(pass) => {
                  if (
                    !pass.target
                      .value
                  ) {
                    setConfirmPasswordErrorSignup('required');
                  }
                }}
                onChange={(pass) => {
                  setPasswordSignup({
                    ...passwordSignup,
                    confirmpass:
                                            pass.target
                                              .value,
                  });
                  if (
                    !passwordSignup.password
                  ) {
                    setPasswordErrorSignup('Enter your password');
                  } else if (passwordValidators(pass.target.value)) {
                    setPasswordErrorSignup(validationErrorMsg);
                  } else {
                    setPasswordErrorSignup('');
                  }
                  if (
                    !pass.target
                      .value
                  ) {
                    setConfirmPasswordErrorSignup('Enter Confirm password');
                  } else if (
                    pass.target
                      .value
                                        !== passwordSignup.password
                  ) {
                    setConfirmPasswordErrorSignup('Password and Confirm Password should be same');
                  } else {
                    setConfirmPasswordErrorSignup('');
                  }
                }}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                                  )}
                labelWidth={100}
              />
            </FormControl>
            <FormHelperText
              error={
                                !!confirmPasswordErrorSignup
                            }
            >
              {
                                confirmPasswordErrorSignup
                            }
            </FormHelperText>
          </Grid>
        </Grid>
        <br />
        <Button
          className={classes.buttonSave}
          size="small"
          variant="contained"
          color="primary"
          onClick={onSubmitEntity}
          type="submit"
          startIcon={<CheckIcon />}
        >
          Save
        </Button>
      </Widget>
      <br />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Message from App
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your password is successfully changed. Now You can Login
            to Your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="primary">
            <Link
              href
              onClick={() => {
                history.push('/login');
                localStorage.clear();
                document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.location.reload(false);
              }}
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Back To LogIn
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ResetPassword;
