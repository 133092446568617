import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import moment from 'moment';
import {
  Grid, Typography, Box, Avatar as AvatarMui, Link, IconButton, Paper,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AppleIcon from '@material-ui/icons/Apple';
import BusinessFavicon from '../../../../common/BusinessFavicon';
import { handleUndefined, postDataRequestPublic } from '../../../../common/commonfunctions';
import config from '../../../../config';
import { Businesseventbooking, BusinessEventData, Createcontactorganization } from '../../../../Api Mutation/Event';
import { LoadingPage } from '../../../LoadingPage/LoadingPage';
import useStyles from './style';
import { showNotification } from '../../../../components/Notification/ToastifyNotifications';
import { EventStatus } from '../../../../common/const';
import { Entity, BrandSettingByLocation } from '../../../../Api Mutation/EntityApi';
import { linkValidate } from '../../../../common/FormValidation';
import LazyLoader from '../../../LoadingPage/LazyLoader';
import SocialShare from '../../../SocialShare/SocialShare';

const FormGenerator = lazy(() => import('../../InquiryForms/FormGenerator'));
const PoweredBy = lazy(() => import('../../../../common/PoweredBy'));
const Payment = lazy(() => import('../../../Setting/PaymentConnection/Payment'));

const EventPublicURL = () => {
  const { id, locationid, businessid } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [eventName, setEventName] = useState();
  const [locationMeeting, setLocationMeeting] = useState();
  const [eventbookingurl, setEventBookingUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phone, setPhone] = useState();
  const [whatsApp, setWhatsApp] = useState();
  const [IOS, setIOS] = useState();
  const [android, setAndroid] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment(new Date()).add(30, 'm').toDate());
  const [eventDescription, setEventDescription] = useState();
  const [hostingName, setHostingName] = useState('');
  const [hostingImage, setHostingImage] = useState('');
  const [eventImage, setEventImage] = useState([]);
  const [document, setDocument] = useState([]);
  const [content, setContent] = useState([]);
  const [paymentId, setPaymentId] = useState([]);
  const [eventId, setEventId] = useState('');
  const [status, setStatus] = useState('');
  const [paidEvent, setPaidEvent] = useState();
  const [eventPrice, setEventPrice] = useState(0);
  const [contactData, setContactData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [updatedBy, setupdatedBy] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [favIcon, setFavIcon] = useState();
  const [brandImage, setBrandImage] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [submittedData, setData] = useState('');
  const [btnName, setBtnName] = useState('Subscribe Now');
  const [colorCode, setColorCode] = useState('#005eb2');
  const [backgroundColorCode, setBackgroundColorCode] = useState('transparent');
  const [foreColor, setForeColor] = useState('#000');
  const [btnPos, setBtnPos] = useState('right');
  const [radioPos, setRadioPos] = useState('vertical');
  const [checkBoxPos, setCheckBoxPos] = useState('vertical');
  const [btnThemeColor, setBtnThemeColor] = useState('#005eb2');
  const [btnTextColor, setBtnTextColor] = useState('#000');
  const [defaultCountryPhoneCode, setDefaultCountryPhoneCode] = useState('1');
  const [termDesc, setTermDesc] = useState();
  const [enableTerm, setEnableTerm] = useState(false);
  const [entity, setEntity] = useState();
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const classes = useStyles({
    color: colorCode,
    background: backgroundColorCode,
    textColor: foreColor,
    btnPosition: btnPos,
    btnColor: btnThemeColor,
    btnText: btnTextColor,
  });

  const getData = (eventid) => {
    if (eventid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: BusinessEventData,
        variables: {
          eventid,
        },
      }).then((res) => {
        setEventId(res?.data?.businesseventdata?.eventid);
        setStatus(res?.data?.businesseventdata?.status);
        setEventName(res?.data?.businesseventdata?.name);
        setLocationMeeting(res?.data?.businesseventdata?.location);
        setEventDescription(res?.data?.businesseventdata?.description);
        setWhatsApp(res?.data?.businesseventdata?.isshowwhatsappicon);
        setIOS(res?.data?.businesseventdata?.isshowiosicon);
        setAndroid(res?.data?.businesseventdata?.isshowandroidicon);
        setPhone(res?.data?.businesseventdata?.isshowphoneicon);
        setStartDate(res?.data?.businesseventdata?.eventstartdate);
        setEndDate(res?.data?.businesseventdata?.eventenddate);
        setHostingName(res?.data?.businesseventdata?.hostname);
        setHostingImage(res?.data?.businesseventdata?.hostimage);
        setEventImage(res?.data?.businesseventdata?.eventimage);
        setRadioPos(res?.data?.businesseventdata?.radioview);
        setCheckBoxPos(res?.data?.businesseventdata?.checkboxview);
        setContent(JSON.parse(res?.data?.businesseventdata?.formdata));
        setDocument(res?.data?.businesseventdata?.documents);
        setEventBookingUrl(res?.data?.businesseventdata?.eventbookingurl);
        setPaidEvent(res?.data?.businesseventdata?.ispaid);
        setEventPrice(res?.data?.businesseventdata?.eventprice);
        setupdatedBy(res?.data?.businesseventdata?.updatedby);
        setBtnName(res?.data?.businesseventdata?.calloutbuttontext);
        setColorCode(res?.data?.businesseventdata?.themecolor);
        setBackgroundColorCode((res?.data?.businesseventdata?.backgroundcolor === '#fff' || res?.data?.businesseventdata?.backgroundcolor === '#ffffff') ? 'transparent' : res?.data?.businesseventdata?.backgroundcolor);
        setForeColor(res?.data?.businesseventdata?.textcolor);
        if (res?.data?.businesseventdata?.btnthemecolor) {
          setBtnThemeColor(res?.data?.businesseventdata?.btnthemecolor);
          setBtnTextColor(res?.data?.businesseventdata?.btntextcolor);
        }
        setBtnPos(res?.data?.businesseventdata?.calloutbtnposition);
        setTermDesc(res?.data?.businesseventdata?.termdesc);
        setEnableTerm(res?.data?.businesseventdata?.termenable);
        setEnablePublicLogo(res?.data?.businesseventdata?.isshowfooterlogo);
        setDataLoading(false);
      });
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getData(id);
    }
    return () => {
      isMounted = false;
    };
  }, [id]);

  const getContact = (cid) => {
    if (cid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
        query contact($contactid: ID!){
          contact(contactid:$contactid){
            contactid,primaryemail,firstname,lastname,contactavatar,phonenumber,lifecyclestage,locationid,
            linkedin,createdby,updatedby,jobid,ownerid,createdAt,updatedAt,istargetlist,ispininchat,organization{
              organizationid,name,orgwebsite
            }
      }}
      `,
        variables: {
          contactid: cid,
        },
      }).then((res) => {
        if (res?.data?.contact) {
          setContactData(res?.data?.contact);
        }
      });
    }
  };

  const getOrganization = (oid) => {
    if (oid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
              query organization($organizationid: ID!){
                organization(organizationid: $organizationid){
                      organizationid,name,locationid,orgphonenumber,orgwebsite,orgtwitter,orgfacebook,orgstatus,categories,employeesize,
                      yearsinbusiness,revenue,businessstartdate,typeofentity,orgmap,orglinkedin,orggmb,orgemail,orgyoutubeurl,
                      address{addressid,street1,street2,city,county,stateid,countryid,zip,country{countryid,name},state{stateid,name}},orgyelp,orgtripadvisor,
                      createdby,updatedby,phonecountryid,createdAt,updatedAt,orgattribute1,orgattribute2,orgattribute3,orgattribute4,
                  }
              }`,
        variables: {
          organizationid: oid,
        },
      }).then((res) => {
        if (res?.data?.organization) {
          setOrganizationData(res?.data?.organization);
        }
      });
    }
  };

  const onFormSubmit = (data) => {
    setDataLoading(true);
    setData(data);
    postDataRequestPublic(config.baseURLApi, {
      query: Createcontactorganization,
      variables: {
        responsedata: JSON.stringify(data),
        eventid: id,
        locationid,
        businessid,
      },
    }).then((res) => {
      const result = res?.data?.createcontactorganization;
      if (result) {
        if (result.contactid) {
          getContact(result.contactid);
        } else if (result.organizationid) {
          getOrganization(result.organizationid);
        }
        if (paidEvent) {
          showNotification('You need to do payment for event booking.', 'info');
          setIsPayment(true);
        } else {
          postDataRequestPublic(config.baseURLApi, {
            query: Businesseventbooking,
            variables: {
              responsedata: JSON.stringify(data),
              eventid: id,
              locationid,
              businessid,
              contactid: result.contactid,
              organizationid: result.organizationid,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }).then((response) => {
            const respo = response?.data?.businesseventbooking;
            if (respo?.message) {
              showNotification(respo.message, 'success');
            } else {
              showNotification('Event booked successfully', 'success');
              setIsSuccess(true);
              getData(eventId);
            }
            setDataLoading(false);
          });
        }
      }
      setDataLoading(false);
    });
  };

  const diff = moment(moment.utc(endDate).toDate()).local().diff(moment(), 'minute');

  useEffect(() => {
    let isMounted = true;
    if (updatedBy !== '' && isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query userprofilebyuser($userId:ID!)
          {userprofilebyuser(userid: $userId) {
            phonenumber            
          }}`,
        variables: {
          userId: updatedBy,
        },
      }).then((result) => {
        if (result?.data?.userprofilebyuser?.phonenumber) {
          setPhoneNumber(result?.data?.userprofilebyuser?.phonenumber);
          setDataLoading(false);
        }
      });
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: updatedBy,
        },
      }).then((result) => {
        if (result?.data?.entity?.phonenumber) {
          setPhoneNumber(result?.data?.entity?.phonenumber);
        }
        setDataLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [updatedBy]);

  useEffect(() => {
    let isMounted = true;
    setDataLoading(true);
    if (isMounted && paymentId.length > 0) {
      postDataRequestPublic(config.baseURLApi, {
        query: Businesseventbooking,
        variables: {
          responsedata: JSON.stringify(submittedData),
          eventid: id,
          locationid,
          businessid,
          contactid: contactData?.contactid,
          organizationid: organizationData?.organizationid,
          paymentid: paymentId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }).then((response) => {
        const respo = response?.data?.businesseventbooking;
        if (respo?.message) {
          showNotification(respo.message, 'success');
        } else {
          showNotification('Event booked successfully', 'success');
          setIsSuccess(true);
          setIsPayment(false);
          getData(eventId);
        }
        setDataLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [paymentId]);

  const entityData = () => {
    if (locationid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: locationid },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
            setEntity(res?.data?.entity);
            if (res?.data?.entity?.defaultphonecountrycode) {
              setDefaultCountryPhoneCode(res?.data?.entity?.defaultphonecountrycode);
            }
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setDataLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      entityData();
    }
    return () => {
      isMounted = false;
    };
  }, [locationid]);

  return (
    <>
      {dataLoading ? <LoadingPage /> : <></>}
      <>
        <BusinessFavicon
          name={eventName}
          logo={favIcon || locationBrandImage || brandImage}
        />
        <Paper className={classes.styledpaper}>
          <Grid container className={classes.container}>
            <Grid container>
              <Grid className={classes.eventBg} item xs={12} sm={12} md={12} lg={12}>
                {eventImage ? (
                  <img src={eventImage} alt="eventimage" style={{ objectFit: 'contain' }} className={classes.coverImg} />
                ) : ''}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold' }}
                    className={status === EventStatus.Cancel
                      ? classes.errorlink
                      : diff <= 0 ? classes.errorlink : classes.publiclink}
                  >
                    {status === EventStatus.Cancel
                      ? 'This Event has cancelled'
                      : diff <= 0
                        ? 'This Event has finished'
                        : `${moment.utc(startDate, 'YYYY-MM-DD HH:mm:ss Z').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('dddd, MMMM D ')}`} 
                  </Typography>
                  <Typography className={classes.eventName} variant="h1"><strong>{eventName}</strong></Typography>
                  <Typography variant="body2" dangerouslySetInnerHTML={{ __html: eventDescription }} />
                  <Grid>
                    <Box className={classes.publichostingbox}>
                      <Box>
                        <AvatarMui
                          className={classes.publichostingimg}
                          src={hostingImage || hostingName?.toUpperCase().charAt(0)}
                        >
                          <Typography variant="h1">{hostingImage || hostingName?.toUpperCase().charAt(0)}</Typography>
                        </AvatarMui>
                      </Box>
                      <Box className={classes.publichostingname}>
                        <Typography variant="body1">Hosted By</Typography>
                        <Typography variant="body2">{hostingName}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} className={classes.iconWrapper}>

                  <Grid container spacing={1}>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                      <Box
                        style={{
                          marginTop: 3, background: 'transparent', padding: '9px', borderRadius: '22px',
                        }}
                        className={classes.grid}
                      >
                        <Suspense fallback={<LazyLoader />}>
                          <SocialShare
                            event
                            Url={entity?.subdomain
                              ? `https://${entity?.subdomain}${config.subDomainUrl}/ep/${eventId}/${locationid}/${businessid}`
                              : `${config.BixUrl}/ep/${eventId}/${locationid}/${businessid}` || eventbookingurl}
                          />
                        </Suspense>
                      </Box>
                    </Grid>
                    {phoneNumber && phone === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2} className="ml-1" style={{ textAlign: 'center' }}>
                        <IconButton
                          className={classes.Button}
                          size="small"
                          href={`tel:${phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PhoneIcon style={{
                            fontSize: 39, color: '#072cfe', background: '#ecebee', padding: '7px', borderRadius: '22px',
                          }}
                          />
                        </IconButton>
                      </Grid>
                    ) : ''}

                    {phoneNumber && whatsApp === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2} className="ml-1" style={{ textAlign: 'center' }}>
                        <IconButton
                          className={classes.Button}
                          size="small"
                          href={`${`https://api.whatsapp.com/send?phone=${handleUndefined(phoneNumber)}`}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <WhatsAppIcon style={{
                            fontSize: 39, color: '#3cd4a0', background: '#ecebee', padding: '7px', borderRadius: '22px',
                          }}
                          />
                        </IconButton>
                      </Grid>
                    ) : ''}

                    {phoneNumber && IOS === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'center' }}>
                        <IconButton
                          className={classes.ButtonPhone}
                          size="small"
                          href={`sms:${phoneNumber}&amp;body=`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AppleIcon style={{
                            color: '#607d8b', fontSize: 39, background: '#ecebee', padding: '7px', borderRadius: '22px',
                          }}
                          />
                        </IconButton>
                      </Grid>
                    ) : ''}

                    {phoneNumber && android === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <IconButton
                          className={classes.ButtonPhone}
                          size="small"
                          href={`sms:${phoneNumber}?body=`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            marginTop: 3, background: '#ecebee', padding: '2px', borderRadius: '22px',
                          }}
                        >
                          <img
                            style={{
                              color: '#ffc260',
                              fontSize: 60,
                              padding: '7px',
                              borderRadius: '22px',
                              height: '35px',
                              marginTop: '0px',
                            }}
                            src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/androidIcon.svg"
                            alt="eventimage"
                            className={classes.publicicons}
                          />
                        </IconButton>
                      </Grid>
                    ) : ''}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{ background: 'transparent' }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginTop: '30px', marginBottom: '30px' }}
                    className={classes.grid}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Box style={{ display: 'block', justifyContent: 'center' }} className={classes.publichostingbox}>
                          <Typography variant="h5"><strong>Date and Time</strong></Typography>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/dateTimeIcon.svg"
                              alt="DateTimeIcon"
                              style={{ height: '27px' }}
                              className={status === EventStatus.Cancel
                                ? classes.erroricons : diff <= 0 ? classes.erroricons
                                  : classes.publicicons}
                            />
                            <Box className={classes.publichostingname}>
                              <Typography
                                variant="body2"
                                className={status === EventStatus.Cancel
                                  ? classes.errorlink
                                  : diff <= 0 ? classes.errorlink : classes.publiclink}
                              >
                                {status === EventStatus.Cancel
                                  ? 'This Event has cancelled'
                                  : diff <= 0
                                    ? 'This Event has finished'
                                    : `${moment.utc(startDate, 'YYYY-MM-DD HH:mm:ss Z').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('dddd, MMMM D · h:mm A z')} To ${moment.utc(endDate, 'YYYY-MM-DD HH:mm:ss Z').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mm A z')}`}
                              </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} style={{ background: 'transparent' }} />

                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.grid}
                    style={{ marginBottom: '20px' }}
                  >
                    <Box style={{ display: 'block', alignItems: 'center' }} className={classes.publichostingbox}>
                      <Typography variant="h5"><strong>Location</strong></Typography>
                      <Box style={{
                        display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px',
                      }}
                      >
                        <img alt="eventimage" src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/locationIcon.svg" style={{ height: '24px' }} />
                        <Box className={classes.publichostingname}>
                          {diff <= 0
                            ? (
                              <Typography variant="body2" className={classes.errorlink}>
                                This Event has finished
                              </Typography>
                            )
                            : status === EventStatus.Cancel
                              ? (
                                <Typography variant="body2" className={classes.errorlink}>
                                  This Event has cancelled
                                </Typography>
                              ) : (
                                <>
                                  {locationMeeting && linkValidate(locationMeeting) ? (
                                    <Link href={locationMeeting} target="_blank">
                                      <Typography variant="body2" className={classes.meetingLink}>
                                        Event Location
                                      </Typography>
                                    </Link>
                                  ) : (
                                    <Typography variant="body2" className={classes.meetingLink}>
                                      Register for the event to get the details
                                    </Typography>
                                  )}
                                </>
                              )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{ background: 'transparent' }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.grid}
                    style={{ marginBottom: '30px', marginTop: 30 }}
                  >
                    <Grid container>
                      {paidEvent && (
                      <Grid item xs={3} sm={3} md={3} lg={3} className={classes.grid}>

                        <Grid container style={{ display: 'block' }} className={classes.publichostingbox}>
                          <Grid item>
                            <Typography variant="h5"><strong>Price</strong></Typography>
                          </Grid>
                          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              style={{ height: '27px', marginRight: '15px' }}
                              src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/dollarIcon.svg"
                              alt="eventimage"
                              className={classes.publicicons}
                            />
                            <Typography variant="body2" className={classes.publiclink}>{eventPrice}</Typography>
                          </Grid>

                        </Grid>
                      </Grid>
                      )}
                      <>
                      { document && (
                        <Grid style={{ display: 'block', alignItems: 'center', marginTop: '40px' }} item xs={12} sm={12} md={12} lg={12} className={classes.grid}>
                          <Typography variant="h5"><strong>Download</strong></Typography>
                          <Box style={{ marginTop: '0px' }} className={classes.publichostingbox}>
                            <Box>
                              <img
                                style={{ height: '27px' }}
                                src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/downloadIcon.svg"
                                alt="eventimage"
                                className={classes.publicicons}
                              />
                            </Box>
                            <Box className={classes.publichostingname}>
                              {document && typeof document === 'string'
                          && (
                            <Link href={document} target="_blank" download>
                              <Typography variant="body2" className={classes.publiclink}>
                                {document?.split('/')[document?.split('/')?.length - 1]}
                              </Typography>
                            </Link>
                          )}
                            </Box>
                          </Box>

                        </Grid>
                      )}
                        

                      </>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.iconWrapper}>
                  <Grid container className={classes.subcontainer}>
                    {isPayment ? (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.paper}>
                          <Paper className={classes.cal} elevation={6}>
                            <Suspense fallback={<LazyLoader />}>
                              <Payment
                                contactData={contactData}
                                organizationData={organizationData}
                                amount={eventPrice}
                                userId={businessid}
                                businessId={businessid}
                                locationId={locationid}
                                setPaymentId={setPaymentId}
                                eventId={eventId}
                                event
                              />
                            </Suspense>

                          </Paper>
                        </div>
                      </Grid>
                    ) : (
                      <>
                        <Typography className={classes.horizontalLine} />
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <div className={classes.paper} style={{ margin: 0 }}>
                            <Paper className={classes.cal} elevation={6}>
                              {diff <= 0
                                ? (
                                  <Typography variant="h6" className={classes.errormsg}>
                                    This Event has finished
                                  </Typography>
                                )
                                : status === EventStatus.Cancel
                                  ? (
                                    <Typography variant="h6" className={classes.errormsg}>
                                      This Event has cancelled
                                    </Typography>
                                  ) : (
                                    <>
                                      {isSuccess
                                        ? (
                                          <Typography variant="h6" className={classes.successmsg}>
                                            Thank you for registration for this event.
                                            Please check your email for more details.
                                          </Typography>
                                        )
                                        : (
                                          <>
                                            <Suspense fallback={<LazyLoader />}>
                                              <FormGenerator
                                                content={content}
                                                onFormSubmit={onFormSubmit}
                                                rows={12}
                                                defaultCountryPhoneCode={defaultCountryPhoneCode}
                                                btnName={btnName}
                                                colorCode={colorCode}
                                                backgroundColorCode={backgroundColorCode}
                                                foreColor={foreColor}
                                                btnPos={btnPos}
                                                radioPos={radioPos}
                                                checkBoxPos={checkBoxPos}
                                                btnThemeColor={btnThemeColor}
                                                btnTextColor={btnTextColor}
                                                termDesc={termDesc}
                                                enableTerm={enableTerm}
                                                eventType="single-page"
                                              />
                                            </Suspense>
                                          </>
                                        )}
                                      <br />
                                      <center>
                                        <Suspense fallback={<LazyLoader />}>
                                          {' '}
                                          {enablePublicLogo ? <PoweredBy id={locationid} /> : ''}
                                        </Suspense>
                                      </center>
                                    </>
                                  )}
                            </Paper>
                          </div>
                        </Grid>
                        <Typography className={classes.horizontalLine} />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={12}
              lg={12}
              className={classes.grid}
            />
          </Grid>
        </Paper>
      </>
    </>
  );
};

export default EventPublicURL;
