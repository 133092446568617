import React from 'react';
import { Button } from '@material-ui/core';
import './style.css';

export default function MuiCustomButton({
  muiStartIcon, muiEndIcon, muiHandleClick, muiVariant,
  muiClass, muiSize, muiDisable, muiBtnValue, muiBtnType,
  muiAriaControls, muiColor, muiAriaHasPopup, muiAutoFocus,
  muiAriaLabel, muiHref, muiTarget, muirel, muitype, muifullwidth, muiHandleChange,
}) {
  return (
    <Button
      className={muiClass}
      aria-controls={muiAriaControls}
      aria-label={muiAriaLabel}
      aria-haspopup={muiAriaHasPopup}
      color={muiColor}
      variant={muiVariant}
      size={muiSize}
      startIcon={muiStartIcon}
      endIcon={muiEndIcon}
      onClick={muiHandleClick}
      onChange={muiHandleChange}
      disabled={muiDisable}
      type={muiBtnType}
      autoFocus={muiAutoFocus}
      href={muiHref}
      target={muiTarget}
      typeof={muitype}
      rel={muirel}
      fullWidth={muifullwidth}
      style={{
        color: '#000',
        background: '#f8f8f8',
        cursor: 'pointer',
        border: '1px solid #005eb2',
        boxShadow: '2px 3px 2px 2px rgba(0,94,178,0.3), 0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        borderRadius: 12,
      }}
    >
      {muiBtnValue}
    </Button>
  );
}
