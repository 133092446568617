export const SocialProfilesBylocation = `query socialprofilesbylocation($locationid:ID!){socialprofilesbylocation(locationid:$locationid){
  placeid,profileid,locationid, googleurl, yelprl, facebookurl, tripadvisorurl, opentableurl, angielisturl, realtorurl,
  trustpiloturl, isenabledgoogle, isenabledyelprl, isenabledfacebookurl, isenabledtripadvisorurl, isenabledopentableurl,
    isenabledangielisturl, isenabledrealtorurl, isenabledtrustpiloturl, newreviewurl,
    isenabledg2, isenabledclutch, isenabledcapterra, isenabledapartments, g2, clutch, capterra, apartments }}`;

export const UpdateSocialProfileByLocation = `
            mutation
            updateSocialProfileByLocation(
                                $placeid: String,
                                $locationid: ID!,
                                $updatedby: ID!,
                                $googleurl: String,
                                $yelprl: String,
                                $facebookurl: String,
                                $tripadvisorurl: String,
                                $opentableurl: String,
                                $angielisturl: String,
                                $realtorurl: String,
                                $trustpiloturl: String,
                                $g2: String,
                                $clutch: String,
                                $capterra: String,
                                $apartments: String,
                                $isenabledgoogle: Boolean,
                                $isenabledyelprl: Boolean,
                                $isenabledfacebookurl: Boolean,
                                $isenabledtripadvisorurl: Boolean,
                                $isenabledopentableurl: Boolean,
                                $isenabledangielisturl: Boolean,
                                $isenabledrealtorurl: Boolean,
                                $isenabledtrustpiloturl: Boolean,
                                $isenabledg2: Boolean
                                $isenabledclutch: Boolean
                                $isenabledcapterra: Boolean
                                $isenabledapartments: Boolean
                            ) {
                            updateSocialProfileByLocation(
                                placeid: $placeid,
                                locationid: $locationid,
                                updatedby: $updatedby,
                                yelprl: $yelprl,
                                facebookurl: $facebookurl,
                                tripadvisorurl: $tripadvisorurl,
                                opentableurl: $opentableurl,
                                angielisturl: $angielisturl,
                                realtorurl: $realtorurl,
                                trustpiloturl: $trustpiloturl,
                                g2: $g2,
                                clutch: $clutch,
                                capterra: $capterra,
                                apartments: $apartments,
                                googleurl: $googleurl,
                                isenabledgoogle: $isenabledgoogle,
                                isenabledyelprl: $isenabledyelprl,
                                isenabledfacebookurl: $isenabledfacebookurl,
                                isenabledtripadvisorurl: $isenabledtripadvisorurl,
                                isenabledopentableurl: $isenabledopentableurl,
                                isenabledangielisturl: $isenabledangielisturl,
                                isenabledrealtorurl: $isenabledrealtorurl,
                                isenabledtrustpiloturl: $isenabledtrustpiloturl,
                                isenabledg2: $isenabledg2,
                                isenabledclutch: $isenabledclutch,
                                isenabledcapterra: $isenabledcapterra,
                                isenabledapartments: $isenabledapartments,
                            )
                        }`;
export const CreateSocialProfile = `
                        mutation
                        createSocialProfile(
                                            $placeid: String,
                                            $locationid: ID!,
                                            $createdby: ID!,
                                            $yelprl: String,
                                            $facebookurl: String,
                                            $tripadvisorurl: String,
                                            $opentableurl: String,
                                            $angielisturl: String,
                                            $realtorurl: String,
                                            $trustpiloturl: String,
                                            $g2: String,
                                            $clutch: String,
                                            $capterra: String,
                                            $apartments: String,
                                            $googleurl: String,
                                            $sourceid: Int
                                        ) {
                                          createSocialProfile(
                                            placeid: $placeid,
                                            locationid: $locationid,
                                            createdby: $createdby,
                                            googleurl: $googleurl,
                                            yelprl: $yelprl,
                                            facebookurl: $facebookurl,
                                            tripadvisorurl: $tripadvisorurl,
                                            opentableurl: $opentableurl,
                                            angielisturl: $angielisturl,
                                            realtorurl: $realtorurl,
                                            trustpiloturl: $trustpiloturl,
                                            g2: $g2,
                                            clutch: $clutch,
                                            capterra: $capterra,
                                            apartments: $apartments,
                                            sourceid: $sourceid 
                                        ) {
                                          profileid
                                        }
                                    }`;

export const RatingReviewScocialProfile = `query socialprofilesbylocation($locationid:ID!){socialprofilesbylocation(locationid:$locationid){
            placeid,profileid,locationid, googleurl, yelprl, facebookurl, tripadvisorurl, opentableurl, angielisturl, realtorurl,
            trustpiloturl, isenabledgoogle,isenabledyelprl, isenabledfacebookurl, isenabledtripadvisorurl, isenabledopentableurl,
              isenabledangielisturl, isenabledrealtorurl, isenabledtrustpiloturl, newreviewurl,
              isenabledg2, isenabledclutch, isenabledcapterra, isenabledapartments, g2, clutch, capterra, apartments }}`;
