import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  profileName: {
    marginTop: 25,
    fontWeight: 'bold',
  },
  subheading: {
    marginTop: 16,
  },
  avatarImg: {
    marginTop: '0px !important',
  },
  profileImage: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: '#005eb2',
    background: 'lightgray',
    marginTop: theme.spacing(3),
    fontSize: 32,
    fontWeight: 600,
    alignItems: 'center',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: '#005EB2',
    marginTop: theme.spacing(3),
    fontSize: 32,
    fontWeight: 600,
    alignItems: 'center',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  box1: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'end',
    },
  },
  placeholderIcon: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  videoResponsive: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  button: {
    marginRight: '20px',
    borderRadius: '50px',
    [theme.breakpoints.down('xs')]: {
      margin: '10px',
    },
  },
  btnlink: {
    textDecoration: 'none',
  },
  cal: {
    width: '90%',
    height: 'auto',
    padding: 37,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 15,
      width: '100%',
      height: 'auto',
    },
  },
  SocialIcon: {
    margin: 5,
  },
  Calendar: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  CalendarDate: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px !impotant',
    },
  },
  Button: {
    maxWidth: '20px',
    marginRight: 18,
  },
  ButtonPhone: {
    maxWidth: '20px',
    marginRight: 18,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  backMenu: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  time: {
    // marginTop: 100,
    maxHeight: '350px',
    minHeight: '350px',
    maxWidth: '100%',
    minWidth: '40%',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginLeft: '8%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
  },
  papers: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  details: {
    paddingLeft: 30,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  Disconnect: {
    backgroundColor: '#42ba96',
    color: 'white',
  },
  Cancel: {
    backgroundColor: '#42ba96',
    color: 'white',
    marginLeft: 10,
  },
  brandImage: {
    width: '170px',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      aspectRatio: 'auto',
    },
  },
  brandImages: {
    width: '22%',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '30%',
      aspectRatio: 'auto',
    },
  },
  heading: {
    alignItems: 'center',
  },
  headingLocation: {
    width: '300px',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      width: '250px',
      wordWrap: 'break-word',
    },
  },
  line: {
    textDecoration: 'line-through',
  },
  videoIcon: {
    marginBottom: -8,
  },
  unActiveLink: {
    marginTop: 50,
  },
  back: {
    border: '1px solid #005EB2',
  },
  root: {
    marginTop: '1%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '2%',
    },
  },
  profile: {
    borderTop: '1px solid #005EB2',
    paddingTop: 65,
  },
  inActiveProfileImg: {
    margin: '0 0 -3% 3%',
  },
  inActiveimg: {
    border: '2px solid #fff',
    aspectRatio: 'auto',
    width: '6%',
    height: 'auto',
  },
  profileImg: {
    margin: '0 0 -8% 3%',
  },
  img: {
    border: '2px solid #fff',
    aspectRatio: 'auto',
    width: '15%',
    height: 'auto',
  },
  notesheading: {
    fontSize: 16,
    fontWeight: 700,
  },
  notes: {
    fontSize: 13,
    fontWeight: 'normal',
  },
  notesimg: {
    width: '100%',
  },
}));
