import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  fieldBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  themeColor: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  centerDivWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  orderViewPaper: {
    padding: '1rem',
  },
  orderPaper: {
    margin: '1rem',
    padding: '1rem',
  },
  currencySelect: {
    marginTop: 4,
    width: 135,
  },
  currencyWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  amountWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#f8faff',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'end !important',
    },
  },
  heading: {
    width: 245,
  },
  headingDialog: {
    width: 245,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  commonField: {
    width: 250,
  },
  commonFieldDialog: {
    width: 250,
    height: 35,
  },
  customSelect: {
    width: 250,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  draftBtn: {
    borderRadius: '4px !important',
    boxShadow: 'none !important',
  },
  saveBtn: {
    borderRadius: '4px !important',
    boxShadow: 'none !important',
    backgroundColor: '#005eb2 !important',
    color: 'white !important',
    border: 'none !important',
  },
  cancelBtn: {
    borderRadius: '4px !important',
    boxShadow: 'none !important',
    backgroundColor: '#e41f41 !important',
    color: 'white !important',
    border: 'none !important',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleHead: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 20,
    fontWeight: 600,
  },
  backBtn: {
    padding: 0,
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tagName: {
    fontSize: 15,
    fontWeight: 600,
  },
  contactCount: {
    fontSize: 15,
    fontWeight: 600,
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    width: '100%',
    justifyContent: 'end',
  },
  orderBtn: {
    width: 114,
    height: 34,
    fontSize: 15,
  },
  table: {
    border: '1px solid #ebebeb',
  },
  rowBtn: {
    border: 'none !important',
    boxShadow: '1px 1px 3px 1px #a8a7a7 !important',
    borderRadius: '6px !important',
    fontWeight: '600 !important',
    backgroundColor: '#ff518b !important',
    color: 'white !important',
    fontSize: '12px !important',
  },
  customeBtn: {
    border: 'none !important',
    boxShadow: '1px 1px 3px 1px #a8a7a7 !important',
    borderRadius: '6px !important',
    fontWeight: '600 !important',
    backgroundColor: '#005eb2 !important',
    color: 'white !important',
    fontSize: '12px !important',
  },
  tableCell: {
    borderRight: '1px solid #ebebeb',
    borderBottom: '1px solid #ebebeb',
  },
  tableCellProd: {
    borderRight: '1px solid #ebebeb',
    borderBottom: '1px solid #ebebeb',
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: 160,
    },
  },
  tableCellQua: {
    borderRight: '1px solid #ebebeb',
    borderBottom: '1px solid #ebebeb',
    width: 140,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
  fieldBoxSub: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  headingSub: {
    width: 245,
    [theme.breakpoints.down('xs')]: {
      width: 120,
    },
  },
  commonFieldSub: {
    width: 245,
    [theme.breakpoints.down('xs')]: {
      width: 130,
    },
  },
  subPaper: {
    padding: 15,
  },
  totalWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  dialogContent: {
    fontSize: 17,
  },
  agreeBtn: {
    backgroundColor: '#005eb2 !important',
    color: 'white !important',
    border: 'none !important',
    borderRadius: '7px !important',
    padding: '5px 10px !important',
  },
  disAgreeBtn: {
    backgroundColor: '#FF5C93 !important',
    color: 'white !important',
    border: 'none !important',
    borderRadius: '7px !important',
    padding: '5px 10px !important',
  },
  fieldGap: {
    marginTop: 7,
  },
  paginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '5px',
    marginBottom: '10px',
  },
  linkText: {
    color: '#005eb2',
    fontWeight: 500,
    fontSize: '13.5px',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '1px solid #005eb2',
    },
  },
  head: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1, 1, 1),
    color: '#005eb2',
  },
  text: {
    fontSize: 16,
    alignItems: 'center',
    cursor: 'pointer',
  },
  headingPara: {
    padding: theme.spacing(1),
    width: '100%',
  },
  list: {
    padding: '8px 16px',
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  Papers: {
    backgroundColor: '#e8e8ee',
    color: '#000000',
    fontWeight: 500,
    minWidth: '100%',
    maxWidth: '100%',
  },
  dialogTitle: {
    color: `${localStorage.getItem('theme') === 'dark' ? 'white' : '#4A4A4A'} !important`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    paddingLeft: 18,
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  orderHeading: {
    fontSize: 16,
    fontWeight: 600,
    borderBottom: '2px solid #005eb2',
    paddingBottom: 5,
    marginBottom: 10,
  },
  hideSection: {
    display: 'none',
  },
  headingContact: {
    width: 245,
  },
  headingContactDialog: {
    width: 245,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  fieldValue: {
    fontWeight: 500,
    paddingLeft: 13,
    color: localStorage.getItem('theme') === 'dark' ? 'white !important' : '#252525 !important',
  },
  tableFieldValue: {
    fontWeight: 500,
    color: localStorage.getItem('theme') === 'dark' ? 'white !important' : '#252525 !important',
  },
}));
