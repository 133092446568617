import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  bixpoweredBy: {
    width: '140px',
    aspectRatio: 'auto',
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: '130px',
      aspectRatio: 'auto',
      objectFit: 'contain',
    },
  },
  rowsPerPageSelect: {
    marginLeft: '5px',
    height: '30px',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    fontSize: '12px',
    padding: '5px 10px',
    borderRadius: 5,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  pageNumberBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    height: '30px',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    fontSize: '12px',
    padding: '5px 10px',
    borderRadius: 5,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  arrowIcon: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#4a4a4a',
    fontSize: 13,
  },
  disableClass: {
    fontSize: 13,
    color: '#a4a4a4',
  },
  pageValue: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    fontSize: 12,
  },
  howToBtnR: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      color: 'lightgreen',
      display: 'inline-block',
      marginTop: '6px',
    },
  },
  elementBox: {
    display: 'flex',
    alignItems: 'center',
  },
  radiogroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  signatureTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  addCompose: {
    fontSize: 11,
    float: 'right',
    color: '#fff',
    border: '1px solid blue',
    borderRadius: 30,
    textDecoration: 'none',
    marginBottom: '8px',
    marginLeft: '15px',
    marginRight: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      marginRight: '5px',
      marginLeft: '3px',
      float: 'right',
      textDecoration: 'none',
    },
  },
  workflowOptions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  workflowList: {
    width: '100%',
    padding: 0,
  },
  addWf: {
    paddingRight: 3,
  },
  disabled: {
    backgroundColor: '#EBEBE4',
    width: '100%',
  },
  accordian: {
    width: '100%',
  },
  accordianDetails: {
    padding: '5px 16px 5px',
    paddingBottom: 0,
  },
  accordianSummery: {
    minHeight: 'auto',
    height: 'auto',
  },
  accordianHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  assignBtn: {
  },
  accordianHeading: {
    marginBottom: 0,
  },
  listItemTextAccordian: {
    cursor: 'pointer',
  },
  clsBtn: {
    color: 'white',
  },
  howToWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
}));
