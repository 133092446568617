import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, TextField, Box, Tooltip, Button, FormControlLabel, Switch,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TextsmsIcon from '@material-ui/icons/Textsms';
import PersonIcon from '@material-ui/icons/Person';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import {
  Entitiesbytypewithoutuser, Businessusagebybusinesswithtype, Businessusagebybusinessidandtype,
  Updatebusinessusagebyid, UpdateEntity,
} from '../../Api Mutation/EntityApi';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import config from '../../config';
import Widget from '../../components/Widget/Widget';
import useStyles from './Styles';
import { LoadingPage } from '../LoadingPage/LoadingPage';

const SvgStyle = {
  fontSize: '22px',
  '@media only screen and(maxWidth: 1420px)': {
    fontSize: '22px',
  },
};

export default function Businessplans() {
  const classes = useStyles();
  const [business, setBusiness] = useState();
  const [businessUsageId, setBusinessUsageId] = useState();
  const [verifyBusiness, setVerifyBusiness] = useState();
  const [usageData, setUsageData] = useState();
  const [businessList, setBusinessList] = useState([]);
  const [emailLimit, setEmailLimit] = useState();
  const [contactLimit, setContactLimit] = useState();
  const [textLimit, setTextLimit] = useState();
  const [storageLimit, setStorageLimit] = useState();
  const [userLimit, setUserLimit] = useState();
  const [callLimit, setCallLimit] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  const getBusinessLimit = (id) => {
    if (id) {
      setDataLoading(true);
      postDataRequest(config.baseURLApi, {
        query: Businessusagebybusinesswithtype,
        variables: {
          businessid: id,
          plantype: [1, 2],
        },
      }).then((res) => {
        const data = res?.data?.businessusagebybusinesswithtype;
        if (data) {
          setUsageData(data);
        } else if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        }
        setDataLoading(false);
      });
      setDataLoading(true);
      postDataRequest(config.baseURLApi, {
        query: Businessusagebybusinessidandtype,
        variables: {
          businessid: id,
          plantype: [1, 2],
        },
      }).then((res) => {
        const data = res?.data?.businessusagebybusinessidandtype;
        if (data) {
          setBusinessUsageId(data?.businessusageid);
          setContactLimit(data?.leadgatheringlimit);
          setEmailLimit(data?.emaillimit);
          setTextLimit(data?.textsmslimit);
          setStorageLimit(data?.storagelimit);
          setUserLimit(data?.userslimit);
          setCallLimit(data?.calltimelimit);
        } else if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        }
        setDataLoading(false);
      });
    }
  };

  const updateLeadInfo = () => {
    if (businessUsageId) {
      setDataLoading(true);
      postDataRequest(config.baseURLApi, {
        query: Updatebusinessusagebyid,
        variables: {
          businessusageid: businessUsageId,
          textsmslimit: handleUndefined(parseInt(textLimit, 10)) || 0,
          emaillimit: handleUndefined(parseInt(emailLimit, 10)) || 0,
          storagelimit: handleUndefined(parseInt(storageLimit, 10)) || 0,
          userslimit: handleUndefined(parseInt(userLimit, 10)) || 0,
          calltimelimit: handleUndefined(parseInt(callLimit, 10)) || 0,
          leadgatheringlimit: handleUndefined(parseInt(contactLimit, 10)) || 0,
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res.errors[0].message, 'error');
        } else if (res?.data?.updatebusinessusagebyid) {
          showNotification('Data updated successfully', 'success');
          getBusinessLimit(business);
        }
        setDataLoading(false);
      });
    }
  };

  const getBusinessByList = () => {
    setDataLoading(true);
    postDataRequest(config.baseURLApi, {
      query: Entitiesbytypewithoutuser,
      variables: {
        entitytypeid: 2,
      },
    }).then((res) => {
      if (res?.data?.entitiesbytypewithoutuser?.length > 0) {
        setBusinessList(res?.data?.entitiesbytypewithoutuser);
      } else if (res?.errors && res?.errors[0]?.message) {
        showNotification(res.errors[0].message, 'error');
      }
      setDataLoading(false);
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getBusinessByList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setVerifyBusiness(e.target.checked);
    postDataRequest(config.baseURLApi, {
      query: UpdateEntity,
      variables: {
        entityid: business,
        isbusinessverified: e?.target?.checked,
      },
    }).then((res) => {
      if (res?.errors && res?.errors[0]?.message) {
        showNotification(res.errors[0].message, 'error');
      } else if (res?.data?.updateEntity) {
        showNotification('Data updated successfully', 'success');
      }
    });
  };

  return (
    <div>
      {dataLoading ? <LoadingPage /> : ''}
      <Widget disablewidgetmenu="true" inheritheight="true">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h1">Business Plans</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Autocomplete
              id="Location"
              size="small"
              fullWidth
              options={businessList}
              getOptionLabel={(option) => option?.businessname}
              renderOption={(option) => (
                <>
                  {option?.businessname}
                  {' '}
                  (
                  {option?.email}
                  )
                </>
              )}
              onChange={(event, newValue) => {
                setBusiness(newValue?.entityid);
                getBusinessLimit(newValue?.entityid);
                setVerifyBusiness(newValue?.isbusinessverified);
              }}
              renderInput={(params) => <TextField fullWidth required {...params} label="Select Business" name="Select Business" variant="outlined" />}
            />
          </Grid>
          <Grid item md={1} xs={12} />
        </Grid>
      </Widget>
      <br />
      {business ? (
        <>
          <Widget
            disablewidgetmenu="true"
            inheritheight="true"
            size="small"
            className={classes.margin}
            bodyclass={classes.navPadding}
          >
            <Box className={classes.useageReport}>
              <Box className={classes.useageCount}>
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="space-start;">
                  <Box>
                    <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/businessplan.svg" alt="businessplan" style={SvgStyle} />
                  </Box>
                  <Typography variant="body1" className={classes.reportText1}>
                    &nbsp;
                    {usageData?.leadgathering || 0}
                    /
                    {usageData?.leadgatheringlimit || 0}
                    {' '}
                    Contact
                  </Typography>
              &nbsp;
                  <Tooltip title="1 Contact for 1 Credit">
                    <ErrorOutlineIcon className={classes.iconSize} color="primary" />
                  </Tooltip>
                </Box>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="space-start;">
                  <EmailIcon
                    color="primary"
                    style={{ fontSize: 22 }}
                  />
                  <Typography variant="body1">
                    &nbsp;
                    {usageData?.email || 0}
                    /
                    {usageData?.emaillimit || 0}
                    {' '}
                    Emails
                  </Typography>
              &nbsp;
                  <Tooltip
                    title={(
                      <>
                        <Typography variant="body2">1 Email for 1 Credit</Typography>
                        <Typography variant="body2">1 Email Verify for 1 Credit</Typography>
                      </>
                  )}
                  >
                    <ErrorOutlineIcon className={classes.iconSize} color="primary" />
                  </Tooltip>
                </Box>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="space-start;">
                  <TextsmsIcon color="primary" style={{ fontSize: 22 }} />
                  <Typography variant="body1">
                    &nbsp;
                    {usageData?.textsms || 0}
                    /
                    {usageData?.textsmslimit || 0}
                    {' '}
                    Text
                  </Typography>
              &nbsp;
                  <Tooltip
                    title={(
                      <>
                        <Typography variant="body2">1 SMS for 1 Credit</Typography>
                        <Typography variant="body2">1 Phonenumber Verify for 1 Credit</Typography>
                      </>
                  )}
                  >
                    <ErrorOutlineIcon className={classes.iconSize} color="primary" />
                  </Tooltip>
                </Box>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="space-start;">
                  <PhoneIcon color="primary" style={{ fontSize: 22 }} />
                  <Typography variant="body1">
                    &nbsp;
                    {Number(parseFloat(usageData?.calltime / 60).toFixed(2)) || 0}
                    /
                    {Number(parseFloat(usageData?.calltimelimit / 60).toFixed(2)) || 0}
                    {' '}
                    Calls
                  </Typography>
              &nbsp;
                  <Tooltip
                    title="60 sec for 1 Credit"
                  >
                    <ErrorOutlineIcon className={classes.iconSize} color="primary" />
                  </Tooltip>
                </Box>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="space-start;">
                  <DataUsageIcon color="primary" style={{ fontSize: 22 }} />
                  <Typography variant="body1">
                    &nbsp;
                    {usageData?.storage || 0}
                    /
                    {usageData?.storagelimit || 0}
                    {' '}
                    Storage
                  </Typography>
              &nbsp;
                  <Tooltip
                    title="1 MB for 1 Credit"
                  >
                    <ErrorOutlineIcon className={classes.iconSize} color="primary" />
                  </Tooltip>
                </Box>
            &nbsp;&nbsp;&nbsp;&nbsp;
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="space-start;">
                  <PersonIcon color="primary" style={{ fontSize: 22 }} />
                  <Typography variant="body1">
                    &nbsp;
                    {usageData?.users || 0}
                    /
                    {usageData?.userslimit || 0}
                    {' '}
                    Users
                  </Typography>
              &nbsp;
                  <Tooltip
                    title="User Credit"
                  >
                    <ErrorOutlineIcon className={classes.iconSize} color="primary" />
                  </Tooltip>
                </Box>
              </Box>
              <FormControlLabel
                control={(
                  <Switch
                    checked={verifyBusiness}
                    onChange={(e) => handleChange(e)}
                    name="verify"
                    color="primary"
                  />
        )}
                label="business Verified"
              />
            </Box>
          </Widget>
          <br />
          <Widget
            disablewidgetmenu="true"
            inheritheight="true"
            size="small"
            className={classes.margin}
            bodyclass={classes.navPadding}
          >
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Contact Limit"
                  size="small"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setContactLimit(e.target.value)}
                  value={contactLimit}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Email Limit"
                  size="small"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEmailLimit(e.target.value)}
                  value={emailLimit}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Text Limit"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setTextLimit(e.target.value)}
                  value={textLimit}
                  type="number"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Call Limit"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setCallLimit(e.target.value)}
                  value={callLimit}
                  type="number"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Storage Limit"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setStorageLimit(e.target.value)}
                  value={storageLimit}
                  type="number"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="User Limit"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setUserLimit(e.target.value)}
                  value={userLimit}
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => updateLeadInfo()}
              startIcon={<SystemUpdateAltIcon />}
            >
              Update
            </Button>
          </Widget>
        </>
      ) : (
        <Box className={classes.placeholder}>
          <center>
            <AccountBalanceOutlinedIcon className={classes.placeholderIcon} />
            <br />
            <Typography>
              Select Your Business.
            </Typography>
          </center>
        </Box>
      )}
    </div>
  );
}
