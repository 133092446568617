export const TwillioSettingsbylocation = `query twillioSettingsbylocation($locationid: ID!) {
  twillioSettingsbylocation(locationid: $locationid) {
    id
    locationid
    twiliosid
    twilioauthtoken
    twilionumber
    twilioserviceid
    twiliocallappsid
    twiliocallnumber
  }
}`;

export const SendBuyTwilioNumberEmail = `
mutation 
sendbuytwilionumberemail(
  $businessid: ID!
  ){
    sendbuytwilionumberemail(
      businessid: $businessid
      )
  }`;

export const EditTwilioCredential = `
mutation editTwiliocredential($locationid: ID!, $twiliosid: String!, $twilioauthtoken: String!, $twilionumber: String, $twilioserviceid: String, $twiliocallappsid: String, $twiliocallnumber: String) {
  editTwiliocredential(locationid: $locationid, twiliosid: $twiliosid, twilioauthtoken: $twilioauthtoken, twilionumber: $twilionumber, twilioserviceid: $twilioserviceid, twiliocallappsid: $twiliocallappsid, twiliocallnumber: $twiliocallnumber)
}`;

export const AddTwiliocredential = `mutation addTwiliocredential($locationid: ID!, $twiliosid: String!, $twilioauthtoken: String!, $twiliocallappsid: String, $twilionumber: String, $twilioserviceid: String, $twiliocallnumber: String) {
    addTwiliocredential(locationid: $locationid, twiliosid: $twiliosid, twilioauthtoken: $twilioauthtoken, twiliocallappsid: $twiliocallappsid, twilionumber: $twilionumber, twilioserviceid: $twilioserviceid, twiliocallnumber: $twiliocallnumber)
  }`;

export const getToken = `query Query($locationid: ID!) {
  getToken(locationid: $locationid)
}`;

export const CheckCall = `query checkCall($number:String!){
  checkCall(number:$number)
}`;
