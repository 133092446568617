import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import useStyles from './styles';
import { handleUndefined, postDataRequestPublic } from '../../../common/commonfunctions';
import CampaignBrand from '../../CampaignBrand/CampaignBrand';
import config from '../../../config';
import {
  useGlobalState,
  setLoginUserInfo,
} from '../../../context/GlobalContext';
import { useEntitiesState } from '../../../context/EntityContext';
import PoweredBy from '../../../common/PoweredBy';

const StyledRating = withStyles({
  iconFilled: {
    color: '#0259a8',
  },
  iconHover: {
    color: '#0259a8',
  },
  iconEmpty: {
    color: '#74b6f7',
  },
  sizeLarge: {
    fontSize: '3rem',
  },
})(Rating);
export default function RatingNagativeLanding({
  brandInfo,
  reviewMsg,
  handleReviewMsg,
  title,
  handletitle,
  error,
}) {
  const classes = useStyles();
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const [entity, setEntity] = useState([]);
  const [rating, setRating] = useState(5);
  useEffect(() => {
    let isMounted = true;
    if (entityContext?.entities?.businessData
        && entityContext?.entities?.businessData[0]?.entityid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query entity($entityid:ID!){
              entity(entityid:$entityid){
                entityid
                businessname
                brandname
                firstname
                lastname
                email
                phonenumber
                entitytypeid
                businesstypeid
                jobid
                tags
                website
                shortdesc
                fulldesc
                policy
                terms
                }}`,
        variables: {
          entityid:
                    entityContext?.entities?.businessData
                    && entityContext?.entities?.businessData[0]?.entityid,
        },
      })
        .then((res) => {
          if (res.data && res.data.entity && isMounted) {
            setEntity(res.data.entity);
          }
        })
        .catch(() => {});
    }

    return () => {
      isMounted = false;
    };
  }, [
    entityContext?.entities?.businessData
            && entityContext?.entities?.businessData[0]?.entityid,
  ]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoginUserInfo(globalContext.setGlobalState);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && localStorage.getItem('userid')) {
      if (entityContext?.entities?.businessData
      && entityContext?.entities?.businessData[0]?.entityid) {
        postDataRequestPublic(config.baseURLApi, {
          query: `
      query brandSettingByLocation($locationid:ID!)
            {brandSettingByLocation(locationid:$locationid){color,dateformat,timeformat,logourl}}
          `,
          variables: {
            locationid:
                      entityContext?.entities?.businessData
                      && entityContext?.entities?.businessData[0]?.entityid,
          },
        })
          .then(() => {})
          .catch(() => {});
      }
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('userid')]);

  const handlePolicy = () => {
    window.open(entity.policy, '_blank');
  };
  const handleTerms = () => {
    window.open(entity.terms, '_blank');
  };
  return (
    <>
      <Box width="100%">
        <center>
          <img
            className={classes.large2}
            src={brandInfo?.logo}
            alt="brand logo"
          />
          <br />
          <Typography variant="h6">{brandInfo?.brandname}</Typography>
        </center>
        <br />
        <Box className={classes.positiveExperienceCard}>
          <b>
            <Typography
              variant="body2"
              className={classes.marginZero}
              style={{ display: 'flex' }}
            >
              Review your Customer &nbsp;
              <CampaignBrand />
                        &nbsp; Experience with Your review matters !!!
            </Typography>
          </b>
        </Box>
        <Grid container className={classes.positiveFeedBackBox}>
          <Grid>
            <Typography variant="body2" className={classes.addMg}><b>Give your rating</b></Typography>
            <Box display="flex">
              <StyledRating
                name="customized-color size-large"
                value={rating}
                onChange={(value) => setRating(value.target.value)}
                precision={1}
                size="large"
                readOnly
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.addMg}><b>Your review</b></Typography>
            <Box>
              <TextField
                id="outlined-multiline-static"
                label="Share your honest experience, and help others make better choices."
                multiline
                minRows={10}
                variant="outlined"
                className={classes.addMg}
                fullWidth
                value={reviewMsg}
                onChange={handleReviewMsg}
              />
              {error && (
                <>
                  {!reviewMsg && (
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color="error"
                    gutterBottom
                  >
                    Review Message is required
                  </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.addMg}><b>Title of your review</b></Typography>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handletitle}
            />
            {error && (
            <>
              {!title && (
              <Typography
                variant="subtitle2"
                component="p"
                color="error"
                gutterBottom
              >
                Title is required
              </Typography>
              )}
            </>
            )}
          </Grid>
        </Grid>
      </Box>
      <center>
        <Box
          marginTop={1}
          marginBottom={1}
        >
          {handleUndefined(entity.policy) && (
            <>
              {' '}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p
                style={{ textDecoration: 'none' }}
                onClick={handlePolicy}
              >
                {' '}
                Privacy Policy
              </p>
              {' '}
              /
              {' '}
            </>
          )}
          {' '}
          {handleUndefined(entity.terms) && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <p
              style={{ textDecoration: 'none' }}
              onClick={handleTerms}
            >
              Terms & Conditions
            </p>
          )}
        </Box>
        <PoweredBy id={entityContext?.entities?.businessData
                        && entityContext?.entities?.businessData[0]?.entityid}
        />
      </center>
    </>
  );
}
