export const DataBySubDomain = `query Entitybysubdomain($subdomain: String) {
    entitybysubdomain(subdomain: $subdomain) {
      entityid
      businessname
      brandname
      firstname
      lastname
      email
      phonenumber
      entitytypeid
      parententityid
      businesstypeid
      createdby
      updatedby
      deleted
      jobid
      tags
      ownerid
      totalreview
      nps
      website
      shortdesc
      fulldesc
      createdAt
      updatedAt
      phonecountryid
      policy
      terms
      displaylocationdoncampaign
      publicpageviewcount
      customercount
      businesscount
      locationcount
      reviewscount
      status
      defaultcountrycode
      defaultphonecountrycode
      trialplanid
      paidplanid
      isfirstsubmitted
      iscreatedagency
      isfreebusiness
      trialplanenddate
      coverpageurl
      iscallrecording
      isbusinessverified
      logourl
      favicon
      subdomain
      bixfeepercentage
      publiclogotype
      referredby
      referralcode
      referralcoupon
    }
  }`;
