export const Planavailablebalance = `
query planavailablebalance($businessid: ID!) {
  planavailablebalance(businessid: $businessid) {
    businessid
    locationid
    trialplanid
    curruntplanid
    subscriptionid
    isfreeplan
    plantype
    paymentid
    userslimit
    textsmslimit
    emaillimit
    storagelimit
    calltimelimit
    textsms
    users
    email
    storage
    calltime
    leadgatheringlimit
    leadgathering
    trialplanenddate
    startdate
    enddate
    isactive
    deleted
    isunlimitedusers
    isunlimitedtextsms
    isunlimitedemail
    isunlimitedstorage
    isunlimitedcalltime
    isunlimitedleadgathering
    isbusinessverified
    socialpostlimit
    socialpost
    isunlimitedsocialpost
  }
}`;

export const Businessesbyreferral = `query businessesbyreferral($businessid: ID!) {
  businessesbyreferral(businessid: $businessid) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    referredby
    referralcode
    useddate
    amountoff
    percentoff
  }
}`;
