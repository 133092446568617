import React from 'react';
import Message from '../Messages/Message';

export default function AccessError() {
  return (
    <div>
      <center>
        <h2>{Message.accessError}</h2>
      </center>
    </div>
  );
}
