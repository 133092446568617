import React, { useState, useEffect } from 'react';
import {
  Typography, TextField, Grid, Box,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import useStyles from './styles';
import { postDataRequestPublic, handleUndefined } from '../../../common/commonfunctions';
import config from '../../../config';

import {
  useGlobalState,
  setLoginUserInfo,
} from '../../../context/GlobalContext';
import { useEntitiesState } from '../../../context/EntityContext';

export default function PositiveLanding({
  activeRating,
  brandInfo,
  reviewMsg,
  handleReviewMsg,
  handleRating,
  handletitle,
  title,
  error,
  userName,
}) {
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const [rating, setRating] = useState();
  const params = new URLSearchParams(search);
  const bid = params.get('bid');
  const r = params.get('r');
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const [entity, setEntity] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (bid && bid !== 'undefined') {
        setRating(parseInt(r, 10));
      }
      setLoginUserInfo(globalContext.setGlobalState);
    }

    return () => {
      isMounted = false;
    };
  }, [bid, r]);
  useEffect(() => {
    let isMounted = true;
    if (entityContext?.entities?.businessData
        && entityContext?.entities?.businessData[0]?.entityid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query entity($entityid:ID!){
                entity(entityid:$entityid){
                  entityid
                  businessname
                  brandname
                  firstname
                  lastname
                  email
                  phonenumber
                  entitytypeid
                  businesstypeid
                  jobid
                  tags
                  website
                  shortdesc
                  fulldesc
                  policy
                  terms
                  }}`,
        variables: {
          entityid:
                    entityContext?.entities?.businessData
                    && entityContext?.entities?.businessData[0]?.entityid,
        },
      })
        .then((res) => {
          if (res?.data && res?.data?.entity && isMounted) {
            setEntity(res?.data?.entity);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [
    entityContext?.entities?.businessData
            && entityContext?.entities?.businessData[0]?.entityid,
  ]);
  useEffect(() => {
    let isMounted = true;
    if (localStorage.getItem('userid')) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query entity($entityid:ID!){
              entity(entityid:$entityid){
                entityid
                businessname
                brandname
                firstname
                lastname
                email
                phonenumber
                entitytypeid
                businesstypeid
                jobid
                tags
                website
                shortdesc
                fulldesc
                policy
                terms
                }}`,
        variables: {
          entityid: JSON.parse(localStorage.getItem('userid')),
        },
      })
        .then((res) => {
          if (res.data && res.data.entity && isMounted) {
            setEntity(res.data.entity);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('userid')]);
  return (
    <>
      {brandInfo?.logo ? (
        <>
          <center>
            <img
              className={classes.large2}
              src={brandInfo?.logo}
              alt="brandImage"
            />
            <br />
          </center>
        </>
      ) : ''}
      <br />
      <Box className={classes.positiveExperienceCard}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2" className={classes.marginZero}>
            <b>
              Review your Customer Experience with
              {' '}
              {handleUndefined(brandInfo?.brandname) ? (
                <>
                  {handleUndefined(brandInfo?.brandname)}
                </>
              ) : (
                <>
                  {handleUndefined(brandInfo?.businessname)}
                </>
              )}
              {brandInfo.displaylocationdoncampaign === true ? (
                <>
                  ,&nbsp;
                  {' '}
                  {handleUndefined(brandInfo?.locationname)}
                </>
              ) : ''}
            </b>
          </Typography>
        </Box>
      </Box>
      <Grid container justifyContent="center" className={classes.positiveFeedBackBox}>
        <Grid item md={6} xs={12}>
          <Typography
            variant="body2"
            style={{ marginBottom: '8px' }}
          >
            <b>Please provide your rating</b>
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <div
              onClick={() => handleRating(1)}
              className={`${classes.shape} ${classes.redRating} ${
                activeRating === 1 ? classes.activeRating : ''
              }`}
            >
              1
            </div>
            <div
              onClick={() => handleRating(2)}
              className={`${classes.shape} ${classes.redRating} ${
                activeRating === 2 ? classes.activeRating : ''
              }`}
            >
              2
            </div>
            <div
              onClick={() => handleRating(3)}
              className={`${classes.shape} ${classes.redRating} ${
                activeRating === 3 ? classes.activeRating : ''
              }`}
            >
              3
            </div>
            <div
              onClick={() => handleRating(4)}
              className={`${classes.shape} ${classes.redRating} ${
                activeRating === 4 ? classes.activeRating : ''
              }`}
            >
              4
            </div>
            <div
              onClick={() => handleRating(5)}
              className={`${classes.shape} ${classes.redRating} ${
                activeRating === 5 ? classes.activeRating : ''
              }`}
            >
              5
            </div>
            <div
              onClick={() => handleRating(6)}
              className={`${classes.shape} ${classes.redRating} ${
                activeRating === 6 ? classes.activeRating : ''
              }`}
            >
              6
            </div>
            <div
              onClick={() => handleRating(7)}
              className={`${classes.shape} ${
                classes.yellowRating
              } ${
                activeRating === 7 ? classes.activeRating : ''
              }`}
            >
              7
            </div>
            <div
              onClick={() => handleRating(8)}
              className={`${classes.shape} ${
                classes.yellowRating
              } ${
                activeRating === 8 ? classes.activeRating : ''
              }`}
            >
              8
            </div>
            <div
              onClick={() => handleRating(9)}
              className={`${classes.shape} ${
                classes.greenRating
              } ${
                activeRating === 9 ? classes.activeRating : ''
              }`}
            >
              9
            </div>
            <div
              onClick={() => handleRating(10)}
              className={`${classes.shape} ${
                classes.greenRating
              } ${
                activeRating === 10 ? classes.activeRating : ''
              }`}
            >
              10
            </div>
          </Box>
          <Box marginTop={1} display="flex" justifyContent="space-between">
            <Box fontWeight="fontWeightBold"><Typography variant="body2"><SentimentVeryDissatisfiedIcon style={{ color: '#F04D5D' }} /></Typography></Box>
            <Box fontWeight="fontWeightBold">
              <Typography variant="body2"><SentimentDissatisfiedIcon style={{ color: '#F04D5D' }} /></Typography>
            </Box>
            <Box fontWeight="fontWeightBold" marginRight={2}>
              <Typography variant="body2"><SentimentSatisfiedAltIcon style={{ color: '#5FBF91' }} /></Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={1} />
        <Grid item md={5} xs={12}>
          <Typography variant="body2" className={classes.titleText}>
            <b>Title of your review</b>
          </Typography>
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            className={classes.fieldColor}
            onChange={handletitle}
          />
          {error && (
            <>
              {!title && (
              <Typography
                variant="subtitle2"
                component="p"
                color="error"
                gutterBottom
              >
                Title is required
              </Typography>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.titleText}>
            <b>Your review</b>
          </Typography>
          <Box>
            <TextField
              id="outlined-multiline-static"
              label="Share your honest experience, and help others make better choices."
              multiline
              placeholder={`You have rated ${entity?.businessname} service ${rating} stars. Could you tell them what they did well? Would you recommend them or buy from them again?`}
              minRows={10}
              className={classes.fieldColor}
              variant="outlined"
              fullWidth
              value={reviewMsg}
              onChange={handleReviewMsg}
            />
            {error && (
            <>
              {!reviewMsg && (
              <Typography
                variant="subtitle2"
                component="p"
                color="error"
                gutterBottom
              >
                Review Message is required
              </Typography>
              )}
            </>
            )}
          </Box>
          <Typography variant="body2">
            Your name:
            {' '}
            {userName || '<<customer Name>>'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
