import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    '& .MuiTab-wrapper': {
      display: 'flex !important',
    },
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none',
    },
  },
  actionIcon: {
    color: '#005EB2',
  },
  iconTheme: {
    color: localStorage.getItem('theme') ? 'white' : '#005eb2',
  },
  call: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  callerImage: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginTop: theme.spacing(1),
    fontSize: 32,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  selectType: {
    padding: '7px 14px',
    margin: '8px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: 150,
  },
  papers: {
    padding: '2px 10px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  backMenuLink: {
    float: 'left',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'left',
      textDecoration: 'none',
    },
  },
  margin: {
    backgroundColor: '#fff',
    padding: `${theme.spacing(3)}px !important`,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  emailbtn: {
    fontSize: 12,
    color: '#fff',
    float: 'left',
    backgroundColor: '#005EB2',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  strip: {
    padding: '7px',
  },
  navPaddingLocation: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
    height: 'calc( 100vh - 82px )',
    overflowY: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  calendericon: {
    width: 40,
    height: 40,
    [theme.breakpoints.down(400)]: {
      width: 33,
      height: 33,
    },
  },
  consultantText: {
    [theme.breakpoints.down(400)]: {
      fontSize: '12px',
      marginTop: '5px',
    },
  },
  consultantIcon: {
    fontSize: '40px',
    [theme.breakpoints.down(400)]: {
      fontSize: '33px',
    },
  },
  callDiv: {
    [theme.breakpoints.down(400)]: {
      marginTop: '-18px',
    },
  },
  notes: {
    marginTop: '40px',
  },
  placeholderIcon: {
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  TableCellhead: {
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  TableCell: {
    padding: 1,
    alignItems: 'center',
    fontSize: '12px',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  socialBtn: {
    minWidth: 40,
    maxWidth: 40,
  },
  Dropzone: {
    height: '150px',
    textAlign: 'center',
    padding: '15px',
    border: '3px dashed #eeeeee',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    marginBottom: '22px',
  },
  roots: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  table: {
    width: '100%',
    padding: 40,
  },
}));
