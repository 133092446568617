export const EventListByUser = `query EventListByUser($userid: ID!) {
    eventListByUser(userid: $userid) {
      eventlistid
      name
      locationid
      isdefault
      pageurl
      businessid
      userid
      eventlist
      createdby
      updatedby
      createdAt
      updatedAt
    }
  }`;

export const EventListByURL = `query EventListByUrl($pageurl: String!) {
  eventListByUrl(pageurl: $pageurl) {
    businessid
    createdAt
    isshowfooterlogo
    createdUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      twiliophonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
      defaulttaskview
      defaultdealview
      defaulttaskviewid
      defaultfolderid
      createdAt
      avatar
    }
    createdby
    eventlist
    eventlistid
    events {
      eventid
      eventtype
      name
      location
      description
      eventbookingurl
      eventlink
      eventcolor
      eventdaterangtype
      scheduleday
      scheduletype
      eventstartdate
      eventenddate
      eventduration
      beforeeventbuffer
      aftereventbuffer
      isemailreminders
      isemailfollowup
      isshowphoneicon
      isshowwhatsappicon
      isshowiosicon
      isshowandroidicon
      createdby
      updatedby
      deleted
      locationid
      status
      timezone
      createdAt
      updatedAt
      totalbooking
      futurebooking
      todaybooking
      ispaidevent
      amount
      isshowuseravatar
      workflowid
      termenable
      termdesc
      captchaenable
      formdata
      themecolor
      backgroundcolor
      textcolor
      calloutbuttontext
      calloutbtnposition
      btnthemecolor
      btntextcolor
      shorturl
      isshowlogo
      isshowfooterlogo
      fieldtextcolor
      createdUser {
        userid
        roleid
        firstname
        lastname
        phonenumber
        twiliophonenumber
        email
        role
        lastlogin
        deleted
        usertype
        businessid
        locationid
        defaultcontactview
        defaulttaskview
        defaultdealview
        defaulttaskviewid
        defaultfolderid
        createdAt
        avatar
      }
    }
    isdefault
    locationid
    name
    pageurl
    updatedAt
    updatedby
    userid
  }
}`;

export const CreateEventList = `mutation CreateEventList($name: String!, $userid: ID!, $createdby: ID!, $pageurl: String, $businessid: ID, $eventlist: [ID], $isdefault: Boolean, $locationid: ID, $isshowfooterlogo:Boolean) {
    createEventList(name: $name, userid: $userid, createdby: $createdby, pageurl: $pageurl, businessid: $businessid, eventlist: $eventlist, isdefault: $isdefault, locationid: $locationid, isshowfooterlogo:$isshowfooterlogo) {
      eventlistid
      name
      locationid
      isdefault
      pageurl
      businessid
      userid
      eventlist
      createdby
      updatedby
      isshowfooterlogo
      createdAt
      updatedAt
    }
  }`;

export const UpdateEventList = `mutation UpdateEventList($eventlistid: ID!, $name: String!, $userid: ID!, $updatedby: ID!, $pageurl: String, $businessid: ID, $eventlist: [ID], $locationid: ID, $isdefault: Boolean, $isshowfooterlogo:Boolean) {
    updateEventList(eventlistid: $eventlistid, name: $name, userid: $userid, updatedby: $updatedby, pageurl: $pageurl, businessid: $businessid, eventlist: $eventlist, locationid: $locationid, isdefault: $isdefault, isshowfooterlogo:$isshowfooterlogo)
  }`;

export const DeleteEventList = `mutation DeleteEventList($eventlistid: ID!) {
    deleteEventList(eventlistid: $eventlistid)
  }`;

export const GetEventListById = `query EventListById($eventlistid: ID!) {
  eventListById(eventlistid: $eventlistid) {
    eventlistid
    name
    locationid
    isdefault
    pageurl
    businessid
    userid
    eventlist
    createdby
    updatedby
    isshowfooterlogo
    createdAt
    updatedAt
  }
}`;
