import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  Chip,
  Typography,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CampaignBrand from '../CampaignBrand/CampaignBrand';
import useStyles from './SmsReviewStyle';
import BusinessFavicon from '../../common/BusinessFavicon';
import '../phoneInput.css';
import config from '../../config';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import PoweredBy from '../../common/PoweredBy';
import { BrandSettingByLocation, Entity } from '../../Api Mutation/EntityApi';

export default function ReviewSms() {
  const classes = useStyles();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const bid = params.get('bid');
  const lid = params.get('lid');
  const custid = params.get('custid');
  const campid = params.get('campid');
  const [entityLocation, setEntityLocation] = useState([]);
  const [brandImage, setBrandImage] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [entity, setEntity] = useState([]);
  const overview = React.useRef(null);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (handleUndefined(bid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: BrandSettingByLocation,
          variables: {
            locationid: handleUndefined(bid),
          },
        }).then((res) => setBrandImage(res?.data?.brandSettingByLocation?.logourl));
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: bid,
          },
        });
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: bid,
          },
        }).then((res) => {
          if (res.data && res.data.entity) {
            setEntity(res.data.entity);
          }
        })
          .then((res) => {
            if (res?.data && res?.data?.entity) {
              setEntity(res?.data?.entity);
            }
          });
      }
      if (handleUndefined(lid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: lid,
          },
        })
          .then((res) => {
            if (res?.data?.entity) {
              setEntityLocation(res?.data?.entity);
              setFavIcon(res?.data?.entity?.favicon);
              setLocationBrandImage(res?.data?.entity?.logourl);
            }
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [bid]);

  return (
    <div>
      <div>
        <BusinessFavicon
          logo={favIcon || locationBrandImage || brandImage}
          name={entityLocation?.businessname}
        />
        <AppBar color="inherit" ref={overview}>
          <Toolbar>
            <img className={classes.logo} src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png" alt="App" />
            <Typography variant="h6">
              App Reviews
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                href={config.BixPublicUrl}
              >
                <Chip
                  icon={<OpenInNewIcon />}
                  label="Visit Our Site"
                  clickable
                  color="primary"
                />
              </a>
            </Typography>
          </Toolbar>
          <div
            style={{
              backgroundImage: 'url(https://storage.googleapis.com/bixpand-staging/WebsiteImages/border.png)',
              height: '2px',
            }}
          />
        </AppBar>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Box className={classes.width}>
        <Grid container justifyContent="center" spacing={6}>
          <Grid item md={11} xs={12}>
            <Card className={classes.cardMedia}>
              <center>
                <CardContent style={{ paddingBottom: 16 }}>
                  {brandImage ? (
                    <>
                      <img
                        className={classes.large2}
                        src={handleUndefined(brandImage)}
                        alt="brandImage"
                      />
                    </>
                  ) : ''}
                  <br />
                  <Typography
                    className={classes.templateText}
                    variant="h6"
                    style={{ marginTop: '10px' }}
                  >
                    <Box>We&apos;d love your feedback!</Box>
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    marginTop={2}
                    marginBottom={2}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-around"
                    >
                      <Button
                        href={`${
                          config.BixUrl
                        }/negative-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=1`}
                        size="small"
                        className={classes.darkPink}
                      >
                        1
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/negative-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=2`}
                        size="small"
                        className={classes.darkPink}
                      >
                        2
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/negative-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=3`}
                        size="small"
                        className={classes.darkPink}
                      >
                        3
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/negative-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=4`}
                        size="small"
                        className={classes.darkPink}
                      >
                        4
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/negative-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=5`}
                        size="small"
                        className={classes.darkPink}
                      >
                        5
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/negative-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=6`}
                        size="small"
                        className={classes.darkPink}
                      >
                        6
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/positive-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=7`}
                        size="small"
                        className={classes.darkYellow}
                      >
                        7
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/positive-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=8`}
                        size="small"
                        className={classes.darkYellow}
                      >
                        8
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/positive-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=9`}
                        size="small"
                        className={classes.darkGreen}
                      >
                        9
                      </Button>
                      <Button
                        href={`${
                          config.BixUrl
                        }/positive-comment?campid=${campid
                                                    || null}&custid=${custid
                                                    || null}&bid=${bid}&lid=${lid}&r=10`}
                        size="small"
                        className={classes.darkGreen}
                      >
                        10
                      </Button>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      className={classes.templateTextbody}
                    >
                      <Box
                        fontSize={12}
                        marginTop={1}
                        marginLeft={2}
                        fontWeight="bold"
                      >
                        Not at all
                        {' '}
                        <br />
                        Likely
                      </Box>
                      <Box
                        fontSize={12}
                        marginTop={1}
                        marginRight={2}
                        fontWeight="bold"
                      >
                        Extremely
                        {' '}
                        <br />
                        Likely
                      </Box>
                    </Box>
                  </Box>
                  <br />
                  <Box
                    className={classes.text}
                    marginBottom={1}
                    fontSize="1.142rem"
                  >
                    <Box fontWeight="fontWeightBold" fontSize="1.142rem">Sincerely</Box>
                    <b>{(handleUndefined(entity?.brandname) ? `${handleUndefined(entity?.brandname)}, ` : '') + handleUndefined(entityLocation?.businessname) || ''}</b>
                    <Box fontWeight="fontWeightBold" fontSize="1.142rem">
                      {' '}
                      {handleUndefined(<CampaignBrand />) && (
                      <Typography variant="h6">
                        {handleUndefined(<CampaignBrand />)}
                      </Typography>
                      )}
                    </Box>
                  </Box>
                  <hr
                    style={{ border: '1.0px solid#627FE3' }}
                  />
                  <PoweredBy id={lid} />
                </CardContent>
              </center>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
