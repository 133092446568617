import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  lazyDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bixImg: {
    width: 55,
    display: 'none',
  },
  skelaton: {
    backgroundColor: '#d3dbe3',
    width: '170px',
  },
}));
