import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  CircularProgress, Box, Typography, Container,
} from '@material-ui/core';
import ThankYouPage from '../Feedbackpages/ThankYou';
import config from '../../../config';
import BusinessFavicon from '../../../common/BusinessFavicon';
import { postDataRequestPublic, handleUndefined, postDataRequest } from '../../../common/commonfunctions';
import Widget from '../../../components/Widget/Widget';
import { BrandSettingByLocation, Entity } from '../../../Api Mutation/EntityApi';

const PublicThankYou = ({ match, history }) => {
  const [loader, setLoader] = useState(false);
  const [positiveThankYouInfo, setPositiveThankYouInfo] = useState('');
  const [negativeThankYouInfo, setNegativeThankYouInfo] = useState('');
  const [positiveRatingThankYouInfo, setPositiveRatingThankYouInfo] = useState('');
  const [negativeRatingThankYouInfo, setNegativeRatingThankYouInfo] = useState('');
  const [brandInfo, setBrandInfo] = useState();
  const [whiteLabelStatus, setWhiteLabelStatus] = useState();
  const [entityData, setEntityData] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const { search } = history.location;
  const params = new URLSearchParams(search);
  const lid = params.get('lid');
  const brandName = params.get('brand');
  const testEmail = params.get('test');

  const getThankYouBody = async () => {
    await postDataRequestPublic(config.baseURLApi, {
      query: `{
          campaignsettingByLocation(locationid: "${lid}") {
          campaignsettingid,
          reviewtypeid,
          feedbackrequest { subject, body },
          reminders { reminderSequence, active, daysAfter, subject, body },
          positiveemails { emailSequence, active, daysAfter, subject, body, emailReminderToLeaveOnlineReview },
          thanksemail { body },
          apologyemail { active, from, body },
          positivelanding { showOnlineReviewLinks, includeYourRatings, includeButtonForDirectFeedbacks, body },
          negativelanding { showOnlineReviewLinks, includeYourRatings, includeButtonForDirectFeedbacks },
          positivesubmissionconfirmation { body },
          negativesubmissionconfirmation { body },
          reviewrequestemail { subject, body },
          reviewreminders { reminderSequence, active, daysAfter, subject, body },
          reviewpositiveemails { emailSequence, active, daysAfter, subject, body, emailReminderToLeaveOnlineReview },
          reviewthanksemail { body },
          reviewapologyemail { active, from, body },
          reviewpositivelanding { showOnlineReviewLinks, includeYourRatings, includeButtonForDirectFeedbacks, body },
          reviewnegativefeedbackpage,
          reviewpositivesubmissionconfirmation { body },
          reviewnegativesubmissionconfirmation { body },
        }}`,
    })
      .then((res) => {
        setPositiveThankYouInfo(
          res?.data?.campaignsettingByLocation
            ?.positivesubmissionconfirmation?.body,
        );
        setPositiveRatingThankYouInfo(
          res?.data?.campaignsettingByLocation
            ?.reviewpositivesubmissionconfirmation?.body,
        );
        setNegativeThankYouInfo(
          res?.data?.campaignsettingByLocation
            ?.negativesubmissionconfirmation?.body,
        );
        setNegativeRatingThankYouInfo(
          res?.data?.campaignsettingByLocation
            ?.reviewnegativesubmissionconfirmation?.body,
        );
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getBrandInfo = async () => {
    if (handleUndefined(lid)) {
      await postDataRequestPublic(config.baseURLApi, {
        query: BrandSettingByLocation,
        variables: {
          locationid: handleUndefined(lid),
        },
      })
        .then((res) => {
          setBrandInfo(res?.data?.brandSettingByLocation);
        })
        .catch(() => {});
    }
  };

  const fetchInfo = async () => {
    setLoader(true);
    await getThankYouBody();
    await getBrandInfo();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (lid && (!testEmail || testEmail !== 'true')) {
        fetchInfo();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [lid, testEmail]);

  const getEntityData = (entityIdValue) => {
    postDataRequest(config.baseURLApi, {
      query: Entity,
      variables: {
        entityid: entityIdValue,
      },
    }).then((response) => {
      if (response?.data?.entity?.whitelabeltype) {
        setWhiteLabelStatus(response?.data?.entity?.whitelabeltype);
        setEntityData(response?.data?.entity);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (lid) {
        getEntityData(lid);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [lid]);

  // eslint-disable-next-line consistent-return
  const fetchThankYouPage = () => {
    if (
      match.path === '/thank-you-p'
            && lid
            && (!testEmail || testEmail !== 'true')
    ) {
      return (
        <ThankYouPage
          bodyText={positiveThankYouInfo}
          editMode={false}
          history={history}
          brandInfo={{
            logo: brandInfo?.logourl,
            businessname: brandName,
          }}
        />
      );
    } if (
      match.path === '/thank-you-n'
            && lid
            && (!testEmail || testEmail !== 'true')
    ) {
      return (
        <ThankYouPage
          bodyText={negativeThankYouInfo}
          editMode={false}
          history={history}
          brandInfo={{
            logo: brandInfo?.logourl,
            businessname: brandName,
          }}
        />
      );
    } if (
      match.path === '/rating-thank-you-p'
            && lid
            && (!testEmail || testEmail !== 'true')
    ) {
      return (
        <ThankYouPage
          bodyText={positiveRatingThankYouInfo}
          editMode={false}
          history={history}
          brandInfo={{
            logo: brandInfo?.logourl,
            businessname: brandName,
          }}
        />
      );
    } if (
      match.path === '/rating-thank-you-n'
            && lid
            && (!testEmail || testEmail !== 'true')
    ) {
      return (
        <ThankYouPage
          bodyText={negativeRatingThankYouInfo}
          editMode={false}
          history={history}
          brandInfo={{
            logo: brandInfo?.logourl,
            businessname: brandName,
          }}
        />
      );
    } if (!lid && !brandName && (testEmail || testEmail === 'true')) {
      return (
        <>
          <Container>
            <Widget>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <Typography component="p" variant="h2">
                  Test completed
                </Typography>
                <Typography component="p">
                  Now you know how consumers get to write
                  their reviews/feedback on their
                  phone/laptop/else
                </Typography>
                <br />
                <Typography component="p">
                  Normally, when consumers click &quot;Share
                  Review&quot; in actual review form, their reviews
                  get published on your business’s public
                  bixpand.com page.
                </Typography>
                <br />
                <Typography component="p">
                  Your next step is to get lots of reviews
                  using one of
                  {' '}
                  {whiteLabelStatus === 'full' ? entityData?.businessname : 'Bixpand/\'s'}
                  {' '}
                  invitation campaigns.
                  The more reviews you collect, the more
                  unique content can be shown on Google, your
                  website, and social media.
                </Typography>
                <br />
                <a
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                  href="http://www.bixpand.com/"
                  target="_blank"
                >
                  Goto Bixpand.com
                </a>
              </Box>
            </Widget>
          </Container>
        </>
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: lid },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [lid]);

  return (
    <>
      <BusinessFavicon
        logo={favIcon || locationBrandImage || brandInfo?.logourl}
        name={brandName}
      />
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ height: '100vh' }}
      >
        {loader ? <CircularProgress /> : fetchThankYouPage()}
      </Box>
    </>
  );
};

export default withRouter(PublicThankYou);
