import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CurrencyJSON, StripeCurrency } from './const';

function CurrencySelect({ currency, setCurrency, isProduct }) {
  const handleChange = (event, value) => {
    setCurrency(value?.value || '');
  };

  return (
    <div>
      <Autocomplete
        id="currency-autocomplete"
        options={isProduct ? StripeCurrency  : CurrencyJSON}
        getOptionLabel={(option) => `${option.label}`}
        value={(isProduct ? StripeCurrency.find((c) => c?.value === currency) : CurrencyJSON.find((c) => c?.value === currency)) || null}
        onChange={handleChange}
        fullWidth
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ width: isProduct ? '100%' : 150 }}
            label="Select Currency"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
}

export default CurrencySelect;
