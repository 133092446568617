import React, { useEffect } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import config from '../../config';
import PoweredBy from '../../common/PoweredBy';
import { Verifyuseremail } from '../../Api Mutation/Login';
import { postDataRequest } from '../../common/commonfunctions';
import useStyles from './styles';
import { setCookie } from '../../common/const';

export default function VerifyPublicPage() {
  const classes = useStyles();
  const { code } = useParams([]);
  const history = useHistory();
  const fetchData = async () => {
    const VERIFYUSEREMAIL = Verifyuseremail;
    await postDataRequest(config.baseURLApi, {
      query: VERIFYUSEREMAIL,
      variables: {
        code,
      },
    }).then((res) => {
      if (res?.data?.verifyuseremail) {
        const user = jwt.decode(res?.data?.verifyuseremail);
        localStorage.setItem('token', res?.data?.verifyuseremail);
        localStorage.setItem('bar', true);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('userid', JSON.stringify(user.userid));
        setCookie('user_Id', user?.userid, 365);
        localStorage.setItem('theme', 'default');
        axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.verifyuseremail}`;
        localStorage.setItem('isemail', true);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={classes.verifyPage}>
      <Paper className={classes.verify}>
        <img
          src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bixpand.png"
          className={classes.logo}
          alt="brand logo"
        />
        <br />
        <CheckCircleIcon color="primary" style={{ fontSize: 120 }} />
        <br />
        <br />
        <Typography variant="h1">Verified!</Typography>
        <br />
        <Typography variant="body1">You have successfully verified account.</Typography>
        <br />
        <br />
        <Button onClick={() => history.push('/get-started')} variant="contained" color="primary">Get Start</Button>
      </Paper>
      <br />
      <PoweredBy />
    </div>
  );
}
