/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-this-in-sfc */
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  IconButton,
  Typography,
  Link as UiLink,
  Tooltip,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  EditOutlined,
  Assignment as AssignmentIcon,
  DeleteOutline,
} from '@material-ui/icons';
// import DateFnsUtils from '@date-io/date-fns';
import 'font-awesome/css/font-awesome.min.css';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  dataRequest,
  handleUndefined,
  postDataRequest,
} from '../../../../common/commonfunctions';
import { showNotification } from '../../../../components/Notification/ToastifyNotifications';
import Message from '../../../Messages/Message';
import { useGlobalState } from '../../../../context/GlobalContext';
import config from '../../../../config';
import {
  useCampaginState,
  getAllReviewTypes,
  getAllCommunicationTypes,
} from '../../../../context/CampaginContext';
import useStyles from './styles';
import MuiCustomButton from '../../../../common/MuiCustomButton';
import BixDataTable from '../../../../components/DataTable/BixDataTable';
import { defaultColDef, pageSizes } from '../../../../common/const';
import { LoadingPage } from '../../../LoadingPage/LoadingPage';
import { DeleteCampaign } from '../../../../Api Mutation/Campaign';

const Master = (props) => {
  const getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          padding: '5px 5px 5px 5px',
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
      },
      MuiToolbar: {
        root: {
          padding: '5px 5px 5px 5px',
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
      },
      MuiTableCell: {
        footer: {
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
        head: {
          padding: '3px 3px 3px 3px',
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
      },
      MUIDataTableToolbarSelect: {
        root: {
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
        title: {
          display: 'none',
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
      },
      MUIDataTable: {
        responsiveScroll: {
          overflowX: 'scroll',
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
        responsiveScrollMaxHeight: {
          maxHeight: '100% !important',
          backgroundColor:
            localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff',
          color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
        },
        responsiveBase: {
          backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#333540' : '#ffff'
            } !important`,
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiTablePagination: {
        root: {
          border: 'none',
        },
      },
      MuiOutlinedInput: {
        input: {
          color: localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff'
            } !important`,
        },
        sortActive: {
          color: localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
        },
      },
      MUIDataTableSelectCell: {
        fixedLeft: {
          backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff'
            } !important`,
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiSvgIcon: {
        root: {
          color: `${localStorage.getItem('theme') === 'dark' ? 'white' : ''
            } !important`,
        },
        colorDisabled: {
          color: `${localStorage.getItem('theme') === 'dark'
            ? 'rgba(255,255,255,0.3)'
            : ''
            } !important`,
        },
      },
      MuiSelect: {
        icon: {
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff'
            } !important`,
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiTypography: {
        body1: {
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MUIDataTableViewCol: {
        title: {
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MUIDataTableFilter: {
        root: {
          backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff'
            } !important`,
        },
      },
      MuiInputLabel: {
        animated: {
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiInputBase: {
        input: {
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MUIDataTableSearch: {
        searchIcon: {
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiDialog: {
        paperWidthSm: {
          backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#333540' : '#ffff'
            } !important`,
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiDialogTitle: {
        root: {
          backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#333540' : '#ffff'
            } !important`,
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MuiButton: {
        text: {
          color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'
            } !important`,
        },
      },
      MUIDataTableBody: {
        emptyTitle: {
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          width: '100%',
        },
      },
    },
  });
  const context = useCampaginState();
  const {
    viewCampaignReport, dataList, sysInpList, gridColumnApi, manageReviewCampaign,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const { campagin, setCampagin } = context;
  const globalContext = useGlobalState();
  const classes = useStyles();
  const [dataLoading, setDataLoading] = useState(false);
  const { currentLocation } = globalContext.globalState;
  const [openPopup, setOpenPopup] = React.useState(false);
  const [campaignDelete, setCampaignDelete] = React.useState();
  const [gridApi, setGridApi] = useState(null);
  const [activePage, setActivePage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState();
  const [visibleSearchField, setVisibleSearchField] = useState(false);
  const history = useHistory();
  const removedCampaign = localStorage.getItem('removeCampaign');
  const reviewGridPageNumber = parseInt(localStorage.getItem('ReviewGridNumber'), 10);
  const reviewGridPageSize = parseInt(localStorage.getItem('ReviewGridSize'), 10);
  const handleCampaginEdit = (id) => {
    history.push(`/app/reputation-central/launch-campaign?Cid=${id}`);
  };

  const fetchInfoOnDidMount = async () => {
    const getCampaigns = async (prop, dispatch) => {
      dispatch({ type: 'CAMAING_LOADING', payload: true });
      dataRequest(config.baseURLApi, {
        query: `{campaignsbylocation(
            locationid: "${prop?.locationId}",
            pageNumber: ${prop?.pageNumber},
            pageSize: ${prop?.pageSize},
            ${prop?.selectedStartDate
            ? `startdate:"${prop?.selectedStartDate}",`
            : ''
          }
            ${prop?.selectedEndDate
            ? `enddate:"${prop?.selectedEndDate}",`
            : ''
          }
            ${prop?.status ? `status: ${prop?.status},` : ''}
            ${prop?.communicationTypeId
            ? `communicationtypeid: "${prop?.communicationTypeId}",`
            : ''
          }
            ${prop?.reviewtypeid
            ? `reviewtypeid: "${prop?.reviewTypeId}",`
            : ''
          }
          ){
          count,
          rows {  
            campaignid,
            name,
            communicationtypeid,
            subject,
            body,
            customers,
            scheduledatetime,
            locationid,
            status,
            reviewtypeid,
          }
        }}`,
      })
        .then((res) => {
          dispatch({
            type: 'GET_CAMPAING_LIST',
            payload: res.data.campaignsbylocation,
          });
          dispatch({
            type: 'SHOW_REVIEWCAMPAIGNFIRSTTIME_POPUP',
            payload: res?.data?.campaignsbylocation?.rows?.length === 0,
          });
          dispatch({ type: 'CAMAING_LOADING', payload: false });
        })
        .catch(() => {
          dispatch({ type: 'CAMAING_LOADING', payload: false });
        });
    };

    await getAllReviewTypes(context.setCampagin);
    await getAllCommunicationTypes(context.setCampagin);
    await getCampaigns(
      {
        locationId: currentLocation.entityid,
        pageNumber: activePage,
        pageSize: rowsPerPage,
      },
      setCampagin,
    );
  };
  const CampaginStatusInfo = {
    1: 'Draft',
    2: 'Sent',
    3: 'Scheduled',
    4: 'Failed',
  };

  const onPageSizeChanged = useCallback(
    (newPageSize) => {
      let isMounted = true;

      if (gridApi && isMounted) {
        gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', newPageSize);
        gridApi.infiniteRowModel.resetCache();
        localStorage.setItem('ReviewGridSize', newPageSize);
        setRowsPerPage(newPageSize);
        setActivePage(1);
      }
      return () => {
        isMounted = false;
      };
    },
    [gridApi],
  );
  const tableColumn = [
    {
      headerName: 'Campaign Id',
      field: 'campaignid',
      hide: true,
      width: 250,
      minWidth: 50,
      filter: false,
      flex: 1,
      resizable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 250,
      minWidth: 50,
      filter: false,
      flex: 1,
      resizable: true,
      cellRendererFramework: (params) => (
        <>
          {CampaginStatusInfo[params?.data?.status] === 'Sent' ? (
            <UiLink
              style={{
                color:
                  localStorage.getItem('theme') === 'dark'
                    ? 'white'
                    : '#005eb2',
              }}
              onClick={() => history?.push(
                `/app/reputation-central/campaign-report?cid=${params?.data?.campaignid}`,
              )}
            >
              {handleUndefined(params?.data?.name)}
            </UiLink>
          ) : (
            <UiLink
              onClick={() => history?.push(
                `/app/reputation-central/launch-campaign?id=${params?.data?.campaignid}`,
              )}
            >
              {handleUndefined(params?.data?.name)}
            </UiLink>
          )}
        </>
      ),
    },

    {
      headerName: 'Channel',
      field: 'communicationtypeid',
      width: 250,
      minWidth: 50,
      filter: true,
      flex: 1,
      resizable: true,
      filterParams: {
        customFilterListOptions: {
          render: (channel) => {
            if (context?.campagin?.communicationtypeList) {
              const communicationType = context?.campagin?.communicationtypeList.find(
                (type) => type.communicationtypeid === channel,
              );
              return communicationType?.displaytext ? (
                <label htmlFor="display text">
                  {communicationType.displaytext.toUpperCase()}
                </label>
              ) : (
                ''
              );
            }
            return '';
          },
        },
        filterOptions: {
          renderValue: (channel) => {
            if (context?.campagin?.communicationtypeList) {
              const communicationType = context?.campagin?.communicationtypeList.find(
                (type) => type.communicationtypeid === channel,
              );
              return communicationType?.displaytext ? (
                <label htmlFor="display text">
                  {communicationType.displaytext.toUpperCase()}
                </label>
              ) : (
                ''
              );
            }
            return '';
          },
        },
      },
      cellRendererFramework: (params) => {
        if (context?.campagin?.communicationtypeList) {
          const communicationType = context?.campagin?.communicationtypeList.find(
            (type) => type.communicationtypeid === params.value,
          );
          return communicationType?.displaytext ? (
            <label htmlFor="display text">
              {communicationType.displaytext.toUpperCase()}
            </label>
          ) : (
            ''
          );
        }
        return '';
      },
    },
    {
      headerName: 'Type',
      field: 'reviewtypeid',
      width: 250,
      minWidth: 50,
      filter: true,
      flex: 1,
      resizable: true,
      filterParams: {
        customFilterListOptions: {
          render: (reviewType) => {
            if (context?.campagin?.reviewtypeList) {
              const reviewTypeList = context?.campagin?.reviewtypeList.find(
                (type) => type.reviewtypeid === reviewType,
              );
              return reviewTypeList?.displaytext ? (
                <label htmlFor="display text">
                  {reviewTypeList.displaytext}
                </label>
              ) : (
                ''
              );
            }
            return '';
          },
        },
        filterOptions: {
          renderValue: (reviewType) => {
            if (context?.campagin?.reviewtypeList) {
              const reviewTypeList = context?.campagin?.reviewtypeList.find(
                (type) => type.reviewtypeid === reviewType,
              );
              return reviewTypeList?.displaytext ? (
                <label htmlFor="display text">
                  {reviewTypeList.displaytext}
                </label>
              ) : (
                ''
              );
            }
            return '';
          },
        },
      },
      cellRendererFramework: (params) => {
        if (context?.campagin?.reviewtypeList) {
          const reviewTypeList = context?.campagin?.reviewtypeList.find(
            (type) => type.reviewtypeid === params?.data?.reviewtypeid,
          );
          return reviewTypeList?.displaytext ? (
            <label htmlFor="display text">{reviewTypeList.displaytext}</label>
          ) : (
            ''
          );
        }
        return '';
      },
    },

    {
      headerName: 'Invited',
      field: 'customers',
      width: 250,
      minWidth: 50,
      filter: false,
      flex: 1,
      resizable: true,
      cellRendererFramework: (params) => {
        const res = params?.data?.customers;
        if (res && res.length > 0) {
          const customersList = JSON.parse(res);
          return <label htmlFor="customer list">{customersList.length}</label>;
        }
        return '';
      },
    },

    {
      headerName: 'Status',
      field: 'status',
      width: 250,
      minWidth: 50,
      filter: true,
      flex: 1,
      resizable: true,
      filterParams: {
        customFilterListOptions: {
          render: (status) => CampaginStatusInfo[status],
        },
        filterOptions: {
          renderValue: (status) => CampaginStatusInfo[status],
        },
      },
      cellRendererFramework: (params) => (
        <label htmlFor="status">
          {CampaginStatusInfo[params?.data?.status]}
        </label>
      ),
    },
    {
      headerName: 'Sent/Schedule',
      field: 'scheduledatetime',
      width: 250,
      minWidth: 50,
      filter: false,
      flex: 1,
      resizable: true,
      cellRendererFramework: (params) => (
        <label htmlFor="date">
          {params?.data?.scheduledatetime
            ? moment(
              params?.data?.scheduledatetime,
              'YYYY-MM-DD HH:mm:ss Z',
            ).format('ddd, MMM DD, YYYY h:mm A')
            : ''}
        </label>
      ),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 250,
      minWidth: 50,
      filter: false,
      flex: 1,
      resizable: true,
      cellRendererFramework: (params) => (
        <Box display="flex">
          {CampaginStatusInfo[params?.data?.status] !== 'Sent' && (
            <>
              {manageReviewCampaign ? (<IconButton
                color="primary"
                size="medium"
                className={classes.actionIcon}
                component="span"
                title="Edit Campaign"
                onClick={() => {
                  handleCampaginEdit(params?.data?.campaignid);
                }}
              >
                 <img
                       width="36px"
                       src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/edit.svg"
                       alt="template"
                      />
              </IconButton>) : ''}
              {manageReviewCampaign ? (<IconButton
                color="secondary"
                size="medium"
                component="span"
                className={classes.actionIcon}
                title="Delete Campaign"
                onClick={() => {
                  setCampaignDelete(params?.data?.campaignid);
                  setOpenPopup(true);
                  localStorage.removeItem('removeCampaign');
                }}
              >
                  <img
                    width="36px"
                    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/delete.svg"
                    alt="template"
                   />
              </IconButton>) : ''}
            </>
          )}
          {CampaginStatusInfo[params?.data?.status] === 'Sent' && (
            <IconButton
              color="primary"
              size="medium"
              component="span"
              title="Report Campaign"
              className={classes.actionIcon}
              onClick={() => {
                if (viewCampaignReport) {
                  history.push(
                    `/app/reputation-central/campaign-report?cid=${params?.data?.campaignid}`,
                  );
                } else {
                  showNotification(
                    Message.accessError,
                    'error',
                    Message.errorDuration,
                  );
                }
              }}
            >
             <img
                        width="36px"
                        src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/preview.svg"
                        alt="template"
                      />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const onGridReady = useCallback(
    (params) => {
      if (params?.api) {
        const { api } = params;
        setGridApi(api);
        const searchtext = params?.searchingText;
        const dataSource = {
          rowCount: null,
          getRows: (param) => {
            setTimeout(() => {
              setDataLoading(true);
              if (searchtext !== undefined && searchtext !== 'undefined' && searchtext !== null) {
                localStorage.setItem('ReviewCampaignSearchText', searchtext);
              }
              localStorage.setItem('ReviewGridNumber', Math.floor((param.startRow / rowsPerPage) + 1));
              postDataRequest(config.baseURLApi, {
                query: `query Campaignsbylocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!, $searchtext: String) {
                            campaignsbylocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize, searchtext: $searchtext) {
                                count
                                rows {
                                    campaignid
                                    communicationtypeid
                                    createdAt
                                    locationid
                                    name
                                    subject
                                    scheduledatetime
                                    customers
                                    status
                                    reviewtypeid
                                }
                            }
                        }`,
                variables: {
                  locationid:
                    globalContext?.globalState?.currentLocation?.entityid,
                  pageNumber:parseInt(localStorage.getItem('ReviewGridNumber')) || activePage,
                  pageSize: parseInt(localStorage.getItem('ReviewGridSize'), 10) || rowsPerPage,
                  searchtext,
                },
              }).then((res) => {
                if (res?.errors && res.errors[0]?.message) {
                  showNotification(res.errors[0].message, 'error');
                  setDataLoading(false);
                } else if (res?.data?.campaignsbylocation) {
                  const resData = res.data.campaignsbylocation;
                  let lastRow = -1;
                  if (resData.rows.length <= resData.count) {
                    lastRow = resData.count;
                  }
                  param.successCallback(resData.rows, lastRow);
                  if (!Number.isNaN(reviewGridPageNumber)) {
                    if ((activePage !== reviewGridPageNumber)) {
                      params.api.paginationGoToPage(reviewGridPageNumber - 1);
                    }
                  }
                  setDataLoading(false);
                }
              });
            }, 500);
          },
        };
        api.setDatasource(dataSource);

        setTimeout(() => {
          const nodes = api.getRenderedNodes();
          if (!nodes || !nodes.length || !nodes[0].displayed) {
            api.showNoRowsOverlay();
          }
        }, 3500);
      }
    },
    [globalContext?.globalState?.currentLocation?.entityid, rowsPerPage],
  );
  
  useEffect(() => {
    onGridReady({
      api: gridApi,
    });
  }, [globalContext.globalState.currentLocation.entityid, rowsPerPage,activePage]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchInfoOnDidMount();
    }
    return () => {
      isMounted = false;
    };
  }, [activePage, setCampagin, currentLocation.entityid, rowsPerPage]);
  const CustomTitle = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        variant="h6"
        style={{ marginRight: '5px', fontWeight: '500' }}
      >
        Review Campaigns
      </Typography>
    </div>
  );
  const handleClickOpen = () => {
    setOpenPopup(false);
    postDataRequest(config.baseURLApi, {
      query: DeleteCampaign,
      variables: {
        campaignid: campaignDelete,
      },
    }).then((res) => {
      if (res?.errors && res?.errors[0]?.message) {
        showNotification(res.errors[0].message, 'error');
      } else if (res?.data?.deleteCampaign) {
        onGridReady({
          api: gridApi,
        });
      }
    });
  };
  return (
    <>
      {dataLoading ? <LoadingPage /> : ''}
      <Dialog
        open={openPopup}
        onClose={() => {
          setCampaignDelete('');
          setOpenPopup(false);
        }}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          Are you sure to delete this campaign?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Campaign&apos;s data will be deleted</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiCustomButton
            muiHandleClick={handleClickOpen}
            muiSize="small"
            muiColor="primary"
            muiVariant="contained"
            muiAutoFocus
            muiBtnValue="Agree"
          />
          <MuiCustomButton
            muiAutoFocus
            muiHandleClick={() => {
              setCampaignDelete('');
              setOpenPopup(false);
            }}
            muiSize="small"
            muiColor="secondary"
            muiVariant="contained"
            muiBtnValue="Disagree"
          />
        </DialogActions>
      </Dialog>
      <MuiThemeProvider theme={getMuiTheme()}>
        <Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '2rem',
              marginBottom: '2rem',
            }}
          >
            <Box>
              {visibleSearchField ? (
                <TextField
                  size="small"
                  id="outlined-search"
                  type="search"
                  style={{ width: 460 }}
                  placeholder="Search by Name"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      setVisibleSearchField(false);
                      onGridReady({
                        api: gridApi,
                        searchingText: e.target.value,
                      });
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <Tooltip title="All Content">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setVisibleSearchField(false);
                              onGridReady({
                                api: gridApi,
                              });
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <CustomTitle />
              )}
            </Box>
            <Box>
              <Tooltip title="Search">
                <TextField
                  size="small"
                  id="outlined-search"
                  type="search"
                  placeholder="Search..."
                  variant="outlined"
                  value={searchText}
                  style={{
                    padding: '0px 8px',
                    borderRadius: 5,
                    width: '250px',
                    height: '32px',
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      setVisibleSearchField(false);
                      onGridReady({
                        api: gridApi,
                        columnApi: gridColumnApi,
                        searchingText: e.target.value,
                        dataList,
                        sysInpList,
                      });
                      setSearchText('');
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" style={{ fontSize: 18 }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: 12, color: '#005eb2' },
                  }}
                />
              </Tooltip>
            </Box>
            <Box className="ml-1">
              {handleUndefined(
                localStorage.getItem('ReviewCampaignSearchText'),
              )
                && !visibleSearchField && (
                  <div
                    style={{
                      border: '1px solid lightgray',
                      padding: '6px 10px',
                      display: 'inline-block',
                      borderRadius: 50,
                    }}
                  >
                    <Typography variant="caption">
                      SearchText =
                      {' '}
                      {localStorage.getItem('ReviewCampaignSearchText')}
                      <Tooltip title="Remove Search">
                        <IconButton
                          size="small"
                          style={{
                            marginBottom: 2,
                            background: 'lightgray',
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            localStorage.removeItem('ReviewCampaignSearchText');
                            setSearchText('');
                            setVisibleSearchField(false);
                            onGridReady({
                              api: gridApi,
                              columnApi: gridColumnApi,
                            });
                          }}
                        >
                          <CloseIcon
                            fontSize="small"
                            style={{
                              fontSize: 15,
                              color: '#005eb2',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </div>
                )}
            </Box>
            <Box>
              {manageReviewCampaign ? (<MuiCustomButton
                muiSize="small"
                muiVariant="contained"
                muiClass={classes.iconButton}
                muiStartIcon={<AddIcon style={{ color: '#005eb2' }} />}
                muiHandleClick={() => history.push('./launch-campaign')}
                muiBtnValue="Campaign"
              />) : ''}
            </Box>
          </Box>
          <BixDataTable
            defaultColDef={defaultColDef}
            columnDefs={tableColumn}
            rowModelType="infinite"
            onGridReady={onGridReady}
            paginationPageSize={reviewGridPageSize || rowsPerPage}
            gridApi={gridApi}
            showColumnView={false}
          />
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: '5px',
              marginBottom: '10px',
            }}
          >
            <Select
              name="pageSize"
              variant="standard"
              size="small"
              value={reviewGridPageSize || rowsPerPage}
              style={{
                marginLeft: '5px',
                height: '30px',
                boxShadow:
                  '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                fontSize: '12px',
                padding: '5px 10px',
                borderRadius: 5,
                color:
                  localStorage.getItem('theme') === 'dark'
                    ? 'white'
                    : '#005eb2',
              }}
              onChange={(e) => onPageSizeChanged(e.target.value)}
            >
              {pageSizes.map((page) => (
                <MenuItem value={page} key="1">
                  {`${page} Records Per Page`}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </MuiThemeProvider>
    </>
  );
};

export default withRouter(Master);
