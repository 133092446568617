export const VerifyAuthToken = `mutation VerifyToken($code: String!) {
  verifyToken(code: $code) {
    token
    lastlogin
    isenabletwofactorauth
  }
}`;

export const ResetPassowrd = `mutation ResetPassword($userid: ID!, $password: String!) {
  resetPassword(userid: $userid, password: $password)
}`;

export const GenerateOutLookAuthurl = `
query generateoutlookauthurl($url: String, $redirecturl: String){
  generateoutlookauthurl(url:$url, redirecturl: $redirecturl)
}`;

export const GenerateGoogleAuthUrl = `
query generateGoogleAuthUrl($url: String, $redirecturl: String){
    generateGoogleAuthUrl(url:$url, redirecturl: $redirecturl)
}`;

export const EmailSettingsByUser = `
query emailsettingsbyuser($ownerid: ID!, $iscalendar: Boolean, $isemail: Boolean, $status: Int) {
  emailsettingsbyuser(ownerid: $ownerid, iscalendar: $iscalendar, isemail: $isemail, status: $status) {
    emailsettingid
    accountid
    refreshtoken
    accesstoken
    historyid
    useremail
    accesstokenexpirydate
    status
    servicesprovider
    createdby
    updatedby
    deleted
    locationid
    ownerid
    iscalendar
    isemail
    iscontact
    isdefaultemail
    username
    hosturl
    port
    password
    createdAt
    updatedAt
    errormessage
    imaphosturl
    imapport
  }
}`;

export const SaveOutLookAccountByCode = `
    mutation   saveoutlookaccountbycode(
        $code: String!,
        $locationid: ID,
        $redirectUri: String,
        $ownerid: ID,
        $iscalendar: Boolean,
        $isemail: Boolean
        $createdby: ID
        ) {
          saveoutlookaccountbycode(
            code: $code ,
            ownerid: $ownerid,
            redirectUri: $redirectUri,
            iscalendar: $iscalendar,
            isemail: $isemail
            locationid: $locationid,
            createdby:  $createdby
        )
    }`;

export const SaveGoogleAccountByCode = `
    mutation   saveGoogleAccountByCode(
        $code: String!,
        $locationid: ID,
        $ownerid: ID,
        $iscalendar: Boolean,
        $isemail: Boolean
        $createdby: ID
        ) {
            saveGoogleAccountByCode(
            code: $code ,
            ownerid: $ownerid,
            locationid: $locationid,
            iscalendar: $iscalendar,
            isemail: $isemail
            createdby:  $createdby
        )
    }`;

export const partnerUserByBusinessId = `query partnerUserByBusinessId($businessid: ID!) {
      partnerUserByBusinessId(businessid: $businessid) {
        partneruserid
        usertype
        email
        action
        planid
        objectid
        invoiceitemuuid
        parentid
        businessid
        locationid
        userid
        deleted
        status
        createdby
        updatedby
        error
        planname
        createdAt
        updatedAt
      }
    }`;

export const CreateEmailSetting = `mutation createEmailSetting($locationid: ID!, $ownerid: ID!, $createdby: ID!, $password: String, $port: Int, $hosturl: String, $username: String,$imaphosturl: String,$imapport: Int, $servicesprovider: Int, $isdefaultemail: Boolean, $iscontact: Boolean, $isemail: Boolean, $iscalendar: Boolean, $status: Int, $accesstokenexpirydate: String, $useremail: String, $historyid: String, $accesstoken: String, $refreshtoken: String, $accountid: String) {
      createEmailSetting(locationid: $locationid, ownerid: $ownerid, createdby: $createdby, password: $password, port: $port, hosturl: $hosturl,imaphosturl:$imaphosturl,imapport:$imapport, username: $username, servicesprovider: $servicesprovider, isdefaultemail: $isdefaultemail, iscontact: $iscontact, isemail: $isemail, iscalendar: $iscalendar, status: $status, accesstokenexpirydate: $accesstokenexpirydate, useremail: $useremail, historyid: $historyid, accesstoken: $accesstoken, refreshtoken: $refreshtoken, accountid: $accountid) {
        emailsettingid
        accountid
        refreshtoken
        accesstoken
        historyid
        useremail
        accesstokenexpirydate
        status
        servicesprovider
        createdby
        updatedby
        deleted
        locationid
        ownerid
        iscalendar
        isemail
        iscontact
        isdefaultemail
        username
        hosturl
        port
        imaphosturl
        imapport
        password
        createdAt
        updatedAt
        errormessage
      }
    }`;

export const EditEmailSetting = `mutation editEmailSetting($emailsettingid: ID!, $updatedby: ID!, $accountid: String, $refreshtoken: String,$imaphosturl: String,$imapport: Int, $accesstoken: String, $useremail: String, $historyid: String, $accesstokenexpirydate: String, $status: Int, $iscalendar: Boolean, $isemail: Boolean, $iscontact: Boolean, $servicesprovider: Int, $isdefaultemail: Boolean, $locationid: ID, $username: String, $ownerid: ID, $hosturl: String, $port: Int, $password: String) {
  editEmailSetting(emailsettingid: $emailsettingid, updatedby: $updatedby, accountid: $accountid, refreshtoken: $refreshtoken,imaphosturl:$imaphosturl,imapport:$imapport, accesstoken: $accesstoken, useremail: $useremail, historyid: $historyid, accesstokenexpirydate: $accesstokenexpirydate, status: $status, iscalendar: $iscalendar, isemail: $isemail, iscontact: $iscontact, servicesprovider: $servicesprovider, isdefaultemail: $isdefaultemail, locationid: $locationid, username: $username, ownerid: $ownerid, hosturl: $hosturl, port: $port, password: $password)
}`;

export const DeleteEmailSetting = `mutation deleteEmailSetting($emailsettingid: ID!) {
  deleteEmailSetting(emailsettingid: $emailsettingid)
}`;
