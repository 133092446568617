import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';
import useStyles from './LoadingPageStyle';

const LoadingPage = (props) => {
  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open>
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <img
              src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png"
              className={classes.loadingLogo}
              alt="bix logo"
            />
            <CircularProgress
              size={78}
              className={classes.fabProgress}
            />
          </div>
        </div>
        {props?.isAuth ? (
          <span className={classes.themeText}>&nbsp;&nbsp;Authentication Process is InProgress</span>
        ) : (
          <span className={classes.themeText}>&nbsp;&nbsp;Loading...</span>
        )}
      </Backdrop>
    </>
  );
};
// eslint-disable-next-line import/prefer-default-export
export { LoadingPage };
