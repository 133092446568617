import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  WidgetLayout: {
    textAlign: 'center',
  },
  WidgetLayoutAlign: {
    textAlign: 'left',
    padding: 10,
  },
  contents: {
    display: 'flex',
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'Column',
    },
  },
  contentsText: {
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 1,
    },
  },
  Widget: {
    margin: 'auto',
    background: '#fff',
  },
  img: {
    width: 240,
    height: 150,
    marginRight: 10,
    aspectRatio: 'auto',
    objectFit: 'fill',
    border: '1px solid black',
  },
}));
