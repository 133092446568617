import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  support: {
    width: '400px',
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
  contentPermanent: {
    flexGrow: 1,
    padding: 18,
    [theme.breakpoints.down('xs')]: {
      padding: 14,
    },
    width: 'calc(100vw - 240px)',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    padding: 18,
    [theme.breakpoints.down('xs')]: {
      padding: 14,
    },
    width: 'calc(100vw - 240px)',
    minHeight: '100vh',
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
    minHeight: '3.125rem !important',
  },
  link: {
    marginRight: theme.spacing(2),
  },
  defaultRadio: {
    color: '#536DFE',
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: '#536DFE',
    },
  },

  fabStyle: {
    position: 'fixed',
    right: '-1.3%',
    top: '10%',
    zIndex: 200,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      right: '-2.5%',
      zIndex: 200,
    },
    [theme.breakpoints.down('xs')]: {
      right: '-5%',
      zIndex: 200,
    },
  },
  cross: {
    backgroundColor: 'red',
  },
  navPadding: {
    paddingTop: `${theme.spacing(1)}px !important`,
    paddingBottom: '6px !important',
  },
  cardSaveButton: {
    float: 'right',
  },
}));
