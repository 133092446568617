/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import useStyle from './style';
import { brand } from './const';
import { postDataRequestPublic } from './commonfunctions';
import { Entity, BrandSettingByLocation } from '../Api Mutation/EntityApi';
import config from '../config';
import { LoadingPage } from '../pages/LoadingPage/LoadingPage';

export default function PoweredBy({ id }) {
  const classes = useStyle();
  const [image, setImage] = useState();
  const [brandImage, setBrandImage] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [website, setWebsite] = useState();
  const [locationLogo, setLocationLogo] = useState('');
  const [locationSite, setLocationSite] = useState('');
  const [whiteLabelStatus, setWhiteLabelStatus] = useState(null);
  const getEntityData = () => {
    if (id) {
      setDataLoading(true);
      const ENTITYDATA = Entity;
      postDataRequestPublic(config.baseURLApi, {
        query: ENTITYDATA,
        variables: {
          entityid: id,
        },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationLogo(res?.data?.entity?.logourl);
          setLocationSite(res?.data?.entity?.website);
          if (res?.data?.entity?.parententityid) {
            postDataRequestPublic(config.baseURLApi, {
              query: ENTITYDATA,
              variables: {
                entityid: res?.data?.entity?.parententityid,
              },
            }).then((respo) => {
              if (respo?.data?.entity) {
                setImage(respo?.data?.entity?.publiclogotype);
                setWhiteLabelStatus(respo?.data?.entity?.whitelabeltype);
                setWebsite(respo?.data?.entity?.website);
                setDataLoading(false);
              }
            });
            postDataRequestPublic(config.baseURLApi, {
              query: BrandSettingByLocation,
              variables: {
                locationid: res?.data?.entity?.parententityid,
              },
            }).then((resp) => {
              setBrandImage(resp?.data?.brandSettingByLocation?.logourl);
              setDataLoading(false);
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getEntityData();
    }
    return () => {
      isMounted = false;
    };
  }, [id]);
  return (
    <>
      {dataLoading ? <LoadingPage /> : ''}
      {image === brand?.noLogo ? (
        <Box />
      ) : (
        <div>
          <center>
            <a
              style={{ textDecoration: 'none', pointerEvents: brandImage === null ? 'none' : '' }}
              rel="noopener noreferrer"
              href={image === brand?.bixpand ? config.bixpand : brandImage === null ? '' : (image === brand?.business ? locationSite || website : '')}
              target="_blank"
            >
              <Box
                marginTop={3}
                marginBottom={1}
              >
                <img
                  className={classes.bixpoweredBy}
                  src={
                (whiteLabelStatus === 'full' || whiteLabelStatus === 'half')
                  ? (locationLogo || brandImage || '') : image === brand?.bixpand ? config.poweredBy
                    : image === brand?.business || brand?.fullWhiteLabel || brand?.halfWhiteLabel
                      ? locationLogo || brandImage
                      : ''
              }
                  alt=""
                />
              </Box>
            </a>
          </center>
        </div>
      )}
    </>
  );
}
