import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import { TextField } from 'final-form-material-ui';
import {
  Button, Grid, Typography, TextField as Input, InputAdornment, FormHelperText,
} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  getLocations,
  updateEntity,
  useEntitiesState,
  getBusinessData,
  getBusinessByUser,
} from '../../context/EntityContext';
import config from '../../config';
import SelectBusinessType from '../selectBusinees/BusinessType';
import {
  handleUndefined,
  handleValidWebsite,
  postDataRequest,
  dataRequest,
} from '../../common/commonfunctions';
import { Entity, Entitiesbyparentid } from '../../Api Mutation/EntityApi';
import { EntityType, domainValidation } from '../../common/const';
import PhoneNoWithCountryCodeFlag from '../Phonenumber/PhoneNoWithCountryCodeFlag';
import {
  required, maxString, emailValidate, composeValidators, websiteValidate, mustBeString,
} from '../../common/FormValidation';
import Address from './address/Address';
import { RectangleCropImage } from '../Collection/components/Rectangle/RectangleCropImage';
import Widget from '../../components/Widget/Widget';
import useStyles from './styles';

function getSteps() {
  return ['Business', 'Brand Logo', 'Address'];
}
export default function NewGettingStarted() {
  const classes = useStyles();
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [dateFormat] = useState(new Date());
  const [state, setState] = useState({ preview: null });
  const [businessTypeId, setBusinessTypeId] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [fieldStatusEntity] = useState(true);
  const steps = getSteps();
  const [entity, setEntity] = useState([]);
  const [locationId, setLocationId] = useState();
  const [domain, setSubDomain] = useState();
  const context = useEntitiesState();
  const { entities } = context;
  const selectedBusinessType = (businessId) => { setBusinessTypeId(businessId); };
  const getEntityStatus = () => {};
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBusinessByUser(context.setEntities);
      const businessId = handleUndefined(localStorage?.getItem('business_Id')) ? JSON.parse(localStorage?.getItem('business_Id')) : context?.entities?.businessData
    && context?.entities?.businessData[0]
      ?.entityid;
      if (businessId) {
        postDataRequest(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: businessId,
          },
        })
          .then((res) => {
            setEntity(res.data.entity);
            setBusinessTypeId(res.data.entity.businesstypeid);
            if (res?.data?.entity?.entityid) {
              postDataRequest(config.baseURLApi, {
                query: Entitiesbyparentid,
                variables: {
                  parententityid: res?.data?.entity?.entityid,
                },
              }).then((response) => {
                if (response?.data?.entitiesbyparentid) {
                  setSubDomain(response?.data?.entitiesbyparentid[0]?.subdomain);
                  setLocationId(response?.data?.entitiesbyparentid[0]?.entityid);
                }
              });
            }
          })
          .catch(() => {
            setEntity([]);
          });
      }
      if (localStorage.getItem('userid')) {
        postDataRequest(config.baseURLApi, {
          query: `query gettingstarted($userid:ID!){gettingstarted(userid:$userid)
                {business,brand}}
              `,
          variables: {
            userid: JSON.parse(localStorage.getItem('userid')),
          },
        })
          .then((res) => {
            if (res.data.gettingstarted) {
              if (res.data.gettingstarted.business) {
                setActiveStep(1);
              }
              if (res.data.gettingstarted.brand) {
                setActiveStep(2);
              }
            }
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    entities?.businessData
            && entities?.businessData[0]?.entityid,
    localStorage.getItem('business_Id'),
  ]);

  const onSubmitEntity = async (values) => {
    updateEntity(
      {
        entityid: context?.entities?.businessData && context?.entities?.businessData[0]?.entityid,
        BrandName: values.BrandName,
        BusinessName: values.BusinessName,
        entitytypeid: EntityType.Business,
        businesstypeid: businessTypeId || null,
        FirstName: values.FirstName,
        LastName: handleUndefined(values.LastName)
          ? values.LastName
          : '',
        email: values.email,
        phonenumber: values.phonenumber,
        Website: handleValidWebsite(values.Website)
          ? values.Website
          : handleUndefined(values.Website)
            ? `http://${values.Website}`
            : '',
        policy: handleUndefined(entity?.policy),
        terms: handleUndefined(entity?.terms),
      },
      getEntityStatus,
      context.setEntities,
    );
    updateEntity(
      {
        entityid: locationId,
        BusinessName: values.BusinessName,
        entitytypeid: EntityType.Location,
        businesstypeid: businessTypeId || null,
        email: values.email,
        subdomain: domain,
      },
      getEntityStatus,
      context.setEntities,
    );
    getBusinessData(EntityType.Business, context.setEntities);
    getLocations(EntityType.Location, context.setEntities);
    setEntity({
      entityid:
                        context?.entities?.businessData
                        && context?.entities?.businessData[0]?.entityid,
      brandname: values.BrandName,
      businessname: values.BusinessName,
      firstname: values.FirstName,
      lastname: handleUndefined(values.LastName)
        ? values.LastName
        : '',
      email: values.email,
      subdomain: domain,
      website: handleValidWebsite(values.Website)
        ? values.Website
        : handleUndefined(values.Website)
          ? `http://${values.Website}`
          : '',
      phonenumber: values.phonenumber,
      businesstypeid: businessTypeId || null,
    });
    if (JSON.parse(localStorage.getItem('userid'))) {
      postDataRequest(config.baseURLApi, {
        query: `
                  mutation editGettingStarted($name:String!,$userId:ID!){
                      editGettingStarted(name:$name,userid:$userId)
                    }
            `,
        variables: {
          name: 'business',
          userId: JSON.parse(localStorage.getItem('userid')),
        },
      });
    }
    setActiveStep(1);
  };

  const onBrandSubmit = () => {
    if (state.preview) {
      dataRequest(config.baseURLApi, {
        query: `mutation{
        createBrandSetting(logourl:"${
  state.preview
}",dateformat:"${dateFormat}",
          locationid:"${context?.entities?.businessData
              && context?.entities?.businessData[0]
                ?.entityid}",createdby:"${context?.entities
  ?.businessData
                        && context?.entities?.businessData[0]
                          ?.entityid}"){color}
      }`,
      });
    }
    if (JSON.parse(localStorage.getItem('userid'))) {
      postDataRequest(config.baseURLApi, {
        query: `
              mutation editGettingStarted($name:String!,$userId:ID!){
                  editGettingStarted(name:$name,userid:$userId)
                }`,
        variables: {
          name: 'brand',
          userId: JSON.parse(localStorage.getItem('userid')),
        },
      });
    }
    setActiveStep(2);
  };

  return (
    <Widget
      disablewidgetmenu="true"
      size="small"
      bodyclass={classes.navPaddingLocation}
    >
      <Stepper
        style={{ display: 'none' }}
        classes={{ root: classes.paddingStepLabel }}
        activeStep={activeStep}
        className={classes.stepper}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{
                label: classes.stepLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.stepIcon,
                  completed:
                                classes.completed,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 ? (
        <>
          <center>
            <div className={classes.backgroundImg}>
              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Bixpand_StarLogo.png" alt="bixpandIcon" className={classes.brandIcon} />
            </div>
            <Typography variant="h1" className={classes.MainTypography}>Let&apos;s set up  your account</Typography>
            <Typography variant="h6" className={classes.InnerTypography}>Grow your business with effective marketing and sales acceleration tools.</Typography>
          </center>
          {formDataChanged === true && (
            <Prompt message="Are you sure you want to leave? Unsaved Data may be lost..." />
          )}
          <Form
            onSubmit={onSubmitEntity}
            render={({
              handleSubmit,
            }) => (
              <form
                className={classes.form}
                onSubmit={handleSubmit}
                onChange={() => {
                  setFormDataChanged(true);
                }}
                noValidate
              >
                {entity !== null && (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item md={8} xs={12}>
                      <Grid container item spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Field
                            className={classes.field}
                            required
                            name="BusinessName"
                            validate={composeValidators(required, maxString(100))}
                            component={TextField}
                            type="text"
                            label="Business Name"
                            variant="outlined"
                            size="small"
                            defaultValue={handleUndefined(
                              entity.businessname,
                            )}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            className={classes.field}
                            required
                            name="FirstName"
                            validate={composeValidators(required, mustBeString, maxString(20))}
                            component={TextField}
                            type="text"
                            label="First Name"
                            variant="outlined"
                            size="small"
                            defaultValue={handleUndefined(
                              entity.firstname,
                            )}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            className={classes.field}
                            name="BrandName"
                            validate={composeValidators(required, maxString(100))}
                            component={TextField}
                            required
                            type="text"
                            label="Brand Name"
                            variant="outlined"
                            size="small"
                            defaultValue={handleUndefined(
                              entity.brandname,
                            )}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            className={classes.field}
                            name="LastName"
                            validate={composeValidators(maxString(20))}
                            component={TextField}
                            type="text"
                            label="Last Name"
                            size="small"
                            variant="outlined"
                            defaultValue={handleUndefined(
                              entity.lastname,
                            )}
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.select}>
                          <SelectBusinessType
                            onBusinessSelect={
                              selectedBusinessType
                          }
                            BusinessName={
                                entity.businesstypeid
                        }
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name="email"
                            className={classes.field}
                            required
                            component={TextField}
                            type="email"
                            validate={composeValidators(required, emailValidate)}
                            defaultValue={handleUndefined(
                              entity.email,
                            )}
                            label="Communication Email"
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            className={classes.field}
                            name="Website"
                            required
                            validate={composeValidators(required, websiteValidate)}
                            component={TextField}
                            type="text"
                            label="Website"
                            variant="outlined"
                            size="small"
                            defaultValue={handleUndefined(
                              entity.website,
                            )}
                          />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.select}>
                          <Field required name="phonenumber" defaultValue={handleUndefined(entity.phonenumber)} component={PhoneNoWithCountryCodeFlag} />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Input
                            className={classes.field}
                            type="text"
                            label="Sub-Domain"
                            size="small"
                            onChange={(e) => setSubDomain(e.target.value)}
                            variant="outlined"
                            error={domain && !domainValidation?.test(domain)}
                            InputProps={{
                              endAdornment:
          <InputAdornment position="end">
            <Typography variant="body1">.bixpand.com</Typography>
          </InputAdornment>,
                            }}
                            value={
                                      handleUndefined(
                                        domain || (context.entities
                                          ?.businessData
                                    && context.entities
                                      ?.businessData[0]
                                      ?.subdomain
                                        ),
                                      )
}
                          />
                          {domain && !domainValidation?.test(domain) ? (
                            <FormHelperText
                              className={classes.price}
                              error={domain && !domainValidation?.test(domain)}
                            >
                              <Typography variant="body2">{domain && !domainValidation?.test(domain) ? 'Invalid domain' : ''}</Typography>
                            </FormHelperText>
                          ) : ''}
                        </Grid>
                        <br />
                        <Button
                          className={classes.buttonSave}
                          size="medium"
                          variant="contained"
                          color="primary"
                          type="submit"
                          endIcon={<NavigateNextIcon />}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <center>
                  <h3 style={{
                    paddingLeft: 30, fontSize: 16, fontWeight: 400,
                  }}
                  >
                    All Rights Reserved. You can check
                    {' '}
                    <a href="https://www.bixpand.com/privacy-policy.html" target="_blank" rel="noreferrer">Privacy</a>
                    {' '}
                    and Statement
                    {' '}
                    <a href="https://www.bixpand.com/terms-conditions.html" target="_blank" rel="noreferrer">Terms and Conditions</a>
                  </h3>
                </center>
              </form>
            )}
          />
        </>
      )
        : ''}
      {activeStep === 1 ? (
        <>
          <center>
            <div className={classes.backgroundImg}>
              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Bixpand_StarLogo.png" alt="bixpandIcon" className={classes.brandIcon} />
            </div>
            <Typography variant="h1" className={classes.MainTypography}>Upload Brand Logo</Typography>
            <Typography variant="h6" className={classes.InnerTypography}>Why logo? This will be used for campaigns and communications you</Typography>
            <Typography variant="h6" className={classes.InnerTypography}>choose to have with customers. Change it anytime later from settings.</Typography>
          </center>
          <Form
            onSubmit={onBrandSubmit}
            render={({
              handleSubmit,
            }) => (
              <form
                className={classes.form}
                onSubmit={handleSubmit}
                onChange={() => setFormDataChanged(true)}
                noValidate
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item md={6} xs={12}>
                    <Grid container item spacing={2}>
                      <Grid xs={12} item md={12}>
                        <RectangleCropImage
                          fieldStatusEntity={fieldStatusEntity}
                          state={state}
                          setState={setState}
                          getStart={false}
                        />
                      </Grid>
                      <Grid xs={6} md={6}>
                        <Button
                          className={classes.buttonBack}
                          size="medium"
                          variant="contained"
                          onClick={() => {
                            setActiveStep(0);
                          }}
                          startIcon={<NavigateBeforeIcon />}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid xs={6} md={6}>
                        <Button
                          className={classes.buttonNext}
                          size="medium"
                          variant="contained"
                          color="primary"
                          type="submit"
                          endIcon={<NavigateNextIcon />}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <center>
                  <h3 style={{
                    paddingLeft: 30, fontSize: 16, fontWeight: 400,
                  }}
                  >
                    All Rights Reserved. You can check
                    {' '}
                    <a href="https://www.bixpand.com/privacy-policy.html" target="_blank" rel="noreferrer">Privacy</a>
                    {' '}
                    and Statement
                    {' '}
                    <a href="https://www.bixpand.com/terms-conditions.html" target="_blank" rel="noreferrer">Terms and Conditions</a>
                  </h3>
                </center>
              </form>
            )}
          />
        </>
      ) : ''}
      {activeStep === 2 ? (
        <>
          <center>
            <div className={classes.backgroundImg}>
              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Bixpand_StarLogo.png" alt="bixpandIcon" className={classes.brandIcon} />
            </div>
            <Typography variant="h1" className={classes.MainTypography}>Add Your Address</Typography>
            <Typography variant="h6" className={classes.InnerTypography}>
              We need a physical address to make sure your customer emails comply with
              {' '}
              <br />
              <a href="https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business" target="_blank" rel="noreferrer">international anti-spam laws.</a>
              &nbsp;Don&apos;t have an  official business address?
              {' '}
              <br />
              Learn about
              {' '}
              <a href="https://eepurl.com/dyikOD" target="_blank" rel="noreferrer">physical address alternatives</a>
            </Typography>
          </center>
          <div style={{ marginTop: '20px' }}>
            <Address
              entityid={entity && entity.entityid}
              handleClick={() => {
                setActiveStep(1);
              }}
            />
          </div>
        </>
      ) : ''}
    </Widget>
  );
}
