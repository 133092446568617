import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  useageReport: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  useageCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-start',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  reportText1: {
    paddingLeft: 5,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  button: {
    float: 'right',
  },
  placeholder: {
    marginTop: '160px',
    color: '#5F87AA',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '100px',
      marginTop: '17px',
    },
  },
  placeholderIcon: {
    fontSize: 70,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
}));
