import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TextField } from 'final-form-material-ui';
import {
  Button, Grid, Avatar, Typography, Box,
} from '@material-ui/core';
import axios from 'axios';
import Widget from '../../components/Widget/Widget';
import '../phoneInput.css';
import useStyles from './stylesforpassword';

import config from '../../config';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import {
  required, emailValidate, composeValidators,
} from '../../common/FormValidation';

function EmailForForget() {
  const [checkEmail, setCheckEmail] = useState(true);
  const classes = useStyles();

  const header = {
    'Content-Type': 'application/json',
    'Apollo-Require-Preflight': 'true',
  };

  const onSubmitEntity = async (values) => {
    axios({
      url: config.baseURLApi,
      method: 'post',
      headers: header,
      data: {
        query: `mutation{
                    forgotPassword(email:"${values.email}")
                  }`,
      },
    }).then((res) => (res?.data?.data?.forgotPassword === true
      ? setCheckEmail(false) || showNotification('Please check your inbox', 'success')
      : showNotification(res?.data?.errors[0]?.message, 'error')));
  };

  return (
    <Grid item className={classes.forgotGrid}>
      {checkEmail === true ? (
        <Form
          onSubmit={onSubmitEntity}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
              >
                <Avatar
                  alt="bixpandAvatar"
                  src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/BixpandLogo.png"
                  className={classes.forgotBixLogo}
                />
              </Grid>
              <Widget
                disablewidgetmenu="true"
                inheritheight="true"
                className={classes.Widgetup}
              >
                <Grid item xs={12} sm={12} md={12} container>
                  <Grid item>
                    <Typography
                      variant="h4"
                      color="primary"
                    >
                      Forgot Password
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="primary"
                      variant="body2"
                      className={classes.WidgetSubTitle}
                    >
                      Enter your registered email address
                      or mobile number to change your
                      account password.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      className={classes.field}
                      required
                      size="small"
                      name="email"
                      validate={composeValidators(required, emailValidate)}
                      component={TextField}
                      label="Email"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Box className={classes.btn}>
                  <Link
                    to="/login"
                    className={classes.backMenuLink}
                  >
                    <Typography variant="body2">
                      <ArrowBackIosIcon className={classes.icon} />
                      Back To LogIn
                    </Typography>
                  </Link>
                  <Button
                    size="small"
                    className={classes.buttonSave}
                    variant="contained"
                    startIcon={<SendIcon />}
                    color="primary"
                    type="submit"
                  >
                    Send
                  </Button>
                </Box>
              </Widget>
            </form>
          )}
        />
      ) : (
        <Widget
          disablewidgetmenu="true"
          inheritheight="true"
          className={classes.Widgetup}
        >
          <Grid item xs={12} sm={12} md={12} container>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                alt="bixpandAvatar"
                src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/BixpandLogo.png"
                className={classes.forgotBixLogo}
              />
            </Grid>
            <Grid item className={classes.WidgetTitle}>
              <Typography variant="title">
                Check Your Email
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                gutterBottom
                variant="body2"
                className={classes.WidgetSubTitle}
              >
                Check your email for instructions on how to
                reset your password. If it doesn’t appear within
                a few minutes, check your spam/junk folder.
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Link to="/login" className={classes.backMenuLink}>
            <Typography variant="body2">
              <ArrowBackIosIcon className={classes.icon} />
              Back
            </Typography>
          </Link>
        </Widget>
      )}
    </Grid>
  );
}
export default EmailForForget;
