/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { postDataRequestPublic, handleUndefined } from '../../common/commonfunctions';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import BusinessFavicon from '../../common/BusinessFavicon';
import PoweredBy from '../../common/PoweredBy';
import config from '../../config';
import { BrandSettingByLocation, Entity } from '../../Api Mutation/EntityApi';

export default function PublicContectPage(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const { id } = useParams([]);
  const [favicon, setFavicon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [brandImage, setBrandImage] = useState();
  const [emailTemplateHtml, setEmailTemlateHtml] = useState();
  const [title, setTitle] = useState();
  const [startDate, setStartDate] = useState(moment().format());
  const [locationId, setLocationId] = useState('');
  const ref = useRef(null);
  const { onClickOutside } = props;

  const fetchData = async (prop) => {
    if (handleUndefined(id)) {
      const CREATE_ACTIVITYCONTECTMARKETING = `
    mutation addActivityContentMarketing($contentmarketingnid: ID!, $openeddata: String, $clickeddata: String) {
      addActivityContentMarketing(contentmarketingnid: $contentmarketingnid, openeddata: $openeddata, clickeddata: $clickeddata)
    }`;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITYCONTECTMARKETING,
        variables: {
          contentmarketingnid: handleUndefined(id),
          openeddata: prop?.opendata,
          clickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData({
        opendata: JSON.stringify({
          timestamp: startDate,
          timespent: 0,
        }),
      });
      setStartDate(moment().format());
      if (id) {
        setDataLoading(true);
        postDataRequestPublic(config.baseURLApi, {
          query: `
        query contentMarketing($contentmarketingnid: ID!){
            contentMarketing(contentmarketingnid: $contentmarketingnid){
              contentmarketingnid,name,isfree,category,author,contenttemplate,templatedesign,locationid,status,createdby,updatedby,createdAt,updatedAt,
            }
          } `,
          variables: {
            contentmarketingnid: id,
          },
        }).then((res) => {
          if (res?.errors && res?.errors[0]?.message) {
            setDataLoading(false);
            showNotification(res?.errors[0]?.message, 'error');
            setDataLoading(false);
          } else if (res?.data?.contentMarketing) {
            setDataLoading(false);
            setEmailTemlateHtml(res?.data?.contentMarketing?.contenttemplate);
            setTitle(res?.data?.contentMarketing?.name);
            setLocationId(res?.data?.contentMarketing?.locationid);
            setDataLoading(false);
          }
        });
        setDataLoading(false);
      }
      if (locationId) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: { entityid: locationId },
        })
          .then((res) => {
            if (res?.data?.entity) {
              setFavicon(res?.data?.entity?.favicon);
              setLocationBrandImage(res?.data?.entity?.logourl);
              if (handleUndefined(res?.data?.entity?.parententityid)) {
                postDataRequestPublic(config.baseURLApi, {
                  query: BrandSettingByLocation,
                  variables: {
                    locationid: handleUndefined(res?.data?.entity?.parententityid),
                  },
                }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
              }
            }
            setDataLoading(false);
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          onClickOutside && onClickOutside();
        } else if (e?.target?.parentNode?.localName === 'a' && e?.target?.parentNode?.href) {
          const clickedData = {
            timestamp: new Date(),
            url: e?.target?.parentNode?.href,
            name: e?.target?.parentNode?.innerText,
          };
          fetchData({ click: clickedData });
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
    return () => {
      isMounted = false;
    };
  }, [onClickOutside]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.onbeforeunload = async (event) => {
        const message = 'Are you sure to exit this page ?';
        const e = event || window.event;
        await e.preventDefault();
        if (e) {
          e.returnValue = message;
        }
        return fetchData({
          opendata: JSON.stringify({
            timestamp: startDate,
            timespent: moment(new Date())?.diff(startDate, 'seconds'),
          }),
        });
      };
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      {dataLoading ? <LoadingPage /> : ''}
      <BusinessFavicon
        logo={favicon || locationBrandImage || brandImage}
        name={title}
      />
      <center>
        <div ref={ref} dangerouslySetInnerHTML={{ __html: emailTemplateHtml }} />
      </center>
      <PoweredBy id={locationId} />
    </div>
  );
}
