import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  margin: {
    marginBottom: 24,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  componentHeading: {
    fontSize: '20px',
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  backMenuLink: {
    fontSize: 17,
    float: 'right',
    textDecoration: 'none',
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'right',
      textDecoration: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  loggedInCard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#70bd0a',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#78d300',
      color: '#fff',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  loggedInPaper: {
    height: 130,
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      height: 130,
    },
  },
  paper: {
    height: 130,
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      height: 130,
    },
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
    },
  },
  yelpIcon: {
    width: 30,
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
    },
  },
  backArrow: {
    fontSize: '35px',
    fontFamily: 'open sans',
  },
  gridWidth: {
    maxWidth: '100vw',
    margin: 0,
  },
  padding: {
    padding: 8,
  },
}));
