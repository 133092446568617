import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  googleIcon: {
    width: 25,
    marginRight: theme.spacing(0),
  },
  topbar: {
    marginLeft: theme.spacing(3),
    float: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      marginLeft: theme.spacing(0.5),
    },
  },
  tab: {
    minWidth: 110,
    fontSize: 14,
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      minWidth: 8,
      fontSize: 10,
    },
    '&:hover': {
      backgroundColor: '#f6f7ff',
      color: 'black',
    },
  },
  tabs: {
    minWidth: 200,
    borderBottom: '2px solid #e8e8e8',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '20px',
      fontSize: 10,
    },
  },
  backMenuLink: {
    fontSize: 17,
    float: 'right',
    textDecoration: 'none',
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'right',
      textDecoration: 'none',
    },
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowBack: {
    color: '#005eb2',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(2, 1, 2, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '20%',
    [theme.breakpoints.up('xs')]: {
      width: '20ch',
    },
  },
  buttonBack: {
    float: 'right',
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      float: 'right',
      fontSize: 22,
      width: 'auto',
    },
  },
  reviewIcon: {
    width: 35,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '105px',
    },
  },
  showCase: {
    display: 'flex',
  },
  buttonClose: {
    float: 'right',
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      float: 'right',
      fontSize: 22,
      width: 'auto',
      position: 'absolute',
    },
  },

  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  dropDown: {
    fontSize: 17,
    color: '#6E6E6E',
    padding: '8px 0 6px',
    width: '40%',
    marginTop: '11px',
    fontStyle: 'transparent',
    borderLeft: 'hidden',
    borderRight: 'hidden',
    borderTop: 'hidden',
    marginLeft: '6%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  buttonSave: {
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '70%',
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  feedback: {
    marginTop: theme.spacing(1),
    fontSize: '15px',
    marginLeft: '13px',
    padding: '1px',
    '&:hover': {
      backgroundColor: '#d2ecd6',
      color: 'black',
    },
  },
  reviewtext: {
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'breakword',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 12,
  },
  disableReport: {
    color: 'grey',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      float: 'left',
    },
  },
  BottomBut: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 7,
      marginLeft: theme.spacing(1),
      float: 'left',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  datepicker: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
  displayText: {
    marginLeft: '1rem',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  themeText: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  daterange: {
    marginTop: '15px',
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
  customerPhoto: {
    height: '3.125rem',
    width: '3.125rem',
  },
  ratingBoxSpace: {
    marginLeft: '0.2rem',
    width: '1.875rem',
    height: '1.875rem',
  },
  filterButton: {
    float: 'right',
  },
  Actiondrop: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginLeft: theme.spacing(1),
      marginTop: '2px',
      float: 'left',
    },
  },
  title: {
    fontSize: 14,
    overflow: 'auto',
    maxHeight: '90px',
    overflowWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '150px',
    },
  },
  fbsmall: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  changelog: {
    whitespace: 'nowrap',
    overflow: 'hidden',
    textoverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  navPadding: {
    paddingTop: `${theme.spacing(1)}px !important`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '4px !important',
    [theme.breakpoints.down('xs')]: {
    },
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
  },
  send: {
    marginLeft: '45%',
  },
  autoReply: {
    float: 'right',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  componentHeading: {
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  refreshIcon: {
    float: 'right',
    margin: '0px 12px 0px 12px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 8px',
    },
  },
  addNoteButton: {
    float: 'right',
  },
  divider: {
    width: '100%',
    marginTop: '4%',
  },
  componentHeading1: {
    fontSize: 18,
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 19,
    },
  },
  media: {
    height: '140px',
  },
  starIcon: {
    marginTop: '5px',
    marginLeft: '6px',
    color: '#ffc247',
  },
  cardActions: {
    padding: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  cardAction: {
    justifyContent: 'center',
  },
  start: {
    float: 'left',
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 'small',
      marginLeft: '1px',
    },
  },
  startSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  logo: {
    height: 35,
    width: 35,
    [theme.breakpoints.down('sm')]: {
      height: 38,
      width: 38,
    },
  },
  logotype: {
    color:
            theme.palette.type === 'dark'
              ? theme.palette.primary.main
              : 'white',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    overflowX: 'auto',

    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      minWidth: 50,
    },
  },
  cardRatingHeader: {
    display: 'flex',
    marginRight: '0.75rem',
    alignItems: 'center',
    gap: '5px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardHeaderRating: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '30px',
    },
  },
  popOver: {
    padding: '1rem !important',
  },
  heightCard: {
    minHeight: '8.5rem !important',
    maxHeight: '8.5rem !important',
    overflow: 'auto !important',
  },
  legendItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignStandaloneElement: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableWidget: {
    overflowX: 'none',
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: '100%',
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },

  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: `${theme.palette.text.hint}80 !important`,
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  mainChartLegentElement: {
    fontSize: '14px !important',
    marginLeft: theme.spacing(1),
  },
  formControl: {
    minWidth: 150,
    padding: '3px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 100,
      padding: '5px',
    },
  },
  formControltextsize: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  margin1: {
    backgroundColor: '#464775',
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '10px !important',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#464775',
      paddingBottom: '5% !important',
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  margin: {
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
  fixIconRight: {
    '& .MuiSelect-icon': {
      right: 4,
    },
  },
  checkboxColor: {
    color: 'rgb(185, 185, 185)',
  },
  wrapper: {
    textAlign: 'center',
    margin: theme.spacing(3),
  },
  containerPopup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {
    display: 'inline-flex',
    '& li': {
      listStyle: 'none',
      '& a': {
        outline: 0,
      },
    },
    alignItems: 'center',
  },
  element: {
    padding: `4px ${theme.spacing(1)}px`,
    margin: theme.spacing(1),
  },
  link: {
    color: theme.palette.text.primary,
  },
  activeLink: {
    color: theme.palette.primary.main,
  },
  activeElement: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  prev: {
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  next: {
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  placeholder: {
    marginTop: '225px',
    color: '#5F87AA',
    maxWidth: '60%',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '100px',
      marginTop: '17px',
      maxWidth: '100%',
    },
  },
  placeholderIcon: {
    fontSize: 70,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  iconTheme: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  displayNone: {
    display: 'none',
  },
}));
