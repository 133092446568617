import { makeStyles } from '@material-ui/styles';
import { fontFamily } from '../../common/const';

export default makeStyles((theme) => ({
  IconButton: {
    borderRadius: 60,
    padding: '3px 15px',
    border: '1px solid #3f51b5',
    margin: '8px auto',
  },
  tagSpan: {
    color: 'red',
    marginTop: 3,
  },
  disableColor:{
    color: '#a6adb1',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  emailPaperIcon: {
    display: 'none',
  },
  contactWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black !important',
    background: '#e2edf5',
    height: 50,
     paddingLeft: 25,
      borderRadius: 16,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  ContactClass: {
    background: '#3c46f5',
    color: '#fff',
    cursor: 'pointer',
    borderRadius: 5,
    marginRight: 10,
  },
  crmHr: {
    backgroundColor: '#005eb2',
  },
  wfDivider: {
    margin: '1rem 0rem',
    backgroundColor: '#005eb2',
  },
  wfDividerR: {
    margin: '1rem 0rem',
    marginTop: '9px',
    backgroundColor: '#005eb2',
  },
  orgBr: {
    display: 'none',
  },
  chevronright: {
    background: '#edf0f6',
    borderRadius: 10,
    color: '#005eb2',
    cursor: 'pointer',
    marginTop: '10px',
    marginLeft: '15px',
  },
  firstBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  videoResponsive: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  themeColor: {
    color: 'white',
  },
  menuItemText: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
  },
  chipText: {
    color: `${localStorage.getItem('theme') === 'dark' ? 'white' : 'black'} !important`,
  },
  iconTheme: {
    color: `${localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2'} !important`,
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  filters: {
    marginBottom: 5,
  },
  dialogTitle: {
    color: `${localStorage.getItem('theme') === 'dark' ? 'white' : '#4A4A4A'} !important`,
  },
  verify: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    marginTop: 5,
  },
  team: {
    marginBottom: 5,
    width: '100%',
  },
  dropDown: {
    width: '100%',
  },
  navPaddingLocation: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
    height: 'calc( 100vh - 82px )',
    overflowY: 'none',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  workflowOptions: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  workflowList: {
    width: '100%',
    padding: 0,
  },
  addWf: {
    paddingRight: 3,
  },
  accordian: {
    width: '100%',
  },
  accordianDetails: {
    padding: '5px 16px 5px',
  },
  accordianSummery: {
    minHeight: 'auto',
    height: 'auto',
  },
  accordianHeading: {
    marginBottom: 0,
  },
  listItemTextAccordian: {
    cursor: 'pointer',
  },
  searchBox: {
    margin: '10px 0px',
  },
  field: {
    width: '100%',
    fontFamily: 'open sans',
  },
  AddDeal: {
    float: 'right',
  },
  card: {
    minHeight: 'auto',
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      padiing: '8px',
    },
  },
  cardTask: {
    minHeight: 'auto',
    padding: 10,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  cardNote: {
    minHeight: 'auto',
    padding: 10,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  cardDocs: {
    minHeight: 'auto',
    padding: 10,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  datepicker: {
    float: 'left',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
  daterange: {
    marginTop: '15px',
    marginBottom: '10px',
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      marginBottom: '15px',
    },
  },
  redirectButtonLayout: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginIcon: {
    marginBottom: 10,
    width: '34px',
    height: '30px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      width: '35px',
      height: '35px',
    },
  },
  editIcon: {
    margin: theme.spacing(0.9),
    width: '34px',
    height: '30px',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1.7),
      width: '35px',
      height: '35px',
    },
  },
  crmeditIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#005eb2',
  },
  viewsHeading: {
    textOverflow: 'ellipsis',
    width: 104,
    fontSize: 18,
    overflow: 'hidden',
    color: 'black !important',
    whiteSpace: 'nowrap',
  },
  margin: {
    margin: theme.spacing(1),
    width: '34px',
    height: '30px',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1.7),
      width: '35px',
      height: '35px',
    },
  },
  marginIcons: {
    width: '35px',
    height: '25px',
    margin: theme.spacing(0.5),
    backgroundColor: '#005eb2',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      width: '30px',
      backgroundColor: '#005eb2',
      height: '30px',
    },
  },
  ImportClass: {
    backgroundColor: '#005eb2',
    color: 'white',
    cursor: 'pointer',
    width: '100%',
    padding: '8px 11px',
  },
  ImportClassHover: {
    backgroundColor: '#cedaf1',
  },
  ImportBtn: {
    backgroundColor: '#005eb2',
    color: '#fff',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  responsiveOrg: {
    overflow: 'auto',
  },
  iconfab: {
    fontSize: 18,
    color: '#fff',
  },
  expandTitle: {
    fontFamily: 'open sans',
    fontSize: 14,
    fontWeight: 500,
  },
  placeholder: {
    marginTop: '180px',
    color: '#5F87AA',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '100px',
      marginTop: '17px',
    },
  },
  placeholderEmail: {
    marginTop: '180px',
    color: '#5F87AA',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '100px',
      marginTop: '60px',
    },
  },
  sidebarPaper: {
    padding: '10px',
    border: '2px solid rgba(0, 94, 178, 0.5)',
  },
  placeholderIcon: {
    fontSize: 70,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  docs: {
    fontSize: 40,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  avatarImg: {
    marginTop: '0px !important',
  },
  count: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'white',
    fontSize: 11,
    color: '#005eb2',
  },
  drawerPaper: {
    width: 260,
  },
  selectionFilter: {
    paddingTop: 8,
  },
  selection: {
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    border: '1px solid lightgrey',
    background: 'white',
    marginRight: 10,
    overflowY: 'auto',
    zIndex: '999',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  selections: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginTop: 8,
    marginRight: 10,
  },
  editHeader: {
    padding: '8px',
  },
  addProperties: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contents: {
    flexGrow: 1,
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -260,
  },
  contentsSplit: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -260,
  },
  profileMenuItem: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  profileMenu: {
    minWidth: 230,
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  contentsView: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -260,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  contentViewShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  contentsViewSplit: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -260,
  },
  listIcon: {
    minWidth: '22px',
  },
  selectType: {
    padding: '7px 14px',
    margin: '8px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: 150,
  },
  head: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1, 1, 1),
    color: '#005eb2',
  },
  textField: {
    border: '2px solid #005eb2',
    width: '100%',
    borderRadius: '4px',
    height: '2.1rem',
    color: '#5e676f',
    fontSize: '13px',
    padding: '10px',
    marginBottom: '5px',
  },
  viewText: {
    fontSize: '12px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  filterContent: {
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  headicon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imgIcon: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    bottom: 0,
    fontSize: 22,
    borderRadius: 6,
    right: 0,
    cursor: 'pointer',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  expandIcon: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  actionBtn: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    float: 'right',
    marginLeft: theme.spacing(1),
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      marginLeft: theme.spacing(0.5),
      float: 'right',
    },
  },
  sharedBtn: {
    color: '#005EB2',
    float: 'right',
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    fontSize: 15,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
      fontWeight: 600,
      marginLeft: theme.spacing(0.5),
      float: 'right',
    },
  },
  actionEmailBtn: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
      marginLeft: theme.spacing(1),
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: '#005EB2',
    marginTop: theme.spacing(2),
    fontSize: 32,
    fontWeight: 600,
    alignItems: 'center',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  split: {
    backgroundColor: '#005EB2',
  },
  logo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  headerDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  headerDetail: {
    display: 'flex',
    alignItems: 'center',
  },
  crmLogo: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  cardIconNote: {
    border: '1px solid #005EB2',
    backgroundColor: '#bcdae6',
    borderRadius: '50px',
    fontSize: 30,
    color: '#005EB2',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  tab: {
    minWidth: 110,
    fontSize: 14,
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      minWidth: 8,
      fontSize: 10,
    },
    '&:hover': {
      backgroundColor: '#f6f7ff',
      color: 'black',
    },
  },
  tabs: {
    minWidth: 200,
    borderBottom: '2px solid #e8e8e8',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '20px',
      fontSize: 10,
    },
  },
  Timeline: {
    backgroundColor: '#3d8190 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineLinkedin: {
    backgroundColor: '#004182 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineAdd: {
    backgroundColor: '#8d99ae !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineNote: {
    backgroundColor: '#957e65 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineAssignment: {
    backgroundColor: '#09375d !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineDec: {
    backgroundColor: '#64726a !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineDonut: {
    backgroundColor: '#e1709a !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineActivity: {
    backgroundColor: '#139c87 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineSms: {
    backgroundColor: '#57cc99 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineSms1: {
    backgroundColor: '#57cc99 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineDeal: {
    backgroundColor: '#92399E !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineCal: {
    backgroundColor: '#aabc32 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineCalBlue: {
    backgroundColor: '#dad7cd !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineRev: {
    backgroundColor: '#c1121f !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineStar: {
    backgroundColor: '#c48d39 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineWeb: {
    backgroundColor: '#c7417b !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TimelineTask: {
    backgroundColor: '#f50057 !important',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: '2px 10px',
    position: 'fixed',
  },
  icon: {
    fontSize: '12px',
  },
  timelineIconEmail: {
    fontSize: '20px',
  },
  timelineIconSms: {
    fontSize: '20px',
  },
  timelineIconTask: {
    fontSize: '20px',
  },
  timelineIcon: {
    fontSize: '22px',
  },
  timelineImgEmail: {
    width: '18px',
  },
  timelineImg: {
    width: '20px',
  },
  grayText: {
    color: '#757575',
  },
  responsiveSplit: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  responsiveBtn: {
    padding: '8px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  responsiveFilter: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  iconButton: {
    width: 350,
    padding: 0,
  },
  menu: {
    marginTop: '55px',
  },
  moreBtn: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
  },
  list: {
    padding: '8px 16px',
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  moreBtnR: {
    display: 'block',
    padding: 0,
    paddingLeft: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.up('xs')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  menuText: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  btn: {
    fontSize: 12,
    color: '#fff',
    float: 'right',
    marginLeft: '3px',
    backgroundColor: '#005EB2',
  },
  emailbtn: {
    fontSize: 12,
    color: '#fff',
    float: 'left',
    backgroundColor: '#005EB2',
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  tableWrapper: {
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  Typography: {
    fontFamily: 'open sans',
    fontSize: '12px',
  },
  TypographyHead: {
    color: '#005EB2',
    textAlign: 'start',
  },
  TypographyBody: {
    textAlign: 'end',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
    },
  },
  filterBtn: {
    fontSize: 12,
    marginLeft: '2%',
    color: '#fff',
    float: 'right',
  },
  componentHeading: {
    fontSize: 20,
    color: '#000000',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  ActivityFilter: {
    float: 'right',
    fontSize: 30,
    color: '#005EB2',
    marginLeft: '2%',
  },
  listItemText: {
    fontSize: 11,
  },
  refreshIcon: {
    fontSize: 27,
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'right',
      fontSize: 25,
      width: 'auto',
    },
  },
  buttonBack: {
    fontSize: 11,
    color: '#fff',
    backgroundColor: '#005EB2',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'right',
      textDecoration: 'none',
    },
  },
  hr: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '93%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '91%',
    },
  },
  hrEmail: {
    width: '100%',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  hrEmailBody: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '78%',
    },
  },
  note: {
    marginLeft: theme.spacing(5),
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    width: '91%',
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      textAlign: 'justify',
    },
  },
  amount: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ExpansionPanelDetails: {
    display: 'block',
  },
  dealcomment: {
    marginLeft: theme.spacing(4),
    width: '91%',
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      textAlign: 'justify',
    },
  },
  notificationTitle: {
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactheader: {
    display: 'flex',
    alignItems: 'center',
  },
  titles: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    position: 'relative',
  },
  texthead: {
    padding: '12px',
  },
  reportOrg: {
    cursor: 'pointer',
    display: 'flex',
  },
  emailOrg: {
    display: 'flex',
  },
  organizationView: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  view: {
    height: 380,
    overflow: 'auto',
  },
  textLabel: {
    zIndex: 0,
    color: 'hsl(0,0%,50%)',
    fontSize: '15px !important',
  },
  text: {
    fontSize: 16,
    alignItems: 'center',
    cursor: 'pointer',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  root: {
    width: '100%',
    maxWidth: 360,
  },
  rootColumn: {
    width: '100%',
    maxWidth: 360,
    maxHeight: '55vh',
    overflowY: 'auto',
  },
  profileMenuNotification: {
    width: '39ch',
    maxHeight: '400px',
  },
  headerMenuNotifications: {
    marginTop: theme.spacing(6),
  },
  headerIcon: {
    textDecoration: 'none',
    fontSize: 25,
    color: 'rgba(255, 255, 255, 0.35)',
  },
  userData: {
    display: 'flex',
    textAlign: 'justify',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  noteInfo: {
    display: 'flex',
    textAlign: 'justify',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  noteText: {
    margin: '1px 0px',
  },
  comments: {
    marginLeft: theme.spacing(4),
    width: '91%',
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      width: '100%',
      textAlign: 'justify',
    },
  },
  body: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  email: {
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
    width: '91%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  docTitle: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  filter: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  popOver: {
    padding: '1rem !important',
  },
  GmaliCancel: {
    backgroundColor: '#4285f4',
    color: 'white',
    marginBottom: '3px',
  },
  dateTime: {
    width: '20%',
  },
  bodyEmail: {
    width: '91%',
  },
  bodyEmailText: {
    width: '91%',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  Email: {
    width: '55px',
    height: '28px',
    '&:hover': {
      border: '1px solid #005EB2',
      color: '#005EB2',
    },
    cursor: 'pointer',
  },
  selectedEmail: {
    width: '55px',
    height: '28px',
    border: '3px solid #005EB2',
    color: '#005EB2',
    cursor: 'pointer',
  },
  emailIcon: {
    height: '20px',
    width: '20px',
  },
  SelectemailIcon: {
    height: '18px',
    width: '18px',
  },
  icons: {
    padding: '3px 0px 0px 18px',
  },
  SelectedIcon: {
    padding: '2px 0px 0px 25px',
  },
  emailPaper: {
    display: 'flex',
    flexWrap: 'wrap',
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0),
    },
  },
  marketingTitle: {
    textDecoration: 'none',
    color: '#4A4A4A',
  },
  startSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  reviewtext: {
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'breakword',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 12,
  },
  reviewborad: {
    marginBottom: '8px',
  },
  content: {
    color: '#005EB2',
  },
  Actiondrop: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginLeft: theme.spacing(1),
      marginTop: '2px',
      float: 'left',
    },
  },
  emailicon: {
    padding: '15px 30px',
  },
  cardRatingHeader: {
    display: 'flex',
  },
  customerPhoto: {
    height: '3.125rem',
    width: '3.125rem',
  },
  topbar: {
    marginLeft: theme.spacing(3),
    float: 'left',
    fontSize: 17,
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      padding: '5px',
      marginLeft: theme.spacing(0.5),
    },
  },
  start: {
    float: 'left',
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 'small',
      marginLeft: '1px',
    },
  },
  BottomBut: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 7,
      marginLeft: theme.spacing(1),
      float: 'left',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  heightCard: {
    minHeight: '8.5rem !important',
    maxHeight: '8.5rem !important',
    overflow: 'auto !important',
  },
  Addcomment: {
    fontSize: 11,
    color: '#fff',
    marginTop: 8,
    backgroundColor: '#005EB2',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      float: 'right',
      textDecoration: 'none',
    },
  },
  formControltextsize: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      width: '100%',
    },
  },
  formControl: {
    padding: 1,
    paddingBottom: 5,
    maxWidth: '100%',
  },
  searchResponsive: {
    padding: '0px 8px',
    borderRadius: 5,
    width: '250px',
    height: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '95px',
      paddingLeft: 0,
    },
  },
  searchBtn: {
    maxWidth: '50px',
    maxHeight: '25px',
    minWidth: '50px',
    minHeight: '25px',
    fontSize: 9,
    color: 'white',
    backgroundColor: '#005eb2',
  },
  sortByFilter: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  comment: {
    float: 'right',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    fontSize: 20,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0.5),
      fontSize: 19,
    },
  },
  date: {
    float: 'right',
  },
  addNotes: {
    fontSize: 11,
    float: 'right',
    color: '#fff',
    backgroundColor: '#005EB2',
    textDecoration: 'none',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      float: 'right',
      textDecoration: 'none',
    },
  },
  addCompose: {
    fontSize: 11,
    float: 'right',
    // color: '#fff',
    // backgroundColor: '#005EB2',
    border: '1px solid blue',
    borderRadius: 30,
    textDecoration: 'none',
    marginBottom: '8px',
    marginLeft: '15px',
    marginRight: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      marginRight: '5px',
      marginLeft: '3px',
      float: 'right',
      textDecoration: 'none',
    },
  },
  composeStyle: {
    border: '2px solid blue',
    bordeRadius: 70,
    height: '28px',
    marginTop: '2px',
    marginLeft: '17px',
  },
  refreshBtn: {
    fontSize: 12,
    float: 'right',
    textDecoration: 'none',
    marginBottom: '8px',
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '4px',
      fontSize: 10,
      float: 'right',
      textDecoration: 'none',
    },
  },
  reviewBtn: {
    fontSize: 11,
    float: 'right',
    color: '#fff',
    backgroundColor: '#005EB2',
    textDecoration: 'none',
    marginBottom: '2px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'right',
      textDecoration: 'none',
    },
  },
  backMenuLink: {
    fontSize: 17,
    textDecoration: 'none',
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      textDecoration: 'none',
    },
  },
  arrowIcon: {
    fontSize: 14,
  },
  headerCell: {
    float: 'right',
    color: '#005EB2',
  },
  headerCellBtn: {
    marginLeft: '10px',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
    backgroundColor: '#edeff0',
    [theme.breakpoints.down('sm')]: {
      gap: 3,
      paddingLeft: 7,
    },
    [theme.breakpoints.down('xs')]: {
      gap: 0,
      paddingLeft: 5,
    },
  },
  sectionBox: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionsize: {
    maxHeight: '68vh',
    overflow: 'auto',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionbtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  orgbtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionsizeCRM: {
    maxHeight: '73vh',
    overflow: 'auto',
  },
  dregMenu: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  target: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  targetTags: {
    display: 'flex',
    background: '#f14949',
    height: '25px',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '12px',
    color: 'white',
    position: 'relative',
    '&::after': {
      content: '<',
      borderWidth: '9px',
      borderStyle: 'solid inset solid solid',
      bordercolor: 'var(--negativeBgFlag) transparent var(--negativeBgFlag) var(--negativeBgFlag)',
      bottom: 0,
      display: 'inline-block',
      position: 'absolute',
      height: '20px',
      right: '-18px',
    },
  },
  taskStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  Paper: {
    backgroundColor: '#e8e8ee',
    color: '#000000',
    padding: '0px 0px 0px 0px',
    fontWeight: 500,
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e8e8ee',
    },
  },
  buttonSave: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'right',
    },
  },
  bodyActivity: {
    fontSize: 12,
    fontFamily,
  },
  Dropzone: {
    height: '190px',
    textAlign: 'center',
    padding: '20px',
    border: '3px dashed #eeeeee',
    backgroundColor: 'transparent',
    color: '#bdbdbd',
    marginBottom: '20px',
  },
  DealCardContent: {
    marginRight: '192px',
    [theme.breakpoints.down('md')]: {
      float: 'right',
      marginRight: '192px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '5px',
      float: 'right !imporatant',
      marginLeft: '20px !important',
      marginRight: '0px',
    },
  },
  infoBox: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  Sub: {
    display: 'block',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    fontWeight: 500,
    margin: '5px 0px',
  },
  draftGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dangerButton: {
    backgroundColor: '#d9534f',
  },
  call: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  callerImage: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginTop: theme.spacing(1),
    fontSize: 32,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  jumpPage: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 24px 0px 24px',
  },
  pages: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
  },
  jumpPageTextPage: {
    width: '60px',
    border: localStorage.getItem('theme') === 'dark' ? '2px solid white' : '',
    borderRadius: 5,
  },
  parentStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
  },
  cellStyle: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  filterContacts: {
    cursor: 'pointer',
    background: '#f8f8f8',
    color: '#000',
    padding: '6px 16px',
    border: '1px solid #babfc7',
    fontSize: '14px',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  disabled: {
    backgroundColor: '#EBEBE4',
    width: '100%',
  },
  pointer: {
    cursor: 'pointer',
  },
  nextButton: {
    marginLeft: 10,
    height: 'fit-content',
    alignSelf: 'center',
  },
  themeText: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  taskStatusText: {
    color: 'black !important',
  },
  filterWrapper: {
    // background: '#e1e7f7',
    borderRadius: 5,
    padding: '10px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filterMain: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  divider: {
    borderBottom: '1px solid #ccc',
    margin: '10px 0',
  },
  closeIcon: {
    textAlign: 'center',
    margin: 'auto',
  },
  ImportBtnClass: {
    color: '#000',
    background: '#f8f8f8',
    cursor: 'pointer',
    border: '1px solid #005eb2',
    boxShadow: '2px 3px 2px 2px rgba(0,94,178,0.3), 0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    borderRadius: 12,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  arrowBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  arrow: {
    fontSize: '2rem',
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  fieldName: {
    color: '#005eb2 !important',
    fontSize: 15,
    fontWeight: 500,
    paddingLeft: 4,
  },
  wfImg: {
    width: 20,
  },
  clsBtn: {
    paddingLeft: 3,
  },
  timelineGridContainer: {
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  timelineGridContainerSpace: {
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingTop: '6px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
    },
  },
  timelineGridItem: {
    textAlign: 'end',
  },
  emailStatusBar: {
    flexDirection: 'column',
  },
  map: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 18,
  },
  mapStatus: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
  },
  mapLineBl: {
    display: 'flex',
    alignItems: 'center',
  },
  mapLine: {
    display: 'flex',
    alignItems: 'center',
    width: '130px',
    [theme.breakpoints.down('xs')]: {
      width: 77,
    },
  },
  mapLineName: {
    display: 'flex',
    alignItems: 'center',
    width: '130px',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      width: 77,
    },
  },
  statusTitle: {
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
  statusHr: {
    width: '100%',
    height: 3,
    backgroundColor: '#a4a4a4',
    border: 'none',
  },
  dotIcon: {
    fontSize: '14px',
    color: '#a4a4a4',
  },
  dotIconFocus: {
    fontSize: '20px',
    color: '#2b5862',
    border: '2px solid #2b5862',
    borderRadius: '50%',
    padding: 2,
  },
  timeLineContent: {
    width: '100%',
  },
  hiddenMapLine: {
    display: 'none',
  },
  descriptionBox: {
    display: 'flex',
    alignItems: 'end',
  },
  locationIcon: {
    color: `${localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2'} !important`,
    fontSize: 20,
    marginTop: 2,
  },
  dialogBtn: {
    backgroundColor: 'lightgrey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    width: '100%',
  },
  orgClickName:{
    color: '#005eb2 !important',
    margin: 0,
    cursor: 'pointer',
  },
  orgName:{
    margin: 0,
  },
  mailServer: {
    width: '25px',
  },
  sourceText: {
    backgroundColor: '#e2edf5',
    color: '#000027',
    borderRadius: '14px',
    padding: '4px 7px 6px 7px',
    margin : '0px 3px',
  },
}));
