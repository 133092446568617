import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography, Box, MobileStepper, Button, Link,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import config from '../../../config';
import { dummyimage } from '../../../common/const';
import useStyle from './PublicEventWidgetStyle';
import { Entity } from '../../../Api Mutation/EntityApi';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import { postDataRequestPublic } from '../../../common/commonfunctions';
import { BusinessEventSettingsByLocationId, Businesseventsbylocation } from '../../../Api Mutation/Event';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import PoweredBy from '../../../common/PoweredBy';

export default function PublicEventWidget() {
  const classes = useStyle();
  const [dataLoading, setDataLoading] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [titleData, setTitleData] = useState();
  const [eventData, setEventData] = useState([]);
  const [entityData, setEntityData] = useState();
  const [whiteLabelStatus, setWhiteLabelStatus] = useState();
  const [subDomain, setSubDomain] = useState();
  const {
    lid,
  } = useParams([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();

  const maxSteps = eventData.length > 0 ? Math.ceil(eventData[0]?.count / 5) : 0;
  const rowsPerPage = 5;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getBusinessEventData = (props) => {
    if (lid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Businesseventsbylocation,
        variables: {
          locationid: lid,
          pagenumber: props.pageNumber + 1,
          pagesize: props.pageSize,
          ispublic: true,
        },
      }).then((res) => {
        if (res?.data?.businesseventsbylocation && res?.data?.businesseventsbylocation.length > 0) {
          setEventData(res?.data?.businesseventsbylocation.filter((i) => i.ispublish === true));
        }
        setDataLoading(false);
      });
    }
  };

  const getWidgetSetting = () => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: BusinessEventSettingsByLocationId,
        variables: { locationid: lid },
      }).then((res) => {
        const data = res?.data?.businessEventSettingsByLocationId;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data) {
          setTitleData(data);
          setEnablePublicLogo(data?.isshowfooterlogo);
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBusinessEventData({
        pageNumber: activeStep,
        pageSize: rowsPerPage,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [activeStep, lid, rowsPerPage]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (lid) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: lid,
          },
        }).then((result) => {
          const data = result?.data?.entity;
          if (data) {
            setEntityData(data);
            setWhiteLabelStatus(data?.whitelabeltype);
            setSubDomain(data?.whitelabeldomain);
          }
        });
      }
      getWidgetSetting();
    }
    return () => {
      isMounted = false;
    };
  }, [lid]);

  return (
    <>
      {dataLoading && <LoadingPage />}
      <Box className={classes.Widget}>
        <Box className={titleData?.contentposition === 'center' ? classes.WidgetLayout : classes.WidgetLayoutAlign}>
          <Typography variant="h6" color="primary">
            {titleData?.pagetitle}
          </Typography>
          <Typography variant="body1" color="primary">
            {titleData?.subtitle}
          </Typography>
          <Typography variant="body2" color="primary">
            {titleData?.innersubtitle}
          </Typography>
        </Box>
        <Box className={classes.contentsText}>
          {eventData && eventData.map((i) => (
            <Box className={classes.contents}>
              <Box>
                <Link
                  href={
                    whiteLabelStatus === 'full' && subDomain && entityData?.subdomain
                      ? `https://${entityData?.subdomain}.${subDomain}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}`
                      : `https://${entityData?.subdomain}${config.subDomainUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}`
                      || `${config.BixUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}` || i?.eventbookingurl
                  }
                  target="_blank"
                >
                  <img className={classes.img} src={i?.eventimage ? i?.eventimage : dummyimage} alt="demo" />
                </Link>
              </Box>
              <Box className={classes.contentsText}>
                <Link
                  href={
                    whiteLabelStatus === 'full' && subDomain && entityData?.subdomain
                      ? `https://${entityData?.subdomain}.${subDomain}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}`
                      : `https://${entityData?.subdomain}${config.subDomainUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}`
                      || `${config.BixUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}` || i?.eventbookingurl
                  }
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <Typography variant="h6">
                    {i?.name}
                  </Typography>
                </Link>
                <Typography variant="body2">
                  {moment(parseInt(i?.eventstartdate, 10)).format('dddd, MMMM DD,YYYY HH:mm A z')}
                  {' '}
                  To
                  {' '}
                  {moment(parseInt(i?.eventenddate, 10)).format('dddd, MMMM DD,YYYY HH:mm A z')}
                </Typography>
              </Box>
            </Box>
          ))}
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={(
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            )}
            backButton={(
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            )}
          />
          <center>
            {enablePublicLogo ? <PoweredBy id={lid} /> : ''}
          </center>
        </Box>
      </Box>
    </>
  );
}
