import React, { useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  Box,
  Radio,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Paper } from '../Wrappers';
import useStyles from './styles';
import { EntityType, Roles } from '../../common/const';
import { postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import {
  useLayoutState,
  useLayoutDispatch,
  setLocationModal,
  setBusinessModal,
} from '../../context/LayoutContext';
import {
  useGlobalState,
  setLocationInfo,
  getLocationAddress,
  getCurrentLocationInfo,
} from '../../context/GlobalContext';
import { useUserState } from '../../context/UserContext';
import {
  useEntitiesState,
  getLocations,
  getLocationsByParent,
  getAgencyByUser,
  getBusinessByUser,
} from '../../context/EntityContext';

export default function Location(props) {
  const classes = useStyles();
  const { isMasterUser, isAgencyUser, history } = props;
  const globalContext = useGlobalState();
  const layoutState = useLayoutState();
  const { userInfo } = useUserState();
  const layoutDispatch = useLayoutDispatch();
  const entitiesState = useEntitiesState();
  const { entities, setEntities } = useEntitiesState();
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [selectedBusiness, setSelectedBusiness] = React.useState('a');
  const [selectedRadioLocation, setSelectedRadioLocation] = useState([]);
  const [selectedRadioBusiness, setSelectedRadioBusiness] = useState([]);
  const [businessLocationData, setBusinessLocation] = useState([]);
  const getStartedUser = [Roles?.MasterAdmin, Roles?.AgencyManager, Roles?.AgencyAdmin];

  const dealPipelineId = localStorage.getItem('dealPipelineId');
  const dealPipelineName = localStorage.getItem('dealPipelineName');

  const handleChangeMaster = () => {
    if (selectedRadioBusiness.businessid) {
      setSelectedBusiness(selectedRadioBusiness.businessid);
      localStorage.setItem(
        'business_Id',
        JSON.stringify(selectedRadioBusiness.businessid),
      );
    }
    if (selectedRadioBusiness.locationid) {
      setSelectedValue(selectedRadioBusiness.locationid);
      localStorage.setItem(
        'location_Id',
        JSON.stringify(selectedRadioBusiness.locationid),
      );
      getCurrentLocationInfo(
        selectedRadioBusiness.locationid,
        globalContext.setGlobalState,
      );
      getLocationAddress(
        selectedRadioBusiness.locationid,
        globalContext.setGlobalState,
      );
    }
    setLocationModal(false, layoutDispatch);
    setBusinessModal(false, layoutDispatch);
  };
  const handleChange = () => {
    if (selectedRadioBusiness.entityid) {
      setSelectedBusiness(selectedRadioBusiness.entityid);
      localStorage.setItem(
        'business_Id',
        JSON.stringify(selectedRadioBusiness.entityid),
      );
    }
    if (selectedRadioLocation.entityid) {
      setSelectedValue(selectedRadioLocation.entityid);
      localStorage.setItem(
        'location_Id',
        JSON.stringify(selectedRadioLocation.entityid),
      );
      if (dealPipelineId) {
        localStorage.removeItem('dealPipelineId');
        localStorage.removeItem(
          'dealPipelineName'
      );
    }
      setLocationInfo(
        selectedRadioLocation,
        globalContext.setGlobalState,
      );
      getCurrentLocationInfo(
        selectedRadioLocation.entityid,
        globalContext.setGlobalState,
      );
      getLocationAddress(
        selectedRadioLocation.entityid,
        globalContext.setGlobalState,
      );
    }
    setLocationModal(false, layoutDispatch);
    setBusinessModal(false, layoutDispatch);
    if (userInfo?.isenabletwofactorauth === true) {
      history.push('/verify');
    } else if (userInfo?.roleid === Roles?.BusinessManager) {
      history.push('/app/home/dashboard');
    } else if (getStartedUser.includes(userInfo?.roleid)) {
      history.push('/app/home/agency');
    } else {
      history.push('/app/home/dashboard');
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (userInfo?.roleid === Roles?.MasterAdmin
        || userInfo?.roleid === Roles?.AgencyAdmin
        || userInfo?.roleid === Roles?.AgencyManager) {
        postDataRequest(config.baseURLApi, {
          query: `{
          businesswithlocationlist{
          businessid,bbusinessname,locationid,lbrandname, lbusinessname
          }
          }`,
        }).then((res) => {
          setBusinessLocation(res?.data?.businesswithlocationlist);
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [isMasterUser, isAgencyUser]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedBusiness
        && (userInfo?.roleid === Roles?.AgencyAdmin
          || userInfo?.roleid === Roles?.AgencyManager
        )) {
        getLocationsByParent(selectedBusiness, setEntities);
      }
      if (entities?.businessData && entities?.businessData[0]?.entityid
        && (userInfo?.roleid === Roles?.BusinessAdmin)) {
        getLocationsByParent(entities?.businessData
          && entities?.businessData[0]?.entityid, setEntities);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    entities?.businessData && entities?.businessData[0]?.entityid,
    selectedBusiness,
    setEntities,
  ]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const businessId = localStorage.getItem('business_Id');
      const locationId = localStorage.getItem('location_Id');
      if (userInfo?.roleid === Roles?.AgencyManager || userInfo?.roleid === Roles?.AgencyAdmin) {
        getAgencyByUser(entitiesState.setEntities);
        setSelectedBusiness(JSON.parse(businessId));
        if (localStorage.getItem('location_Id')) {
          setSelectedValue(JSON.parse(locationId));
        } else if (entitiesState?.entities?.locationList.length !== 0) {
          setSelectedValue(entitiesState?.entities?.locationList[0]?.entityid);
          localStorage.setItem(
            'location_Id',
            JSON.stringify(entitiesState?.entities?.locationList[0]?.entityid),
          );
          setSelectedRadioLocation(entitiesState?.entities?.locationList[0]);
          getCurrentLocationInfo(
            entitiesState?.entities?.locationList[0]?.entityid,
            globalContext.setGlobalState,
          );
        }
      } else if (userInfo?.roleid === Roles?.BusinessAdmin) {
        setBusinessModal(true, layoutDispatch);
        getBusinessByUser(entitiesState.setEntities);
        if (localStorage.getItem('location_Id')) {
          setSelectedValue(JSON.parse(locationId));
        } else if (entitiesState?.entities?.locationList.length !== 0) {
          setSelectedValue(entitiesState.entities.locationList[0].entityid);
          localStorage.setItem(
            'location_Id',
            JSON.stringify(entitiesState.entities.locationList[0].entityid),
          );
          setSelectedRadioLocation(entitiesState.entities.locationList[0]);
          getCurrentLocationInfo(
            entitiesState.entities.locationList[0].entityid,
            globalContext.setGlobalState,
          );
        }
      } else {
        if (localStorage.getItem('business_Id')
          && (userInfo?.roleid === Roles?.MasterAdmin
            || userInfo?.roleid === Roles?.AgencyManager
            || userInfo?.roleid === Roles?.AgencyAdmin
            || userInfo?.roleid === Roles?.BusinessAdmin
          )) {
          setSelectedBusiness(JSON.parse(businessId));
          getLocationsByParent(
            JSON.parse(businessId),
            entitiesState.setEntities,
          );
        } else if (!localStorage.getItem('location_Id')) {
          getLocations(EntityType.Location, entitiesState.setEntities);
        }
        if (localStorage.getItem('location_Id')) {
          if (entitiesState?.entities?.locationList[0]?.entityid && !entitiesState?.entities?.locationList?.includes(localStorage.getItem('location_Id'))) {
            localStorage.setItem(
              'location_Id',
              JSON.stringify(entitiesState?.entities?.locationList[0]?.entityid),
            );
            setSelectedValue(entitiesState?.entities?.locationList[0]?.entityid);
            getCurrentLocationInfo(
              entitiesState.entities.locationList[0].entityid,
              globalContext.setGlobalState,
            );
          } else {
            setSelectedValue(JSON.parse(locationId));
          }
        } else if (entitiesState?.entities?.locationList.length !== 0) {
          setSelectedValue(entitiesState.entities.locationList[0].entityid);
          localStorage.setItem(
            'location_Id',
            JSON.stringify(entitiesState.entities.locationList[0].entityid),
          );
          setSelectedRadioLocation(entitiesState.entities.locationList[0]);
          getCurrentLocationInfo(
            entitiesState.entities.locationList[0].entityid,
            globalContext.setGlobalState,
          );
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      {/* {isMasterUser || userInfo?.roleid === Roles?.AgencyAdmin
        || userInfo?.roleid === Roles?.AgencyManager ? (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={layoutState.businessModal}
          onClose={() => setBusinessModal(false, layoutDispatch)}
          scroll="body"
          aria-labelledby="scroll-dialog-title"
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle id="scroll-dialog-title">
            <Paper
              disablewidgetmenu="true"
              inheritheight="true"
              size="small"
              className={classes.margin}
              bodyclass={classes.navPadding}
            >
              <Box display="flex" justifyContent="flex-start">
                <EditLocationIcon color="primary" />
                &nbsp;
                <Typography color="primary" className={classes.marginText} variant="body1">
                  Business | Locations
                </Typography>
              </Box>
            </Paper>
          </DialogTitle>
          <DialogContent style={{ maxHeight: '250px' }}>
            <Grid container>
              <Grid item xs={12}>
                <Table className="mb-0">
                  <TableBody>
                    {businessLocationData?.length > 0 && businessLocationData?.map((d) => (
                      <TableCell
                        style={{
                          display: 'flex',
                          alignItems:
                            'center',
                        }}
                      >
                        <Radio
                          checked={
                            selectedValue
                            === d.locationid
                          }
                          name="rdolocation"
                          value={d.entityid}
                          onClick={() => {
                            setSelectedBusiness(
                              d.businessid,
                            );
                            setSelectedValue(d.locationid);
                            setSelectedRadioBusiness(d);
                            getLocationsByParent(
                              d.businessid,
                              entitiesState.setEntities,
                            );
                          }}
                        />
                        {' '}
                        <Typography variant="body2">
                          {d.bbusinessname}
                          {' '}
                          |
                          {' '}
                          {d.lbusinessname}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleChangeMaster}
              color="primary"
              variant="contained"
              size="small"
              startIcon={<CheckCircleIcon />}
            >
              Select
            </Button>
            <Button
              onClick={() => setBusinessModal(false, layoutDispatch)}
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<CloseOutlinedIcon />}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      ) : ''} */}
      {isMasterUser ? (
        <>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={layoutState.locationModal}
            onClose={() => setLocationModal(false, layoutDispatch)}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            classes={{ paper: classes.dialog }}
          >
            <DialogTitle id="scroll-dialog-title">
              <Paper
                disablewidgetmenu="true"
                inheritheight="true"
                size="small"
                className={classes.margin}
                bodyclass={classes.navPadding}
              >
                <Box display="flex" justifyContent="flex-start">
                  <EditLocationIcon color="primary" />
                  &nbsp;
                  <Typography color="primary" className={classes.marginText} variant="body1">
                    Locations
                  </Typography>
                </Box>
              </Paper>
            </DialogTitle>
            <DialogContent style={{ maxHeight: '250px' }}>
              <Grid container>
                <Grid item xs={isMasterUser ? 12 : 12}>
                  <List>
                    {entitiesState.entities.locationLoading ? (
                      <Box textAlign="center">
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        {entitiesState?.entities?.locationList
                          ?.length === 0 ? (
                          <center>
                            <p className={classes?.userNote}>
                              Ask your admin to assign location.
                            </p>
                          </center>
                        ) : (
                          entitiesState?.entities?.locationList.map(
                            (d) => (
                              <ListItem key={d.entityid}>
                                <ListItemText className={classes.flexItems}>
                                  <Typography variant="body2">
                                    <Radio
                                      checked={
                                        selectedValue
                                        === d.entityid
                                      }
                                      name="rdolocation"
                                      value={
                                        d.entityid
                                      }
                                      onClick={() => {
                                        setSelectedValue(
                                          d.entityid,
                                        );
                                        setSelectedRadioLocation(
                                          d,
                                        );
                                      }}
                                    />
                                    {' '}
                                    {d.businessname}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            ),
                          )
                        )}
                      </>
                    )}
                  </List>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleChange}
                color="primary"
                variant="contained"
                size="small"
                startIcon={<CheckCircleIcon />}
              >
                Select
              </Button>
              <Button
                onClick={() => setLocationModal(false, layoutDispatch)}
                color="secondary"
                variant="contained"
                size="small"
                startIcon={<CloseOutlinedIcon />}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : ''}
      {
        !isMasterUser ? (
          <Dialog
            fullWidth
            maxWidth="xs"
            open={layoutState.locationModal}
            onClose={() => setLocationModal(false, layoutDispatch)}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            classes={{ paper: classes.dialog }}
          >
            <DialogTitle id="scroll-dialog-title">
              <Paper
                disablewidgetmenu="true"
                inheritheight="true"
                size="small"
                className={classes.margin}
                bodyclass={classes.navPadding}
              >
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  {localStorage.getItem('theme') === 'dark' ? (
                    <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/location.svg" alt="location" />
                  )
                    : (<EditLocationIcon color="primary" />)}
                  &nbsp;
                  <Typography color="primary" className={classes.marginText} variant="body1">
                    {isMasterUser
                      ? 'Select : Business | Location'
                      : 'Change Location'}
                  </Typography>
                </Box>
              </Paper>
            </DialogTitle>
            <DialogContent style={{ maxHeight: '250px' }}>
              <Grid container>
                <Grid item xs={isMasterUser || isAgencyUser ? 12 : 12}>
                  {entitiesState.entities.locationLoading ? (
                    <Box textAlign="center">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {entitiesState?.entities?.locationList
                        ?.length === 0 ? (
                        <center>
                          <p className={classes?.userNote}>Ask your admin to assign location.</p>
                        </center>
                      ) : (
                        <>
                          {entitiesState?.entities?.locationList.map(
                            (d, i) => (
                              <Box className={classes.flexItems} key={i}>
                                <Radio
                                  checked={
                                    selectedValue
                                    === d.entityid
                                  }
                                  name="rdolocation"
                                  value={
                                    d.entityid
                                  }
                                  onClick={() => {
                                    setSelectedValue(
                                      d.entityid,
                                    );
                                    setSelectedRadioLocation(
                                      d,
                                    );
                                  }}
                                />
                                <Typography variant="body2">
                                  {d.businessname}
                                </Typography>
                              </Box>
                            ),
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleChange}
                color="primary"
                variant="contained"
                size="small"
                startIcon={<CheckCircleIcon />}
              >
                Select
              </Button>
              <Button
                onClick={() => setLocationModal(false, layoutDispatch)}
                color="secondary"
                variant="contained"
                size="small"
                startIcon={<CloseOutlinedIcon />}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        ) : ''
      }
    </>
  );
}
