/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import {
  Grid, Box, CircularProgress, Typography,
} from '@material-ui/core';
import Input from '@material-ui/core/TextField';
import { CheckCircleOutline } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import useStyles from './styles';
import Widget from '../../../components/Widget/Widget';
import { Button } from '../../Wrappers';
import { handleUndefined, postDataRequest } from '../../../common/commonfunctions';

import config from '../../../config';
import {
  useGlobalState,
  setLoginUserInfo,
} from '../../../context/GlobalContext';
import PoweredBy from '../../../common/PoweredBy';

const StyledRating = withStyles({
  iconFilled: {
    color: '#0259a8',
  },
  iconHover: {
    color: '#0259a8',
  },
  iconEmpty: {
    color: '#74b6f7',
  },
  sizeLarge: {
    fontSize: '3rem',
  },
})(Rating);
export default function DirectNagativefeedbackpage({
  brandInfo,
  feedbackValue,
  handleFeedbackValue,
  loading,
  handleSubmit,
  error,
  ratingType,
}) {
  const classes = useStyles();
  const [rating, setRating] = useState();
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const bid = params.get('bid');
  const r = params.get('r');
  const globalContext = useGlobalState();
  const [entity, setEntity] = useState([]);
  useEffect(() => {
    let isMounted = true;
    if (localStorage.getItem('business_Id')) {
      postDataRequest(config.baseURLApi, {
        query: `query entity($entityid:ID!){
              entity(entityid:$entityid){
                entityid
                businessname
                brandname
                firstname
                lastname
                email
                phonenumber
                entitytypeid
                businesstypeid
                jobid
                tags
                website
                shortdesc
                fulldesc
                policy
                terms
                }}`,
        variables: {
          entityid: JSON.parse(localStorage.getItem('business_Id')),
        },
      })
        .then((res) => {
          if (res.data && res.data.entity && isMounted) {
            setEntity(res.data.entity);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('userid')]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (bid && bid !== 'undefined') {
        setRating(parseInt(r, 10));
      }
      setLoginUserInfo(globalContext.setGlobalState);
    }
    return () => {
      isMounted = false;
    };
  }, [r, bid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && localStorage.getItem('userid')) {
      postDataRequest(config.baseURLApi, {
        query: `
              {brandSettingByLocation(locationid: ${localStorage.getItem(
    'userid',
  )}){color,dateformat,timeformat,logourl}}
            `,
      })
        .then(() => { })
        .catch(() => { });
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('userid')]);

  const handlePolicy = () => {
    window.open(entity.policy, '_blank');
  };
  const handleTerms = () => {
    window.open(entity.terms, '_blank');
  };
  return (
    <>
      <center>
        {brandInfo?.logo ? (
          <>
            <img
              className={classes.large2}
              src={brandInfo?.logo}
              alt="brand logo"
            />
          </>
        ) : ''}
        <br />
        <Typography variant="h6">{entity?.brandname}</Typography>
        <br />
      </center>
      <Box className={classes.negativeExperienceCard}>
        <Box style={{ display: 'flex' }}>
          <Typography variant="h1" className={classes.marginZero}>
            Your feedback is very important for our success!
          </Typography>
        </Box>
      </Box>
      <Box width="100%">
        <Grid item xs={12} md={12}>
          <Widget>
            {ratingType ? (
              <Grid item md={5} xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <StyledRating
                    name="customized-color size-large"
                    value={r}
                    precision={1}
                    size="large"
                    readOnly
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="space-between">
                  <div
                    className={`${classes.shape} ${classes.redRating} ${parseInt(r) === 1 ? classes.activeRating : ''
                    }`}
                  >
                    1
                  </div>
                  <div
                    className={`${classes.shape} ${classes.redRating} ${parseInt(r) === 2 ? classes.activeRating : ''
                    }`}
                  >
                    2
                  </div>
                  <div
                    className={`${classes.shape} ${classes.redRating} ${parseInt(r) === 3 ? classes.activeRating : ''
                    }`}
                  >
                    3
                  </div>
                  <div
                    className={`${classes.shape} ${classes.redRating} ${parseInt(r) === 4 ? classes.activeRating : ''
                    }`}
                  >
                    4
                  </div>
                  <div
                    className={`${classes.shape} ${classes.redRating} ${parseInt(r) === 5 ? classes.activeRating : ''
                    }`}
                  >
                    5
                  </div>
                  <div
                    className={`${classes.shape} ${classes.redRating} ${parseInt(r) === 6 ? classes.activeRating : ''
                    }`}
                  >
                    6
                  </div>
                  <div
                    className={`${classes.shape} ${classes.yellowRating
                    } ${parseInt(r) === 7 ? classes.activeRating : ''
                    }`}
                  >
                    7
                  </div>
                  <div
                    className={`${classes.shape} ${classes.yellowRating
                    } ${parseInt(r) === 8 ? classes.activeRating : ''
                    }`}
                  >
                    8
                  </div>
                  <div
                    className={`${classes.shape} ${classes.greenRating
                    } ${parseInt(r) === 9 ? classes.activeRating : ''
                    }`}
                  >
                    9
                  </div>
                  <div
                    className={`${classes.shape} ${classes.greenRating
                    } ${parseInt(r) === 10 ? classes.activeRating : ''
                    }`}
                  >
                    10
                  </div>
                </Box>
                <Box marginTop={1} display="flex" justifyContent="space-between">
                  <Box fontWeight="fontWeightBold"><Typography variant="body2"><SentimentVeryDissatisfiedIcon style={{ color: '#F04D5D' }} /></Typography></Box>
                  <Box fontWeight="fontWeightBold">
                    <Typography variant="body2"><SentimentDissatisfiedIcon style={{ color: '#F04D5D' }} /></Typography>
                  </Box>
                  <Box fontWeight="fontWeightBold" marginRight={2}>
                    <Typography variant="body2"><SentimentSatisfiedAltIcon style={{ color: '#5FBF91' }} /></Typography>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item container alignItems="center">
              <Grid xs={12} item>
                <Typography variant="body2" color="primary">
                  Please tell us more.
                </Typography>
                <Input
                  className={classes.fieldColor}
                  id="outlined-multiline-static"
                  label="Your Feedback is important to us. Enter text here"
                  multiline
                  minRows="10"
                  fullWidth
                  placeholder={`You have rated ${entity?.businessname} service ${rating}. Could you tell them how they can improve their service?`}
                  margin="normal"
                  variant="outlined"
                  value={feedbackValue}
                  onChange={handleFeedbackValue}
                />
                {error && (
                  <>
                    {!feedbackValue && (
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color="error"
                        gutterBottom
                      >
                        Feedback Message is required
                      </Typography>
                    )}
                  </>
                )}
                <center />
              </Grid>
            </Grid>
          </Widget>
          <br />
          <center>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={
                loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CheckCircleOutline />
                )
              }
              onClick={handleSubmit}
            >
              <Typography variant="body2">SEND FEEDBACK</Typography>
            </Button>
          </center>
        </Grid>
      </Box>
      <center>
        <Typography style={{ fontSize: 13, marginTop: 5 }}>
          {handleUndefined(entity.policy) && (
            <>
              {' '}
              <b
                onClick={handlePolicy}
              >
                {' '}
                Privacy Policy
              </b>
              {' '}
              /
              {' '}
            </>
          )}
          {' '}
          {handleUndefined(entity.terms) && (
            <b
              style={{ textDecoration: 'none' }}
              onClick={handleTerms}
            >
              Terms & Conditions
            </b>
          )}
        </Typography>
        <PoweredBy id={bid} />
      </center>
    </>
  );
}
