import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, Paper, Typography,
} from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { ContactDocument } from '../../Api Mutation/Contact';
import config from '../../config';
import { postDataRequestPublic } from '../../common/commonfunctions';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import useStyle from './styles';

const DocumentPublicPage = () => {
  const {
    documentid,
  } = useParams([]);
  const classes = useStyle();
  const [dataLoading, setDataLoading] = useState(false);
  const [docs, setDocs] = useState([]);
  const [fileDeleted, setDeleted] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (documentid && isMounted) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: ContactDocument,
        variables: {
          documentid,
        },
      }).then((res) => {
        const result = res?.data?.contactDocument;
        if (result) {
          const { documents, deleted } = result;
          setDocs(JSON.parse(documents));
          setDeleted(deleted);
        }
        setDataLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [documentid]);

  const extension = docs?.[0]?.split('/')[docs?.[0]?.split('/').length - 1]?.split('.')?.[1];

  return (
    <>
      {dataLoading ? <LoadingPage /> : ''}
      {fileDeleted ? (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
          backgroundColor: localStorage.getItem('theme') === 'dark' ? '#23232d' : 'white',
        }}
        >
          <center>
            <p style={{
              margin: 0, fontSize: 23, textAlign: 'center', fontWeight: 600, fontFamily: 'sans-serif',
            }}
            >
              <p style={{ margin: 0 }}>
                We are sorry, but the document you are looking for
                does not exist or may have been removed.
              </p>
            </p>
          </center>
        </div>
      ) : (
        extension?.toLowerCase() === 'pdf' ? (
          <iframe
            title="document"
            src={docs[0]}
            width="100%"
            allowFullScreen=""
            style={{
              display: 'block',
              height: '100vh',
              border: 'none',
            }}
          />
        ) : ['jpg', 'jpeg', 'png', 'svg'].includes(extension?.toLowerCase()) ? (
          <div className={classes.paper}>
            <Paper>
              <div className="p-3">
                <img
                  src={docs[0]}
                  alt="docs"
                  style={{
                    width: 'auto', height: '480px', objectFit: 'cover', aspectRatio: 'auto',
                  }}
                />
                <center>
                  <div>
                    {docs
                  && docs.map((documents) => {
                    const Documentfile = documents.split('/');
                    return (
                      <>
                        <Typography style={{ fontSize: 16, wordBreak: 'break-all' }}>
                          {Documentfile[Documentfile?.length - 1]}
                        </Typography>
                      </>
                    );
                  })}
                  </div>
                  <div className="mt-2">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.open(docs[0], '_blank');
                      }}
                    >
                      Download
                    </Button>
                  </div>
                </center>
              </div>
            </Paper>
          </div>
        ) : (
          <div className={classes.paper}>
            <Paper className={classes.cal} elevation={6}>
              {['mp4', 'ogg', 'gif', 'webm'].includes(extension?.toLowerCase()) ? (
                <div>
                  <video
                    controls="controls"
                    height="330"
                    width="575"
                    autoPlay
                  >
                    <source
                      src={docs[0]}
                      type={`video/${extension}`}
                    />
                    <track kind="captions" srcLang="en" label="English captions" />
                  </video>
                  <center>
                    <div>
                      {docs
                        && docs.map((documents) => {
                          const Documentfile = documents.split('/');
                          return (
                            <>
                              <Typography style={{ fontSize: 16, wordBreak: 'break-all' }}>
                                {Documentfile[Documentfile?.length - 1]}
                              </Typography>
                            </>
                          );
                        })}
                    </div>
                  </center>
                </div>
              ) : (
                <>
                  <div className={classes.firstdiv}>
                    <center>
                      <DescriptionOutlinedIcon style={{ fontSize: 160 }} />
                      <div>
                        {docs
                        && docs.map((documents) => {
                          const Documentfile = documents.split('/');
                          return (
                            <>
                              <Typography style={{ fontSize: 24, wordBreak: 'break-all' }}>
                                {Documentfile[Documentfile?.length - 1]}
                              </Typography>
                            </>
                          );
                        })}
                      </div>
                    </center>
                  </div>
                  <div className={classes.vertical} />
                  <div className={classes.seconddiv}>
                    <center>
                      <CloudDownloadOutlinedIcon style={{ fontSize: 160 }} />
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(docs[0], '_blank');
                          }}
                        >
                          View / Download
                        </Button>
                      </div>
                    </center>
                  </div>
                </>
              )}
            </Paper>
          </div>
        )
      )}
    </>
  );
};

export default DocumentPublicPage;
