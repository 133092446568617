import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Bix from './bix.png';
import useStyles from './style';

function LazyLoader() {
  const classes = useStyles();
  return (
    <div className={classes.lazyDiv}>
      <center>
        <img src={Bix} alt="img" className={classes.bixImg} />
        <CircularProgress color="primary" size={45} />
        <Skeleton animation="pulse" className={classes.skelaton} />
        <Skeleton animation="pulse" className={classes.skelaton} />
      </center>
    </div>
  );
}

export default LazyLoader;
