/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect, BrowserRouter,
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Layout from './Layout';
import Error from '../pages/error';
import Login from '../pages/login/Login-New';
import TwofactorAuth from '../pages/login/TwofactorAuth';
import VerifyAccount from '../pages/login/verifyAccount';
import NewGettingStarted from '../pages/NewGettingStarted/NewGettingStarted';
import { useUserState } from '../context/UserContext';
import PositiveComment from '../pages/Setting/ReviewComment/PositiveComment';
import RatingPositiveComment from '../pages/Setting/ReviewComment/RatingPositiveComment';
import NegativeComment from '../pages/Setting/ReviewComment/NegativeComment';
import RatingNegativeComment from '../pages/Setting/ReviewComment/RatingNegativeComment';
import ReviewDisplay from '../pages/PublicReviewPage/ReviewDisplay';
import Kioskmode from '../pages/Display/Kioskmode';
import EmailForForget from '../pages/login/emailForForget';
import ResetPassword from '../pages/login/resetpassword';
import PublicPage from '../pages/PublicPage/PublicPage';
import PublicNewsletterWidget from '../pages/NewsLetter/PublicNewsletterWidget';
import PublicThankYou from '../pages/Setting/ReviewComment/PublicThankYou';
import TouchLessPublic from '../pages/Display/TochlessQRcode/TouchLessPublic';
import { EntitiesProvider } from '../context/EntityContext';
import { CampaginProvider } from '../context/CampaginContext';
import ReviewSms from '../pages/reviewcam/ReviewSms';
import Url from '../pages/reviewcam/Url';
import ThirdPartyUrl from '../pages/Setting/ThirdPartyCal/ThirdPartyUrl';
import TextUrl from '../pages/Text_marketing/TextMarketingUrl';
import PaymentUrl from '../pages/Payment&Invoice/invoicePublicUrl';
import { postDataRequest, handleUndefined, decrypt } from '../common/commonfunctions';
import config from '../config';
import { LoadingPage } from '../pages/LoadingPage/LoadingPage';
import { Roles, bixpandDescription } from '../common/const';
import { User } from '../Api Mutation/Login';
import RatingReviewSms from '../pages/reviewcam/RatingReviewSms';
import AppoinmentPublic from '../pages/LeadCentral/Appointment/PublicPageAppointment/AppointmentPublic';
import AppointmentPublicURl from '../pages/LeadCentral/Appointment/PublicPageAppointment/AppointmentURL';
import InvoicePublic from '../pages/Payment&Invoice/PublicInvoice';
import CancelAppointment from '../pages/LeadCentral/Appointment/PublicPageAppointment/CancleAppointment';
import PublicContectPage from '../pages/Contact_Marketing/PublicContectPage';
import VerifyPublicPage from '../pages/login/VerifyPublicPage';
import ContectUrl from '../pages/Contact_Marketing/ContentUrl';
import ContentPublicPageURL from '../pages/Contact_Marketing/ContentPublicPageURL';
import PublicContectDomain from '../pages/Contact_Marketing/ContentPublicDomain';
import AffiliatePublicPage from '../pages/Setting/AfilliateLink/AffiliatePublicPage';
import UnSubscribe from '../pages/UnSubscribe/RequestUnSubscribe';
import PublicInquiryForm from '../pages/LeadCentral/InquiryForms/PublicInquiryForm';
import PublicInquiryResponse from '../pages/LeadCentral/InquiryForms/PublicInquiryResponse';
import ContentWidget from '../pages/Contact_Marketing/ContentWidget';
import EventPublicURL from '../pages/LeadCentral/Events/component/EventPublicURL';
import Product from '../pages/ProductAndService/Component/Product';
import PublicPreview from '../pages/NewsLetter/PublicPreview';
import PublicEventWidget from '../pages/LeadCentral/Events/PublicEventWidget';
import ProductDetails from '../pages/ProductAndService/Component/ProductDetails';
import Appointmentshort from '../pages/LeadCentral/Appointment/PublicPageAppointment/Appointmentshort';
import DocumentPublicPage from '../pages/Documents/DocumentPublicPage';
import AppointmentList from '../pages/LeadCentral/Appointment/PublicPageAppointmentList/AppointmentList';
import TicketRaisePublic from '../pages/TicketManagement/TicketRaisePublic';
import TicketHistory from '../pages/TicketManagement/TicketHistory';

const stripePromise = loadStripe(config.paymentKey);
export default function App() {
  const {
    isAuthenticated, userInfo, lastLogin,
  } = useUserState();
  const [loading, setLoading] = React.useState(false);
  const [baseRoute, setBaseRoute] = React.useState('/login');
  const getStartedUser = [Roles?.MasterAdmin, Roles?.AgencyManager, Roles?.AgencyAdmin];
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!localStorage.getItem('token')) {
        setBaseRoute('/login');
      }
      if (!handleUndefined(lastLogin)
        && (userInfo?.roleid === Roles.AgencyManager || userInfo?.roleid === Roles.AgencyAdmin)) {
        setBaseRoute('/app/setting-my-profile');
      } else if (localStorage.getItem('passlength')) {
        const decryptedPass = decrypt(bixpandDescription, localStorage.getItem('passlength'));
        if (decryptedPass.length < 12) {
          setBaseRoute(`/reset-password/${userInfo?.userid}`);
        }
      } else if (localStorage.getItem('userid')) {
        if (userInfo?.isenabletwofactorauth === true) {
          setBaseRoute('/verify');
        } else if (userInfo?.roleid === Roles?.BusinessManager) {
          setBaseRoute('/app/home/dashboard');
        } else if (getStartedUser.includes(userInfo?.roleid)) {
          setBaseRoute('/app/home/agency');
        } else {
          setLoading(true);
          if (localStorage.getItem('userid')) {
            postDataRequest(config.baseURLApi, {
              query: User,
              variables: {
                userid: JSON.parse(localStorage.getItem('userid')),
              },
            }).then(async (response) => {
              await postDataRequest(config.baseURLApi, {
                query: `query gettingstarted($userid:ID!){gettingstarted(userid:$userid)
              {business,brand,address}}
            `,
                variables: {
                  userid: JSON.parse(localStorage.getItem('userid')),
                },
              })
                .then(async (res) => {
                  if (res?.data?.gettingstarted?.business === true) {
                    await setBaseRoute('/app/home/dashboard');
                  } else if (response?.data?.user?.isemailverified === true || localStorage.getItem('isemail') === true) {
                    await setBaseRoute('/app/home/dashboard');
                  } else if (response?.data?.user?.isemailverified === false
                    || userInfo?.isemailverified === false
                  ) {
                    await setBaseRoute('/confrim_email');
                  }
                  setLoading(false);
                })
                .catch(() => setLoading(false));
            });
          }
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('userid'), localStorage.getItem('token'), lastLogin, localStorage.getItem('passlength')]);
  return (
    <>
      {loading === false
        ? (
          <BrowserRouter responsive="standard">
            <Switch responsive="standard">
              <Route
                exact
                path="/"
                render={() => <Redirect to={baseRoute} />}
              />
              <PrivateRoute responsive="standard" path="/app">
                <Elements responsive="standard" stripe={stripePromise}>
                  <Layout responsive="standard" />
                </Elements>
              </PrivateRoute>
              <Route path="/get-started">
                <EntitiesProvider>
                  <CampaginProvider>
                    <NewGettingStarted />
                  </CampaginProvider>
                </EntitiesProvider>
              </Route>
              <PublicPageRoute path="/reviews/:businessName/:locationName/:uid" />
              <InquiryPublicPageRoute path="/if/:locationid/:businessid/:formid" />
              <InquiryPublicPageRoute path="/ifo/:formid" />
              <InquiryResponseRoute path="/if/view/:formid" />
              <DocumentPublicPageRoute path="/cds/:documentid" />
              <InquiryPublicPageRoute path="/inquiryform/:locationid/:businessid/:formid" />
              <UnSubscribePageRoute path="/unsub" />
              <PublicRoute path="/review/:reviewid">
                <EntitiesProvider>
                  <ReviewDisplay />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/login" component={Login} />
              <PublicRoute path="/register" component={Login} />
              <PublicRoute path="/positive-comment">
                <EntitiesProvider>
                  <PositiveComment />
                </EntitiesProvider>
              </PublicRoute>
              <PrivateRoute path="/verify">
                <EntitiesProvider>
                  <TwofactorAuth userInfo={userInfo} user={JSON.parse(localStorage.getItem('userid'))} />
                </EntitiesProvider>
              </PrivateRoute>
              <PublicRoute path="/confrim_email">
                <EntitiesProvider>
                  <VerifyAccount />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/rating-positive-comment">
                <EntitiesProvider>
                  <RatingPositiveComment />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/thank-you-p">
                <PublicThankYou />
              </PublicRoute>
              <PublicRoute path="/rating-thank-you-p">
                <PublicThankYou />
              </PublicRoute>
              <PublicRoute path="/rating-thank-you-n">
                <PublicThankYou />
              </PublicRoute>
              <PublicRoute path="/negative-comment">
                <NegativeComment />
              </PublicRoute>
              <PublicRoute path="/rating-negative-comment">
                <RatingNegativeComment />
              </PublicRoute>
              <PublicRoute path="/thank-you-n">
                <PublicThankYou />
              </PublicRoute>
              <QrcodeRoute path="/kiosk-mode" component={Kioskmode} />
              <PublicRoute
                path="/reset-pass"
                component={EmailForForget}
              />
              <PublicRoute path="/touchless-public/:bid/:lid/:uid">
                <EntitiesProvider>
                  <TouchLessPublic />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/u/:shortUrl">
                <EntitiesProvider>
                  <Url />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/u/:calUrl">
                <EntitiesProvider>
                  <ThirdPartyUrl />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/text/:campaignCode">
                <EntitiesProvider>
                  <TextUrl />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/content/:id/:bid">
                <EntitiesProvider>
                  <PublicContectPage />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/confirm-user-email/:code">
                <EntitiesProvider>
                  <VerifyPublicPage />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/contents/:content">
                <EntitiesProvider>
                  <ContectUrl />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/template/:lid/:content">
                <EntitiesProvider>
                  <ContentPublicPageURL />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/cm/:content">
                <EntitiesProvider>
                  <PublicContectDomain />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/content-marketing/:content">
                <EntitiesProvider>
                  <PublicContectDomain />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/ac/:bid/:content">
                <EntitiesProvider>
                  <AffiliatePublicPage />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/c/:lid">
                <EntitiesProvider>
                  <ContentWidget />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/affiliatecontent/:bid/:content">
                <EntitiesProvider>
                  <AffiliatePublicPage />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/invoice/:paymentcode">
                <EntitiesProvider>
                  <PaymentUrl />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/appointment-public/:user/:event">
                <AppointmentPublicURl />
              </PublicRoute>
              <PublicRoute path="/nl/:lid">
                <PublicNewsletterWidget />
              </PublicRoute>
              <PublicRoute path="/newsletter/:lid">
                <PublicNewsletterWidget />
              </PublicRoute>
              <PublicRoute path="/wp/:id/:lid">
                <PublicPreview />
              </PublicRoute>
              <PublicRoute path="/webpage/:id/:lid">
                <PublicPreview />
              </PublicRoute>
              <PublicRoute path="/ew/:lid">
                <PublicEventWidget />
              </PublicRoute>
              <PublicRoute path="/event-widget/:lid">
                <PublicEventWidget />
              </PublicRoute>
              <PublicRoute path="/ep/:id/:locationid/:businessid">
                <EventPublicURL />
              </PublicRoute>
              <PublicRoute path="/event-public/:id/:locationid/:businessid">
                <EventPublicURL />
              </PublicRoute>
              <PublicRoute path="/p/:lid">
                <Product />
              </PublicRoute>
              <PublicRoute path="/product/:lid">
                <Product />
              </PublicRoute>
              <PublicRoute path="/pd/:pid/:lid/:uid">
                <ProductDetails />
              </PublicRoute>
              <PublicRoute path="/product-details/:pid/:lid/:uid">
                <ProductDetails />
              </PublicRoute>
              <PublicRoute path="/invoice-public/:iid/:bid/:lid">
                <InvoicePublic />
              </PublicRoute>
              <PublicRoute path="/review-sms">
                <EntitiesProvider>
                  <ReviewSms />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/rating-review-sms">
                <EntitiesProvider>
                  <RatingReviewSms />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/appointment/:user/:event/:eventId/:lid/:uid/:bid">
                <EntitiesProvider>
                  <AppoinmentPublic />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/appointment/:username/:shorturl">
                <EntitiesProvider>
                  <Appointmentshort />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/al/:shorturl">
                <EntitiesProvider>
                  <AppointmentList />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/cancelappointment/:bookid/:eventId/:bid/:uid">
                <EntitiesProvider>
                  <CancelAppointment />
                </EntitiesProvider>
              </PublicRoute>
              <PublicRoute path="/reset-password/:userid">
                <ResetPassword />
              </PublicRoute>
              <PublicRoute path="/ticket-raise">
                <TicketRaisePublic />
              </PublicRoute>
              <PublicRoute path="/tico">
                <TicketHistory />
              </PublicRoute>
              <Route component={Error} />
            </Switch>
          </BrowserRouter>
        ) : <LoadingPage />}
    </>
  );
  // #######################################################################
  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        responsive="standard"
        {...rest}
        render={(props) => (isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect to="/login" />
        ))}
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => (isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  }

  function InquiryPublicPageRoute({ ...rest }) {
    return (
      <>
        <Route
          {...rest}
          render={() => (isAuthenticated ? (
            <>
              <PrivateRoute
                path="/if/:locationid/:businessid/:formid"
                component={PublicInquiryForm}
              />
              <PrivateRoute
                path="/ifo/:formid"
                component={PublicInquiryForm}
              />
              <PrivateRoute
                path="/inquiryform/:locationid/:businessid/:formid"
                component={PublicInquiryForm}
              />
            </>
          ) : (
            <>
              <PublicRoute
                path="/if/:locationid/:businessid/:formid"
                component={PublicInquiryForm}
              />
              <PublicRoute
                path="/ifo/:formid"
                component={PublicInquiryForm}
              />
              <PublicRoute
                path="/inquiryform/:locationid/:businessid/:formid"
                component={PublicInquiryForm}
              />
            </>
          ))}
        />
      </>
    );
  }

  function UnSubscribePageRoute({ ...rest }) {
    return (
      <>
        <Route
          {...rest}
          render={() => (isAuthenticated ? (
            <>
              <PrivateRoute
                path="/unsub"
                component={UnSubscribe}
              />
            </>
          ) : (
            <>
              <PublicRoute
                path="/unsub"
                component={UnSubscribe}
              />
            </>
          ))}
        />
      </>
    );
  }

  function DocumentPublicPageRoute({ ...rest }) {
    return (
      <>
        <Route
          {...rest}
          render={() => (isAuthenticated ? (
            <>
              <PrivateRoute
                path="/cds/:documentid"
                component={DocumentPublicPage}
              />
            </>
          ) : (
            <>
              <PublicRoute
                path="/cds/:documentid"
                component={DocumentPublicPage}
              />
            </>
          ))}
        />
      </>
    );
  }

  function InquiryResponseRoute({ ...rest }) {
    return (
      <>
        <Route
          {...rest}
          render={() => (isAuthenticated ? (
            <>
              <PrivateRoute
                path="/if/view/:formid"
                component={PublicInquiryResponse}
              />
            </>
          ) : (
            <>
              <PrivateRoute
                path="/if/view/:formid"
                component={PublicInquiryResponse}
              />
            </>
          ))}
        />
      </>
    );
  }

  function PublicPageRoute({ ...rest }) {
    return (
      <>
        <Route
          {...rest}
          render={() => (isAuthenticated ? (
            <PrivateRoute
              path="/reviews/:businessName/:locationName/:uid"
              component={PublicPage}
            />
          ) : (
            <PublicRoute
              path="/reviews/:businessName/:locationName/:uid"
              component={PublicPage}
            />
          ))}
        />
      </>
    );
  }
  function QrcodeRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => (isAuthenticated ? (
          <Redirect to="/kiosk-mode" />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  }
}
