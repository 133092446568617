/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  FormHelperText,
  Avatar,
  Box,
  Tabs,
  FormControl, InputLabel, IconButton, InputAdornment, OutlinedInput,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { withRouter, Link } from 'react-router-dom';
import '../phoneInput.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// eslint-disable-next-line import/no-named-as-default
import useStyles from './styles';
import { BixFavIcon, EntityType, GlobeIcon, bixpandDescription, validationErrorMsg } from '../../common/const';
import useStyles1 from './styles-new';
import Email from '../email/email';
import 'react-toastify/dist/ReactToastify.css';
import {
  useUserDispatch, loginUser, signUpUser, receiveToken,
} from '../../context/UserContext';
import { Button, Typography } from '../../components/Wrappers/Wrappers';
import Phonenumber from '../Phonenumber/PhoneNoWithCountryCodeFlag';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import {
  required, maxString, mustBeString, emailValidate, composeValidators, phoneValidate,
  passwordValidators,
} from '../../common/FormValidation';
import {
  encrypt, handleUndefined, postDataRequest, postDataRequestPublic,
  postRequest,
} from '../../common/commonfunctions';
import { DataBySubDomain } from '../../Api Mutation/SubDomainAPI';
import config from '../../config';
import { BrandSettingByLocation, EntityPolicy } from '../../Api Mutation/EntityApi';
import BusinessFavicon from '../../common/BusinessFavicon';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { VerifyAuthToken } from '../../Api Mutation/Auth';

function Login(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const currentUrl = window?.location?.href;
  const pathValidation = (currentUrl.includes('app.bixpand.com') || currentUrl.includes('dev.bixpand.com'));
  const classes = useStyles();
  const { history } = props;
  const classes1 = useStyles1();
  const userDispatch = useUserDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [titleLoader, setTitleLoader] = useState(false);
  const [domainData, setDomainData] = useState();
  const [policyUrl, setPolicyUrl] = useState();
  const [favicon, setFavicon] = useState('');
  const [whiteLabelStatus, setWhiteLabelStatus] = useState('');
  const [locationBrandImage, setLocationBrandImage] = useState('');
  const [brandImage, setBrandImage] = useState('');
  const [title, setTitle] = useState('');

  const fromEntity = false;
  const setErrorSignup = () => { };
  const [activetabid, setActiveTabId] = useState(
    history?.location?.pathname === '/login' ? 0 : 1,
  );
  const [passwordSignup, setPasswordSignup] = useState({
    password: '',
    confirmpass: '',
  });
  const [passwordErrorSignup, setPasswordErrorSignup] = useState();
  const [passwordLogin, setPasswordLogin] = useState();
  const [confirmPasswordErrorSignup, setConfirmPasswordErrorSignup] = useState();
  const { search } = history?.location;
  const params = new URLSearchParams(search);
  const referId = params.get('bid');
  const patnarId = params.get('pid');
  const email = params.get('email');
  const [passwordError, setPasswordError] = useState('');
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const [isCode, setIsCode] = useState(true);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const bixUserStatus = (status) => {
    if (status === true) {
      setActiveTabId(0);
    }
  };

  const EntityData = (entityId) => {
    postDataRequestPublic(config.baseURLApi, {
      query: EntityPolicy,
      variables: {
        entityid: entityId,
      },
    }).then((resN) => {
      if (resN?.data?.entity) {
        if (resN?.data?.entity?.whitelabeltype === 'full') {
          setWhiteLabelStatus(resN?.data?.entity?.whitelabeltype);
          setPolicyUrl(resN?.data?.entity?.policy);
          setFavicon(resN?.data?.entity?.favicon);
          setTitle(resN?.data?.entity?.businessname);
          setLocationBrandImage(resN?.data?.entity?.logourl);
          if (handleUndefined(resN?.data?.entity?.parententityid)) {
            postDataRequestPublic(config.baseURLApi, {
              query: BrandSettingByLocation,
              variables: {
                locationid: handleUndefined(resN?.data?.entity?.parententityid),
              },
            }).then((r) => {
              setBrandImage(r?.data?.brandSettingByLocation?.logourl);
            });
          }
        }
        setTitleLoader(false);
      }
    });
  };

  const GetDataByDomain = () => {
    const domainUrl = new URL(window?.location?.href);
    const parsedDomainUrl = domainUrl.hostname;
    const domainWithoutWWW = parsedDomainUrl.replace('www.', '');
    const subdomainValue = domainWithoutWWW.split('.')[0];
    if (subdomainValue) {
      setTitleLoader(true);
      postDataRequest(config.baseURLApi, {
        query: DataBySubDomain,
        variables: { subdomain: subdomainValue },
      }).then((res) => {
        if (res) {
          if (res?.data.entitybysubdomain) {
            setDomainData(res?.data?.entitybysubdomain);
            if (res?.data?.entitybysubdomain?.parententityid) {
              if (res?.data?.entitybysubdomain !== null) {
                localStorage.setItem('business_Data', JSON.stringify(res?.data?.entitybysubdomain))
              }
              EntityData(res?.data?.entitybysubdomain?.parententityid);
            } else {
              setTitleLoader(false);
            }
          } else {
            setTitleLoader(false);
          }
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (window.location.pathname.includes('/login')) {
        setActiveTabId(0);
        if (!window?.location?.href?.includes('app.bixpand') && !window?.location?.href?.includes('dev.bixpand')) {
          GetDataByDomain();
        }
      } else {
        setActiveTabId(1);
      }
      const params = new URLSearchParams(props?.location.search);
      const token = params.get('token');
      const code = params.get('code');
      setTitleLoader(true);
      if (code) {
        postRequest(config.baseURLApi, {
          query: VerifyAuthToken,
          variables: { code: code.replace(/ /g, '+') },
        }).then((res) => {
          if (res.data?.verifyToken?.token) {
            receiveToken(res.data?.verifyToken?.token, userDispatch);
            showNotification('Login Successful', 'success');
          } else {
            showNotification(`Authentication process failed due to ${res.errors?.[0]?.message}`, 'error');
          }
          setTitleLoader(false);
        });
      } else {
        setTitleLoader(false);
        setIsCode(false);
      }
      if (token) {
        receiveToken(token, userDispatch);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      {titleLoader ? <LoadingPage isAuth={true} /> : ''}
      <Grid container className={classes1.container}>
        {activetabid === 0 && (
          <Grid
            container
            item
            xs={5}
            className={classes1.leftContent}
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item />
            <Grid item>
              <Avatar
                alt="bixpandAvatar"
                src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Avatar.png"
                className={classes1.avatarIcon}
              />
            </Grid>
            <Grid item>
              <Typography className={classes1.leftTitle}>
                Welcome Back
              </Typography>
              <Typography className={classes1.leftSubTitle}>
                Elevate your business with the most latest set of tools
              </Typography>
              <center>
                <Grid item>
                  <ArrowForwardIosIcon
                    className={classes1.arrowForward}
                  />
                </Grid>
              </center>
            </Grid>
            <Grid item />
          </Grid>
        )}
        {activetabid === 1 && (
          <Grid
            container
            item
            xs={5}
            className={classes1.leftContent}
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item>
              <Avatar
                alt="bixpandAvatar"
                src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Avatar.png"
                className={classes1.avatarIcon}
              />
            </Grid>
            <Grid item>
              <Typography className={classes1.leftTitle}>
                Let’s get you set up
              </Typography>
              <Typography className={classes1.leftSubTitle}>
                No Card Required.
              </Typography>
              <center>
                <Grid item>
                  <ArrowForwardIosIcon
                    className={classes1.arrowForward}
                  />
                </Grid>
              </center>
            </Grid>
            <Grid item>
              <Typography className={classes1.leftSubTitle}>
                Already a member?
                <Link to="/login"
                  onClick={() => {
                    history?.push('/login');
                    setActiveTabId(0);
                  }}
                  activetabid={0} className={classes1.leftSubTitle}>
                  <span> Login</span>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Tabs
          value={activetabid}
          onChange={(e, id) => setActiveTabId(id)}
          indicatorColor="primary"
          textColor="primary"
        />
        {activetabid === 0 && !isCode && (
          <Grid container item xs={12} md={7} className={classes1.formContainerLogin}>
            <Grid
              container
              xs={12}
              item
              justifyContent="space-evenly"
              alignItems="center"
              className={classes1.formHeader}
            >
              <Box className={classes.headerInfo}>
                <Typography className={classes1.formTitle}>
                  Login
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.gridPadding}
            >
              <Form
                onSubmit={(values) => {
                  passwordLogin
                    ? loginUser(
                      userDispatch,
                      {
                        email: values.email,
                        Password: passwordLogin,
                      },
                      props?.history,
                      setIsLoading,
                    )
                    : !passwordLogin
                      ? setPasswordError('enter your password')
                      : setPasswordError('');
                  if (passwordLogin.length < 12) {
                    const crypted = encrypt(bixpandDescription, passwordLogin);
                    localStorage.setItem('passlength', crypted);
                  } else {
                    localStorage.removeItem('passlength');
                  }
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item md={10} xs={12}>
                        <Email validate={composeValidators(required, emailValidate)} label="Business Email" />
                      </Grid>
                      <Grid item md={10} xs={12}>
                        <FormControl size="small" fullWidth variant="outlined" className={classes.brMargin}>
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            error={!!passwordError}
                            type={values.showPassword ? 'text' : 'password'}
                            onBlur={(pass) => {
                              setPasswordLogin(pass.target.value);
                              if (!pass.target.value) {
                                setPasswordError(
                                  'Enter your password',
                                );
                                setIsDisabled(true);
                              }
                            }}
                            onChange={(pass) => {
                              setPasswordLogin(pass.target.value);
                              if (!pass.target.value) {
                                setPasswordError(
                                  'Enter your password',
                                );
                                setIsDisabled(true);
                              } else {
                                setPasswordError('');
                                setIsDisabled(false);
                              }
                            }}
                            endAdornment={(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )}
                            labelWidth={70}
                          />
                        </FormControl>
                        <FormHelperText error={!!passwordError}>
                          {passwordError}
                        </FormHelperText>
                      </Grid>
                      <Grid item md={10} xs={12} className={classes.brMargin}>
                        <Link
                          to="/reset-pass"
                          className={classes1.loginLinkTxt}
                        >
                          <Typography variant="body1">
                            Forgot Password
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item md={10} xs={12}>
                        <Box className={classes.fieldBox}>
                          <div
                            className={
                              classes.creatingButtonContainer
                            }
                          >
                            {isLoading ? (
                              <CircularProgress
                                size={26}
                                className={classes.loginLoader}
                              />
                            ) : (
                              <Button
                                className={classes1.loginBtn}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isDisabled}
                              >
                                Login
                              </Button>
                            )}
                          </div>
                          <p className={classes.responsiveBr} />
                        </Box>
                      </Grid>
                      <h4 className={classes.copyRightText}>
                        &copy;2024
                        {' '}
                        {domainData?.businessname ? domainData?.businessname : 'Bixpand.com.'}
                        {' '}
                        All Rights Reserved.
                        {' '}
                        <a href={handleUndefined(policyUrl) || config?.bixPolicy} target="_blank" rel="noreferrer">Privacy Policy</a>
                      </h4>
                      <p className={classes.responsiveBr} />
                      <p className={classes.responsiveBr} />
                      <p className={classes.responsiveBr} />
                      <Box className={classes.optionText}>
                        <Typography
                          className={classes1.leftSubTitle}
                        >
                          Don’t have an account?
                          <Link
                            to="/register"
                            className={
                              classes1.leftSubTitle
                            }
                          >
                            <span> Sign Up</span>
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </form>
                )}
              />
            </Grid>
          </Grid>
        )}
        {activetabid === 1 && (
          <Grid container item xs={12} md={7} className={classes1.formContainer}>
            <Grid
              container
              xs={12}
              item
              alignItems="center"
              className={classes1.formHeader}
            >
              <Box className={classes.signHeaderInfo}>
                <Typography className={classes1.formTitle}>
                  Create Account
                </Typography>
                <Avatar
                  alt="bixpandAvatar"
                  src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png"
                  className={classes1.formBixLogo}
                />
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
            >
              <Form
                onSubmit={(values) => {
                  if (!passwordSignup.password) {
                    setPasswordErrorSignup('Required');
                  }
                  if (!passwordSignup.confirmpass) {
                    setConfirmPasswordErrorSignup('Required');
                  }
                  if (!values.phonenumber) {
                    showNotification('Phone number is required', 'error');
                  } else if (!passwordErrorSignup && !confirmPasswordErrorSignup) {
                    signUpUser(
                      userDispatch,
                      {
                        BusinessName:
                          values?.BusinessName,
                        FirstName: values?.FirstName,
                        LastName: values?.LastName,
                        phonenumber: values.phonenumber,
                        UserType: EntityType?.Business,
                        email: email || values?.email,
                        Password:
                          passwordSignup.password,
                        fromEntity,
                        bixUserStatus,
                        referid: referId,
                        partneruserid: patnarId,
                      },
                      props?.history,
                      setIsLoading,
                      setErrorSignup,
                    );
                  }
                }}
                render={({
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item md={6} xs={12}>
                        <Field
                          className={
                            classes.field
                          }
                          variant="outlined"
                          required
                          size="small"
                          validate={composeValidators(required, mustBeString, maxString(20))}
                          name="FirstName"
                          component={TextField}
                          type="text"
                          label="First Name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          className={
                            classes.field
                          }
                          name="LastName"
                          validate={composeValidators(mustBeString, maxString(20))}
                          variant="outlined"
                          size="small"
                          component={TextField}
                          type="text"
                          label="Last Name"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Field
                          className={
                            classes.field
                          }
                          required
                          variant="outlined"
                          name="BusinessName"
                          size="small"
                          validate={composeValidators(required, maxString(100))}
                          component={TextField}
                          type="text"
                          label="Business Name"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Field
                          name="email"
                          className={
                            classes.field
                          }
                          validate={composeValidators(required, emailValidate)}
                          required
                          size="small"
                          defaultValue={email}
                          variant="outlined"
                          disabled={email}
                          component={TextField}
                          type="email"
                          label="Business Email"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Box className={classes1.phonenumber}>
                          <Field required name="phonenumber" validate={phoneValidate} component={Phonenumber} />
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl size="small" fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            error={!!passwordErrorSignup}
                            type={values.showPassword ? 'text' : 'password'}
                            onBlur={(pass) => {
                              if (
                                !pass.target
                                  .value
                              ) {
                                setPasswordErrorSignup('Required');
                              }
                            }}
                            onChange={(pass) => {
                              setPasswordSignup({
                                ...passwordSignup,
                                password:
                                  pass.target
                                    .value,
                              });
                              if (
                                !pass.target
                                  .value
                              ) {
                                setPasswordErrorSignup('Enter your password');
                              } else if (passwordValidators(pass.target.value)) {
                                setPasswordErrorSignup(validationErrorMsg);
                              } else {
                                setPasswordErrorSignup('');
                              }
                              if (
                                !passwordSignup.confirmpass
                              ) {
                                setConfirmPasswordErrorSignup('Enter Confirm password');
                              } else if (
                                passwordSignup.confirmpass
                                && passwordSignup.confirmpass
                                !== pass.target
                                  .value
                              ) {
                                setConfirmPasswordErrorSignup('Password and Confirm Password should be same');
                              } else {
                                setConfirmPasswordErrorSignup('');
                              }
                            }}
                            endAdornment={(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {values.showPassword
                                    ? <Visibility />
                                    : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )}
                            labelWidth={70}
                          />
                        </FormControl>
                        <FormHelperText
                          error={
                            !!passwordErrorSignup
                          }
                        >
                          {
                            passwordErrorSignup
                          }
                        </FormHelperText>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl size="small" fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">Confirm password *</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            error={!!confirmPasswordErrorSignup}
                            type={values.showPassword ? 'text' : 'password'}
                            onBlur={(pass) => {
                              if (
                                !pass.target
                                  .value
                              ) {
                                setConfirmPasswordErrorSignup('Required');
                              }
                            }}
                            onChange={(pass) => {
                              setPasswordSignup({
                                ...passwordSignup,
                                confirmpass:
                                  pass.target
                                    .value,
                              });
                              if (
                                !passwordSignup.password
                              ) {
                                setPasswordErrorSignup('Enter your password');
                              } else if (passwordValidators(pass.target.value)) {
                                setPasswordErrorSignup(validationErrorMsg);
                              } else {
                                setPasswordErrorSignup('');
                              }
                              if (
                                !pass.target
                                  .value
                              ) {
                                setConfirmPasswordErrorSignup('Enter Confirm password');
                              } else if (
                                pass.target
                                  .value
                                !== passwordSignup.password
                              ) {
                                setConfirmPasswordErrorSignup('Password and Confirm Password should be same');
                              } else {
                                setConfirmPasswordErrorSignup('');
                              }
                            }}
                            endAdornment={(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {values.showPassword
                                    ? <Visibility />
                                    : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )}
                            labelWidth={70}
                          />
                        </FormControl>
                        <FormHelperText
                          error={
                            !!confirmPasswordErrorSignup
                          }
                        >
                          {
                            confirmPasswordErrorSignup
                          }
                        </FormHelperText>
                      </Grid>
                      <Grid item md={12} xs={12} className={classes.msgGrid}>
                        <h4 className={classes.loginHead}>
                          By continuing, you&apos;re agreeing to the bixpand.com customer
                          {' '}
                          <a href={config?.bixCondition} target="_blank" rel="noreferrer">Terms of Service</a>
                          {' '}
                          and
                          {' '}
                          <a href={config?.bixPolicy} target="_blank" rel="noreferrer">Privacy Policy</a>
                        </h4>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Box className={classes.signBtnBox}>
                          <div
                            className={
                              classes.creatingButtonContainer
                            }
                          >
                            {isLoading ? (
                              <CircularProgress
                                size={26}
                              />
                            ) : (
                              <Button
                                className={classes1.signUpBtn}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={(!passwordSignup?.password
                                  || !passwordSignup?.confirmpass)}
                              >
                                Sign Up
                              </Button>
                            )}
                          </div>
                        </Box>
                        <Box display={{ xs: 'block', sm: 'none' }} className={classes.loginOption}>
                          <Typography
                            className={classes1.leftSubTitle}
                          >
                            Already a member?
                            <Link
                              to="/login"
                              onClick={() => {
                                history?.push('/login');
                                setActiveTabId(0);
                              }}
                              className={
                                classes1.leftSubTitle
                              }
                            >
                              <span> Login</span>
                            </Link>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <BusinessFavicon
        logo={titleLoader
          ? "https://storage.cloud.google.com/bixpand-staging/WebsiteImages/globe.png"
          : pathValidation
            ? BixFavIcon
            : (whiteLabelStatus === 'full' ? (favicon || domainData?.favicon || locationBrandImage || brandImage || GlobeIcon) : GlobeIcon)}
        name={titleLoader ? "Loading..." : (pathValidation ? 'Bixpand' : title)}
      />
    </div>
  );
}

export default withRouter(Login);
