import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  width: {
    width: '40%',
    marginLeft: '30%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  PhoneInput: {
    width: '40%',
    marginLeft: '6%',

    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  margin: {
    marginBottom: 24,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  field1: {
    marginLeft: '6%',
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },

  dropDown: {
    fontSize: 17,
    color: '#6E6E6E',
    padding: '8px 0 6px',
    // marginLeft: theme.spacing(2),
    width: '40%',
    marginTop: '11px',
    fontStyle: 'transparent',
    borderLeft: 'hidden',
    borderRight: 'hidden',
    borderTop: 'hidden',
    marginLeft: '6%',

    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  buttonSave: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'right',
    },
  },
  buttonBack: {
    float: 'right',
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      float: 'right',
      fontSize: 8,
      width: 'auto',
    },
  },
  componentHeading: {
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  backMenuLink: {
    fontSize: 17,
    float: 'right',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'right',
      textDecoration: 'none',
    },
  },

  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignStandaloneElement: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableWidget: {
    overflowX: 'auto',
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: '100%',
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: `${theme.palette.text.hint}80 !important`,
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: '14px !important',
    marginLeft: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  large2: {
    width: theme.spacing(22),
    height: 'auto',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(22),
      height: 'auto',
      aspectRatio: 'auto',
    },
  },
  templateTextbody: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  templateText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 650,
    [theme.breakpoints.down('sm')]: {
      width: 380,
    },
  },
  logo: {
    marginLeft: 20,
    margin: 15,
    height: 70,
  },
  darkPink: {
    backgroundColor: '#F04D5D',
    maxWidth: '38px',
    maxHeight: '38px',
    minWidth: '38px',
    minHeight: '38px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      maxWidth: '25px',
      maxHeight: '25px',
      minWidth: '25px',
      minHeight: '25px',
    },
  },

  darkYellow: {
    backgroundColor: '#E59E12',
    maxWidth: '38px',
    maxHeight: '38px',
    minWidth: '38px',
    minHeight: '38px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      maxWidth: '25px',
      maxHeight: '25px',
      minWidth: '25px',
      minHeight: '25px',
    },
  },
  darkGreen: {
    maxWidth: '38px',
    maxHeight: '38px',
    minWidth: '38px',
    minHeight: '38px',
    backgroundColor: '#5FBF91',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      maxWidth: '25px',
      maxHeight: '25px',
      minWidth: '25px',
      minHeight: '25px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  fixIconRight: {
    '& .MuiSelect-icon': {
      right: 4,
    },
  },
  checkboxColor: {
    color: 'rgb(185, 185, 185)',
  },
}));
