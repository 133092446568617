/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography, Box, Link, MobileStepper, Button,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import { NewsletterEmailByLocationId, NewsletterSettingsByLocationId, AddActivityNewsletterEmail } from '../../Api Mutation/NewsLetterApi';
import config from '../../config';
import { NewsLetterStatus, dummyimage } from '../../common/const';
import PoweredBy from '../../common/PoweredBy';
import { Entity } from '../../Api Mutation/EntityApi';
import useStyle from './PublicNewletterWidgetStyle';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { postDataRequestPublic } from '../../common/commonfunctions';
import './widgetPreview.css';

export default function PublicNewsletterWidget() {
  const classes = useStyle();
  const [titleData, setTitleData] = useState();
  const [newsLetterData, setNewsLetterData] = useState([]);
  const [entityData, setEntityData] = useState();
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [whiteLabelStatus, setWhiteLabelStatus] = useState();
  const [subDomain, setSubDomain] = useState();
  const theme = useTheme();
  const [count, setCount] = useState();
  const maxSteps = Math.ceil(count?.count / 5);
  const {
    lid,
  } = useParams([]);

  const getNewsLetterByData = (props) => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: NewsletterEmailByLocationId,
        variables: {
          locationid: lid,
          pageNumber: props?.pageNumber + 1,
          pageSize: 5,
          ispublish: true,
        },
      }).then((res) => {
        const data = res?.data?.newsletterEmailByLocationId;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data) {
          setNewsLetterData(data?.rows);
          setCount(data);
        }
      });
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fetchData = async (prop) => {
    if (prop?.id) {
      const CREATE_ACTIVITYNEWSLETTER = AddActivityNewsletterEmail;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITYNEWSLETTER,
        variables: {
          newsletteremailid: prop?.id,
          webpageclickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getEntityData = () => {
    const entityIdValue = JSON.parse(localStorage?.getItem('location_Id'));
    if (entityIdValue) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: entityIdValue,
        },
      }).then((response) => {
        if (response?.data?.entity?.whitelabeltype) {
          setWhiteLabelStatus(response?.data?.entity?.whitelabeltype);
          setSubDomain(response?.data?.entity?.whitelabeldomain);
        }
      });
    }
  };

  const getWidgetSetting = () => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: NewsletterSettingsByLocationId,
        variables: { locationid: lid },
      }).then((res) => {
        const data = res?.data?.newsletterSettingsByLocationId;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data) {
          setTitleData(data);
          setEnablePublicLogo(data?.isshowfooterlogo);
          getEntityData();
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getNewsLetterByData({
        pageNumber: activeStep,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [lid, activeStep]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (lid) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: lid,
          },
        }).then((result) => {
          const data = result?.data?.entity;
          if (data) {
            setEntityData(data);
          }
        });
      }
      getWidgetSetting();
    }
    return () => {
      isMounted = false;
    };
  }, [lid]);

  return (
    <>
      <Box className={classes.Widget}>
        <Box className={titleData?.contentposition === 'center' ? classes.WidgetLayout : classes.WidgetLayoutAlign}>
          <Box className="widgetPageTitle" color="primary" dangerouslySetInnerHTML={{ __html: titleData?.pagetitle }} />
          <Box className="widgetTitleText" color="primary" dangerouslySetInnerHTML={{ __html: titleData?.subtitle }} />
          <Box className="widgetTitleText" color="primary" dangerouslySetInnerHTML={{ __html: titleData?.subscriptiontitle }} />
        </Box>
        <Box className={classes.contentsText}>
          {newsLetterData && newsLetterData?.map((i) => (
            i?.status !== NewsLetterStatus?.Draft ? (
              <Link
                target="_blank"
                href={whiteLabelStatus === 'full' && subDomain && entityData?.subdomain
                  ? `https://${entityData?.subdomain}.${subDomain}/wp/${i?.newsletteremailid}/${lid}`
                  : entityData?.subdomain
                    ? `https://${entityData?.subdomain}${config.subDomainUrl}/wp/${i?.newsletteremailid}/${lid}`
                    : entityData?.subdomain === null
                      ? `${config.BixUrl}/wp/${i?.newsletteremailid}/${lid}`
                      : `${config.BixUrl}/wp/${i?.newsletteremailid}/${lid}`}
                onClick={() => {
                  fetchData({
                    id: i?.newsletteremailid,
                    click: {
                      timestamp: new Date(),
                      url: whiteLabelStatus === 'full' && subDomain && entityData?.subdomain
                        ? `https://${entityData?.subdomain}${subDomain}/wp/${i?.newsletteremailid}/${lid}`
                        : entityData?.subdomain
                          ? `https://${entityData?.subdomain}${config.subDomainUrl}/wp/${i?.newsletteremailid}/${lid}`
                          : entityData?.subdomain === null
                            ? `${config.BixUrl}/wp/${i?.newsletteremailid}`
                            : `${config.BixUrl}/wp/${i?.newsletteremailid}`,
                      name: i?.name,
                    },
                  });
                }}
              >
                <Box className={classes.contents}>
                  <img className={classes.img} src={i?.listpageimage ? i?.listpageimage : dummyimage} alt="demo" />
                  <Box className={classes.contentsText}>
                    <Typography variant="h6">
                      {i?.subject}
                    </Typography>
                    <Typography variant="body2">
                      {moment(i?.createdAt).format('ddd, MMM DD, YYYY h:mm A')}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            ) : ''
          ))}
        </Box>
      </Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={(
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        )}
        backButton={(
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        )}
      />
      {enablePublicLogo ? <PoweredBy id={lid} /> : ''}
    </>
  );
}
