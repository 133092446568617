import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import config from '../../../config';
import BusinessFavicon from '../../../common/BusinessFavicon';
import NagativeLanding from '../Feedbackpages/NagativeLanding';
import DirectNagativefeedbackpage from '../Feedbackpages/DirectNagativefeedbackpage';
import { postDataRequestPublic, handleUndefined } from '../../../common/commonfunctions';
import 'react-toastify/dist/ReactToastify.css';
import { ReviewStatusApi } from '../../../common/const';
import { Entity } from '../../../Api Mutation/EntityApi';

const NegativeComment = ({ history }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const feedbackValue = 'feedback-us';
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rating, setRating] = useState();
  const [title, setTitle] = useState('');
  const [reviewId, setReviewId] = useState('');
  const [reviewMsg, setReviewMsg] = useState('');
  const [userName, setUserName] = useState();
  const [entity, setEntity] = useState([]);
  const [brandInfo, setBrandInfo] = useState([]);
  const [locationBrandImage, setLocationBrandImage] = useState([]);
  const [favIcon, setFavIcon] = useState([]);
  const { search } = history.location;
  const params = new URLSearchParams(search);
  const campaignId = params.get('campid');
  const customerId = params.get('custid');
  const locationId = params.get('lid');
  const bid = params.get('bid');
  const r = params.get('r');
  const emailcode = params.get('ec');
  const [business, setBusiness] = useState([]);
  const createReview = async () => {
    setSubmitLoading(true);
    postDataRequestPublic(config.reviewApi, {
      query: `
      mutation{
        createReview(
          review: "${reviewMsg !== undefined ? reviewMsg.split('\n').join('\\n').split('"').join('\\"') : ''
}",
          rating: ${rating !== undefined ? rating : ''},
          title: "${title.split('\n').join('\\n').split('"').join('\\"') || ''}",
          providedby: "Bixpand",
          locationid: "${locationId}",
          customerid: "${customerId}",
          campaignid: ${handleUndefined(campaignId) ? JSON.stringify(campaignId) : null},
          emailcode:"${emailcode}",
          createdby: "${customerId}",
          sourceid: 1,
          status:${ReviewStatusApi.NegativeComment},
          reviewdatetime: "${new Date()}"
          ) {
            reviewid,
          }
        }`,
    })
      .then(() => {
        history.push(
          `/thank-you-n?lid=${locationId}&bid=${bid}&brand=${business?.brandname
                    || business?.businessname}`,
        );
        setReviewMsg('');
        setTitle('');
        setSubmitLoading(false);
      })
      .catch(() => {
        toast.error('Something went wrong. Your feedback can\'t be save');
        setSubmitLoading(false);
      });
  };

  const updateReview = async () => {
    setSubmitLoading(true);
    postDataRequestPublic(config.reviewApi, {
      query: `
      mutation{
        editReview(
          reviewid:"${reviewId}",
          review: "${reviewMsg.split('\n').join('\\n').split('"').join('\\"')}",
          rating: ${rating},
          title: "${title.split('\n').join('\\n').split('"').join('\\"') || ''}",
          customerid: "${customerId}",
          emailcode:"${emailcode}",
          campaignid: "${campaignId}",
          updatedby: "${customerId}",
          status:${ReviewStatusApi.NegativeComment},
          reviewdatetime: "${new Date()}"
          )
        }`,
    })
      .then(() => {
        history.push(
          `/thank-you-n?lid=${locationId}&bid=${bid}&brand=${business?.brandname
                    || business?.businessname}`,
        );
        setReviewMsg('');
        setTitle('');
        setSubmitLoading(false);
      })
      .catch(() => {
        toast.error('Something went wrong. Your feedback can\'t be save');
        setSubmitLoading(false);
      });
  };

  const getUserInfo = async () => {
    await postDataRequestPublic(config.baseURLApi, {
      query: `{contact(contactid:"${customerId}"){ firstname }}`,
    })
      .then((res) => {
        setUserName(res?.data?.contact?.firstname);
      })
      .catch(() => { });
  };

  const getBrandInfo = async () => {
    if (handleUndefined(bid)) {
      await postDataRequestPublic(config.baseURLApi, {
        query: `{
          brandSettingByLocation(locationid: "${handleUndefined(bid)}") {
            brandsettingid,
            logourl,
            color,
            timeformat,
            dateformat,
            locationid,
            createdby,
            updatedby,
        }}`,
      })
        .then((res) => {
          setBrandInfo(res?.data?.brandSettingByLocation);
        })
        .catch(() => { });
    }
  };
  const handleSubmit = () => {
    if (feedbackValue === 'review-us' && title && reviewMsg) {
      if (
        (!campaignId || campaignId === 'null')
                && (!customerId || customerId === 'null')
      ) {
        history.push('/thank-you-n?lid=&brand=&test=true');
      } else if (reviewId) {
        updateReview();
      } else {
        createReview();
      }
    }
    if (feedbackValue === 'feedback-us' && reviewMsg) {
      if (
        (!campaignId || campaignId === 'null')
                && (!customerId || customerId === 'null')
      ) {
        history.push('/thank-you-n?lid=&brand=&test=true');
      } else if (reviewId) {
        updateReview();
      } else {
        createReview();
      }
    } else {
      setError(true);
    }
  };

  const checkReviewExist = async () => {
    await postDataRequestPublic(config.reviewApi, {
      query: `{checkReviewExist(customerid:"${customerId}", locationid: "${locationId}", emailcode: "${emailcode}") {
          reviewid,
          review,
          title,
          emailcode,
        }}`,
    })
      .then((res) => {
        if (res?.data?.checkReviewExist) {
          setReviewMsg(res.data.checkReviewExist.review);
          setTitle(res.data.checkReviewExist.title);
          setReviewId(res.data.checkReviewExist.reviewid);
        }
      })
      .catch(() => { });
  };

  const fetchInfoOnMount = async () => {
    await checkReviewExist();
    await getUserInfo();
    await getBrandInfo();
    setLoading(false);
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (handleUndefined(locationId)) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,

          variables: {
            entityid: locationId,
          },
        })
          .then((res) => {
            if (res?.data?.entity) {
              setEntity(res.data.entity);
              setLocationBrandImage(res?.data?.entity?.logourl);
              setFavIcon(res?.data?.entity?.favicon);
            }
          });
      }
      if (handleUndefined(bid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,

          variables: {
            entityid: bid && bid,
          },
        })
          .then((res) => {
            if (res?.data?.entity) {
              setBusiness(res.data.entity);
            }
          });
      }
      if (bid && bid !== 'undefined') {
        fetchInfoOnMount();
        setRating(parseInt(r, 10));
      } else {
        setLoading(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [bid]);

  const handlePolicy = () => {
    window.open(entity.policy, '_blank');
  };
  const handleTerms = () => {
    window.open(entity.terms, '_blank');
  };

  return (
    <>
      <BusinessFavicon
        logo={favIcon || locationBrandImage || brandInfo?.logourl}
        name={entity.businessname}
      />
      <ToastContainer />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          height="100vh"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {(!campaignId || campaignId === 'null')
                                && (!customerId || customerId === 'null') && (
                                <Chip
                                  label="This is a test Feedback form. Your Feedback won’t be published on Bixpand.com."
                                  color="primary"
                                  style={{
                                    width: '100%',
                                    fontSize: '1rem',
                                    marginBottom: '1rem',
                                  }}
                                />
          )}

          <Container>
            <br />
            {feedbackValue === 'review-us' ? (
              <>
                <br />
                <NagativeLanding
                  activeRating={rating}
                  reviewMsg={reviewMsg}
                  handleRating={setRating}
                  handleReviewMsg={(e) => setReviewMsg(e.target.value)}
                  title={title}
                  error={error}
                  handletitle={(e) => setTitle(e.target.value)}
                  brandInfo={{
                    logo: brandInfo?.logourl,
                    brandname: business?.brandname,
                    businessname: business?.businessname,
                    locationname: entity.businessname,
                    displaylocationdoncampaign: entity.displaylocationdoncampaign,
                  }}
                />

                <Typography variant="h6">
                  <Box marginTop={2}>
                    Your name:
                    {' '}
                    {userName}
                  </Box>
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  marginTop={2}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={submitLoading}
                    startIcon={
                                                submitLoading ? (
                                                  <CircularProgress size={20} />
                                                ) : (
                                                  <CheckCircleOutline />
                                                )
                                            }
                  >
                    Share Review
                  </Button>

                  <Typography>
                    <Box
                      marginTop={1}
                      marginBottom={1}
                      style={{ fontSize: '13px' }}
                    >
                      {handleUndefined(entity.policy) && (
                      <>
                        {' '}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          style={{
                            textDecoration:
                                                                        'none',
                          }}
                          onClick={handlePolicy}
                        >
                          {' '}
                          Privacy Policy
                        </a>
                        {' '}
                        /
                        {' '}
                      </>
                      )}
                      {' '}
                      {handleUndefined(entity.terms) && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        style={{
                          textDecoration: 'none',
                        }}
                        onClick={handleTerms}
                      >
                        Terms & Conditions
                      </a>
                      )}
                    </Box>
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <DirectNagativefeedbackpage
                  brandInfo={{
                    logo: brandInfo?.logourl,
                  }}
                  error={error}
                  feedbackValue={reviewMsg}
                  handleFeedbackValue={(e) => setReviewMsg(e.target.value)}
                  handleSubmit={handleSubmit}
                  loading={submitLoading}
                />
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default withRouter(NegativeComment);
