/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  Chip, Grid, Box, LinearProgress, withStyles,
} from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Button from '@material-ui/core/Button';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import Rating from '@material-ui/lab/Rating';
import ReadMoreAndLess from 'react-read-more-less';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { useParams, useHistory } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';
import Geocode from 'react-geocode';
import { Helmet } from 'react-helmet';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReviewBoard from '../Display/Reviewboard/ReviewBoard';
import { postDataRequestPublic, handleUndefined } from '../../common/commonfunctions';
import config from '../../config';
import Widget from '../../components/Widget/Widget';
import { Avatar } from '../../components/Wrappers';
import { useGlobalState } from '../../context/GlobalContext';
import { Entity, Entityaddressbyentityid } from '../../Api Mutation/EntityApi';
import { useEntitiesState, getBusinessData } from '../../context/EntityContext';
import SocialShare from '../SocialShare/SocialShare';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { EntityType } from '../../common/const';
import useStyles from './styles';
import { GetReviewsWithCustomer, ReviewsAnalyticsByLocation } from '../../Api Mutation/ReviewMarketingApi';

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.root}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function PublicPage(props) {
  const matches = useMediaQuery('(max-width:959px)');
  const classes = useStyles();
  const history = useHistory();
  const { bixpandpage } = props;
  const overview = React.useRef(null);
  const review = React.useRef(null);
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const [businessCategory, setBusinessCategory] = useState();
  const [analytics, setAnalytics] = useState([]);
  const [whiteLabelStatus, setWhiteLabelStatus] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [address, setAddress] = useState([]);
  const [entity, setEntity] = useState([]);
  const [locationData, setLocationData] = useState();
  const [latitude, setLatitude] = useState();
  const [customerReviews, setCustomerReviews] = useState(null);
  const [longitude, setLongitude] = useState();
  const [brandImage, setBrandImage] = useState();
  const [reviewTotal, setReviewTotal] = useState(0);
  const BixpandLogo = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png';
  const Url = bixpandpage
    ? `${config.BixUrl}/reviews/${entity?.businessname}/${globalContext?.globalState?.currentLocation?.businessname}/${globalContext?.globalState?.userInfo?.userid}`
    : window.location.href;
  const { businessName, locationName, uid } = useParams([]);
  const isLoading = false;

  Geocode.setApiKey(config.googleMapApi);
  const BasicMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
      >
        <Marker position={{ lat: latitude, lng: longitude }} />
      </GoogleMap>
    )),
  );

  const overallReviewTotal = (object) => {
    setReviewTotal(
      object.reduce(
        (totalReviews, reviews) => totalReviews + parseInt(reviews.count, 10),
        0,
      ),
    );
  };

  const getEntityData = (entityIdValue) => {
    postDataRequestPublic(config.baseURLApi, {
      query: Entity,
      variables: {
        entityid: entityIdValue,
      },
    }).then((response) => {
      if (response?.data?.entity?.whitelabeltype) {
        setWhiteLabelStatus(response?.data?.entity?.whitelabeltype);
        setLogoUrl(response?.data?.entity?.logourl);
      }
    });
  };

  const getReviewsWithCustomers = (entityIdValue) => {
    postDataRequestPublic(config.baseURLApi, {
      query: GetReviewsWithCustomer,
      variables: {
        locationid: entityIdValue,
        sortorder: `${('DESC', 'ASC')}`,
        maxrating: 10,
        minrating: 0,
      },
    }).then((response) => {
      if (response) {
        setCustomerReviews(response?.data?.getReviewsWithCustomer);
      }
    });
  };

  function calculateAverageRating(reviewsArray, targetSourceId) {
    const filteredReviews = reviewsArray?.filter(
      (singleReview) => singleReview?.sourceid === targetSourceId,
    );
    const totalRating = filteredReviews?.reduce(
      (accumulator, currentReview) => accumulator + currentReview?.rating, 0,
    );
    const averageRating = filteredReviews?.length > 0 ? totalRating / filteredReviews?.length : 0;
    return averageRating.toFixed(1);
  }

  const analyticsByLocation = (locationId) => {
    postDataRequestPublic(config.reviewApi, {
      query: ReviewsAnalyticsByLocation,
      variables: {
        locationid: locationId,
        ispublic: true,
      },
    }).then((analyticsData) => {
      if (
        analyticsData?.data?.reviewsAnalyticsByLocation
        && analyticsData?.data?.reviewsAnalyticsByLocation[0]
      ) {
        const analyticData = JSON.parse(analyticsData?.data
          ?.reviewsAnalyticsByLocation[0]?.reportdata);
        setAnalytics(analyticData);
        overallReviewTotal(analyticData?.overall_group_by_rating);
      }
    });
  };
  const lookUpNameById = (lookUpId) => {
    postDataRequestPublic(config.baseURLApi, {
      query: `query lookupdata($lookupid:ID!)
    {lookupdata(lookupid:$lookupid){key,name}}`,
      variables: {
        lookupid: lookUpId,
      },
    }).then((name) => {
      setBusinessCategory(name?.data?.lookupdata?.key);
    });
  };
  const getLogo = async (businessId) => {
    if (handleUndefined(businessId)) {
      await postDataRequestPublic(config.baseURLApi, {
        query: `
          {brandSettingByLocation(locationid:"${handleUndefined(businessId)}"){brandsettingid,color,dateformat,timeformat,logourl}}
        `,
      }).then((logo) => setBrandImage(logo?.data?.brandSettingByLocation?.logourl));
    }
  };
  const QUERYENTITYBYENTITYID = `query entity($entityid:ID!){entity(entityid:$entityid){entityid,businessname,brandname
        firstname,lastname,email,phonenumber,entitytypeid,businesstypeid,jobid,tags,website,shortdesc,fulldesc,publicpageviewcount,createdby,updatedby,
        }}`;
  const businessByEntityId = async (entityId) => {
    if (handleUndefined(entityId)) {
      getLogo(entityId);
      await postDataRequestPublic(config.baseURLApi, {
        query: QUERYENTITYBYENTITYID,
        variables: {
          entityid: entityId,
        },
      }).then((business) => {
        setEntity(business?.data?.entity);
        if (business?.data?.entity?.businesstypeid) {
          lookUpNameById(business?.data?.entity?.businesstypeid);
        }
      });
    }
  };

  const addressByEntityId = (addressId) => {
    if (addressId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entityaddressbyentityid,
        variables: {
          entityid: addressId,
        },
      }).then((res) => {
        setAddress(res?.data?.entityaddressbyentityid);
        Geocode.fromAddress(
          `${handleUndefined(res?.data?.entityaddressbyentityid?.street1)},${handleUndefined(res?.data?.entityaddressbyentityid?.street2)},${handleUndefined(res?.data?.entityaddressbyentityid?.zip)}`,
        ).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            setLatitude(lat);
            setLongitude(lng);
          },
        );
      });
    }
  };
  const locationByEntityId = async (entityId) => {
    await postDataRequestPublic(config.baseURLApi, {
      query: QUERYENTITYBYENTITYID,
      variables: {
        entityid: entityId,
      },
    }).then((location) => {
      setLocationData(location?.data?.entity);
      analyticsByLocation(location?.data?.entity?.entityid);
      addressByEntityId(location?.data?.entity?.entityid);
    });
  };

  const locationByName = (name, businessId) => {
    postDataRequestPublic(config.baseURLApi, {
      query: `
                          query    locationbyname($name: String!, $businessid: ID!)
                            { locationbyname(name:$name,businessid:$businessid){
                                entityid,
                                businessname,
                                brandname
                        firstname,
                        lastname,email,
                        phonenumber,
                        entitytypeid,
                        businesstypeid,
                        jobid,
                        tags,
                        website,
                        shortdesc,
                        fulldesc,
                        publicpageviewcount
                              }}
                      `,
      variables: {
        name,
        businessid: handleUndefined(businessId),
      },
    }).then((location) => {
      setLocationData(location?.data?.locationbyname);
      analyticsByLocation(location?.data?.locationbyname?.entityid);
      addressByEntityId(location?.data?.locationbyname?.entityid);
      getEntityData(location?.data?.locationbyname?.entityid);
      getReviewsWithCustomers(location?.data?.locationbyname?.entityid);
    });
  };

  const businessByName = (BusinessName) => {
    postDataRequestPublic(config.baseURLApi, {
      query: `
                          query  businessbyname($name: String!)
                            {businessbyname(name:$name){
                                entityid,
                                businessname,
                                brandname
                        firstname,
                        lastname,email,
                        phonenumber,
                        entitytypeid,
                        businesstypeid,
                        jobid,
                        tags,
                        website,
                        shortdesc,
                        fulldesc
                              }}
                      `,
      variables: {
        name: BusinessName,
      },
    }).then((business) => {
      getLogo(business?.data?.businessbyname?.entityid);
      setEntity(business?.data?.businessbyname);
      lookUpNameById(business?.data?.businessbyname?.businesstypeid);
      locationByName(
        locationName,
        business?.data?.businessbyname?.entityid,
      );
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (locationData?.entityid && !props?.bixpandpage && !JSON.parse(localStorage.getItem(`${locationData?.entityid}`)) === true) {
        postDataRequestPublic(config.baseURLApi, {
          query: 'mutation increasePublicPageViewCount($entityId:ID!){increasePublicPageViewCount(entityid:$entityId)}',
          variables: {
            entityId: locationData?.entityid,
          },
        }).then((res) => {
          if (res?.data?.increasePublicPageViewCount) localStorage.setItem(`${locationData?.entityid}`, true);
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [locationData?.entityid]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (props?.bixpandpage) {
        getBusinessData(EntityType.Business, entityContext.setEntities);
        businessByEntityId(
          entityContext?.entities?.businessData
          && entityContext?.entities?.businessData[0]?.entityid,
        );
        locationByEntityId(
          globalContext?.globalState?.currentLocation?.entityid,
        );
      } else if (
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
          businessName,
        )
        && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
          locationName,
        )
      ) {
        businessByEntityId(businessName);
        locationByEntityId(locationName);
      } else {
        businessByName(businessName);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    globalContext?.globalState?.currentLocation?.entityid,
    entityContext?.entities?.businessData
    && entityContext?.entities?.businessData[0]?.entityid,
  ]);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      width: '90%',
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

  const getReviewCountByRating = (reviewData, rating) => handleUndefined(
    reviewData
      ?.filter((name) => name.round_rating === rating)
      ?.map((d) => `(${d.count})`),
  );
  return (
    <>
      <div className={classes.publicChatButton}>
        <Button
          className={classes.phoneChatButton}
          startIcon={<PhoneIcon style={{ fontSize: 50, color: '#072cfe' }} />}
          href={`tel:${locationData?.phonenumber}`}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
      <div className={classes.locationButton}>
        <Button
          className={classes.locationButton}
          startIcon={<LocationOnIcon style={{ fontSize: 50, color: '#f44336' }} />}
          href={`https://www.google.com/maps/search/${handleUndefined(address?.street1)},${handleUndefined(address?.street2)},${handleUndefined(address?.city)},${address?.AddressState?.name},${address?.AddressCountry?.name}`}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
      <div className={classes.publicChatButton}>
        <Button
          className={classes.whatsApp}
          startIcon={<WhatsAppIcon style={{ fontSize: 50, color: '#3cd4a0' }} />}
          href={`${`https://api.whatsapp.com/send?phone=${handleUndefined(locationData?.phonenumber)}`}`}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
      <div className={classes.publicChatButton}>
        <Button
          className={classes.textApple}
          startIcon={<AppleIcon style={{ fontSize: 50, color: '#607d8b' }} />}
          href={`sms:${locationData?.phonenumber}&amp;body=`}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
      <div className={classes.TextButton}>
        <Button
          className={classes.textMessage}
          startIcon={<AndroidIcon style={{ fontSize: 50, color: '#ffc260' }} />}
          href={`sms:${locationData?.phonenumber}?body=`}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>

      {isLoading ? (
        <LoadingPage />
      ) : (
        <div>
          <Helmet>
            <title>Marketing CRM & Sales, made easy for SMEs</title>
            <meta
              name="description"
              content="Technical Academy provide you best learning resources for javascript and related frameworks."
            />
            <meta property="og:title" content="Learn Technology" />
            <meta
              property="og:url"
              content="https://www.example.com/webpage/"
            />
            <meta
              property="og:description"
              content="Technical Academy provide you best learning resources for javascript"
            />
            <meta
              property="og:image"
              content="https://i.picsum.photos/id/24/200/300.jpg?hmac=UogR0hFxP5yLDwcZpCawObw8Bzm9vnzci_7eMrbqn_s"
            />
            <meta property="og:type" content="article" />
          </Helmet>
          <div style={{ display: bixpandpage ? 'none' : '' }}>
            <AppBar color="inherit" ref={overview}>
              <Toolbar>
                <img
                  className={classes.logoPublic}
                  src={whiteLabelStatus === 'full' ? logoUrl : BixpandLogo}
                  alt="App"
                />
                <div style={{ padding: 18 }}>
                  <Typography variant="subtitle1">
                    App Reviews
                    <br />
                    <a
                      className={classes.link}
                      href={whiteLabelStatus === 'full' ? (locationData?.website || '#') : config.BixPublicUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Chip
                        icon={<OpenInNewIcon />}
                        label="Visit Our Site"
                        clickable
                        color="primary"
                      />
                    </a>
                  </Typography>
                </div>
              </Toolbar>
              <div
                style={{
                  backgroundImage: 'url(https://storage.googleapis.com/bixpand-staging/WebsiteImages/border.png)',
                  height: '2px',
                }}
              />
            </AppBar>
          </div>
          <div
            style={{
              marginTop: !bixpandpage ? '100px' : '',
              padding: !bixpandpage ? '35px' : '',
            }}
          >
            <div
              style={{
                display: !bixpandpage ? 'none' : 'flex',
              }}
            >
              <Button
                className={classes.sharebtn}
                variant="none"
                color="primary"
                size="small"
                onClick={() => window.open(
                  Url,
                  '_blank',
                )}
                style={{ fontSize: 'smaller' }}
              >
                View Your public page
                {' '}
                <span style={{ color: 'blue' }}>
                  {!matches
                    ? `-> ${Url.substring(0, 85)}`
                    : ''}
                </span>
              </Button>
              &nbsp;&nbsp;
              <SocialShare brandName={handleUndefined(entity?.brandname)} Url={Url} />
            </div>
            <br />

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Widget disablewidgetmenu="true" inheritheight="true">
                  <Box
                    style={{
                      width: '50%',
                      float: 'left',
                      padding: '5px',
                    }}
                  >
                    <span style={{ fontSize: '1.5rem' }}>
                      <b>
                        {entity?.brandname
                          && entity?.brandname
                          !== 'undefined'
                          ? `${entity.brandname}`
                          : `${entity?.businessname
                            && entity?.businessname
                            !== 'undefined'
                            ? entity?.businessname
                            : ''
                          }`}
                      </b>
                    </span>
                    <br />
                    <span style={{ color: 'blue' }}>
                      {handleUndefined(
                        businessCategory,
                      ) ? (
                        <>{businessCategory}</>
                        ) : (
                          'business'
                        )}
                    </span>
                    {address?.AddressState?.name ? (
                      <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        {address?.AddressState?.name}
                        &nbsp;,
                        {' '}
                      </>
                    ) : (
                      ''
                    )}
                    &nbsp;
                    {handleUndefined(address?.zip)}
                  </Box>
                  <Box
                    style={{
                      width: '50%',
                      float: 'right',
                      padding: '5px',
                    }}
                  >
                    <center>
                      {brandImage ? (
                        <img
                          src={brandImage}
                          alt="brand logo"
                          className={
                            classes.brandLogo
                          }
                        />
                      ) : (
                        ''
                      )}
                    </center>
                  </Box>
                  <hr style={{ width: '100%' }} />
                  {handleUndefined(locationData?.website) ? (
                    <>
                      <Button
                        className={classes.website}
                        endIcon={<LaunchRoundedIcon />}
                        style={{
                          textOverflow: 'ellipsis',
                          width: '',
                          overflow: 'hidden',
                          justifyContent: 'left',
                          whiteSpace: 'nowrap',
                          float: 'left',
                        }}
                      >
                        {' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'none',
                            justifyContent: 'left',
                          }}
                          href={
                            JSON.stringify(
                              handleUndefined(
                                locationData?.website,
                              ),
                            )?.includes('http')
                              ? `${locationData?.website}`
                              : `//${locationData?.website}`
                          }
                        >
                          {handleUndefined(
                            locationData?.website,
                          )}
                        </a>
                      </Button>
                      {' '}
                      <br />
                      <a
                        className={classes.visitwebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          JSON.stringify(
                            handleUndefined(
                              locationData?.website,
                            ),
                          )?.includes('http')
                            ? `${locationData?.website}`
                            : `${locationData?.website}`
                        }
                      >
                        Visit Website
                      </a>
                    </>
                  ) : (
                    ''
                  )}
                </Widget>
              </Grid>

              <Grid item xs={12} md={6}>
                <Widget disablewidgetmenu="true">
                  <Typography
                    className={classes.ShortAndLongText}
                    variant="h5"
                    component="h2"
                  >
                    About&nbsp;
                    <b>
                      {handleUndefined(entity?.brandname)
                        ? `${`${entity?.brandname}, `}`
                        : `${handleUndefined(
                          entity?.businessname,
                        )
                          ? `${entity?.businessname
                          }, `
                          : ''
                        }`}
                      {handleUndefined(
                        locationData?.businessname,
                      )
                        ? locationData?.businessname
                        : ''}
                    </b>
                  </Typography>

                  <pre>
                    <Typography
                      className={classes.title}
                      gutterBottom
                    >
                      {locationData?.shortdesc && (
                        <ReadMoreAndLess
                          charLimit={100}
                          readMoreText="Read more"
                          readLessText="Read less"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                        >
                          {locationData?.shortdesc}
                        </ReadMoreAndLess>
                      )}
                    </Typography>
                  </pre>
                  <hr />
                  <pre>
                    <Typography
                      className={classes.title}
                      gutterBottom
                    >
                      {locationData?.fulldesc && (
                        <ReadMoreAndLess
                          className="read-more-content"
                          charLimit={250}
                          readMoreText="Read more"
                          readLessText="Read less"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                        >
                          {locationData?.fulldesc}
                        </ReadMoreAndLess>
                      )}
                    </Typography>
                  </pre>
                </Widget>
              </Grid>
            </Grid>
            <br />
            <hr className={classes.label} />
            <br />

            <Grid container spacing={1}>
              <Grid container item spacing={3}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing="2">
                    <Grid item xs={12} md={6}>
                      <Widget disablewidgetmenu="true">
                        <center>
                          <Avatar
                            style={{
                              width: '80px',
                              height: '80px',
                            }}
                            aria-label=""
                            size="large"
                            color="primary"
                          >
                            <h3>
                              {calculateAverageRating(customerReviews, 1)}
                            </h3>
                          </Avatar>
                          <Box>
                            <Rating
                              readOnly
                              name="customized-empty"
                              precision={0.5}
                              value={`${parseFloat(
                                analytics
                                  ?.Bixpand
                                  ?.total_avg,
                              )?.toFixed(1)}`}
                            />
                          </Box>
                          {
                            analytics?.Bixpand
                              ?.total_count
                          }
                          &nbsp;Reviews
                          <br />
                          <img
                            className={
                              whiteLabelStatus === 'full' ? classes.locationLogo : classes.bixLogo
                            }
                            src={whiteLabelStatus === 'full' ? logoUrl : BixpandLogo}
                            alt="bix "
                          />
                        </center>
                      </Widget>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Widget disablewidgetmenu="true">
                        <center>
                          <Avatar
                            style={{
                              width: '80px',
                              height: '80px',
                            }}
                            aria-label=""
                            size="large"
                            color="primary"
                          >
                            <h3>
                              {calculateAverageRating(customerReviews, 2)}
                            </h3>
                          </Avatar>
                          <Box>
                            <Rating
                              readOnly
                              name="customized-empty"
                              precision={0.5}
                              value={`${parseFloat(
                                analytics
                                  ?.Google
                                  ?.total_avg,
                              )?.toFixed(1)}`}
                            />
                          </Box>
                          {
                            analytics?.Google
                              ?.total_count
                          }
                          &nbsp;Reviews
                          <br />
                          <img
                            className={
                              classes.reviewLogo
                            }
                            src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/google.svg"
                            alt="google"
                          />
                        </center>
                      </Widget>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <Widget
                      disablewidgetmenu="true"
                      title={<b>Review Summary </b>}
                    >
                      <Typography
                        variant="h5"
                        component="h2"
                      >
                        <span
                          className={
                            classes.totalReviewsText
                          }
                          style={{ float: 'right' }}
                        >
                          Total Reviews:
                          <b>
                            {handleUndefined(
                              reviewTotal,
                            ) || 0}
                          </b>
                        </span>
                      </Typography>
                      <br />

                      <br />
                      <Grid container alignItems="center">
                        <Grid xs={2} item>
                          {' '}
                          5 Star
                        </Grid>
                        <Grid xs={8} item>
                          <div
                            className={classes.root}
                          >
                            <BorderLinearProgress
                              variant="determinate"
                              value={((handleUndefined(
                                analytics?.overall_group_by_rating
                                  ?.filter(
                                    (name) => name.round_rating === '5',
                                  )
                                  ?.map((d) => d.count),
                              ) * 100) / handleUndefined(reviewTotal))?.toFixed(1) || 0}
                            />
                          </div>
                        </Grid>
                        <Grid xs={2} item>
                          {handleUndefined(
                            analytics
                              ?.overa_all_rating
                              ?.count,
                          )
                            ? `${(
                              (handleUndefined(
                                analytics?.overall_group_by_rating
                                  ?.filter(
                                    (name) => name.round_rating
                                      === '5',
                                  )?.map(
                                    (d) => d.count,
                                  ),
                              ) * 100)
                              / handleUndefined(reviewTotal)
                            )?.toFixed(1)}%`
                            : ''}
                          &nbsp;
                          {getReviewCountByRating(
                            analytics?.overall_group_by_rating,
                            '5',
                          )}
                        </Grid>
                      </Grid>
                      <br />

                      <Grid container alignItems="center">
                        <Grid xs={2} item>
                          {' '}
                          4 Star
                        </Grid>
                        <Grid xs={8} item>
                          <BorderLinearProgress
                            variant="determinate"
                            value={((handleUndefined(
                              analytics?.overall_group_by_rating
                                ?.filter(
                                  (name) => name.round_rating === '4',
                                )
                                ?.map((d) => d.count),
                            )
                              * 100)
                              / handleUndefined(
                                analytics
                                  ?.overa_all_rating
                                  ?.count,
                              ))?.toFixed(1) || 0}
                          />
                        </Grid>
                        <Grid xs={2} item>
                          {handleUndefined(
                            analytics
                              ?.overa_all_rating
                              ?.count,
                          )
                            ? `${(
                              (handleUndefined(
                                analytics?.overall_group_by_rating
                                  ?.filter(
                                    (name) => name.round_rating
                                      === '4',
                                  )
                                  ?.map(
                                    (d) => d.count,
                                  ),
                              )
                                * 100)
                              / handleUndefined(
                                reviewTotal,
                              )
                            )?.toFixed(1)}%`
                            : ''}
                          &nbsp;
                          {getReviewCountByRating(
                            analytics?.overall_group_by_rating,
                            '4',
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container alignItems="center">
                        <Grid xs={2} item>
                          3 Star
                        </Grid>
                        <Grid xs={8} item>
                          <BorderLinearProgress
                            variant="determinate"
                            value={((handleUndefined(analytics?.overall_group_by_rating
                              ?.filter((name) => name.round_rating === '3')
                              ?.map((d) => d.count)) * 100) / handleUndefined(
                              analytics?.overa_all_rating?.count,
                            )
                            )?.toFixed(1) || 0}
                          />
                        </Grid>
                        <Grid xs={2} item>
                          {handleUndefined(
                            analytics
                              ?.overa_all_rating
                              ?.count,
                          )
                            ? `${(
                              (handleUndefined(
                                analytics?.overall_group_by_rating
                                  ?.filter(
                                    (name) => name.round_rating
                                      === '3',
                                  )
                                  ?.map(
                                    (d) => d.count,
                                  ),
                              )
                                * 100)
                              / handleUndefined(
                                reviewTotal,
                              )
                            )?.toFixed(1)}%`
                            : ''}
                          &nbsp;
                          {getReviewCountByRating(
                            analytics?.overall_group_by_rating,
                            '3',
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container alignItems="center">
                        <Grid xs={2} item>
                          {' '}
                          2 Star
                        </Grid>
                        <Grid xs={8} item>
                          <BorderLinearProgress
                            variant="determinate"
                            value={((handleUndefined(analytics?.overall_group_by_rating
                              ?.filter(
                                (name) => name.round_rating === '2',
                              )
                              ?.map((d) => d.count)) * 100)
                              / handleUndefined(analytics?.overa_all_rating?.count)
                            )?.toFixed(1) || 0}
                          />
                        </Grid>
                        <Grid xs={2} item>
                          {handleUndefined(
                            analytics
                              ?.overa_all_rating
                              ?.count,
                          )
                            ? `${(
                              (handleUndefined(
                                analytics?.overall_group_by_rating
                                  ?.filter(
                                    (name) => name.round_rating
                                      === '2',
                                  )
                                  ?.map(
                                    (d) => d.count,
                                  ),
                              )
                                * 100)
                              / handleUndefined(
                                reviewTotal,
                              )
                            )?.toFixed(1)}%`
                            : ''}
                          &nbsp;
                          {getReviewCountByRating(
                            analytics?.overall_group_by_rating,
                            '2',
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container alignItems="center">
                        <Grid xs={2} item>
                          {' '}
                          1 Star
                        </Grid>
                        <Grid xs={8} item>
                          <BorderLinearProgress
                            variant="determinate"
                            value={((handleUndefined(
                              analytics?.overall_group_by_rating
                                ?.filter((name) => name.round_rating === '1')
                                ?.map(
                                  (d) => d.count,
                                ),
                            ) * 100) / handleUndefined(
                              analytics?.overa_all_rating?.count,
                            ))?.toFixed(1) || 0}
                          />
                        </Grid>
                        <Grid xs={2} item>
                          {handleUndefined(
                            analytics
                              ?.overa_all_rating
                              ?.count,
                          )
                            ? `${(
                              (handleUndefined(
                                analytics?.overall_group_by_rating
                                  ?.filter(
                                    (name) => name.round_rating
                                      === '1',
                                  )
                                  ?.map(
                                    (d) => d.count,
                                  ),
                              )
                                * 100)
                              / handleUndefined(
                                reviewTotal,
                              )
                            )?.toFixed(1)}%`
                            : ''}
                          &nbsp;
                          {getReviewCountByRating(
                            analytics?.overall_group_by_rating,
                            '1',
                          )}
                        </Grid>
                        <br />
                      </Grid>

                      <br />

                      <SocialShare
                        Url={Url}
                        brandName={
                          handleUndefined(
                            entity?.brandname,
                          )
                            ? entity?.brandname
                            : handleUndefined(
                              entity?.businessname,
                            )
                        }
                      />
                    </Widget>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Widget disablewidgetmenu="true">
                    {address?.addressid ? (
                      <>
                        <Box
                          height={200}
                          width="100%"
                          mx="-24px"
                          mt={2}
                        >
                          <BasicMap
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.googleMapApi}`}
                            loadingElement={(
                              <div
                                style={{
                                  height:
                                    'inherit',
                                  width:
                                    'inherit',
                                }}
                              />
                            )}
                            containerElement={(
                              <div
                                style={{
                                  height:
                                    '100%',
                                  width:
                                    'calc(100% + 48px)',
                                }}
                              />
                            )}
                            mapElement={(
                              <div
                                style={{
                                  height:
                                    '100%',
                                }}
                              />
                            )}
                          />
                        </Box>
                        <br />
                      </>
                    ) : null}

                    {address?.addressid ? (
                      <div className={classes.Iconlabel}>
                        <LocationOnIcon color="error" />
                        &nbsp; &nbsp; &nbsp;
                        <a
                          href={`https://www.google.com/maps/search/${handleUndefined(address?.street1)},${handleUndefined(address?.street2)},${handleUndefined(address?.city)},${address?.AddressState?.name},${address?.AddressCountry?.name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'none',
                            color: 'blue',
                          }}
                        >
                          <p>
                            {handleUndefined(
                              address?.street1,
                            )
                              ? `${address.street1
                              }, `
                              : ''}
                            {handleUndefined(
                              address?.street2,
                            )
                              ? `${address.street2
                              }, `
                              : ''}
                            {handleUndefined(
                              address?.city,
                            )
                              ? `${address.city
                              }, `
                              : ''}
                            {address?.AddressState?.name
                              ? `${address?.AddressState?.name}, `
                              : ''}
                            {address?.AddressCountry?.name && address?.AddressCountry?.name}
                            &nbsp;
                            {handleUndefined(
                              address?.zip,
                            )
                              ? `${address.zip}.`
                              : ''}
                          </p>
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                    {locationData?.phonenumber ? (
                      <div className={classes.Iconlabel}>
                        <PhoneIcon color="primary" />
                        &nbsp; &nbsp; &nbsp;
                        <a
                          href={`tel:${locationData?.phonenumber}`}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          {locationData?.phonenumber}
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                    {handleUndefined(
                      locationData?.website,
                    ) ? (
                      <div className={classes.Iconlabel}>
                        <PublicIcon
                          style={{ color: '#3CD4A0' }}
                        />
                        &nbsp; &nbsp; &nbsp;
                        <a
                          href={locationData?.website}
                          style={{
                            textDecoration: 'none',
                            color: 'blue',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p>
                            {locationData?.website}
                          </p>
                        </a>
                      </div>
                      ) : (
                        ''
                      )}

                    {handleUndefined(businessCategory) ? (
                      <>
                        <div
                          className={
                            classes.Iconlabel
                          }
                        >
                          <LocalOfferIcon
                            style={{
                              color: '#FFC260',
                            }}
                          />
                          &nbsp; &nbsp; &nbsp;
                          <p>
                            {' '}
                            {businessCategory}
                          </p>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    {handleUndefined(locationData?.publicpageviewcount)
                      ? (
                        <>
                          <div
                            className={
                              classes.Iconlabel
                            }
                          >
                            <VisibilityIcon color="primary" />
                            &nbsp; &nbsp; &nbsp;
                            <p>
                              Total&nbsp;Views&nbsp;Of&nbsp;This&nbsp;Page&nbsp;:-&nbsp;
                              <b>{locationData?.publicpageviewcount}</b>
                            </p>
                          </div>
                        </>
                      ) : ''}
                    <br />
                    <center>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => history.push(`/touchless-public/${entity?.entityid}/${locationData?.entityid}/${uid || globalContext?.globalState?.userInfo?.userid}?public=true`)}
                        startIcon={
                          <CreateIcon size={50} />
                        }
                      >
                        Write&nbsp;a&nbsp;Review
                      </Button>
                    </center>
                  </Widget>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {locationData?.entityid ? (
                  <div ref={review}>
                    {locationData?.entityid ? (
                      <ReviewBoard
                        shareReview
                        filterReviews
                        setCustomerReviews={setCustomerReviews}
                        whiteLabelStatus={whiteLabelStatus}
                        logoUrl={logoUrl}
                        publicPage={false}
                        locationid={
                          locationData?.entityid
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </div>
          <div style={{ display: bixpandpage ? 'none' : '' }}>
            <div style={{ backgroundColor: 'black' }}>
              <center>
                {' '}
                <a
                  className={classes.link}
                  href={whiteLabelStatus === 'full' ? '#' : config.BixPublicUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={classes.logo}
                    src={whiteLabelStatus === 'full' ? logoUrl : BixpandLogo}
                    alt="App"
                  />
                  {' '}
                </a>
              </center>
              <div
                style={{
                  backgroundImage: 'url(https://storage.googleapis.com/bixpand-staging/WebsiteImages/border.png)',
                  height: '2px',
                }}
              />
              <center>
                <br />
                <span style={{ color: '#fff' }}>
                  MAKE REAL CUSTOMERS YOUR BRAND AMBASSADOR
                </span>
                {' '}
              </center>

              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
