export const CreateAffiliateLink = `
mutation createAffiliateLink($createdby: ID!, $ownerid: ID!) {
    createAffiliateLink(createdby: $createdby, ownerid: $ownerid) {
      affiliateid
      name
      category
      author
      contenttemplate
      templatedesign
      locationid
      status
      isfree
      createdby
      updatedby
      createdAt
      updatedAt
      openeddata
      clickeddata
      sharecount
      shorturl
      businessid
      ownerid
    }
  }`;

export const AffiliateLinkByOwnerId = `query affiliateLinkByOwnerId($ownerid: ID!, $pageNumber: Int!, $status: Int, $startdate: String, $enddate: String, $pageSize: Int!, $searchtext: String) {
    affiliateLinkByOwnerId(ownerid: $ownerid, pageNumber: $pageNumber, status: $status, startdate: $startdate, enddate: $enddate, pageSize: $pageSize, searchtext: $searchtext) {
      count
      rows {
        affiliateid
        name
        category
        author
        contenttemplate
        templatedesign
        locationid
        status
        isfree
        createdby
        updatedby
        createdAt
        updatedAt
        openeddata
        clickeddata
        sharecount
        shorturl
        businessid
        ownerid
      }
    }
  }`;

export const AffiliateLink = `query affiliateLink($affiliateid: ID!) {
  affiliateLink(affiliateid: $affiliateid) {
    affiliateid
    name
    category
    author
    contenttemplate
    templatedesign
    locationid
    status
    isfree
    createdby
    updatedby
    createdAt
    updatedAt
    openeddata
    clickeddata
    sharecount
    shorturl
    businessid
    ownerid
  }
}`;

export const AddActivityAffiliateLink = `mutation addActivityAffiliateLink($affiliateid: ID!, $openeddata: String, $clickeddata: String) {
  addActivityAffiliateLink(affiliateid: $affiliateid, openeddata: $openeddata, clickeddata: $clickeddata)
}`;

export const AffiliateLinkByShorturl = `query affiliateLinkbyshorturl($shorturl: String!, $ownerid: ID!) {
  affiliateLinkbyshorturl(shorturl: $shorturl, ownerid: $ownerid) {
    affiliateid
    name
    category
    author
    contenttemplate
    templatedesign
    locationid
    status
    isfree
    createdby
    updatedby
    createdAt
    updatedAt
    openeddata
    clickeddata
    sharecount
    shorturl
    businessid
    ownerid
  }
}`;

export const EditAffiliateLink = `mutation editAffiliateLink($affiliateid: ID!, $updatedby: ID!, $name: String, $category: [String], $author: String, $contenttemplate: String, $templatedesign: String, $locationid: ID, $status: Int, $openeddata: String, $clickeddata: String, $sharecount: Int, $businessid: ID, $ownerid: ID, $isfree: Boolean) {
  editAffiliateLink(affiliateid: $affiliateid, updatedby: $updatedby, name: $name, category: $category, author: $author, contenttemplate: $contenttemplate, templatedesign: $templatedesign, locationid: $locationid, status: $status, openeddata: $openeddata, clickeddata: $clickeddata, sharecount: $sharecount, businessid: $businessid, ownerid: $ownerid, isfree: $isfree)
}`;

export const UsersByReferby = `query usersbyreferby($referby: ID!) {
  usersbyreferby(referby: $referby) {
    userid
    roleid
    firstname
    lastname
    phonenumber
    email
    role
    lastlogin
    deleted
    usertype
    businessid
    locationid
    defaultcontactview
    createdAt
  }
}`;
