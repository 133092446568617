import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import useStyle from './styles';
import MuiCustomButton from '../../common/MuiCustomButton';
import { postDataRequestPublic } from '../../common/commonfunctions';
import config from '../../config';
import { Entity } from '../../Api Mutation/EntityApi';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { SubscriptionStatus } from '../../common/const';

const UnSubscribe = () => {
    const classes = useStyle();
    const search = window?.location?.search;
    const params = new URLSearchParams(search);
    const locationId = params.get('l');
    const emailId = params.get('e');
    const campaignrunId = params.get('cri');
    const toEmail = params.get('to');
    const fromEmail = params.get('from');
    const [dataLoading, setDataLoading] = useState(false);
    const [website, setWebSite] = useState('');
    const [thankYouMsg, setThankYouMessage] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (isMounted && locationId) {
            setDataLoading(true);
            postDataRequestPublic(config.baseURLApi, {
                query: Entity,
                variables: { entityid: locationId },
            }).then(res => {
                if (res?.data?.entity) {
                    setWebSite(res?.data?.entity?.website);
                }
                setDataLoading(false);
            });
        }
        return () => {
            isMounted = false;
        };
    }, [locationId]);

    const unSubscribe = () => {
        setDataLoading(true);
        postDataRequestPublic(config.baseURLApi, {
            query: `mutation Updatecontactbyemail($locationid: ID!, $primaryemail: String!, $emailid: ID, $subscriptionstatus: Int, $emailsubscriptiondate: String, $emailunsubscriptiondate: String, $campaignrunid: ID) {
            updatecontactbyemail(locationid: $locationid, emailid: $emailid, primaryemail: $primaryemail, subscriptionstatus: $subscriptionstatus, emailsubscriptiondate: $emailsubscriptiondate, emailunsubscriptiondate: $emailunsubscriptiondate, campaignrunid: $campaignrunid)
          }`,
            variables: {
                locationid: locationId,
                primaryemail: toEmail,
                subscriptionstatus: SubscriptionStatus.UnSubscribe,
                emailunsubscriptiondate: moment(Date.now())
                    .utc()
                    .format(),
                emailid: emailId,
                campaignrunid: campaignrunId,
            },
        }).then(res => {
            if (res?.data?.updatecontactbyemail) {
                setThankYouMessage(true);
            }
            setDataLoading(false);
        });
    };

    return (
        <>
            {dataLoading ? <LoadingPage /> : ''}
            <div className={classes.paper}>
                {thankYouMsg ? (
                    <Paper className={classes.cal} elevation={6}>
                        <center>
                            <Typography variant="h6">
                                We are sad to see you go!
                            </Typography>
                            <Typography variant="body1">
                                You have been unsubscribed and will no longer
                                here from us.
                            </Typography>
                        </center>
                    </Paper>
                ) : (
                    <Paper className={classes.cal} elevation={6}>
                        <center>
                            <Typography variant="h5">
                                Unsubscribe Yourself
                            </Typography>
                        </center>
                        <Box className="mt-3">
                            {/* <Typography variant="body1">{`You are receiving this email from ${fromEmail}`}</Typography> */}
                            <Typography variant="body1">
                                {`Your Email is `}
                                <span style={{ color: 'blue' }}>{toEmail}</span>
                            </Typography>
                        </Box>
                        <Box className="mt-3">
                            <Typography variant="body1">{`Unsubscribe to all emails from `}<span style={{color: 'purple'}}>{fromEmail}</span></Typography>
                        </Box>
                        <Box className="mt-5">
                            <MuiCustomButton
                                muiHandleClick={() => unSubscribe()}
                                muiSize="small"
                                muiVariant="contained"
                                muiBtnValue="UNSUBSCRIBE"
                            />
                            <MuiCustomButton
                                muiHandleClick={() => {
                                    window.open(website, '_blank');
                                }}
                                muiSize="small"
                                muiVariant="contained"
                                muiBtnValue="CANCEL"
                                muiClass="ml-2"
                            />
                        </Box>
                    </Paper>
                )}
            </div>
        </>
    );
};

export default UnSubscribe;
