import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  cropContainer: (props) => ({
    position: 'relative',
    width: '65%',
    height: 200,
    background: props.background,
    margin: '0 50px 0px 50px',
    border: '2px dashed black',
  }),
  componentHeading: {
    fontSize: '14px',
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  img: {
    width: '30%',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      fontSize: '10px',
      width: '35%',
    },
  },
  cropButton: {
    flexShrink: 0,
    margin: '10px 20px',
    padding: '4px 10px',
    fontSize: '12px',
    fontFamily: 'open sans',
    alignItems: 'center',
  },
  controls: {
    padding: '0 16 16 16',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    margin: '0px 50px',
    width: '200px',
    alignItems: 'center',
  },
  sliderLabelInput: {
    textAlign: 'center',
    width: '447px',
    marginLeft: '50px',
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      margin: '0 15px',
      marginLeft: '50px',
      width: '200px',
      textAlign: 'center',
    },
  },
  sliderLabel: {
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      minWidth: 55,
    },
  },
  body: {
    background: '#f2f2f2',
    padding: '20px',
  },
  inputStyle: {
    width: '100%',
    padding: '2px',
    textAlign: 'center',
  },
  imageGrid: {
    width: '100%',
    textAlign: 'center',
  },
  errormsg: {
    marginLeft: 50,
    marginTop: 10,
    color: 'red',
    fontWeight: 900,
  },
  required: {
    color: 'red',
    marginLeft: 3,
    fontSize: 14,
    fontWeight: 600,
  },
}));
