import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography, CircularProgress, Avatar as AvatarMui } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';
import useStyles from './styles';
import { handleUndefined, postDataRequest } from "../../common/commonfunctions";
import { showNotification } from "../../components/Notification/ToastifyNotifications";
import config from "../../config";
import { useGlobalState } from '../../context/GlobalContext';
import { findDomainByCompany, findEmailByDomain } from "../../Api Mutation/Campaign";
import { CreateContact, CRMcontactByData, UpdateContact } from "../../Api Mutation/Contact";
import { LoadingPage } from "../LoadingPage/LoadingPage";

export default function EmailFinder({
    contactId,
    handleClose
}) {
    const classes = useStyles();
    const globalContext = useGlobalState();
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [domainArr, setDomainArr] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [domain, setDomain] = useState(null);
    const [email, setEmail] = useState(null);
    const [apiCallCount, setApiCallCount] = useState(1);
    const [contactLoading, setContactLoading] = useState(false);
    useEffect(() => {
        if (contactId) {
            setContactLoading(true);
            postDataRequest(config.baseURLApi, {
                query: CRMcontactByData,
                variables: {
                    contactid: contactId,
                },
            }).then(res => {
                const result = res?.data?.contact;
                if (res?.errors && res?.errors[0]?.message) {
                    showNotification(res.errors[0].message, 'error');
                } else if (result) {
                    setFirstName(result?.firstname);
                    setLastName(result?.lastname);
                    setCompanyName(result?.organization?.name);
                }
                setContactLoading(false);
            });
        }
    }, [contactId])
    return (
        <>
            {contactLoading ? <LoadingPage /> : ''}
            <Box style={{ marginTop: 30 }}>
                <center>
                    <Typography variant="h4">Email Finder</Typography>
                    <Typography variant="h2" style={{ color: '#005eb2', fontWeight: 500 }}>Find the verified email address</Typography>
                    <Typography variant="h2" style={{ fontWeight: 500 }}>of any professional.</Typography>
                </center>
            </Box>
            <Box className={contactId ? classes.newBox : classes.Box}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            label="First Name"
                            placeholder="Enter a FirstName"
                            variant="outlined"
                            InputProps={{
                                style: { height: 38 },
                            }}
                            InputLabelProps={{
                                style: { marginTop: 1 },
                            }}
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                                setApiCallCount(1);
                                setEmail(null);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Last Name"
                            placeholder="Enter a LastName"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                style: { height: 38 },
                            }}
                            InputLabelProps={{
                                style: { marginTop: 1 },
                            }}
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                                setApiCallCount(1);
                                setEmail(null);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Find Domain By Company"
                            placeholder="Enter a CompanyName"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                style: { height: 38 },
                            }}
                            InputLabelProps={{
                                style: { marginTop: 1 },
                            }}
                            value={companyName}
                            onChange={(e) => {
                                setCompanyName(e.target.value);
                                setApiCallCount(1);
                                setEmail(null);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            size="small"
                            variant="contained"
                            className={classes.emailbtn}
                            disabled={!companyName || dataLoading || apiCallCount > 1}
                            autoFocus
                            startIcon={dataLoading ? <CircularProgress color="inherit" size={18} /> : ''}
                            onClick={() => {
                                setDataLoading(true);
                                setDomainArr([]);
                                setDomain(null);
                                setEmail(null);
                                postDataRequest(config.emailFinderApi, {
                                    query: findDomainByCompany,
                                    variables: {
                                        name: companyName
                                    },
                                }).then((res) => {
                                    if (res?.errors && res?.errors[0]?.message) {
                                        showNotification(res.errors[0].message, 'error');
                                    } else if (res?.data?.findDomainByCompany) {
                                        let arr = [];
                                        res?.data?.findDomainByCompany.map((r) => {
                                            let obj = {};
                                            obj.key = r;
                                            obj.value = r;
                                            arr.push(obj);
                                        });
                                        setDomainArr(arr);
                                        showNotification(`Domain find Successfully`, 'success');

                                    }
                                    setDataLoading(false);
                                }).catch((err) => {
                                    setDataLoading(false);
                                    showNotification(err, 'error');
                                });
                            }}
                        >
                            Find Domain
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Select
                            name="Select Domain"
                            size="small"
                            required
                            menuPlacement="auto"
                            fullWidth
                            placeholder={<Typography variant="body2">Select Domain</Typography>}
                            getOptionLabel={(option) => option?.key}
                            getOptionValue={(option) => option?.value}
                            options={domainArr}
                            menuPortalTarget={document.body}
                            value={domainArr.find((d) => d.value === domain)}
                            onChange={(newValue) => {
                                setDomain(newValue?.value);
                                setEmail(null);
                                setApiCallCount(1);
                            }}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <center>
                            <Button
                                startIcon={loading ? <CircularProgress color="inherit" size={18} /> : <SearchIcon />}
                                size="small"
                                variant="contained"
                                fullWidth
                                className={classes.emailButton}
                                disabled={loading || apiCallCount > 1 || dataLoading}
                                onClick={() => {
                                    if (firstName) {
                                        if (lastName) {
                                            if (domain) {
                                                setLoading(true);
                                                setApiCallCount(2);
                                                postDataRequest(config.emailFinderApi, {
                                                    query: findEmailByDomain,
                                                    variables: {
                                                        firstname: firstName,
                                                        lastname: lastName,
                                                        companyName: companyName,
                                                        domain: domain,
                                                        createdby: globalContext?.globalState?.userInfo?.userid,
                                                        locationid: globalContext.globalState.currentLocation.entityid,
                                                        businessid: globalContext?.globalState?.currentLocation?.parententityid,
                                                    },
                                                }).then((res) => {
                                                    if (res?.errors && res?.errors[0]?.message) {
                                                        showNotification(res.errors[0].message, 'error');
                                                    } else if (res?.data?.findEmailByDomain !== '') {
                                                        setEmail(res?.data?.findEmailByDomain);
                                                        showNotification('1 credit has been debited to finding this email', 'success');
                                                    } else {
                                                        setApiCallCount(1);
                                                        setEmail('Verified email not found to this contact');
                                                    }
                                                    setLoading(false);
                                                }).catch((err) => {
                                                    setLoading(false);
                                                    showNotification(err, 'error');
                                                });
                                            } else {
                                                showNotification('Domain is required to find email', 'error');
                                            }
                                        } else {
                                            showNotification('Last Name is required to find email', 'error');
                                        }
                                    } else {
                                        showNotification('First Name is required to find email', 'error');
                                    }
                                }}
                            >
                                Find Email
                            </Button>
                        </center>
                    </Grid>
                </Grid>
            </Box>
            {email ? (
                <Box className={classes.mainBox}>
                    <Box className={classes.childBox}>
                        <AvatarMui className={classes.split} src={null}>
                            {handleUndefined(firstName?.charAt(0)) + handleUndefined(lastName?.charAt(0))}
                        </AvatarMui>
                        <Box style={{ marginLeft: 10 }}>
                            <Typography variant="body1" style={{ fontSize: 17 }}>{handleUndefined(firstName).charAt(0).toUpperCase() + handleUndefined(firstName).slice(1) + ' ' + handleUndefined(lastName).charAt(0).toUpperCase() + handleUndefined(lastName).slice(1)}</Typography>
                            <Typography variant="body1">{email}</Typography>
                        </Box>
                    </Box>
                    {email.includes('@') && (
                        <Box>
                            <Button
                                size="small"
                                variant="contained"
                                fullWidth
                                className={contactId ? classes.contactbtn : classes.btn}
                                disabled={contactLoading}
                                onClick={() => {
                                    setContactLoading(true);
                                    if (contactId) {
                                        postDataRequest(config.baseURLApi, {
                                            query: UpdateContact,
                                            variables: {
                                                contactid: contactId,
                                                primaryemail: email,
                                                ownerid: globalContext?.globalState?.userInfo?.userid,
                                                locationid: globalContext.globalState.currentLocation.entityid,
                                                updatedby: globalContext?.globalState?.userInfo?.userid,
                                            },
                                        }).then((res) => {
                                            if (res?.errors && res?.errors[0]?.message) {
                                                showNotification(res.errors[0].message, 'error');
                                            } else if (res?.data?.updateContact) {
                                                handleClose();
                                                setApiCallCount(1);
                                                setFirstName(null);
                                                setLastName(null);
                                                setCompanyName(null);
                                                setDomain(null);
                                                setDomainArr([]);
                                                setEmail(null);
                                                showNotification('Email saved in contact Successfully', 'success');
                                            }
                                            setContactLoading(false);
                                        }).catch((err) => {
                                            setContactLoading(false);
                                            showNotification(err, 'error');
                                        });
                                    } else {
                                        const contactObj = {
                                            firstname: firstName,
                                            lastname: lastName,
                                            primaryemail: email,
                                            organizationname: companyName,
                                            lifecyclestage: 2,
                                            status: 3,
                                            ownerid: globalContext?.globalState?.userInfo?.userid,
                                            locationid: globalContext.globalState.currentLocation.entityid,
                                            createdby: globalContext?.globalState?.userInfo?.userid,
                                        };
                                        postDataRequest(config.baseURLApi, {
                                            query: CreateContact,
                                            variables: contactObj,
                                        }).then((res) => {
                                            if (res?.errors && res?.errors[0]?.message) {
                                                showNotification(res.errors[0].message, 'error');
                                            } else if (res?.data?.createContact) {
                                                setApiCallCount(1);
                                                setFirstName(null);
                                                setLastName(null);
                                                setCompanyName(null);
                                                setDomain(null);
                                                setDomainArr([]);
                                                setEmail(null);
                                                showNotification('Contact Created Successfully', 'success');
                                            }
                                            setContactLoading(false);
                                        }).catch((err) => {
                                            setContactLoading(false);
                                            showNotification(err, 'error');
                                        });
                                    }
                                }}
                            >
                                {contactId ? 'Add Email To Contact' : 'Add To Contact'}
                            </Button>
                        </Box>
                    )}
                </Box>
            ) : ''}
        </>
    );
};