/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, {
  useState, useEffect, lazy, Suspense,
} from 'react';
import {
  Paper, Grid, Typography, Box, Button, Divider, Step, Stepper, StepLabel, useMediaQuery,
  IconButton, Link, Tooltip, Avatar,
} from '@material-ui/core';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import TimezoneSelect from 'react-timezone-select';
import { useParams, useHistory } from 'react-router-dom';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PublicIcon from '@material-ui/icons/Public';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import VideocamIcon from '@material-ui/icons/Videocam';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import BusinessFavicon from '../../../../common/BusinessFavicon';
import { Entity, BrandSettingByLocation } from '../../../../Api Mutation/EntityApi';
import { Userprofilebyuser } from '../../../../Api Mutation/Login';
import Payment from '../../../Setting/PaymentConnection/Payment';
import { postDataRequestPublic, handleUndefined } from '../../../../common/commonfunctions';
import {
  ActiveAppointmentStatus,
} from '../../../../common/const';
import config from '../../../../config';
import useStyle from '../style';
import { LoadingPage } from '../../../LoadingPage/LoadingPage';
import {
  editBooking, eventBookingData, eventsAvailableTime, eventByData, bookEvent, CreateContactOrg,
  Eventbyshorturl,
} from '../../../../Api Mutation/Appointment';
import LazyLoader from '../../../LoadingPage/LazyLoader';
import { showNotification } from '../../../../components/Notification/ToastifyNotifications';
import BixpoweredBy from '../../../../common/PoweredBy';

const FormGenerator = lazy(() => import('../../InquiryForms/FormGenerator'));

export default function Appintment() {
  const classes = useStyle();
  const [activeTabId, setActiveTabId] = useState(0);
  const matches = useMediaQuery('(max-width:600px)');
  const { shorturl, username } = useParams([]);
  const history = useHistory();
  const { search } = history?.location;
  const params = new URLSearchParams(search);
  const bookid = params.get('bookid');
  const user = params.get('user');
  const contactid = params.get('contactId');
  const steps = ['Date & Time', 'Payment', 'Details', 'Confrim'];
  const suggestContact = [];
  const [value, setValue] = useState(Date.now());
  const [dataLoading, setDataLoading] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [radioPos, setRadioPos] = useState('vertical');
  const [checkBoxPos, setCheckBoxPos] = useState('vertical');
  const [bookingId, setBookingId] = useState();
  const [eventData, setEventData] = useState();
  const [bookingDate, setBookingDate] = useState();
  const [profileData, setProfileData] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [contact, setContact] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [contactId, setContactId] = useState();
  const [contactData, setContactData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [brandImage, setBrandImage] = useState();
  const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [termDesc, setTermDesc] = useState();
  const [enableTerm, setEnableTerm] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [content, setContent] = useState([]);
  const [btnName, setBtnName] = useState('Schedule Event');
  const [colorCode, setColorCode] = useState('#005eb2');
  const [backgroundColorCode, setBackgroundColorCode] = useState('#fff');
  const [foreColor, setForeColor] = useState('#000');
  const [btnPos, setBtnPos] = useState('center');
  const [defaultCountryPhoneCode, setDefaultCountryPhoneCode] = useState('1');
  const [btnThemeColor, setBtnThemeColor] = useState('#005eb2');
  const [btnTextColor, setBtnTextColor] = useState('#fff');
  const [submittedData, setData] = useState('');
  const [onClick, setClick] = useState(false);
  const [eventId, setEventId] = useState();
  const [lid, setLid] = useState();
  const [bid, setBid] = useState();
  const [uid, setUid] = useState();
  const [favicon, setFavicon] = useState();
  const locationId = handleUndefined(JSON.parse(localStorage.getItem('location_Id')));

  const getEventTime = async (props) => {
    if (handleUndefined(props?.eventid || eventId)) {
      setDataLoading(true);
      await postDataRequestPublic(config.baseURLApi, {
        query: eventsAvailableTime,
        variables: {
          eventid: handleUndefined(props?.eventid || eventId),
          day: moment(props?.day || value).format('YYYY-MM-DD'),
          timezone: props?.timeZone || timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }).then((res) => {
        if (res?.data?.eventsavailabletime) {
          setDataLoading(false);
          const { timeSlot } = res?.data?.eventsavailabletime;
          setTimeData(timeSlot);
          setDataLoading(false);
        }
      });
    }
  };

  const eventbyshorturlData = () => {
    if (shorturl) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Eventbyshorturl,
        variables: {
          shorturl: `${username}/${shorturl}`,
        },
      }).then((res) => {
        const data = res?.data?.eventbyshorturl;
        if (data) {
          setEventId(data?.eventid);
          setLid(data?.locationid);
          setUid(data?.createdby);
          getEventTime({
            eventid: data?.eventid,
          });
        }
        if (data?.eventid) {
          postDataRequestPublic(config.baseURLApi, {
            query: eventByData,
            variables: {
              eventid: data?.eventid,
            },
          }).then((res) => {
            setDataLoading(false);
            setEventData(res?.data?.eventdata);
            setTermDesc(res?.data?.eventdata?.termdesc);
            setEnableTerm(res?.data?.eventdata?.termenable);
            setEnablePublicLogo(res?.data?.eventdata?.isshowfooterlogo);
            setContent(JSON.parse(res?.data?.eventdata?.formdata));
            setBtnName(res?.data?.eventdata?.calloutbuttontext);
            setColorCode(res?.data?.eventdata?.themecolor);
            setRadioPos(res?.data?.eventdata?.radioview);
            setCheckBoxPos(res?.data?.eventdata?.checkboxview);
            setBackgroundColorCode(res?.data?.eventdata?.backgroundcolor);
            setForeColor(res?.data?.eventdata?.textcolor);
            if (res?.data?.eventdata?.btnthemecolor) {
              setBtnThemeColor(res?.data?.eventdata?.btnthemecolor);
              setBtnTextColor(res?.data?.eventdata?.btntextcolor);
            }
            setBtnPos(res?.data?.eventdata?.calloutbtnposition);
            setDataLoading(false);
          });
        }
        if (data?.locationid) {
          postDataRequestPublic(config.baseURLApi, {
            query: Entity,
            variables: { entityid: data?.locationid },
          })
            .then((res) => {
              if (res?.data?.entity) {
                setFavicon(res?.data?.entity?.favicon);
                setLocationBrandImage(res?.data?.entity?.logourl);
                setBid(res?.data?.entity?.parententityid);
                if (res?.data?.entity?.defaultphonecountrycode) {
                  setDefaultCountryPhoneCode(res?.data?.entity?.defaultphonecountrycode);
                }
              }
              setDataLoading(false);
            })
            .catch(() => {
              setDataLoading(false);
            });
        }
        if (data?.createdby) {
          postDataRequestPublic(config.baseURLApi, {
            query: BrandSettingByLocation,
            variables: {
              locationid: data?.createdby,
            },
          }).then((res) => setBrandImage(res?.data?.brandSettingByLocation?.logourl));
        }
        if (data?.createdby) {
          setDataLoading(true);
          postDataRequestPublic(config.baseURLApi, {
            query: Userprofilebyuser,
            variables: {
              userid: data?.createdby,
            },
          })
            .then((res) => {
              setProfileData(res?.data?.userprofilebyuser);
            });
        }
      });
      setDataLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      eventbyshorturlData();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const eventBookByData = () => {
    if (bookid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: eventBookingData,
        variables: {
          bookingid: bookid,
        },
      }).then((res) => {
        setDataLoading(false);
        setEventData(res?.data?.eventbookingdata?.event);
        setBookingId(res?.data?.eventbookingdata?.bookingid);
        setBookingDate(res?.data?.eventbookingdata?.bookingdate);
        setContactId(res?.data?.eventbookingdata?.contact?.contactid);
        setStartTime(res?.data?.eventbookingdata?.starttime);
        setEndTime(res?.data?.eventbookingdata?.endtime);
        setContact(res?.data?.eventbookingdata?.guestemail);
        if (res?.data?.eventbookingdata?.responsedata) {
          setData(JSON.parse(res?.data?.eventbookingdata?.responsedata));
        }
        setDataLoading(false);
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && bookid) {
      eventBookByData();
    }
    return () => {
      isMounted = false;
    };
  }, [bookid]);

  const getContact = (cid) => {
    if (cid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
        query contact($contactid: ID!){
          contact(contactid:$contactid){
            contactid,primaryemail,firstname,lastname,contactavatar,phonenumber,lifecyclestage,locationid,
            linkedin,createdby,updatedby,jobid,ownerid,createdAt,updatedAt,istargetlist,ispininchat,organization{
              organizationid,name,orgwebsite
            }
      }}
      `,
        variables: {
          contactid: cid,
        },
      }).then((res) => {
        if (res?.data?.contact) {
          setContactData(res?.data?.contact);
        }
      });
    }
  };

  const getOrganization = (oid) => {
    if (oid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
              query organization($organizationid: ID!){
                organization(organizationid: $organizationid){
                      organizationid,name,locationid,orgphonenumber,orgwebsite,orgtwitter,orgfacebook,orgstatus,categories,employeesize,
                      yearsinbusiness,revenue,businessstartdate,typeofentity,orgmap,orglinkedin,orggmb,orgemail,orgyoutubeurl,
                      address{addressid,street1,street2,city,county,stateid,countryid,zip,country{countryid,name},state{stateid,name}},orgyelp,orgtripadvisor,
                      createdby,updatedby,phonecountryid,createdAt,updatedAt,orgattribute1,orgattribute2,orgattribute3,orgattribute4,
                  }
              }`,
        variables: {
          organizationid: oid,
        },
      }).then((res) => {
        if (res?.data?.organization) {
          setOrganizationData(res?.data?.organization);
        }
      });
    }
  };

  const handleActiveTabs = (tab) => {
    if (tab < activeTabId) {
      setActiveTabId(tab);
    } else if (activeTabId === 0) {
      setActiveTabId(tab);
      setDataLoading(true);
      if (bookid || bookingId) {
        postDataRequestPublic(config.baseURLApi, {
          query: editBooking,
          variables: {
            bookingid: bookid || bookingId,
            bookingdate: bookingDate || moment(value).format('YYYY-MM-DD'),
            starttime: `${moment(`${moment(value).format('YYYY-MM-DD')}${' '}${startTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')}`,
            endtime: `${moment(`${moment(value).format('YYYY-MM-DD')}${' '}${endTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')}`,
            meetingduration: eventData?.eventduration,
            eventid: eventId,
            timezone: timeZone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
            locationid: lid,
            updatedby: bookid ? user ? uid : contactId : uid,
          },
        }).then((res) => {
          if (res?.data?.editbooking) {
            setDataLoading(false);
          }
        });
      } else {
        postDataRequestPublic(config.baseURLApi, {
          query: bookEvent,
          variables: {
            bookingdate: moment(value).format('YYYY-MM-DD'),
            meetingduration: eventData?.eventduration,
            starttime: `${moment(`${moment(value).format('YYYY-MM-DD')}${' '}${startTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')}`,
            endtime: eventData?.eventduration === 1440 ? `${moment(value)
              .add(1440, 'minutes').format('YYYY-MM-DDTHH:mm:ss')}` : `${moment(`${moment(value).format('YYYY-MM-DD')}${' '}${endTime}`,
              'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')}`,
            eventid: eventId,
            timezone: timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            locationid: lid,
            createdby: uid,
          },
        }).then((res) => {
          if (res?.data?.bookevent) {
            setDataLoading(false);
            setBookingId(res?.data?.bookevent?.bookingid);
            setBookingDate(res?.data?.bookevent?.bookingdate);
            setDataLoading(false);
          }
        });
      }
    } else if (activeTabId === 1) {
      setActiveTabId(tab);
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: CreateContactOrg,
        variables: {
          responsedata: JSON.stringify(submittedData),
          eventid: eventId,
          locationid: lid,
          businessid: bid,
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.createcontactorg) {
          const result = res?.data?.createcontactorg;
          if (result) {
            if (result.contactid) {
              getContact(result.contactid);
            } else if (result.organizationid) {
              getOrganization(result.organizationid);
            }
            postDataRequestPublic(config.baseURLApi, {
              query: editBooking,
              variables: {
                eventid: eventId,
                bookingdate: moment(value).format('YYYY-MM-DD'),
                bookingid: bookingId || bookid,
                starttime: `${moment(`${moment(value).format('YYYY-MM-DD')}${' '}${startTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')}`,
                endtime: `${moment(`${moment(value).format('YYYY-MM-DD')}${' '}${endTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')}`,
                timezone: timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
                contactid: result?.contactid,
                issubmit: eventData.ispaidevent !== true,
                isrescheduled: !!bookid,
                paymentid: paymentId || null,
                guestemail: contact,
                organizationid: result?.organizationid,
                responsedata: JSON.stringify(submittedData),
                updatedby: bookid ? user ? uid : contactId : uid,
              },
            }).then((res) => {
              if (res?.data?.editbooking) {
                setDataLoading(false);
                if (eventData.ispaidevent !== true) {
                  setActiveTabId(3);
                  showNotification('Data updated successfully', 'success');
                }
                setDataLoading(false);
              }
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && paymentId) {
      postDataRequestPublic(config.baseURLApi, {
        query: editBooking,
        variables: {
          eventid: eventId,
          bookingdate: moment(value).format('YYYY-MM-DD'),
          bookingid: bookingId || bookid,
          issubmit: true,
          paymentid: paymentId,
          updatedby: bookid ? user ? uid : contactId : uid,
        },
      }).then((res) => {
        if (res?.data?.editbooking) {
          setDataLoading(false);
          showNotification('Data updated successfully', 'success');
          setDataLoading(false);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [paymentId]);

  const onFormSubmit = (data) => {
    setClick(true);
    setData(data);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && submittedData && onClick) {
      if (eventData.ispaidevent === true) {
        (activeTabId === 2
          ? handleActiveTabs(2)
          : handleActiveTabs(
            activeTabId + 1,
          ));
      } else {
        handleActiveTabs(
          activeTabId + 1,
        );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [submittedData, onClick]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getContact(contactid);
    }
    return () => {
      isMounted = false;
    };
  }, [contactid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && contactData) {
      if (content?.length > 0) {
        const arr = [];
        content?.map((con) => {
          const obj = {};
          if (con.objecttype !== 'Designing') {
            obj.name = `${con?.objecttype}_${con?.name}`;
            obj.custom_name = `${con?.objecttype}_${con?.name}`;
            obj.dbfieldname = con?.dbfieldname;
            if (contactData && contactData[con?.dbfieldname]) {
              obj.value = contactData[con?.dbfieldname];
            } else if (contactData && contactData.organization
              && contactData?.organization[con?.dbfieldname]) {
              obj.value = contactData?.organization[con?.dbfieldname];
            }
            arr.push(obj);
          }
        });
        setData(arr);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [contactData, content]);

  return (
    <>
      <center>
        <BusinessFavicon
          name={eventData?.name}
          logo={favicon || locationBrandImage || brandImage}
        />
      </center>
      <Stepper
        style={{ display: 'none' }}
        classes={{ root: classes.paddingStepLabel }}
        activeStep={activeTabId}
        className={classes.stepper}
        alternativeLabel={matches}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{
                label: classes.stepLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.stepIcon,
                  completed:
                    classes.completed,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {dataLoading ? <LoadingPage /> : <></>}
      {eventData?.status === ActiveAppointmentStatus?.inactive
        ? (
          <div className={classes.root}>
            <div className={classes.paper}>
              <Paper className={classes.cal} elevation={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {locationBrandImage && eventData?.isshowlogo === true ? (
                      <>
                        <center>
                          <img
                            className={classes.brandImage}
                            src={locationBrandImage}
                            alt="brand logo"
                          />
                        </center>
                      </>
                    ) : (
                      brandImage && eventData?.isshowlogo === true ? (
                        <center>
                          <img
                            className={classes.brandImage}
                            src={brandImage}
                            alt="brand logo"
                          />
                        </center>
                      ) : ''
                    )}
                    {eventData?.isshowuseravatar === true ? (
                      <div className={classes.inActiveProfileImg}>
                        <Avatar
                          className={classes.inActiveimg}
                          alt={profileData?.firstname}
                          src={profileData && profileData?.avatar}
                        />
                      </div>
                    ) : ''}
                    <Box className={classes.profile}>
                      <center>
                        <Typography variant="h6">
                          {eventData?.createdUser?.firstname}
                          {' '}
                          {eventData?.createdUser?.lastname}
                          {' '}
                          is out of office and not taking any further meeting.
                        </Typography>
                      </center>
                    </Box>
                  </Grid>
                </Grid>
                {enablePublicLogo ? <BixpoweredBy id={lid || locationId} /> : ''}
              </Paper>
            </div>
          </div>
        )
        : (
          <>
            <div className={classes.root}>
              {activeTabId === 0 && (
                <>
                  <div className={classes.paper}>
                    <Paper className={classes.cal} elevation={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                          {locationBrandImage && eventData?.isshowlogo === true ? (
                            <>
                              <center>
                                <img
                                  className={classes.brandImage}
                                  src={locationBrandImage}
                                  alt="brand logo"
                                />
                              </center>
                            </>
                          ) : (
                            brandImage && eventData?.isshowlogo === true ? (
                              <center>
                                <img
                                  className={classes.brandImage}
                                  src={brandImage}
                                  alt="brand logo"
                                />
                              </center>
                            ) : ''
                          )}
                          {eventData?.isshowuseravatar === true ? (
                            <div className={classes.profileImg}>
                              <Avatar
                                className={classes.img}
                                alt={profileData?.firstname}
                                src={profileData && profileData?.avatar}
                              />
                            </div>
                          ) : ''}
                          <Box className={classes.profile}>
                            <Typography className={classes.heading} variant="body2" color="primary">
                              Meeting With
                            </Typography>
                            <Typography className={classes.heading} variant="h6">
                              {eventData?.createdUser?.firstname}
                              {' '}
                              {eventData?.createdUser?.lastname}
                            </Typography>
                            <Box className={classes.SocialIcon}>
                              {profileData?.phonenumber && eventData?.isshowphoneicon === true ? (
                                <IconButton
                                  className={classes.Button}
                                  size="small"
                                  href={`tel:${profileData?.phonenumber}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <PhoneIcon style={{ fontSize: 25, color: '#072cfe' }} />
                                </IconButton>
                              ) : ''}
                              {profileData?.phonenumber
                                && eventData?.isshowwhatsappicon === true ? (
                                  <IconButton
                                    className={classes.Button}
                                    size="small"
                                    href={`${`https://api.whatsapp.com/send?phone=${handleUndefined(profileData?.phonenumber)}`}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <WhatsAppIcon style={{ fontSize: 25, color: '#3cd4a0' }} />
                                  </IconButton>
                                ) : ''}
                              {profileData?.phonenumber && eventData?.isshowiosicon === true ? (
                                <IconButton
                                  className={classes.ButtonPhone}
                                  size="small"
                                  href={`sms:${profileData?.phonenumber}&amp;body=`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <AppleIcon style={{ fontSize: 25, color: '#607d8b' }} />
                                </IconButton>
                              ) : ''}
                              {profileData?.phonenumber && eventData?.isshowandroidicon === true ? (
                                <IconButton
                                  className={classes.ButtonPhone}
                                  size="small"
                                  href={`sms:${profileData?.phonenumber}?body=`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <AndroidIcon style={{ fontSize: 25, color: '#ffc260' }} />
                                </IconButton>
                              ) : ''}
                            </Box>
                            <br />
                            <Typography className={classes.heading} variant="h1">{eventData?.name}</Typography>
                            <br />
                            <Typography className={classes.heading} variant="body1">
                              <Box display="flex">
                                <AccessTimeIcon />
                                &nbsp;
                                {eventData?.eventduration === 1440 ? 'Full Day' : `${eventData?.eventduration} Min`}
                              </Box>
                            </Typography>
                            <br />
                            {eventData?.ispaidevent === true && (
                              <Typography className={classes.heading} variant="body1">
                                <Box display="flex">
                                  <MonetizationOnIcon />
                                  &nbsp;
                                  $
                                  {eventData?.amount}
                                </Box>
                              </Typography>
                            )}
                            <br />
                            <Typography className={classes.heading} variant="body1">
                              <Box display="flex">
                                <VideocamIcon />
                                &nbsp;
                                <div className={classes.headingLocation}>
                                  {eventData?.islinkvisible
                                    ? eventData?.location
                                    : eventData?.platform}
                                </div>
                              </Box>
                            </Typography>
                            <br />
                            <Typography className={classes.heading} variant="body1" dangerouslySetInnerHTML={{ __html: eventData?.description }} />
                          </Box>
                        </Grid>

                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} md={6}>
                          <Box className={classes.Calendar}>
                            <Box className={classes.details}>
                              <Typography variant="h1">Select a Date & Time</Typography>
                              <br />
                              <TimezoneSelect
                                value={timeZone}
                                onChange={(e) => {
                                  setTimeZone(e?.value);
                                  getEventTime({
                                    timeZone: e?.value,
                                  });
                                }}
                              />

                            </Box>
                            <br />
                            <Grid container>
                              <Grid item lg={8} md={10} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                <Calendar
                                  classNames={classes.CalendarDate}
                                  onChange={(date) => {
                                    getEventTime({
                                      day: date,
                                    });
                                    setValue(date);
                                  }}
                                  select
                                  date={value}
                                  color="#3d91ff"
                                  value={value}
                                  minDate={moment().toDate()}
                                />
                              </Grid>
                              <Grid item lg={4} md={2} sm={12} xs={12}>
                                <Box className={classes.time}>
                                  {value ? (
                                    <>
                                      <Box>
                                        {timeData && timeData?.length === 0 ? (
                                          <Typography variant="body1">No Time available</Typography>
                                        ) : (
                                          <>
                                            <Typography variant="body1">
                                              {moment(value).format('MMMM Do YYYY')}
                                            </Typography>
                                            <br />
                                            {timeData?.length >= 0 && timeData?.map((item) => (
                                              <Grid container spacing={1}>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={startTime === item?.starttime ? 12 : 12}
                                                >
                                                  <Button
                                                    onClick={() => {
                                                      setStartTime(item?.starttime);
                                                      setEndTime(item?.endtime);
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{ fontSize: 13 }}
                                                  >
                                                    {eventData?.eventduration === 1440 ? ' Full Day' : `${item?.starttime}
                                                ${' '}
                                                -
                                                ${' '}
                                                ${item?.endtime}`}
                                                  </Button>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={startTime === item?.starttime ? 12 : 12}
                                                >
                                                  {startTime === item?.starttime
                                                    ? (
                                                      <Button
                                                        onClick={() => {
                                                          if (eventData.ispaidevent === true) {
                                                            (activeTabId === 2
                                                              ? handleActiveTabs(2)
                                                              : handleActiveTabs(
                                                                activeTabId + 1,
                                                              ));
                                                          } else {
                                                            handleActiveTabs(
                                                              activeTabId + 1,
                                                            );
                                                          }
                                                        }}
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                      >
                                                        Confirm
                                                      </Button>
                                                    ) : ''}
                                                </Grid>
                                              </Grid>

                                            ))}
                                          </>
                                        )}
                                      </Box>
                                    </>
                                  ) : ''}
                                </Box>
                              </Grid>
                            </Grid>

                          </Box>
                        </Grid>
                      </Grid>
                      {enablePublicLogo ? <BixpoweredBy id={lid || locationId} /> : ''}
                    </Paper>
                  </div>
                </>
              )}
              {activeTabId === 1 && (
                <>
                  <div className={classes.paper}>
                    <Paper className={classes.cal} elevation={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={5}>
                          <Box className={classes.backMenu}>
                            {activeTabId !== 0 && (
                              <Link
                                href
                                onClick={() => {
                                  (activeTabId === 0
                                    ? handleActiveTabs(0)
                                    : handleActiveTabs(
                                      activeTabId - 1,
                                    ));
                                }}
                              >
                                <Tooltip title="Back">
                                  <IconButton aria-label="back" size="medium">
                                    <ArrowBackIosIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            )}
                            <Typography className={classes.heading} variant="h6">
                              {eventData?.createdUser?.firstname}
                              {' '}
                              {eventData?.createdUser?.lastname}
                            </Typography>
                          </Box>
                          <br />
                          {locationBrandImage ? (
                            <img
                              className={classes.brandImage}
                              src={locationBrandImage}
                              alt="brand logo"
                            />
                          ) : (
                            brandImage ? (
                              <img
                                className={classes.brandImage}
                                src={brandImage}
                                alt="brand logo"
                              />
                            ) : ''
                          )}
                          <br />
                          <br />
                          <Typography className={classes.heading} variant="h1">{eventData?.name}</Typography>
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex" alignItems="center">
                              <AccessTimeIcon />
                              &nbsp;
                              {eventData?.eventduration === 1440 ? 'Full Day' : `${eventData?.eventduration} Min`}
                            </Box>
                          </Typography>
                          <br />
                          {eventData?.ispaidevent === true && (
                            <Typography className={classes.heading} variant="body1">
                              <Box display="flex">
                                <MonetizationOnIcon />
                                &nbsp;
                                $
                                {eventData?.amount}
                              </Box>
                            </Typography>
                          )}
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex">
                              <VideocamIcon />
                              &nbsp;
                              <div className={classes.headingLocation}>
                                {eventData?.islinkvisible
                                  ? eventData?.location
                                  : eventData?.platform}
                              </div>
                            </Box>
                          </Typography>
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex" alignItems="center">
                              <CalendarTodayIcon />
                              &nbsp;&nbsp;
                              {eventData?.eventduration === 1440 ? 'Full Day' : `${moment(startTime, config.timeformate).format(config.timeformate)}
                             
                              -
                           
                              ${moment(endTime, config.timeformate).format(config.timeformate)}`}
                              ,
                              &nbsp;
                              {moment(value).format('MMM Do YYYY') || bookingDate}
                              &nbsp;
                            </Box>
                          </Typography>
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex" alignItems="center">
                              <PublicIcon />
                              &nbsp;&nbsp;
                              {timeZone}
                            </Box>
                          </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <br />
                        <Grid item xs={12} md={6}>
                          <Suspense fallback={<LazyLoader />}>
                            <FormGenerator
                              onFormSubmit={onFormSubmit}
                              content={content}
                              defaultCountryPhoneCode={defaultCountryPhoneCode}
                              rows={6}
                              btnName={btnName}
                              colorCode={colorCode}
                              backgroundColorCode={backgroundColorCode}
                              foreColor={foreColor}
                              btnPos={btnPos}
                              radioPos={radioPos}
                              checkBoxPos={checkBoxPos}
                              btnThemeColor={btnThemeColor}
                              btnTextColor={btnTextColor}
                              termDesc={termDesc}
                              enableTerm={enableTerm}
                              visible={visible}
                              setVisible={setVisible}
                              suggestContact={suggestContact}
                              contact={contact}
                              setContact={setContact}
                              formData={submittedData}
                              eventType="single-page"
                              isAppointment
                              isAppointmentPublic
                            />
                          </Suspense>
                        </Grid>
                      </Grid>
                      {enablePublicLogo ? <BixpoweredBy id={lid || locationId} /> : ''}
                    </Paper>
                  </div>
                </>
              )}
              {activeTabId === 2 && eventData?.ispaidevent === true && (
                <>
                  <div className={classes.paper}>
                    <Paper className={classes.cal} elevation={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={5}>
                          <Box className={classes.backMenu}>
                            {activeTabId !== 0 && (
                              <Link
                                href
                                onClick={() => {
                                  (activeTabId === 0
                                    ? handleActiveTabs(0)
                                    : handleActiveTabs(
                                      activeTabId - 1,
                                    ));
                                }}
                              >
                                <Tooltip title="Back">
                                  <IconButton aria-label="back" size="medium">
                                    <ArrowBackIosIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            )}
                            <Typography className={classes.heading} variant="h6">
                              {eventData?.createdUser?.firstname}
                              {' '}
                              {eventData?.createdUser?.lastname}
                            </Typography>
                          </Box>
                          <br />

                          {locationBrandImage ? (
                            <img
                              className={classes.brandImage}
                              src={locationBrandImage}
                              alt="brand logo"
                            />
                          ) : (
                            brandImage ? (
                              <img
                                className={classes.brandImage}
                                src={brandImage}
                                alt="brand logo"
                              />
                            ) : ''
                          )}
                          <br />
                          <br />
                          <Typography className={classes.heading} variant="h1">{eventData?.name}</Typography>
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex" alignItems="center">
                              <AccessTimeIcon />
                              &nbsp;
                              {eventData?.eventduration === 1440 ? 'Full Day' : `${eventData?.eventduration} Min`}
                            </Box>
                          </Typography>
                          <br />
                          {eventData?.ispaidevent === true && (
                            <Typography className={classes.heading} variant="body1">
                              <Box display="flex">
                                <MonetizationOnIcon />
                                &nbsp;
                                $
                                {eventData?.amount}
                              </Box>
                            </Typography>
                          )}
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex">
                              <VideocamIcon />
                              &nbsp;
                              <div className={classes.headingLocation}>
                                {eventData?.islinkvisible
                                  ? eventData?.location
                                  : eventData?.platform}
                              </div>
                            </Box>
                          </Typography>
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex" alignItems="center">
                              <CalendarTodayIcon />
                              &nbsp;&nbsp;
                              {eventData?.eventduration === 1440 ? 'Full Day' : `${moment(startTime, config.timeformate).format(config.timeformate)}
                             
                              -
                           
                              ${moment(endTime, config.timeformate).format(config.timeformate)}`}
                              ,
                              &nbsp;
                              {moment(value).format('MMM Do YYYY') || bookingDate}
                              &nbsp;
                            </Box>
                          </Typography>
                          <br />
                          <Typography className={classes.heading} variant="body1">
                            <Box display="flex" alignItems="center">
                              <PublicIcon />
                              &nbsp;&nbsp;
                              {timeZone}
                            </Box>
                          </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Payment
                          appointment
                          userId={uid}
                          businessId={bid}
                          locationId={lid}
                          contactData={contactData}
                          organizationData={organizationData}
                          objectId={bookid || bookingId}
                          amount={eventData?.amount}
                          setPaymentId={setPaymentId}
                          setActiveTabId={setActiveTabId}
                          handleActiveTabs={handleActiveTabs}
                        />
                      </Grid>
                      {enablePublicLogo ? <BixpoweredBy id={lid || locationId} /> : ''}
                    </Paper>
                  </div>
                </>
              )}
              {activeTabId === 3 && (
                <>
                  <div className={classes.paper}>
                    <Paper className={classes.cal} elevation={6}>
                      <center>
                        <Grid container spacing={2} justifyContent="center" alignContent="center">
                          <Grid item xs={12}>
                            {locationBrandImage ? (
                              <img
                                className={classes.brandImage}
                                src={locationBrandImage}
                                alt="brand logo"
                              />
                            ) : (
                              brandImage ? (
                                <img
                                  className={classes.brandImage}
                                  src={brandImage}
                                  alt="brand logo"
                                />
                              ) : ''
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h1">Confirmed</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              You are scheduled with
                              {' '}
                              {eventData?.createdUser?.firstname}
                              {' '}
                              {eventData?.createdUser?.lastname}
                              .
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider variant="middle" />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h1">{eventData?.name}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              <Box display="inline-flex" alignItems="center">
                                <CalendarTodayIcon />
                                &nbsp;
                                <Typography variant="body2">
                                  {eventData?.eventduration === 1440 ? 'Full Day' : `${moment(startTime, config.timeformate).format(config.timeformate)}
                             
                             -
                          
                             ${moment(endTime, config.timeformate).format(config.timeformate)}`}
                                  ,
                                  &nbsp;
                                  {moment(value).format('MMM Do YYYY') || bookingDate}
                                </Typography>
                              </Box>
                            </Typography>
                          </Grid>
                          {eventData?.ispaidevent === true && (
                            <Grid item xs={12}>
                              <Typography className={classes.heading} variant="body1">
                                <Box display="inline-flex" alignItems="center">
                                  <MonetizationOnIcon />
                                  &nbsp;
                                  $
                                  {eventData?.amount}
                                </Box>
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              <Box display="inline-flex" alignItems="center">
                                <PublicIcon />
                                &nbsp;&nbsp;
                                {timeZone}
                              </Box>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography className={classes.headingLocation} variant="body2" alignItems="center">
                                <VideocamIcon className={classes.videoIcon} />
                                &nbsp;
                                {eventData?.islinkvisible
                                  ? eventData?.location
                                  : eventData?.platform}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              A calendar invitation has been sent to your email address.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider variant="middle" />
                          </Grid>
                        </Grid>
                      </center>
                      {enablePublicLogo ? <BixpoweredBy id={lid || locationId} /> : ''}
                    </Paper>
                  </div>
                </>
              )}
            </div>
          </>
        )}
    </>
  );
}
