/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import BusinessFavicon from '../../common/BusinessFavicon';
import { postDataRequestPublic, handleUndefined, ValidateSubdomain } from '../../common/commonfunctions';
import { ContentMarketingbyshorturl } from '../../Api Mutation/Campaign';
import { Entitybysubdomain, Entity, BrandSettingByLocation } from '../../Api Mutation/EntityApi';
import PoweredBy from '../../common/PoweredBy';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import config from '../../config';
import Error from '../error/Error';

export default function PublicContectDomain(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const {
    lid, content, bid,
  } = useParams([]);
  const SubDomainValidate = ValidateSubdomain();
  const [brandImage, setBrandImage] = useState();
  const [emailTemplateHtml, setEmailTemlateHtml] = useState();
  const [favicon, setFavicon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [contentMarketingId, setContentMarketingId] = useState('');
  const [title, setTitle] = useState();
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [startDate, setStartDate] = useState(moment().format());
  const [locationId, setLocationId] = useState();
  const ref = useRef(null);
  const { onClickOutside } = props;
  const host = window.location.hostname;
  const getHostName = host.split('.');

  const getDomainByData = () => {
    if (host !== config.DOMAIN || host !== config.stagingDomain) {
      const DOMAIN = Entitybysubdomain;
      postDataRequestPublic(config.baseURLApi, {
        query: DOMAIN,
        variables: { subdomain: getHostName[0] },
      }).then((res) => {
        if (res?.data.entitybysubdomain) {
          setLocationId(res?.data.entitybysubdomain?.entityid);
        }
      });
    }
  };

  const fetchData = async (prop) => {
    if (handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id)) {
      const CREATE_ACTIVITYCONTECTMARKETING = `
    mutation addActivityContentMarketing($contentmarketingnid: ID!, $openeddata: String, $clickeddata: String) {
      addActivityContentMarketing(contentmarketingnid: $contentmarketingnid, openeddata: $openeddata, clickeddata: $clickeddata)
    }`;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITYCONTECTMARKETING,
        variables: {
          contentmarketingnid: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id),
          openeddata: prop?.opendata,
          clickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getDataBytemplate = async () => {
    setStartDate(moment().format());
    if (lid || locationId) {
      await postDataRequestPublic(config.baseURLApi, {
        query: ContentMarketingbyshorturl,
        variables: {
          shorturl: content,
          locationid: lid || locationId,
        },
      }).then(async (res) => {
        if (res?.errors && res?.errors[0]?.message) {
          await setDataLoading(false);
          await showNotification(res?.errors[0]?.message, 'error');
          await setDataLoading(false);
        } else if (res?.data?.contentMarketingbyshorturl) {
          await setEnablePublicLogo(res?.data?.contentMarketingbyshorturl?.isshowfooterlogo);
          await setDataLoading(false);
          await setContentMarketingId(res?.data?.contentMarketingbyshorturl?.contentmarketingnid);
          await localStorage.setItem('cid', res?.data?.contentMarketingbyshorturl?.contentmarketingnid);
          await fetchData({
            id: res?.data?.contentMarketingbyshorturl?.contentmarketingnid,
            opendata: JSON.stringify({
              timestamp: startDate,
              timespent: 0,
            }),
          });
          await setEmailTemlateHtml(res?.data?.contentMarketingbyshorturl?.contenttemplate);
          await setTitle(res?.data?.contentMarketingbyshorturl?.name);
          await setDataLoading(false);
        }
      });
    }
  };

  const entityData = () => {
    if (lid || locationId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: lid || locationId },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setFavicon(res?.data?.entity?.favicon);
            setLocationBrandImage(res?.data?.entity?.logourl);
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setDataLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          onClickOutside && onClickOutside();
        } else if (e?.target?.parentNode?.localName === 'a' && e?.target?.parentNode?.href) {
          const clickedData = {
            timestamp: new Date(),
            url: e?.target?.parentNode?.href,
            name: e?.target?.parentNode?.innerText,
          };
          fetchData({
            id: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId),
            click: clickedData,
          });
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
    return () => {
      isMounted = false;
    };
  }, [onClickOutside]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getDataBytemplate();
      if (lid || locationId) {
        entityData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [bid, lid, locationId]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.onbeforeunload = async (event) => {
        const message = 'Are you sure to exit this page ?';
        const e = event || window.event;
        await e.preventDefault();
        if (e) {
          e.returnValue = message;
        }
        return fetchData({
          opendata: JSON.stringify({
            timestamp: startDate,
            timespent: moment(new Date())?.diff(startDate, 'seconds'),
          }),
        });
      };
      if (!SubDomainValidate) {
        getDomainByData();
      }
      fetchData({
        opendata: JSON.stringify({
          timestamp: startDate,
          timespent: 0,
        }),
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {(!SubDomainValidate)
        ? (
          <div>
            {dataLoading ? <LoadingPage /> : ''}
            <BusinessFavicon
              logo={favicon || locationBrandImage || brandImage}
              name={title}
            />
            <center>
              <div ref={ref} dangerouslySetInnerHTML={{ __html: emailTemplateHtml }} />
            </center>
            {enablePublicLogo ? <PoweredBy id={lid} /> : ''}
          </div>
        ) : <Error />}
    </>
  );
}
