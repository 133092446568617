import React, { useEffect, useRef, useState } from 'react';
import {
  List, ListItem, ListItemIcon, ListItemText, Typography, Divider,
  useMediaQuery,
  IconButton,
  makeStyles,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  Tooltip,
  Popover,
  Grid,
  InputAdornment,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import {
  Mail, Send, ArrowBackSharp, Close, AccountCircle, DateRange, TuneRounded, BorderColor,
  Sync,
} from '@material-ui/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from 'materialui-daterange-picker';
import { useStyles } from './style';
import { MailBoxEmailList, SyncMailBox } from '../../Api Mutation/MailBoxApi';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import { useGlobalState } from '../../context/GlobalContext';
import { ContactEmail } from '../../Api Mutation/Contact';
import RowsPerPageCustom from '../../common/RowsPerPageCustom';
import PageNumberCustom from '../../common/PageNumberCustom';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { EmailServicesProvider, EmailType, EmailTypeStatus } from '../../common/const';
import ContactCRMedit from '../ContactCentral/ContactCRMedit';
import { showNotification } from '../../components/Notification/ToastifyNotifications';

const MailBox = () => {
  const globalContext = useGlobalState();
  const [showDetails, setShowDetails] = useState(false);
  const [emailType, setEmailType] = useState(2);
  const [emailStatus, setEmailStatus] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [contactData, setContactData] = useState();
  const [selectedEmailValue, setSelectedEmailValue] = useState();
  const [opencontacttrend, setOpenContactTrend] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD'),
  });
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment(new Date()).add(2, 'month').format('YYYY-MM-DD'));
  const classes = useStyles({ showDetails });
  const [activeTab, setActiveTab] = useState('reply');
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [searchText, setSearchText] = useState('');
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [mailList, setMailList] = useState([]);
  const history = useHistory();
  const formRef = useRef(null);
  const isMediumOrSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const locationId = JSON.parse(localStorage.getItem('location_Id'));
  const globalEntityId = globalContext?.globalState?.currentLocation?.entityid;
  const bixpand = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png';
  const Google = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/google.svg';
  const SMTP = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/SMTP.svg';
  const OutlookGoogle = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/outlook%201.svg';

  const toggle = () => setOpenContactTrend(!opencontacttrend);
  const handleCloseDate = () => {
    setDateAnchorEl(null);
    setOpenContactTrend(false);
  };

  function useMailBoxStyles() {
    return makeStyles(() => ({
      showEmailList: {
        display: showDetails ? 'none' : 'block',
      },
      showDetails: {
        display: showDetails ? 'block' : 'none',
        width: '100%',
      },
      deatils: {
        width: '75%',
      },
    }))();
  }
  const conditionClasses = useMailBoxStyles();

  const handleDateChange = (range) => {
    setSelectedDate({
      startDate: moment(range?.startDate).format('YYYY-MM-DD'),
      endDate: moment(range?.endDate).format('YYYY-MM-DD'),
    });
    setSelectedStartDate(moment(range?.startDate).format('YYYY-MM-DD'));
    setSelectedEndDate(moment(range?.endDate).format('YYYY-MM-DD'));
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const EmailDetails = ({ mainEmail }) => {
    if (!mainEmail) {
      return (
        <div className={classes.noDetails}>
          <Typography variant="subtitle1" className={classes.noDetailText} color="textSecondary">No email selected</Typography>
        </div>
      );
    }

    const {
      fromname, subject, replies, emailbody, scheduledatetime,
      fromemail, to, servicesprovider, contactid,
    } = mainEmail;

    return (
      <>
        <div className={classes.emailDetails}>
          <div>
            <div className={classes.senderDetails}>
              {servicesprovider === EmailServicesProvider?.GmailService ? (
                <img src={Google} alt="gmail" className={classes.logo} />
              ) : servicesprovider === EmailServicesProvider?.OutlookEmailService ? (
                <img src={OutlookGoogle} alt="outlook" className={classes.logo} />
              ) : servicesprovider === EmailServicesProvider?.SMTPService ? (
                <img src={SMTP} alt="SMTP" className={classes.logo} />
              ) : (<img src={bixpand} alt="bixpand" className={classes.logo} />)}
              <Box className={classes.info}>
                <Typography className={classes.subjectText}>{subject}</Typography>
                <Tooltip title="View Contact">
                  <IconButton onClick={() => handleOpen()}>
                    <AccountCircle color="primary" className={classes.personIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Dialog fullScreen open={open} onClose={handleClose}>
                {dataLoading ? <LoadingPage /> : <></>}
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                      Contact -
                      {' '}
                      {handleUndefined(contactData?.firstname !== null ? contactData?.firstname : '')}
                      {' '}
                      {handleUndefined(contactData?.lastname !== null ? contactData?.lastname : '')}
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                      <Close />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <List>
                  <ContactCRMedit
                    CRM
                    history={history}
                    usageContactId={contactid}
                    addDeal
                    editDeal
                    deleteDeal
                    viewBox={1}
                  />
                </List>
              </Dialog>
            </div>
            <Typography variant="subtitle1" color="textSecondary">{moment(new Date(parseInt(scheduledatetime, 10))).format('ddd, MMM D, YYYY h:mm A')}</Typography>
          </div>
          <hr className={classes.hrEmailBody} />
          <div className={classes.emailBox}>
            <Typography variant="subtitle1" color="textSecondary">
              <span className={classes.toSpan}>From: </span>
              {' '}
              {fromemail}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <span className={classes.toSpan}>To: </span>
              {' '}
              {to}
            </Typography>
          </div>
          <div>
            <Typography className={classes.senderText}>{fromname}</Typography>
            <hr className={classes.hrEmailBody} />
            <Typography variant="subtitle2" className={classes.emailBody} dangerouslySetInnerHTML={{ __html: emailbody }} />
            <hr className={classes.hrEmail} />
            {replies?.length > 0 && (
              <>
                {replies.map((repliedEmail) => (
                  <div
                    className={classes.repliedBox}
                    style={{
                      paddingTop: repliedEmail?.fromemail.includes(selectedEmailValue) ? '7px' : '', backgroundColor: repliedEmail?.fromemail.includes(selectedEmailValue) ? '#f7f7f7' : '',
                    }}
                  >
                    <div>
                      <div className={classes.senderDetails}>
                        {servicesprovider === EmailServicesProvider?.GmailService ? (
                          <img src={Google} alt="gmail" className={classes.logo} />
                        ) : servicesprovider === EmailServicesProvider?.OutlookEmailService ? (
                          <img src={OutlookGoogle} alt="outlook" className={classes.logo} />
                        ) : servicesprovider === EmailServicesProvider?.SMTPService ? (
                          <img src={SMTP} alt="SMTP" className={classes.logo} />
                        ) : (<img src={bixpand} alt="bixpand" className={classes.logo} />)}
                        <Typography className={classes.subjectText}>
                          {repliedEmail.subject}
                        </Typography>
                      </div>
                    </div>
                    <hr className={classes.hrEmailBody} />
                    <div className={classes.emailBox}>
                      <Typography variant="subtitle1" color="textSecondary">
                        <span className={classes.toSpan}>From: </span>
                        {' '}
                        {repliedEmail?.fromemail}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        <span className={classes.toSpan}>To: </span>
                        {' '}
                        {repliedEmail?.to}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.senderText}>
                        {repliedEmail?.fromname}
                      </Typography>
                      <hr className={classes.hrEmailBody} />
                      <Typography variant="subtitle2" className={classes.reEmailBody} dangerouslySetInnerHTML={{ __html: repliedEmail?.emailbody }} />
                      <hr className={classes.hrEmail} />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleShowDetail = () => {
    if (isMediumOrSmallScreen) {
      setShowDetails(false);
    }
  };

  const tabs = [
    { label: 'Inbox', icon: <Mail />, value: 'reply' },
    { label: 'Sent', icon: <Send />, value: 'sent' },
    { label: 'Draft', icon: <BorderColor />, value: 'draft' },
  ];

  const getEmailList = async (prop) => {
    setDataLoading(true);
    await postDataRequest(config.baseURLApi, {
      query: MailBoxEmailList,
      variables: {
        locationid: locationId || globalEntityId,
        emailtype: emailType,
        status: emailStatus,
        searchtext: searchText,
        subject: prop?.searchIncludeSubject || '',
        notSubject: prop?.searchNotIncludeSubject || '',
        to: prop?.searchTo || '',
        fromemail: prop?.searchFrom || '',
        emailbody: prop?.searchBody || '',
        pagenumber: currentPage,
        pagesize: rowsPerPage,
        startdate: moment(selectedDate?.startDate).format('YYYY-MM-DD') || selectedStartDate,
        enddate: moment(selectedDate?.endDate).format('YYYY-MM-DD') || selectedEndDate,
      },
    }).then((res) => {
      if (res?.data?.mailBoxEmailList?.rows) {
        setMailList(res?.data?.mailBoxEmailList?.rows);
        setDataLoading(false);
      }
      setTotalCount(res?.data?.mailBoxEmailList?.count);
    }).catch((err) => {
      if (err) {
        setDataLoading(false);
      }
    });
  };

  const getEmailDetails = async (emailId) => {
    setDataLoading(true);
    await postDataRequest(config.baseURLApi, {
      query: ContactEmail,
      variables: {
        emailid: emailId,
      },
    }).then((res) => {
      if (res.errors && res.errors[0].message) {
        showNotification(res.errors[0].message, 'error');
      } else if (res?.data?.contactEmail) {
        setSelectedEmail(res?.data?.contactEmail);
        setDataLoading(false);
      }
    }).catch((err) => {
      if (err) {
        setDataLoading(false);
      }
    });
  };

  const handleKeyPres = (event) => {
    if (event.key === 'Enter') {
      setSearchText(event.target.value);
    }
  };

  const handleEmail = (email) => {
    setSelectedEmailValue(email?.contact?.primaryemail);
    if (email?.contact) {
      setContactData(email?.contact);
    }
    if (email?.parentemailid || email?.emailid) {
      getEmailDetails(email?.parentemailid || email?.emailid);
    }
    if (isMediumOrSmallScreen) {
      setShowDetails(true);
    }
  };

  const handleOption = (e) => {
    setActiveTab(e.target.value);
    if (e.target.value === 'draft') {
      setEmailType(EmailType.sent);
      setEmailStatus(EmailTypeStatus.draft);
    } else if (e.target.value === 'sent') {
      setEmailType(EmailType.sent);
      setEmailStatus(EmailTypeStatus.sent);
    } else {
      setEmailType(EmailType.reply);
      setEmailStatus(null);
    }
    if (isMediumOrSmallScreen) {
      setShowDetails(false);
    }
  };

  const handleRowPage = (rowPage) => {
    setRowsPerPage(rowPage);
    setCurrentPage(1);
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFormSearch = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const formValues = Object.fromEntries(formData.entries());
    setSearchOpen(false);
    if (formValues) {
      getEmailList(formValues);
    }
  };

  const handleSync = async () => {
    setDataLoading(true);
    await postDataRequest(config.baseURLApi, {
      query: SyncMailBox,
      variables: {
        locationid: locationId,
      },
    }).then((res) => {
      if (res?.errors && res?.errors[0]?.message) {
        showNotification(res.errors[0].message, 'error');
      } else if (res?.data?.syncMailboxEmails) {
        if ((locationId || globalEntityId)) {
          getEmailList();
        }
        showNotification('Data Synced Successfully', 'success');
      }
    });
    setDataLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if ((locationId || globalEntityId) && isMounted) {
      getEmailList();
    }
    return () => {
      isMounted = false;
    };
  }, [locationId || globalEntityId, currentPage, rowsPerPage, searchText,
    emailType, selectedDate, emailStatus]);

  return (
    <>
      {dataLoading ? <LoadingPage /> : ''}
      <Box className={classes.fieldBox}>
        <div className={classes.headerTitle}  style={{
                                                    color: 'black !important',
                                                    display: 'flex',
                                                    background: '#e2edf5',
                                                    alignItems: 'center',
                                                    padding: '5px 14px',
                                                    borderRadius: '16px',
                                                    justifyContent: 'center',
                                                    marginRight: 15,
                                                }}>
          {activeTab === 'reply'
            ? (
              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/mailbox_1.svg" className={classes.svgStyle} alt="mail" />
            )
            : activeTab === 'sent'
              ? (
                <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/mailbox_2.svg" className={classes.frwdSvgStyle} alt="mail" />
              )
              : (
                <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/mailbox_3.svg" alt="mail" />
              )}
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              color="primary"
              className={classes.filterSelect}
              value={activeTab}
              onChange={(e) => handleOption(e)}
            >
              {tabs.map((tab) => (
                <MenuItem
                  className={classes.filterMenuItem}
                  value={tab.value}
                >
                  {tab.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Box>
          <TextField
            variant="outlined"
            className={classes.searchField}
            placeholder="Press Enter to Search"
            size="small"
            onKeyPress={(e) => handleKeyPres(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box className={classes.endAdornmentBox}>
                    <Tooltip title="Advanced Search">
                      <IconButton size="small" onClick={() => setSearchOpen(true)}>
                        <TuneRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Sync">
                      <IconButton size="small" onClick={() => handleSync()}>
                        <Sync fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <Dialog
            open={searchOpen}
            fullWidth
            maxWidth="sm"
            onClose={() => setSearchOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Advanced Search</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <form ref={formRef} onSubmit={handleFormSearch}>
                <Box>
                  <TextField
                    name="searchFrom"
                    variant="outlined"
                    label="From"
                    fullWidth
                    size="small"
                    className={classes.searchFieldAdvance}
                  />
                </Box>
                <Box>
                  <TextField
                    name="searchTo"
                    variant="outlined"
                    label="To"
                    fullWidth
                    size="small"
                    className={classes.searchFieldAdvance}
                  />
                </Box>
                <Box>
                  <TextField
                    name="searchIncludeSubject"
                    variant="outlined"
                    label="Include In Subject"
                    fullWidth
                    size="small"
                    className={classes.searchFieldAdvance}
                  />
                </Box>
                <Box>
                  <TextField
                    name="searchNotIncludeSubject"
                    variant="outlined"
                    label="Not Include In Subject"
                    fullWidth
                    size="small"
                    className={classes.searchFieldAdvance}
                  />
                </Box>
                <Box>
                  <TextField
                    name="searchBody"
                    variant="outlined"
                    label="Body"
                    fullWidth
                    size="small"
                    className={classes.searchFieldAdvance}
                  />
                </Box>
                <Box className={classes.btnWrapper}>
                  <Button type="submit" variant="contained" size="small" color="primary">
                    Search
                  </Button>
                  <Button variant="contained" size="small" color="secondary" className={classes.cnclBtn} onClick={() => setSearchOpen(false)}>
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
        <Box className={classes.featureWrapper}>
          <Box className={classes.featureBox}>
            <Grid container alignItems="center">
              <Grid item sm={12} lg={6} justifyContent="flex-end">
                <Box className={classes.newdiv}>
                  <Box className={classes.dateText}>
                    <Typography variant="body1">
                      {selectedDate?.startDate ? moment(selectedDate?.startDate).format('MMM Do YYYY') : moment().subtract(1, 'month').format('MMM Do YYYY')}
                      {' '}
                      -
                      {' '}
                      {selectedDate?.endDate ? moment(selectedDate?.endDate).format('MMM Do YYYY') : moment().add(2, 'month').format('MMM Do YYYY')}
                    </Typography>
                  </Box>
                  <Tooltip title="Date Range">
                    <IconButton
                      onClick={(event) => {
                        setOpenContactTrend(true);
                        setDateAnchorEl(event.currentTarget);
                      }}
                    >
                      <img
                        className={classes.themeText}
                        width="40px"
                        src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/calendar%20(2).svg"
                        alt="template"
                      />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    open={opencontacttrend}
                    anchorEl={dateAnchorEl}
                    onClose={handleCloseDate}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <DateRangePicker
                      open={opencontacttrend}
                      value={selectedDate}
                      toggle={toggle}
                      onChange={(range) => {
                        handleDateChange(range);
                      }}
                    />
                  </Popover>
                </Box>
              </Grid>
              <Grid item sm={12} lg={6} className={classes.newdivPage}>
                <Box className={classes.newdiv}>
                  <RowsPerPageCustom
                    onPageSizeChanged={(e) => handleRowPage(e.target.value)}
                    rowPerPageValue={rowsPerPage}
                    disableBoolean={!(mailList.length > 0)}
                  />
                  <PageNumberCustom
                    handlePrev={handlePrevPage}
                    handleNext={handleNextPage}
                    currentPage={currentPage}
                    lastPage={Math.ceil(totalCount / rowsPerPage)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <div className={classes.root}>
        <div className={classes.content} style={{ display: showDetails ? 'none' : 'block' }}>
          {mailList.length > 0 ? (
            <List className={isMediumOrSmallScreen ? conditionClasses.showEmailList : ''}>
              {mailList.map((email, index) => (
                <>
                  <ListItem button onClick={() => handleEmail(email)} key={index}>
                    <ListItemIcon>
                      {email?.servicesprovider === EmailServicesProvider?.GmailService ? (
                        <img src={Google} alt="gmail" className={classes.logo} />
                      ) : email?.servicesprovider === EmailServicesProvider?.OutlookEmailService ? (
                        <img src={OutlookGoogle} alt="outlook" className={classes.logo} />
                      ) : email?.servicesprovider === EmailServicesProvider?.SMTPService ? (
                        <img src={SMTP} alt="SMTP" className={classes.logo} />
                      ) : (<img src={bixpand} alt="bixpand" className={classes.logo} />)}
                    </ListItemIcon>
                    <ListItemText
                      primary={`${email?.contact !== null ? (email?.contact?.firstname !== null ? email?.contact?.firstname : '') : ''} ${email?.contact !== null ? (email?.contact?.lastname !== null ? email?.contact?.lastname : '') : ''}`}
                      secondary={(
                        <Box className={classes.primaryText}>
                          <Typography variant="subtitle1" className={classes.subject} color="textSecondary">
                            {email?.subject.length > 30 ? email?.subject.slice(0, 30)
                              : email?.subject}
                            {email?.subject.length > 30 ? '...' : ''}
                          </Typography>
                          <Typography variant="subtitle1" className={classes.date} color="textSecondary">{moment(new Date(parseInt(email?.scheduledatetime, 10))).format('ddd, MMM D, YYYY h:mm A')}</Typography>
                        </Box>
                      )}
                    />
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          ) : (
            <List className={isMediumOrSmallScreen ? conditionClasses.showEmailList : ''}>
              <ListItem>
                <ListItemText>No Data to Show</ListItemText>
              </ListItem>
            </List>
          )}
        </div>
        <div className={isMediumOrSmallScreen ? conditionClasses.showDetails
          : conditionClasses.deatils}
        >
          {isMediumOrSmallScreen ? (
            <IconButton onClick={() => handleShowDetail()} className={classes.arrowBtn}>
              <ArrowBackSharp />
            </IconButton>
          ) : ''}
          <EmailDetails mainEmail={selectedEmail} />
        </div>
      </div>
    </>
  );
};

export default MailBox;
