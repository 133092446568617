import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  fieldBox: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  field: {
    width: '100%',
  },
  disclaimer: {
    fontSize: 10,
    marginTop: 3,
  },
}));
