import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  paper: {
    width: '300px',
  },
  sidebarMenu: {
    width: '22%',
  },
  link: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      backgroundColor: '#e1e5fa33',
      '& > .MuiSvgIcon-root': {
        display: 'inline-block',
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
  },
  linkNested: {
    paddingLeft: 0,
    '&:hover, &:focus': {
      backgroundColor:
                theme.palette.type === 'dark'
                  ? theme.palette.background.light
                  : '#005eb2',
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    color:
            theme.palette.type === 'dark'
              ? '#616168 !important'
              : `${theme.palette.text.secondary}99`,
    transition: theme.transitions.create('color'),
    display: 'flex',
    justifyContent: 'center',
  },
  linkIconActive: {
    color: `${theme.palette.primary.main} !important`,
  },
  linkText: (props) => ({
    padding: 0,
    color:
            theme.palette.type === 'dark'
              ? '#D6D6D6 !important'
              : (props.isOpen ? '#c1c1c1 !important' : '#D6D6D6 !important'),
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 11,
    fontFamily: 'open sans, sans-serif',
    '@media only screen and (max-width: 1420px)': {
      fontSize: 11,
    },
  }),
  linkTextActive: {
    color: theme.palette.text.primary,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 8,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 1,
    backgroundColor:
            theme.palette.type === 'dark'
              ? 'rgba(151,151,151, .15)'
              : '#D8D8D880',
  },
  expand: {
    transform: 'rotate(180deg)',
  },
  expandWrapper: {
    color: '#ffffff',
    transition: theme.transitions.create('transform'),
    display: (props) => (props ? 'inline-flex' : 'none'),
    marginRight: 15,
  },
  nestedMenu: {
    paddingLeft: 0,
  },
  nestedMenuItem: {
    paddingLeft: 0,
  },
}));
