import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  width: {
    width: '50%',
    marginLeft: '25%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  reviewRating: {
    border: '4px solid black',
  },
  redRating: {
    backgroundColor: '#F04D5D',
  },
  yellowRating: {
    backgroundColor: '#E49E12',
  },
  greenRating: {
    backgroundColor: '#5FBF91',
  },
  shape: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      width: 25,
      height: 25,
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      width: 18,
      height: 18,
      marginRight: theme.spacing(1),
    },
  },
}));
