import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import { handleUndefined, postDataRequestPublic } from '../../../common/commonfunctions';

import CampaignBrand from '../../CampaignBrand/CampaignBrand';
import config from '../../../config';
import {
  useGlobalState,
  setLoginUserInfo,
} from '../../../context/GlobalContext';
import { useEntitiesState } from '../../../context/EntityContext';
import PoweredBy from '../../../common/PoweredBy';

export default function NagativeLanding({
  activeRating,
  brandInfo,
  reviewMsg,
  handleReviewMsg,
  handleRating,
  title,
  handletitle,
  error,
}) {
  const classes = useStyles();
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const [entity, setEntity] = useState([]);
  useEffect(() => {
    let isMounted = true;
    if (entityContext?.entities?.businessData
        && entityContext?.entities?.businessData[0]?.entityid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query entity($entityid:ID!){
              entity(entityid:$entityid){
                entityid
                businessname
                brandname
                firstname
                lastname
                email
                phonenumber
                entitytypeid
                businesstypeid
                jobid
                tags
                website
                shortdesc
                fulldesc
                policy
                terms
                }}`,
        variables: {
          entityid:
                    entityContext?.entities?.businessData
                    && entityContext?.entities?.businessData[0]?.entityid,
        },
      })
        .then((res) => {
          if (res.data && res.data.entity && isMounted) {
            setEntity(res.data.entity);
          }
        })
        .catch(() => {});
    }

    return () => {
      isMounted = false;
    };
  }, [
    entityContext?.entities?.businessData
            && entityContext?.entities?.businessData[0]?.entityid,
  ]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoginUserInfo(globalContext.setGlobalState);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && localStorage.getItem('userid')) {
      if (entityContext?.entities?.businessData
      && entityContext?.entities?.businessData[0]?.entityid) {
        postDataRequestPublic(config.baseURLApi, {
          query: `
      query brandSettingByLocation($locationid:ID!)
            {brandSettingByLocation(locationid:$locationid){color,dateformat,timeformat,logourl}}
          `,
          variables: {
            locationid:
                      entityContext?.entities?.businessData
                      && entityContext?.entities?.businessData[0]?.entityid,
          },
        })
          .then(() => {})
          .catch(() => {});
      }
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('userid')]);

  const handlePolicy = () => {
    window.open(entity.policy, '_blank');
  };
  const handleTerms = () => {
    window.open(entity.terms, '_blank');
  };
  return (
    <>
      <Box width="100%">
        <center>
          <img
            className={classes.large2}
            src={brandInfo?.logo}
            alt="brand logo"
          />
          <br />
        </center>
        <br />
        <Box className={classes.positiveExperienceCard}>
          <h2
            className={classes.marginZero}
            style={{ display: 'flex' }}
          >
            Review your Customer &nbsp;
            <CampaignBrand />
                        &nbsp; Experience with Your review matters !!!
          </h2>
        </Box>
        <Grid container className={classes.positiveFeedBackBox}>
          <Grid>
            <Typography variant="body2" className={classes.addMg}><b>Give your rating</b></Typography>
            <Box display="flex">
              <div
                onClick={() => handleRating(1)}
                className={`${classes.shape} ${
                  classes.redRating
                } ${
                  activeRating === 1
                    ? classes.activeRating
                    : ''
                }`}
              >
                1
              </div>
              <div
                onClick={() => handleRating(2)}
                className={`${classes.shape} ${
                  classes.redRating
                } ${
                  activeRating === 2
                    ? classes.activeRating
                    : ''
                }`}
              >
                2
              </div>
              <div
                onClick={() => handleRating(3)}
                className={`${classes.shape} ${
                  classes.redRating
                } ${
                  activeRating === 3
                    ? classes.activeRating
                    : ''
                }`}
              >
                3
              </div>
              <div
                onClick={() => handleRating(4)}
                className={`${classes.shape} ${
                  classes.redRating
                } ${
                  activeRating === 4
                    ? classes.activeRating
                    : ''
                }`}
              >
                4
              </div>
              <div
                onClick={() => handleRating(5)}
                className={`${classes.shape} ${
                  classes.redRating
                } ${
                  activeRating === 5
                    ? classes.activeRating
                    : ''
                }`}
              >
                5
              </div>
              <div
                onClick={() => handleRating(6)}
                className={`${classes.shape} ${
                  classes.redRating
                } ${
                  activeRating === 6
                    ? classes.activeRating
                    : ''
                }`}
              >
                6
              </div>
              <div
                onClick={() => handleRating(7)}
                className={`${classes.shape} ${
                  classes.yellowRating
                } ${
                  activeRating === 7
                    ? classes.activeRating
                    : ''
                }`}
              >
                7
              </div>
              <div
                onClick={() => handleRating(8)}
                className={`${classes.shape} ${
                  classes.yellowRating
                } ${
                  activeRating === 8
                    ? classes.activeRating
                    : ''
                }`}
              >
                8
              </div>
              <div
                onClick={() => handleRating(9)}
                className={`${classes.shape} ${
                  classes.greenRating
                } ${
                  activeRating === 9
                    ? classes.activeRating
                    : ''
                }`}
              >
                9
              </div>
              <div
                onClick={() => handleRating(10)}
                className={`${classes.shape} ${
                  classes.greenRating
                } ${
                  activeRating === 10
                    ? classes.activeRating
                    : ''
                }`}
              >
                10
              </div>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box><Typography variant="body2"><b>Not Likely</b></Typography></Box>
              <Box marginRight={2}>
                <Typography variant="body2"><b>Very Likely</b></Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.addMg}><b>Your review</b></Typography>
            <Box>
              <TextField
                id="outlined-multiline-static"
                label="Share your honest experience, and help others make better choices."
                multiline
                minRows={10}
                variant="outlined"
                fullWidth
                value={reviewMsg}
                onChange={handleReviewMsg}
              />
              {error && (
                <>
                  {!reviewMsg && (
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color="error"
                    gutterBottom
                  >
                    Review Message is required
                  </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.addMg}><b>Title of your review</b></Typography>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handletitle}
            />
            {error && (
            <>
              {!title && (
              <Typography
                variant="subtitle2"
                component="p"
                color="error"
                gutterBottom
              >
                Title is required
              </Typography>
              )}
            </>
            )}
          </Grid>
        </Grid>
      </Box>
      <center>
        <Box
          marginTop={1}
          marginBottom={1}
        >
          {handleUndefined(entity.policy) && (
            <>
              {' '}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p
                style={{ textDecoration: 'none' }}
                onClick={handlePolicy}
              >
                {' '}
                Privacy Policy
              </p>
              {' '}
              /
              {' '}
            </>
          )}
          {' '}
          {handleUndefined(entity.terms) && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <p
              style={{ textDecoration: 'none' }}
              onClick={handleTerms}
            >
              Terms & Conditions
            </p>
          )}
        </Box>
        <Box
          marginTop={2}
          marginBottom={1}
        >
          <Typography variant="body2">Powered by</Typography>
        </Box>
        <PoweredBy id={entityContext?.entities?.businessData
                        && entityContext?.entities?.businessData[0]?.entityid}
        />
      </center>
    </>
  );
}
