import React, {
  useState, useEffect, lazy, Suspense,
} from 'react';
import { Route, withRouter } from 'react-router-dom';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import classnames from 'classnames';
import {
  Checkbox, Fab,
} from '@material-ui/core';
import { Brightness4, Brightness7 } from '@material-ui/icons';
import useStyles from './styles';
import { Planavailablebalance } from '../../Api Mutation/Payment';
import { modules, permissions } from '../../common/permissions';
import config from '../../config';
import {
  useUserState, UserProvider,
} from '../../context/UserContext';
import { EntitiesProvider } from '../../context/EntityContext';
import { CampaginProvider } from '../../context/CampaginContext';
import { PaymentProvider } from '../../context/PaymentContext';
import {
  postDataRequest, getDataRequest, checkAccess, handleUndefined,
} from '../../common/commonfunctions';
import { accessPermissionIds, Roles } from '../../common/const';
import {
  useLayoutDispatch,
  setLocationModal,
  useLayoutState,
} from '../../context/LayoutContext';
import { useGlobalState } from '../../context/GlobalContext';
import { showNotification } from '../Notification/ToastifyNotifications';
import { LoadingPage } from '../../pages/LoadingPage/LoadingPage';
import AccessError from '../../pages/Access/AccessError';
import structure from '../Sidebar/SidebarStructure';
import Sidebar from '../Sidebar';
import SelectLocation from './SelectLocation';
import LazyLoader from '../../pages/LoadingPage/LazyLoader';
import Businessplans from '../../pages/BusinessPlans/Businessplans';
import { useThemeDispatch } from '../../context/ThemeContext';
import Themes from '../../themes';
import MailBox from '../../pages/MailBox/MailBox';
import Header from '../Header';
import CreateOrderModule from '../../pages/OrderModule/CreateOrderModule';
import CampaignManager from '../../pages/Collection/components/CampaignManager/CampaignManager';
const EmailFinder = lazy(() => import('../../pages/EmailFinder/EmailFinder'));
const ImportCustomer = lazy(() => import('../../pages/Collection/components/import/import'));
const PlanExpirePage = lazy(() => import('../../pages/PlanExpire/PlanExpirePage'));
const QRcodeimg = lazy(() => import('../../pages/Display/QrCodeImage'));
const ImportSummary = lazy(() => import('../../pages/ImportHistory/ImportSummary'));
const Yelpbusiness = lazy(() => import('../../pages/Setting/FetchYourOnlineReview/Yelp-Review/Yelpbusiness'));
const Facebookbusiness = lazy(() => import('../../pages/Setting/FetchYourOnlineReview/Facebook-Review/Facebookbusiess'));
const SettingGooglebusiness = lazy(() => import('../../pages/Setting/FetchYourOnlineReview/Google-Review/Googlebusiness'));
const SalesDashboard = lazy(() => import('../../pages/Analytics/SalesDashboard'));
const MarketingDashboard = lazy(() => import('../../pages/Analytics/MarketingDashboard'));
const EmailReport = lazy(() => import('../../pages/Analytics/EmailReport'));
const PowerBIDashboard = lazy(() => import('../../pages/PowerBi/PowerbiDashboard'));
const ContactDashboard = lazy(() => import('../../pages/Analytics/ContactDashboard'));
const PaymentDashboard = lazy(() => import('../../pages/Setting/PaymentConnection/PaymentDashboard'));
const Analytics = lazy(() => import('../../pages/Analytics/Analytics'));
const AfilliateLink = lazy(() => import('../../pages/Setting/AfilliateLink/AfilliateLink'));
const PaymentSetting = lazy(() => import('../../pages/Setting/PaymentConnection/PaymentSetting'));
const ThirdCalender = lazy(() => import('../../pages/Setting/ThirdPartyCal/ThirdPartyCal'));
const ProcessAutomation = lazy(() => import('../../pages/Setting/ProcessAutomation/ProcessAutomation'));
const FetchYourOnlineReview = lazy(() => import('../../pages/Setting/FetchYourOnlineReview/FetchYourOnlineReview'));
const CommunicationSetting = lazy(() => import('../../pages/Setting/CommunicationSetting/Email'));
const ManageLocation = lazy(() => import('../../pages/Setting/ManageLocation/ManageLocation'));
const ManageBusiness = lazy(() => import('../../pages/Setting/ManageBusiness/ManageBusiness'));
const Addwidgetslider = lazy(() => import('../../pages/Widget-libary/Addwidgetslider'));
const Addwidgetminisecond = lazy(() => import('../../pages/Widget-libary/Addwidgetminisecond'));
const Addwidgetlist = lazy(() => import('../../pages/Widget-libary/AddwidgetList'));
const Addwidgetmini = lazy(() => import('../../pages/Widget-libary/Addwidgetmini'));
const ReviewBadge = lazy(() => import('../../pages/ReviewBadge/ReviewBadge'));
const VisitorDashboard = lazy(() => import('../../pages/VisitorDetails/VisitorDashboard'));
const SetupTracking = lazy(() => import('../../pages/VisitorDetails/SetupTracking'));
const Addwidgetproduct = lazy(() => import('../../pages/Widget-libary/Addwidgetproduct'));
const Appointment = lazy(() => import('../../pages/LeadCentral/Appointment/Appointment'));
const PageWidget = lazy(() => import('../../pages/PageWidget/PageWidget'));
const Widgetlibary = lazy(() => import('../../pages/Widget-libary/Widget'));
const ManageAgency = lazy(() => import('../../pages/Setting/ManageAgency/ManageAgency'));
const AdminPlans = lazy(() => import('../../pages/Admin/Plans'));
const Topup = lazy(() => import('../../pages/Setting/Plans&price/component/Topup'));
const Users = lazy(() => import('../../pages/Setting/Plans&price/component/Users'));
const Services = lazy(() => import('../../pages/Setting/Plans&price/component/Services'));
const Invoice = lazy(() => import('../../pages/Setting/Plans&price/Invoice'));
const EditAccess = lazy(() => import('../../pages/Setting/UserManagement/EditAccess'));
const UserManagementEdit = lazy(() => import('../../pages/Setting/UserManagement/UsermanagementEdit'));
const Domain = lazy(() => import('../../pages/Setting/DomainVerify/Domain'));
const WhiteLabel = lazy(() => import('../../pages/Setting/WhiteLabel/WhiteLabel'));
const ApiDocumentation = lazy(() => import('../../pages/Setting/API Documentation/ApiDocumentation'));
const Properties = lazy(() => import('../../pages/Setting/Properties Settings/Properties'));
const UserManagementDashboard = lazy(() => import('../../pages/Setting/UserManagement/Usermanagementdashboard'));
const UserManagement = lazy(() => import('../../pages/Setting/UserManagement/Usermanagement'));
const AffiliateReport = lazy(() => import('../../pages/Setting/AfilliateLink/AffiliateReport'));
const WebPageReport = lazy(() => import('../../pages/NewsLetter/WebPageReport'));
const NewsLetterReport = lazy(() => import('../../pages/NewsLetter/NewsLetterReport'));
const ProductReport = lazy(() => import('../../pages/ProductAndService/Component/Report'));
const ContactReport = lazy(() => import('../../pages/Contact_Marketing/ContectReport'));
const CaptureLeadsReport = lazy(() => import('../../pages/LeadCentral/components/CaptureLeadsReport'));
const CampaignReport = lazy(() => import('../../pages/Collection/components/CampaignReport/CampaignReport'));
const SmsCampainReport = lazy(() => import('../../pages/Text_marketing/TextMarketingReport'));
const TextMarketing = lazy(() => import('../../pages/Text_marketing/TextMarketing'));
const EmailMarketing = lazy(() => import('../../pages/Email_marketing/EmailMarketing'));
const WorkflowReport = lazy(() => import('../../pages/Workflow Automation/WorkflowReport'));
const CreateWorkflow = lazy(() => import('../../pages/Workflow Automation/CreateWorkflow'));
const CreateWorkflowTemplate = lazy(() => import('../../pages/Workflow Automation/CreateWorkflowTemplate'));
const WorkflowTemplates = lazy(() => import('../../pages/Workflow Automation/WorkflowTemplates'));
const Reviewcam = lazy(() => import('../../pages/reviewcam/ReviewCam'));
const PublicPage = lazy(() => import('../../pages/PublicPage/PublicPage'));
const TochlessQRcode = lazy(() => import('../../pages/Display/TochlessQRcode/TochlessQRcode'));
const SocialSharing = lazy(() => import('../../pages/Display/Social-Sharing/SocialSharing'));
const QuickReview = lazy(() => import('../../pages/Display/Reviewboard/QuickReview'));
const Customer = lazy(() => import('../../pages/Collection/Customer'));
const ImportContacts = lazy(() => import('../../pages/Collection/components/Addcustomer/ImportCustomer'));
const ImportCustomers = lazy(() => import('../../pages/Collection/components/Addcustomer/ImportContacts'));
const Organizationedit = lazy(() => import('../../pages/Organizations/Edit-Organizations'));
const ContactCRMedit = lazy(() => import('../../pages/ContactCentral/ContactCRMedit'));
const ContactList = lazy(() => import('../../pages/ContactCentral/Components/ContactList'));
const EditDeal = lazy(() => import('../../pages/CRM Sales/editDeal'));
const EditAffiliatePage = lazy(() => import('../../pages/Setting/AfilliateLink/EditAffiliatePage'));
const AddNewsLetter = lazy(() => import('../../pages/NewsLetter/AddNewsLetter'));
const AddContactMarketing = lazy(() => import('../../pages/Contact_Marketing/AddContactMarketing'));
const Call = lazy(() => import('../../pages/Call/Call'));
const AddInvoice = lazy(() => import('../../pages/Payment&Invoice/AddInvoice'));
const PaymentInvoice = lazy(() => import('../../pages/Payment&Invoice/Payment&Invoice'));
const ProspectCentral = lazy(() => import('../../pages/ProspectCentral/prospectcentral'));
const AddCustomer = lazy(() => import('../../pages/Collection/components/Addcustomer/Addcustomer'));
const CampaignSetting = lazy(() => import('../../pages/Setting/CampaignSetting'));
const Maindashboard = lazy(() => import('../../pages/Maindashboard/Maindashboard'));
const LeadCentral = lazy(() => import('../../pages/LeadCentral/LeadCentral'));
const ContactCRM = lazy(() => import('../../pages/ContactCentral/ContactCRM'));
const EditContactForm = lazy(() => import('../../pages/ContactCentral/EditContactForm'));
const EditDealForm = lazy(() => import('../../pages/CRM Sales/EditDealForm'));
const Organization = lazy(() => import('../../pages/Organizations/Organizations'));
const DocumentReport = lazy(() => import('../../pages/Documents/DocumentReport'));
const Documents = lazy(() => import('../../pages/Documents/Documents'));
const EmailCampaignManager = lazy(() => import('../../pages/Email_marketing/EmailCampaignManager/EmailMarketingManager'));
const TextCampaignManager = lazy(() => import('../../pages/Text_marketing/TextCampaignManager/TextMarketingManager'));
const Deal = lazy(() => import('../../pages/CRM Sales/Deal'));
const Task = lazy(() => import('../../pages/Task/Task'));
const Reviewboard = lazy(() => import('../../pages/Display/Reviewboard/ReviewBoard'));
const Setting = lazy(() => import('../../pages/Setting/Setting'));
const MyWorkSpace = lazy(() => import('../../pages/MyWorkSpace/Myworkspace'));
const Team = lazy(() => import('../../pages/Team/Team'));
const Plans = lazy(() => import('../../pages/Setting/Plans&price/plans'));
const ImportLead = lazy(() => import('../../pages/LeadCentral/ImportLeads/importleads'));
const SalesNavigator = lazy(() => import('../../pages/LeadCentral/ImportLeads/SalesNavigator'));
const LinkedIn = lazy(() => import('../../pages/LeadCentral/ImportLeads/LinkedIn'));
const Apollo = lazy(() => import('../../pages/LeadCentral/ImportLeads/Apollo'));
const ZoomInfo = lazy(() => import('../../pages/LeadCentral/ImportLeads/ZoomInfo'));
const SummarySales = lazy(() => import('../../pages/LeadCentral/ImportLeads/SummarySales'));
const LiveChat = lazy(() => import('../../pages/LeadCentral/LiveChat/LiveChat'));
const InquiryForm = lazy(() => import('../../pages/LeadCentral/InquiryForms/InquiryForm'));
const Event = lazy(() => import('../../pages/LeadCentral/Events/Event'));
const NewsLetter = lazy(() => import('../../pages/NewsLetter/NewsLetter'));
const WorkflowAutomation = lazy(() => import('../../pages/Workflow Automation/WorkflowAutomation'));
const LinkedinAutomation = lazy(() => import('../../pages/LinkedIn Automation/LinkedinAutomation'));
const LinkedinAccount = lazy(() => import('../../pages/LinkedIn Automation/LinkedinAccount'));
const CreateAutomation = lazy(() => import('../../pages/LinkedIn Automation/CreateAutomation'));
const AutomationContact = lazy(() => import('../../pages/LinkedIn Automation/AutomationContact'));
const AutomationReport = lazy(() => import('../../pages/LinkedIn Automation/AutomationReport'));
const Templates = lazy(() => import('../../pages/Email_marketing/Templates/TemplateCatagor'));
const ContactMarketing = lazy(() => import('../../pages/Contact_Marketing/ContactMarketing'));
const ReviewMarketing = lazy(() => import('../../pages/Review Marketing/ReviewMarketing'));
const HelpAdvisors = lazy(() => import('../../pages/HelpAdvisors/HelpAdvisors'));
const ManageYourBrand = lazy(() => import('../../pages/Setting/ManageYourBrand/ManageYourBrand'));
const MyProfile = lazy(() => import('../../pages/Setting/MyProfile/MyProfile'));
const UsageReport = lazy(() => import('../../pages/UsageReport/UsageReport'));
const Subscription = lazy(() => import('../../pages/Setting/Plans&price/component/Subscription'));
const Master = lazy(() => import('../../pages/Master/Master'));
const MasterAgencydetail = lazy(() => import('../../pages/Master/components/MasterAgencydetail'));
const ProductAndService = lazy(() => import('../../pages/ProductAndService/Product&Service'));
const ProductForm = lazy(() => import('../../pages/ProductAndService/ProductForm'));
const AddEvent = lazy(() => import('../../pages/LeadCentral/Appointment/component/AddEvent'));
const AddNewEvent = lazy(() => import('../../pages/LeadCentral/Events/component/AddNewEvent'));
const EventReport = lazy(() => import('../../pages/LeadCentral/Events/component/EventReport'));
const EventScreen = lazy(() => import('../../pages/LeadCentral/Appointment/component/EventScreen'));
const CreateForm = lazy(() => import('../../pages/LeadCentral/components/CreateForm'));
const ViewInquiryForm = lazy(() => import('../../pages/LeadCentral/InquiryForms/ViewInquiryForm'));
const CreateInquiryForm = lazy(() => import('../../pages/LeadCentral/InquiryForms/CreateInquiryForm'));
const InquiryFormEditor = lazy(() => import('../../pages/LeadCentral/InquiryForms/InquiryFormEditor'));
const FormEditor = lazy(() => import('../../pages/LeadCentral/components/FormEditor'));
const Clientdetails = lazy(() => import('../../pages/dashboard/Clientdetails'));
const BusinessClientdetails = lazy(() => import('../../pages/business/components/BusinessClientdetails'));
const Business = lazy(() => import('../../pages/business/business'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const MasterAddNewAgency = lazy(() => import('../../pages/Master/components/MasterAddNewAgency'));
const AddNewClient = lazy(() => import('../../pages/dashboard/AddNewClient'));
const Socialmedia = lazy(() => import('../../pages/SocialMedia/Socialmedia'));
const Soical = lazy(() => import('../../pages/SocialMedia/Soical'));
const CreatePost = lazy(() => import('../../pages/SocialMedia/CreatePost'));
const Refercode = lazy(() => import('../../pages/ReferCode/Refercode'));
const WorkflowContact = lazy(() => import('../../pages/Workflow Automation/WorkFlowContact'));
const OrderModule = lazy(() => import('../../pages/OrderModule/OrderModule'));
const TicketList = lazy(() => import('../../pages/TicketManagement/TicketList'));
const TicketForm = lazy(() => import('../../pages/TicketManagement/TicketForm'));
const RoleList = lazy(() => import('../../pages/RolesAccess/RoleList'));
const RolesAccess = lazy(() => import('../../pages/RolesAccess/RolesAccess'));

function Layout(props) {
  const { history } = props;
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [toogleBodyStatus, setToogleBodyStatus] = useState(false);
  const globalContext = useGlobalState();
  const [masterUser, setMasterUser] = useState();

  const getToogleBodyStatus = (status) => {
    setToogleBodyStatus(status);
  };

  const { isAuthenticated, userInfo } = useUserState();
  const accessPermissions = {
    BusinessPublic: true,
    publicPage: true,
    googleMyBusinessPage: true,
    campaignSetting: true,
  };
  const [loading, setLoading] = useState(true);
  const accessComponent = loading ? (
    <center>
      <h2>loading...</h2>
    </center>
  ) : (
    <center>
      <h2>You have no access</h2>
    </center>
  );
  const [userPermissions, setUserPermissions] = useState();
  const [home, setHome] = useState();
  const [settings, setSettings] = useState();
  const [professionalServices, setProfessionalServices] = useState();
  const [reputationCentral, setReputationCentral] = useState();
  const [prospectCentral, setProspectCentral] = useState();
  const [marketingCentral, setMarketingCentral] = useState();
  const [promotionCentral, setPromotionCentral] = useState();
  const [productService, setProductService] = useState();
  const [insightsCentral, setInsightsCentral] = useState();
  const [leadCentral, setLeadCentral] = useState();
  const [salesCentral, setSalesCentral] = useState();
  const [resMenu, setResMenu] = useState({});
  const layoutDispatch = useLayoutDispatch();
  const [allowAccess, setAllowAccess] = useState();
  const businessId = JSON.parse(localStorage.getItem('business_Id'));
  const themeDispatch = useThemeDispatch();

  useEffect(() => {
    let isMounted = true;
    if (userInfo?.roleid) {
      if (businessId) {
        if (userInfo?.roleid) {
          if (businessId) {
            postDataRequest(config.baseURLApi, {
              query: Planavailablebalance,
              variables: {
                businessid: businessId,
              },
            }).then((res) => {
              if (isMounted) {
                if (res?.data?.planavailablebalance === null) {
                  setAllowAccess(false);
                } else {
                  setAllowAccess(true);
                }
              }
            });
          }
        }
        if (businessId || JSON.parse(localStorage.getItem('business_Id'))) {
          getDataRequest(`${config.payment}v1/businesses/${businessId || JSON.parse(localStorage.getItem('business_Id'))}`)
            .then((res) => {
              if (res?.data?.customerid);
            })
            .catch(async (err) => {
              if (err?.response?.data?.isError) {
                await postDataRequest(config.baseURLApi, {
                  query: 'query entity($entityid:ID!){entity(entityid:$entityid){email}}',
                  variables: {
                    entityid: businessId,
                  },
                }).then(async (businessData) => {
                  if (businessData?.data?.entity?.email);
                }).catch(() => showNotification('Something went wrong, please try after sometime', 'error'));
              }
            });
        }
      }
    } else {
      setAllowAccess(true);
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('business_Id'), userInfo?.roleid]);



  const populatePermissions = (data) => {
    const menuPermissions = {
      homePermissions: [],
      reputationPermissions: [],
      crmPermissions: [],
      insightsPermissions: [],
      leadsPermissions: [],
      salesPermissions: [],
      marketingPermissions: [],
      professionalPermissions: [],
      workflowPermissions: [],
      linkedInPermissions: [],
    };
    data.forEach((category) => {
      const permissionIds = category.modules.flatMap(module =>
        module.permissions.map(permission => permission.permissionid)
      );

      switch (category.name) {
        case 'Home':
          menuPermissions.homePermissions.push(...permissionIds);
          break;
        case 'Leads':
          menuPermissions.leadsPermissions.push(...permissionIds);
          break;
        case 'CRM':
          menuPermissions.crmPermissions.push(...permissionIds);
          break;
        case 'Marketing':
          menuPermissions.marketingPermissions.push(...permissionIds);
          break;
        case 'Sales':
          menuPermissions.salesPermissions.push(...permissionIds);
          break;
        case 'Reputation':
          menuPermissions.reputationPermissions.push(...permissionIds);
          break;
        case 'Insights':
          menuPermissions.insightsPermissions.push(...permissionIds);
          break;
        case 'Professional Services':
          menuPermissions.professionalPermissions.push(...permissionIds);
          break;
        default:
          break;
      }
    });
    if (menuPermissions?.leadsPermissions.includes(accessPermissionIds.workflowPermissionId)) {
      menuPermissions?.workflowPermissions.push(accessPermissionIds.workflowPermissionId);
    }
    if (menuPermissions?.leadsPermissions?.includes(accessPermissionIds.linkedinPermissionId)) {
      menuPermissions?.linkedInPermissions?.push(accessPermissionIds.linkedinPermissionId);
    }
    setResMenu(menuPermissions);
  };

  useEffect(() => {
    let isMounted = true;
    if (localStorage.getItem('userid')) {
      setLoading(true);
      postDataRequest(config.baseURLApi, {
        query: `query user($userid:ID!){user(userid:$userid)
              {
                usertype
                email
                permissions{
                 permissionid
                }
              }
              }`,
        variables: {
          userid: JSON.parse(localStorage.getItem('userid')),
        },
      }).then(async (res) => {
        await postDataRequest(config.baseURLApi, {
          query: `query role($roleId:Int!){role(roleid:$roleId){
                          name
                             menus{
                               name
                               menuid
                               code
                             modules{
                               name
                               code
                               moduleid
                               permissions{
                                 permissionid
                                 name
                                 code
                               }
                             }}
                           }}
                           `,
          variables: {
            roleId: userInfo?.roleid,
          },
        }).then((resMenu) => {
          if (isMounted) {
            populatePermissions(resMenu?.data?.role?.menus);
            setHome(resMenu?.data?.role?.menus.find((r) => r.code === 'home')?.modules);
            setReputationCentral(resMenu?.data?.role?.menus.find((r) => r.code === 'reputation')?.modules);
            setProspectCentral(resMenu?.data?.role?.menus.find((r) => r.code === 'crm')?.modules);
            setPromotionCentral(resMenu?.data?.role?.menus.find((r) => r.code === 'Promotion Central')?.modules); //*
            setProductService(resMenu?.data?.role?.menus.find((r) => r.code === 'productService')?.modules); //*
            setInsightsCentral(resMenu?.data?.role?.menus.find((r) => r.code === "insights")?.modules);
            setSettings(resMenu?.data?.role?.menus.find((r) => r.code === 'Settings')?.modules); //*
            setLeadCentral(resMenu?.data?.role?.menus.find((r) => r.code === 'leads')?.modules);
            setSalesCentral(resMenu?.data?.role?.menus.find((r) => r.code === "sales")?.modules);
            setMarketingCentral(resMenu?.data?.role?.menus.find((r) => r.code === 'marketing')?.modules);
            setProfessionalServices(resMenu?.data?.role?.menus.find((r) => r.code === 'professionalServices')?.modules);
            setLoading(false);
          }
        }).catch(() => {
          setLoading(false);
        });
        if (isMounted) {
          setLoading(false);
          setUserPermissions(
            res?.data?.user?.permissions?.map((pid) => pid.permissionid),
          );
        }
      });
    }
    const timerId = setTimeout(() => {
      if (!localStorage.getItem('location_Id')) {
        setLocationModal(true, layoutDispatch);
      }
      setLocationModal(false, layoutDispatch);
    }, [3000]);
    return () => {
      clearTimeout(timerId);
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (handleUndefined(userInfo?.roleid) && isMounted) {
      setMasterUser(userInfo?.roleid);
    }
    return () => {
      isMounted = false;
    };
  }, [userInfo?.roleid]);
  useEffect(() => {
    let isMounted = true;
    if (!isAuthenticated && isMounted) {
      history.push('/login');
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated]);

  const userData = JSON.parse(localStorage.getItem('user'));
  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_LoadStart = new Date();
  window.Tawk_API.onLoad = () => {
    window.Tawk_API.setAttributes({
      name: `${userData?.firstname.toUpperCase()} ${userData?.lastname.toUpperCase()}` || '',
      email: `${userData?.email}` || '',
      Phone: userData.phonenumber || '',
    });
  };

  const toggleDarkTheme = () => {
    if (localStorage.getItem('theme') === 'dark') {
      localStorage.setItem('theme', 'default');
      themeDispatch(Themes.default);
    } else {
      localStorage.setItem('theme', 'dark');
      themeDispatch(Themes.dark);
    }
  };

  return (
    <div className={classes.root}>
      {loading ? <LoadingPage /> : (
        <EntitiesProvider>
          <Header
            locationSeletion={checkAccess(userPermissions, home, modules.settings,
              permissions.locationSelectionTopBar, userInfo?.roleid)}
            history={history}
            isMasterUser={masterUser}
            toogleBody={toogleBodyStatus}
          />
        </EntitiesProvider>
      )}

      {(config.tawkTo && businessId !== '37169fea-7316-45ae-9882-084d628bcb85') ? (
        <TawkMessengerReact
          propertyId="63ce097e47425128790f1760"
          widgetId="1gneda5j1"
        />
      ) : ''}
      <Fab
        variant="extended"
        size="large"
        className={classes.fabStyle}
        onClick={toggleDarkTheme}
        color={localStorage.getItem('theme') === 'dark' ? 'primary' : 'default'}
      >
        <Checkbox
          checked={localStorage.getItem('theme') === 'dark'}
          icon={<Brightness7 style={{ color: '#005eb2' }} />}
          checkedIcon={<Brightness4 style={{ color: 'white' }} />}
        />
      </Fab>
      <Sidebar
        structure={structure}
        userPermissions={userPermissions}
        home={home}
        menuPermissions={resMenu}
        settings={settings}
        professionalServices={professionalServices}
        reputationCentral={reputationCentral}
        prospectCentral={prospectCentral}
        marketingCentral={marketingCentral}
        promotionCentral={promotionCentral}
        productService={productService}
        insightsCentral={insightsCentral}
        leadCentral={leadCentral}
        salesCentral={salesCentral}
        roleid={userInfo?.roleid}
        getToogleBodyStatus={
          !globalContext.globalState.currentLocation.entityid
            ? getToogleBodyStatus
            : ''
        }
      />
      {loading ? <LoadingPage /> : (
        <div
          style={{ backgroundColor: localStorage.getItem('theme') === 'dark' ? '#333540' : '#ffff' }}
          className={classnames(layoutState.permanent
            ? classes.contentPermanent : classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Suspense fallback={<LazyLoader />}>
            <Route path="/app/subscription">
              {userInfo?.roleid
                ? (
                  <EntitiesProvider>
                    <PaymentProvider>
                      <Subscription view history={history} />
                    </PaymentProvider>
                  </EntitiesProvider>
                )
                : <AccessError />}
            </Route>
          </Suspense>
          {allowAccess === false ? (
            <>
              <EntitiesProvider>
                <CampaginProvider>
                  <Suspense fallback={<LazyLoader />}>
                    <PlanExpirePage {...props} />
                  </Suspense>
                </CampaginProvider>
              </EntitiesProvider>
            </>
          ) : (
            <>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/master">
                  <EntitiesProvider>
                    <Master isMasterUser={masterUser} history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/master-agency-detail">
                  <EntitiesProvider>
                    <MasterAgencydetail isMasterUser={masterUser} history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/forms">
                  {checkAccess(userPermissions, leadCentral, modules.leadGenForms,
                    permissions.manageLeadGenForms, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <LeadCentral
                        isMasterUser={masterUser}
                        deleteFrom={checkAccess(userPermissions, leadCentral,
                          modules.leadGenForms, permissions.manageLeadGenForms, userInfo?.roleid)}
                        history={history}
                      />
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/appointment">
                  {checkAccess(userPermissions, leadCentral, modules.appointments,
                    permissions.manageAppointment, userInfo?.roleid) ? (
                    <Appointment
                      isMasterUser={masterUser}
                      manageAppointmentBooking={checkAccess(userPermissions, leadCentral, modules.appointments,
                        permissions.manageAppointmentBooking, userInfo?.roleid)}
                      manageAppointmentBookingPage={checkAccess(userPermissions, leadCentral, modules.appointments,
                        permissions.manageAppointmentBookingPage, userInfo?.roleid)}
                      history={history}
                    />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/event">
                  {checkAccess(userPermissions, leadCentral, modules.events,
                    permissions.manageEvents, userInfo?.roleid) ? (
                    <Event
                      isMasterUser={masterUser}
                      history={history}
                    />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              {/* <Suspense fallback={<LazyLoader />}>
                <Route path="/app/chat">
                  {checkAccess(userPermissions, salesCentral, modules.bixBox,
                    permissions.bixBox, userInfo?.roleid) ? (
                      <EntitiesProvider>
                        <CampaginProvider>
                          <ChatUI
                            addDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                            editDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                            deleteDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                            history={history}
                          />
                        </CampaginProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense> */}
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/dashboard">
                  {checkAccess(userPermissions, home, modules.favourites,
                    permissions.favourites, userInfo?.roleid) ? (
                    <Maindashboard
                      history={history}
                      manageContact={checkAccess(userPermissions, prospectCentral,
                        modules.contacts, permissions.contactGrid, userInfo?.roleid)}
                      manageWorkflow={checkAccess(userPermissions, leadCentral,
                        modules.workflows,
                        permissions.workflowList, userInfo?.roleid)}
                      manageTask={checkAccess(userPermissions, prospectCentral, modules.tasks,
                        permissions.tasksList, userInfo?.roleid)}
                      manageAppointment={checkAccess(userPermissions, leadCentral, modules.appointments,
                        permissions.manageAppointment, userInfo?.roleid)}
                      manageEMailMarketing={checkAccess(userPermissions, marketingCentral,
                        modules.emailMarketing, permissions.emailMarketingList, userInfo?.roleid)}
                      manageTextMarketing={checkAccess(userPermissions, marketingCentral,
                        modules.textMarketing, permissions.textMarketingList, userInfo?.roleid)}
                      managePipeline={checkAccess(userPermissions, prospectCentral, modules.deals,
                        permissions.dealsList, userInfo?.roleid)}
                      managePages={checkAccess(userPermissions, marketingCentral,
                        modules.pages, permissions.PagesList, userInfo?.roleid)}
                    />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/product-service">
                  {checkAccess(userPermissions, salesCentral, modules.productService,
                    permissions.productServiceList, userInfo?.roleid) ? (
                    <ProductAndService
                      isMasterUser={masterUser}
                      history={history}
                      manageProductService={checkAccess(userPermissions, salesCentral, modules.productService,
                        permissions.manageProductService, userInfo?.roleid)}
                    />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/product-form">
                  {checkAccess(userPermissions, salesCentral, modules.productService,
                    permissions.productServiceList, userInfo?.roleid) ? (
                    <ProductForm
                      isMasterUser={masterUser}
                      history={history}
                    />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/import-lead">
                  {checkAccess(userPermissions, leadCentral, modules.importLeads,
                    permissions.manageImportLeads, userInfo?.roleid) ? (
                    <ImportLead history={history} />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/review-marketing">
                  {checkAccess(userPermissions, marketingCentral, modules.reviewMarketing,
                    permissions.ReviewMarketingList, userInfo?.roleid) ? (
                    <ReviewMarketing
                      history={history}
                      manageReviewMarketing={checkAccess(userPermissions, marketingCentral, modules.reviewMarketing,
                        permissions.manageReviewMarketing, userInfo?.roleid)} />
                  ) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/add-event">
                  {checkAccess(userPermissions, leadCentral, modules.appointments,
                    permissions.manageAppointment, userInfo?.roleid) ? (
                    <AddEvent isMasterUser={masterUser} history={history} />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/add-new-event">
                  {checkAccess(userPermissions, leadCentral, modules.events,
                    permissions.manageEvents, userInfo?.roleid) ? (
                    <AddNewEvent isMasterUser={masterUser} history={history} />
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/event/report">
                  <EntitiesProvider>
                    <EventReport history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/event-screen">
                  <EventScreen history={history} />
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/create-form">
                  {checkAccess(userPermissions, leadCentral, modules.leadGenForms,
                    permissions.manageLeadGenForms, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CreateForm history={history} />
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/live-chat">
                  {checkAccess(userPermissions, leadCentral,
                    modules.liveChat,
                    permissions.manageLiveChat, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <CampaginProvider>
                          <LiveChat />
                        </CampaginProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/inquiry-forms">
                  {checkAccess(userPermissions, leadCentral, modules.inquiryForms,
                    permissions.manageInquiryForms, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <InquiryForm
                        history={history}
                        isMasterUser={masterUser}
                        deleteFrom={checkAccess(userPermissions, leadCentral,
                          modules.inquiryForms, permissions.manageInquiryForms, userInfo?.roleid)}
                      />
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/view-user-response/:formId">
                  <EntitiesProvider>
                    <ViewInquiryForm history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/create-inquiry-form">
                  {checkAccess(userPermissions, leadCentral, modules.inquiryForms,
                    permissions.manageInquiryForms, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CreateInquiryForm history={history} isMasterUser={masterUser} />
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/inquiry-form-editor">
                  {checkAccess(userPermissions, leadCentral, modules.inquiryForms,
                    permissions.manageInquiryForms, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <InquiryFormEditor history={history} isMasterUser={masterUser} />
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/form-editor">
                  <EntitiesProvider>
                    <FormEditor history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/Home/agency-client-details"
                >
                  <EntitiesProvider>
                    <Clientdetails isMasterUser={masterUser} history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/agency-add-new-client">
                  <EntitiesProvider>
                    <AddNewClient isMasterUser={masterUser} history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/master-add-new-agency">
                  <EntitiesProvider>
                    <MasterAddNewAgency isMasterUser={masterUser} history={history} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/agency">
                  {checkAccess(userPermissions, home, modules.favourites,
                    permissions.favourites, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <Dashboard isMasterUser={masterUser} history={history} />
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/businessplans">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, home, modules.plan,
                      permissions.managePlan, userInfo?.roleid)
                      ? (
                        <Businessplans
                          isMasterUser={masterUser}
                          history={history}
                        />
                      )
                      : <AccessError />}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/business">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, home, modules.settings,
                      permissions.manageLocation, userInfo?.roleid) ? (
                      <Business
                        isMasterUser={masterUser}
                        viewList
                        editLocation
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/myworkspace">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, home, modules.myWorkspace,
                      permissions.myWorkspace, userInfo?.roleid) ? (
                      <MyWorkSpace
                        isMasterUser={masterUser}
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/Team">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, home, modules.team,
                      permissions.manageTeams, userInfo?.roleid) ? (
                      <Team
                        isMasterUser={masterUser}
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/Inbox">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, prospectCentral, modules.inbox,
                      permissions.inboxList, userInfo?.roleid) ? (
                      <MailBox
                        isMasterUser={masterUser}
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/visitors">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, leadCentral, modules.visitorDashboard,
                      permissions.manageVisitorDashboard, userInfo?.roleid) ? (
                      <VisitorDashboard
                        isMasterUser={masterUser}
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/setup-tracking">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, leadCentral, modules.visitorDashboard,
                      permissions.manageVisitorDashboard, userInfo?.roleid) ? (
                      <SetupTracking
                        isMasterUser={masterUser}
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/home/business-client-details"
                  render={() => (
                    <EntitiesProvider>
                      <BusinessClientdetails history={history} isMasterUser={masterUser} />
                    </EntitiesProvider>
                  )}
                />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/campaign-setting">
                  <EntitiesProvider>
                    <CampaignSetting isMasterUser={masterUser} />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/add-customer">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <AddCustomer isMasterUser={masterUser} history={history} />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/contact-central">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (checkAccess(userPermissions, prospectCentral,
                    modules.contacts, permissions.contactGrid, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <ContactCRM
                          isMasterUser={masterUser}
                          history={history}
                          addDeal={checkAccess(userPermissions, prospectCentral,
                            modules.deals, permissions.manageDeals, userInfo?.roleid)}
                          editContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          editDeal={checkAccess(userPermissions,
                            prospectCentral, modules.deals,
                            permissions.manageDeals, userInfo?.roleid)}
                          deleteDeal={checkAccess(userPermissions, prospectCentral,
                            modules.deals, permissions.manageDeals, userInfo?.roleid)}
                          addContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          addImport={checkAccess(userPermissions, leadCentral,
                            modules.importLeads, permissions.manageImportLeads, userInfo?.roleid)}
                          exportContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.exportContact, userInfo?.roleid)}
                          isContactCentral
                          title="Contacts"
                          isMainCrm
                        />
                      </EntitiesProvider>
                    ) : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/contact-editform">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (checkAccess(userPermissions, prospectCentral,
                    modules.contacts, permissions.addEditContact, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <EditContactForm
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    ) : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/deal-editform">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (checkAccess(userPermissions, prospectCentral,
                    modules.deals, permissions.manageDeals, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <EditDealForm
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    ) : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/ticket-editform">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (checkAccess(userPermissions, prospectCentral,
                    modules.serviceDesk, permissions.manageServiceDesk, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <TicketForm
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    ) : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/import-summary">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  )
                    : (
                      <EntitiesProvider>
                        <ImportSummary
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/sales-navigator">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  )
                    : (
                      <EntitiesProvider>
                        <SalesNavigator
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/linkedin">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  )
                    : (
                      <EntitiesProvider>
                        <LinkedIn
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/apollo-io">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  )
                    : (
                      <EntitiesProvider>
                        <Apollo
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/zoominfo">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  )
                    : (
                      <EntitiesProvider>
                        <ZoomInfo
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/sales-navigator-summary">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  )
                    : (
                      <EntitiesProvider>
                        <SummarySales
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/contact-central-edit">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, prospectCentral,
                      modules.contacts, permissions.addEditContact, userInfo?.roleid) ? (
                      <EntitiesProvider>
                        <ContactCRMedit
                          addDeal={checkAccess(userPermissions, prospectCentral,
                            modules.deals, permissions.manageDeals, userInfo?.roleid)}
                          editContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          editDeal={checkAccess(userPermissions,
                            prospectCentral, modules.deals,
                            permissions.manageDeals, userInfo?.roleid)}
                          deleteDeal={checkAccess(userPermissions, prospectCentral,
                            modules.deals, permissions.manageDeals, userInfo?.roleid)}
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )
                      : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/organizations">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (checkAccess(userPermissions, prospectCentral,
                    modules.accounts, permissions.accountsList, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <Organization
                          isMasterUser={masterUser}
                          history={history}
                          addOrganization={checkAccess(userPermissions, prospectCentral,
                            modules.accounts, permissions.accountsCreateEdit, userInfo?.roleid)}
                          editAccounts={checkAccess(userPermissions, prospectCentral,
                            modules.accounts, permissions.accountsCreateEdit, userInfo?.roleid)}
                          accountsDelete={checkAccess(userPermissions, prospectCentral,
                            modules.accounts, permissions.accountsDelete, userInfo?.roleid)}
                        />
                      </EntitiesProvider>
                    ) : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/contact-list">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <ContactList
                        isMasterUser={masterUser}
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/documents">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      {checkAccess(userPermissions, prospectCentral,
                        modules.documents, permissions.documentsList, userInfo?.roleid) ?
                        <Documents
                          isMasterUser={masterUser}
                          manageDocuments={checkAccess(userPermissions, prospectCentral,
                            modules.documents, permissions.manageDocuments, userInfo?.roleid)}
                          history={history}
                        />
                        : <AccessError />}
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/prospect-central">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (checkAccess(userPermissions, prospectCentral,
                    modules.prospects, permissions.prospects, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <ProspectCentral isMasterUser={masterUser} history={history} />
                      </EntitiesProvider>
                    ) : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/deal-central">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <>
                      {checkAccess(userPermissions, prospectCentral, modules.deals,
                        permissions.dealsList, userInfo?.roleid) ? (
                        <EntitiesProvider>
                          <Deal
                            addDeal={checkAccess(userPermissions, prospectCentral,
                              modules.deals, permissions.manageDeals, userInfo?.roleid)}
                            editDeal={checkAccess(userPermissions, prospectCentral,
                              modules.deals, permissions.manageDeals, userInfo?.roleid)}
                            deleteDeal={checkAccess(userPermissions, prospectCentral,
                              modules.deals, permissions.manageDeals, userInfo?.roleid)}
                            isMasterUser={masterUser}
                            history={history}
                          />
                        </EntitiesProvider>
                      ) : <AccessError />}
                    </>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/paymentinvoice">
                  {checkAccess(userPermissions, salesCentral, modules.paymentInvoice,
                    permissions.paymentInvoiceList, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <PaymentInvoice
                        isMasterUser={masterUser}
                        history={history}
                        managePaymentInvoice={checkAccess(userPermissions, salesCentral, modules.paymentInvoice,
                          permissions.managePaymentInvoice, userInfo?.roleid)}
                      />
                    </EntitiesProvider>
                  ) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/AddInvoice">
                  <AddInvoice
                    isMasterUser={masterUser}
                    history={history}
                  />
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/call">
                  {checkAccess(userPermissions, prospectCentral, modules.calls,
                    permissions.CallsList, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <Call
                          addDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                          editDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                          deleteDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </CampaginProvider>
                    </EntitiesProvider>
                  ) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/email-find">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, prospectCentral, modules.emailFinder,
                      permissions.manageEmailFinder, userInfo?.roleid) ? <EmailFinder
                      history={history}
                    /> : <AccessError />}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/task">
                  {checkAccess(userPermissions, prospectCentral, modules.tasks,
                    permissions.tasksList, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <Task
                        manageTask={checkAccess(userPermissions, prospectCentral,
                          modules.tasks, permissions.manageTasks, userInfo?.roleid)}
                        isMasterUser={masterUser}
                        history={history}
                      />
                    </EntitiesProvider>
                  ) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/content-marketing">
                  <EntitiesProvider>
                    <CampaginProvider>
                      <AddContactMarketing
                        isMasterUser={masterUser}
                        history={history}
                      />
                    </CampaginProvider>
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/marketing-central/create-newsletter">
                  <EntitiesProvider>
                    <CampaginProvider>
                      <AddNewsLetter
                        isMasterUser={masterUser}
                        history={history}
                      />
                    </CampaginProvider>
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/marketing-central/social-media">
                  <EntitiesProvider>
                    <CampaginProvider>
                      <Socialmedia
                        isMasterUser={masterUser}
                        history={history}
                      />
                    </CampaginProvider>
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/marketing-central/social-media-list">
                  {checkAccess(userPermissions, marketingCentral, modules.socialMedia,
                    permissions.socialMediaList, userInfo?.roleid) ? (<EntitiesProvider>
                      <CampaginProvider>
                        <Soical
                          isMasterUser={masterUser}
                          manageSocialMedia={checkAccess(userPermissions, marketingCentral, modules.socialMedia,
                            permissions.manageSocialMedia, userInfo?.roleid)}
                          history={history}
                        />
                      </CampaginProvider>
                    </EntitiesProvider>) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/create-post">
                  <EntitiesProvider>
                    <CampaginProvider>
                      <CreatePost
                        isMasterUser={masterUser}
                        history={history}
                      />
                    </CampaginProvider>
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/Affiliate-marketing">
                  <EntitiesProvider>
                    <CampaginProvider>
                      <EditAffiliatePage
                        isMasterUser={masterUser}
                        history={history}
                      />
                    </CampaginProvider>
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/editDeal">
                  {checkAccess(userPermissions, prospectCentral, modules.deals,
                    permissions.manageDeals, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <EditDeal
                          history={history}
                        />
                      </EntitiesProvider>
                    ) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/organization-edit">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, prospectCentral,
                      modules.accounts, permissions.accountsCreateEdit, userInfo?.roleid) ? (
                      <EntitiesProvider>
                        <Organizationedit
                          addDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                          editContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          editDeal={checkAccess(userPermissions,
                            salesCentral, modules.manageSalesDeal,
                            permissions.editDeal, userInfo?.roleid)}
                          deleteDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                          isMasterUser={masterUser}
                          history={history}
                        />
                      </EntitiesProvider>
                    )
                      : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/import" component={ImportCustomer} />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting">
                  {(!globalContext.globalState.currentLocation.entityid
                    && (userInfo?.roleid !== Roles.AgencyManager
                      && userInfo?.roleid !== Roles.AgencyAdmin)) ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <Setting
                      isMasterUser={userInfo?.roleid === Roles.BusinessAdmin}
                      manageBusiness={checkAccess(userPermissions, home,
                        modules.settings, permissions.manageBusiness, userInfo?.roleid)}
                      manageBrand={checkAccess(userPermissions, home,
                        modules.settings, permissions.manageYourBrand, userInfo?.roleid)}
                      manageRoleAccess={checkAccess(userPermissions, home,
                        modules.settings, permissions.addRole, userInfo?.roleid)}
                      manageProperties={checkAccess(userPermissions, home,
                        modules.settings, permissions.manageProperties, userInfo?.roleid)}
                      manageVerifyDomain={checkAccess(userPermissions, home,
                        modules.settings, permissions.veriyDomain, userInfo?.roleid)}
                      manageWhitelable={checkAccess(userPermissions, home,
                        modules.settings, permissions.whiteLabelSettings, userInfo?.roleid)}
                      manageApiDoc={checkAccess(userPermissions, home,
                        modules.settings, permissions.apiDoc, userInfo?.roleid)}
                      manageUserManagement={checkAccess(userPermissions, home,
                        modules.settings, permissions.manageUser, userInfo?.roleid)}
                      addUser={checkAccess(userPermissions, home,
                        modules.settings, permissions.addUser, userInfo?.roleid)}
                      deleteUser={checkAccess(userPermissions, home,
                        modules.settings, permissions.deleteUser, userInfo?.roleid)}
                      editAccess={checkAccess(userPermissions, home,
                        modules.settings, permissions.editAccess, userInfo?.roleid)}
                      addRole={checkAccess(userPermissions, home,
                        modules.settings, permissions.addRole, userInfo?.roleid)}
                      editRole={checkAccess(userPermissions, home,
                        modules.settings, permissions.editRole, userInfo?.roleid)}
                      manageLocation={checkAccess(userPermissions, home,
                        modules.settings, permissions.manageLocation, userInfo?.roleid)}
                      manageFetchReview={checkAccess(userPermissions, home,
                        modules.settings, permissions.fetchOnlineReview, userInfo?.roleid)}
                      manageCampaignSetting={checkAccess(userPermissions, home,
                        modules.settings, permissions.campaignSettings, userInfo?.roleid)}
                      manageAutomation={checkAccess(userPermissions, home,
                        modules.settings, permissions.processAutomation, userInfo?.roleid)}
                      manageProfile={true}
                      manageThirdPartySetting={checkAccess(userPermissions, home,
                        modules.settings, permissions.thirdPartySettings, userInfo?.roleid)}
                      getToogleBodyStatus={
                        !globalContext.globalState.currentLocation
                          .entityid
                          ? getToogleBodyStatus
                          : ''
                      }
                    />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/manage-customer">
                  <EntitiesProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      checkAccess(userPermissions, prospectCentral,
                        modules.contacts, permissions.contacts, userInfo?.roleid)
                        ? (
                          <Customer
                            isMasterUser={masterUser}
                            addDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                            editDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                            deleteDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                            addCustomer={checkAccess(userPermissions, prospectCentral,
                              modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                            editCustomer={checkAccess(userPermissions, prospectCentral,
                              modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                            deleteCustomer={checkAccess(userPermissions, reputationCentral,
                              modules.contacts, permissions.deleteCustomer, userInfo?.roleid)}
                            sendQuickMail
                            viewCustomerList
                            importCustomer={checkAccess(userPermissions, reputationCentral,
                              modules.contacts, permissions.importCustomer, userInfo?.roleid)}
                            history={history}
                          />
                        )
                        : <AccessError />
                    )}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/quick-reviews">
                  <EntitiesProvider>
                    {masterUser ? (<AccessError />) : (<QuickReview />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/monitor-reputation">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (checkAccess(userPermissions, reputationCentral,
                    modules.monitorReputation, permissions.monitorReputationList, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <Reviewboard
                          isMasterUser={masterUser}
                          filterReviews
                          sendReplyToReview={checkAccess(userPermissions, reputationCentral,
                            modules.monitorReputation, permissions.monitorReputationList, userInfo?.roleid)}
                          report={checkAccess(userPermissions, reputationCentral,
                            modules.monitorReputation, permissions.monitorReputationList, userInfo?.roleid)}
                          autoReply={checkAccess(userPermissions, reputationCentral,
                            modules.monitorReputation, permissions.monitorReputationList, userInfo?.roleid)}
                          shareReview
                          viewCustomerInfo
                          addNote
                          changeReviewAction
                          history={history}
                        />
                      </EntitiesProvider>
                    )
                    : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/display/social-sharing">
                  {checkAccess(userPermissions, promotionCentral, modules.socialSharing,
                    permissions.socialShare, userInfo?.roleid)
                    ? <SocialSharing />
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/promotion-central/kiosk-mode">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, marketingCentral, modules.reviewMarketing,
                      permissions.ReviewMarketingList, userInfo?.roleid)
                      ? <QRcodeimg isMasterUser={masterUser} /> : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/reputation-central/touchless-reviews"
                >
                  <TochlessQRcode isMasterUser={masterUser} />
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/bix-connect-page">
                  <EntitiesProvider>
                    <PublicPage bixpandpage />
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/reputation-central/launch-campaign"
                  render={({ locationCampaign }) => (
                    <CampaginProvider>
                      {!globalContext.globalState.currentLocation
                        .entityid ? (
                        <>
                          <SelectLocation />
                        </>
                      ) : (
                        <EntitiesProvider>
                          {checkAccess(userPermissions, reputationCentral,
                            modules.launchCampaign,
                            permissions.manageLaunchCampaign, userInfo?.roleid)
                            ? (
                              <Reviewcam
                                isMasterUser={masterUser}
                                launchCampaign
                                history={history}
                                key={locationCampaign?.search || ''}
                              />
                            ) : <AccessError />}
                        </EntitiesProvider>
                      )}
                    </CampaginProvider>
                  )}
                />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/linkedin-automation">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      checkAccess(userPermissions, leadCentral,
                        modules.linkedInSequence,
                        permissions.linkedInSequenceList, userInfo?.roleid) ? (<EntitiesProvider>
                          <LinkedinAutomation
                            history={history}
                            manageLinkedinSeq={checkAccess(userPermissions, leadCentral,
                              modules.linkedInSequence,
                              permissions.manageLinkedInSequence, userInfo?.roleid)}
                          />
                        </EntitiesProvider>) : <AccessError />
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/linkedin-account">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <EntitiesProvider>
                        <LinkedinAccount
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/create-sequence">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <EntitiesProvider>
                        <CreateAutomation
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/workflow-automation">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <EntitiesProvider>
                        {checkAccess(userPermissions, leadCentral,
                          modules.workflows,
                          permissions.workflowList, userInfo?.roleid) ?
                          <WorkflowAutomation
                            history={history}
                            manageWorkflow={checkAccess(userPermissions, leadCentral,
                              modules.workflows,
                              permissions.manageWorkflows, userInfo?.roleid)}
                          />
                          : <AccessError />}
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/workflow-templates">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <EntitiesProvider>
                        <WorkflowTemplates
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/marketing-central/news-letter">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <EntitiesProvider>
                        {checkAccess(userPermissions, marketingCentral,
                          modules.newsLetter,
                          permissions.newsLetterList, userInfo?.roleid) ? (
                          <NewsLetter
                            history={history}
                            manageNewsletter={checkAccess(userPermissions, marketingCentral,
                              modules.newsLetter,
                              permissions.manageNewsLetter, userInfo?.roleid)}
                          />
                        ) : <AccessError />}
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/create-workflow">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (

                      <EntitiesProvider>
                        <CreateWorkflow
                          isMasterUser={masterUser}
                          history={history}
                          addCustomer={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                        />
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/create-workflow-template">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (

                      <EntitiesProvider>
                        <CreateWorkflowTemplate
                          isMasterUser={masterUser}
                          history={history}
                          addCustomer={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                        />
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/workflow/add-recipients">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <WorkflowContact
                        isMasterUser={masterUser}
                        history={history}
                        addCustomer={checkAccess(userPermissions, prospectCentral,
                          modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                        addContact={checkAccess(userPermissions, prospectCentral,
                          modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                        editContact={checkAccess(userPermissions, prospectCentral,
                          modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                        exportContact={checkAccess(userPermissions, prospectCentral,
                          modules.contacts, permissions.exportContact, userInfo?.roleid)}
                        addImport={checkAccess(userPermissions, leadCentral,
                          modules.importLeads, permissions.manageImportLeads, userInfo?.roleid)}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/linkedin-sequence/add-recipients">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <AutomationContact
                        isMasterUser={masterUser}
                        history={history}
                        addCustomer
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/workflow/workflow-report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <WorkflowReport
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/linkedin-sequence/sequenet-report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <AutomationReport
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/email">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (

                      <EntitiesProvider>
                        <EmailMarketing
                          isMasterUser={masterUser}
                          addContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          editContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          addCustomer={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          addDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                          editDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                          deleteDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/content-marketing">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      checkAccess(userPermissions, marketingCentral,
                        modules.pages, permissions.PagesList, userInfo?.roleid)
                        ? (
                          <EntitiesProvider>
                            <ContactMarketing
                              history={history}
                              managePages={checkAccess(userPermissions, marketingCentral,
                                modules.pages, permissions.managePages, userInfo?.roleid)}
                            />
                          </EntitiesProvider>
                        ) : <AccessError />
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/text">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (

                      <EntitiesProvider>
                        <TextMarketing
                          isMasterUser={masterUser}
                          addContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          editContact={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          addCustomer={checkAccess(userPermissions, prospectCentral,
                            modules.contacts, permissions.addEditContact, userInfo?.roleid)}
                          addDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                          editDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                          deleteDeal={checkAccess(userPermissions, salesCentral,
                            modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                          history={history}
                        />
                      </EntitiesProvider>
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/customer-add-customer">
                  <EntitiesProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <AddCustomer isMasterUser={masterUser} history={history} />
                    )}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/customer-import-customer">
                  <EntitiesProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <ImportContacts history={history} />
                    )}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/import-contact">
                  <EntitiesProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      <ImportCustomers history={history} />
                    )}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/reputation-central/customer-import"
                >
                  {checkAccess(userPermissions, prospectCentral, modules.importLeads,
                    permissions.manageImportLeads, userInfo?.roleid)
                    ? <ImportContacts history={history} />
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/review-campaigns">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      (checkAccess(userPermissions, reputationCentral,
                        modules.reviewCampaigns, permissions.reviewCampaignsList, userInfo?.roleid) || checkAccess(userPermissions, marketingCentral, modules.reviewMarketing,
                          permissions.manageReviewMarketing, userInfo?.roleid))
                        ? (
                          <EntitiesProvider>
                            <CampaignManager
                              isMasterUser={masterUser}
                              manageReviewCampaign={checkAccess(userPermissions, reputationCentral,
                                modules.reviewCampaigns, permissions.manageReviewCampaigns, userInfo?.roleid)}
                              viewCampainList
                              campaignFilters
                              viewCampaignReport
                            />
                          </EntitiesProvider>
                        ) : <AccessError />
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/email-marketing">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      checkAccess(userPermissions, marketingCentral,
                        modules.emailMarketing, permissions.emailMarketingList, userInfo?.roleid)
                        ? (
                          <EntitiesProvider>
                            <EmailCampaignManager
                              isMasterUser={masterUser}
                              manageEmailMarketing={checkAccess(userPermissions, marketingCentral,
                                modules.emailMarketing, permissions.manageEmailMarketing, userInfo?.roleid)}
                              viewCampainList
                              createCampaign
                              campaignFilters
                              editCampaign
                              deleteCampaign
                              viewCampaignReport
                              history={history}
                            />
                          </EntitiesProvider>
                        )
                        : <AccessError />
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/email-Templates">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      checkAccess(userPermissions, marketingCentral,
                        modules.pages, permissions.managePages, userInfo?.roleid)
                        ? (
                          <EntitiesProvider>
                            <Templates
                              isMasterUser={masterUser}
                              history={history}
                            />
                          </EntitiesProvider>
                        )
                        : <AccessError />
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/text-marketing">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : (
                      checkAccess(userPermissions, marketingCentral,
                        modules.textMarketing, permissions.textMarketingList, userInfo?.roleid)
                        ? (
                          <EntitiesProvider>
                            <TextCampaignManager
                              isMasterUser={masterUser}
                              manageTextMarketing={checkAccess(userPermissions, marketingCentral,
                                modules.textMarketing, permissions.manageTextMarketing, userInfo?.roleid)}
                              viewCampainList
                              createCampaign
                              campaignFilters
                              editCampaign
                              deleteCampaign
                              viewCampaignReport
                              history={history}
                            />
                          </EntitiesProvider>
                        )
                        : <AccessError />
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/Smscampaign-report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <SmsCampainReport
                        history={history}
                        addDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                        editDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                        deleteDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/reputation-central/campaign-report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <CampaignReport
                        history={history}
                        addDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                        editDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                        deleteDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/document-report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <DocumentReport
                        history={history}
                        addDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                        editDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                        deleteDeal={checkAccess(userPermissions, salesCentral,
                          modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/lead-central/forms-report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <CaptureLeadsReport
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/contentmarketing/content_report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <ContactReport
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/sales-central/product_report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <ProductReport
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/marketing-central/newsletter_report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <NewsLetterReport
                          history={history}
                        />
                      </CampaginProvider>
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/marketing-central/webpage_report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <WebPageReport
                          history={history}
                        />
                      </CampaginProvider>
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/affiliate-link/affiliate_report">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <AffiliateReport
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-user-management">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.manageUser, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <UserManagement history={history} />
                      </EntitiesProvider>
                    ) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-user-management-dashboard">
                  {(userInfo?.roleid === Roles.BusinessAdmin) && checkAccess(userPermissions, home,
                    modules.settings, permissions.addRole, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <UserManagementDashboard
                        createUser
                        editUser
                        viewUserList
                        history={history}
                      />
                    </EntitiesProvider>
                  ) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/properties">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.manageProperties, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <Properties
                          history={history}
                        />
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/verify-domain">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.veriyDomain, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <Domain
                          history={history}
                        />
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/white-label-setting">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.whiteLabelSettings, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <WhiteLabel
                          history={history}
                        />
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/api-documentation">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.apiDoc, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <ApiDocumentation
                          history={history}
                        />
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-user-management-edit">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.manageUser, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <UserManagementEdit history={history} />
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-edit-access">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.editAccess, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <EditAccess history={history} />
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/invoice">
                  <Invoice history={history} />
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/plans">
                  {(checkAccess(userPermissions, home,
                    modules.plan, permissions.managePlan, userInfo?.roleid))
                    ? (
                      <EntitiesProvider>
                        <PaymentProvider>
                          <Plans view history={history} />
                        </PaymentProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/services">
                  {userInfo?.roleid
                    ? (
                      <EntitiesProvider>
                        <PaymentProvider>
                          <Services view history={history} />
                        </PaymentProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/users">
                  {userInfo?.roleid
                    ? (
                      <EntitiesProvider>
                        <PaymentProvider>
                          <Users view history={history} />
                        </PaymentProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/topup">
                  {userInfo?.roleid
                    ? (
                      <EntitiesProvider>
                        <PaymentProvider>
                          <Topup view history={history} />
                        </PaymentProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/home/plans">
                  {userInfo?.roleid
                    ? (
                      <EntitiesProvider>
                        <PaymentProvider>
                          <AdminPlans history={history} />
                        </PaymentProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-manage-agency">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : checkAccess(userPermissions, settings, modules.settings,
                    permissions.manageBusiness, userInfo?.roleid)
                    ? <ManageAgency />
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/promotion-central/showcase-reviews">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, marketingCentral, modules.reviewMarketing,
                      permissions.reviewCampaignsList, userInfo?.roleid)
                      ? (
                        <Widgetlibary
                          viewWidgets
                          getLibraryCode={!masterUser}
                          getWidgetCode={!masterUser}
                        />
                      )
                      : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/promotion-central/review-page">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, marketingCentral,
                      modules.pages, permissions.managePages, userInfo?.roleid)
                      ? (
                        <PageWidget
                          getLibraryCode={!masterUser}
                          getWidgetCode={!masterUser}
                        />
                      )
                      : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/Widget-libary/add-widget-product"
                  component={Addwidgetproduct}
                />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/promotion-central/review-badge">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, marketingCentral,
                      modules.reviewMarketing, permissions.ReviewMarketingList, userInfo?.roleid)
                      ? (
                        <ReviewBadge
                          getLibraryCode={!masterUser}
                          getWidgetCode={!masterUser}
                        />
                      )
                      : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/Widget-libary/add-widget-mini"
                  component={Addwidgetmini}
                />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/Widget-libary/add-widget-list"
                  component={Addwidgetlist}
                />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/Widget-libary/add-widget-minisecond"
                  component={Addwidgetminisecond}
                />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route
                  path="/app/Widget-libary/add-widget-slider"
                  component={Addwidgetslider}
                />
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-manage-your-brand">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      {checkAccess(userPermissions, home,
                        modules.settings, permissions.manageYourBrand, userInfo?.roleid)
                        ? (
                          <ManageYourBrand
                            isMasterUser={masterUser}
                            history={history}
                            editBrandSetting
                          />
                        )
                        : <AccessError />}
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-manage-business">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      {checkAccess(userPermissions, home, modules.settings,
                        permissions.manageBusiness, userInfo?.roleid)
                        ? (
                          <ManageBusiness
                            isMasterUser={masterUser}
                            history={history}
                          />
                        )
                        : <AccessError />}
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-manage-location">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, settings, modules.manageLocation,
                      permissions.manageLocation, userInfo?.roleid)
                      ? <ManageLocation />
                      : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-communication-setting">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      <CommunicationSetting
                        editCommunicationSetting
                        history={history}
                      />
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-fetch-online-reviews">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, home, modules.settings,
                      permissions.fetchOnlineReview, userInfo?.roleid)
                      ? (
                        <EntitiesProvider>
                          <FetchYourOnlineReview history={history} isMasterUser={masterUser} />
                        </EntitiesProvider>
                      )
                      : <AccessError />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-process-automation">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    checkAccess(userPermissions, home, modules.settings,
                      permissions.processAutomation, userInfo?.roleid)
                      ? <ProcessAutomation history={history} /> : <AccessError />
                  )}
                </Route>
              </Suspense>
              <UserProvider>
                <Suspense fallback={<LazyLoader />}>
                  <Route path="/app/setting-my-profile">
                    {checkAccess(userPermissions, home, modules.settings,
                      permissions.myProfile, userInfo?.roleid)
                      ? (
                        <MyProfile
                          editAccountDetails
                          editChangePassword
                          history={history}
                        />
                      )
                      : <AccessError />}
                  </Route>
                </Suspense>
                <Suspense fallback={<LazyLoader />}>
                  <Route path="/app/third-calender">
                    {checkAccess(userPermissions, home, modules.settings,
                      permissions.myProfile, userInfo?.roleid)
                      ? (
                        <EntitiesProvider>
                          <CampaginProvider>
                            <ThirdCalender
                              editAccountDetails
                              editChangePassword
                              history={history}
                            />
                          </CampaginProvider>
                        </EntitiesProvider>
                      )
                      : <AccessError />}
                  </Route>
                </Suspense>
                <Suspense fallback={<LazyLoader />}>
                  <Route path="/app/setting-payment">
                    <EntitiesProvider>
                      <CampaginProvider>
                        <PaymentSetting
                          history={history}
                        />
                      </CampaginProvider>
                    </EntitiesProvider>
                  </Route>
                </Suspense>
                <Suspense fallback={<LazyLoader />}>
                  <Route path="/app/afilliate-link">
                    <EntitiesProvider>
                      <CampaginProvider>
                        <AfilliateLink
                          history={history}
                        />
                      </CampaginProvider>
                    </EntitiesProvider>
                  </Route>
                </Suspense>
              </UserProvider>
              <UserProvider>
                <Suspense fallback={<LazyLoader />}>
                  <Route path="/app/usage-report">
                    {checkAccess(userPermissions, home, modules.settings,
                      permissions.myProfile, userInfo?.roleid)
                      ? (
                        <EntitiesProvider>
                          <UsageReport
                            addDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.addDeal, userInfo?.roleid)}
                            editDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.editDeal, userInfo?.roleid)}
                            deleteDeal={checkAccess(userPermissions, salesCentral,
                              modules.manageSalesDeal, permissions.deleteDeal, userInfo?.roleid)}
                            history={history}
                          />
                        </EntitiesProvider>
                      )
                      : <AccessError />}
                  </Route>
                </Suspense>
              </UserProvider>
              <UserProvider>
                <Suspense fallback={<LazyLoader />}>
                  <Route path="/app/my-referral">
                    {checkAccess(userPermissions, home, modules.settings,
                      permissions.myProfile, userInfo?.roleid)
                      ? (
                        <EntitiesProvider>
                          <Refercode
                            history={history}
                          />
                        </EntitiesProvider>
                      )
                      : <AccessError />}
                  </Route>
                </Suspense>
              </UserProvider>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/insights-central/reputation-dashboard">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <EntitiesProvider>
                      {checkAccess(userPermissions, insightsCentral, modules.monitorReputation,
                        permissions.monitorReputationList, userInfo?.roleid)
                        ? <Analytics history={history} /> : <AccessError />}
                    </EntitiesProvider>
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/insights-central/payment-dashboard">
                  {checkAccess(userPermissions, insightsCentral, modules.paymentDashboard,
                    permissions.managePaymentDashboard, userInfo?.roleid) ? (<EntitiesProvider>
                      <CampaginProvider>
                        <PaymentDashboard history={history} />
                      </CampaginProvider>
                    </EntitiesProvider>) : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/insights-central/contact-dashboard">
                  {checkAccess(userPermissions, insightsCentral, modules.contactDashboard,
                    permissions.manageContactDashboard, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <ContactDashboard history={history} />
                      </CampaginProvider>
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/insights-central/dashboard">
                  {checkAccess(userPermissions, insightsCentral, modules.contactDashboard,
                    permissions.manageContactDashboard, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <PowerBIDashboard history={history} />
                      </CampaginProvider>
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/insights-central/marketing-dashboard">
                  {checkAccess(userPermissions, insightsCentral, modules.marketingDashboard,
                    permissions.manageMarketingDashboard, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <MarketingDashboard history={history} />
                      </CampaginProvider>
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/insights-central/email-report">
                  {checkAccess(userPermissions, insightsCentral, modules.emailReport,
                    permissions.manageEmailReport, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <EmailReport
                          history={history}
                          isFromWorkFlow={false}
                        />
                      </CampaginProvider>
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/insights-central/sales-dashboard">
                  {checkAccess(userPermissions, insightsCentral, modules.salesDashboard,
                    permissions.manageSalesDashboard, userInfo?.roleid) ? (
                    <EntitiesProvider>
                      <CampaginProvider>
                        <SalesDashboard history={history} />
                      </CampaginProvider>
                    </EntitiesProvider>
                  )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-google-business">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : accessPermissions.googleMyBusinessPage ? (
                    <SettingGooglebusiness isMasterUser={masterUser} history={history} />
                  ) : (
                    accessComponent
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-facebook-business">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <Facebookbusiness />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-yelp-business">
                  {!globalContext.globalState.currentLocation.entityid ? (
                    <>
                      <SelectLocation />
                    </>
                  ) : (
                    <Yelpbusiness />
                  )}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/setting-campagin-setting">
                  <CampaginProvider>
                    {!globalContext.globalState.currentLocation.entityid ? (
                      <>
                        <SelectLocation />
                      </>
                    ) : checkAccess(userPermissions, home, modules.settings,
                      permissions.campaignSettings, userInfo?.roleid) ? (
                      <EntitiesProvider>
                        <CampaignSetting history={history} />
                      </EntitiesProvider>
                    ) : (
                      accessComponent
                    )}
                  </CampaginProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/HelpAdvisors">
                  {checkAccess(userPermissions, professionalServices,
                    modules.professionalServices,
                    permissions.manageProfessionalServices, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <CampaginProvider>
                          <HelpAdvisors history={history} />
                        </CampaginProvider>
                      </EntitiesProvider>
                    )
                    : <AccessError />}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/sales-order">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, salesCentral, modules.salesOrders,
                      permissions.salesOrdersList, userInfo?.roleid) ? (
                      <OrderModule
                        isMasterUser={masterUser}
                        history={history}
                        manageSalesOrder={checkAccess(userPermissions, salesCentral, modules.salesOrders,
                          permissions.manageSalesOrders, userInfo?.roleid)}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/create-sales-order">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, salesCentral, modules.salesOrders,
                      permissions.manageSalesOrders, userInfo?.roleid) ? (
                      <CreateOrderModule
                        isMasterUser={masterUser}
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/tickets">
                  <EntitiesProvider>
                    {checkAccess(userPermissions, prospectCentral, modules.serviceDesk,
                      permissions.serviceDeskList, userInfo?.roleid) ? (
                      <TicketList
                        isMasterUser={masterUser}
                        manageTicket={checkAccess(userPermissions, prospectCentral, modules.serviceDesk,
                          permissions.manageServiceDesk, userInfo?.roleid)}
                        history={history}
                      />
                    ) : (<AccessError />)}
                  </EntitiesProvider>
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/roles-list">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.addRole, userInfo?.roleid) ? (<EntitiesProvider>
                      <RoleList
                        isMasterUser={masterUser}
                        history={history}
                        addRoleAccess={checkAccess(userPermissions, home,
                          modules.settings, permissions.addRole, userInfo?.roleid)}
                        editRoleAccess={checkAccess(userPermissions, home,
                          modules.settings, permissions.editRole, userInfo?.roleid)}
                        deleteRoleAccess={checkAccess(userPermissions, home,
                          modules.settings, permissions.deleteRole, userInfo?.roleid)}
                      />
                    </EntitiesProvider>
                  ) : (<AccessError />)}
                </Route>
              </Suspense>
              <Suspense fallback={<LazyLoader />}>
                <Route path="/app/roles">
                  {checkAccess(userPermissions, home,
                    modules.settings, permissions.addRole, userInfo?.roleid)
                    ? (
                      <EntitiesProvider>
                        <RolesAccess
                          isMasterUser={userInfo?.roleid}
                          history={history}
                        />
                      </EntitiesProvider>
                    ) : <AccessError />
                  }
                </Route>
              </Suspense>
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default withRouter(Layout);
