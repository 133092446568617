import React, { Component } from 'react';
import { Button } from '@material-ui/core';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
          backgroundColor: localStorage.getItem('theme') === 'dark' ? '#23232d' : 'white',
        }}
        >
          <center>
            <p style={{
              margin: 0, fontSize: 23, textAlign: 'center', fontWeight: 600, fontFamily: 'sans-serif',
            }}
            >
              We could not find the page
              <p style={{ margin: 0 }}>you were looking for</p>
            </p>
          </center>
          <center>
            <a href="/">
              <Button style={{ marginTop: 10 }} color="primary" variant="contained">Go to Home</Button>
            </a>
          </center>
        </div>
      );
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
