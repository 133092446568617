export const TicketFormByLocation = `query TicketFormByLocation($locationid: ID!) {
  ticketFormByLocation(locationid: $locationid) {
    fields
    formid
  }
}`;

export const FormValidate = `query TicketFormByLocation($locationid: ID!) {
  ticketFormByLocation(locationid: $locationid) {
    formid
  }
}`;

export const CustomPropertiesByBusiness = `query CustomPropertiesByBusiness($businessid: ID!, $objecttype: String) {
  customPropertiesByBusiness(businessid: $businessid, objecttype: $objecttype) {
    rows {
      name
      locationid
      sectionid
      sequence
      objecttype
      label
      isshowinform
      isrequired
      fieldtype
      description
      propertyid
      dbfieldname
      options
    }
  }
}`;

export const CreateTicketForm = `mutation CreateTicketForm($fields: String!, $businessid: ID!, $locationid: ID!) {
  createTicketForm(fields: $fields, businessid: $businessid, locationid: $locationid) {
    formid
  }
}`;

export const EditTicketForm = `mutation EditTicketForm($formid: ID!, $fields: String!) {
  editTicketForm(formid: $formid, fields: $fields)
}`;

export const DeleteTicketForm = `mutation DeleteTicketForm($formid: ID!) {
  deleteTicketForm(formid: $formid)
}`;

export const ContactByEmail = `query Contactbyemail($locationid: ID!, $primaryemail: String!) {
  contactbyemail(locationid: $locationid, primaryemail: $primaryemail) {
    firstname
    lastname
    phonenumber
    primaryemail
  }
}
 `;

export const RaiseTicket = `mutation RaiseTicket($responsedata: String!, $fromadmin: Boolean!, $locationid: ID!, $businessid: ID!) {
  raiseTicket(responsedata: $responsedata, fromadmin: $fromadmin, locationid: $locationid, businessid: $businessid) {
    ticketid
    ticketno
    subject
    type
    priority
    topic
    status
    firstname
    lastname
    primaryemail
    phonenumber
    locationid
    businessid
    contactid
    createdAt
  }
}`;

export const UpdateTicket = `mutation UpdateTicket($ticketid: ID!, $locationid: ID!, $businessid: ID!, $updatedby: ID!, $assignee: String, $status: Int) {
  updateTicket(ticketid: $ticketid, locationid: $locationid, businessid: $businessid, updatedby: $updatedby, assignee: $assignee, status: $status)
}`;

export const Ticketstatushistory = `query Ticketstatushistory($ticketid: ID!) {
  ticketstatushistory(ticketid: $ticketid) {
    ticketid
    status
    createdAt
    updatedAt
  }
}`;

export const Raiseticketbylocation = `query Raiseticketbylocation($sortby: SortBy, $locationid: ID!, $pagenumber: Int!, $pagesize: Int!, $searchText: String, $status: [Int!], $topic: [Int!], $priority: [Int!], $type: [Int!]) {
  raiseticketbylocation(sortby: $sortby, locationid: $locationid, pagenumber: $pagenumber, pagesize: $pagesize, searchText: $searchText, status: $status, topic: $topic, priority: $priority, type: $type) {
    count
    rows {
      type
      topic
      ticketno
      ticketid
      subject
      status
      priority
      primaryemail
      phonenumber
      locationid
      lastupdatedAt
      lastresponsebyuser
      lastresponsebyadmin
      lastname
      firstname
      createdAt
      contactid
      businessid
      assignee
    }
  }
}`;

export const Raiseticketbyticketid = `query Raiseticketbyticketid($ticketid: ID!) {
  raiseticketbyticketid(ticketid: $ticketid) {
    ticketid
    ticketno
    subject
    type
    priority
    topic
    status
    firstname
    lastname
    primaryemail
    phonenumber
    assignee
    locationid
    businessid
    contactid
    createdAt
  }
}`;

export const Conversationlistbyticket = `query Conversationlistbyticket($ticketid: ID!, $pagenumber: Int!, $pagesize: Int!) {
  conversationlistbyticket(ticketid: $ticketid, pagenumber: $pagenumber, pagesize: $pagesize) {
    count
    rows {
      ticketid
      contactid
      conversation
      fromadmin
      attachments
      locationid
      businessid
      createdAt
    }
  }
}`;

export const CreateConversation = `mutation CreateConversation($ticketid: ID!, $contactid: ID!, $conversation: String!, $fromadmin: Boolean!, $locationid: ID!, $businessid: ID!, $attachments: String) {
  createConversation(ticketid: $ticketid, contactid: $contactid, conversation: $conversation, fromadmin: $fromadmin, locationid: $locationid, businessid: $businessid, attachments: $attachments) {
    ticketid
    contactid
    conversation
    fromadmin
    attachments
    locationid
    businessid
    createdAt
  }
}`;
