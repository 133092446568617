import React from 'react';

const MyContext = React.createContext();

const rootReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_SUBSCRIPTION_TYPE':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          SubscriptionType: payload,
        },
      };
    case 'SET_PLAN_ID':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          planId: payload,
        },
      };
    case 'SET_SELECTEDDURATION_TYPE':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          selectedDuration: payload,
        },
      };
    case 'SET_SELECTED_USER':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          selectedUser: payload,
        },
      };
    case 'SET_FEATURE':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          feature: {
            ...state.createPaymentInfo.featureInfo,
            selectedFeature: payload,
          },
        },
      };
    case 'SET_FEATURE_TOPUP':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          featureTopup: {
            ...state.createPaymentInfo.featureInfo,
            selectedFeature: payload,
          },
        },
      };
    case 'SET_PLANPRIZE':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          planPrize: payload,
        },
      };
    case 'SET_SELETED_AMOUNT':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          selectedAmount: {
            ...state.createPaymentInfo.amountInfo,
            selectedAmount: payload,
          },
        },
      };
    case 'SET_SELETED_AMOUNTTOPUP':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          selectedAmountTopup: {
            ...state.createPaymentInfo.amountInfo,
            selectedAmountTopup: payload,
          },
        },
      };
    case 'SET_ADDONPLAN_ID':
      return {
        ...state,
        createPaymentInfo: {
          ...state.createPaymentInfo,
          selectedAddonPlanId: {
            ...state.createPaymentInfo.addonInfo,
            selectedAddonPlanId: payload,
          },
        },
      };
    default: break;
  }
  return {
    ...state,
    createPaymentInfo: {
      ...state.createPaymentInfo,
      SubscriptionType: payload?.name,
      selectedUser: payload?.user,
      feature: payload?.feature,
      featureTopup: payload?.featureTopup,
      planPrize: payload?.prize,
      selectedAmount: payload?.selectedAmount,
      selectedAmountTopup: payload?.selectedAmountTopup,
      selectedDuration: payload?.selectedDuration,
      planId: payload?.planId,
      selectedAddonPlanId: payload?.selectedAddonPlanId,
    },
  };
};

const PaymentProvider = ({ children }) => {
  const [payment, setPayment] = React.useReducer(rootReducer, {
    isLoading: false,
    createPaymentInfo: {
      SubscriptionType: '',
      selectedUser: '',
      planPrize: '',
      selectedDuration: '',
      planId: '',
      feature: [],
      featureTopup: [],
      selectedAmountTopup: [],
      selectedAmount: [],
      selectedAddonPlanId: [],
    },
  });
  return (
    <MyContext.Provider value={{ payment, setPayment }}>
      {children}
    </MyContext.Provider>
  );
};

const usePaymentState = () => {
  const context = React.useContext(MyContext);
  return context;
};

const setSubscriptionType = (value, dispatch) => {
  dispatch({ type: 'SET_SUBSCRIPTION_TYPE', payload: value });
};

const setSelectedUser = (value, dispatch) => {
  dispatch({ type: 'SET_SELECTED_USER', payload: value });
};

const setFeature = (value, dispatch) => {
  dispatch({ type: 'SET_FEATURE', payload: value });
};

const setFeatureTopup = (value, dispatch) => {
  dispatch({ type: 'SET_FEATURE_TOPUP', payload: value });
};

const setPlanPrize = (value, dispatch) => {
  dispatch({ type: 'SET_PLANPRIZE', payload: value });
};

const setSelectedAmount = (value, dispatch) => {
  dispatch({ type: 'SET_SELETED_AMOUNT', payload: value });
};

const setSelectedAmountTopup = (value, dispatch) => {
  dispatch({ type: 'SET_SELETED_AMOUNTTOPUP', payload: value });
};

const setSelectedDuration = (value, dispatch) => {
  dispatch({ type: 'SET_SELECTEDDURATION_TYPE', payload: value });
};

const setPlanId = (value, dispatch) => {
  dispatch({ type: 'SET_PLAN_ID', payload: value });
};

const setAddonPlanId = (value, dispatch) => {
  dispatch({ type: 'SET_ADDONPLAN_ID', payload: value });
};

export {
  PaymentProvider,
  setSelectedUser,
  setFeatureTopup,
  setSubscriptionType,
  setFeature,
  usePaymentState,
  setPlanPrize,
  setSelectedAmount,
  setSelectedAmountTopup,
  setSelectedDuration,
  setPlanId,
  setAddonPlanId,
};
