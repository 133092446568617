import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Divider,
  Avatar,
  Typography,
  Button,
  Grid,
  TextField as Input,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import config from '../../config';
import { RatingColor } from '../../common/const';
import { postDataRequestPublic, handleUndefined } from '../../common/commonfunctions';
import useStyles from './styles';
import Widget from '../../components/Widget';

const StyledRating = withStyles((theme) => ({
  iconFilled: {
    color: RatingColor.IconFilled,
  },
  iconHover: {
    color: RatingColor.IconHover,
  },
  iconEmpty: {
    color: RatingColor.IconEmpty,
  },
  sizeLarge: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}))(Rating);
export default function ReviewDisplay() {
  const classes = useStyles();
  const [reviewData, setReviewData] = useState([]);
  const [reviewReply, setReviewReply] = useState([]);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [disabledReviewEdit, setDisabledReviewEdit] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('edit');
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const { reviewid } = useParams([]);
  const locationid = params.get('locationid');
  const businessid = params.get('businessid');
  const [entity, setEntity] = useState([]);
  const [business, setBusiness] = useState([]);
  const [reviewText, setReviewText] = useState();
  const [reviewTitle, setReviewTitle] = useState();
  const [noReviewFound, setNoReviewFound] = useState(false);
  const [dataLoading, setDataLoading] = useState();
  const [reviewRating, setReviewRating] = useState();

  const reviewDataGetting = useCallback(async () => {
    if (handleUndefined(reviewid)) {
      await postDataRequestPublic(config.reviewApi, {
        query: `
            query getReviewsWithCustomerById($reviewid:ID!){
          getReviewsWithCustomerById(reviewid:$reviewid){
            review
            placeid
            campaignid
            customerid
            title
            status
            reviewdatetime
            reviewreplyid
            providedby
            rating
            locationid
            reviewid
            sourcereviewid
            replied
            deleted
            sourceid
            createdby
            firstname
            communicationtype
            reviewtype
            lastname
            reviewaction
            email
            phonenumber
          }}
        `,
        variables: {
          reviewid,
        },
      }).then((json) => {
        if (json?.data?.getReviewsWithCustomerById) {
          setReviewData(json?.data?.getReviewsWithCustomerById);
          setReviewText(json?.data?.getReviewsWithCustomerById?.review);
          setReviewTitle(json?.data?.getReviewsWithCustomerById?.title);
          setReviewRating(json?.data?.getReviewsWithCustomerById?.rating);
        } else {
          setNoReviewFound(true);
        }
      });
      await postDataRequestPublic(config.reviewApi, {
        query: `
                query reviewRepliesByReview($reviewid:ID!){
                    reviewRepliesByReview(reviewid:$reviewid){
                    replytext
                    reviewid
                    reviewreplyid
                  }}
            `,
        variables: {
          reviewid,
        },
      }).then((json) => {
        setReviewReply(json?.data?.reviewRepliesByReview[0]);
      });
    }
  }, [reviewid]);

  const editReviewPublic = () => {
    if (buttonStatus === 'save') {
      setDataLoading(true);
      const CREATE_EDITREVIEW_MUTATION = `
        mutation
            editReview
            (
                $reviewid:ID!,
                $review: String,
                $title: String,
                $rating: Float,
            ){
                editReview(
                    reviewid:$reviewid,
                    review:$review,
                    title: $title,
                    rating: $rating
                )
            }`;
      postDataRequestPublic(config.reviewApi, {
        query: CREATE_EDITREVIEW_MUTATION,
        variables: {
          reviewid,
          review: reviewText,
          title: reviewTitle,
          rating: parseFloat(reviewRating),
        },
      })
        .then(async (res) => {
          setDataLoading(false);
          if (res?.data?.editReview) {
            await reviewDataGetting();
            showNotification(
              'Your review has been successfully edited',
              'success',
            );
          }
        })
        .catch(() => {
          setDataLoading(false);
        });
    }
    setButtonStatus(buttonStatus === 'edit' ? 'save' : 'edit');
    setDisabledReviewEdit(
      !disabledReviewEdit,
    );
  };

  const deletePublicReview = () => {
    const CREATE_DELETEREVIEW_MUTATION = `
        mutation
            deleteReview($reviewid: ID!){
                deleteReview(reviewid: $reviewid)
            }`;
    postDataRequestPublic(config.reviewApi, {
      query: CREATE_DELETEREVIEW_MUTATION,
      variables: {
        reviewid,
      },
    }).then(() => {
      // setDeleteReview(res.data)
      reviewDataGetting();
      showNotification(
        'Your review has been successfully Deleted',
        'success',
      );
    });
  };

  const handleClickOpen = () => {
    setOpenPopup(false);
    deletePublicReview('');
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (handleUndefined(businessid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: `query entity($entityid:ID!){entity(entityid:$entityid){entityid,businessname,brandname
                    firstname,lastname,email,phonenumber,entitytypeid,businesstypeid,jobid,tags,website,shortdesc,fulldesc
                    }}`,

          variables: {
            entityid: businessid && businessid,
          },
        }).then((res) => {
          if (res?.data?.entity) {
            setBusiness(res.data.entity);
          }
        });
      }
      if (handleUndefined(locationid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: `query entity($entityid:ID!){entity(entityid:$entityid){entityid,businessname,brandname
                    firstname,lastname,email,phonenumber,entitytypeid,businesstypeid,jobid,tags,website,shortdesc,fulldesc,policy
                    terms
                    }}`,

          variables: {
            entityid: locationid && locationid,
          },
        }).then((res) => {
          if (res?.data?.entity) {
            setEntity(res.data.entity);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [businessid, locationid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      reviewDataGetting();
    }
    return () => {
      isMounted = false;
    };
  }, [reviewDataGetting]);
  if (
    !noReviewFound
    && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      reviewid,
    )
  ) {
    return (
      <div style={{ marginTop: '30px' }}>
        <Dialog
          open={openPopup}
          onClose={() => {
            setOpenPopup(false);
          }}
          aria-labelledby="responsive-dialog-title"
          fullWidth="fullWidth"
          maxWidth="xs"
        >
          <DialogTitle id="responsive-dialog-title">
            Are you sure to delete this Review?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Review&apos;s data will be deleted
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClickOpen}
              size="small"
              color="primary"
              variant="contained"
              autoFocus
            >
              Agree
            </Button>
            <Button
              autoFocus
              onClick={() => {
                setOpenPopup(false);
              }}
              size="small"
              color="secondary"
              variant="contained"
            >
              Disagree
            </Button>
          </DialogActions>
        </Dialog>
        {dataLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Box className={classes.width}>
              <Widget disablewidgetmenu="true" inheritheight="true">
                <Box display="flex" flexDirection="column">
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      src="12"
                      style={{ marginRight: 8 }}
                    />
                    <Typography block>
                      <Box fontWeight="fontWeightBold">
                        <Typography
                          color="warning"
                          style={{ fontSize: '17px' }}
                        >
                          {reviewData?.firstname}
                          {' '}
                          {reviewData
                            && reviewData?.lastname}
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                  <Box padding="10px">
                    <Divider className={classes.divider} />
                  </Box>
                  <Box
                    style={{
                      padding: '8px',
                      float: 'right',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'flex',
                        float: 'right',
                      }}
                    >
                      <b>
                        {' '}
                        {moment(
                          parseInt(reviewData?.reviewdatetime, 10),
                        )
                          .local()
                          .format('ddd, MMM DD, YYYY')}
                      </b>
                    </Typography>
                    {reviewData?.status === 3 || reviewData?.status === 4
                      ? (
                        <StyledRating
                          name="customized-color size-large"
                          value={parseInt(reviewRating, 10)}
                          onChange={(val) => setReviewRating(val.target.value)}
                          className={
                            classes.topbar
                          }
                          precision={
                            1
                          }
                          size="large"
                          readOnly={disabledReviewEdit}
                        />
                      ) : (
                        <Box display="flex">
                          <div
                            onClick={() => setReviewRating(1)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.redRating} ${reviewRating === 1 ? classes.reviewRating : ''
                            }`}
                          >
                            1
                          </div>
                          <div
                            onClick={() => setReviewRating(2)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.redRating} ${reviewRating === 2 ? classes.reviewRating : ''
                            }`}
                          >
                            2
                          </div>
                          <div
                            onClick={() => setReviewRating(3)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.redRating} ${reviewRating === 3 ? classes.reviewRating : ''
                            }`}
                          >
                            3
                          </div>
                          <div
                            onClick={() => setReviewRating(4)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.redRating} ${reviewRating === 4 ? classes.reviewRating : ''
                            }`}
                          >
                            4
                          </div>
                          <div
                            onClick={() => setReviewRating(5)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.redRating} ${reviewRating === 5 ? classes.reviewRating : ''
                            }`}
                          >
                            5
                          </div>
                          <div
                            onClick={() => setReviewRating(6)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.redRating} ${reviewRating === 6 ? classes.reviewRating : ''
                            }`}
                          >
                            6
                          </div>
                          <div
                            onClick={() => setReviewRating(7)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.yellowRating
                            } ${reviewRating === 7 ? classes.reviewRating : ''
                            }`}
                          >
                            7
                          </div>
                          <div
                            onClick={() => setReviewRating(8)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.yellowRating
                            } ${reviewRating === 8 ? classes.reviewRating : ''
                            }`}
                          >
                            8
                          </div>
                          <div
                            onClick={() => setReviewRating(9)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.greenRating
                            } ${reviewRating === 9 ? classes.reviewRating : ''
                            }`}
                          >
                            9
                          </div>
                          <div
                            onClick={() => setReviewRating(10)}
                            disabled={disabledReviewEdit}
                            className={`${classes.shape} ${classes.greenRating
                            } ${reviewRating === 10 ? classes.reviewRating : ''
                            }`}
                          >
                            10
                          </div>
                        </Box>
                      )}
                  </Box>
                  <Box padding="10px">
                    <Divider className={classes.divider} />
                  </Box>
                  {reviewData?.status === 2 ? (
                    <>
                      <Box style={{ fontSize: '19px', padding: '5px' }}>
                        <Typography style={{ padding: '6px' }}>
                          <b>Title :</b>
                          {' '}
                        </Typography>
                        <Input
                          id="outlined-multiline-static"
                          multiline
                          rows="1"
                          fullWidth
                          disabled={disabledReviewEdit}
                          value={reviewTitle}
                          onChange={(text) => setReviewTitle(text.target.value)}
                          variant="outlined"
                        />
                      </Box>
                    </>
                  ) : ''}
                  <Box style={{ fontSize: '19px', padding: '5px' }}>
                    {reviewData?.status === 2 ? (
                      <>
                        <Typography style={{ padding: '6px' }}>
                          <b>Review :</b>
                          {' '}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography style={{ padding: '6px' }}>
                          <b>Feedback :</b>
                          {' '}
                        </Typography>
                      </>
                    )}
                    <Input
                      id="outlined-multiline-static"
                      multiline
                      rows="5"
                      fullWidth
                      placeholder="Write Review here..."
                      disabled={disabledReviewEdit}
                      value={reviewText}
                      onChange={(text) => setReviewText(text.target.value)}
                      variant="outlined"
                    />
                  </Box>
                  <Box padding="8px">
                    <Divider className={classes.divider} />
                  </Box>
                  <Box display="flex">
                    <Button
                      aria-label="comments"
                      color="primary"
                      onClick={() => {
                        editReviewPublic();
                      }}
                      startIcon={
                        buttonStatus === 'edit' ? (
                          <EditIcon />
                        ) : (
                          <SendIcon />
                        )
                      }
                    >
                      {buttonStatus}
                    </Button>
                    <Button
                      aria-label="comments"
                      color="secondary"
                      onClick={() => {
                        setOpenPopup(true);
                      }}
                      startIcon={
                        <DeleteIcon />
                      }
                    >
                      Delete
                    </Button>
                  </Box>
                  <Box>
                    <Divider className={classes.divider} />
                  </Box>
                  <br />
                  <Typography style={{ padding: '6px' }}>
                    Reply From :
                    {' '}
                    <b>
                      {handleUndefined(
                        business?.brandname,
                      ) ? (
                        <>
                          {handleUndefined(
                            business?.brandname,
                          )}
                          ,&nbsp;
                        </>
                        ) : (
                          <>
                            {handleUndefined(
                              business?.businessname,
                            )}
                            ,&nbsp;
                          </>
                        )}
                      {handleUndefined(
                        entity?.businessname,
                      )}
                    </b>
                    {' '}
                  </Typography>
                  <Box display="flex" my={1}>
                    <TextField
                      id="filled-multiline-static"
                      multiline
                      fullWidth
                      rows={6}
                      disabled
                      defaultValue={handleUndefined(reviewReply?.replytext)}
                      variant="filled"
                    />
                  </Box>
                  <Divider className={classes.divider} />
                </Box>
              </Widget>
            </Box>
          </>
        )}
      </div>
    );
  }
  return (
    <div>
      <center>
        <Grid item xs={12} md={6}>
          <Widget>
            <h1> Your review has been deleted</h1>
          </Widget>
        </Grid>
      </center>
    </div>
  );
}
