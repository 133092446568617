import React, { useEffect, useState } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { postDataRequest, handleUndefined } from '../../common/commonfunctions';
import 'font-awesome/css/font-awesome.min.css';
import { useEntitiesState } from '../../context/EntityContext';
import config from '../../config';
import { useGlobalState } from '../../context/GlobalContext';

export default function CampaignBrand({
  brandname,
  businessname,
  manageBrand,
}) {
  const [entity, setEntity] = useState([]);
  const globalContext = useGlobalState();
  const entitiesState = useEntitiesState();
  useEffect(() => {
    let isMounted = true;
    if (entitiesState?.entities?.businessData
        && entitiesState?.entities?.businessData[0]?.entityid) {
      postDataRequest(config.baseURLApi, {
        query: `query entity($entityid:ID!){entity(entityid:$entityid){entityid,businessname,brandname
                firstname,lastname,email,phonenumber,entitytypeid,businesstypeid,jobid,tags,website,shortdesc,fulldesc
                }}`,
        variables: {
          entityid:
                    entitiesState?.entities?.businessData
                    && entitiesState?.entities?.businessData[0]?.entityid,
        },
      })
        .then((res) => {
          if (isMounted) {
            setEntity(res?.data?.entity);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [entitiesState.entities.businessData, globalContext.globalState.currentLocation.entityid]);

  return (
    <>
      {handleUndefined(brandname)
            && handleUndefined(businessname)
            && manageBrand === true ? (
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                <span>{handleUndefined(businessname)}</span>
                <span>{handleUndefined(brandname)}</span>
              </Breadcrumbs>
        ) : (
          <>
            {(entity.brandname || entity.businessname)
                    && `,${globalContext.globalState.currentLocation
                      .businessname && manageBrand}` !== true ? (
                        <>
                          <span>
                            {handleUndefined(entity.brandname)
                              ? `${entity.brandname}`
                              : `${
                                handleUndefined(entity.businessname)
                                  ? entity.businessname
                                  : ''
                              }`}
                          </span>

                          <span>
                            {globalContext.globalState.currentLocation.displaylocationdoncampaign
                            === true
                              ? (
                                <>
                                  ,&nbsp;
                                  {globalContext.globalState.currentLocation
                                    .businessname
                                    && globalContext.globalState.currentLocation
                                      .businessname}
                                </>
                              ) : ''}
                          </span>
                        </>
              ) : (
                ''
              )}
          </>
        )}
    </>
  );
}
