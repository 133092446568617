import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogContent, Typography, Box, Button,
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { postDataRequest } from '../../common/commonfunctions';
import { Entity } from '../../Api Mutation/EntityApi';
import PopupMessage from '../../pages/PopupMessage/PopupMessage';
import config from '../../config';
import useStyle from './styles';

export default function Refercode({ openRefer, setOpenRefer }) {
  const classes = useStyle();
  const [locationData, setLocationData] = useState();
  const businessId = JSON.parse(localStorage?.getItem('business_Id'));
  const handleClose = () => {
    setOpenRefer(false);
  };

  const getLocationByData = () => {
    if (openRefer === true && businessId) {
      const ENTITYDATA = Entity;
      postDataRequest(config.baseURLApi, {
        query: ENTITYDATA,
        variables: { entityid: businessId },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationData(res?.data?.entity);
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLocationByData();
    }
    return () => {
      isMounted = false;
    };
  }, [openRefer === true]);

  return (
    <div>
      <Dialog
        open={openRefer}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box className={classes.referDialog}>
            <center>
              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/ReferCode.gif" alt="refer" className={classes.refeerAnimation} />
            </center>
            <br />
            <Typography className={classes.textRefer} variant="body2">
              <b>Refer and Earn !</b>
              {' '}
              Any business using your
              {' '}
              <b>Refer Code</b>
              {' '}
              gets a $100 discount on their Annual Plans.
              Your Referral bonus is $100 for each use of this code.
              Track your referrals- Profile
              {' '}
              {'>'}
              {' '}
              My Referrals
            </Typography>
            <br />
            <Box className={classes.Refer}>
              <Box className={classes.Refercode}>
                <Typography variant="body1" color="primary">
                  {locationData?.referralcode}
                </Typography>
              </Box>
              <CopyToClipboard
                text={locationData?.referralcode}
              >
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<FileCopyIcon />}
                  onClick={() => {
                    handleClose();
                    PopupMessage('Copied');
                  }}
                >
                  Copy
                </Button>
              </CopyToClipboard>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
