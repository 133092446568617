import { regularExpressions } from './const';

export const required = (value) => (value ? undefined : 'Required');
export const mustBeNumber = (value) => (Number.isNaN(value) ? 'Must be a number' : undefined);
export const mustBeString = (value) => (!/^[A-Za-z\s]+$/.test(value) ? 'Enter Characters only' : undefined);
export const minValue = (min) => (value) => (Number.isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`);
export const maxString = (max) => (value) => (value && value?.length > max ? `Maximum ${max} characters allowed` : undefined);
export const minString = (min) => (value) => (value && value?.length < min ? `Minimum ${min} characters Required` : undefined);
export const maxNumber = (max) => (value) => (value && value?.toString()?.length > max ? `Maximum ${max} numbers allowed` : undefined);
export const emailValidate = (value) => (value ? (/^(?!.*[@.]{2,})[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])*\.[a-zA-Z]{2,}$/i.test(value) ? undefined : 'Email address is invalid') : 'Email address is Required');
export const domainValidate = (value) => (value ? (!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/i.test(value) ? 'Domain address is invalid' : undefined) : 'Required');
export const websiteValidate = (value) => (value && !regularExpressions.webSite.test(value) ? 'Invalid URL' : undefined);
export const socialValidate = (value) => (value && !regularExpressions.link.test(value) ? 'Invalid URL' : undefined);
export const phoneValidate = (value) => (value && value.length < 11 ? 'Please enter phone number with country code' : undefined);
export const phoneCountryValidate = (value) => (value && value.length < 11 ? 'Kindly input a valid phone number containing a minimum of 10 digits.' : undefined);
export const phoneCountryDialCodeValidate = (value) => (value && !regularExpressions.phonenumber.test(value) ? 'Please enter phone number with country code' : undefined);
export const zipValidate = (value) => (value && !regularExpressions.postalCode.test(value) ? 'Zip Code is Invalid. Please enter a valid Zip code.' : undefined);
export const authValidate = (value) => (value && !/^\d{6}$/.test(value) ? 'Code must be Six digits and only numeric' : undefined);
export const linkValidate = (value) => (!!/^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g.test(value));
export const composeValidators = (...validators) => (value) => validators.reduce(
  (error, validator) => error || validator(value), undefined,
);
export const passwordValidators = (value) => (value && !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#,^()\-_+=\\/:"'<>,~`[\]{}\\|])[A-Za-z\d.@$!%*?&#,^()\-_+=\\/;:"'<>,~`[\]{}\\|]{12,}$/.test(value) ? 'Invalid Password' : undefined);
