import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  paper: () => ({
    position: 'relative',
    zIndex: 1,
    marginTop: '5%',
    width: '38%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  }),
  cal: () => ({
    width: '100%',
    height: 'auto',
    border: '1px solid lightgrey',
    padding: '20px 0px 40px 20px',
  }),
}));
