/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import BusinessFavicon from '../../common/BusinessFavicon';
import { Entity, BrandSettingByLocation } from '../../Api Mutation/EntityApi';
import { NewsletterEmail, AddActivityNewsletterEmail } from '../../Api Mutation/NewsLetterApi';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { postDataRequestPublic, handleUndefined } from '../../common/commonfunctions';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import PoweredBy from '../../common/PoweredBy';
import config from '../../config';

export default function PublicPreview(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [newsLetterData, setNewsLetterData] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [brandImage, setBrandImage] = useState();
  const [startDate, setStartDate] = useState(moment().format());
  const ref = useRef(null);
  const { onClickOutside } = props;
  const {
    id, lid,
  } = useParams([]);

  const fetchData = async (prop) => {
    if (handleUndefined(id) || handleUndefined(prop?.id)) {
      const CREATE_ACTIVITYNEWSLETTER = AddActivityNewsletterEmail;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITYNEWSLETTER,
        variables: {
          newsletteremailid: handleUndefined(id) || handleUndefined(prop?.id),
          webpageopeneddata: prop?.opendata,
          webpageclickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getData = () => {
    if (id) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: NewsletterEmail,
        variables: {
          newsletteremailid: id,
        },
      }).then((res) => {
        const data = res?.data?.newsletterEmail;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data) {
          setNewsLetterData(data);
          setEnablePublicLogo(data?.isshowfooterlogo);
        }
        setDataLoading(false);
      });
    }
  };

  const entityData = () => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: lid },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setDataLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          if (onClickOutside) {
            onClickOutside();
          }
        } else {
          const link = e?.path?.find((i) => i?.localName === 'a');
          if (link && link.href) {
            const clickedData = {
              timestamp: new Date(),
              url: link?.href,
              name: link?.innerText,
            };
            fetchData({
              id,
              click: clickedData,
            });
          }
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
    return () => {
      isMounted = false;
    };
  }, [onClickOutside]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData({
        opendata: JSON.stringify({
          timestamp: startDate,
          timespent: 0,
        }),
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      entityData();
    }
    return () => {
      isMounted = false;
    };
  }, [lid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.onbeforeunload = async (event) => {
        const message = 'Are you sure to exit this page ?';
        const e = event || window.event;
        await e.preventDefault();
        if (e) {
          e.returnValue = message;
        }
        return fetchData({
          opendata: JSON.stringify({
            timestamp: startDate,
            timespent: moment(new Date())?.diff(startDate, 'seconds'),
          }),
        });
      };
      setStartDate(moment().format());
      getData();
    }
    return () => {
      isMounted = false;
    };
  }, [id]);
  return (
    <div>
      <BusinessFavicon
        name={newsLetterData?.subject}
        logo={favIcon || locationBrandImage || brandImage}
      />
      <center>
        <Typography
          variant="h6"
        >
          {newsLetterData?.subject}
        </Typography>
        {dataLoading ? <LoadingPage /> : (
          <div
            dangerouslySetInnerHTML={{
              __html: newsLetterData?.webpagehtml,
            }}
          />
        )}
      </center>
      {enablePublicLogo ? <PoweredBy id={lid} /> : ''}
    </div>
  );
}
