export const WhiteLabelSettingByBusiness = `query WhiteLabelSettingsByBusiness($businessid: ID!) {
  whiteLabelSettingsByBusiness(businessid: $businessid) {
    whitelabelsettingid
    businessid
    locationid
    email
    phonenumber
    appointmenturl
    isdisabledtawkchat
    isdisabledgetdemo
    isdisabledexpertservices
    deleted
    createdby
    updatedby
  }
}`;

export const CreateWhiteLabelSetting = `mutation CreateWhiteLabelSetting($businessid: ID!, $locationid: ID!, $userid: ID!, $createdby: ID!, $email: String, $phonenumber: String, $appointmenturl: String, $isdisabledtawkchat: Boolean, $isdisabledgetdemo: Boolean, $isdisabledexpertservices: Boolean, $deleted: Boolean) {
  createWhiteLabelSetting(businessid: $businessid, locationid: $locationid, userid: $userid, createdby: $createdby, email: $email, phonenumber: $phonenumber, appointmenturl: $appointmenturl, isdisabledtawkchat: $isdisabledtawkchat, isdisabledgetdemo: $isdisabledgetdemo, isdisabledexpertservices: $isdisabledexpertservices, deleted: $deleted) {
    whitelabelsettingid
    businessid
    locationid
    email
    phonenumber
    appointmenturl
    isdisabledtawkchat
    isdisabledgetdemo
    isdisabledexpertservices
    deleted
    createdby
    updatedby
  }
}`;

export const UpdateWhiteLabelSetting = `mutation UpdateWhiteLabelSetting($whitelabelsettingid: ID!, $updatedby: ID!, $businessid: ID, $locationid: ID, $email: String, $phonenumber: String, $appointmenturl: String, $isdisabledtawkchat: Boolean, $isdisabledgetdemo: Boolean, $isdisabledexpertservices: Boolean) {
  updateWhiteLabelSetting(whitelabelsettingid: $whitelabelsettingid, updatedby: $updatedby, businessid: $businessid, locationid: $locationid, email: $email, phonenumber: $phonenumber, appointmenturl: $appointmenturl, isdisabledtawkchat: $isdisabledtawkchat, isdisabledgetdemo: $isdisabledgetdemo, isdisabledexpertservices: $isdisabledexpertservices)
}`;
