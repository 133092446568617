import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  newBox: {
    width: '90%',
    margin: '30px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  Box: {
    width: '60%',
    margin: '30px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  emailbtn: {
    fontSize: 12,
    color: '#fff',
    float: 'left',
    backgroundColor: '#e56ed3',
    width: 115,
    height: 37,
  },
  emailButton: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#005EB2',
    width: 130,
    height: 40,
    marginTop: 5,
  },
  split: {
    width: 80,
    height: 80,
    color: '#005EB2',
    background: '#b2dce9',
    fontSize: 25,
  },
  mainBox: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    width: '60%',
    margin: '10px auto',
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: '30px 20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 10,
    },
  },
  childBox: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#005EB2',
    width: 120,
    height: 35,
  },
  contactbtn: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#005EB2',
    width: 150,
    height: 35,
  },
}));
