import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    top: '106px',
  },
  logo: {
    marginLeft: 20,
    margin: 15,
    height: 70,
  },
  logoPublic: {
    width: 50,
  },
  link: {
    textDecoration: 'none',
  },
  sharebtn: {
    [theme.breakpoints.down('md')]: {
      marginRight: 80,
    },
  },
  brandLogo: {
    width: '50%',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
  },
  website: {
    fontsize: 8,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  visitwebsite: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
    },
  },
  ShortAndLongText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  title: {
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'breakword',
    fontSize: 14,
    maxHeight: '90px',
    overflowWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '150px',
    },
  },
  label: {
    height: '35px',
    backgroundColor: '#536DFE',
    width: '100%',
  },
  bixLogo: {
    width: '35px',
    marginTop: theme.spacing(2),
  },
  locationLogo: {
    width: '55px',
    marginTop: '4px',
  },
  reviewLogo: {
    width: '30px',
    marginTop: theme.spacing(2),
  },
  totalReviewsText: {
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  Iconlabel: {
    display: 'flex',
    alignItems: 'center',
  },
  publicChatButton: {
    bottom: '0',
    right: '0',
    position: 'absolute',
  },
  whatsApp: {
    position: 'fixed',
    zIndex: 9,
    bottom: theme.spacing(2),
    right: theme.spacing(0),
  },
  textApple: {
    position: 'fixed',
    zIndex: 9,
    bottom: theme.spacing(12),
    right: theme.spacing(0),
  },
  textMessage: {
    position: 'fixed',
    zIndex: 9,
    bottom: theme.spacing(20),
    right: theme.spacing(0),
  },
  phoneChatButton: {
    position: 'fixed',
    zIndex: 9,
    bottom: theme.spacing(40),
    right: theme.spacing(0),
  },
  locationButton: {
    position: 'fixed',
    zIndex: 9,
    bottom: theme.spacing(30),
    right: theme.spacing(0),
  },
}));
