/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import BusinessFavicon from '../../../common/BusinessFavicon';
import { BrandSettingByLocation, Entity } from '../../../Api Mutation/EntityApi';
import { postDataRequestPublic, handleUndefined } from '../../../common/commonfunctions';
import { AddActivityAffiliateLink, AffiliateLinkByShorturl } from '../../../Api Mutation/AffiliateApi';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import PoweredBy from '../../../common/PoweredBy';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import config from '../../../config';

export default function AffiliatePublicPage(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const {
    content, bid,
  } = useParams([]);
  const locationId = localStorage?.getItem('location_Id');
  const [brandImage, setBrandImage] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [emailTemplateHtml, setEmailTemlateHtml] = useState();
  const [contentMarketingId, setContentMarketingId] = useState('');
  const [title, setTitle] = useState();
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState(moment().format());
  const ref = useRef(null);
  const { onClickOutside } = props;

  const fetchData = async (prop) => {
    if (handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id)) {
      const CREATE_ACTIVITY = AddActivityAffiliateLink;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITY,
        variables: {
          affiliateid: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id),
          openeddata: prop?.opendata,
          clickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getDataBytemplate = async () => {
    if (bid) {
      await postDataRequestPublic(config.baseURLApi, {
        query: AffiliateLinkByShorturl,
        variables: {
          shorturl: content,
          ownerid: bid,
        },
      }).then(async (res) => {
        if (res?.errors && res?.errors[0]?.message) {
          await showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.affiliateLinkbyshorturl) {
          await setContentMarketingId(res?.data?.affiliateLinkbyshorturl?.affiliateid);
          await localStorage.setItem('cid', res?.data?.affiliateLinkbyshorturl?.affiliateid);
          await setEmailTemlateHtml(res?.data?.affiliateLinkbyshorturl?.contenttemplate);
          await setTitle(res?.data?.affiliateLinkbyshorturl?.name);
        }
        await setDataLoading(false);
      });
    }
    if (handleUndefined(bid)) {
      postDataRequestPublic(config.baseURLApi, {
        query: BrandSettingByLocation,
        variables: {
          locationid: handleUndefined(bid),
        },
      }).then((res) => setBrandImage(res?.data?.brandSettingByLocation?.logourl));
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (locationId && isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: locationId },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setFavIcon(res?.data?.entity?.favicon);
            setLocationBrandImage(res?.data?.entity?.logourl);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [locationId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          if (onClickOutside) {
            onClickOutside();
          }
        } else if (e?.target?.parentNode?.localName === 'a') {
          if (e?.target?.parentNode?.localName === 'a' && e?.target?.parentNode?.href) {
            const clickedData = {
              timestamp: new Date(),
              url: e?.target?.parentNode?.href,
              name: e?.target?.parentNode?.innerText,
            };
            e.preventDefault();
            if (e?.target?.parentNode?.href?.includes(config.BixPublicUrl)) {
              window.open(`${config.BixPublicUrl}/register?bid=${bid}`);
            }
            fetchData({
              id: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId),
              click: clickedData,
            });
          }
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
    return () => {
      isMounted = false;
    };
  }, [onClickOutside]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData({
        opendata: JSON.stringify({
          timestamp: startDate,
        }),
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDataBytemplate();
    }
    return () => {
      isMounted = false;
    };
  }, [bid]);

  return (
    <div>
      {dataLoading ? <LoadingPage /> : ''}
      <BusinessFavicon
        logo={favIcon || locationBrandImage || brandImage}
        name={title}
      />
      <center>
        <div ref={ref} dangerouslySetInnerHTML={{ __html: emailTemplateHtml }} />
      </center>
      <PoweredBy id={locationId} />
    </div>
  );
}
