import { useState } from 'react';

function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPages) => Math.min(currentPages + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPagePrev) => Math.max(currentPagePrev - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    // eslint-disable-next-line no-restricted-globals
    setCurrentPage(() => Math.min(pageNumber, isNaN(maxPage) ? 1 : maxPage));
  }

  return {
    next, prev, jump, currentData, currentPage, maxPage,
  };
}

export default usePagination;
