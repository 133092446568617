import React, { useEffect } from 'react';
import {
  useLayoutDispatch,
  setLocationModal,
} from '../../context/LayoutContext';

const SelectLocation = () => {
  const layoutDispatch = useLayoutDispatch();
  useEffect(() => {
    let isMounted = true;
    if (!localStorage.getItem('location_Id') && isMounted) {
      setLocationModal(true, layoutDispatch);
    }
    return () => {
      isMounted = false;
    };
  }, [layoutDispatch]);
  return <h1>Please Select Location</h1>;
};
export default SelectLocation;
