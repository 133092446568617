import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import useStyles from './styles';
import config from '../../config';
import { postDataRequest } from '../../common/commonfunctions';

function Country({
  onCountryChange,
  countryid,
  status,
  edit,
  countryError,
}) {
  const classes = useStyles();
  const [countries, setCountires] = useState([]);
  const [country, setCountry] = useState(
    countryid === undefined ? 0 : countryid,
  );
  const [error, setError] = useState(edit && !status && !countryid);
  const handleChangeCountry = (event) => {
    if (event.target.value !== 0) {
      setError(false);
      setCountry(event.target.value);
      onCountryChange(event.target.value);
    } else {
      setCountry(event.target.value);
      onCountryChange(event.target.value);
      setError(true);
    }
  };
  useEffect(() => {
    let isMounted = true;
    postDataRequest(config.baseURLApi, {
      query: '{countries{countryid,name}}',
    })
      .then((res) => {
        if (isMounted) {
          setCountires(res.data.countries);
          setError(false);
        }
      }).catch(() => {
        setError(true);
      });

    return () => {
      isMounted = false;
    };
  }, [countryError]);
  return (
    <div>
      <FormControl className={classes.form}>
        <Select
          error={error}
          value={parseInt(country, 10)}
          fullWidth
          variant="outlined"
          size="small"
          onChange={handleChangeCountry}
          className={classes.selectEmpty}
          margin="dense"
          disabled={status}
        >
          <MenuItem value={0}>Select Country *</MenuItem>
          {countries
                        && countries.map((e) => (
                          <MenuItem
                            name={e.name}
                            value={e.countryid}
                            key={e.countryid}
                          >
                            {e.name}
                          </MenuItem>
                        ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default Country;
