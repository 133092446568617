import React, { useState, useEffect } from 'react';
import {
  FormControl, MenuItem, Select, TextField,
} from '@material-ui/core';
import useStyles from './styles';
import config from '../../config';
import { postDataRequest, handleUndefined } from '../../common/commonfunctions';

function State({
  onStateChange,
  id,
  stateName,
  stateid,
  status,
  stateError,
}) {
  const classes = useStyles();
  const [states, setStates] = useState();
  const [state, setState] = useState(handleUndefined(stateid) || 0);
  const [stateText, setStateText] = useState();
  const [textBoxState, setTextBoxState] = useState(false);
  const handleChangeState = (event) => {
    setState(event.target.value);
    onStateChange(event.target.value);
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setStateText(handleUndefined(stateName) || '');
    }
    return () => {
      isMounted = false;
    };
  }, [stateName]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setState(handleUndefined(stateid) || 0);
    }
    return () => {
      isMounted = false;
    };
  }, [stateid]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted && handleUndefined(id)) {
      postDataRequest(config.baseURLApi, {
        query: 'query statebycountry($countryId:Int!){statesbycountry(countryid:$countryId){stateid,name,code}}',
        variables: {
          countryId: parseInt(id, 10),
        },
      })
        .then((res) => {
          setStates(res?.data?.statesbycountry);
          if (res?.data?.statesbycountry?.length > 0) {
            setTextBoxState(true);
          } else {
            setTextBoxState(false);
          }
        })
        .catch(() => {});
    }
    return () => {
      isMounted = false;
    };
  }, [id]);

  return (
    <div>
      {textBoxState ? (
        <FormControl className={classes.form}>
          <Select
            value={state}
            fullWidth
            variant="outlined"
            size="small"
            onChange={handleChangeState}
            className={classes.selectEmpty}
            margin="dense"
            disabled={status}
          >
            <MenuItem value={0}>Select State</MenuItem>
            {states
                            && states.map((e) => (
                              <MenuItem value={e.stateid} key={e.stateid}>
                                {e.name}
                              </MenuItem>
                            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          error={stateError}
          value={stateText || ''}
          name="state"
          className={classes.field}
          variant="outlined"
          size="small"
          disabled={status}
          onChange={(selectedState) => {
            setStateText(selectedState.target.value);
            onStateChange(selectedState.target.value);
          }}
          type="text"
          label="State"
        />
      )}
    </div>
  );
}
export default State;
