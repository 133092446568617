import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  rootDialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  socialSvgContent: {
    borderRadius: '50%',
    top: 0,
    left: 0,
    margin: 1,
  },
  BottomBut: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  socialIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    '&:hover': {
      fill: 'black',
      WebkitTransition: 'fill 170ms ease-in-out',
    },
  },
  field: {
    width: '100%',
  },
}));
