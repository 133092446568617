import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  filterDialog: {
    maxHeight: '400px',
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  closeBtn: {
    color: 'white',
  },
  actionIcon: {
    paddingTop: 3,
  },
  contactheader: {
    display: 'flex',
    alignItems: 'center',
    color: 'black !important',
    background: '#e2edf5',
    paddingLeft: '25px',
    borderRadius: '16px',
    justifyContent: 'center',
    marginRight: 15,
    marginBottom: 30,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerMenuNotifications: {
    marginTop: theme.spacing(6),
    padding: 20,
  },
  profileMenuNotification: {
    width: '45ch',
    maxHeight: 120 * 4.5,
  },
  texthead: {
    padding: '12px',
  },
  notificationTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    alignItems: 'center',
    cursor: 'pointer',
  },
  tableWrapper: {
    overflowX: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 800,
  },
  margin: {
    background: '#fb8b80',
    color: 'white',
    fontWeight: 800,
  },
  Dropzone: {
    height: '190px',
    textAlign: 'center',
    padding: '20px',
    border: '3px dashed #eeeeee',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    marginBottom: '20px',
    marginTop: 10,
  },
  placeholderIcon: {
    fontSize: 70,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  count: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'white',
    fontSize: 11,
    color: '#005EB2',
  },
  fabIcon: {
    display: 'flex',
  },
  marginIcons: {
    width: '35px',
    height: '25px',
    boxShadow: 'none',
    background: 'none',
    color: 'white',
    margin: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      width: '30px',
      height: '30px',
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  reportCount: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      cursor: 'pointer',
    },
  },
  linkText: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  reportContact: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  reportContacthead: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  backMenuLink: {
    float: 'left',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      float: 'left',
      textDecoration: 'none',
    },
  },
  head: {
    lineHeight: 2.9,
  },
  report: {
    marginLeft: '38%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '4%',
    },
  },
  box: {
    marginTop: '0.5%',
    marginBottom: '0.5%',
  },
  marginBtm: {
    marginBottom: 10,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
    zIndex: 1,
    marginLeft: '29%',
    marginRight: '29%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '20%',
      marginRight: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      marginRight: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '2%',
      marginRight: '2%',
    },
  },
  cal: () => ({
    width: '100%',
    height: 'auto',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  }),
  vertical: {
    borderLeft: '1px solid #d3cccc',
  },
  firstdiv: {
    width: '50%',
    padding: '70px 45px 70px 45px',
  },
  seconddiv: {
    width: '50%',
    background: '#ede9e9',
    padding: '70px 25px 70px 25px',
  },
}));
