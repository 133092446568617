import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { postDataRequestPublic } from '../../../../common/commonfunctions';
import { UrlByCode } from '../../../../Api Mutation/Appointment';
import config from '../../../../config';

export default function AppintmentPublicUrl() {
  const { user, event } = useParams();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      postDataRequestPublic(config.url, {
        query: UrlByCode,
        variables: {
          sorturlcode: window.location.href,
        },
      }).then((res) => {
        if (res?.data?.urlbycode?.fullurl) {
          window.open(res?.data?.urlbycode?.fullurl, '_self');
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [user, event]);
  return <div />;
}
