import React, { StrictMode } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@material-ui/core';
import { ApolloProvider } from '@apollo/client';
import apolloClientContact from './apolloClientContact';
import { GlobalStateProvider } from './context/GlobalContext';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';
import {
  ThemeProvider as ThemeChangeProvider,
  ThemeStateContext,
} from './context/ThemeContext';
import config from './config';
import './index.css';
import ErrorBoundary from './pages/ErrorBoundary/ErrorBoundary';
import 'react-toastify/dist/ReactToastify.css';

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <ApolloProvider client={apolloClientContact}>
    <LayoutProvider>
      <UserProvider>
        <GlobalStateProvider>
          <ThemeChangeProvider>
            <ThemeStateContext.Consumer>
              {(theme) => (
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <ErrorBoundary>
                    <StrictMode>
                      <App />
                    </StrictMode>
                  </ErrorBoundary>
                  <ToastContainer />
                </ThemeProvider>
              )}
            </ThemeStateContext.Consumer>
          </ThemeChangeProvider>
        </GlobalStateProvider>
      </UserProvider>
    </LayoutProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
