import React, {
  useState, useEffect, lazy, Suspense,
} from 'react';
import {
  Typography, TableBody, Paper, TableContainer, DialogTitle, DialogContent,
  Button, Grid, TextField, TableRow, Table, TableCell, TableHead, Dialog,
  DialogActions, Box,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LockIcon from '@material-ui/icons/Lock';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import PaymentIcon from '@material-ui/icons/Payment';
import SendIcon from '@material-ui/icons/Send';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PhoneIcon from '@material-ui/icons/Phone';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  Elements, useStripe, useElements, CardElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BixpoweredBy from '../../common/PoweredBy';
import {
  handleUndefined, getDataRequestPublic, postDataRequestPublic,
} from '../../common/commonfunctions';
import config from '../../config';
import { Entity, Entityaddressbyentityid } from '../../Api Mutation/EntityApi';
import useStyles from './styles';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import LazyLoader from '../LoadingPage/LazyLoader';
import BusinessFavicon from '../../common/BusinessFavicon';

const Widget = lazy(() => import('../../components/Widget/Widget'));

const stripePromise = loadStripe(config.paymentKey);
export default function App() {
  return (
    <Elements stripe={stripePromise}>
      <PublicInvoice />
    </Elements>
  );
}
function PublicInvoice() {
  const classes = useStyles();
  const [contacts, setContacts] = useState();
  const [invoicedata, setInvoiceData] = useState();
  const [addressByBusiness, setAddressByBusiness] = useState([]);
  const [cardError, setCardError] = useState(true);
  const { iid, bid, lid } = useParams();
  const [open, setOpen] = React.useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openPaymentDone, setOpenPaymentDone] = useState(false);
  const [locationData, setLocationData] = useState();
  const [openPayment, setOpenPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [brandImage, setBrandImage] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [comment, setComment] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const locationId = handleUndefined(JSON.parse(localStorage.getItem('location_Id')));

  const ref = React.createRef();

  const windowPrint = () => {
    document.body.style.visibility = 'hidden';
    document.body.style.visibility = 'hidden';
    ref.current.style.visibility = ' visible';
    ref.current.style.position = 'fixed';
    ref.current.style.top = '50%';
    ref.current.style.left = '50%';
    ref.current.style.width = '90%';
    ref.current.style.visibility = ' visible';
    ref.current.style.transform = 'translate(-50%, -50%)';
    window.print();
    document.body.style.visibility = 'visible';
    ref.current.style.position = 'unset';
    ref.current.style.width = 'unset';
    ref.current.style.top = 'unset';
    ref.current.style.left = 'unset';
    ref.current.style.transform = 'none';
  };

  function invoicetotal(items) {
    return items?.orderItems.map(({
      price, qty,
    }) => Number(price) * Number(qty)).reduce((sum, i) => sum + i, 0);
  }
  const invoiceTotalPrice = invoicetotal(invoicedata);
  const locationByEntityId = async () => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: lid },
      })
        .then((res) => {
          setLocationData(res?.data?.entity);
          setLocationBrandImage(res?.data?.entity?.logourl);
          setFavIcon(res?.data?.entity?.favicon);
        });
    }
  };

  const askForRevision = () => {
    postDataRequestPublic(
      `${config.payment}v1/order/ask-for-invoice-revision`,
      {
        orderinvoiceid: iid,
        locationid: lid,
        note: comment,
      },
    ).then((res) => {
      if (res) {
        showNotification(res?.message, 'success');
        setOpen(false);
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleOpenPaymentDone = () => {
    setOpenPaymentDone(true);
  };

  const handleClosePaymentDone = () => {
    setOpenPaymentDone(false);
  };

  const [paymentDoneData, setPaymentDoneData] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      showNotification(`Payment failed ${payload.error.message}`, 'error');
    } else if (payload?.paymentIntent) {
      setPaymentDoneData(payload?.paymentIntent);
      showNotification('Payment Successfully', 'success');
      setOpenPayment(false);
    }
  };

  const invoiceData = () => {
    getDataRequestPublic(
      `${config.payment}v1/order/${iid}`,
    ).then(
      (response) => {
        setInvoiceData(response?.data);
        setClientSecret(response?.data?.clientsecret);
        if (response?.data?.contactid) {
          postDataRequestPublic(config.baseURLApi, {
            query: `
            query contact($contactid: ID!){
              contact(contactid: $contactid){
                contactid,primaryemail,secondaryemail,firstname,lastname,contactavatar,phonenumber,locationid,createdby,updatedby,
                deleted,address{addressid,street1,street2,city,county,stateid,countryid,addresstype,zip,country{countryid,name},state{stateid,name}},
                ownerid,source,createdAt,updatedAt,status}
            }`,
            variables: {
              contactid: response?.data?.contactid,
            },
          }).then((res) => {
            if (res?.data?.contact) {
              setContacts(res?.data?.contact);
            }
          });
        }
      },
    );
    if (bid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entityaddressbyentityid,
        variables: {
          entityid: bid,
        },
      }).then((res) => {
        setAddressByBusiness(res?.data?.entityaddressbyentityid);
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      locationByEntityId();
      if (bid) {
        postDataRequestPublic(config.baseURLApi, {
          query: `
      query brandSettingByLocation($locationid:ID!)
            {brandSettingByLocation(locationid:$locationid){color,dateformat,timeformat,logourl}}
          `,
          variables: {
            locationid: bid,
          },
        }).then((res) => setBrandImage(res?.data?.brandSettingByLocation?.logourl));
      }
      invoiceData();
    }
    return () => {
      isMounted = false;
    };
  }, [iid, bid]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePayClick = () => {
    setOpenPay(true);
  };

  const handlePayClose = () => {
    setOpenPay(false);
  };

  const handleClickPayment = () => {
    setOpenPayment(true);
  };

  const handleClosePayment = () => {
    setOpenPayment(false);
  };

  return (
    <>
      {' '}
      <BusinessFavicon
        logo={favIcon || locationBrandImage || brandImage}
        name={locationData?.businessname}
      />
      <Suspense fallback={<LazyLoader />}>
        <Widget
          disablewidgetmenu="true"
          inheritheight="true"
          size="small"
          className={classes.margin}
          bodyclass={classes.navPadding}
        >
          <Dialog
            open={openPayment}
            fullWidth
            maxWidth="md"
            onClose={handleClosePayment}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h1">Payment</Typography>
                <CloseIcon onClick={() => handleClosePayment()} />
              </Box>
            </DialogTitle>
            <br />
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <div
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                  id="card-element"
                >
                  <CardElement
                    onChange={(e) => setCardError(e.error)}
                    error={cardError}
                    options={{
                      iconStyle: 'solid',
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#32325d',
                          fontFamily:
                                                            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                          '::placeholder': {
                            color: '#a0aec0',
                          },
                        },
                      },
                    }}
                  />
                </div>
              </form>
            </DialogContent>
            <br />
            <DialogActions>
              <Button size="small" variant="contained" startIcon={<PaymentIcon />} onClick={handleSubmit} color="primary">
                Pay
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Notes</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                multiline
                rows={5}
                onChange={(e) => setComment(e.target.value)}
                margin="dense"
                id="name"
                label="Notes"
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button startIcon={<CloseIcon />} size="small" variant="contained" onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button startIcon={<SendIcon />} size="small" variant="contained" onClick={askForRevision} color="primary">
                Send
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <Box
              style={{
                background: 'linear-gradient(180deg, #4c32c6 50%, white 50%)',
              }}
            >
              <br />
              <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item md={5} xs={12}>
                  {brandImage ? (
                    <img src={brandImage} width="160px" alt="logo" />
                  ) : ''}
                  <Box>
                    <Button
                      className={classes.socialBtn}
                      startIcon={<PhoneIcon style={{ fontSize: 23, color: '#072cfe' }} />}
                      href={`tel:${locationData?.phonenumber}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                    <Button
                      className={classes.socialBtn}
                      startIcon={<WhatsAppIcon style={{ fontSize: 23, color: '#3cd4a0' }} />}
                      href={`${`https://api.whatsapp.com/send?phone=${handleUndefined(locationData?.phonenumber)}`}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                    <Button
                      className={classes.socialBtn}
                      startIcon={<AppleIcon style={{ fontSize: 23, color: '#607d8b' }} />}
                      href={`sms:${locationData?.phonenumber}&amp;body=`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                    <Button
                      className={classes.socialBtn}
                      startIcon={<AndroidIcon style={{ fontSize: 25, color: '#ffc260' }} />}
                      href={`sms:${locationData?.phonenumber}?body=`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </Box>
                  <Typography variant="body1" style={{ color: 'white' }}>Address:</Typography>
                  {handleUndefined(
                    addressByBusiness?.street1
                        || addressByBusiness?.street2,
                  ) && (
                  <Typography style={{ color: 'white' }} variant="body2" color="primary" className={classes.templateText}>
                    {addressByBusiness?.street1}
                    {' '}
                    {addressByBusiness?.street2}
                  </Typography>
                  )}
                  {handleUndefined(
                    addressByBusiness?.city || addressByBusiness?.AddressState?.name,
                  ) && (
                  <Typography style={{ color: 'white' }} variant="body2" color="primary" className={classes.templateText}>
                    {addressByBusiness?.city}
                    ,
                    {' '}
                    {addressByBusiness?.AddressState?.name}
                  </Typography>
                  )}
                  {handleUndefined(
                    addressByBusiness?.AddressCountry?.name
                        || addressByBusiness?.zip,
                  ) && (
                  <Typography style={{ color: 'white' }} variant="body2" color="primary" className={classes.templateText}>
                    {addressByBusiness?.AddressCountry?.name}
                    ,
                    {' '}
                    {addressByBusiness?.zip}
                  </Typography>
                  )}
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography style={{ color: 'white' }} variant="h1" color="primary">{locationData?.businessname}</Typography>
                  <Box display="flex">
                    <Typography style={{ color: 'white', fontSize: '42px' }}>
                      <b>
                        $
                        {invoicedata?.totalprice}
                      </b>
                      <Typography style={{ color: 'white' }} variant="body2">One-Time Payment</Typography>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={3} xs={12} />
                <Grid item md={1} xs={12} />
                <Grid item md={4} xs={12} style={{ background: 'none' }}>
                  <Widget
                    disablewidgetmenu="true"
                    size="small"
                    bodyclass={classes.navPaddingLocation}
                  >
                    <Grid container spacing={3} alignItems="center">
                      <Grid item md={12} xs={12}>
                        <Typography variant="body1" color="primary">Bill To</Typography>
                        <Typography variant="body2">
                          <b>Name:</b>
                &nbsp;
                          {contacts?.firstname}
&nbsp;
                          {contacts?.lastname}
                        </Typography>
                        {contacts?.primaryemail ? (
                          <>
                            <Typography variant="body2">
                              <b>Email:</b>
                &nbsp;
                              {contacts?.primaryemail}
                            </Typography>
                          </>
                        ) : ''}
                        {contacts?.phonenumber ? (
                          <>
                            <Typography variant="body2">
                              <b>Phone:</b>
                &nbsp;
                              {contacts?.phonenumber}
                            </Typography>
                          </>
                        ) : ''}
                        {contacts?.address?.addressid ? (
                          <Typography variant="body1">
                            Address:
                            &nbsp;
                            {contacts?.address?.street1}
                            ,
                            &nbsp;
                            {contacts?.address?.street2}
                            ,
                            &nbsp;
                            {contacts?.address?.city}
                            ,
                            &nbsp;
                            {contacts?.address?.state}
                &nbsp;
                            {contacts?.address?.county}
                            -
                            {contacts?.address?.zip}
                          </Typography>
                        ) : ''}
                      </Grid>
                      <Grid item md={6} xs={12} style={{ background: 'none' }}>
                        {invoicedata?.orderno ? (
                          <>
                            <Typography variant="body2"><b>Order Number:</b></Typography>
                            <Typography variant="body2" color="primary">{invoicedata?.orderno}</Typography>
                          </>
                        ) : ''}
                        {invoicedata?.invoicedate ? (
                          <>
                            <Typography variant="body2"><b>Invoice Date:</b></Typography>
                            <Typography variant="body2" color="primary">{moment(invoicedata?.invoicedate).format('MMMM Do YYYY')}</Typography>
                          </>
                        ) : ''}
                      </Grid>
                      <Grid item md={6} xs={12} style={{ background: 'none' }}>
                        {invoicedata?.invoiceno ? (
                          <>
                            <Typography variant="body2"><b>Invoice Number</b></Typography>
                            <Typography variant="body2" color="primary">{invoicedata?.invoiceno}</Typography>
                          </>
                        ) : ''}
                        {invoicedata?.duedate ? (
                          <>
                            <Typography variant="body2"><b>Due Date</b></Typography>
                            <Typography variant="body2" color="primary">{moment(invoicedata?.duedate).format('MMMM Do YYYY')}</Typography>
                          </>
                        ) : ''}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {invoicedata?.attachment ? (
                          <>
                            <Typography variant="body2"><b>Attachment:</b></Typography>
                            <br />
                            <img src={invoicedata?.attachment} alt="attachment" width="100px" height="auto" />
                          </>
                        ) : ''}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {invoicedata?.note === 'null' ? '' : (
                          <>
                            <Typography variant="body2"><b>Notes:</b></Typography>
                            <Typography variant="body2" color="primary">{invoicedata?.note}</Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {invoicedata?.termsandconditions === 'null' ? ''
                          : (
                            <>
                              <Typography variant="body2"><b>Terms & Conditions:</b></Typography>
                              <Typography variant="body2" color="primary">{invoicedata?.termsandconditions}</Typography>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  </Widget>
                </Grid>
                <Grid item md={7} xs={12} style={{ background: 'none' }}>
                  <Widget
                    disablewidgetmenu="true"
                    size="small"
                    bodyclass={classes.navPaddingLocation}
                  >
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="spanning table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Items</TableCell>
                            <TableCell align="left" className={classes.TableCellhead} />
                            <TableCell align="center" className={classes.TableCellhead}>Quantity</TableCell>
                            <TableCell align="center" className={classes.TableCellhead}>Rate</TableCell>
                            <TableCell align="center" className={classes.TableCellhead}>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoicedata?.orderItems.map((row) => (
                            <>
                              <TableRow key={row.description}>
                                <TableCell align="left">
                                  {row?.name}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.description}
                                </TableCell>
                                <TableCell align="center" className={classes.TableCell}>
                                  {row?.qty}
                                </TableCell>
                                <TableCell align="center" className={classes.TableCell}>
                                  {row?.price}
                                </TableCell>
                                <TableCell align="center" className={classes.TableCell}>
                                  {row?.price * row?.qty}
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                          <TableRow>
                            <TableCell align="center" className={classes.TableCell} rowSpan={4} />
                            <TableCell className={classes.TableCell} />
                            <TableCell className={classes.TableCell} />
                            <TableCell align="right" className={classes.TableCell}>Subtotal($):</TableCell>
                            <TableCell align="center" className={classes.TableCell}>
                              {invoiceTotalPrice}
                            </TableCell>
                          </TableRow>
                          {invoicedata?.orderTaxDiscount.map((row) => (
                            <TableRow>
                              <TableCell className={classes.TableCell}>
                                {row.type}
                              </TableCell>
                              <TableCell className={classes.TableCell} />
                              <TableCell align="center" className={classes.TableCell}>{row?.qty}</TableCell>
                              <TableCell align="center" className={classes.TableCell}>{row?.valuetype === 'PERCENTAGE' ? (row?.value * invoiceTotalPrice) / 100 : row?.value}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell rowSpan={100} className={classes.TableCell} />
                            <TableCell className={classes.TableCell} />
                            <TableCell align="right" className={classes.TableCell}>Total($):</TableCell>
                            <TableCell align="center" className={classes.TableCell}>
                              {invoicedata?.totalprice}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                      <Grid item md={3} xs={12}>
                        <Button onClick={() => handlePayClick()} color="primary" size="small" variant="contained">
                          View as PDF
                        </Button>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Button onClick={() => handleClickOpen()} color="primary" size="small" variant="contained">
                          Ask for Revision
                        </Button>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Button onClick={() => handleClickPayment(invoicedata?.clientsecret)} startIcon={<PaymentIcon />} color="primary" size="small" variant="contained">
                          Pay Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Widget>
                </Grid>
                <Grid item xs={12} md={12}>
                  <br />
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={3}>
                      <BixpoweredBy id={lid || locationId} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper className={classes.strip} elevation={3}>
                        <center>
                          <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <LockIcon style={{ fontSize: 15 }} />
                          &nbsp;
                            <Typography variant="body2" color="primary">Secured Payment with SHA-256 SSL Encryption.</Typography>
                          </Box>
                          <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/stripelogo.png" alt="strip" width="150px" />
                        </center>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
          <Dialog
            open={openPay}
            fullWidth
            maxWidth="md"
            onClose={handlePayClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div
                ref={ref}
                style={{
                  backgroundColor: '#f5f5f5',
                  backgroundImage: brandImage,
                  width: '210mm',
                  minHeight: '297mm',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Widget>
                      <div className={classes.Invoice}>
                        <Box display="flex" justifyContent="space-between" mb={3} alignItems="center">
                          <Box display="flex" alignItems="center">
                            <img
                              src={brandImage}
                              alt="Flatlogic"
                              style={{ maxHeight: 120, borderRadius: 10, marginRight: 24 }}
                            />
                          </Box>
                          <Box>
                            <Typography weight="bold" display="inline">
                              {addressByBusiness?.street1}
                              {' '}
                              {addressByBusiness?.street2}
                            </Typography>
                            <br />
                            <Typography weight="bold" display="inline">
                              {addressByBusiness?.city}
                              ,
                              {' '}
                              {addressByBusiness?.AddressState?.name}
                            </Typography>
                            <Typography weight="bold" display="inline">
                              ,
                              {addressByBusiness?.AddressCountry?.name}
                              ,
                              {' '}
                              {addressByBusiness?.zip}
                            </Typography>
                          </Box>
                        </Box>
                        <br />
                        <br />
                        <br />
                        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                          <Box>
                            <Typography color="primary" weight="bold">Billed To</Typography>
                            <Typography variant="body1">
                              {contacts?.firstname}
                              {' '}
                              {contacts?.lastname}
                            </Typography>
                            <Typography variant="body2">
                              {contacts?.address?.street1}
                              {' '}
                              {contacts?.address?.street2}
                            </Typography>
                            <Typography variant="body2">
                              {contacts?.address?.city}
                              {' '}
                              {addressByBusiness?.AddressState?.name}
                            </Typography>
                            <Typography variant="body2">
                              {addressByBusiness?.AddressCountry?.name}
                              {' '}
                              {contacts?.address?.zip}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1" color="primary" weight="bold">Date of Invoice</Typography>
                            <Typography variant="body2">{moment(invoicedata?.invoicedate).format('ddd, MMM DD, YYYY')}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1" color="primary" weight="bold">Invoice Number</Typography>
                            <Typography variant="body2">{invoicedata?.invoiceno}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1" color="primary" weight="bold">Due Date</Typography>
                            <Typography variant="body2">{moment(invoicedata?.duedate).format('MMMM Do YYYY')}</Typography>
                          </Box>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                              <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="spanning table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">Items</TableCell>
                                      <TableCell align="center">Description</TableCell>
                                      <TableCell align="center">Quantity</TableCell>
                                      <TableCell align="center">Rate</TableCell>
                                      <TableCell align="center">Amount</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {invoicedata?.orderItems.map((row) => (
                                      <>
                                        <TableRow key={row.description}>
                                          <TableCell align="center" className={classes.TableCell}>
                                            {row?.name}
                                          </TableCell>
                                          <TableCell align="center" className={classes.TableCell}>
                                            {row?.description}
                                          </TableCell>
                                          <TableCell align="center" className={classes.TableCell}>
                                            {row?.qty}
                                          </TableCell>
                                          <TableCell align="center" className={classes.TableCell}>
                                            {row?.price}
                                          </TableCell>
                                          <TableCell align="center" className={classes.TableCell}>
                                            {row?.price * row?.qty}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                    <TableRow>
                                      <TableCell align="center" className={classes.TableCell} rowSpan={4} />
                                      <TableCell className={classes.TableCell} />
                                      <TableCell />
                                      <TableCell align="center" className={classes.TableCell}>Subtotal($)</TableCell>
                                      <TableCell align="center" className={classes.TableCell}>{invoiceTotalPrice}</TableCell>
                                    </TableRow>
                                    {invoicedata?.orderTaxDiscount.map((row) => (
                                      <TableRow>
                                        <TableCell className={classes.TableCell} />
                                        <TableCell className={classes.TableCell} />
                                        <TableCell align="center" className={classes.TableCell}>{row?.type}</TableCell>
                                        <TableCell align="center" className={classes.TableCell}>{row?.valuetype === 'PERCENTAGE' ? (row?.value * invoiceTotalPrice) / 100 : row?.value}</TableCell>
                                      </TableRow>
                                    ))}
                                    <TableRow>
                                      <TableCell className={classes.TableCell} />
                                      <TableCell />
                                      <TableCell align="center" className={classes.TableCell}>Total($)</TableCell>
                                      <TableCell align="center" className={classes.TableCell}>{invoicedata?.totalprice}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Box>
                        <br />
                        <br />
                        <br />
                        <br />
                        <center className={classes.poweredby}>
                          <BixpoweredBy id={lid || locationId} />
                        </center>
                      </div>
                    </Widget>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button size="small" startIcon={<CloseIcon />} onClick={handlePayClose} color="primary">
                Close
              </Button>
              <Button
                aria-label="Print"
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                onClick={windowPrint}
                startIcon={<GetAppIcon />}
              >
                Download
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openPaymentDone}
            fullWidth
            maxWidth="xs"
            onClose={handleClosePaymentDone}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <center>
                <ReceiptIcon fontSize={35} />
                <Typography variant="h1">Invoice paid</Typography>
                <Typography variant="h2">
                  $
                  {paymentDoneData?.amount}
                </Typography>
              </center>
            </DialogContent>
          </Dialog>
        </Widget>
      </Suspense>
    </>
  );
}
