/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { Fragment, useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper,
  IconButton,
  DialogActions,
  Typography,
  DialogContent,
  Dialog,
  Box,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete, Rating, createFilterOptions } from '@material-ui/lab';
import { AddCircle, Delete, Event } from '@material-ui/icons';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import ReactPhoneInput from 'react-phone-input-2';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import useStyle from './Style';
import { useGlobalState } from '../../context/GlobalContext';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import MuiCustomButton from '../../common/MuiCustomButton';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { DeleteSalesOrderItem } from '../../Api Mutation/OrderModuleApi';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { ProductList } from '../../Api Mutation/Product&ServiceApi';
import { getFieldType } from '../../common/const';

const DynamicTable = ({
  rows, setRows, getSalesOrder, sectionOrderData, defaultCountryOrder, defaultRow, orderDialogView,
}) => {
  const classes = useStyle();
  const globalContext = useGlobalState();
  const filter = createFilterOptions();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteInd, setDeleteInd] = useState(null);
  const [orderItemId, setOrderItemId] = useState(null);
  const [multipleCheckbox, setMultipleCheckbox] = useState({});

  const getInitialData = () => {
    postDataRequest(config.reviewApi, {
      query: ProductList,
      variables: {
        locationid: globalContext?.globalState?.currentLocation?.entityid,
      },
    }).then((response) => {
      const res = response?.data?.productServicesbylocation;
      setData(res);
    });
  };

  const handleChangeValue = (e, db, index) => {
    rows[index][db] = e.target.value;
    setRows([...rows]);
  };

  const handleChangePhone = (isPhone, db, index) => {
    rows[index][db] = isPhone;
    setRows([...rows]);
  };

  const handleChangeDate = (e, db, index) => {
    if (e === null) {
      rows[index][db] = null;
      setRows([...rows]);
    } else {
      rows[index][db] = moment(e).format('YYYY-MM-DDTHH:mm');
      setRows([...rows]);
    }
  };

  const handleChangeDropDown = (newValue, db, index) => {
    rows[index][db] = newValue;
    setRows([...rows]);
  };

  const handleChangeCheck = (e, db, index) => {
    rows[index][db] = JSON.stringify(e.target.checked);
    setRows([...rows]);
  };

  const handleChangeCheckMulti = async (e, db, index) => {
    const arr = JSON.parse(rows[index][db])?.length > 0 ? JSON.parse(rows[index][db]) : [];
    if (e.target.checked) {
      arr.push(e.target.value);
      rows[index][db] = JSON.stringify(arr);
    } else {
      const ind = arr.findIndex((b) => b === e.target.value);
      if (ind > -1) {
        arr.splice(ind, 1);
        if (arr?.length > 0) {
          rows[index][db] = JSON.stringify(arr);
        } else {
          rows[index][db] = null;
        }
      }
    }
    setRows([...rows]);
  };

  const handleChangeRadio = (e, db, index) => {
    rows[index][db] = e.target.value;
    setRows([...rows]);
  };

  const handleAddRow = () => {
    if (!rows.find((c) => c?.title === '')) {
      if (!rows.find((c) => c?.quantity === '')) {
        defaultRow.isCustom = false;
        setRows([...rows, defaultRow]);
      } else {
        showNotification('Please select atleast 1 quantity', 'error');
      }
    } else {
      showNotification('Please Select Product', 'error');
    }
  };

  const calculateTotalPrice = (quantity, singleitemprice) => {
    const parsedQuantity = parseFloat(quantity);
    const parsedSingleItemPrice = parseFloat(singleitemprice);
    return Number.isNaN(parsedQuantity) || Number.isNaN(parsedSingleItemPrice) ? '' : (parsedQuantity * parsedSingleItemPrice);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const parsedValue = name === 'quantity' || name === 'singleitemprice' ? ((Number.isNaN(parseFloat(value))) ? '' : parseFloat(value)) : value;
    const newRows = rows.map((row, rowIndex) => {
      if (rowIndex === index) {
        const rate = name === 'singleitemprice' ? parsedValue : row?.singleitemprice;
        const quantity = name === 'quantity' ? parsedValue : row?.quantity;
        return {
          ...row,
          [name]: parsedValue,
          totalprice: calculateTotalPrice(quantity, rate),
        };
      }
      return row;
    });
    setRows(newRows);
  };

  const handleProductChange = (newValue, index) => {
    const rate = newValue?.salesprice ? parseFloat(newValue?.salesprice) : 0;
    const newRows = rows.map((row, rowIndex) => {
      if (rowIndex === index) {
        const quantity = row?.quantity || 0;
        return {
          ...row,
          title: newValue?.name || '',
          description: newValue?.shortdiscription || '',
          singleitemprice: rate,
          productserviceid: newValue?.productserviceid || '',
          productid: newValue?.productserviceid || '',
          totalprice: calculateTotalPrice(quantity, rate),
        };
      }
      return row;
    });
    setRows(newRows);
  };

  const handleDelete = () => {
    if (orderItemId) {
      postDataRequest(config.baseURLApi, {
        query: DeleteSalesOrderItem,
        variables: {
          salesorderitemid: orderItemId,
        },
      }).then((res) => {
        if (res?.errors?.[0]?.message) {
          showNotification(res.errors[0].message, 'error');
          setLoading(false);
          setOpen(false);
        } else if (res?.data?.deleteSalesOrderItem) {
          showNotification('Item Deleted Sucessfully', 'success');
          setLoading(false);
          setOpen(false);
          getSalesOrder();
        }
      });
    } else {
      const updatedRows = [...rows];
      updatedRows.splice(deleteInd, 1);
      setRows(updatedRows);
      setOpen(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getInitialData();
    }
    return () => {
      isMounted = false;
    };
  }, [globalContext.globalState.currentLocation.entityid]);

  return (
    <>
      {loading ? <LoadingPage /> : ''}
      <TableContainer component={Paper} style={{ padding: 10 }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} sx={{ borderRight: '1px solid gray' }}>Product</TableCell>
              <TableCell className={classes.tableCell} sx={{ borderRight: '1px solid gray' }}>Details</TableCell>
              <TableCell className={classes.tableCell} sx={{ borderRight: '1px solid gray' }}>Quantity</TableCell>
              <TableCell className={classes.tableCell} sx={{ borderRight: '1px solid gray' }}>Rate</TableCell>
              <TableCell className={classes.tableCell} sx={{ borderRight: '1px solid gray' }}>Amount</TableCell>
              <TableCell className={orderDialogView ? classes.hideSection : classes.tableCell} sx={{ borderRight: '1px solid gray' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} style={{ verticalAlign: sectionOrderData?.length > 0 ? 'top' : '' }}>
                <TableCell className={classes.tableCellProd}>
                  {orderDialogView ? (
                    <Typography className={classes.tableFieldValue}>{row?.title}</Typography>
                  ) : (row?.isCustom ? (
                    <TextField
                      name="title"
                      fullWidth
                      variant="outlined"
                      InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                      size="small"
                      value={row?.title || ''}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  )
                    : (
                      <Autocomplete
                        id={`combo-box-demo-${index}`}
                        size="small"
                        fullWidth
                        options={data}
                        disabled={handleUndefined(orderDialogView)}
                        value={(data
                          && data.filter((c) => c?.productserviceid === row?.productid)[0]) || null}
                        onChange={(event, newValue) => {
                          if (newValue && newValue?.inputValue) {
                            const newRows = [...rows];
                            newRows[index] = {
                              ...newRows[index],
                              title: newValue?.inputValue || '',
                              description: '',
                              quantity: 1,
                              singleitemprice: 0,
                              productserviceid: null,
                              productid: null,
                              totalprice: 0,
                              isCustom: true,
                            };
                            setRows(newRows);
                          } else {
                            handleProductChange(newValue, index);
                          }
                        }}
                        getOptionLabel={(option) => option?.name || ''}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          if (params?.inputValue !== '') {
                            filtered.push({
                              inputValue: params?.inputValue,
                              name: `Add "${params?.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        renderInput={(params) => <TextField {...params} label="Product & Service" variant="outlined" />}
                      />
                    ))}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {orderDialogView ? (
                    <Typography className={classes.tableFieldValue}>{row?.description}</Typography>
                  ) : (<TextField
                    name="description"
                    fullWidth
                    variant="outlined"
                    InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                    size="small"
                    value={row?.description}
                    onChange={(event) => handleInputChange(index, event)}
                  />)}
                  {sectionOrderData && sectionOrderData.map((item, i) => (
                    <Fragment key={i}>
                      {item?.fieldtype === getFieldType?.Rating ? (
                        <Box className={classes.fieldGap}>
                          <Rating
                            name={item?.dbfieldname}
                            precision={0.1}
                            readOnly={handleUndefined(orderDialogView)}
                            disabled={handleUndefined(orderDialogView)}
                            value={Number(rows[index][item?.dbfieldname])}
                            onChange={(e) => handleChangeValue(e, item?.dbfieldname, index)}
                          />
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType.Singlelinetext ? (
                        <Box className={classes.fieldGap}>
                          {orderDialogView ? (
                            <Typography className={classes.tableFieldValue}>{rows[index][item?.dbfieldname] || ''}</Typography>
                          ) : (<TextField
                            fullWidth
                            size="small"
                            id={item?.propertyid}
                            InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: rows[index][item?.dbfieldname],
                            }}
                            value={rows[index][item?.dbfieldname] || ''}
                            onChange={(e) => handleChangeValue(e, item?.dbfieldname, index)}
                            name={item?.dbfieldname}
                            type="text"
                            label={item?.label}
                          />)}
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType?.Multilinetext ? (
                        <Box className={classes.fieldGap}>
                          {orderDialogView ? (
                            <Typography className={classes.tableFieldValue}>{rows[index][item.dbfieldname] === null ? '' : rows[index][item?.dbfieldname]}</Typography>
                          ) : (<TextField
                            fullWidth
                            id={item?.label}
                            size="small"
                            variant="outlined"
                            InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                            value={rows[index][item.dbfieldname] === null ? '' : rows[index][item?.dbfieldname]}
                            onChange={(e) => handleChangeValue(e, item?.dbfieldname, index)}
                            multiline
                            name={item?.dbfieldname}
                            InputLabelProps={{
                              shrink: rows[index][item?.dbfieldname]?.[item?.dbfieldname],
                            }}
                            type="text"
                            label={item?.label}
                          />)}
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType.Singlecheckbox ? (
                        <Box className={classes.fieldGap}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                readOnly={handleUndefined(orderDialogView)}
                                disabled={handleUndefined(orderDialogView)}
                                checked={rows[index][item?.dbfieldname]
                                  ? Boolean(rows[index][item?.dbfieldname]
                                    && JSON.parse(rows[index][item?.dbfieldname]))
                                  : false}
                                onChange={(e) => handleChangeCheck(e, item?.dbfieldname, index)}
                                name={item?.dbfieldname}
                                color="primary"
                              />
                            )}
                            label={item?.label}
                          />
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType.Multiplecheckbox ? (
                        <Box className={classes.fieldGap}>
                          {JSON.parse(item?.options)
                            && JSON.parse(item?.options).map((c, ic) => (
                              <FormControlLabel
                                key={ic}
                                className={classes.field}
                                control={(
                                  <Checkbox
                                    id={ic?.toString()}
                                    readOnly={handleUndefined(orderDialogView)}
                                    disabled={handleUndefined(orderDialogView)}
                                    value={c?.label}
                                    checked={
                                      rows[index][item?.dbfieldname]
                                      && JSON.parse(rows[index][item?.dbfieldname]).includes(c.label)
                                    }
                                    onChange={(e) => handleChangeCheckMulti(e,
                                      item?.dbfieldname, index)}
                                    name={c.label}
                                    color="primary"
                                  />
                                )}
                                label={c?.label}
                              />
                            ))}
                        </Box>
                      ) : ('')}

                      {item?.fieldtype === getFieldType.Dropdownselect ? (
                        <Box className={classes.fieldGap}>
                          <Select
                            fullWidth
                            variant="outlined"
                            id={`value-${i}`}
                            readOnly={handleUndefined(orderDialogView)}
                            labelId="demo-simple-select-outlined-label"
                            value={rows[index][item?.dbfieldname]}
                            label={item?.label}
                            onChange={(e) => handleChangeDropDown(
                              e.target.value, item?.dbfieldname, index,
                            )}
                          >
                            {JSON.parse(item?.options)
                              && JSON.parse(item?.options).map((e) => (
                                <MenuItem value={e.label} key={e.label}>
                                  {e.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType.Radioselect ? (
                        <Box className={classes.fieldGap}>
                          <Typography className={classes.heading}>{item?.label}</Typography>
                          <RadioGroup
                            id={i}
                            row
                            name={item.dbfieldname}
                            value={
                              rows[index][item?.dbfieldname]
                                ? rows[index][item?.dbfieldname] : ''
                            }
                            onChange={(e) => handleChangeRadio(e, item?.dbfieldname, index)}
                          >
                            {JSON.parse(item?.options)
                              && JSON.parse(item?.options)?.map((r, ib) => (
                                <FormControlLabel
                                  id={r?.toString()}
                                  value={r?.label}
                                  control={<Radio disabled={handleUndefined(orderDialogView)} />}
                                  label={r?.label}
                                  key={ib}
                                />
                              ))}
                          </RadioGroup>
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType.Datepicker ? (
                        <Box className={classes.fieldGap}>
                          {orderDialogView ? (
                            <Typography className={classes.tableFieldValue}>{moment(parseInt((rows[index][item?.dbfieldname]
                              ? rows[index][item?.dbfieldname]
                              : (new Date())), 10)).format('yyyy/MM/dd hh:mm')}</Typography>
                          ) : (<MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                          >
                            <DateTimePicker
                              id={item?.label}
                              inputVariant="outlined"
                              label={item?.label}
                              size="small"
                              readOnly={handleUndefined(orderDialogView)}
                              fullWidth
                              ampm={false}
                              clearable
                              value={rows[index][item?.dbfieldname]
                                ? rows[index][item?.dbfieldname]
                                : null}
                              onChange={(e) => handleChangeDate(e, item?.dbfieldname, index)}
                              format="yyyy/MM/dd hh:mm"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <Event />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </MuiPickersUtilsProvider>)}
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType.Numbers ? (
                        <Box className={classes.fieldGap}>
                          {orderDialogView ? (
                            <Typography className={classes.tableFieldValue}>{rows[index][item?.dbfieldname] || ''}</Typography>
                          ) : (<TextField
                            fullWidth
                            size="small"
                            InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: rows[index][item.dbfieldname],
                            }}
                            value={rows[index][item?.dbfieldname] || ''}
                            name={item?.dbfieldname}
                            onChange={(e) => handleChangeValue(e, item?.dbfieldname, index)}
                            type="number"
                            label={item?.label}
                          />)}
                        </Box>
                      ) : ('')}
                      {item?.fieldtype === getFieldType.Phonenumber ? (
                        <>
                          <Box className={classes.fieldGap}>
                            {orderDialogView ? (
                              <Typography className={classes.tableFieldValue}>{rows[index][item?.dbfieldname] || ''}</Typography>
                            ) : (<ReactPhoneInput
                              specialLabel={false}
                              country={defaultCountryOrder || 'us'}
                              disabled={handleUndefined(orderDialogView)}
                              isValid={(value, country) => {
                                if (value?.length < 11 && value?.length > 2 && country) {
                                  return false;
                                }
                                return true;
                              }}
                              variant="outlined"
                              value={rows[index][item?.dbfieldname] || ''}
                              placeholder="Enter Phone Number"
                              onChange={(phoneno) => handleChangePhone(
                                phoneno, item?.dbfieldname, index,
                              )}
                            />)}
                          </Box>
                        </>
                      ) : ('')}
                    </Fragment>
                  ))}
                </TableCell>
                <TableCell className={classes.tableCellQua}>
                  {orderDialogView ? (
                    <Typography className={classes.tableFieldValue}>{row.quantity}</Typography>
                  ) : (<TextField
                    name="quantity"
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                    fullWidth
                    value={row.quantity || ''}
                    onChange={(event) => handleInputChange(index, event)}
                  />)}
                </TableCell>
                <TableCell className={classes.tableCellQua}>
                  {orderDialogView ? (
                    <Typography className={classes.tableFieldValue}>{row.singleitemprice || ''}</Typography>
                  ) : (<TextField
                    name="singleitemprice"
                    variant="outlined"
                    size="small"
                    InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                    type="number"
                    fullWidth
                    value={row.singleitemprice || ''}
                    onChange={(event) => handleInputChange(index, event)}
                  />)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row?.totalprice !== undefined && row?.totalprice !== '' ? row?.totalprice?.toFixed(2) : '0.00'}
                </TableCell>
                <TableCell className={orderDialogView ? classes.hideSection : classes.tableCell}>
                  <IconButton
                    disabled={handleUndefined(orderDialogView)}
                    onClick={() => {
                      setOpen(true);
                      setOrderItemId(row?.salesorderitemid ? row?.salesorderitemid : '');
                      setDeleteInd(index);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br />
        {!handleUndefined(orderDialogView) && (
          <Box className={classes?.centerDiv}>
            <MuiCustomButton
              muiStartIcon={<AddCircle style={{ color: 'white !important' }} />}
              muiClass={classes.rowBtn}
              muiBtnValue="Add Row"
              muiSize="small"
              muiHandleClick={handleAddRow}
            />
          </Box>
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;
      </TableContainer>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
      >
        <DialogContent>
          <Typography variant="h5" className={classes.dialogContent}>
            <b>Are you sure you want to delete your template?</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <MuiCustomButton
            muiBtnValue="Agree"
            muiHandleClick={() => {
              handleDelete();
            }}
            muiSize="small"
            muiClass={classes.agreeBtn}
          />
          <MuiCustomButton
            muiBtnValue="Disagree"
            muiHandleClick={() => setOpen(false)}
            muiSize="small"
            muiClass={classes.disAgreeBtn}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DynamicTable;
