/* eslint-disable react/jsx-indent */
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { ConfirmationNumber, ReceiptOutlined } from '@material-ui/icons';
import { modules, permissions } from '../../common/permissions';

const SvgStyle = {
  marginRight: '8%',
  verticalAlign: 'bottom',
  fontSize: '20px',
  '@media only screen and(maxWidth: 1420px)': {
    fontSize: 20,
  },
};
const EmailStyle = {
  marginRight: '8%',
  verticalAlign: 'bottom',
  fontSize: '26px',
  '@media only screen and(maxWidth: 1420px)': {
    fontSize: 26,
  },
};
const SvgVisitorStyle = {
  marginRight: '9%',
  verticalAlign: 'bottom',
  fontSize: '20px',
  '@media only screen and(maxWidth: 1420px)': {
    fontSize: 20,
  },
};

const OrderStyle = {
  marginRight: '8%',
  verticalAlign: 'bottom',
  fontSize: '20px',
  color: '#ffffff',
  marginTop: 0,
  width: 20,
  height: 20,
};

const structure = [
  {
    id: 1,
    label: 'Home',
    link: '/app/home/business',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_101.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_2.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <div>
              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_65.svg" alt="Sidebar" style={SvgStyle} />
              <span>Plans</span>
            </div>
          </>
        ),
        link: '/app/plans',
        menuid: 4,
        roles: [1, 2, 3, 4, 5],
        sideMenu: 'home',
        sideModule: modules.plan,
        sidePermission: permissions.managePlan,
      },
      {
        link: '/app/setting',
        label: (<>
          <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_66.svg" alt="Sidebar" style={SvgStyle} />
          Settings
        </>),
      },
    ],
  },
  {
    id: 2,
    label: 'Leads',
    link: '/app/lead-central/workflow-automation',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_7.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_8.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_69.svg" alt="Sidebar" style={SvgStyle} />
            Import Leads
          </>
        ),
        link: '/app/lead-central/import-lead',
        sideMenu: 'leads',
        sideModule: modules.importLeads,
        sidePermission: permissions.manageImportLeads,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_610.svg" alt="Sidebar" style={SvgStyle} />
            Workflows
          </>
        ),
        link: '/app/lead-central/workflow-automation',
        sideMenu: 'leads',
        sideModule: modules.workflows,
        sidePermission: permissions.workflowList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/linkedinsequence.svg" alt="Sidebar" style={SvgStyle} />
            LinkedIn Sequences
          </>
        ),
        link: '/app/lead-central/linkedin-automation',
        sideMenu: 'leads',
        sideModule: modules.linkedInSequence,
        sidePermission: permissions.linkedInSequenceList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_611.svg" alt="Sidebar" style={SvgStyle} />
            Appointments
          </>
        ),
        link: '/app/lead-central/appointment',
        sideMenu: 'leads',
        sideModule: modules.appointments,
        sidePermission: permissions.manageAppointment,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_612.svg" alt="Sidebar" style={SvgStyle} />
            LeadGen Forms
          </>
        ),
        link: '/app/lead-central/forms',
        sideMenu: 'leads',
        sideModule: modules.leadGenForms,
        sidePermission: permissions.manageLeadGenForms,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_614.svg" alt="Sidebar" style={SvgStyle} />
            Inquiry Forms
          </>
        ),
        link: '/app/lead-central/inquiry-forms',
        sideMenu: 'leads',
        sideModule: modules.inquiryForms,
        sidePermission: permissions.manageInquiryForms,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/visitor-dashboard.png" width='22px' alt="Sidebar" style={SvgVisitorStyle} />
            Visitor Dashboard
          </>
        ),
        link: '/app/lead-central/visitors',
        sideMenu: 'leads',
        sideModule: modules.visitorDashboard,
        sidePermission: permissions.manageVisitorDashboard,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_615.svg" alt="Sidebar" style={SvgStyle} />
            Events
          </>
        ),
        link: '/app/lead-central/event',
        sideMenu: 'leads',
        sideModule: modules.events,
        sidePermission: permissions.manageEvents,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_613.svg" alt="Sidebar" style={SvgStyle} />
            Live Chat
          </>
        ),
        link: '/app/lead-central/live-chat',
        sideMenu: 'leads',
        sideModule: modules.liveChat,
        sidePermission: permissions.manageLiveChat,
      },
    ],
  },
  {
    id: 3,
    label: 'CRM',
    link: '/app/contact-central',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_16.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_17.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_618.svg" alt="Sidebar" style={SvgStyle} />
            Contacts
          </>
        ),
        link: '/app/contact-central',
        sideMenu: 'crm',
        sideModule: modules.contacts,
        sidePermission: permissions.contactGrid,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_619.svg" alt="Sidebar" style={SvgStyle} />
            Accounts
          </>
        ),
        link: '/app/organizations',
        sideMenu: 'crm',
        sideModule: modules.accounts,
        sidePermission: permissions.accountsList,
      },
      {
        label: (
          <>
            <SearchIcon style={EmailStyle} />
            Email Finder
          </>
        ),
        link: '/app/email-find',
        sideMenu: 'crm',
        sideModule: modules.emailFinder,
        sidePermission: permissions.manageEmailFinder,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_620.svg" alt="Sidebar" style={SvgStyle} />
            Documents
          </>
        ),
        link: '/app/documents',
        sideMenu: 'crm',
        sideModule: modules.documents,
        sidePermission: permissions.documentsList,
      },
      {
        label: (<>
          <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_36.svg" alt="Sidebar" style={SvgStyle} />
          Deals
        </>),
        link: '/app/deal-central',
        sideMenu: 'crm',
        sideModule: modules.deals,
        sidePermission: permissions.dealsList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_34.svg" alt="Sidebar" style={SvgStyle} />
            Tasks
          </>
        ),
        link: '/app/task',
        sideMenu: 'crm',
        sideModule: modules.tasks,
        sidePermission: permissions.tasksList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_621.svg" alt="Sidebar" style={SvgStyle} />
            Inbox
          </>
        ),
        link: '/app/home/Inbox',
        menuid: 3,
        roles: [1, 2, 3, 4, 5],
        sideMenu: 'crm',
        sideModule: modules.inbox,
        sidePermission: permissions.inboxList,
      },
      {
        label: (
          <>
            <ConfirmationNumber style={OrderStyle} />
            Service Desk
          </>
        ),
        link: '/app/tickets',
        sideMenu: 'crm',
        sideModule: modules.serviceDesk,
        sidePermission: permissions.serviceDeskList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_35.svg" alt="Sidebar" style={SvgStyle} />
            Calls
          </>
        ),
        link: '/app/call',
        sideMenu: 'crm',
        sideModule: modules.calls,
        sidePermission: permissions.CallsList,
      },
    ],
  },
  {
    id: 4,
    label: 'Marketing',
    link: '/app/lead-central/email-marketing',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_222.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_223.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_624.svg" alt="Sidebar" style={SvgStyle} />
            News Letter
          </>
        ),
        link: '/app/marketing-central/news-letter',
        sideMenu: 'marketing',
        sideModule: modules.newsLetter,
        sidePermission: permissions.newsLetterList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_625.svg" alt="Sidebar" style={SvgStyle} />
            Social Media
          </>
        ),
        link: '/app/marketing-central/social-media-list',
        sideMenu: 'marketing',
        sideModule: modules.socialMedia,
        sidePermission: permissions.socialMediaList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_626.svg" alt="Sidebar" style={SvgStyle} />
            Email Marketing
          </>
        ),
        link: '/app/lead-central/email-marketing',
        sideMenu: 'marketing',
        sideModule: modules.emailMarketing,
        sidePermission: permissions.emailMarketingList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_628.svg" alt="Sidebar" style={SvgStyle} />
            Text Marketing
          </>
        ),
        link: '/app/lead-central/text-marketing',
        sideMenu: 'marketing',
        sideModule: modules.textMarketing,
        sidePermission: permissions.textMarketingList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_629.svg" alt="Sidebar" style={SvgStyle} />
            Pages
          </>
        ),
        link: '/app/lead-central/content-marketing',
        sideMenu: 'marketing',
        sideModule: modules.pages,
        sidePermission: permissions.PagesList,
      },
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_630.svg" alt="Sidebar" style={SvgStyle} />
      //       Review Marketing
      //     </>
      //   ),
      //   link: '/app/lead-central/review-marketing',
      //   sideMenu: 'marketing',
      //   sideModule: modules.reviewMarketing,
      //   sidePermission: permissions.ReviewMarketingList,
      // },
    ],
  },
  {
    id: 5,
    label: 'Sales',
    link: '/app/deal-central',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_31.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_32.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_637.svg.svg" alt="Sidebar" style={SvgStyle} />
            Product & Service
          </>
        ),
        link: '/app/product-service',
        sideMenu: 'sales',
        sideModule: modules.productService,
        sidePermission: permissions.productServiceList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_638.svg.svg" alt="Sidebar" style={SvgStyle} />
            Payment & Invoice
          </>
        ),
        link: '/app/paymentinvoice',
        sideMenu: 'sales',
        sideModule: modules.paymentInvoice,
        sidePermission: permissions.paymentInvoiceList,
      },
      {
        label: (
          <>
            <ReceiptOutlined style={OrderStyle} />
            Sales Orders
          </>
        ),
        link: '/app/sales-order',
        sideMenu: 'sales',
        sideModule: modules.salesOrders,
        sidePermission: permissions.salesOrdersList,
      },
    ],
  },
  {
    id: 6,
    label: 'Reputation',
    link: '/app/reputation-central/monitor-reputation',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_39.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_40.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_641.svg" alt="Sidebar" style={SvgStyle} />
            Monitor Reputation
          </>
        ),
        link: '/app/reputation-central/monitor-reputation',
        sideMenu: 'reputation',
        sideModule: modules.monitorReputation,
        sidePermission: permissions.monitorReputationList,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_43.svg" alt="Sidebar" style={SvgStyle} />
            Review Campaigns
          </>
        ),
        link: '/app/reputation-central/review-campaigns',
        sideMenu: 'reputation',
        sideModule: modules.reviewCampaigns,
        sidePermission: permissions.reviewCampaignsList,
      },
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_44.svg" alt="Sidebar" style={SvgStyle} />
      //       Launch Campaign
      //     </>
      //   ),
      //   link: '/app/reputation-central/launch-campaign',
      //   sideMenu: 'reputation',
      //   sideModule: modules.launchCampaign,
      //   sidePermission: permissions.manageLaunchCampaign,
      // },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_46.svg" alt="Sidebar" style={SvgStyle} />
            Quick Reviews
          </>
        ),
        link: '/app/reputation-central/quick-reviews',
        sideMenu: 'reputation',
        sideModule: modules.quickReviews,
        sidePermission: permissions.manageQuickReviews,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_47.svg" alt="Sidebar" style={SvgStyle} />
            Touchless Reviews
          </>
        ),
        link: '/app/reputation-central/touchless-reviews',
        sideMenu: 'reputation',
        sideModule: modules.touchlessReviews,
        sidePermission: permissions.manageTouchlessReviews,
      },
    ],
  },
  {
    id: 7,
    label: 'Insights',
    link: '/app/insights-central',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_448.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_449.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_651.svg" alt="Sidebar" style={SvgStyle} />
            Marketing Dashboard
          </>
        ),
        link: '/app/insights-central/marketing-dashboard',
        sideMenu: 'insights',
        sideModule: modules.marketingDashboard,
        sidePermission: permissions.manageMarketingDashboard,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_650.svg" alt="Sidebar" style={SvgStyle} />
            Contact Dashboard
          </>
        ),
        link: '/app/insights-central/contact-dashboard',
        sideMenu: 'insights',
        sideModule: modules.contactDashboard,
        sidePermission: permissions.manageContactDashboard,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_652.svg" alt="Sidebar" style={SvgStyle} />
            Email Report
          </>
        ),
        link: '/app/insights-central/email-report',
        sideMenu: 'insights',
        sideModule: modules.emailReport,
        sidePermission: permissions.manageEmailReport,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_653.svg" alt="Sidebar" style={SvgStyle} />
            Sales Dashboard
          </>
        ),
        link: '/app/insights-central/sales-dashboard',
        sideMenu: 'insights',
        sideModule: modules.salesDashboard,
        sidePermission: permissions.manageSalesDashboard,
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_655.svg" alt="Sidebar" style={SvgStyle} />
            Payment Dashboard
          </>
        ),
        link: '/app/insights-central/payment-dashboard',
        sideMenu: 'insights',
        sideModule: modules.paymentDashboard,
        sidePermission: permissions.managePaymentDashboard,
      },
    ],
  },
  { id: 8, type: 'divider' },
  {
    id: 9,
    label: 'Workflows',
    link: '/app/lead-central/workflow-automation',
    sideMenu: 'leads',
    sideModule: modules.workflows,
    sidePermission: permissions.workflowList,
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_610.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_610.svg" alt="Sidebar" />,
  },
  {
    id: 10,
    label: 'LinkedIn Sequences',
    link: '/app/lead-central/linkedin-automation',
    sideMenu: 'leads',
    sideModule: modules.linkedInSequence,
    sidePermission: permissions.linkedInSequenceList,
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/linkedinsequence.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/linkedinsequence.svg" alt="Sidebar" />,
  },
  {
    id: 11,
    label: 'Professional Services',
    link: '/app/HelpAdvisors',
    sideMenu: 'professionalServices',
    sideModule: modules.professionalServices,
    sidePermission: permissions.manageProfessionalServices,
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_56.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_57.svg" alt="Sidebar" />,
  },
];

export default structure;
