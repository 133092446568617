import { makeStyles } from '@material-ui/styles';

const drawerWidth = 260;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 6%)',
  },
  drawerOpen: {
    zIndex: theme.zIndex.drawer + 50,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth + 10,
    },
    background: '#071828',
  },
  headerIcon: {
    color: 'white',
  },
  drawerOpenPaper: {
    zIndex: theme.zIndex.drawer + 50,
    width: drawerWidth + 10,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth + 10,
    },
    background: '#071828',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    fontSize: 0,
    background: localStorage.getItem('theme') === 'dark' ? '#23232d !important' : '#071828 !important',
    width: theme.spacing(7) + 4,
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    background: '#071828',
  },
  sidebarList: {
    height: '100%',
    overflowX: 'hidden',
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    background: '#071828',
  },
  padding: {
    paddingBottom: theme.spacing(2),
  },
  bixpandLogo: {
    height: 25,
    aspectRatio: 'auto',
    marginLeft: 14,
    marginTop: 20,
  },
  sidebarLogo: {
    height: 25,
    marginLeft: 20,
    marginTop: 20,
    display: 'block',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sidebarLogoUnvisible: {
    display: 'none',
  },
}));
