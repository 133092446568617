import React, { useState, useEffect } from 'react';
import {
  Grid, TextField, Box, Typography,
  Button, Container, Tooltip,
} from '@material-ui/core';
import useStyles from './styles';
import Widget from '../../../components/Widget/Widget';
import { handleUndefined, handleValidWebsite, postDataRequestPublic } from '../../../common/commonfunctions';
import config from '../../../config';
import {
  useGlobalState,
  setLoginUserInfo,
} from '../../../context/GlobalContext';
import PoweredBy from '../../../common/PoweredBy';

export default function ThankYou({
  brandInfo,
  editMode,
  bodyText,
  handleBody,
  history,
}) {
  const classes = useStyles();
  const globalContext = useGlobalState();
  const [brandImage, setBrandImage] = useState();
  const [entity, setEntity] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const { search } = history?.location ? history?.location : '';
  const params = new URLSearchParams(search);
  const lid = params.get('lid');
  const bid = params.get('bid');
  const [redirectUrls, setRedirectUrls] = useState([]);
  const redirectOptions = [
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/google.svg', name: 'Google', fieldName: 'newreviewurl' },
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/yelp-icon.svg', name: 'Yelp', fieldName: 'yelprl' },
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/Facebook.svg', name: 'Facebook', fieldName: 'facebookurl' },
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/tripadvisor-icon.svg', name: 'Tripadvisor', fieldName: 'tripadvisorurl' },
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/opentable-icon.svg', name: 'Opentable', fieldName: 'opentableurl' },
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/angieslist-icon.svg', name: 'Angieslist', fieldName: 'angielisturl' },
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/realtor.png', name: 'Realtor', fieldName: 'realtorurl' },
    { logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/Trustpilot.svg', name: 'Trustpilot', fieldName: 'trustpiloturl' },
  ];
  useEffect(() => {
    let isMounted = true;
    if (brandInfo?.locationid || lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query entity($entityid:ID!){
                      entity(entityid:$entityid){
                        entityid
                        businessname
                        brandname
                        firstname
                        lastname
                        email
                        phonenumber
                        entitytypeid
                        displaylocationdoncampaign
                        businesstypeid
                        jobid
                        tags
                        website
                        shortdesc
                        fulldesc
                        policy
                        terms
                        }}`,
        variables: {
          entityid: editMode ? brandInfo?.locationid : lid,
        },
      }).then((res) => {
        if (isMounted) {
          setEntity(res?.data?.entity);
        }
      });
      postDataRequestPublic(config.reviewApi, {
        query: `query socialprofilesbylocation($locationid:ID!){socialprofilesbylocation(locationid:$locationid){
          newreviewurl, yelprl, facebookurl, tripadvisorurl, opentableurl, angielisturl, realtorurl, trustpiloturl,
          }}`,
        variables: {
          locationid: editMode ? brandInfo?.locationid : lid,
        },
      }).then((res) => {
        if (res?.data?.socialprofilesbylocation?.length > 0 && isMounted) {
          setRedirectUrls(res?.data?.socialprofilesbylocation[0]);
        }
      });
    }
    if (handleUndefined(bid) || handleUndefined(brandInfo?.businessid)) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query brandSettingByLocation($locationid:ID!)
        {brandSettingByLocation(locationid:$locationid)
        {color,dateformat,timeformat,logourl}}`,
        variables: {
          locationid: editMode ? brandInfo?.businessid : bid,
        },
      }).then((res) => {
        if (isMounted) {
          setBrandImage(res?.data?.brandSettingByLocation?.logourl);
        }
      });
      postDataRequestPublic(config.baseURLApi, {
        query: `query entity($entityid:ID!){
                          entity(entityid:$entityid){
                            entityid
                            businessname
                            brandname
                            firstname
                            lastname
                            email
                            phonenumber
                            entitytypeid
                            businesstypeid
                            jobid
                            tags
                            website
                            shortdesc
                            fulldesc
                            policy
                            terms
                            }}`,
        variables: {
          entityid: editMode ? brandInfo?.businessid : bid,
        },
      }).then((res) => {
        if (isMounted) {
          setBusinessData(res?.data?.entity);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [brandInfo?.businessid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoginUserInfo(globalContext.setGlobalState);
    }
    return () => {
      isMounted = false;
    };
  }, []);
  const handlePolicy = () => {
    window.open(entity.policy, '_blank');
  };
  const handleTerms = () => {
    window.open(entity.terms, '_blank');
  };
  return (
    <Grid container item md={12} xs={12} style={{ height: '100%' }}>
      <Box width="100%">
        <Widget>
          <center>
            {brandImage ? (
              <>
                <img
                  className={classes.large2}
                  src={brandImage}
                  alt="brand logo"
                />
              </>
            ) : ''}
            <br />
            <Typography variant="h6">{businessData?.brandname}</Typography>
            <br />
            <br />
            <Box paddingTop={2}>
              <Typography variant="body2" color="primary" gutterBottom className={classes.templateText}>
                Thank you!
              </Typography>
              {editMode ? (
                <TextField
                  multiline
                  variant="outlined"
                  fullWidth
                  value={bodyText}
                  onChange={handleBody}
                />
              ) : (
                <Typography
                  className={classes.text}
                  gutterBottom
                  variant="body1"
                  color="primary"
                >
                  {bodyText}
                </Typography>
              )}
              <Box
                className={classes.text}
                marginBottom={1}
              >
                <Box
                  fontWeight="fontWeightBold"
                >
                  <Typography variant="body2" color="primary" className={classes.templateText}>Sincerely,</Typography>
                </Box>
                <Box
                  fontWeight="fontWeightBold"
                  fontSize="1.142rem"
                >
                  <Typography variant="body2" color="primary" className={classes.templateText}>
                    {handleUndefined(
                      businessData?.brandname,
                    ) ? (
                      <>{businessData?.brandname}</>
                      ) : (
                        <>
                          {handleUndefined(
                            businessData?.businessname,
                          )}
                        </>
                      )}
                    {entity.displaylocationdoncampaign === true ? (
                      <>
                        ,&nbsp;
                        {handleUndefined(entity?.businessname)}
                      </>
                    ) : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <br />
            <hr className={classes.head} />
            <br />
            <Container maxWidth="sm">
              <Grid container>
                {redirectOptions?.map((map, index) => (
                  <React.Fragment key={index}>
                    {redirectUrls[map?.fieldName] ? (
                      <>
                        <Grid className={classes.padding} align="center" item xs={12}>
                          <Tooltip
                            title={map.name}
                            color="primary"
                            interactive
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => window.open(handleValidWebsite(redirectUrls[map?.fieldName]) ? redirectUrls[map?.fieldName] : `https://${redirectUrls[map?.fieldName]}`, '_blank')}
                              endIcon={(
                                <img
                                  src={map?.logo}
                                  alt="google"
                                  className={
                                    classes.googleIcon
                                }
                                />
)}
                            >
                              Review on
                            </Button>
                          </Tooltip>
                          <br />
                          <br />
                        </Grid>
                      </>
                    ) : ''}
                  </React.Fragment>
                ))}
              </Grid>
            </Container>
            <Box
              marginTop={1}
              marginBottom={1}
              style={{ fontSize: '13px' }}
            >
              {handleUndefined(entity.policy) && (
                <>
                  {' '}
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <p
                    style={{ textDecoration: 'none' }}
                    onClick={handlePolicy}
                  >
                    {' '}
                    Privacy Policy
                  </p>
                  {' '}
                  /
                  {' '}
                </>
              )}
              {' '}
              {handleUndefined(entity.terms) && (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <p
                  style={{ textDecoration: 'none' }}
                  onClick={handleTerms}
                >
                  Terms & Conditions
                </p>
              )}
            </Box>
            <br />
            <br />
            <PoweredBy id={lid} />
          </center>
        </Widget>
      </Box>
    </Grid>
  );
}
