import gql from 'graphql-tag';

export const Inquiryformsbylocation = `query inquiryformsbylocation(
    $pagenumber: Int,
    $pagesize: Int,
    $locationid:ID!){
    inquiryformsbylocation(
    pagenumber: $pagenumber,
    pagesize: $pagesize,
    locationid:$locationid,
  ){
    count,rows{inquiryformid,
    name,
    formurl,
    formtype,
    updatedAt,
    createdUser{
      firstname,
      lastname
    },
    inquiryformrespons{
      responsedata
    }
  }
}}`;

export const GetInquiryFormResponse = `query inquiryform(
  $inquiryformid: ID!
  ){
    inquiryform(
      inquiryformid: $inquiryformid
    ){
        scriptcode
        inquiryformid
        name
        formdata
        thanksmessage
        isfirstnameshow
        islastnameshow
        isemailshow
        isphoneshow
        isshowthanksbutton
        thanksbuttontext
        thanksbuttontype
        thanksbuttonlink
        thanksbuttonfileurl
        callouttext
        calloutbody
        issendthanksemail
        issendthankssms
        issentsystemthanksemail
        thanksemailconfig
        thankssmsconfig
        selectedrows
        themecolor
        backgroundcolor
        textcolor
        calloutbuttontext
        calloutbtnposition
        textposition
        fieldtextcolor
        btnthemecolor
        btntextcolor
        workflowid
        termenable
        termdesc
        captchaenable
        ispaid
        formprice
        formtype
      inquiryformid
      formtype
      isfirstnameshow
      islastnameshow
      isemailshow
      isphoneshow
      name
      formurl
      formdata
      createdby
      updatedby
      locationid
      businessid
      status
      thanksmessage
      isshowthanksbutton
      thanksbuttontext
      thanksbuttontype
      thanksbuttonlink
      thanksbuttonfileurl
      callouttext
      calloutbody
      issendthanksemail
      issendthankssms
      issentsystemthanksemail
      radioview
      checkboxview
      thanksemailconfig
      thankssmsconfig
      selectedrows
      themecolor
      backgroundcolor
      textcolor
      calloutbuttontext
      calloutbtnposition
      textposition
      btnthemecolor
      btntextcolor
      workflowid
      termenable
      termdesc
      captchaenable
      isshowfooterlogo
      ispaid
      formprice
      deleted
      createdAt
      updatedAt
      formwidth
      fieldtextcolor
        inquiryformrespons{
          responsedata
        }
    }
}`;

export const GetInquiryByFormId = `query Inquiriesbyform($inquiryformid: ID!) {
  inquiriesbyform(inquiryformid: $inquiryformid) {
    inquiryid
    inquiryformid
    responsedata
    createdby
    updatedby
    locationid
    businessid
    status
    deleted
    createdAt
    updatedAt
    contactid
    organizationid
    paymentid
    ispublish
  }
}`;

export const CreateInquiryform = `mutation createinquiryform(
      $formtype: String!
      $name: String,
      $formdata: String,
      $thanksMessage: String,
      $isShowThanksButton: Boolean,
      $thanksButtonText: String,
      $thanksButtonType: String,
      $thanksButtonLink: String,
      $thanksbuttonfileurl: String,
      $callouttext: String,
      $calloutbody: String,
      $locationid: ID!,
      $businessid: ID!,
      $status: String,
      $createdby: ID!,
      $issendthanksemail:Boolean,
      $issendthankssms:Boolean,
      $issentsystemthanksemail: Boolean,
      $thanksemailconfig: String,
      $thankssmsconfig: String
      $selectedrows: String
      $themecolor: String
      $backgroundcolor: String
      $radioview: String
    $checkboxview: String
      $textcolor: String
      $fieldtextcolor: String
      $calloutbuttontext: String
      $calloutbtnposition: String
      $textposition: String
      $btnthemecolor: String
      $btntextcolor: String
      $workflowid: ID
      $termenable:Boolean
      $termdesc: String
      $captchaenable:Boolean
      $isshowfooterlogo:Boolean
      $ispaid: Boolean
      $formprice: Int
      $formwidth: Int
      $scriptcode:String
    ){
      createinquiryform(
            formtype: $formtype
            name: $name
            formdata: $formdata
            status: $status
            thanksmessage: $thanksMessage
            isshowthanksbutton: $isShowThanksButton
            thanksbuttontext: $thanksButtonText
            thanksbuttontype: $thanksButtonType
            thanksbuttonlink: $thanksButtonLink
            thanksbuttonfileurl: $thanksbuttonfileurl
            callouttext: $callouttext
            calloutbody: $calloutbody
            locationid: $locationid
            businessid: $businessid
            radioview: $radioview
            checkboxview: $checkboxview
            createdby: $createdby
            issendthanksemail:$issendthanksemail
            issendthankssms:$issendthankssms
            issentsystemthanksemail: $issentsystemthanksemail
            thanksemailconfig: $thanksemailconfig
            thankssmsconfig: $thankssmsconfig
            selectedrows: $selectedrows
            themecolor: $themecolor
            backgroundcolor: $backgroundcolor
            textcolor: $textcolor
            fieldtextcolor: $fieldtextcolor
            calloutbuttontext: $calloutbuttontext
            calloutbtnposition: $calloutbtnposition
            textposition: $textposition
            btnthemecolor: $btnthemecolor
            btntextcolor: $btntextcolor
            workflowid: $workflowid
            termenable:$termenable
            termdesc: $termdesc
            captchaenable:$captchaenable
            isshowfooterlogo:$isshowfooterlogo
            ispaid:$ispaid
            formwidth:$formwidth
            formprice:$formprice
            scriptcode:$scriptcode ){
            inquiryformid
      }
}`;

export const UpdateInquiryform = `mutation updateinquiryform(
    $inquiryformid: ID!,
    $formtype: String!,
    $name: String,
    $formurl: String,
    $formdata: String,
    $status: String,
    $thanksMessage: String,
    $isShowThanksButton: Boolean,
    $thanksButtonText: String,
    $thanksButtonType: String,
    $thanksButtonLink: String,
    $thanksbuttonfileurl: String,
    $callouttext: String,
    $calloutbody: String,
    $locationid: ID,
    $businessid: ID,
    $updatedby: ID!,
    $issendthanksemail:Boolean,
    $issendthankssms:Boolean,
    $issentsystemthanksemail: Boolean,
    $thanksemailconfig: String,
    $thankssmsconfig: String,
    $selectedrows: String
    $themecolor: String
    $fieldtextcolor: String,
    $backgroundcolor: String
    $textcolor: String
    $calloutbuttontext: String
    $calloutbtnposition: String
    $textposition: String
    $btnthemecolor: String
    $btntextcolor: String
    $radioview: String
    $checkboxview: String
    $workflowid: ID
    $termenable:Boolean
    $termdesc: String
    $captchaenable:Boolean
    $isshowfooterlogo:Boolean
    $ispaid: Boolean
    $formprice: Int
    $formwidth: Int
    $scriptcode: String
  ){
    updateinquiryform(
      inquiryformid: $inquiryformid
      formtype: $formtype
      name: $name
      formurl: $formurl
      formdata: $formdata
      radioview: $radioview
      checkboxview: $checkboxview
      status: $status
      thanksmessage: $thanksMessage
      isshowthanksbutton: $isShowThanksButton
      thanksbuttontext: $thanksButtonText
      thanksbuttontype: $thanksButtonType
      thanksbuttonlink: $thanksButtonLink
      thanksbuttonfileurl: $thanksbuttonfileurl
      callouttext: $callouttext
      calloutbody: $calloutbody
      locationid: $locationid
      businessid: $businessid
      updatedby: $updatedby
      issendthanksemail:$issendthanksemail
      issendthankssms:$issendthankssms
      issentsystemthanksemail: $issentsystemthanksemail
      thanksemailconfig: $thanksemailconfig
      thankssmsconfig: $thankssmsconfig
      selectedrows: $selectedrows
      themecolor: $themecolor
      fieldtextcolor: $fieldtextcolor,
      backgroundcolor: $backgroundcolor
      textcolor: $textcolor
      calloutbuttontext: $calloutbuttontext
      calloutbtnposition: $calloutbtnposition
      textposition: $textposition
      btnthemecolor: $btnthemecolor
      btntextcolor: $btntextcolor
      workflowid: $workflowid
      termenable:$termenable
      termdesc: $termdesc
      captchaenable:$captchaenable
      isshowfooterlogo:$isshowfooterlogo
      ispaid:$ispaid
      formwidth:$formwidth
      formprice:$formprice
      scriptcode:$scriptcode)
}`;

export const DeleteInquiryform = `mutation deleteinquiryform($inquiryformid:ID!){
  deleteinquiryform(inquiryformid:$inquiryformid)
}`;

export const UploadImage = gql`mutation Uploadimg($images: [Upload!]) {
  uploadimg(images: $images) {
    images
  }
}`;

export const createInquiry = `mutation createinquiry(
  $responsedata: String,
  $inquiryformid: ID!,
  $locationid: ID!,
  $businessid: ID!,
  $status: String,
  $contactid: ID,
  $organizationid: ID,
  $paymentid: ID
){
  createinquiry(
        responsedata: $responsedata
        inquiryformid: $inquiryformid
        status: $status
        locationid: $locationid
        businessid: $businessid
        contactid: $contactid
        organizationid: $organizationid
        paymentid: $paymentid){
          inquiryid
        }
}`;

export const CreateInquiryContact = `mutation Createinquirycontact($inquiryformid: ID!, $responsedata: String!, $locationid: ID!, $businessid: ID!) {
  createinquirycontact(inquiryformid: $inquiryformid, responsedata: $responsedata, locationid: $locationid, businessid: $businessid) {
    contactid
    organizationid
  }
}`;

export const updateInquiry = `mutation Updateinquiry($inquiryid: ID!, $inquiryformid: ID, $responsedata: String, $locationid: ID, $businessid: ID, $status: String, $contactid: ID, $organizationid: ID, $paymentid: ID, $ispublish: Boolean) {
  updateinquiry(inquiryid: $inquiryid, inquiryformid: $inquiryformid, responsedata: $responsedata, locationid: $locationid, businessid: $businessid, status: $status, contactid: $contactid, organizationid: $organizationid, paymentid: $paymentid, ispublish: $ispublish)
}`;

export const InquiryById = `query Inquiry($inquiryid: ID!) {
  inquiry(inquiryid: $inquiryid) {
    inquiryid
    inquiryformid
    responsedata
    createdby
    updatedby
    locationid
    businessid
    status
    deleted
    createdAt
    updatedAt
  }
}`;

export const SendEditInquiryEmail = `mutation Sendeditinquiryemail($inquiryformid: ID!, $inquiryid: ID!, $contactid: ID, $organizationid: ID) {
  sendeditinquiryemail(inquiryformid: $inquiryformid, inquiryid: $inquiryid, contactid: $contactid, organizationid: $organizationid) {
    inquiryid
    inquiryformid
    responsedata
    createdby
    updatedby
    locationid
    businessid
    status
    deleted
    createdAt
    updatedAt
    contactid
    organizationid
    paymentid
  }
}`;

export const WorkflowAutomationsByLocation = `query workflowAutomationsByLocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!) {
  workflowAutomationsByLocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize) {
    workflowid
    name
  }
}`;
