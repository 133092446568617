import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../config';

export default function BusinessFavicon({ name, logo }) {
  return (
    <Helmet>
      <title>{name}</title>
      <link rel="icon" type="image/x-icon" href={logo} />

      {/* Open Graph (og:) tags */}
      <meta property="og:title" content={name} />
      <meta property="og:image" content={logo} />
      <meta property="og:description" content={name} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={config.BixPublicUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:image:width" content="256" />
      <meta property="og:image:height" content="256" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:hashtag" content="#bixpand" />

      {/* Twitter meta tags */}
      <meta name="twitter:title" content={name} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={config.BixPublicUrl} />
      <meta name="twitter:creator" content="@bixpand" />
      <meta name="twitter:image" content={logo} />

      {/* Additional meta tags */}
      <meta name="description" content={name} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="robots" content="noodp" />

      {/* MS application meta tags */}
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />

      {/* Other necessary meta tags */}
      <meta charset="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {/* End of Helmet */}
    </Helmet>
  );
}
