/* eslint-disable react/no-unknown-property */
/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { toast, Flip } from 'react-toastify';
import ClearIcon from '@material-ui/icons/Clear';
import ImageIcon from '@material-ui/icons/Image';
import getCroppedImg from './cropImage';
import useStyles from './styles';
import { extractImageFileExtensionFromBase64 } from './ResuableUtils';
import { handleUndefined } from '../../../../common/commonfunctions';

const imageMaxSize = 1000000;
// bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg,';
const acceptedFileTypesArray = acceptedFileTypes.split(',').map((item) => item.trim());

export const RectangleCropImage = (props) => {
  const {
    state, getStart, location, setLocationLogo, setEventImage,
    events, newsletter, fav, setFavicon,
    setNewsLetterImg, org, setOrganizationImg, inquiry, backgroundColorCode, label, required,
  } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const classes = useStyles({
    background: backgroundColorCode,
  });
  const [value, setValue] = useState('');
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels2) => {
    setCroppedAreaPixels(croppedAreaPixels2);
  }, []);
  const showCroppedImage = useCallback(async () => {
    const croppedImage = await getCroppedImg(
      props?.state.imgSrc,
      croppedAreaPixels,
      rotation,
    );
    props?.setState({
      imgSrc: props?.state.imgSrc,
      preview: croppedImage,
    });
  }, [croppedAreaPixels, props, rotation]);
  // eslint-disable-next-line consistent-return
  const verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        toast.warning(
          'the file that you are trying to upload exceeds the 1MB attachment limit',
          { transition: Flip },
        );
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        toast.warning(
          'This file is not allowed. Only images are allowed.',
          { transition: Flip },
        );
        return false;
      }
      return true;
    }
  };
  const handleFileSelect = (event) => {
    const { files } = event.target;
    setValue(event.target.files.name);
    if (files && files.length > 0) {
      const isVerified = verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        if (setLocationLogo) {
          setLocationLogo(currentFile);
        }
        if (setOrganizationImg) {
          setOrganizationImg(currentFile);
        }
        if (setEventImage) {
          setEventImage(files);
        }
        if (setNewsLetterImg) {
          setNewsLetterImg(currentFile);
        }
        if (setFavicon) {
          setFavicon(currentFile);
        }
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          'load',
          () => {
            const myResult = myFileItemReader.result;
            props?.setState({
              imgSrc: myResult,
              imgSrcExt: extractImageFileExtensionFromBase64(
                myResult,
              ),
              preview: myResult,
            });
          },
          false,
        );
        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };
  const handleClearToDefault = (event) => {
    if (event) event.preventDefault();
    setCrop({ x: 0, y: 0 });
    props?.setState({
      imgSrc: null,
      imgSrcExt: null,
      crop: {
        aspect: 4 / 3,
      },
      preview: null,
    });
    setValue('');
    setEventImage([]);
    if (setOrganizationImg) {
      setOrganizationImg(null);
    }
  };
  return (
    <Grid container spacing={3}>
      {getStart === false ? ' '
        : (
          <Grid item md={12} xs={12}>
            <label className={classes.componentHeading}>
              <b>
                {location ? 'Upload Your Location Logo'
                  : events ? 'Upload Event Image'
                    : newsletter ? 'Upload Newsletter Image'
                      : fav ? 'Upload Favicon'
                        : org ? 'Upload Business Image'
                          : inquiry ? `Upload ${label}` : 'Upload Your Brand Logo'}
              </b>
            </label>
            {required ? <label className={classes.required}>*</label> : ''}
            <br />
            <hr />
          </Grid>
        )}

      <Grid item md={6} xs={12} style={{ backgroundColor: backgroundColorCode }}>
        <div className={classes.sliderLabelInput}>
          <input
            required
            name="logo"
            type="file"
            accept={acceptedFileTypes}
            multiple={false}
            onChange={handleFileSelect}
            value={value}
            className={classes.inputStyle}
          />
        </div>
        {events && (
          <>
            <Typography variant="body2" className={classes.errormsg}>*File size should not greater than 1MB!</Typography>
            <Typography
              variant="body2"
              className={classes.errormsg}
              style={{ color: '#0DCAF0' }}
            >
              {' '}
              * Image is suitable in 500 px (height) format
            </Typography>

          </>
        )}
        <br />
        <div className={classes.cropContainer}>
          <Cropper
            image={state.imgSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            restrictPosition={false}
            cropSize={{ width: 350, height: 190 }}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className={classes.controls}>
          <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Zoom
            </Typography>

            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={{}}
              onChange={(e, zoom1) => setZoom(zoom1)}
            />
          </div>
          <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Rotation
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              classes={{}}
              onChange={(e, rotation1) => setRotation(rotation1)}
            />
          </div>
        </div>
      </Grid>
      <Grid item md={6} xs={12} style={{ backgroundColor: backgroundColorCode }}>
        <div className={classes.imageGrid}>
          <Typography
            align="center"
            variant="body2"
            style={{
              fontSize: '16px',
            }}
          >
            <b>Image Preview</b>
          </Typography>
        </div>
        <br />
        <div className={classes.imageGrid}>
          {handleUndefined(state.preview) ? (
            <img
              src={state.preview}
              alt="Brand Logo"
              className={classes.img}
            />
          ) : (
            <p align="center">Select image for preview</p>
          )}
        </div>
        <br />
        <div className={classes.imageGrid}>
          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
            classes={{ root: classes.cropButton }}
            startIcon={<ImageIcon />}
          >
            Image preview
          </Button>
          <Button
            onClick={handleClearToDefault}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<ClearIcon />}
          >
            Clear
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
