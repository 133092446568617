import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
  },
  leftContent: {
    backgroundColor: '#004482',
    boxShadow: '5px 0px 30px rgba(0, 0, 0, 0.07)',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  phonenumber: {
    backgroundColor: '#f6f7ff !important',
  },
  signUp: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  avatarIcon: {
    background: '#FFFFFF',
    border: '10px solid #E1CEE9',
    boxSizing: 'border-box',
    width: '100px',
    height: '100px',
  },
  leftTitle: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '35px',
    lineHeight: '52px',
    letterSpacing: '0.05em',
    color: '#FFFFFF',
  },
  leftSubTitle: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.05em',
    color: '#FFFFFF',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      color: '#004482',
      margin: '10px 0',
    },
  },
  leftSubTitleS: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.05em',
    color: '#FFFFFF',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      color: '#004482',
      margin: '10px 0',
      marginLeft: 0,
    },
  },
  arrowForward: {
    backgroundColor: '#ffffff',
    color: '#004482',
    borderRadius: '100%',
    fontSize: 28,
  },
  formContainer: {
    padding: '0px 60px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px',
    },
  },
  formContainerLogin: {
    padding: '0px 60px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  formHeader: {
    padding: '0px 2px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 2px',
    },
  },
  signUpHeader: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  formBixLogo: {
    width: '100px',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      display: 'flex',
      justifyContent: 'space-between',
      height: '50px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70px',
      display: 'flex',
      justifyContent: 'space-between',
      height: '70px',
    },
  },
  domainLogo: {
    width: '100px',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      display: 'flex',
      justifyContent: 'space-between',
      height: '50px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70px',
      display: 'flex',
      justifyContent: 'space-between',
      height: '70px',
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loginBtn: {
    width: '80%',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-13px',
    },
  },
  signUpBtn: {
    width: '80%',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '107px',
      marginLeft: '-27px',
    },
  },
  formTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    letterSpacing: '0.05em',
    color: '#004482',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
    },
  },
  formSubTitle: {
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '23px',
    lineHeight: '120%',
    letterSpacing: '-0.02em',
    color: '#004482',
  },
  tncLine: {
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0.05em',
    color: '#004482',
  },
  tncCheckbox: {
    color: '#004482',
  },
  formFooter: {
    padding: '0 10px',
  },
  loginLinkTxt: {
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.05em',
    color: '#004482',
    opacity: '0.7',
    margin: '10px 0px',
  },
}));
