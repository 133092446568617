import React, { useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import 'font-awesome/css/font-awesome.min.css';
import { useGlobalState } from '../../context/GlobalContext';
import { postDataRequest } from '../../common/commonfunctions';
import config from '../../config';

export default function Kioskmode() {
  const globalContext = useGlobalState();
  const [brandImage, setBrandImage] = useState();
  const qrValue = `${`${config.BixUrl}/PublicPage/${
    globalContext.globalState.currentLocation.entityid
        && globalContext.globalState.currentLocation.brandname !== 'undefined'
        && globalContext.globalState.currentLocation.brandname !== 'null'
      ? globalContext.globalState.currentLocation.brandname
      : ''
  }`}`;
  useEffect(() => {
    let isMounted = true;
    if (localStorage.getItem('userid') && isMounted) {
      postDataRequest(config.baseURLApi, {
        query: `
                  {brandSettingByLocation(locationid: ${localStorage.getItem(
    'userid',
  )}){color,dateformat,timeformat,logourl}}
                `,
      })
        .then((res) => setBrandImage(res.data.brandSettingByLocation.logourl));
    }
    return () => {
      isMounted = false;
    };
  }, [localStorage.getItem('userid')]);

  return (
    <div style={{ marginTop: '10%' }}>
      <center>
        <div id="my-node">
          <QRCode
            id="mycanvas"
            value={qrValue}
            size={400}
            level="H"
            includeMargin
            logoImage={brandImage}
            logoHeight={100}
            logoWidth={100}
          />
        </div>
      </center>
    </div>
  );
}
