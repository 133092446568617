/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SmsIcon from '@material-ui/icons/Sms';
import moment from 'moment';
import config from '../config';
import { Facebook } from '@material-ui/icons';

const SvgStyle = { verticalAlign: 'bottom', width: 35, height: 35 };

export const themeColor = '#005EB2';

export const limitErrorMessage = 'You need to verify business identity. Please book a meeting with our team';
export const supportMeetingLink = `${config.BixUrl.concat(config.supportMeetingURL)}`;
export const limitErrorCode = 'VERIFY_BUSINESS_EMAIL';
export const graphQl = 'GraphQL error: Error:';
export const graphQlError = 'GraphQL error:';

export const amountValidate = /^\d+(\.\d{1,2})?$/;
export const emailValidation = /^(?!.*[@.]{2,})[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])*\.[a-zA-Z]{2,}$/i;
export const domainValidation = /^\S*$/;
export const bixpandDescription = 'Bixpand - Marketing CRM & Sales, made easy for SMEs';

export const textLength = 255;

export const apiHeader = {
  Authorization: `Bearer ${localStorage?.getItem('token')}`,
  businessid: JSON.parse(localStorage?.getItem('business_Id')),
  locationid: JSON.parse(localStorage?.getItem('location_Id')),
  'Apollo-Require-Preflight': 'true',
};

export const htmlTemplateStyle = {
  height: '100%',
  width: '235px',
  borderRadius: '6px',
  objectFit: 'cover',
  backgroundPosition: 'top',
  backgroundSize: '100%,100%',
  backgroundRepeat: 'no-repeat',
};

export const servicesprovider = {
  Google: 1,
  Outlook: 2,
  SMTP: 3,
};

const currentValidUrl = window?.location?.href;
export const pathValidation = (currentValidUrl.includes('app.bixpand.com') || currentValidUrl.includes('dev.bixpand.com') || currentValidUrl.includes('localhost'));
export const BixFavIcon = "https://storage.googleapis.com/bixpand-staging/WebsiteImages/favicon-16x16.png";
export const GlobeIcon = "https://storage.cloud.google.com/bixpand-staging/WebsiteImages/globe.png";
export const mailIcon = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/Group%20348.png';

export const brand = {
  bixpand: 'bixpand',
  business: 'business',
  fullWhiteLabel: 'fullWhiteLabel',
  noLogo: 'nologo',
  halfWhiteLabel: 'halfWhiteLabel',
};

export const paymentServiceProvider = {
  stripe: 'STRIPE',
  paypal: 'PAYPAL',
};

export const TicketStatus = {
  1: 'New',
  2: 'In Progress',
  3: 'Resolved',
  4: 'Closed',
};
export const TicketStatusByValue = {
  New: 1,
  InProgress: 2,
  Resolved: 3,
  Closed: 4,
};
export const TicketStatusArr = [
  { label: 'New', value: 1 },
  { label: 'In-Progress', value: 2 },
  { label: 'Resolved', value: 3 },
  { label: 'Closed', value: 4 },
];
export const EmailCampaignChartTimeArr = [
  { label: 'Year', value: 1 },
  { label: 'Month', value: 2 },
  { label: 'Week', value: 3 },
];
export const CampaignChartArr = [
  { label: 'Email Campaign', value: 1 },
  { label: 'Workflow Campaign', value: 2 },
];
export const CampaignChartObj = {
  EmailCampaign: 1,
  WorkflowCampaign: 2,
};

export const ImportStatus = {
  1: 'Pending',
  2: 'Completed',
};

export const EmailVerification = {
  1: 'Not Started',
  2: 'In Progress',
  3: 'Completed',
};

export const paymentConnectStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const textMarketingLength = 256;
export const ReviewSource = {
  Bixpand: 1,
  Google: 2,
  Yelp: 3,
  Facebook: 4,
  Tripadvisor: 5,
  Opentable: 6,
  Angielist: 7,
  Realtor: 8,
  Trustpilot: 9,
  G2: 10,
  Clutch: 11,
  Capterra: 12,
  Apartments: 13,
};
export const PlanIntervals = {
  ONE_MONTH: 'ONE_MONTH',
  SIX_MONTH: 'SIX_MONTH',
  TWELVE_MONTH: 'TWELVE_MONTH',
};

export const EntityType = {
  Agency: 1,
  Business: 2,
  Location: 3,
  Customer: 4,
  MasterAdmin: 5,
  User: 6,
};
export const fontSize = {
  heading: 20,
  subHeading: 14,
  text: 12,
};
export const AppointmentStatus = {
  draft: 1,
  active: 2,
  inactive: 3,
};

export const AppointmentListType = {
  MyAppointment: 1,
  LocationAppointment: 2,
};
export const ActiveAppointmentStatus = {
  inactive: 'inactive',
};
export const DealType = {
  1: 'New Business',
  2: 'Exiting Business',
};
export const serviceProvider = {
  1: 'Google',
  2: 'Outlook',
};
export const timeFormat = {
  time: 'HH:mm:ss',
  times: 'YYYY-MMM-DD HH:mm A',
};
export const dealType = {
  0: [1, 2],
  'New Business': [1],
  'Exiting Business': [2],
};
export const DealStage = {
  1: 'Appointment scheduled',
  2: 'Qualified to buy',
  3: 'Presentation Schedules',
  4: 'Decision maker bought in',
  5: 'Contract sent',
  6: 'Closed Won',
  7: 'Closed Lost',
};

export const ContentMarketingStatus = {
  Published: 1,
  Draft: 2,
};

export const accessPermissionIds = {
  workflowPermissionId: 35,
  linkedinPermissionId: 37,
};

export const DealStages = {
  Appointmentscheduled: 1,
  Qualifiedtobuy: 2,
  PresentationSchedules: 3,
  Decisionmakeboughtin: 4,
  Contractsent: 5,
  Closedwon: 6,
  Closedlost: 7,
};
export const TaskStages = {
  Notstarted: 1,
  InProgress: 2,
  Completed: 3,
  Overdue: 4,
  Stuck: 5,
};
export const EmailType = {
  sent: 1,
  reply: 2,
  draft: 3,
};
export const EmailTypeStatus = {
  sent: [2, 3],
  draft: [1],
};
export const fieldLable = { style: { fontSize: 12 } };
export const AddressType = {
  Contact: 1,
  Organization: 2,
};
export const CampaginStatusInfo = {
  1: 'Publish',
  2: 'Draft',
};
export const fontFamily = '\'Open Sans\', sans-serif';

export const captureLeadStatus = {
  1: 'Active',
  2: 'In-Active',
  3: 'Active Date',
};
export const Roles = {
  MasterAdmin: 1,
  AgencyAdmin: 2,
  AgencyManager: 3,
  BusinessAdmin: 4,
  BusinessManager: 5,
  LocationManager: 6,
  LocationUser: 7,
};

export const NewsLetterStatus = {
  Completed: 1,
  Draft: 2,
  Scheduled: 3,
  Failed: 4,
};
export const NewsLetterStatusList = {
  1: 'Completed',
  2: 'Draft',
  3: 'Scheduled',
  4: 'Failed',
};

export const Agency = {
  active: 1,
  inactive: 2,
};

export const SubscriptionStatus = {
  NA: 1,
  Subscribed: 2,
  UnSubscribe: 3,
};

export const ThirdPartyConnetion = {
  GoogleEmail: 'googleemail',
  OutlookEmail: 'outlookemail',
};

export const CalenderConnetion = {
  GoogleCalender: 'googlecalender',
  OutlookCalender: 'outlookcalender',
};

export const SubscriptionByStatus = [
  { id: 1, value: 'NA' },
  { id: 2, value: 'Subscribed' },
  { id: 3, value: 'UnSubscribe' },
];

export const CRMStatus = {
  1: 'Cold',
  2: 'Hot',
  3: 'Warm',
  4: 'Inactive',
  5: 'Email Incorrect',
  6: 'Unsubscribed',
};
export const contectStatus = {
  1: 'Published',
  2: 'Draft',
};
export const segmentStatus = {
  1: 'active',
  2: 'inactive',
};

export const platform = {
  1: 'Facebook',
  2: 'LinkedIn',
};

export const platformId = {
  Facebook: 1,
  Linkedin: 2,
  Instagram: 4,
};

export const platformValue = [
  {
    id: 1,
    value: 'Facebook',
  },
  {
    id: 2,
    value: 'LinkedIn',
  },
  {
    id: 3,
    value: 'GMB',
  },
  {
    id: 4,
    value: 'Instagram',
  },
];

export const facebookLimitaion = `Compose a Facebook post based on the provided topic/outline, adhering to the following guidelines:
    
1. Ensure the post is concise, engaging, and no more than 200 words.
2. Include 3-5 relevant hashtags related to the post's content.
3. Incorporate emojis wherever possible. 

Outline of the post: 
[[Provide topic/outline of the post here.]]`;

export const linkedInLimitaion = `Compose a LinkedIn post based on the provided topic/outline, adhering to the following guidelines:

1. Ensure the post is concise, engaging, and between 150 to 300 words.
2. Include 3-5 relevant hashtags related to the post's content.
3. Use paragraphs wherever possible and begin each with a relevant emoji reflecting the paragraph's content.

Outline of the post: 
[[Provide topic/outline of the post here.]]`;

export const GMBLimitation = `Compose a GMB post based on the provided topic/outline, adhering to the following guidelines:

1. Ensure the post is concise, engaging, and no more than 300 words.
2. Include 3-5 relevant hashtags related to the post's content.
3. Use paragraphs wherever possible and begin each with a relevant emoji reflecting the paragraph's content.

Outline of the post: 
[[Provide topic/outline of the post here.]]`;

export const SocialmediaStatus = {
  1: 'Draft',
  2: 'Completed',
  3: 'Scheduled',
  4: 'Failed',
};

export const SocialByStatus = {
  Draft: 1,
  Completed: 2,
  Scheduled: 3,
  Failed: 4,
};

export const SocialAccountStatus = {
  1: 'Pending',
  2: 'Connected',
  3: 'Error',
};

export const platformStatus = {
  Facebook: 1,
  LinkedIn: 2,
  GMB: 3,
  Instagram: 4,
};

export const tone = [
  'None',
  'Witty',
  'Casual',
  'Excited',
  'Curious',
  'Informative',
  'Sarcastic',
  'Secretive',
  'Bold',
  'Funny',
  'Professional',
  'Dramatic',
  'Grumpy',
];

export const actionTypeAutomation = {
  email: 1,
  Sms: 2,
  CallTask: 3,
  Task: 4,
  Deal: 5,
  Document: 6,
  Property: 7,
  LinkedIn: 8,
};

export const workflowTemplateType = {
  Public: 1,
  Private: 2,
};

export const workflowContact = {
  Active: 1,
  Push: 2,
  InActive: 3,
};

export const workflowContactList = {
  1: 'Active',
  2: 'Pause',
  3: 'InActive',
};

export const workflowSteps = {
  Active: 1,
  InActive: 2,
};

export const workflowStepType = {
  MainStep: 1,
  ChildStep: 2,
};

export const workflowStatus = {
  Draft: 1,
  Active: 2,
  InActive: 3,
};

export const iconAutomation = {
  1: <MailIcon color="primary" />,
  2: <SmsIcon color="primary" />,
  3: <CallIcon color="primary" />,
  4: <AssignmentTurnedInIcon color="primary" />,
  5: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/workflowdeal.svg" width={24} height={24} alt="workflow" />,
  6: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/workflowdocument.svg" width={24} height={24} style={{ marginBottom: 5, marginRight: 2, }} alt="workflow" />,
  7: <FormatListBulleted color="primary" />,
  8: <img
    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/linkedin.svg"
    alt="source"
    width="23px"
  />,
};

export const stepType = {
  1: 'Email',
  2: 'Text',
  3: 'Call',
  4: 'Task',
  5: 'Deal',
  6: 'Document',
  7: 'Property',
  8: 'LinkedIn',
};

export const workFlowReportIcon = {
  1: <MailIcon color="primary" />,
  2: <SmsIcon color="primary" />,
  3: <CallIcon color="primary" />,
  4: <AssignmentTurnedInIcon color="primary" />,
  5: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/workflowdeal.svg" alt="workflow" />,
  6: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/svgviewer-output%20(3)%206.svg" alt="workflow" width={25} />,
  7: <FormatListBulleted color="primary" />,
  8: <img
    src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/linkedin.svg"
    alt="source"
    width="23px"
  />,
  9: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/dh_deals.svg" alt="workflow" />,
};

export const automationName = {
  1: 'Email',
  2: 'Text',
  3: 'Call',
  4: 'Task',
  5: 'Deal',
  6: 'Document',
  7: 'Property',
  8: 'LinkedIn',
};

export const CRMStatusArray = [1, 2, 3, 4, 5, 6];

export const Days = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  31,
];

export const Hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

export const Minutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
  46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];
export const dateFormatBrowser = 'YYYY-MM-DDTHH:mm:ssZ';

export const domainStatus = {
  1: 'Pending',
  2: 'Verified',
  3: 'Failed',
};

export const domainStatusCount = {
  Pending: 1,
  Verified: 2,
  Failed: 3,
};

const subject = 'Newsletter Demo';
export const dummyimage = 'https://dummyimage.com/250x150/000/fff';
const date = moment(Date.now()).format('ddd, MMM DD, YYYY h:mm A');

export const newsLetterWidget = [
  {
    id: 1,
    img: dummyimage,
    head: subject,
    date,
  },
  {
    id: 2,
    img: dummyimage,
    head: subject,
    date,
  },
  {
    id: 3,
    img: dummyimage,
    head: subject,
    date,
  },
];

export const eventWidgetData = [
  {
    id: 1,
    img: 'https://dummyimage.com/250x150/000/fff',
    heading: 'Event Demo 1',
    eventDate: `${moment(Date.now()).format('dddd, MMMM DD,YYYY HH:mm A z')} To ${moment(Date.now()).add(30, 'm').format('dddd, MMMM DD,YYYY HH:mm A z')}`,
  },
  {
    id: 2,
    img: 'https://dummyimage.com/250x150/000/fff',
    heading: 'Event Demo 2',
    eventDate: `${moment(Date.now()).format('dddd, MMMM DD,YYYY HH:mm A z')} To ${moment(Date.now()).add(30, 'm').format('dddd, MMMM DD,YYYY HH:mm A z')}`,
  },
  {
    id: 3,
    img: 'https://dummyimage.com/250x150/000/fff',
    heading: 'Event Demo 3',
    eventDate: `${moment(Date.now()).format('dddd, MMMM DD,YYYY HH:mm A z')} To ${moment(Date.now()).add(30, 'm').format('dddd, MMMM DD,YYYY HH:mm A z')}`,
  },
];

export const customField = [
  'firstname',
  'lastname',
  'primaryemail',
  'phonecountryid',
  'phonenumber',
  'jobid',
  'street1',
  'street2',
  'city',
  'stateid',
  'state',
  'countryid',
  'zip',
  'twitter',
  'linkedin',
  'facebook',
  'tags',
  'source',
  'organizationstreet1',
  'organizationstreet2',
  'organizationcity',
  'organizationstateid',
  'organizationstate',
  'organizationcountryid',
  'organizationzip',
  'categories',
  'attribute1',
  'attribute2',
  'attribute3',
  'attribute4',
  'attribute5',
  'attribute6',
  'attribute7',
  'attribute8',
  'attribute9',
  'attribute10',
  'attribute11',
  'attribute12',
  'attribute13',
  'attribute14',
  'attribute15',
  'attribute16',
  'attribute17',
  'attribute18',
  'attribute19',
  'attribute20',
  'attribute21',
  'attribute22',
  'attribute23',
  'attribute24',
  'attribute25',
  'attribute26',
  'attribute27',
  'attribute28',
  'attribute29',
  'attribute30',
  'attribute31',
  'attribute32',
  'attribute33',
  'attribute34',
  'attribute35',
  'attribute36',
  'attribute37',
  'attribute38',
  'attribute39',
  'attribute40',
  'attribute41',
  'attribute42',
  'attribute43',
  'attribute44',
  'attribute45',
  'attribute46',
  'attribute47',
  'attribute48',
  'attribute49',
  'attribute50',
  'name',
  'orgphonecountryid',
  'orgphonenumber',
  'orgemail',
  'employeesize',
  'yearsinbusiness',
  'revenue',
  'businessstartdate',
  'typeofentity',
  'orgmap',
  'orgwebsite',
  'orgtwitter',
  'orgfacebook',
  'orgstatus',
  'orglinkedin',
  'orggmb',
  'orgyelp',
  'orgtripadvisor',
  'orgtags',
  'orgsource',
  'orgownerid',
  'shortdesc',
  'longdesc',
  'orgyoutubeurl',
  'orgattribute1',
  'orgattribute2',
  'orgattribute3',
  'orgattribute4',
  'orgattribute5',
  'orgattribute6',
  'orgattribute7',
  'orgattribute8',
  'orgattribute9',
  'orgattribute10',
  'orgattribute11',
  'orgattribute12',
  'orgattribute13',
  'orgattribute14',
  'orgattribute15',
  'orgattribute16',
  'orgattribute17',
  'orgattribute18',
  'orgattribute19',
  'orgattribute20',
  'orgattribute21',
  'orgattribute22',
  'orgattribute23',
  'orgattribute24',
  'orgattribute25',
  'orgattribute26',
  'orgattribute27',
  'orgattribute28',
  'orgattribute29',
  'orgattribute30',
  'orgattribute31',
  'orgattribute32',
  'orgattribute33',
  'orgattribute34',
  'orgattribute35',
  'orgattribute36',
  'orgattribute37',
  'orgattribute38',
  'orgattribute39',
  'orgattribute40',
  'orgattribute41',
  'orgattribute42',
  'orgattribute43',
  'orgattribute44',
  'orgattribute45',
  'orgattribute46',
  'orgattribute47',
  'orgattribute48',
  'orgattribute49',
  'orgattribute50',
];

export const CRMFilter = {
  1: 'Equal To',
  2: 'Not Equal To',
  3: 'In',
  4: 'Not In',
  5: 'Greater Than Or Equal To',
  6: 'Less Than Or Equal To',
  7: 'Date Rang',
};

export const INTEGER = [
  { value: 'Equal To' },
  { value: 'Not Equal To' },
  { value: 'In' },
  { value: 'Not In' },
  { value: 'Greater Than Or Equal To' },
  { value: 'Less Than Or Equal To' },
  { value: 'Is Null' },
  { value: 'Is Not Null' },
];

export const DATE = [
  { value: 'Date Range' },
  { value: 'Is Null' },
  { value: 'Is Not Null' },
];

export const emailStatus = {
  1: 'Not',
  2: 'Pending',
  3: 'Verified',
  4: 'invalid',
};

export const emailReportStatus = {
  1: 'Draft',
  2: 'Completed',
  3: 'Scheduled',
  4: 'Failed',
};

export const emailMarketinReportStatus = {
  1: 'Success',
  2: 'Error',
  3: 'Pending',
};

export const signatureTypeValue = {
  no: 1,
  default: 2,
  custom: 3,
};

export const userDataType = {
  All: 1,
  Own: 2,
};

export const SalesOrderStatus = [
  { label: 'Completed', value: 1 },
  { label: 'Pending', value: 2 },
];

export const SalesOrderStatusTitle = {
  1: 'Completed',
  2: 'Pending',
}

export const OrderStatus = {
  1: 'Completed',
  2: 'Pending',
};

export const signatureTypeName = {
  1: 'no',
  2: 'default',
  3: 'custom',
};

export const EmailField = [
  '{{FirstName}}',
  '{{LastName}}',
  '{{FullName}}',
  '{{Email}}',
  '{{PhoneNumber}}',
  '{{JobTitle}}',
  '{{City}}',
  '{{State}}',
  '{{Country}}',
  '{{OrganizationName}}',
  '{{OrganizationEmail}}',
  '{{OrganizationPhoneNumber}}',
  '{{OrganizationCategories}}',
  '{{OrganizationWebsite}}',
  '{{OrganizationCity}}',
  '{{OrganizationState}}',
  '{{OrganizationCountry}}',
];

export const LinkedInField = [
  '{{FirstName}}',
  '{{LastName}}',
  '{{FullName}}',
  '{{Email}}',
  '{{PhoneNumber}}',
  '{{OrganizationName}}'
];

export const FieldType = [
  { id: 1, value: 'Single-line text' },
  { id: 2, value: 'Multi-line text' },
  { id: 3, value: 'Single checkbox' },
  { id: 4, value: 'Multiple checkbox' },
  { id: 5, value: 'Dropdown select' },
  { id: 6, value: 'Radio select' },
  { id: 7, value: 'Date picker' },
  { id: 8, value: 'Number' },
  { id: 9, value: 'Phonenumber' },
  { id: 10, value: 'Rating' },
  { id: 11, value: 'Contact DropDown' },
];
export const FieldTypeDropdown = [
  { id: 5, value: 'Dropdown select' },
];

export const FieldTypeNumber = {
  1: 'Single-line text',
  2: 'Multi-line text',
  3: 'Single checkbox',
  4: 'Multiple checkbox',
  5: 'Dropdown select',
  6: 'Radio select',
  7: 'Date picker',
  8: 'Number',
  9: 'Phonenumber',
  10: 'Rating',
  11: 'Contact DropDown',
};

export const getFieldType = {
  Singlelinetext: 1,
  Multilinetext: 2,
  Singlecheckbox: 3,
  Multiplecheckbox: 4,
  Dropdownselect: 5,
  Radioselect: 6,
  Datepicker: 7,
  Numbers: 8,
  Phonenumber: 9,
  Rating: 10,
  ContactDropDown: 11,
};

export const PropertiesObjType = [
  { key: 'Contact', value: 'contact' },
  { key: 'Organization', value: 'organization' },
  { key: 'Deals', value: 'deals' },
  { key: 'Sales Order', value: 'order' },
  { key: 'Order Items', value: 'orderItem' },
  { key: 'Ticket', value: 'ticket' },
  { key: 'Product', value: 'product' },
];

export const PropertiesObj = [
  { key: 'Contact', value: 'contact' },
  { key: 'Organization', value: 'organization' },
  { key: 'Deals', value: 'deals' },
  { key: 'Sales Order', value: 'order' },
  { key: 'Order Items', value: 'orderItem' },
  { key: 'Ticket', value: 'ticket' },
  { key: 'Product', value: 'product' },
];

export const emailMarketingColumnStatus = {
  Google: 1,
  Outlook: 2,
  SMTP: 3,
};

export const STRING = [
  { value: 'Equal To' },
  { value: 'Not Equal To' },
  { value: 'In' },
  { value: 'Not In' },
  { value: 'Like' },
  { value: 'Is Null' },
  { value: 'Is Not Null' },
];

export const UUID = [
  { value: 'Equal To' },
  { value: 'Not Equal To' },
  { value: 'In' },
  { value: 'Not In' },
  { value: 'Is Null' },
  { value: 'Is Not Null' },
];

export const Json = [
  { value: 'Equal To' },
  { value: 'In' },
  { value: 'Like' },
  { value: 'Is Null' },
  { value: 'Is Not Null' },
];

export const BOOLEAN = [
  { value: 'Equal To' },
  { value: 'Is Null' },
  { value: 'Is Not Null' },
];

export const TochlessPubilcStatus = {
  Warm: 3,
};

export const PersonalizationTag = 'Personalization Tag';

export const columnSelected = [
  { columnname: 'Phone', dbcolumnname: 'phonenumber', table: 'contact' },
  { columnname: 'Lifecycle', dbcolumnname: 'lifecyclestage', table: 'contact' },
  { columnname: 'Status', dbcolumnname: 'status', table: 'contact' },
  { columnname: 'Tags', dbcolumnname: 'tags', table: 'contact' },
  { columnname: 'Source', dbcolumnname: 'source', table: 'contact' },
  { columnname: 'Contact Owner', dbcolumnname: 'owneruser', table: 'contact' },
  { columnname: 'Created', dbcolumnname: 'createdAt', table: 'contact' },
  { columnname: 'Action', dbcolumnname: 'istargetlist', table: 'contact' },
];
export const CRMTasktype = {
  1: 'To do',
  2: 'Call',
  3: 'Email',
};

export const formTypes = [
  {
    id: 2,
    name: 'Embedded Form',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/formtype_1.svg" alt="formtype" />,
    description: 'Create a form that you can embed as part of your website.',
    disabled: false,
    image: false,
    onLoad: false,
    pageScroll: false,
    exitIntent: false,
    elapsedTime: false,
    fixedDisplay: false,
    fields: [
      { name: 'firstName', label: 'First Name', disable: true },
      { name: 'lastName', label: 'Last Name' },
      { name: 'email', label: 'Email', disable: true },
      { name: 'phoneNumber', label: 'Phone Number' },
    ],
  },
  {
    id: 3,
    name: 'Pop-Up Box',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/formtype_2.svg" alt="formtype" />,
    description: 'Add a form that pops up as a box in the center of the page.',
    disabled: false,
    image: false,
    onLoad: true,
    pageScroll: true,
    exitIntent: true,
    elapsedTime: true,
    fixedDisplay: false,
    position: ['Center', 'Right-Bottom', 'Left-Bottom'],
    fields: [
      { name: 'firstName', label: 'First Name', disable: true },
      { name: 'lastName', label: 'Last Name' },
      { name: 'email', label: 'Email', disable: true },
      { name: 'phoneNumber', label: 'Phone Number' },
    ],
  },
  {
    id: 5,
    name: 'Subscription Form',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/formtype_3.svg" alt="formtype" />,
    description: 'Create a form that you can embed as part of your website.',
    disabled: false,
    image: false,
    onLoad: false,
    pageScroll: false,
    exitIntent: false,
    elapsedTime: false,
    fixedDisplay: false,
    fields: [
      { name: 'firstName', label: 'First Name', disable: true },
      { name: 'lastName', label: 'Last Name' },
      { name: 'email', label: 'Email', disable: true },
      { name: 'phoneNumber', label: 'Phone Number' },
    ],
  },
];

export const submitButtonsPosition = [
  {
    id: 1,
    name: 'right',
    value: 'Right',
  },
  {
    id: 2,
    name: 'center',
    value: 'Center',
  },
  {
    id: 3,
    name: 'left',
    value: 'Left',
  },
];
export const formFieldPosition = [
  {
    id: 1,
    label: 'Vertical',
    value: 'vertical',
  },
  {
    id: 2,
    label: 'Horizontal',
    value: 'horizontal',
  },
];
export const radioButtonsForm = [
  {
    id: 1,
    name: 'Form Step',
    disabled: false,
  },
  {
    id: 2,
    name: 'Non-Bixpand URL',
    disabled: false,
  },
  {
    id: 3,
    name: 'File Download',
    disabled: false,
  },
  {
    id: 4,
    name: 'Meeting Link',
    disabled: true,
  },
  {
    id: 5,
    name: 'Calendar Event',
    disabled: true,
  },
];
export const radioButtonsFormThankYou = [
  {
    id: 2,
    name: 'Non-Bixpand URL',
    disabled: false,
  },
  {
    id: 3,
    name: 'File Download',
    disabled: false,
  },
  {
    id: 4,
    name: 'Meeting Link',
    disabled: true,
  },
  {
    id: 5,
    name: 'Calendar Event',
    disabled: true,
  },
];
export const CRMTaskPriority = {
  1: 'High',
  2: 'Medium',
  3: 'Low',
};

export const CRMEmailServicesProvider = {
  1: 'Bixpand Email Service',
  2: 'Gmail Service',
  3: 'Outlook Email Service',
};
export const TouchLessPublicId = {
  ReviewUs: 0,
  Rating: 1,
};

// Contact Email ServiceProvider
export const EmailServicesProvider = {
  BixpandEmailService: 1,
  GmailService: 2,
  OutlookEmailService: 3,
  SMTPService: 4,
};

export const EmailReportServicesProvider = {
  1: 'Bixpand Email Service',
  2: 'Gmail Service',
  3: 'Outlook Email Service',
  4: 'SMTP Service',
};

export const CRMCommentType = {
  note: 1,
  task: 2,
  docs: 3,
  deal: 4,
};

export const CRMTaskStatus = {
  1: 'Not Started',
  2: 'In Progress',
  3: 'Completed',
  4: 'Overdue',
  5: 'Stuck',
};

export const CampaginStatus = {
  Draft: 1,
  Completed: 2,
  Scheduled: 3,
  Failed: 4,
};
export const TemplateType = {
  Premium: 1,
  Free: 2,
};
export const WorkflowOptionStatus = {
  OptionEnable: 1,
  OptionDisable: 2,
};

export const RatingColor = {
  IconFilled: '#0259a8',
  IconHover: '#0259a8',
  IconEmpty: '#74b6f7',
};
export const CRMEmailStatus = {
  Draft: 1,
  Completed: 2,
  Scheduled: 3,
  Failed: 4,
  replied: 5,
};
export const CurrencyConst = [
  { label: 'USD', value: 'USD' },
  { label: 'INR', value: 'INR' },
];

export const CurrencySymbol = {
  USD: '$',
  INR: '₹',
  AED: 'Ø¯.Ø¥',
  AFN: 'Ø‹',
  ALL: 'L',
  AMD: 'Õ¤Ö€.',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: 'm',
  BAM: 'ÐšÐœ',
  BBD: '$',
  BDT: 'à§³',
  BGN: 'Ð»Ð²',
  BHD: '.Ø¯.Ø¨',
  BIF: 'Fr',
  BMD: '$',
  BND: '$',
  BOB: 'Bs.',
  BOV: '',
  BRL: 'R$',
  BSD: '$',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BZD: '$',
  CAD: '$',
  CDF: 'Fr',
  CHE: '',
  CHF: 'Fr',
  CHW: '',
  CLF: '',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: '',
  CRC: 'â‚¡',
  CUC: '$',
  CUP: '$',
  CVE: 'Esc or $',
  CZK: 'KÄ',
  DJF: 'Fr',
  DKK: 'kr',
  DOP: '$',
  DZD: 'Ø¯.Ø¬',
  EGP: 'Ø¬.Ù…',
  ERN: 'Nfk',
  ETB: 'Br',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: 'áƒš',
  GHS: 'â‚µ',
  GIP: '£',
  GMD: 'D',
  GNF: 'Fr',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: '$',
  JOD: 'د.ا',
  JPY: '¥',
  KES: 'Sh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'Fr',
  KPW: '₩',
  KRW: '₩',
  KWD: 'د.ك',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: 'ل.ل',
  LKR: 'Rs',
  LRD: '$',
  LSL: 'L',
  LYD: 'ل.د',
  MAD: 'د.م.',
  MDL: 'L',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'Ks',
  MNT: '₮',
  MOP: 'P',
  MRU: 'UM',
  MUR: '₨',
  MVR: '.ރ',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: 'ر.ع.',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'ر.ق',
  RON: 'lei',
  RSD: 'дин.',
  RUB: '₽',
  RWF: 'Fr',
  SAR: 'ر.س',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'Sh',
  SRD: '$',
  SSP: '£',
  STN: 'Db',
  SVC: '₡',
  SYP: '£',
  SZL: 'L',
  THB: '฿',
  TJS: 'ЅМ',
  TMT: 'm',
  TND: 'د.ت',
  TOP: 'T$',
  TRY: '₺',
  TTD: '$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'Sh',
  UAH: '₴',
  UGX: 'Sh',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  VUV: 'Vt',
  WST: 'T',
  XAF: 'Fr',
  XCD: '$',
  XDR: 'SDR',
  XOF: 'Fr',
  XPF: '₣',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWL: '$',
};

export const CurrencyJSON = [
  { label: 'USD ($)', value: 'USD', symbol: '$' },
  { label: 'INR (₹)', value: 'INR', symbol: '₹' },
  { label: 'CAD ($)', value: 'CAD', symbol: '$' },
  { label: 'AED (Ø¯.Ø¥)', value: 'AED', symbol: 'Ø¯.Ø¥' },
  { label: 'AFN (Ø‹)', value: 'AFN', symbol: 'Ø‹' },
  { label: 'ALL (L)', value: 'ALL', symbol: 'L' },
  { label: 'AMD (Õ¤Ö€.)', value: 'AMD', symbol: 'Õ¤Ö€.' },
  { label: 'ANG (ƒ)', value: 'ANG', symbol: 'ƒ' },
  { label: 'AOA (Kz)', value: 'AOA', symbol: 'Kz' },
  { label: 'ARS ($)', value: 'ARS', symbol: '$' },
  { label: 'AUD ($)', value: 'AUD', symbol: '$' },
  { label: 'AWG (ƒ)', value: 'AWG', symbol: 'ƒ' },
  { label: 'AZN (m)', value: 'AZN', symbol: 'm' },
  { label: 'BAM (ÐšÐœ)', value: 'BAM', symbol: 'ÐšÐœ' },
  { label: 'BBD ($)', value: 'BBD', symbol: '$' },
  { label: 'BDT (à§³)', value: 'BDT', symbol: 'à§³' },
  { label: 'BGN (Ð»Ð²)', value: 'BGN', symbol: 'Ð»Ð²' },
  { label: 'BHD (.Ø¯.Ø¨)', value: 'BHD', symbol: '.Ø¯.Ø¨' },
  { label: 'BIF (Fr)', value: 'BIF', symbol: 'Fr' },
  { label: 'BMD ($)', value: 'BMD', symbol: '$' },
  { label: 'BND ($)', value: 'BND', symbol: '$' },
  { label: 'BOB (Bs.)', value: 'BOB', symbol: 'Bs.' },
  { label: 'BOV ()', value: 'BOV', symbol: '' },
  { label: 'BRL (R$)', value: 'BRL', symbol: 'R$' },
  { label: 'BSD ($)', value: 'BSD', symbol: '$' },
  { label: 'BTN (Nu.)', value: 'BTN', symbol: 'Nu.' },
  { label: 'BWP (P)', value: 'BWP', symbol: 'P' },
  { label: 'BYN (Br)', value: 'BYN', symbol: 'Br' },
  { label: 'BZD ($)', value: 'BZD', symbol: '$' },
  { label: 'CDF (Fr)', value: 'CDF', symbol: 'Fr' },
  { label: 'CHE ()', value: 'CHE', symbol: '' },
  { label: 'CHF (Fr)', value: 'CHF', symbol: 'Fr' },
  { label: 'CHW ()', value: 'CHW', symbol: '' },
  { label: 'CLF ()', value: 'CLF', symbol: '' },
  { label: 'CLP ($)', value: 'CLP', symbol: '$' },
  { label: 'CNY (¥)', value: 'CNY', symbol: '¥' },
  { label: 'COP ($)', value: 'COP', symbol: '$' },
  { label: 'COU ()', value: 'COU', symbol: '' },
  { label: 'CRC (â‚¡)', value: 'CRC', symbol: 'â‚¡' },
  { label: 'CUC ($)', value: 'CUC', symbol: '$' },
  { label: 'CUP ($)', value: 'CUP', symbol: '$' },
  { label: 'CVE (Esc or $)', value: 'CVE', symbol: 'Esc or $' },
  { label: 'CZK (KÄ)', value: 'CZK', symbol: 'KÄ' },
  { label: 'DJF (Fr)', value: 'DJF', symbol: 'Fr' },
  { label: 'DKK (kr)', value: 'DKK', symbol: 'kr' },
  { label: 'DOP ($)', value: 'DOP', symbol: '$' },
  { label: 'DZD (Ø¯.Ø¬)', value: 'DZD', symbol: 'Ø¯.Ø¬' },
  { label: 'EGP (Ø¬.Ù…)', value: 'EGP', symbol: 'Ø¬.Ù…' },
  { label: 'ERN (Nfk)', value: 'ERN', symbol: 'Nfk' },
  { label: 'ETB (Br)', value: 'ETB', symbol: 'Br' },
  { label: 'EUR (€)', value: 'EUR', symbol: '€' },
  { label: 'FJD ($)', value: 'FJD', symbol: '$' },
  { label: 'FKP (£)', value: 'FKP', symbol: '£' },
  { label: 'GBP (£)', value: 'GBP', symbol: '£' },
  { label: 'GEL (áƒš)', value: 'GEL', symbol: 'áƒš' },
  { label: 'GHS (â‚µ)', value: 'GHS', symbol: 'â‚µ' },
  { label: 'GIP (£)', value: 'GIP', symbol: '£' },
  { label: 'GMD (D)', value: 'GMD', symbol: 'D' },
  { label: 'GNF (Fr)', value: 'GNF', symbol: 'Fr' },
  { label: 'GTQ (Q)', value: 'GTQ', symbol: 'Q' },
  { label: 'GYD ($)', value: 'GYD', symbol: '$' },
  { label: 'HKD ($)', value: 'HKD', symbol: '$' },
  { label: 'HNL (L)', value: 'HNL', symbol: 'L' },
  { label: 'HRK (kn)', value: 'HRK', symbol: 'kn' },
  { label: 'HTG (G)', value: 'HTG', symbol: 'G' },
  { label: 'HUF (Ft)', value: 'HUF', symbol: 'Ft' },
  { label: 'IDR (Rp)', value: 'IDR', symbol: 'Rp' },
  { label: 'ILS (₪)', value: 'ILS', symbol: '₪' },
  { label: 'IMP (£)', value: 'IMP', symbol: '£' },
  { label: 'IQD (Ø¹.Ø¯)', value: 'IQD', symbol: 'Ø¹.Ø¯' },
  { label: 'IRR (﷼)', value: 'IRR', symbol: '﷼' },
  { label: 'ISK (kr)', value: 'ISK', symbol: 'kr' },
  { label: 'JEP (£)', value: 'JEP', symbol: '£' },
  { label: 'JMD ($)', value: 'JMD', symbol: '$' },
  { label: 'JOD (Ø¯.Ø§)', value: 'JOD', symbol: 'Ø¯.Ø§' },
  { label: 'JPY (¥)', value: 'JPY', symbol: '¥' },
  { label: 'KES (Sh)', value: 'KES', symbol: 'Sh' },
  { label: 'KGS (Ð») ', value: 'KGS', symbol: 'Ð»' },
  { label: 'KHR (â¿)', value: 'KHR', symbol: 'â¿' },
  { label: 'KMF (Fr)', value: 'KMF', symbol: 'Fr' },
  { label: 'KPW (â‚©)', value: 'KPW', symbol: 'â‚©' },
  { label: 'KRW (₩)', value: 'KRW', symbol: '₩' },
  { label: 'KWD (Ø¯.Ùƒ)', value: 'KWD', symbol: 'Ø¯.Ùƒ' },
  { label: 'KYD ($)', value: 'KYD', symbol: '$' },
  { label: 'KZT (â‚¸)', value: 'KZT', symbol: 'â‚¸' },
  { label: 'LAK (â‚­)', value: 'LAK', symbol: 'â‚­' },
  { label: 'LBP (£)', value: 'LBP', symbol: '£' },
  { label: 'LKR (â‚¨)', value: 'LKR', symbol: 'â‚¨' },
  { label: 'LRD ($)', value: 'LRD', symbol: '$' },
  { label: 'LSL (L)', value: 'LSL', symbol: 'L' },
  { label: 'LYD (Ù„.Ø¯)', value: 'LYD', symbol: 'Ù„.Ø¯' },
  { label: 'MAD (Ø¯.Ù….)', value: 'MAD', symbol: 'Ø¯.Ù….' },
  { label: 'MDL (L)', value: 'MDL', symbol: 'L' },
  { label: 'MGA (Ar)', value: 'MGA', symbol: 'Ar' },
  { label: 'MKD (Ð´ÐµÐ½)', value: 'MKD', symbol: 'Ð´ÐµÐ½' },
  { label: 'MMK (K)', value: 'MMK', symbol: 'K' },
  { label: 'MNT (â‚®)', value: 'MNT', symbol: 'â‚®' },
  { label: 'MOP (P)', value: 'MOP', symbol: 'P' },
  { label: 'MRU (UM)', value: 'MRU', symbol: 'UM' },
  { label: 'MUR (â‚¨)', value: 'MUR', symbol: 'â‚¨' },
  { label: 'MVR (Rf)', value: 'MVR', symbol: 'Rf' },
  { label: 'MWK (MK)', value: 'MWK', symbol: 'MK' },
  { label: 'MXN ($)', value: 'MXN', symbol: '$' },
  { label: 'MYR (RM)', value: 'MYR', symbol: 'RM' },
  { label: 'MZN (MT)', value: 'MZN', symbol: 'MT' },
  { label: 'NAD ($)', value: 'NAD', symbol: '$' },
  { label: 'NGN (â‚¦)', value: 'NGN', symbol: 'â‚¦' },
  { label: 'NIO (C$)', value: 'NIO', symbol: 'C$' },
  { label: 'NOK (kr)', value: 'NOK', symbol: 'kr' },
  { label: 'NPR (â‚¨)', value: 'NPR', symbol: 'â‚¨' },
  { label: 'NZD ($)', value: 'NZD', symbol: '$' },
  { label: 'OMR (Ø±.Ø¹)', value: 'OMR', symbol: 'Ø±.Ø¹' },
  { label: 'PAB (B/.)', value: 'PAB', symbol: 'B/.' },
  { label: 'PEN (S/.)', value: 'PEN', symbol: 'S/.' },
  { label: 'PGK (K)', value: 'PGK', symbol: 'K' },
  { label: 'PHP (â‚±)', value: 'PHP', symbol: 'â‚±' },
  { label: 'PKR (â‚¨)', value: 'PKR', symbol: 'â‚¨' },
  { label: 'PLN (zÅ‚)', value: 'PLN', symbol: 'zÅ‚' },
  { label: 'PYG (â‚²)', value: 'PYG', symbol: 'â‚²' },
  { label: 'QAR (Ø±.Ù‚)', value: 'QAR', symbol: 'Ø±.Ù‚' },
  { label: 'RON (lei)', value: 'RON', symbol: 'lei' },
  { label: 'RSD (Ð´Ð¸Ð½.)', value: 'RSD', symbol: 'Ð´Ð¸Ð½.' },
  { label: 'RUB (â‚½)', value: 'RUB', symbol: 'â‚½' },
  { label: 'RWF (Fr)', value: 'RWF', symbol: 'Fr' },
  { label: 'SAR (Ø±.Ø³)', value: 'SAR', symbol: 'Ø±.Ø³' },
  { label: 'SBD ($)', value: 'SBD', symbol: '$' },
  { label: 'SCR (â‚¨)', value: 'SCR', symbol: 'â‚¨' },
  { label: 'SDG (Ø¬.Ø³.)', value: 'SDG', symbol: 'Ø¬.Ø³.' },
  { label: 'SEK (kr)', value: 'SEK', symbol: 'kr' },
  { label: 'SGD ($)', value: 'SGD', symbol: '$' },
  { label: 'SHP (£)', value: 'SHP', symbol: '£' },
  { label: 'SLL (Le)', value: 'SLL', symbol: 'Le' },
  { label: 'SOS (Sh)', value: 'SOS', symbol: 'Sh' },
  { label: 'SRD ($)', value: 'SRD', symbol: '$' },
  { label: 'SSP (£)', value: 'SSP', symbol: '£' },
  { label: 'STN (Db)', value: 'STN', symbol: 'Db' },
  { label: 'SVC (â‚¡)', value: 'SVC', symbol: 'â‚¡' },
  { label: 'SYP (£)', value: 'SYP', symbol: '£' },
  { label: 'SZL (L)', value: 'SZL', symbol: 'L' },
  { label: 'THB (฿)', value: 'THB', symbol: '฿' },
  { label: 'TJS (SM)', value: 'TJS', symbol: 'SM' },
  { label: 'TMT (m)', value: 'TMT', symbol: 'm' },
  { label: 'TND (Ø¯.Øª)', value: 'TND', symbol: 'Ø¯.Øª' },
  { label: 'TOP (T$)', value: 'TOP', symbol: 'T$' },
  { label: 'TRY (â‚º)', value: 'TRY', symbol: 'â‚º' },
  { label: 'TTD ($)', value: 'TTD', symbol: '$' },
  { label: 'TVD ($)', value: 'TVD', symbol: '$' },
  { label: 'TWD (NT$)', value: 'TWD', symbol: 'NT$' },
  { label: 'TZS (Sh)', value: 'TZS', symbol: 'Sh' },
  { label: 'UAH (â‚´)', value: 'UAH', symbol: 'â‚´' },
  { label: 'UGX (Sh)', value: 'UGX', symbol: 'Sh' },
  { label: 'UYU ($)', value: 'UYU', symbol: '$' },
  { label: 'UZS (â‚³)', value: 'UZS', symbol: 'â‚³' },
  { label: 'VEF ($)', value: 'VEF', symbol: '$' },
  { label: 'VND (â‚«)', value: 'VND', symbol: 'â‚«' },
  { label: 'VUV (Vt)', value: 'VUV', symbol: 'Vt' },
  { label: 'WST (T)', value: 'WST', symbol: 'T' },
  { label: 'XAF (Fr)', value: 'XAF', symbol: 'Fr' },
  { label: 'XCD ($)', value: 'XCD', symbol: '$' },
  { label: 'XDR ()', value: 'XDR', symbol: '' },
  { label: 'XOF (Fr)', value: 'XOF', symbol: 'Fr' },
  { label: 'XPF (Fr)', value: 'XPF', symbol: 'Fr' },
  { label: 'YER (Ø±.ÙŠ)', value: 'YER', symbol: 'Ø±.ÙŠ' },
  { label: 'ZAR (R)', value: 'ZAR', symbol: 'R' },
  { label: 'ZMW (ZK)', value: 'ZMW', symbol: 'ZK' },
  { label: 'ZWL ($)', value: 'ZWL', symbol: '$' },
];

export const ServiceProviderIndex = {
  Bixpand: 0,
  Google: 1,
  Outlook: 2,
  SMTP: 3,
};
export const ReviewStatus = {
  1: 'Feedback',
  2: 'Review',
  3: 'Rating',
  4: 'Negative Rating',
};

export const StripeCurrency = [
  { label: 'USD ($)', value: 'USD', symbol: '$' },
  { label: 'INR (₹)', value: 'INR', symbol: '₹' },
  { label: 'CAD ($)', value: 'CAD', symbol: '$' },
  { label: 'AUD ($)', value: 'AUD', symbol: '$' },
  { label: 'GBP (£)', value: 'GBP', symbol: '£' },
];

export const ReviewByStatus = {
  Feedback: 1,
  Review: 2,
  Rating: 3,
  NegativeRating: 4,
};
export const ReviewImage = {
  1: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png',
  2: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/google.svg',
  3: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/yelp-icon.svg',
  4: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/Facebook.svg',
  5: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/tripadvisor-icon.svg',
  6: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/opentable-icon.svg',
  7: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/angieslist-icon.svg',
  8: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/realtor.png',
  9: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/Trustpilot.svg',
  10: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/G2%20CRO.svg',
  11: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/clutchsvg.png',
  12: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/capterra.svg',
  13: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/apartment.svg',
};
export const ActivityObjectTypes = {
  contact: 1,
  note: 2,
  organizations: 3,
  email: 4,
  document: 5,
  task: 6,
  sms: 7,
};
export const ReviewStatusApi = {
  NegativeComment: 1,
  PositiveComment: 2,
  RatingPositiveComment: 3,
  RatingNegativeComment: 4,
};
export const CRMLifecyclestage = {
  2: 'Lead',
  3: 'Marketing Qualified Lead',
  4: 'Sales Qualified Lead',
  5: 'Opportunity',
  6: 'Customer',
  7: 'Competitor',
  8: 'Partner',
  9: 'Vendor',
  10: 'Annonymous',
  11: 'Other',
};
export const contactFormTypes = { popup: 'Pop-Up Box', embeddedPage: 'Embedded From', standalonePage: 'Standalone Page' };
export const CRMLifecyclestageArray = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const CRMLifecyclestageByStatus = {
  Lead: 2,
  Customer: 6,
};

export const TimeZoneList = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  ' America/Knox_IN',
  ' America/Kralendijk',
  ' America/La_Paz',
  ' America/Lima',
  ' America/Los_Angeles',
  ' America/Louisville',
  ' America/Lower_Princes',
  ' America/Maceio',
  ' America/Managua',
  ' America/Manaus',
  ' America/Marigot',
  ' America/Martinique',
  ' America/Matamoros',
  ' America/Mazatlan',
  ' America/Mendoza',
  ' America/Menominee',
  ' America/Merida',
  ' America/Metlakatla',
  ' America/Mexico_City',
  ' America/Miquelon',
  ' America/Moncton',
  ' America/Monterrey',
  ' America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'CET',
  'CST6CDT',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'EET',
  'EST',
  'EST5EDT',
  'Egypt',
  'Eire',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT-0',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/GMT0',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/UTC',
  'Etc/Universal',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'Greenwich',
  'HST',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'MST',
  'MST7MDT',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'NZ',
  'NZ-CHAT',
  'Navajo',
  'PRC',
  'PST8PDT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Pacific-New',
  'US/Samoa',
  'UTC',
  'Universal',
  'W-SU',
  'WET',
  'Zulu',
];

export const objectType = {
  1: 0,
  2: 2,
  3: 3,
  4: 6,
  5: 4,
  6: 2,
  7: 3,
  8: 6,
  9: 0,
  10: 5,
  11: 9,
  12: 9,
  13: 0,
  14: 0,
  15: 0,
  16: 0,
  17: 0,
  18: 0,
  19: 2,
  20: 0,
  21: 0,
  22: 9,
};
export const Reviewstatus = {
  Feedback: 1,
  Review: 2,
  Replied: 3,
  SharedtoGoogle: 4,
};

export const ReviewAction = {
  ActionNoAction: 1,
  ActionInProgress: 2,
  ActionTodo: 3,
  ActionTaken: 4,
};
export const regularExpressions = {
  name: /^[A-Za-z\s]+$/,
  email: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  webSite: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  twitter: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  facebook: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  gmb: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  linkedin: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  yelp: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  tripadvisor: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  link: /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  postalCode: /^[A-Za-z0-9\s]{1,12}$/,
  phonenumber: /^(\\+)?[1-9]{1}[0-9]{0,2}[0-9]{1}[0-9]{2}[0-9]{1}[0-9]{2}[0-9]{4}$/,
};
export const Icons = {
  Home: <img src="https://i.ibb.co/rvsr6xZ/workspace.png" alt="Icons" style={SvgStyle} />,
  Marketing: <img src="https://i.ibb.co/vjzc6Rt/Marketing-Central.png" alt="Icons" style={SvgStyle} />,
  'Product & Service': <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Icons_3.svg" alt="Icons" />,
  'Professional Services': <img src="https://i.ibb.co/XLXphfv/support-839961-3.png" alt="Icons" style={SvgStyle} />,
  Sales: <img src="https://i.ibb.co/Rc0JVRW/icon-park-outline-sales-report.png" alt="Icons" style={SvgStyle} />,
  CRM: <img src="https://i.ibb.co/PzjfQvH/ri-contacts-book-3-line.png" alt="Icons" style={SvgStyle} />,
  Leads: <img src="https://i.ibb.co/8MddBkb/svgexport-1-5-2.png" alt="Icons" style={SvgStyle} />,
  Reputation: <img src="https://i.ibb.co/QFf9CZS/favorite-2883290-2.png" alt="Icons" style={SvgStyle} />,
  'Promotion Central': <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Icons_9.svg" alt="Icons" style={SvgStyle} />,
  Insights: <img src="https://i.ibb.co/myWVb07/tracking-9408629-3.png" alt="Icons" style={SvgStyle} />,
  'BixConnect Page': <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Icons_11.svg" alt="Icons" style={SvgStyle} />,
  Settings: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Icons_12.svg" alt="Icons" style={SvgStyle} />,
  'Get Started': <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/Icons_13.svg" alt="Icons" style={SvgStyle} />,
};
export const SideMenu = {
  Home: "home",
  Reputation: "reputation",
  CRM: 'crm',
  Insights: 'insights',
  Settings: 'Settings',
  Leads: 'leads',
  Sales: 'sales',
  Marketing: 'marketing',
  ProfessionalServices: 'professionalServices',
};

export const DealStatus = {
  1: 'Created',
  2: 'Archived',
  3: 'Won',
  4: 'Lost',
  5: 'Deleted',
};

export const DealStatusId = {
  Created: 1,
  Archived: 2,
  Won: 3,
  Lost: 4,
  Deleted: 5,
};

export const dealStageCount = {
  three: '3',
  six: '6',
  nine: '9',
};

export const Component = {
  1: 'Textbox',
  2: 'Multiline',
  3: 'Single checkbox',
  4: 'Multiple checkbox',
  5: 'Dropdown',
  6: 'Radio',
  7: 'Date picker',
  8: 'Number',
  9: 'Phone number',
  10: 'Rating',
  12: 'Divider',
  13: 'Label',
  14: 'Image',
  15: 'Section',
  16: 'availablehours',
  17: 'Docs',
};

export const Country = {
  USA: 1,
};

export const State = {
  Alabama: 1,
};

export const EventStatus = {
  Active: 'active',
  Cancel: 'cancel',
  Past: 'Past',
  Schedule: 'Schedule',
  Draft: 'Draft',
  Cancelled: 'Cancelled',
};

export const InitialProperty = [
  {
    fieldtype: 13,
    name: 'Label',
    objecttype: 'Designing',
    propertyid: '19384760-3380-4e09-a657-9306887098a2',
    propertyname: 'Label',
    value: 'Enter Details',
  },
  {
    dbfieldname: 'firstname',
  },
  {
    dbfieldname: 'lastname',
  },
  {
    dbfieldname: 'primaryemail',
  },
  {
    dbfieldname: 'phonenumber',
  },
  {
    dbfieldname: 'jobid',
  },
  {
    dbfieldname: 'linkedin',
  },
  {
    dbfieldname: 'name',
  },
  {
    dbfieldname: 'orgwebsite',
  },
];

export const triggerImage = {
  Contact: {
    LeadInformation: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_1.svg" alt="triggerImage" />,
    EmailSubscription:
      <EmailIcon style={{ color: 'white' }} />,
  },
  Organization: {
    LeadInformation: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_1.svg" alt="triggerImage" />,
  },
  Deal: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_2.svg" alt="triggerImage" />,
  Appointment: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_3.svg" alt="triggerImage" />,
  CaptureLead: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_4.svg" alt="triggerImage" />,
  LiveChat: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_5.svg" alt="triggerImage" />,
  Event: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_6.svg" alt="triggerImage" />,
  InquiryForm: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_7.svg" alt="triggerImage" />,
  Documents: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_8.svg" alt="triggerImage" />,
  NewsLetter: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_9.svg" alt="triggerImage" />,
  EmailMarketing: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_10.svg" alt="triggerImage" />,
  ReviewMarekting: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/triggerImage_11.svg" alt="triggerImage" />,
};

export const workflowReportStatus = {
  Pending: 'The step has not executed yet',
  InProgress: 'The step execution is running',
  Completed: 'The step execution is completed',
  Failed: 'Due to some error, the step execution is failed',
};

export const pageSizes = [5, 10, 20, 50, 100];

export const CRMIcon = {
  YelpIcon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/importcustomer_1.svg" alt="importcustomer" />,
  TripadvisorIcon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/importcustomer_2.svg" alt="importcustomer" style={{ color: 'white' }} />,
  GMBIcon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/contactcrmimport_2.svg" alt="crm" />,
};

export const defaultColDef = {
  sortable: true,
  resizable: true,
  hide: false,
};

export const GridViewBox = {
  TableView: 1,
  BoardView: 2,
};

export const themeOverrides = {
  MuiOutlinedInput: {
    input: {
      color: localStorage.getItem('theme') === 'dark' ? 'white' : 'black',
    },
  },
  MuiSvgIcon: {
    root: {
      color: `${localStorage.getItem('theme') === 'dark' ? 'white' : ''} !important`,
    },
    colorDisabled: {
      color: `${localStorage.getItem('theme') === 'dark' ? 'rgba(255,255,255,0.3)' : ''} !important`,
    },
  },
  MuiPopover: {
    paper: {
      backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#23232d' : '#ffff'} !important`,
      color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'} !important`,
    },
  },
  MuiTypography: {
    body1: {
      color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'} !important`,
    },
  },
  MuiInputBase: {
    input: {
      color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'} !important`,
    },
  },
  MuiDialog: {
    paperWidthSm: {
      backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#333540' : '#ffff'} !important`,
      color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'} !important`,
    },
  },
  MuiDialogTitle: {
    root: {
      backgroundColor: `${localStorage.getItem('theme') === 'dark' ? '#333540' : '#ffff'} !important`,
      color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'} !important`,
    },
  },
  MuiButton: {
    text: {
      color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black'} !important`,
    },
  },
};

export const EditFormType = {
  contactForm: 1,
  dealForm: 2,
  orderForm: 3,
  ticketForm: 4,
};

export const TrackingSourceType = {
  website: 1,
};

export const LinkedinActivityType = {
  Message: 'message',
  Connection: 'connection',
};

export const TagActions = [{
  name: 'Add Tag', value: 'Add',
}, {
  name: 'Update Tag', value: 'Update',
}, {
  name: 'Delete Tag', value: 'Delete',
}];

export const SubScriptionStatus = [{
  name: 'NA', value: '1',
}, {
  name: 'Subscribed', value: '2',
}, {
  name: 'Unsubscribed', value: '3',
}];

export const ContactStatus = [
  {
    name: 'cold',
    value: '1',
  },
  {
    name: 'hot',
    value: '2',
  },
  {
    name: 'warm',
    value: '3',
  },
  {
    name: 'inactive',
    value: '4',
  },
  {
    name: 'Email Incorrect',
    value: '5',
  },
  {
    name: 'Unsubscribed',
    value: '6',
  },
];

export const FilterDealType = [
  {
    name: 'New Business',
    value: 'newBusiness',
  },
  {
    name: 'Exiting Business',
    value: 'exitingBusiness',
  },
];

export const ValidEmail = [
  {
    name: 'Email Not Verified',
    value: '1',
  },
  {
    name: 'Email Verification Pending',
    value: '2',
  },
  {
    name: 'Valid Email',
    value: '3',
  },
  {
    name: 'Invalid Email',
    value: '4',
  },
];

export const ValidPhone = [
  {
    name: 'Phone Number Not Verified',
    value: '1',
  },
  {
    name: 'Phone Number Verification Pending',
    value: '2',
  },
  {
    name: 'Valid Phone Number',
    value: '3',
  },
  {
    name: 'Invalid Phone Number',
    value: '4',
  },
];

export const validationErrorMsg = `Passwords must have at least 12 characters and contains all of the following: 
uppercase letters(A-Z), 
lowercase letters(a-z), 
numbers(0-9) and symbols`;

export const DefaultContactField = [
  {
    fieldtype: 1,
    isrequired: true,
    name: 'Main Name',
    objecttype: 'contact',
    propertyid: '54390c59-1451-4c47-be4b-8b3ada2d6da7',
    options: [
      {
        label: '',
        internal: '',
      },
    ],
    propertyname: 'Main Name',
    dbfieldname: 'firstname',
  },
  {
    fieldtype: 1,
    isrequired: false,
    name: 'Surname',
    objecttype: 'contact',
    propertyid: 'b49eb818-3e57-468e-9b99-caebddbb4d9d',
    options: [
      {
        label: '',
        internal: '',
      },
    ],
    propertyname: 'Surname',
    dbfieldname: 'lastname',
  },
  {
    fieldtype: 1,
    isrequired: false,
    name: 'Contact Email',
    objecttype: 'contact',
    propertyid: 'bfce1ab9-45f9-48c2-ae12-f6b7a869e36e',
    options: [
      {
        label: '',
        internal: '',
      },
    ],
    propertyname: 'Email',
    dbfieldname: 'primaryemail',
  },
  {
    fieldtype: 9,
    isrequired: false,
    name: 'Contact Phone Number',
    objecttype: 'contact',
    propertyid: 'a7ac7121-ead9-4883-a59e-dbd2029f44fe',
    options: [
      {
        label: '',
        internal: '',
      },
    ],
    propertyname: 'Phone Number',
    dbfieldname: 'phonenumber',
  },
  {
    fieldtype: 1,
    name: 'Organization New Name',
    objecttype: 'organization',
    propertyid: 'c99cecad-e193-4b09-8c28-223abe0a289d',
    options: [
      {
        label: '',
        internal: '',
      },
    ],
    propertyname: 'Organization Name',
    dbfieldname: 'name',
  },
  {
    fieldtype: 1,
    isrequired: false,
    name: 'Job Title',
    objecttype: 'contact',
    propertyid: 'e61514cb-2de7-4a9c-bf3d-5383f7383355',
    options: null,
    propertyname: 'Job Title',
    dbfieldname: 'jobid',
  },
  {
    fieldtype: 1,
    name: 'Assign WorkFlow',
    objecttype: 'workflow',
    propertyid: 'cv9cecad-e193-4b09-8c28-223abe0a289d',
    options: [
      {
        label: '',
        internal: '',
      },
    ],
    propertyname: 'Assign WorkFlow',
    dbfieldname: 'workflowid',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Tags',
    objecttype: 'contact',
    propertyid: '937047ed-137c-4297-8117-3bf36124af86',
    options: null,
    propertyname: 'Tags',
    dbfieldname: 'tags',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Source',
    objecttype: 'contact',
    propertyid: '3bb975ea-88cd-48cb-9492-73486450b658',
    options: null,
    propertyname: 'Source',
    dbfieldname: 'source',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Status',
    objecttype: 'contact',
    propertyid: '855f3511-985d-4420-80fc-d8ca0c3f641b',
    options: null,
    propertyname: 'Status',
    dbfieldname: 'status',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Lifecycle Stage',
    objecttype: 'contact',
    propertyid: '3cf707a7-07e1-4b37-9bdc-e0df7c24168d',
    options: null,
    propertyname: 'Lifecycle Stage',
    dbfieldname: 'lifecyclestage',
  },
];

export const DefaultDealField = [
  {
    fieldtype: 1,
    isrequired: true,
    name: 'Deal Name',
    objecttype: 'deal',
    propertyname: 'Deal Name',
    dbfieldname: 'dealname',
  },
  {
    fieldtype: 2,
    isrequired: false,
    name: 'Description',
    objecttype: 'deal',
    propertyname: 'Description',
    dbfieldname: 'description',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Pipeline',
    objecttype: 'deal',
    propertyname: 'Pipeline',
    dbfieldname: 'pipelineid',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Stage',
    objecttype: 'deal',
    propertyname: 'Stage',
    dbfieldname: 'stageid',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Type',
    objecttype: 'deal',
    propertyname: 'Deal Type',
    dbfieldname: 'dealtype',
  },
  {
    fieldtype: 8,
    isrequired: true,
    name: 'Amount',
    objecttype: 'deal',
    propertyname: 'Amount',
    dbfieldname: 'dealamount',
  },
  {
    fieldtype: 7,
    isrequired: false,
    name: 'Close Date',
    objecttype: 'deal',
    propertyname: 'Close Date',
    dbfieldname: 'closedate',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Deal Owner',
    objecttype: 'deal',
    propertyname: 'Deal Owner',
    dbfieldname: 'ownerid',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Contact',
    objecttype: 'deal',
    propertyname: 'Contact',
    dbfieldname: 'associatedcontact',
  },
];

export const DefaultOrderField = [
  {
    fieldtype: 1,
    isrequired: true,
    name: 'Order Id#*',
    objecttype: 'order',
    propertyname: 'OrderId',
    dbfieldname: 'orderid',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Customer Name',
    objecttype: 'order',
    propertyname: 'Customer Name',
    dbfieldname: 'ordercontact',
  },
  {
    fieldtype: 7,
    isrequired: false,
    name: 'Date of Order',
    objecttype: 'order',
    propertyname: 'Date of Order',
    dbfieldname: 'orderdate',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Organization Name',
    objecttype: 'order',
    propertyname: 'Organization Name',
    dbfieldname: 'orderorgname',
  },
  {
    fieldtype: 1,
    isrequired: false,
    name: 'Sales Person',
    objecttype: 'order',
    propertyname: 'Sales Person',
    dbfieldname: 'salesperson',
  },
  {
    fieldtype: 5,
    isrequired: false,
    name: 'Order Status',
    objecttype: 'order',
    propertyname: 'Order Status',
    dbfieldname: 'orderstatus',
  },
  {
    fieldtype: 1,
    isrequired: false,
    name: 'Address',
    objecttype: 'order',
    propertyname: 'Address',
    dbfieldname: 'orderaddress',
  },
  {
    fieldtype: 7,
    isrequired: false,
    name: 'Expected Shipment Date',
    objecttype: 'order',
    propertyname: 'Expected Shipment Date',
    dbfieldname: 'ordershipmentdate',
  },
  {
    fieldtype: 1,
    isrequired: false,
    name: 'Delivery Method',
    objecttype: 'order',
    propertyname: 'Delivery Method',
    dbfieldname: 'orderdelivery',
  },
];

export const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

export const clearCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
};
export const ImportSource = {
  Bixpand: 1,
  SalesNavigator: 2,
};

export const DefaultTicketField = [
  {
    fieldtype: 1,
    isrequired: true,
    name: 'Email',
    dbfieldname: 'primaryemail',
    propertyname: 'Email',
    objecttype: 'ticket',
    isDefault: true,
  },
  {
    fieldtype: 1,
    isrequired: true,
    name: 'First Name',
    dbfieldname: 'firstname',
    propertyname: 'First Name',
    objecttype: 'ticket',
    isDefault: true,
  },
  {
    fieldtype: 1,
    isrequired: true,
    name: 'Last Name',
    dbfieldname: 'lastname',
    propertyname: 'Last Name',
    objecttype: 'ticket',
    isDefault: true,
  },
  {
    fieldtype: 1,
    isrequired: false,
    name: 'Phone Number',
    dbfieldname: 'phonenumber',
    propertyname: 'Phone Number',
    objecttype: 'ticket',
    isDefault: true,
  },
  {
    fieldtype: 1,
    isrequired: true,
    name: 'Subject',
    dbfieldname: 'subject',
    propertyname: 'Subject',
    objecttype: 'ticket',
    isDefault: true,
  },
  {
    fieldtype: 2,
    isrequired: true,
    name: 'Description',
    dbfieldname: 'ticketdescription',
    propertyname: 'Description',
    objecttype: 'ticket',
    isDefault: true,
  },
];

export const LinkedinAccountStaus = {
  1: 'Pending',
  2: 'Connected',
  3: 'Rejected'
}

export const actionTypeSequence = {
  connectionRequest: 1,
  sendMessage: 2,
  viewProfie: 3
};

export const sequenceReportIcon = {
  1: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/connectionrequest.svg" style={{ width: 25 }} />,
  2: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sendmessage.svg" style={{ width: 25 }} />,
  3: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/viewprofile.svg" style={{ width: 25 }} />
};
