import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Button, Grid } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import useStyles from './styles';
import '../../phoneInput.css';
import config from '../../../config';
import State from '../../State/State';
import Country from '../../Country/Country';
import { handleUndefined, postDataRequest } from '../../../common/commonfunctions';
import { editAddress, createAddress } from '../../../context/EntityContext';
import { Entityaddressbyentityid } from '../../../Api Mutation/EntityApi';
import { editAddressUser, createAddressUser } from '../../../context/UserContext';
import {
  zipValidate, composeValidators, maxString, required,
} from '../../../common/FormValidation';

function Address({
  entityid, edit, postAddress, userStatus, userId, handleClick,
}) {
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [buttonStatusAddress, setButtonStatusAddress] = useState('save');
  const [getAddressStatus, setGetAddressStatus] = useState(false);
  const [address, setAddress] = useState([]);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const handleGetAddress = (status) => {
    setGetAddressStatus(status);
    if (handleUndefined(postAddress)) {
      postAddress(status);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (userStatus) {
        if (handleUndefined(userId)) {
          postDataRequest(config.baseURLApi, {
            query: `                      
                      {addressbyuserid(userid:"${userId}"){
                        addressid,street1,street2,state,city,stateid,countryid,zip
                      }}
                      `,
          })
            .then((res) => {
              if (res?.data?.addressbyuserid) {
                setAddress(res?.data?.addressbyuserid);
                setCountryId(res?.data?.addressbyuserid?.countryid);
                setStateId(handleUndefined(res?.data?.addressbyuserid?.state)
                  ? (res?.data?.addressbyuserid?.state)
                  : JSON.parse((res?.data?.addressbyuserid?.stateid)));
              }
            });
        }
      } else if (handleUndefined(entityid)) {
        postDataRequest(config.baseURLApi, {
          query: Entityaddressbyentityid,
          variables: {
            entityid,
          },
        })
          .then((res) => {
            if (res?.data?.entityaddressbyentityid) {
              setAddress(res?.data?.entityaddressbyentityid);
              setCountryId(res?.data?.entityaddressbyentityid?.countryid);
              setStateId(
                handleUndefined(res?.data?.entityaddressbyentityid?.state)
                  ? (res?.data?.entityaddressbyentityid?.state)
                  : JSON.parse((res?.data?.entityaddressbyentityid?.stateid)),
              );
            }
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [entityid, getAddressStatus, userStatus, userId]);
  const countryChange = (selectCountry) => {
    setStateId(0);
    if (selectCountry === 0) {
      setCountryError(true);
      setCountryId(selectCountry);
    } else {
      setCountryError(false);
      setCountryId(selectCountry);
    }
  };
  const StateChange = (selectState) => {
    setStateId(selectState);
    if (selectState === 0 || selectState === '') {
      setStateError(true);
    } else {
      setStateError(false);
    }
  };
  const onSubmitAddress = async (values) => {
    if (countryId === 0 && buttonStatusAddress === 'save') {
      setCountryError(true);
    } else {
      setCountryError(false);
    }
    if ((stateId === 0 || stateId === '') && buttonStatusAddress === 'save') {
      setStateError(true);
    } else {
      setStateError(false);
    }
    if (userStatus === true) {
      if (
        buttonStatusAddress === 'save'
        && edit === 'edit'
        && address.length !== 0
        && countryId !== 0
        && (stateId !== 0 && stateId !== '')
      ) {
        // editAddress for user
        editAddressUser(
          address.addressid,
          values,
          userId,
          countryId,
          stateId,
          handleGetAddress,
        );
      }
      if (
        buttonStatusAddress === 'save'
        && address.length === 0
        && userId
        && !edit
        && countryId !== 0
        && (stateId !== 0 && stateId !== '')
      ) {
        // add user create address
        createAddressUser(
          values,
          countryId,
          stateId,
          userId,
          handleGetAddress,
        );
      }
      if (
        buttonStatusAddress === 'save'
        && edit === 'edit'
        && address.length === 0
        && countryId !== 0
        && (stateId !== 0 && stateId !== '')
      ) {
        createAddressUser(
          values,
          countryId,
          stateId,
          userId,
          handleGetAddress,
        );
      }
    } else {
      if (
        buttonStatusAddress === 'save'
        && edit === 'edit'
        && address?.length !== 0
        && countryId !== 0
        && (stateId !== 0 && stateId !== '')
      ) {
        // edit address for entity
        editAddress(
          address.addressid,
          values,
          entityid,
          countryId,
          stateId,
          handleGetAddress,
        );
      }
      if (
        buttonStatusAddress === 'save'
        && !edit
        && countryId !== 0
        && (stateId !== 0 && stateId !== '')
      ) {
        createAddress(
          values,
          countryId,
          stateId,
          entityid,
          handleGetAddress,
        );
      }
      if (
        buttonStatusAddress === 'save'
        && edit === 'edit'
        && address.length === 0
        && countryId !== 0
        && (stateId !== 0 && stateId !== '')
      ) {
        createAddress(
          values,
          countryId,
          stateId,
          entityid,
          handleGetAddress,
        );
      }
    }
    if (edit && countryId !== 0 && (stateId !== 0 && stateId !== '')) {
      setGetAddressStatus(false);
      if (handleUndefined(postAddress)) {
        postAddress(false);
      }
    } else if (handleUndefined(edit)) {
      setButtonStatusAddress('save');
    }
    if (localStorage.getItem('userid')) {
      postDataRequest(config.baseURLApi, {
        query: `
                    mutation editGettingStarted($name:String!,$userId:ID!){
                        editGettingStarted(name:$name,userid:$userId)
                      }
              `,
        variables: {
          name: 'address',
          userId: JSON.parse(localStorage.getItem('userid')),
        },
      }).then((res) => {
        if (res?.data?.editGettingStarted) {
          window.location = '/app/home/dashboard';
        }
      });
    }
  };
  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmitAddress}
      render={({ handleSubmit }) => (
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          onChange={() => setFormDataChanged(true)}
          noValidate
        >
          {buttonStatusAddress === 'save'
            && formDataChanged === true && (
              <Prompt message="Are you sure you want to leave? Unsaved Data may be lost..." />
          )}
          {address === undefined && (
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <Country
                  countryError={countryError}
                  onCountryChange={countryChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <State
                  stateError={stateError}
                  id={countryId}
                  onStateChange={StateChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  className={classes.field}
                  required
                  name="Address1"
                  variant="outlined"
                  size="small"
                  validate={composeValidators(required, maxString(200))}
                  component={TextField}
                  type="text"
                  label="Address Line 1"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  className={classes.field}
                  name="Address2"
                  component={TextField}
                  variant="outlined"
                  size="small"
                  type="text"
                  label="Address Line 2"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  className={classes.field}
                  required
                  name="City"
                  variant="outlined"
                  size="small"
                  validate={composeValidators(required, maxString(50))}
                  component={TextField}
                  type="text"
                  label="City"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  className={classes.field}
                  required
                  name="Zip"
                  component={TextField}
                  validate={composeValidators(required, zipValidate)}
                  variant="outlined"
                  size="small"
                  type="text"
                  label="ZIP/Postal Code"
                />
              </Grid>
            </Grid>
          )}
          {address !== undefined && (
          <Grid container spacing={2} justifyContent="center">
            <Grid item md={8} xs={12}>
              <Grid container spacing={2}>
                {address.countryid && (
                <Grid item md={6} xs={12}>
                  <Country
                    countryError={countryError}
                    onCountryChange={countryChange}
                    countryid={address.countryid}
                  />
                </Grid>
                )}
                {address?.countryid === undefined && (
                <Grid item md={6} xs={12}>
                  <Country
                    countryError={countryError}
                    onCountryChange={countryChange}
                  />
                </Grid>
                )}
                {(address?.stateid && address?.stateid !== 0) ? (
                  <Grid item md={6} xs={12}>
                    <State
                      stateError={stateError}
                      stateid={address?.stateid}
                      id={countryId || ''}
                      onStateChange={StateChange}
                    />
                  </Grid>
                ) : (
                  <Grid item md={6} xs={12}>
                    <State
                      stateError={stateError}
                      id={countryId}
                      stateName={address?.state}
                      onStateChange={StateChange}
                    />
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <Field
                    className={classes.field}
                    required
                    name="Address1"
                    validate={composeValidators(required, maxString(200))}
                    component={TextField}
                    variant="outlined"
                    size="small"
                    type="text"
                    label="Address Line 1"
                    defaultValue={handleUndefined(
                      address.street1,
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    className={classes.field}
                    name="Address2"
                    component={TextField}
                    variant="outlined"
                    size="small"
                    type="text"
                    label="Address Line 2"
                    defaultValue={handleUndefined(
                      address.street2,
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    className={classes.field}
                    required
                    name="City"
                    component={TextField}
                    validate={composeValidators(required, maxString(50))}
                    variant="outlined"
                    size="small"
                    type="text"
                    label="City"
                    defaultValue={handleUndefined(
                      address.city,
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    className={classes.field}
                    required
                    name="Zip"
                    validate={composeValidators(required, zipValidate)}
                    component={TextField}
                    variant="outlined"
                    size="small"
                    type="text"
                    label="ZIP/Postal Code"
                    defaultValue={handleUndefined(
                      address.zip,
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          )}
          <br />
          <>
            <div className={classes.centerButtonDiv}>
              <Button
                className={classes.buttonBack}
                size="medium"
                variant="contained"
                onClick={() => {
                  handleClick();
                }}
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </Button>
              <Button
                className={classes.buttonSkip}
                size="medium"
                color="primary"
                variant="contained"
                onClick={() => {
                  window.location = '/app/home/dashboard';
                }}
                endIcon={<SkipNextIcon />}
              >
                Skip
              </Button>
              <Button
                className={classes.buttonNext}
                size="medium"
                variant="contained"
                color="primary"
                type="submit"
                endIcon={<NavigateNextIcon />}
              >
                Next
              </Button>
            </div>
            <center>
              <h3 style={{
                paddingLeft: 30, fontSize: 16, fontWeight: 400,
              }}
              >
                All Rights Reserved. You can check
                {' '}
                <a href="https://www.bixpand.com/privacy-policy.html" target="_blank" rel="noreferrer">Privacy</a>
                {' '}
                and Statement
                {' '}
                <a href="https://www.bixpand.com/terms-conditions.html" target="_blank" rel="noreferrer">Terms and Conditions</a>
              </h3>
            </center>
          </>
          <br />
        </form>
      )}
    />
  );
}
export default Address;
