export default {
  loginErrorMessage: 'Your Email or password is incorrect',
  loginSuccessMessage: 'Login Successful',
  SignupSuccessMessage: 'Signup  Successful',
  signupErrorMessage: ' unable to register ',
  planDeleteMessage: 'Plan Inactivated Successfully',
  errorPlanDeleteMessage: 'Plan not Inactivated Successfully',
  entityDataChangeMessage: 'Data updated successfully',
  mangeCustomerDeleted: 'Customer data deleted',
  errorEntityDataChangeMessage: 'Data not updated successfully',
  passwordChangeSuccessMessage: 'Password updated successfully',
  passwordChangeErrorMessage: 'Password not updated successfully',
  imageSizeError: 'Image size should not be larger than 500KB',
  serverError: 'App is Under Maintenence, Please try again later',
  unsavedPromptMessage: 'Are you sure you want to leave? Unsaved Data may be lost...',
  accessError: 'You have no access',
  validCsvRecordMessage: 'Ready to upload',
  errorDuration: 15000,
  successDuration: 8000,
  agencyNotAssigned: 'You don\'t have assigned any Agency',
};
