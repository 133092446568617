import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import {
  Container, Grid, Tooltip,
  Typography, Divider,
  Box,
  Button,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import config from '../../../config';
import BusinessFavicon from '../../../common/BusinessFavicon';
import PositiveLanding from '../Feedbackpages/RatingPositiveLanding';
import { handleUndefined, postDataRequestPublic, handleValidWebsite } from '../../../common/commonfunctions';
import { useEntitiesState } from '../../../context/EntityContext';
import { ReviewStatusApi, ReviewSource } from '../../../common/const';
import Poweredby from '../../../common/PoweredBy';
import {
  useGlobalState,
  setLoginUserInfo,
} from '../../../context/GlobalContext';
import 'react-toastify/dist/ReactToastify.css';
import useStyles from './styles';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import { BrandSettingByLocation, Entity } from '../../../Api Mutation/EntityApi';
import { RatingReviewScocialProfile } from '../../../Api Mutation/FetchReview';

const RatingPositiveComment = ({ history }) => {
  const entityContext = useEntitiesState();
  const globalContext = useGlobalState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [reviewId, setReviewId] = useState('');
  const [newEnabledUrls, setEnabledUrls] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [brandInfo, setBrandInfo] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [rating, setRating] = useState();
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState();
  const [title, setTitle] = useState('');
  const [reviewMsg, setReviewMsg] = useState('');
  const { search } = history.location;
  const params = new URLSearchParams(search);
  const campaignId = params.get('campid');
  const customerId = params.get('custid');
  const locationId = params.get('lid');
  const bid = params.get('bid');
  const emailcode = params.get('ec');
  const r = params.get('r');
  const [entity, setEntity] = useState([]);
  const [business, setBusiness] = useState([]);
  const classes = useStyles();
  const [redirectUrls, setRedirectUrls] = useState([]);
  const [redirectLoader, setRedirectLoader] = useState(false);
  const [social, setSocial] = useState([]);
  const [viewBixReviewPart, setViewBixReviewPart] = useState(social?.length === 0);

  const redirectOptions = [
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/google.svg', name: 'Google', fieldName: 'newreviewurl', sourceId: ReviewSource.Google, enableName: 'newreviewurl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/google.svg', name: 'Google', fieldName: 'googleurl', sourceId: ReviewSource.Google, enableName: 'isenabledgoogle',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/yelp-icon.svg', name: 'Yelp', fieldName: 'yelprl', sourceId: ReviewSource.Yelp, enableName: 'isenabledyelprl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/Facebook.svg', name: 'Facebook', fieldName: 'facebookurl', sourceId: ReviewSource.Facebook, enableName: 'isenabledfacebookurl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/tripadvisor-icon.svg', name: 'Tripadvisor', fieldName: 'tripadvisorurl', sourceId: ReviewSource.Tripadvisor, enableName: 'isenabledtripadvisorurl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/opentable-icon.svg', name: 'Opentable', fieldName: 'opentableurl', sourceId: ReviewSource.Opentable, enableName: 'isenabledopentableurl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/angieslist-icon.svg', name: 'Angieslist', fieldName: 'angielisturl', sourceId: ReviewSource.Angielist, enableName: 'isenabledangielisturl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/realtor.png', name: 'Realtor', fieldName: 'realtorurl', sourceId: ReviewSource.Realtor, enableName: 'isenabledrealtorurl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/Trustpilot.svg', name: 'Trustpilot', fieldName: 'trustpiloturl', sourceId: ReviewSource.Trustpilot, enableName: 'isenabledtrustpiloturl',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/G2%20CRO.svg', name: 'G2', fieldName: 'g2', sourceId: ReviewSource.G2, enableName: 'isenabledg2',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/capterra.svg', name: 'Capterra', fieldName: 'capterra', sourceId: ReviewSource.Capterra, enableName: 'isenabledcapterra',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/clutchsvg.png', name: 'Clutch', fieldName: 'clutch', sourceId: ReviewSource.Clutch, enableName: 'isenabledclutch',
    },
    {
      logo: 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/apartment.svg', name: 'Apartments', fieldName: 'apartments', sourceId: ReviewSource.Apartments, enableName: 'isenabledapartments',
    },
  ];
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (handleUndefined(bid)) {
        postDataRequestPublic(config.baseURLApi, {
          query: `query entity($entityid:ID!){entity(entityid:$entityid){entityid,businessname,brandname
            firstname,lastname,email,phonenumber,entitytypeid,businesstypeid,jobid,tags,website,shortdesc,fulldesc,policy,terms
            }}`,

          variables: {
            entityid: bid && bid,
          },
        }).then((res) => {
          if (res?.data?.entity) {
            setBusiness(res.data.entity);
          }
        });
      }
      if (handleUndefined(locationId)) {
        postDataRequestPublic(config.baseURLApi, {
          query: Entity,

          variables: {
            entityid: locationId,
          },
        }).then((res) => {
          if (res?.data?.entity) {
            setEntity(res.data.entity);
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    entityContext?.entities?.businessData
    && entityContext?.entities?.businessData[0]?.entityid,
  ]);
  const createReviewRedirect = (reviewUrl, sourceId) => {
    setSubmitLoading(true);
    postDataRequestPublic(config.reviewApi, {
      query: `
      mutation{
        createReview(
          review: "${reviewMsg !== undefined ? reviewMsg?.split('\n').join('\\n').split('"').join('\\"') : ''
}",
          rating: ${rating !== undefined ? rating : parseInt(r, 10)},
          title: "${title?.split('\n').join('\\n').split('"').join('\\"') || ''}",
          providedby: "Bixpand",
          locationid: "${locationId}",
          customerid: "${customerId}",
          emailcode: "${emailcode}"
          campaignid: ${handleUndefined(campaignId) ? JSON.stringify(campaignId) : null
},
          createdby: "${customerId}",
          sourceid: ${sourceId || 1},
          status: ${ReviewStatusApi.RatingPositiveComment},
          reviewdatetime: "${new Date()}"
          ) {
            reviewid,
          }
        }`,
    })
      .then(() => {
        window.open(reviewUrl, '_blank');
        history.push(
          `/rating-thank-you-p?lid=${locationId}&bid=${bid}&rating=${rating}&brand=${business?.brandname
          || business?.businessname}`,
        );
        setReviewMsg('');
        setTitle('');
        setSubmitLoading(false);
      })
      .catch(() => {
        toast.error('Something went wrong. Your feedback can\'t be save');
        setSubmitLoading(false);
      });
  };
  const createReview = () => {
    setSubmitLoading(true);
    postDataRequestPublic(config.reviewApi, {
      query: `
      mutation{
        createReview(
          review: "${reviewMsg !== undefined ? reviewMsg.split('\n').join('\\n').split('"').join('\\"') : ''
}",
          rating: ${rating !== undefined ? rating : parseInt(r, 10)},
          title: "${title.split('\n').join('\\n').split('"').join('\\"') || ''}",
          providedby: "Bixpand",
          locationid: "${locationId}",
          customerid: "${customerId}",
          emailcode: "${emailcode}"
          campaignid: ${handleUndefined(campaignId) ? JSON.stringify(campaignId) : null
},
          createdby: "${customerId}",
          sourceid: 1,
          status: ${ReviewStatusApi.RatingPositiveComment},
          reviewdatetime: "${new Date()}"
          ) {
            reviewid,
          }
        }`,
    })
      .then(() => {
        history.push(
          `/rating-thank-you-p?lid=${locationId}&bid=${bid}&rating=${rating}&brand=${business?.brandname
          || business?.businessname}`,
        );
        setReviewMsg('');
        setTitle('');
        setSubmitLoading(false);
      })
      .catch(() => {
        toast.error('Something went wrong. Your feedback can\'t be save');
        setSubmitLoading(false);
      });
  };
  const updateReviewDirect = async (updateTitle, updateText, updateRating, sourceId, url) => {
    setSubmitLoading(true);
    postDataRequestPublic(config.reviewApi, {
      query: `
      mutation{
        editReview(
          reviewid: "${reviewId}",
          review: "${updateText !== undefined ? updateText?.split('\n').join('\\n').split('"').join('\\"') : ''
}",
          rating: ${updateRating},
          title: "${updateTitle?.split('\n').join('\\n').split('"').join('\\"') || ''}",
          customerid: "${customerId}",
          emailcode: "${emailcode}"
          campaignid: ${campaignId === 'undefined' ? null : JSON.stringify(campaignId)},
          updatedby: "${customerId}",
          status:${ReviewStatusApi.RatingPositiveComment},
          reviewdatetime: "${new Date()}",
          sourceid: ${sourceId}
          )
        }`,
    })
      .then(() => {
        if (sourceId) {
          window.open(url, '_blank');
          history.push(
            `/thank-you-n?lid=${locationId}&bid=${bid}&rating=${updateRating}&brand=${business?.brandname
            || business?.businessname}`,
          );
        } else {
          history.push(
            `/thank-you-n?lid=${locationId}&bid=${bid}&rating=${updateRating}&brand=${business?.brandname
            || business?.businessname}`,
          );
        }
        setReviewMsg('');
        setTitle('');
        setSubmitLoading(false);
      })
      .catch(() => {
        toast.error('Something went wrong. Your feedback can\'t be save');
        setSubmitLoading(false);
      });
  };
  const updateReview = async () => {
    setSubmitLoading(true);
    postDataRequestPublic(config.reviewApi, {
      query: `
      mutation{
        editReview(
          reviewid: "${reviewId}",
          review: "${reviewMsg !== undefined ? reviewMsg.split('\n').join('\\n').split('"').join('\\"') : ''
}",
          rating: ${rating !== undefined ? rating : parseInt(r, 10)},
          title: "${title.split('\n').join('\\n').split('"').join('\\"') || ''}",
          customerid: "${customerId}",
          emailcode: "${emailcode}"
          campaignid: ${campaignId === 'undefined' ? null : JSON.stringify(campaignId)},
          updatedby: "${customerId}",
          status: ${ReviewStatusApi.RatingPositiveComment},
          reviewdatetime: "${new Date()}"
          )
        }`,
    })
      .then(() => {
        history.push(
          `/rating-thank-you-n?lid=${locationId}&bid=${bid}&rating=${rating}&brand=${business?.brandname
          || business?.businessname}`,
        );
        setReviewMsg('');
        setTitle('');
        setSubmitLoading(false);
      })
      .catch(() => {
        toast.error('Something went wrong. Your feedback can\'t be save');
        setSubmitLoading(false);
      });
  };
  const handleSubmit = () => {
    if (title && reviewMsg) {
      if (
        (!campaignId || campaignId === 'null')
        && (!customerId || customerId === 'null')
      ) {
        history.push('/rating-thank-you-p?lid=&brand=&test=true');
      } else if (reviewId) {
        updateReview();
      } else {
        createReview();
      }
    } else {
      setError(true);
    }
  };

  const getBrandInfo = async () => {
    if (handleUndefined(bid)) {
      await postDataRequestPublic(config.baseURLApi, {
        query: BrandSettingByLocation,
        variables: {
          locationid: handleUndefined(bid),
        },
      })
        .then((res) => {
          setBrandInfo(res?.data?.brandSettingByLocation);
        })
        .catch(() => { });
    }
  };
  const getUserInfo = async () => {
    postDataRequestPublic(config.baseURLApi, {
      query: `{contact(contactid:${handleUndefined(customerId) ? JSON.stringify(customerId) : null}){ firstname ,lastname }}`,
    })
      .then((res) => {
        setUserName(`${handleUndefined(res?.data?.contact?.firstname)} ${handleUndefined(res?.data?.contact?.lastname)}`);
      })
      .catch(() => { });
  };
  const checkReviewExist = async () => {
    await postDataRequestPublic(config.reviewApi, {
      query: `{checkReviewExist(customerid:"${customerId}", locationid: "${locationId}", emailcode: "${emailcode}") {
          reviewid,
          review,
          title,
          emailcode,
          rating,
          status
        }}`,
    })
      .then(async (res) => {
        if (res?.data?.checkReviewExist) {
          setReviewMsg(res.data.checkReviewExist.review);
          setTitle(res.data.checkReviewExist.title);
          setReviewId(res.data.checkReviewExist.reviewid);
        }
        await postDataRequestPublic(config.reviewApi, {
          query: RatingReviewScocialProfile,
          variables: {
            locationid: locationId,
          },
        }).then(async (url) => {
          setSocial(url?.data?.socialprofilesbylocation);
          if (url?.data?.socialprofilesbylocation?.length > 0) {
            setRedirectUrls(url?.data?.socialprofilesbylocation[0]);
            const urls = url?.data?.socialprofilesbylocation[0];
            if (!res?.data?.checkReviewExist?.reviewid
              && redirectOptions?.filter((data) => urls[data?.fieldName])?.length === 1) {
              createReviewRedirect(urls[redirectOptions?.filter(
                (data) => urls[data?.fieldName],
              )[0].fieldName], 1);
            }
          }
        });
      })
      .catch(() => { });
  };
  const fetchInfoOnMount = async () => {
    await checkReviewExist();
    await getUserInfo();
    await getBrandInfo();
    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (bid && bid !== 'undefined') {
        setLoading(true);
        setRating(parseInt(r, 10));
        fetchInfoOnMount();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [bid, campaignId, customerId, locationId, r]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoginUserInfo(globalContext.setGlobalState);
    }
    return () => {
      isMounted = false;
    };
  }, [globalContext.setGlobalState]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const enabledKeys = [
        'angielisturl',
        'apartments',
        'capterra',
        'clutch',
        'facebookurl',
        'g2',
        'opentableurl',
        'realtorurl',
        'tripadvisorurl',
        'trustpiloturl',
        'yelprl',
        'googleurl',
      ];

      const enabledUrls = enabledKeys
        .filter((key) => redirectUrls[`isenabled${handleUndefined(key) === 'googleurl' ? 'google' : key}`] && redirectUrls[key])
        .map((key) => redirectUrls[key]);
      setEnabledUrls(enabledUrls);

      if (enabledUrls.length === 1) {
      // eslint-disable-next-line prefer-destructuring
        window.location.href = enabledUrls[0];
        setRedirectLoader(true);
      } else {
        setRedirectLoader(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [redirectUrls]);

  return (
    <>
      {redirectLoader ? (
        <div className={classes.redirectLoader}>
          <LoadingPage />
        </div>
      ) : (
        <>
          <BusinessFavicon
            logo={favIcon || locationBrandImage || brandInfo?.logourl}
            name={entity.businessname}
          />
          <ToastContainer />
          <br />
          <Typography align="center" color="primary" variant="h1">
            Start your review now
          </Typography>
          <br />
          <Divider variant="middle" />
          <Container maxWidth="sm">
            <Grid className={classes.gridWidth} justifyContent="center" container>
              {redirectOptions && redirectOptions?.map((map) => (
                <>
                  {redirectUrls[map?.fieldName] && redirectUrls[map?.enableName] ? (
                    <Grid className={classes.padding} align="center" item xs={12}>
                      <>
                        <Tooltip
                          title={map.name}
                          color="primary"
                          interactive
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              if (reviewId) {
                                updateReviewDirect(title, reviewMsg, rating !== undefined ? rating : parseInt(r, 10), map.sourceId, handleValidWebsite(redirectUrls[map?.fieldName]) ? redirectUrls[map?.fieldName] : `https://${redirectUrls[map?.fieldName]}`);
                              }
                              createReviewRedirect(handleValidWebsite(redirectUrls[map?.fieldName]) ? redirectUrls[map?.fieldName] : `https://${redirectUrls[map?.fieldName]}`, map.sourceId);
                            }}
                            endIcon={(
                              <img
                                src={map?.logo}
                                alt="google"
                                className={
                                  classes.googleIcon
                                }
                              />
                            )}
                          >
                            Review on
                          </Button>
                        </Tooltip>
                      </>
                    </Grid>
                  ) : ''}
                </>
              ))}
              {newEnabledUrls.length > 1
                ? '' : (
                  <Grid className={classes.padding} align="center" item xs={6}>
                    <>
                      <Tooltip
                        title="App"
                        color="primary"
                        interactive
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          className={viewBixReviewPart ? classes.loggedInCard : classes.card}
                          onClick={() => {
                            setViewBixReviewPart(!viewBixReviewPart);
                            setTimeout(() => {
                              if (!viewBixReviewPart === true) {
                                window.scrollTo({
                                  top: document.documentElement.scrollHeight,
                                  behavior: 'smooth',
                                });
                              }
                            }, [1000]);
                          }}
                          endIcon={(
                            <img
                              src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png"
                              alt="google"
                              className={
                                classes.googleIcon
                              }
                            />
                          )}
                        >
                          Review on
                        </Button>
                      </Tooltip>
                    </>
                  </Grid>
                )}
            </Grid>
          </Container>
          <Divider variant="middle" />
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              height="100vh"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (newEnabledUrls.length > 1 ? ''
            : (
              <>
                {(!campaignId || campaignId === 'null') && (!customerId || customerId === 'null') && (
                  <Chip
                    label="This is a test review form. Your review won’t be published on App."
                    color="primary"
                    style={{
                      width: '100%',
                      fontSize: '1rem',
                      marginBottom: '1rem',
                    }}
                  />
                )}
                {viewBixReviewPart && (
                  <>
                    <Container>
                      <br />
                      <PositiveLanding
                        activeRating={rating}
                        reviewMsg={reviewMsg}
                        userName={userName}
                        error={error}
                        handleRating={setRating}
                        handleReviewMsg={(e) => {
                          setReviewMsg(e.target.value);
                        }}
                        title={title}
                        handletitle={(e) => setTitle(e.target.value)}
                        brandInfo={{
                          logo: brandInfo?.logourl,
                          brandname: business?.brandname,
                          businessname: business?.businessname,
                          locationname: entity.businessname,
                          displaylocationdoncampaign: entity.displaylocationdoncampaign,
                        }}
                      />
                    </Container>
                    <Container>
                      {' '}
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        marginTop={2}
                      >
                        <CopyToClipboard text={reviewMsg}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={submitLoading}
                            startIcon={
                              submitLoading ? (
                                <CircularProgress size={20} />
                              ) : (
                                <CheckCircleOutline />
                              )
                            }
                          >
                            Post Review
                          </Button>
                        </CopyToClipboard>
                        {' '}
                      </Box>
                    </Container>
                  </>
                )}
              </>
            )
          )}
          <center>
            <Poweredby id={bid} />
          </center>
        </>
      )}
    </>
  );
};

export default withRouter(RatingPositiveComment);
