import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import useStyles from './style';
import config from '../../config';
import { postDataRequest } from '../../common/commonfunctions';

function SelectBusinessType({ onBusinessSelect, BusinessName, status }) {
  const classes = useStyles();
  const [businessSelect, setBusinessSelect] = useState(
    BusinessName === undefined ? 0 : BusinessName,
  );
  const [businessNameData, setBusinessNameData] = useState();
  const BusinessTypeSet = (event) => {
    setBusinessSelect(event.target.value);
    onBusinessSelect(event.target.value);
  };
  useEffect(() => {
    let isMounted = true;
    postDataRequest(config.baseURLApi, {
      query: '{lookupdatabyname(name:"business name"){lookupid,name,key,value,parentvalue,deleted,createdby,updatedby}}',
    })
      .then((res) => {
        if (isMounted) {
          setBusinessNameData(res.data.lookupdatabyname);
          onBusinessSelect(BusinessName);
          setBusinessSelect(BusinessName !== undefined ? BusinessName : 0);
        }
      })
      .catch(() => { });

    return () => {
      isMounted = false;
    };
  }, [BusinessName]);
  return (
    <FormControl className={classes.form}>
      <Select
        required
        value={businessSelect === null ? 0 : businessSelect}
        onChange={BusinessTypeSet}
        className={classes.selectEmpty}
        variant="outlined"
        size="small"
        margin="dense"
        disabled={status}
      >
        <MenuItem value={0}>Select Business Type</MenuItem>
        {businessNameData
                    && businessNameData.map((val) => (
                      <MenuItem value={val.lookupid} key={val.lookupid}>
                        {val.key}
                      </MenuItem>
                    ))}
      </Select>
    </FormControl>
  );
}
export default SelectBusinessType;
