import { createTheme } from '@material-ui/core';
import defaultTheme from './default';
import successTheme from './success';
import secondaryTheme from './secondary';
import darkTheme from './dark';
import { fontFamily } from '../common/const';

const overrides = {
  typography: {
    h1: {
      fontSize: '3rem',
      fontFamily,
    },
    h2: {
      fontSize: '2rem',
      fontFamily,
    },
    h3: {
      fontSize: '1.64rem',
      fontFamily,
    },
    h4: {
      fontSize: '1.5rem',
      fontFamily,
    },
    h5: {
      fontSize: '1.285rem',
      fontFamily,
    },
    h6: {
      fontSize: '1.25rem',
      fontFamily,
      fontWeight: 600,
    },
  },
  overrides: {
    palette: {
      primary: {
        main: '#464775',
      },
      secondary: {
        main: '#464775',
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: '#fff',
      },
      checkboxListTitle: {
        fontSize: 14,
        fontFamily,
        color: '#4A4A4A',
        fontWeight: 600,
      },
      checkboxFormControlLabel: {
        fontSize: 12,
        fontFamily,
      },
      title: {
        fontSize: 14,
        fontFamily,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '12px',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '20px',
        fontFamily,
      },
    },
    MuiCardHeader: {
      action: {
        alignSelf: 'auto',
      },
    },
    MuiButton: {
      root: {
        fontFamily,
        textTransform: 'capitalize',
      },
      containedSizeSmall: {
        fontSize: 12,
      },
      containedPrimary: {
        backgroundColor: '#005EB2 !important',
      },
      outlinedSizeSmall: {
        fontSize: 12,
        fontFamily,
      },
    },
    MUIDataTableToolbar: {
      filterPaper: {
        maxWidth: '80%',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontFamily,
        display: '-webkit-inline-box',
        fontSize: 14,
        fontWeight: 600,
        '&:hover': {
          opacity: 1,
        },
        '&$selected': {
          fontWeight: 600,
          color: '#000',
          zIndex: 2,
        },
      },
      wrapper: {
        // display: '-webkit-inline-box',
      },
      selected: {},
    },
    MuiChip: {
      labelSmall: {
        fontSize: 12,
        fontFamily,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontFamily,
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: 'center',
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid',
        borderBottomColor: '#005EB2',
      },
      indicator: {
        height: '100%',
        background:
                    'linear-gradient(to top, #005EB2 0%,#005EB2 10%,#d5ecf0 10%,#d5ecf0 100%)',
        zIndex: 1,
      },
    },
    MuiFab: {
      primary: {
        backgroundColor: '#005EB2 !important',
      },
      sizeSmall: {
        width: 35,
        height: 35,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontSize: 12,
        fontFamily,
      },
    },
    MuiTypography: {
      h6: {
        fontFamily,
      },
      root: {
        fontFamily,
        fontSize: 14,
        fontWeight: 400,
      },
      h1: {
        fontSize: 20,
        fontFamily,
        fontWeight: 600,
      },
      body1: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily,
      },
      body2: {
        fontSize: 12,
        fontFamily,
      },
      subtitle2: {
        fontSize: 13,
        fontWeight: 800,
        fontFamily,
      },
      subtitle1: {
        fontSize: 12,
        fontFamily,
        fontWeight: 600,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        fontSize: 12,
        fontFamily,
        fontWeight: 600,
      },
    },
    MuiDrawer: {
      paperAnchorLeft: { width: '25%' },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#f6f7ff !important',
      },
      fixedHeaderCommon: {
        backgroundColor: 'unset',
      },
      fixedLeft: {
        position: 'unset',
      },
    },
    MUIDataTableHeadCell: {
      data: {
        fontFamily,
        fontWeight: 'normal',
        color: 'black',
        fontSize: 14,
      },
      fixedHeader: {
        backgroundColor: '#f6f7ff',
        position: 'unset',
      },
    },
    MuiCard: {
      root: {
        boxShadow:
                    '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: '68vh !important',
      },
      paper: {
        boxShadow:
                    '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '12px',
        fontFamily,
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
                    '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiSelect: {
      outlined: {
        fontSize: 12,
        fontFamily,
        padding: 10,
      },
      icon: {
        color: `${localStorage.getItem('theme') === 'dark' ? '#ffff' : '#4A4A4A'} !important`,
      },
    },
    MuiMenuItem: {
      root: {
        padding: '0.5rem',
        fontSize: 14,
        fontFamily,
      },
    },
    MuiListItemIcon: {
      minWidth: 45,
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
      },
      root: {
        '&$selected': {
          backgroundColor: '#F3F5FF !important',
          '&:focus': {
            backgroundColor: '#F3F5FF',
          },
        },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#F3F5FF',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: '#005eb280',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: '#D6ECFF',
          '&$hover:hover':
                    {
                      backgroundColor: '#005eb280',
                    },
        },
        '&$hover:hover':
                {
                  backgroundColor: '#DCDCDC',
                },
        height: 46,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
        padding: '14px 40px 14px 24px',
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
      },
      paddingCheckbox: {
        padding: '0 0 0 15px',
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          content: '""',
          flex: 0,
          padding: '0px',
        },
      },
    },
    MuiCardContent: {
      root: {
        paddingBottom: 0,
      },
    },
  },
};

const darkModeOverrides = {
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#23232D !important',
        boxShadow:
                    '0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important',
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: '#23232D !important',
      },
    },
    MuiGrid: {
      root: {
        backgroundColor: 'transparent !important',
      },
    },
    typography: {
      root: {
        color: 'white !important',
      },
      h1: {
        fontSize: '20px',
        fontFamily,
        backgroundColor: 'transparent !important',
      },
      h2: {
        fontSize: '2rem',
        fontFamily,
      },
      h3: {
        fontSize: '1.64rem',
        fontFamily,
      },
      h4: {
        fontSize: '1.5rem',
        fontFamily,
      },
      h5: {
        fontSize: '1.285rem',
        fontFamily,
      },
      h6: {
        fontSize: '1.25rem',
        fontFamily,
        fontWeight: 600,
      },
    },
    palette: {
      primary: {
        main: '#464775',
      },
      secondary: {
        main: '#464775',
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: '#fff',
      },
      checkboxListTitle: {
        fontSize: 14,
        fontFamily,
        color: '#4A4A4A',
        fontWeight: 600,
      },
      checkboxFormControlLabel: {
        fontSize: 12,
        fontFamily,
      },
      title: {
        fontSize: 14,
        fontFamily,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '12px',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '20px',
        fontFamily,
      },
    },
    MuiCardHeader: {
      action: {
        alignSelf: 'auto',
      },
    },
    MuiButton: {
      root: {
        fontFamily,
        textTransform: 'capitalize',
        boxShadow: 'none !important',
      },
      containedSizeSmall: {
        fontSize: 12,
      },
      containedPrimary: {
        backgroundColor: '#005EB2 !important',
      },
      outlinedSizeSmall: {
        fontSize: 12,
        fontFamily,
      },
    },
    MUIDataTableToolbar: {
      filterPaper: {
        maxWidth: '80%',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontFamily,
        display: '-webkit-inline-box',
        fontSize: 14,
        fontWeight: 600,
        '&:hover': {
          opacity: 1,
        },
        '&$selected': {
          fontWeight: 600,
          color: '#000',
          zIndex: 2,
        },
      },
      selected: {},
    },
    MuiChip: {
      labelSmall: {
        fontSize: 12,
        fontFamily,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontFamily,
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: 'center',
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid',
        borderBottomColor: '#005EB2',
      },
      indicator: {
        height: '100%',
        background:
                      'linear-gradient(to top, #005EB2 0%,#005EB2 10%,#d5ecf0 10%,#d5ecf0 100%)',
        zIndex: 1,
      },
    },
    MuiFab: {
      primary: {
        backgroundColor: '#005EB2 !important',
      },
      sizeSmall: {
        width: 35,
        height: 35,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontSize: 12,
        fontFamily,
      },
    },
    MuiTypography: {
      h6: {
        fontFamily,
      },
      root: {
        fontFamily,
        fontSize: 14,
        fontWeight: 400,
      },
      h1: {
        fontSize: 20,
        fontFamily,
        fontWeight: 600,
      },
      body1: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily,
      },
      body2: {
        fontSize: 12,
        fontFamily,
      },
      subtitle2: {
        fontSize: 13,
        fontWeight: 800,
        fontFamily,
      },
      subtitle1: {
        fontSize: 12,
        fontFamily,
        fontWeight: 600,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        fontSize: 12,
        fontFamily,
        fontWeight: 600,
      },
    },
    MuiDrawer: {
      paperAnchorLeft: { width: '25%' },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#f6f7ff !important',
      },
      fixedHeaderCommon: {
        backgroundColor: 'unset',
      },
      fixedLeft: {
        position: 'unset',
      },
    },
    MUIDataTableHeadCell: {
      data: {
        fontFamily,
        fontWeight: 'normal',
        color: 'black',
        fontSize: 14,
      },
      fixedHeader: {
        backgroundColor: '#f6f7ff',
        position: 'unset',
      },
    },
    MuiCard: {
      root: {
        boxShadow:
                      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: '68vh !important',
      },
      paper: {
        boxShadow:
                      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '12px',
        fontFamily,
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
                      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiSelect: {
      outlined: {
        fontSize: 12,
        fontFamily,
        padding: 10,
      },
      icon: {
        color: '#B9B9B9',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '0.5rem',
        fontSize: 14,
        fontFamily,
      },
    },
    MuiListItemIcon: {
      minWidth: 45,
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
      },
      root: {
        '&$selected': {
          backgroundColor: '#4a658c !important',
          '&:focus': {
            backgroundColor: '#4a658c',
          },
        },
      },
      button: {
        '&:hover, &:focus': {
          backgroundColor: '#F3F5FF',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: '#005eb280',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: '#D6ECFF',
          '&$hover:hover':
                      {
                        backgroundColor: '#005eb280',
                      },
        },
        '&$hover:hover':
                  {
                    backgroundColor: '#DCDCDC',
                  },
        height: 46,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
        padding: '14px 40px 14px 24px',
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
      },
      paddingCheckbox: {
        padding: '0 0 0 15px',
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          content: '""',
          flex: 0,
          padding: '0px',
        },
      },
    },
    MuiCardContent: {
      root: {
        paddingBottom: 0,
      },
    },
  },
};

export default {
  default: createTheme({ ...defaultTheme, ...overrides }),
  secondary: createTheme({ ...secondaryTheme, ...overrides }),
  success: createTheme({ ...successTheme, ...overrides }),
  dark: createTheme({ ...darkTheme, ...darkModeOverrides }),
};
