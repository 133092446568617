/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Paper, Grid, Typography, Box, TextField, Button, Divider,
} from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useParams } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VideocamIcon from '@material-ui/icons/Videocam';
import PublicIcon from '@material-ui/icons/Public';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import BusinessFavicon from '../../../../common/BusinessFavicon';
import { postDataRequestPublic, handleUndefined } from '../../../../common/commonfunctions';
import config from '../../../../config';
import useStyle from '../style';
import { maxString } from '../../../../common/FormValidation';
import { LoadingPage } from '../../../LoadingPage/LoadingPage';
import BixpoweredBy from '../../../../common/PoweredBy';
import { showNotification } from '../../../../components/Notification/ToastifyNotifications';
import { BrandSettingByLocation, Entity } from '../../../../Api Mutation/EntityApi';

export default function CancleAppintmentPublic() {
  const classes = useStyle();
  const {
    event, eventId, bid, bookid,
  } = useParams([]);
  const [favicon, setFavicon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [dateTime, setDateTime] = useState();
  const [eventData, setEventData] = useState();
  const [bookingDate, setBookingDate] = useState();
  const [meetingDuration, setMeetingDuration] = useState();
  const [location, setLocation] = useState();
  const [timeZone, setTimeZone] = useState();
  const [brandImage, setBrandImage] = useState();
  const [contactId, setContactId] = useState();
  const [cancenllationReason, setCancenllationReason] = useState();
  const [visible, setVisible] = useState(false);
  const locationId = handleUndefined(JSON.parse(localStorage.getItem('location_Id')));

  const eventBookByData = () => {
    if (bookid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: `
           query eventbookingdata($bookingid: ID!){
            eventbookingdata(bookingid:$bookingid){
              bookingid,bookingdate,contactid,guestemail,starttime,endtime,meetingduration,meetingprepare,eventid,createdby,updatedby,
              deleted,issentreminder,locationid,timezone,createdAt,updatedAt,cancellationreason,canceledby,changereason,status,
              createdUser{userid,firstname,lastname,phonenumber,email,}
              event{eventid,eventtype,name,location,description,scheduleday,scheduletype,eventstartdate,eventenddate,eventduration,isemailreminders}
              contact{contactid,primaryemail,firstname,lastname}
            }}`,
        variables: {
          bookingid: bookid,
        },
      }).then((res) => {
        setDataLoading(false);
        setEventData(res?.data?.eventbookingdata);
        setBookingDate(res?.data?.eventbookingdata?.bookingdate);
        setMeetingDuration(res?.data?.eventbookingdata?.meetingduration);
        setContactId(res?.data?.eventbookingdata?.contact?.contactid);
        setLocation(res?.data?.eventbookingdata?.event?.location);
        setTimeZone(res?.data?.eventbookingdata?.timezone);
        setDateTime(res?.data?.eventbookingdata);
        setDataLoading(false);
      });
    }
  };

  const CancelBookingData = () => {
    if (bookid) {
      setDataLoading(true);
      const CANCEL_BOOKING_MUTATION = `
            mutation
            cancelbooking(
              $bookingid: ID!
              $cancellationreason: String
              $canceledby: ID!
            ){
              cancelbooking(
                bookingid:$bookingid
                cancellationreason:$cancellationreason
                canceledby:$canceledby
                )
            }`;
      postDataRequestPublic(config.baseURLApi, {
        query: CANCEL_BOOKING_MUTATION,
        variables: {
          bookingid: bookid,
          cancellationreason: cancenllationReason,
          canceledby: contactId,
        },
      }).then((res) => {
        if (res?.data?.cancelbooking) {
          setDataLoading(false);
          showNotification('Appointment Cancel Successfully', 'success');
          setVisible(true);
          setDataLoading(false);
        }
      });
    }
  };

  const getEventData = () => {
    setDataLoading(true);
    if (handleUndefined(eventId)) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
           query eventdata($eventid: ID!){
            eventdata(eventid:$eventid){
              eventid,eventtype,name,location,description,eventlink,eventcolor,eventdaterangtype,scheduleday,scheduletype,status,
              eventstartdate,eventenddate,eventduration,beforeeventbuffer,aftereventbuffer,isemailreminders,isemailfollowup,
              createdby,updatedby,deleted,locationid,createdAt,updatedAt,createdUser{ userid,firstname,lastname,phonenumber,email}
            }}`,
        variables: {
          eventid: handleUndefined(eventId),
        },
      }).then((res) => {
        setDataLoading(false);
        setEventData(res?.data?.eventdata);
        setDataLoading(false);
      });
    }
    if (bid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: bid },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setFavicon(res?.data?.entity?.favicon);
            setLocationBrandImage(res?.data?.entity?.logourl);
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setDataLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (bookid) {
        eventBookByData();
      }
      getEventData();
    }
    return () => {
      isMounted = false;
    };
  }, [eventId, bid, bookid]);

  return (
    <>
      {dataLoading ? <LoadingPage /> : <></>}
      <BusinessFavicon
        logo={favicon || locationBrandImage || brandImage}
        name={event}
      />
      <div className={classes.root}>
        <div className={classes.paper}>
          {visible === false
            ? (
              <Paper className={classes.cal} elevation={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={5}>
                    {brandImage ? (
                      <>
                        <img
                          className={classes.brandImage}
                          src={brandImage}
                          alt="brand logo"
                        />
                      </>
                    ) : ''}
                    <Typography className={classes.heading} variant="h6">
                      {eventData?.createdUser?.firstname}
                      {' '}
                      {eventData?.createdUser?.lastname}
                    </Typography>
                    <br />
                    <Typography className={classes.heading} variant="h1">{event}</Typography>
                    <br />
                    <Typography className={classes.heading} variant="body1">
                      <Box display="flex" alignItems="center">
                        <AccessTimeIcon />
                                                &nbsp;
                        {meetingDuration}
                        &nbsp;
                        Min
                      </Box>
                    </Typography>
                    <br />
                    <Typography className={classes.heading} variant="body1">
                      <Box display="flex">
                        <VideocamIcon />
                                                &nbsp;
                        <div className={classes.headingLocation}>
                          {location}
                        </div>
                      </Box>
                    </Typography>
                    <br />
                    <Typography className={classes.heading} variant="body1">
                      <Box display="flex" alignItems="center">
                        <CalendarTodayIcon />
                        &nbsp;
                        {moment(parseInt(dateTime?.starttime, 10)).format(config.timeformate)}
                        &nbsp;
                        -
                        &nbsp;
                        {moment(parseInt(dateTime?.endtime, 10)).format(config.timeformate)}
                        ,
                        &nbsp;
                        {moment(bookingDate).format('MMM Do YYYY')}
                                                &nbsp;
                      </Box>
                    </Typography>
                    <br />
                    <Typography className={classes.heading} variant="body1">
                      <Box display="flex" alignItems="center">
                        <PublicIcon />
                        &nbsp;
                        {timeZone}
                      </Box>
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={12} md={6}>
                    <Box className={classes.details}>
                      <Typography variant="h1">Cancel Event?</Typography>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={10}>
                          <Typography variant="body1">Reason for canceling</Typography>
                          <br />
                          <TextField
                            className={classes.field}
                            fullWidth
                            multiline
                            rows={6}
                            size="small"
                            value={cancenllationReason}
                            onChange={(e) => setCancenllationReason(e.target.value)}
                            validate={maxString(35)}
                            variant="outlined"
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            className={classes.button}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              CancelBookingData();
                            }}
                          >
                            Cancel Event
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <BixpoweredBy id={locationId} />
              </Paper>
            )
            : (
              <Paper className={classes.cal} elevation={6}>
                <center>
                  <Grid container spacing={2} justifyContent="center" alignContent="center">
                    <Grid item xs={12}>
                      <Typography variant="h1">Cancellation Confirmed</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Your meeting with
                        {' '}
                        {eventData?.createdUser?.firstname}
                        {' '}
                        {eventData?.createdUser?.lastname}
                        {' '}
                        has been canceled.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h1">{event}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <Box display="inline-flex" alignItems="center">
                          <CalendarTodayIcon />
                                                    &nbsp;
                          <Typography className={classes.line} variant="body2">
                            {moment(bookingDate).format('MMM Do YYYY')}
                            ,
                                                        &nbsp;
                            {moment(parseInt(dateTime?.starttime, 10)).format(config.timeformate)}
                            -
                            {moment(parseInt(dateTime?.endtime, 10)).format(config.timeformate)}
                          </Typography>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography style={{ textDecoration: 'line-through' }} className={classes.headingLocation} variant="body2" alignItems="center">
                          <VideocamIcon className={classes.videoIcon} />
                                                    &nbsp;
                          {location}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <Box>
                          <Typography style={{ textDecoration: 'line-through' }} className={classes.headingLocation} variant="body2" alignItems="center">
                            <PublicIcon className={classes.videoIcon} />
                                                    &nbsp;
                            {timeZone}
                          </Typography>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  </Grid>
                </center>
                <center>
                  <BixpoweredBy id={locationId} />
                </center>
              </Paper>
            )}
        </div>
      </div>
    </>
  );
}
