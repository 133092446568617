import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  changeThemeFab: {
    backgroundColor: '#33344a',
    position: 'fixed',
    top: theme.spacing(22),
    right: 0,
    fontSize: 10,
    height: '45px',
    width: '27px',
    display: 'felx',
    zIndex: 1,
    borderRadius: 0,
    borderTopLeftRadius: '6%',
    borderBottomLeftRadius: '6%',
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(27.5),
      fontSize: 8,
      height: '40px',
      minHeight: 'px',
    },
  },
  loginHead: {
    paddingLeft: 30,
    fontSize: 14,
    fontWeight: 400,
  },
  spacing: {
    paddingLeft: '2px',
  },
  headerInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  signHeaderInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 32,
      marginTop: 20,
      marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 90,
      marginTop: 10,
      marginBottom: 10,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 185,
      marginTop: 20,
      marginBottom: 20,
    },
  },
  gridPadding: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },
  },
  fieldBox: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
    },
  },
  signBtnBox: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  phonenumber: {
    backgroundColor: '#f6f7ff !important',
  },
  facebook: {
    background: '#3b5998',
    opacity: '0.6',
  },
  google: {
    width: '85%',
    font: 50,
    textAlign: 'center',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
  },
  toast: {
    background: '#49d301',
    minHeight: '40px',
    borderRadius: '10px',
    fontWeight: 'bold',
    color: '#fff',
  },
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    // backgroundColor: theme.palette.primary.main,
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logotypeImage: {
    width: '90%',
    height: '90%',

    // marginBottom: theme.spacing(4)
  },
  responsiveBr: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logotypeText: {
    color: 'white',
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: '40%',
    display: 'flex',
    // flexDirection: 'column',
    overflow: 'auto',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      width: '40%',
      overflow: 'visible',
    },
  },
  form: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 350,
    },
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(3),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  FacebookButton: {
    marginTop: theme.spacing(1),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  FacebookButtonCreating: {
    marginTop: 8,
  },
  FacebookIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  OutlookButton: {
    marginTop: theme.spacing(1),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  OutlookButtonCreating: {
    marginTop: 8,
  },
  OutlookIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    height: '46%',
    width: '20%',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  },
  createAccountButton: {
    height: 46,
    textTransform: 'none',
  },
  formDividerContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: `${theme.palette.text.hint}40`,
  },
  errorMessage: {
    textAlign: 'center',
    margin: '1.5rem',
  },
  brMargin: {
    marginBottom: '1.5rem !important',
  },
  msgGrid: {
    background: 'white',
    marginTop: '2px',
    marginBottom: '1.5rem',
  },
  field: {
    width: '100%',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(2),
    },
  },
  twoFactor: {
    padding: 25,
    textAlign: 'center',
  },
  textLabel: {
    float: 'left',
    marginBottom: 7,
  },
  verify: {
    margin: 'auto',
    width: '40%',
    textAlign: 'center',
    marginTop: '5%',
    border: '3px solid #005EB2',
    borderRadius: 20,
    boxShadow: '8px 8px 5px #005EB2',
    padding: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '85%',
      marginTop: '2%',
    },
  },
  verifyTwocode: {
    margin: 'auto',
    width: '40%',
    textAlign: 'center',
    marginTop: '1%',
    border: '3px solid #005EB2',
    borderRadius: 20,
    boxShadow: '8px 8px 5px #005EB2',
    padding: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '85%',
      marginTop: '2%',
    },
  },
  copyRightText: {
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  optionText: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginBottom: 110,
    },
  },
  loginOption: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 125,
    },
  },
  logo: {
    width: 130,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      width: 120,
      marginBottom: 15,
    },
  },
}));
