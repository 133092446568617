import React from 'react';
import axios from 'axios';
import config from '../config';
import { dataRequest, postDataRequest, handleUndefined } from '../common/commonfunctions';
import PopupMessage from '../pages/PopupMessage/PopupMessage';
import Message from '../pages/Messages/Message';
import { setCookie } from '../common/const';

const EntitiesContext = React.createContext();
const AddressContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ENTITIES':
      return {
        ...state,
        isLoaded: true,
        entities: action.payload && action.payload.entitiesbytype,
      };
    case 'UPDATE_ENTITIES_LOCATIONS':
      return {
        ...state,
        isLoaded: true,
        entities: action.payload && action.payload.locationsWithCustomers,
      };
    case 'AGENCY_BY_USER':
      return {
        ...state,
        isLoaded: true,
        agencyId: action.payload && action.payload.agencylistbyuser
          && action.payload.agencylistbyuser[0]?.entityid,
      };
    case 'BUSINESS_BY_USER':
      return {
        ...state,
        isLoaded: true,
        businessData: action.payload && action.payload.businesslistbyuser,
      };
    case 'UPDATE_BUSINESS':
      return {
        ...state,
        isLoaded: true,
        businessData: action.payload && action.payload.entitiesbytype,
      };
    case 'UPDATE_ENTITY':
      return {
        ...state,
        isLoaded: true,
        entities: action.payload,
      };
    case 'EDIT_ENTITY':
      return {
        ...state,
        isLoaded: true,
        entities: state.entities.map((c) => {
          if (c.entityid === action.payload.entityid) {
            return { ...c, ...action.payload };
          }
          return c;
        }),
      };

    case 'CREATE_ENTITY':
      return {
        ...state,
        isLoaded: true,
        entities: state.entities,
      };
    case 'CREATE_ADDRESS':
      state.addreses.push(action.payload);
      return {
        ...state,
        isLoaded: true,
        addreses: state.addreses,
      };
    case 'SET_LOCATION_LIST':
      return {
        ...state,
        locationList: action.payload,
      };
    case 'SET_LOCATION_LIST_BUSINESSDASHBOARD':
      return {
        ...state,
        locationListinBusinessDashboard: action.payload,
      };
    case 'SET_LOCATION_LOADING':
      return {
        ...state,
        locationLoading: action.payload,
      };
    case 'SET_CUSTOMER_LIST':
      return {
        ...state,
        customerList: action.payload,
      };
    case 'SET_CUSTOMER_LOADING':
      return {
        ...state,
        customerLoading: action.payload,
      };
    case 'ENTITY_LOADING':
      return {
        ...state,
        Loading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const EntitiesProvider = ({ children }) => {
  const [entities, setEntities] = React.useReducer(rootReducer, {
    isLoaded: false,
    entities: [],
    locationList: [],
    locationLoading: true,
    customerList: [],
    customerLoading: true,
  });

  return (
    <EntitiesContext.Provider value={{ entities, setEntities }}>
      {children}
    </EntitiesContext.Provider>
  );
};
//= =============================
const AddressProvider = ({ children }) => {
  const [addreses, setAddreses] = React.useReducer(rootReducer, {
    isLoaded: false,
    addreses: [],
  });
  return (
    <AddressContext.Provider value={{ addreses, setAddreses }}>
      {children}
    </AddressContext.Provider>
  );
};
//= =============================

const useEntitiesState = () => {
  const context = React.useContext(EntitiesContext);
  return context;
};
//= =============================
const useAddresesState = () => {
  const context1 = React.useContext(AddressContext);
  return context1;
};
//= =============================
export function getEntitiesRequest(entityTypeId, dispatch) {
  // We check if app runs with backend mode
  dispatch({ type: 'ENTITY_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{entitiesbytype(entitytypeid:${entityTypeId}){entityid,businessname,firstname,displaylocationdoncampaign,brandname,lastname,email,phonenumber,businesstypeid,website,shortdesc,fulldesc,tags,jobid,nps,totalreview,subdomain}}`,
  })
    .then((res) => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      dispatch({ type: 'UPDATE_ENTITIES', payload: res.data });
    })
    .catch(() => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
    });
}
export function getEntitiesListRequest(dispatch) {
  // We check if app runs with backend mode
  dispatch({ type: 'ENTITY_LOADING', payload: true });
  postDataRequest(config.baseURLApi, {
    query: '{locationsWithCustomers{entityid,businessname,firstname,displaylocationdoncampaign,customercount,brandname,lastname,email,phonenumber,businesstypeid,website,shortdesc,fulldesc,tags,jobid,nps,totalreview}}',
  })
    .then((res) => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      dispatch({ type: 'UPDATE_ENTITIES_LOCATIONS', payload: res.data });
    })
    .catch(() => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
    });
}
export function getAgencyByUser(dispatch) {
  // We check if app runs with backend mode
  dispatch({ type: 'ENTITY_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: '{agencylistbyuser{entityid, businessname}}',
  })
    .then((res) => {
      if (res?.data?.agencylistbyuser[0]?.entityid) {
        // eslint-disable-next-line no-unused-expressions
        localStorage?.setItem('agency_Id', JSON.stringify(res?.data?.agencylistbyuser[0]?.entityid));
      }
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      dispatch({ type: 'AGENCY_BY_USER', payload: res.data });
    })
    .catch(() => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
    });
}
export function getBusinessByUser(dispatch) {
  // We check if app runs with backend mode
  dispatch({ type: 'ENTITY_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: '{businesslistbyuser{entityid, businessname, parententityid}}',
  })
    .then((res) => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      dispatch({ type: 'BUSINESS_BY_USER', payload: res.data });
      localStorage.setItem(
        'agency_Id',
        JSON.stringify(res.data?.businesslistbyuser
          && res.data?.businesslistbyuser[0]?.parententityid),
      );
    })
    .catch(() => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
    });
}
export function getBusinessData(entityTypeId, dispatch) {
  // We check if app runs with backend mode
  dispatch({ type: 'ENTITY_LOADING', payload: true });
  dataRequest(config.baseURLApi, {
    query: `{entitiesbytype(entitytypeid:${entityTypeId}){entityid,businessname,firstname,brandname,displaylocationdoncampaign,defaultcountrycode,lastname,email,phonenumber,businesstypeid,website,shortdesc,fulldesc,tags,jobid,nps,totalreview, iscallrecording, subdomain}}`,
  })
    .then((res) => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      dispatch({ type: 'UPDATE_BUSINESS', payload: res.data });
      if (res?.data?.entitiesbytype && res?.data?.entitiesbytype[0]) {
        localStorage.setItem('business_Id', JSON.stringify(res?.data?.entitiesbytype[0]?.entityid));
        setCookie('business_Id', res?.data?.entitiesbytype[0]?.entityid, 365);
      }
      dispatch({ type: 'ENTITY_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
    });
}

export function getEntityInfo(dispatch) {
  if (config.isBackend) {
    axios.get('/entities').then((res) => {
      dispatch({ type: 'UPDATE_ENTITIES', payload: res.data });
    });
  }
}

export function updateEntity(entity, getEntityStatus, dispatch) {
  const updatedById = JSON.parse(localStorage.getItem('userid'));
  dispatch({ type: 'ENTITY_LOADING', payload: true });
  postDataRequest(config.baseURLApi, {
    query: `
        mutation updateEntity(
           $entityid:ID!,
           $businessName:String!
           $brandName: String
           $businesstypeid: ID
           $firstName: String
           $lastName: String
           $email: String!
           $phoneNumber: String
           $entityTypeId: Int!
           $parentEntityId: ID
           $displaylocationdoncampaign:Boolean
           $jobId: String
           $website: String
           $status: Int
           $shortDesc: String
           $fullDesc: String
           $policy: String
           $terms: String
           $updatedById:ID
           $trialPlanId:Int
           $paidPlanId:Int
           $subdomain: String
           $defaultcountrycode: String
           $defaultphonecountrycode: String
           $iscallrecording: Boolean
           ){updateEntity
    (
     entityid:$entityid,
     businessname:$businessName,
     brandname:$brandName,
     firstname:$firstName,
     lastname: $lastName,
     email:$email,
     status:$status
     iscallrecording:$iscallrecording
     phonenumber:$phoneNumber,
     displaylocationdoncampaign:$displaylocationdoncampaign,
     businesstypeid:$businesstypeid, 
     entitytypeid:$entityTypeId,
     updatedby:$updatedById,
     website:$website,
     parententityid:$parentEntityId,
     shortdesc:$shortDesc,
     fulldesc:$fullDesc,
     tags:${handleUndefined(entity?.tags) ? entity?.tags : null},
     jobid:$jobId,
     policy:$policy,
     trialplanid:$trialPlanId,
     paidplanid:$paidPlanId,
     defaultcountrycode:$defaultcountrycode,
     defaultphonecountrycode:$defaultphonecountrycode,
     subdomain: $subdomain,
     terms:$terms)
    }`,
    variables: {
      entityid: entity.entityid,
      businessName: entity.BusinessName,
      brandName: entity.BrandName,
      firstName: handleUndefined(entity.FirstName),
      lastName: handleUndefined(entity.LastName),
      businesstypeid: entity.businesstypeid,
      email: entity.email,
      displaylocationdoncampaign: entity.displaylocationdoncampaign,
      iscallrecording: entity?.iscallrecording,
      phoneNumber: entity.phonenumber,
      entityTypeId: entity.entitytypeid,
      status: entity.status,
      parentEntityId: entity.parententityid,
      jobId: entity.JobId,
      updatedById,
      subdomain: entity?.subdomain?.toLowerCase(),
      website: entity.Website,
      shortDesc: entity.shortdesc,
      fullDesc: entity.fulldesc,
      policy: entity.policy,
      terms: entity.terms,
      trialPlanId: entity.trialPlanId || null,
      paidPlanId: entity.paidPlanId || null,
      defaultcountrycode: entity.defaultCountryCode || null,
      defaultphonecountrycode: entity.defaultPhoneCountryCode || null,
    },
  })
    .then((res) => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      if (res?.errors && res?.errors[0]?.message) {
        PopupMessage(res?.errors[0]?.message, true);
      }
      if (res?.data?.updateEntity[0]) {
        if (getEntityStatus) { getEntityStatus(true); }
        PopupMessage(Message.entityDataChangeMessage);
      }
    })
    .catch(() => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      if (getEntityStatus) { getEntityStatus('error'); }
    });
}
export function editAddress(
  addressId,
  address,
  entityId,
  countryId,
  stateId,
  handleGetAddress,
) {
  const updatedById = JSON.parse(localStorage.getItem('userid'));
  const state = stateId;
  postDataRequest(config.baseURLApi, {
    query: `
    mutation editEntityAddress(
      $addressid: ID!
      $street1: String
      $street2: String
      $city: String
      $stateid: Int
      $state: String
      $countryid: Int!
      $zip: String
      $updatedby: ID
      $entityid: ID!
      ){
    editEntityAddress(
        addressid:$addressid,
      street1:$street1,
      street2:$street2,
      city:$city,
      countryid:$countryid,
      stateid:$stateid,
      state:$state,
      zip:$zip,
      entityid:$entityid
      updatedby:$updatedby
      )
    }`,
    variables: {
      addressid: addressId,
      street1: address?.Address1 || null,
      street2: address?.Address2 || null,
      city: address?.City || null,
      countryid: JSON.parse(countryId),
      stateid: typeof stateId === 'number' ? JSON.parse(stateId) : 0,
      state: typeof stateId !== 'number' ? state : null,
      zip: address.Zip || null,
      entityid: entityId,
      updatedby: updatedById,
    },
  })
    .then((res) => {
      if (res.errors && res.errors[0].message) {
        PopupMessage(res.errors[0].message, true);
      }
      if (res.data.editEntityAddress[0] === 1) {
        handleGetAddress(true);
        PopupMessage(Message.entityDataChangeMessage);
      }
    })
    .catch(() => {
      handleGetAddress('error');
    });
}
export function createEntity(
  entity,
  getEntityStatus,
  dispatch,
) {
  const ownerId = JSON.parse(localStorage.getItem('userid'));
  dispatch({ type: 'ENTITY_LOADING', payload: true });
  postDataRequest(config.baseURLApi, {
    query: `
            mutation createEntity(
                $businessName:String!
                $brandName: String
                $firstName: String
                $lastName: String
                $businessTypeId: ID
                $email: String!
                $phoneNumber: String
                $entityTypeId: Int!
                $parentEntityId: ID
                $jobId: String
                $ownerId: ID
                $displaylocationdoncampaign: Boolean
                $website: String
                $shortDesc: String
                $fullDesc: String
                $trialPlanId:Int
                $paidPlanId:Int 
                $isCreatedAgency:Boolean
               ){createEntity
        (businessname:$businessName,
         brandname:$brandName,
         firstname:$firstName,
         lastname: $lastName,
         email:$email,
         displaylocationdoncampaign: $displaylocationdoncampaign,
         phonenumber:$phoneNumber,
         businesstypeid:$businessTypeId, 
         entitytypeid:$entityTypeId,
         ownerid:$ownerId,
         website:$website,
         parententityid:$parentEntityId,
         shortdesc:$shortDesc,
         fulldesc:$fullDesc,
         trialplanid:$trialPlanId,
         paidplanid:$paidPlanId,
         iscreatedagency:$isCreatedAgency,
         tags:${handleUndefined(entity?.tags) ? entity?.tags : null}, 
         jobid:$jobId) {
             entityid,
             businessname,
             firstname,
             lastname,
             email,
             phonenumber,
             entitytypeid,
             parententityid,
             ownerid,
             displaylocationdoncampaign,
             businesstypeid,
             shortdesc,
             fulldesc, 
             tags, 
             jobid
          }
        }`,
    variables: {
      businessName: entity.BusinessName,
      brandName: entity.BrandName,
      firstName: handleUndefined(entity.FirstName),
      lastName: handleUndefined(entity.LastName),
      businessTypeId: entity.businesstypeid,
      email: entity.email,
      phoneNumber: entity.phonenumber,
      entityTypeId: entity.entitytypeid,
      parentEntityId: entity.parententityid,
      jobId: entity.JobId,
      displaylocationdoncampaign: entity.displaylocationdoncampaign,
      ownerId,
      website: entity.Website,
      shortDesc: entity.shortdesc,
      fullDesc: entity.fulldesc,
      trialPlanId: entity.trialPlanId || null,
      paidPlanId: entity.paidPlanId || null,
      isCreatedAgency: entity.isCreatedAgency || false,
    },
  })
    .then((res) => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      if (res.errors && res.errors[0].message) {
        PopupMessage(res.errors[0].message, true);
      }
      if (res?.data?.createEntity) {
        PopupMessage(Message.entityDataChangeMessage);
        getEntityStatus(true);
      }
      dispatch({ type: 'CREATE_ENTITY', payload: res });
      if (entity?.getEntityIdAddress) { entity.getEntityIdAddress(res.data.createEntity.entityid); }
    })
    .catch(() => {
      dispatch({ type: 'ENTITY_LOADING', payload: false });
      getEntityStatus('error');
    });
}

export function createAddress(
  address,
  countryId,
  stateId,
  adrressEntityid,
  handleGetAddress,
) {
  const state = stateId;
  postDataRequest(config.baseURLApi, {
    query: `
      mutation createEntityAddress(
        $street1: String
        $street2: String
        $city: String
        $stateid: Int
        $state: String
        $countryid: Int!
        $zip: String
        $createdy: ID
        $updatedby: ID
        $entityid: ID!
        ){
        createEntityAddress(
        street1:$street1,
        street2:$street2,
        city:$city,
        countryid:$countryid,
        stateid:$stateid,
        state:$state,
        createdy: $createdy,
        updatedby: $updatedby,
        zip:$zip,
        entityid:$entityid){
         street1, street2, city,
          countryid,stateid,zip}
      }`,
    variables: {
      street1: address?.Address1 || null,
      street2: address?.Address2 || null,
      city: address?.City || null,
      countryid: JSON.parse(countryId),
      stateid: typeof stateId === 'number' ? JSON.parse(stateId) : 0,
      state: typeof stateId !== 'number' ? state : null,
      zip: address.Zip || null,
      entityid: adrressEntityid || null,
    },
  })
    .then((res) => {
      if (res.errors && res.errors[0].message) {
        PopupMessage(res.errors[0].message, true);
      }
      if (res?.data?.createEntityAddress) {
        handleGetAddress(true);
        PopupMessage(Message.entityDataChangeMessage);
      }
    })
    .catch(() => {
      handleGetAddress('error');
    });
}
export const getLocationsListinBusinessDasboard = async (id, dispatch) => {
  dispatch({ type: 'SET_LOCATION_LOADING', payload: true });
  await postDataRequest(config.baseURLApi, {
    query: `{locationsWithCustomers(entitytypeid:${id}){
            entityid,businessname,brandname,firstname,lastname,email,displaylocationdoncampaign,
            phonenumber,entitytypeid,parententityid, businesstypeid,createdby,customercount
            updatedby,jobid,ownerid,website,shortdesc,fulldesc
    }}`,
  })
    .then((res) => {
      dispatch({
        type: 'SET_LOCATION_LIST_BUSINESSDASHBOARD',
        payload: res.data.locationsWithCustomers,
      });
      dispatch({ type: 'SET_LOCATION_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'SET_LOCATION_LOADING', payload: false });
    });
};
export const getLocationsByParent = async (id, dispatch) => {
  dispatch({ type: 'SET_LOCATION_LOADING', payload: true });
  await dataRequest(config.baseURLApi, {
    query: `{entitiesbyparentid(parententityid:"${id}"){
            entityid,businessname,brandname,firstname,lastname,email,displaylocationdoncampaign,
            phonenumber,entitytypeid,parententityid, businesstypeid,createdby,customercount
            updatedby,jobid,ownerid,website,shortdesc,fulldesc,nps,totalreview,subdomain
    }}`,
  })
    .then((res) => {
      dispatch({
        type: 'SET_LOCATION_LIST',
        payload: res.data.entitiesbyparentid,
      });
      dispatch({ type: 'SET_LOCATION_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'SET_LOCATION_LOADING', payload: false });
    });
};
export const getLocations = async (id, dispatch) => {
  dispatch({ type: 'SET_LOCATION_LOADING', payload: true });
  await dataRequest(config.baseURLApi, {
    query: `{entitiesbytype(entitytypeid:${id}){
            entityid,businessname,brandname,firstname,lastname,email,displaylocationdoncampaign,
            phonenumber,entitytypeid,parententityid, businesstypeid,createdby,customercount
            updatedby,jobid,ownerid,website,shortdesc,fulldesc,parententityid,subdomain
    }}`,
  })
    .then((res) => {
      dispatch({
        type: 'SET_LOCATION_LIST',
        payload: res.data.entitiesbytype,
      });
      dispatch({ type: 'SET_LOCATION_LOADING', payload: false });
    })
    .catch(() => {
      dispatch({ type: 'SET_LOCATION_LOADING', payload: false });
    });
};
export const getCustomers = async (id, dispatch) => {
  if (handleUndefined(id)) {
    dispatch({ type: 'SET_CUSTOMER_LOADING', payload: true });
    dispatch({ type: 'SET_CUSTOMER', payload: true });
    await postDataRequest(config.baseURLApi, {
      query: ` {customersbylocation(locationid: ${id}) {
        entityid
          brandname
        firstname
        lastname
        email
        phonenumber
      }}`,
    })
      .then((res) => {
        dispatch({
          type: 'SET_CUSTOMER_LIST',
          payload: res.data.customersbylocation,
        });
        dispatch({ type: 'SET_CUSTOMER_LOADING', payload: false });
      })
      .catch(() => {
        dispatch({ type: 'SET_CUSTOMER_LOADING', payload: false });
      });
  }
};
export {
  EntitiesProvider,
  EntitiesContext,
  useEntitiesState,
  AddressProvider,
  AddressContext,
  useAddresesState,
};
