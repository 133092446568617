import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Grid, Typography, Avatar as AvatarMui, Badge, Paper, Button,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { handleUndefined, postDataRequestPublic } from '../../../../common/commonfunctions';
import config from '../../../../config';
import { EventListByURL } from '../../../../Api Mutation/EventList';
import { LoadingPage } from '../../../LoadingPage/LoadingPage';
import useStyles from '../style';
import PoweredBy from '../../../../common/PoweredBy';

const AppointmentList = () => {
  const classes = useStyles();
  const { shorturl } = useParams([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [profile, setProfile] = useState('');
  const [eventData, setEventData] = useState([]);
  const [showLogo, setShowLogo] = useState(false);
  const [businessId, setBusinessId] = useState('');

  useEffect(() => {
    let isMounted = true;
    if (shorturl && isMounted) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: EventListByURL,
        variables: {
          pageurl: `${shorturl}`,
        },
      }).then((res) => {
        const data = res?.data?.eventListByUrl;
        setShowLogo(data?.isshowfooterlogo);
        setBusinessId(data?.locationid);
        setProfile(data?.createdUser);
        setEventData(data?.events);
      });
      setDataLoading(false);
    }
    return () => {
      isMounted = false;
    };
  }, [shorturl]);

  return (
    <>
      {dataLoading ? (<LoadingPage />) : ''}
      {profile && eventData.length > 0 && (
        <>
          <center>
            <Box>
              <Badge
                className={classes.avatarImg}
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <AvatarMui
                  className={profile?.avatar ? classes.profileImage : classes.avatar}
                  src={profile?.avatar}
                >
                  {handleUndefined(profile?.firstname?.charAt(0))}
                </AvatarMui>
              </Badge>
              <Typography variant="h4" className={classes.profileName}>{`${profile?.firstname ? profile?.firstname : ''} ${profile?.lastname ? profile?.lastname : ''}`}</Typography>
              <Typography variant="body1" className={classes.subheading}>Book a call with me:</Typography>
            </Box>
          </center>
          <Box className="pl-3 pr-3">
            <Grid container spacing={3} className="mt-5" alignItems="center" justifyContent="center">
              {eventData && eventData.map((item) => (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Paper
                    className={classes.paperCard}
                    elevation={3}
                    style={{
                      background: 'white',
                      borderBottom: '7px solid green',
                    }}
                  >
                    <Box className="p-3">
                      <Typography variant="h6" style={{ textTransform: 'capitalize' }}>{item?.name}</Typography>
                      <Box style={{ overflow: 'auto', height: 250 }}>
                        <Typography
                          variant="body1"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </Box>
                      <Box className="mt-3 mb-1">
                        <Button
                          variant="outlined"
                          color="primary"
                          endIcon={<ArrowForwardIos />}
                          size="medium"
                          target="_blank"
                          href={item?.eventbookingurl}
                          style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                        >
                          Book Now
                          {' '}
                          {item.ispaidevent ? `For $${item.amount}` : ''}
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
          <center>
            {showLogo ? <PoweredBy id={businessId} /> : ''}
          </center>
        </>
      )}
    </>
  );
};

export default AppointmentList;
