import React, { useState, useEffect } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { useGlobalState } from '../../context/GlobalContext';
import { Organizationbylocation } from '../../Api Mutation/Contact';
import config from '../../config';

import useStyles from '../styles';
import { CreateOrganization } from '../../Api Mutation/OrganizationApi';

export default function OrganizationsName({
  locationid, setOrganizationData, organizationData, isOrderModule, organizationId,
  setOrganizationId, orderDialogView,
}) {
  const classes = useStyles();
  const filter = createFilterOptions();
  const globalContext = useGlobalState();
  const [pageNumber, setPageNumber] = useState(1);
  const [getOrganizationData, setGetOrganizationData] = useState([]);

  const getOrganizationByLocation = (props) => {
    if (locationid || globalContext.globalState.currentLocation.entityid) {
      postDataRequest(config.baseURLApi, {
        query: Organizationbylocation,
        variables: {
          locationid: locationid || globalContext.globalState.currentLocation.entityid,
          pagenumber: props?.searchtext ? 1 : props?.pagenumber || pageNumber,
          pagesize: 20,
          searchtext: props?.searchtext,
          organizationid: isOrderModule ? handleUndefined(localStorage.getItem('orgMId')) : null,
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.organizationbylocation?.rows?.length > 0) {
          const data = getOrganizationData.concat(
            res?.data?.organizationbylocation?.rows,
          );
          const uniqueArray = [...new Set(
            data.map((item) => JSON.stringify(item)),
          )].map((item) => JSON.parse(item));
          setGetOrganizationData(uniqueArray);
        }
      });
    }
  };

  const loadNextpage = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      setPageNumber(pageNumber + 1);
      getOrganizationByLocation({ pagenumber: pageNumber + 1 });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getOrganizationByLocation();
    }
    return () => {
      isMounted = false;
    };
  }, [locationid || globalContext.globalState.currentLocation.entityid]);
  return (
    isOrderModule
      ? (
        <Autocomplete
          value={getOrganizationData.find((con) => con?.organizationid === (organizationId || localStorage.getItem('orgMId')))
            || organizationData || null}
          style={{ width: isOrderModule ? 250 : '' }}
          disabled={orderDialogView}
          closeIcon={false}
          onChange={(event, newValue) => {
            if (newValue) {
              if (typeof newValue === 'string') {
                setOrganizationData({
                  name: newValue,
                });
              } else if (newValue && newValue?.inputValue) {
                setOrganizationData({
                  name: newValue?.inputValue,
                });
                const fetchContact = {
                  name: newValue?.inputValue || null,
                  locationid: locationid || globalContext.globalState.currentLocation.entityid,
                  createdby: JSON.parse(localStorage.getItem('userid')),
                };
                postDataRequest(config.baseURLApi, {
                  query: CreateOrganization,
                  variables: { ...fetchContact },
                }).then(async (res) => {
                  if (res?.errors && res?.errors[0]?.message) {
                    showNotification(res.errors[0].message, 'error');
                  } else if (res?.data?.createOrganization) {
                    setOrganizationId(res?.data?.createOrganization?.organizationid);
                    localStorage.setItem('orgMId', res?.data?.createOrganization?.organizationid);
                    getOrganizationByLocation();
                    showNotification('Organization added successfully', 'success');
                  }
                });
              } else {
                setOrganizationData(newValue);
              }
              setOrganizationId(newValue?.organizationid);
            } else {
              localStorage?.removeItem('orgMId');
              setOrganizationData();
              setOrganizationId(null);
            }
          }}
          ListboxProps={{
            onScroll: loadNextpage,
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={getOrganizationData}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option?.inputValue) {
              return option?.inputValue;
            }
            return option?.name || '';
          }}
          renderOption={(option) => option?.name}
          size="small"
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              value={organizationData?.name}
              size="small"
              label="Organization Name"
              variant="outlined"
              className={classes.autocomplete}
              placeholder="Name"
              onChange={(e) => {
                getOrganizationByLocation({ searchtext: e?.target?.value });
                localStorage?.removeItem('orgMId');
                setOrganizationId(null);
              }}
            />
          )}
        />
      )
      : (
        <Autocomplete
          value={organizationData}
          style={{ width: isOrderModule ? 250 : '' }}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setOrganizationData({
                name: newValue,
              });
            } else if (newValue && newValue?.inputValue) {
              setOrganizationData({
                name: newValue?.inputValue,
              });
            } else {
              setOrganizationData(newValue);
            }
          }}
          ListboxProps={{
            onScroll: loadNextpage,
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={getOrganizationData}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option?.inputValue) {
              return option?.inputValue;
            }
            return option?.name || '';
          }}
          renderOption={(option) => option?.name}
          size="small"
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              value={organizationData?.name}
              size="small"
              label="Organization Name"
              variant="outlined"
              className={classes.autocomplete}
              placeholder="Name"
              onChange={(e) => {
                getOrganizationByLocation({ searchtext: e?.target?.value });
              }}
            />
          )}
        />
      )
  );
}
