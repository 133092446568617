import { IconButton, Typography } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import React from 'react';
import useStyle from './style';

function PageNumberCustom({
  handlePrev, handleNext, currentPage, lastPage,
}) {
  const classes = useStyle();
  return (
    <div>
      <div className={classes.pageNumberBox}>
        <IconButton onClick={handlePrev} disabled={currentPage === 1}>
          <ArrowBackIos className={currentPage === 1 ? classes.disableClass : classes.arrowIcon} />
        </IconButton>
        <Typography className={classes.pageValue}>
          {lastPage === 0 ? 0 : currentPage}
          {' '}
          of
          {' '}
          {lastPage}
        </Typography>
        <IconButton onClick={handleNext} disabled={currentPage === lastPage || lastPage === 0}>
          <ArrowForwardIos className={(currentPage === lastPage || lastPage === 0)
            ? classes.disableClass : classes.arrowIcon}
          />
        </IconButton>
      </div>
    </div>
  );
}

export default PageNumberCustom;
