import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  field: {
    width: '100%',
  },
  buttonBack: {
    marginTop: 10,
    marginBottom: 10,
    width: '100px',
    background: 'gray',
  },
  buttonNext: {
    marginTop: 10,
    marginBottom: 10,
    width: '100px',
    marginLeft: 8,
  },
  buttonSkip: {
    marginTop: 10,
    marginBottom: 10,
    width: '100px',
    marginLeft: 8,
  },
  centerButtonDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
