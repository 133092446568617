export const modules = {
  business: 'Business',
  location: 'Location',
  favourites: 'Favourites',
  myWorkspace: 'My Workspace',
  plan: 'Plan',
  settings: 'Settings',
  team: 'Teams',
  importLeads: 'Import Leads',
  workflows: 'Workflows',
  linkedInSequence: 'LinkedIn Sequence',
  appointments: 'Appointments',
  leadGenForms: 'LeadGen Forms',
  inquiryForms: 'Inquiry Forms',
  visitorDashboard: 'Visitor Dashboard',
  events: 'Events',
  liveChat: 'Live Chat',
  contacts: 'Contacts',
  accounts: 'Accounts',
  emailFinder: 'Email Finder',
  documents: 'Documents',
  deals: 'Deals',
  tasks: 'Tasks',
  inbox: 'Inbox',
  serviceDesk: 'Service Desk',
  calls: 'Calls',
  newsLetter: 'News Letter',
  socialMedia: 'Social Media',
  emailMarketing: 'Email Marketing',
  textMarketing: 'Text Marketing',
  pages: 'Pages',
  reviewMarketing: 'Review Marketing',
  productService: 'Product & Service',
  paymentInvoice: 'Payment & Invoice',
  salesOrders: 'Sales Orders',
  monitorReputation: 'Monitor Reputation',
  reviewCampaigns: 'Review Campaigns',
  launchCampaign: 'Launch Campaign',
  quickReviews: 'Quick Reviews',
  touchlessReviews: 'Touchless Reviews',
  marketingDashboard: 'Marketing Dashboard',
  contactDashboard: 'Contact Dashboard',
  emailReport: 'Email Report',
  salesDashboard: 'Sales Dashboard',
  paymentDashboard: 'Payment Dashboard',
  professionalServices: 'Professional Services',
};
export const permissions = {
  createBusiness: 'Create Business',
  businessDashboard: 'Business Dashboard',
  createLocation: 'Create Location',
  locationDashboard: 'Location Dashboard',
  favourites: 'Favourites',
  myWorkspace: 'My Workspace',
  managePlan: 'Manage Plan',
  locationSelectionTopBar: 'Business/Location Selection Top Bar',
  manageBusiness: 'Manage Business',
  manageYourBrand: 'Manage Your Brand',
  manageProperties: 'Manage Properties',
  veriyDomain: 'Veriy Domain',
  whiteLabelSettings: 'White Label Settings',
  apiDoc: 'API Doc.',
  manageUser: 'Manage User',
  addUser: 'Add User',
  deleteUser: 'Delete User',
  editAccess: 'Edit Access',
  addRole: 'Add Role',
  deleteRole: 'Delete Role',
  editRole: 'Edit Role',
  manageLocation: 'Manage Location',
  fetchOnlineReview: 'Fetch Online Review',
  campaignSettings: 'Campaign Settings',
  processAutomation: 'Process Automation',
  myProfile: 'My Profile',
  thirdPartySettings: 'Third party Settings',
  manageTeams: 'Manage Teams',
  manageImportLeads: 'Manage Import Leads',
  workflowList: 'Workflow List',
  manageWorkflows: 'Manage Workflows',
  linkedInSequenceList: 'LinkedIn Sequence List',
  manageLinkedInSequence: 'Manage LinkedIn Sequence',
  manageAppointment: 'Manage Appointment',
  manageAppointmentBooking: 'Manage Appointment Booking',
  manageAppointmentBookingPage: 'Manage Appointment Booking Page',
  manageLeadGenForms: 'Manage LeadGen Forms',
  manageInquiryForms: 'Manage Inquiry Forms',
  manageVisitorDashboard: 'Manage Visitor Dashboard',
  manageEvents: 'Manage Events',
  manageLiveChat: 'Manage Live Chat',
  contactGrid: 'Contact Grid',
  addEditContact: 'Add/Edit Contact',
  exportContact: 'Export Contact',
  accountsCreateEdit: 'Accounts Create/Edit',
  accountsList: 'Accounts List',
  accountsDelete: 'Accounts Delete',
  manageEmailFinder: 'Manage Email Finder',
  documentsList: 'Documents List',
  manageDocuments: 'Manage Documents',
  dealsList: 'Deals List',
  manageDeals: 'Manage Deals',
  tasksList: 'Tasks List',
  manageTasks: 'Manage Tasks',
  inboxList: 'Inbox List',
  serviceDeskList: 'Service Desk List',
  manageServiceDesk: 'ManageServiceDesk',
  CallsList: 'Calls List',
  newsLetterList: 'News Letter List',
  manageNewsLetter: 'Manage News Letter',
  socialMediaList: 'Social Media List',
  manageSocialMedia: 'Manage Social Media',
  emailMarketingList: 'Email Marketing List',
  manageEmailMarketing: 'Manage Email Marketing',
  textMarketingList: 'Text Marketing List',
  manageTextMarketing: 'Manage Text Marketing',
  PagesList: 'Pages List',
  managePages: 'Manage Pages',
  ReviewMarketingList: 'Review Marketing List',
  manageReviewMarketing: 'Manage Review Marketing',
  productServiceList: 'Product & Service List',
  manageProductService: 'Manage Product & Service',
  paymentInvoiceList: 'Payment & Invoice List',
  managePaymentInvoice: 'Manage Payment & Invoice',
  salesOrdersList: 'Sales Orders List',
  manageSalesOrders: 'Manage Sales Orders',
  monitorReputationList: 'Monitor Reputation List',
  reviewCampaignsList: 'Review Campaigns List',
  manageReviewCampaigns: 'Manage Review Campaigns',
  manageLaunchCampaign: 'Manage Launch Campaign',
  manageQuickReviews: 'Manage Quick Reviews',
  manageTouchlessReviews: 'Manage Touchless Reviews',
  manageMarketingDashboard: 'Manage Marketing Dashboard',
  manageContactDashboard: 'Manage Contact Dashboard',
  manageEmailReport: 'Manage Email Report',
  manageSalesDashboard: 'Manage Sales Dashboard',
  managePaymentDashboard: 'Manage Payment Dashboard',
  manageProfessionalServices: 'Manage Professional Services'
};
