export const Verifyuseremail = `
mutation verifyuseremail($code: String) {
  verifyuseremail(code: $code)
}`;

export const Verifytwofactorauth = `
  mutation verifytwofactorauth($code: String) {
    verifytwofactorauth(code: $code) {
      token
      lastlogin
      isenabletwofactorauth
    }
  }`;

export const Resendtwofactorauthemail = `
  mutation resendtwofactorauthemail($userid: ID!) {
    resendtwofactorauthemail(userid: $userid)
  }`;

export const Resenduserverificationemail = `
  mutation resenduserverificationemail($userid: ID!) {
    resenduserverificationemail(userid: $userid)
  }`;

export const Userprofilebyuser = `
query userprofilebyuser($userid: ID!) {
  userprofilebyuser(userid: $userid) {
    userprofileid
    firstname
    lastname
    phonenumber
    twiliophonenumber
    avatar
    user {
      userid
      email
      usertype
    }
    profiletype
    createdby
    updatedby
    deleted
    phonecountryid
    jobtitle
    linkedin
    facebook
    twitter
    customemailsignature
    includeemailsignature
    includecustomemailsignature
    isenabletwofactorauth
    twofactorstatus
  }
}`;

export const User = `query user($userid: ID!) {
  user(userid: $userid) {
    userid
    usertype
    email
    password
    role {
      roleid
      name
      parentroleid
      level
    }
    roleid
    parentid
    issocial
    deleted
    lastlogin
    businessid
    locationid
    ishidebusinessdetails
    ishidewelcomemsg
    defaultcontactview
    isemailverified
    isenabletwofactorauth
    twofactorstatus
    defaulttaskview
    defaultdealview
    defaulttaskviewid
    defaultfolderid
    isemailrequireimport
    isphonerequireimport
  }
}`;

export const Usersbytype = `query usersbytype($businessid: ID!, $locationid: ID!) {
    usersbytype(businessid: $businessid, locationid: $locationid) {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
  }`;

export const SignUp = `mutation signUp($businessname: String!, $firstname: String!, $password: String!, $usertype: Int!, $referby: ID, $userid: ID, $lastname: String, $phonenumber: String, $email: String, $createdby: ID, $fromEntity: Boolean, $parentid: ID, $deleted: Boolean, $partneruserid : ID) {
  signUp(businessname: $businessname, firstname: $firstname, password: $password, usertype: $usertype, referby: $referby, userid: $userid, lastname: $lastname, phonenumber: $phonenumber, email: $email, createdby: $createdby, fromEntity: $fromEntity, parentid: $parentid, deleted: $deleted, partneruserid: $partneruserid) {
    token
    lastlogin
    isenabletwofactorauth
  }
}`;

export const FromUsers = `query fromusers(
  $ownerid: ID!,
   $businessid: ID!,
    $locationid: ID!
  ){
  fromusers(
    ownerid: $ownerid,
     businessid: $businessid,
      locationid: $locationid
    ) {
      emailsettingid
      accountid
      refreshtoken
      accesstoken
      historyid
      useremail
      accesstokenexpirydate
      status
      servicesprovider
      createdby
      updatedby
      deleted
      locationid
      ownerid
      iscalendar
      isemail
      iscontact
      isdefaultemail
      username
      createdAt
      updatedAt
                }
              }`;

export const UserByParent = `query usersbyparent($userid:ID!){
  usersbyparent(userid:$userid) {
    userid,firstname,lastname,email,phonenumber,role
  }
}`;

export const EditUserProfile = `mutation editUserProfile($userprofileid: ID!, $updatedby: ID!, $firstname: String, $lastname: String, $twiliophonenumber: String, $phonenumber: String, $avatar: String, $profiletype: Int, $phonecountryid: ID, $jobtitle: String, $linkedin: String, $facebook: String, $twitter: String, $customemailsignature: String, $includeemailsignature: Boolean, $includecustomemailsignature: Boolean) {
  editUserProfile(userprofileid: $userprofileid, updatedby: $updatedby, firstname: $firstname, lastname: $lastname, twiliophonenumber: $twiliophonenumber, phonenumber: $phonenumber, avatar: $avatar, profiletype: $profiletype, phonecountryid: $phonecountryid, jobtitle: $jobtitle, linkedin: $linkedin, facebook: $facebook, twitter: $twitter, customemailsignature: $customemailsignature, includeemailsignature: $includeemailsignature, includecustomemailsignature: $includecustomemailsignature)
}`;

export const GetEmailSignature = `query getemailsignature($userid: ID!) {
  getemailsignature(userid: $userid)
}`;

export const UserData = `query User($userid: ID!) {
  user(userid: $userid) {
    datavisibility
  }
}`;

