import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  form: {
    flexGrow: 1,
    width: '100%',
  },
  selectEmpty: {
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
    },
  },
  field: {
    width: '100%',
  },
}));
