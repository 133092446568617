import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, CardActionArea, CardContent, Typography, Box, Grid, Dialog, DialogContent, Link, Button,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { postDataRequestPublic } from '../../../common/commonfunctions';
import { CRMLifecyclestageByStatus, StripeCurrency, TochlessPubilcStatus } from '../../../common/const';
import { ProductServicesbyid } from '../../../Api Mutation/Product&ServiceApi';
import { Entity, BrandSettingByLocation } from '../../../Api Mutation/EntityApi';
import { createOrGetContact } from '../../../Api Mutation/Contact';
import BusinessFavicon from '../../../common/BusinessFavicon';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import PoweredBy from '../../../common/PoweredBy';
import config from '../../../config';
import useStyles from '../styles';
import LazyLoader from '../../LoadingPage/LazyLoader';

const Payment = lazy(() => import('../../Setting/PaymentConnection/Payment'));
const Contactinfo = lazy(() => import('../../ContactInfo/ContactInfo'));

export default function PublicProduct() {
  const classes = useStyles();
  const { pid, lid, uid } = useParams([]);
  const [productData, setProductData] = useState();
  const [visible, setVisible] = useState(false);
  const [contactData, setContactData] = useState();
  const [thankYou, setThankYou] = useState(false);
  const [locationData, setLocationData] = useState();
  const [brandImage, setBrandImage] = useState();
  const [favicon, setFavicon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [ckValue, setCkValue] = useState();
  const [popupThankYouButtonText, setPopupThankYouButtonText] = useState();
  const [popupThankYouUrl, setPopupThankYouUrl] = useState('');
  const [currencyValue, setCurrencyValue] = useState('');
  const [showThankYouButton, setShowThankYouButton] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [thankYouFileUrl, setThankYouFileUrl] = useState();
  const [thanksMsg, setThanksMsg] = useState(ckValue);
  const [paymentId, setPaymentId] = useState([]);

  const getLocationByData = () => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: lid },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationData(res?.data?.entity);
          setLocationBrandImage(res?.data?.entity?.logourl);
          setFavicon(res?.data?.entity?.favicon);
        }
      });
    }
    if (uid) {
      postDataRequestPublic(config.baseURLApi, {
        query: BrandSettingByLocation,
        variables: {
          locationid: uid,
        },
      }).then((res) => setBrandImage(res?.data?.brandSettingByLocation?.logourl));
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLocationByData();
    }
    return () => {
      isMounted = false;
    };
  }, [lid, uid]);

  const getInitialData = () => {
    postDataRequestPublic(config.baseURLApi, {
      query: ProductServicesbyid,
      variables: {
        productserviceid: pid,
      },
    }).then((response) => {
      if (response?.data?.productServicesbyid) {
        setProductData(response?.data?.productServicesbyid);
        setCurrencyValue(response?.data?.productServicesbyid?.currency);
        setEnablePublicLogo(response?.data?.productServicesbyid?.isshowfooterlogo);
        setShowThankYouButton(response?.data?.productServicesbyid?.isshowthanksbutton);
        setPopupThankYouUrl(response?.data?.productServicesbyid?.thanksbuttonlink);
        setPopupThankYouButtonText(response?.data?.productServicesbyid?.thanksbuttontext);
        setCkValue(response?.data?.productServicesbyid?.thanksmessage);
        setThankYouFileUrl(response?.data?.productServicesbyid?.thanksbuttonfileurl);
      } else if (response?.errors[0]?.message) {
        showNotification(response?.errors && response?.errors[0]?.message, 'error');
      }
    });
  };

  const createContact = (values) => {
    const msg = ckValue.replace(/{{Name}}/g, values?.FirstName ? `${values?.FirstName} ${values?.LastName || ''}` : '');
    setThanksMsg(msg);
    const CREATE_CONTACT_MUTATION = createOrGetContact;
    postDataRequestPublic(config.baseURLApi, {
      query: CREATE_CONTACT_MUTATION,
      variables: {
        primaryemail: values?.primaryemail,
        firstname: values?.FirstName,
        lastname: values?.LastName,
        ownerid: uid,
        phonenumber: values?.phonenumber,
        locationid: lid,
        status: TochlessPubilcStatus?.Warm,
        lifecyclestage: CRMLifecyclestageByStatus?.Customer,
        productid: pid,
      },
    }).then((res) => {
      if (res?.errors && res?.errors[0]?.message) {
        showNotification(res?.errors[0]?.message, 'error');
      } else if (res?.data?.createOrGetContact) {
        setContactData(res?.data?.createOrGetContact);
        setVisible(true);
        if (productData?.salesprice <= 0) {
          setThankYou(true);
        }
        showNotification('Data updated successfully', 'success');
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getInitialData();
    }
    return () => {
      isMounted = false;
    };
  }, [pid]);

  useEffect(() => {
    let isMounted = true;
    if (paymentId && isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: createOrGetContact,
        variables: {
          primaryemail: contactData?.primaryemail,
          firstname: contactData?.firstname,
          lastname: contactData?.lastname,
          ownerid: uid,
          phonenumber: contactData?.phonenumber,
          locationid: lid,
          status: TochlessPubilcStatus?.Warm,
          lifecyclestage: CRMLifecyclestageByStatus?.Customer,
          productid: pid,
          paymentid: paymentId,
        },
      });
    }
    return () => {
      isMounted = false;
    };
  }, [paymentId]);
  return (
    <>
      <BusinessFavicon
        name={productData?.name}
        logo={favicon || locationBrandImage || brandImage}
      />
      <Dialog
        open={thankYou}
        onClose={() => {
          setThankYou(false);
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
      >
        <DialogContent id="responsive-dialog-title" style={{ padding: 20 }}>
          <center>
            <CheckCircleOutlineIcon style={{ fontSize: 70, color: 'green' }} />
          </center>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: thanksMsg }} />
          <br />
          {thankYouFileUrl ? (
            <Link
              href={thankYouFileUrl}
              target="_blank"
              download
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
              >
                {popupThankYouButtonText}
              </Button>
            </Link>
          ) : showThankYouButton
            ? (
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  window.open(popupThankYouUrl, '_blank');
                }}
              >
                {popupThankYouButtonText}
              </Button>
            )
            : ''}
          <center>
            {enablePublicLogo ? <PoweredBy id={locationData?.entityid} /> : ''}
          </center>
        </DialogContent>
      </Dialog>
      <Box className={classes.productCardDetails}>
        <Card className={classes.rootDetails}>
          <Box className={classes.details}>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
              <Grid item xs={12} md={1}>
                {locationData?.logourl ? (
                  <img
                    className={classes.businessLogo}
                    src={locationData?.logourl}
                    alt="logo"
                  />
                ) : (
                  <img
                    className={classes.businessLogo}
                    src={brandImage}
                    alt="logo"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="primary" variant="body2">
                  Business Name:
                </Typography>
                <Typography variant="body1">
                  {locationData?.businessname}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="primary" variant="body2">
                  Product Name:
                </Typography>
                <Typography variant="body1">
                  {productData?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={1}>
                <Typography color="primary" variant="body2">
                  Price:
                </Typography>
                <Typography variant="body1" className={classes.priceText}>
                  <span>{StripeCurrency?.find((c) => c?.value === currencyValue)?.symbol} {productData?.salesprice || 0} </span>
                  <span className={classes.currencyText}>({StripeCurrency?.find((c) => c?.value === currencyValue)?.value})</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <CardActionArea className={classes.background}>
            <center>
              <img
                className={classes.mediaDetails}
                src={productData?.imgurl}
                alt="img"
              />
            </center>
            <hr />
            <CardContent>
              <Grid container spacing={2} direction="row" justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Box className={classes.prod}>
                    <Typography gutterBottom variant="h4" component="h4">
                      {productData?.name}
                    </Typography>
                    <Box className={classes.price}>
                      <ShoppingCartOutlinedIcon fontSize="large" color="primary" />
                      &nbsp;
                      {productData?.salesprice > 0 ? (
                        <>
                          <Typography variant="h6" className={classes.priceTypo}>
                            <span>{StripeCurrency?.find((c) => c?.value === currencyValue)?.symbol} {productData?.salesprice} </span>
                            <span className={classes.currencySpan}>({StripeCurrency?.find((c) => c?.value === currencyValue)?.value})</span>
                          </Typography>
                          &nbsp;
                          <Typography variant="subtitle1">
                            <strike>
                              <span>{StripeCurrency?.find((c) => c.value === currencyValue)?.symbol} {productData?.actualprice} </span>
                              <span className={classes.currencyText}>({StripeCurrency?.find((c) => c?.value === currencyValue)?.value})</span>
                            </strike>
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="h6" color="primary">
                            Free
                          </Typography>
                          &nbsp;&nbsp;
                          {productData?.actualprice > 0 ? (
                            <Typography variant="subtitle1" className={classes.freeSpan}>
                              <strike>
                                <span>{StripeCurrency?.find((c) => c.value === currencyValue)?.symbol} {productData?.actualprice} </span>
                                <span className={classes.currencyText}>({StripeCurrency?.find((c) => c?.value === currencyValue)?.value})</span>
                              </strike>
                            </Typography>
                          ) : ''}
                        </>
                      )}
                    </Box>
                  </Box>
                  <br />
                  <Typography variant="body1" color="textSecondary" component="p">
                    {productData?.shortdiscription}
                  </Typography>
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: productData?.longdiscription }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {visible === false ? (
                    <>
                      <div>
                        <Suspense fallback={<LazyLoader />}>
                          <Contactinfo
                            createContact={createContact}
                            productData={productData}
                          />
                        </Suspense>
                      </div>
                    </>
                  ) : visible === true && productData?.salesprice > 0 && thankYou === false ? (
                    <Suspense fallback={<LazyLoader />}>
                      <Payment
                        product={productData?.name}
                        userId={uid}
                        businessId={uid}
                        locationId={lid}
                        objectId={pid}
                        currencyValue={StripeCurrency?.find((c) => c.value === currencyValue)?.value}
                        setThankYou={setThankYou}
                        contactData={contactData}
                        amount={productData?.salesprice}
                        setPaymentId={setPaymentId}
                      />
                    </Suspense>
                  ) : ''}
                </Grid>
              </Grid>
            </CardContent>
            <br />
          </CardActionArea>
        </Card>
      </Box>
      {enablePublicLogo ? <PoweredBy id={locationData?.entityid} /> : ''}
    </>
  );
}
