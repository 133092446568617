import React from 'react';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { postDataRequest, handleUndefined, getDataRequest } from '../common/commonfunctions';
import { Roles, clearCookie, setCookie } from '../common/const';
import { SignUp } from '../Api Mutation/Login';
import config from '../config';
import PopupMessage from '../pages/PopupMessage/PopupMessage';
import Message from '../pages/Messages/Message';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const header = {
  'Content-Type': 'application/json',
  'Apollo-Require-Preflight': 'true',
};

function userReducer(state, action) {
  switch (action.type) {
    case 'SET_USER_INFO':
      return { ...state, userInfo: action.payload };
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false };
    case 'LANDING_TO_BUSINESS':
      return { ...state, isSetToBusiness: true };
    case 'SET_LAST_LOGIN':
      return { ...state, lastLogin: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('token'),
    userInfo: jwt.decode(localStorage.getItem('token')),
    lastLogin: null,
  });
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}
function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}
function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}
async function retrivePaymentList() {
  const response = await getDataRequest(`${config.payment}v1/payments/retrive-payment-method/${JSON.parse(localStorage.getItem('business_Id'))}`);
  return response;
}
async function retriveInvoice() {
  const response = await getDataRequest(`${config.payment}v1/payments/retrieve-upcoming-invoice/${JSON.parse(localStorage.getItem('business_Id'))}`);
  return response;
}

export function receiveToken(token, dispatch) {
  const user = jwt.decode(token);
  localStorage.setItem('token', token);
  localStorage.setItem('bar', true);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('userid', JSON.stringify(user.userid));
  setCookie('user_Id', user?.userid, 365);
  localStorage.setItem('theme', 'default');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  dispatch({ type: 'LOGIN_SUCCESS' });
}

// ###########################################################
function loginUser(
  dispatch,
  userData,
  history,
  setIsLoading,
  social = '',
  getLoginStatus,
) {
  localStorage.removeItem('location_Id');
  clearCookie('location_Id');
  setIsLoading(true);
  if (social !== undefined && social !== '') {
    axios
      .post(
        config.baseURLApi,
        {
          query: `mutation{socialSignIn(email:"${userData.email}", profiletype:${social}, 
            firstname:"${userData.firstname}", lastname:"${userData.lastname}", 
            avatar:"${userData.avatar}"){token}}`,
        },
        {
          headers: header,
        },
      )
      .then((res) => {
        const { token } = res.data.data.socialSignIn;
        getLoginStatus(true);
        dispatch({ type: 'SET_USER_INFO', payload: jwt.decode(token) });
        setTimeout(() => {
          setIsLoading(false);
          receiveToken(token, dispatch);
        }, 2000);
      })
      .catch(() => {
        getLoginStatus('error');
        setIsLoading(false);
      });
  } else if (userData) {
    axios
      .post(
        config.baseURLApi,
        {
          query: `mutation{signIn(login:"${userData.email}" password:"${userData.Password}"){
                        token
                        lastlogin
                        isenabletwofactorauth
                    }}`,
        },
        {
          headers: header,
        },
      )
      .then((res) => {
        if (res.data.errors && res.data.errors[0].message) {
          PopupMessage(res.data.errors[0].message, true);
        }
        if (res?.data?.data?.signIn?.token) {
          PopupMessage(Message.loginSuccessMessage);
        }
        const { token, lastlogin } = res.data.data.signIn;
        localStorage.setItem('isemailVerify', 'bixpand');
        dispatch({ type: 'SET_USER_INFO', payload: jwt.decode(token) });
        dispatch({ type: 'SET_LAST_LOGIN', payload: handleUndefined(lastlogin) });
        const timerId = setTimeout(() => {
          setIsLoading(false);
          receiveToken(token, dispatch);
        }, 2000);
        if (!handleUndefined(lastlogin) && jwt.decode(token)?.parentid !== null) {
          PopupMessage('Please update your password');
        }
        return () => {
          clearTimeout(timerId);
        };
      })
      .catch(() => {
        setIsLoading(false);
      });
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    getLoginStatus('error');
  }
}
// ----------------signup------------------------------------------
function signUpUser(
  dispatch,
  userData,
  history,
  setIsLoading,
  setError,
  social = '',
  getSignUpStatus,
) {
  setError(false);
  setIsLoading(true);
  if (social) {
    axios
      .post(
        config.baseURLApi,
        {
          query: `mutation{socialSignUp(email:"${userData.email}" firstname:"${userData.firstname}" lastname:"${userData.lastname}" avatar:"${userData.avatar}" profiletype:${userData.profiletype})}`,
        },
        {
          headers: header,
        },
      )
      .then((res) => {
        if (res.data.data.socialSignUp) {
          getSignUpStatus(true);
          axios
            .post(
              config.baseURLApi,
              {
                query: `mutation{socialSignIn(email:"${userData.email}", profiletype:${social}, 
            firstname:"${userData.firstname}", lastname:"${userData.lastname}", 
            avatar:"${userData.avatar}"){token, lastlogin}}`,
              },
              {
                headers: header,
              },
            )
            .then((socialSignIn) => {
              const { token } = socialSignIn.data.data.socialSignIn;
              dispatch({
                type: 'SET_USER_INFO',
                payload: jwt.decode(token),
              });
              setTimeout(() => {
                setError(null);
                setIsLoading(false);
                receiveToken(token, dispatch);
              }, 2000);
            })
            .catch(() => {
              setError(true);
              setIsLoading(false);
            });
        }
        setTimeout(() => {
          setError(null);
          setIsLoading(false);
        }, 2000);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  } else if (userData) {
    axios
      .post(
        config.baseURLApi,
        {
          query: SignUp,
          variables: {
            businessname: userData?.BusinessName ? userData?.BusinessName : '',
            firstname: userData?.FirstName ? userData?.FirstName : '',
            lastname: userData?.LastName ? userData?.LastName : '',
            phonenumber: userData?.phonenumber ? userData?.phonenumber : '',
            usertype: userData?.UserType,
            email: userData?.email,
            password: userData.Password,
            referby: userData?.referid ? userData?.referid : null,
            partneruserid: userData?.partneruserid ? userData?.partneruserid : null,
            fromEntity: userData.fromEntity,
          },
        },
        {
          headers: header,
        },
      )
      .then((res) => {
        if (res.data.errors && res.data.errors[0].message) {
          PopupMessage(res.data.errors[0].message.replace('SequelizeUniqueConstraintError: This changed email is already a registered profile in Bixpand.com. Please use a different email for your profile', ' This email is already registered in Bixpand.com. Please Log in with the same'), true);
        }
        if (res?.data?.data?.signUp) {
          PopupMessage(Message.SignupSuccessMessage);
          const { token } = res.data.data.signUp;
          dispatch({
            type: 'SET_USER_INFO',
            payload: jwt.decode(token),
          });
          setTimeout(() => {
            setError(null);
            setIsLoading(false);
            receiveToken(token, dispatch);
          }, 2000);
        }
        setTimeout(() => {
          setError(null);
          setIsLoading(false);
        }, 2000);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  } else {
    dispatch({ type: 'SIGNUP_FAILURE' });
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('userid');
  localStorage.removeItem('business_Data')
  clearCookie('user_Id');
  localStorage.removeItem('location_Id');
  clearCookie('location_Id');
  clearCookie('business_Id');
  localStorage.clear();
  document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  axios.defaults.headers.common.Authorization = '';
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
  window.location.reload(false);
}
export function createAddressUser(
  address,
  countryId,
  stateId,
  userId,
  handleGetAddress,
) {
  const state = stateId;
  postDataRequest(config.baseURLApi, {
    query: `
      mutation createAddress(
        $street1: String
        $street2: String
        $city: String
        $stateid: Int
        $state: String
        $countryid: Int!
        $zip: String
        $createdby: ID
        $userid: ID!
        ){
        createAddress(
        street1:$street1,
        street2:$street2,
        city:$city,
        countryid:$countryid,
        stateid:$stateid,
        state:$state,
        zip:$zip,
        createdby:$createdby
        userid:$userid
    ){
        addressid
        street1
        street2
        city
        stateid
        state
        countryid
        zip
        user{
            userid
        }
}
      }`,
    variables: {
      street1: address?.Address1 || null,
      street2: address?.Address2 || null,
      city: address?.City || null,
      countryid: JSON.parse(countryId),
      stateid: typeof stateId === 'number' ? JSON.parse(stateId) : 0,
      state: typeof stateId !== 'number' ? state : null,
      zip: address.Zip || null,
      userid: userId,
      createdby: userId,
    },
  })
    .then((res) => {
      if (res.errors && res.errors[0].message) {
        PopupMessage(res.errors[0].message, true);
      }
      if (res?.data?.createAddress) {
        handleGetAddress(true);
        PopupMessage(Message.entityDataChangeMessage);
      }
    })
    .catch(() => {
      handleGetAddress('error');
    });
}
export function createUser(userData, handleGetUserId) {
  const CREATE_USER_MUTATION = `
  mutation createUser(
     $usertype:Int!
      $firstname:String!,
      $lastname:String,
      $phonenumber:String,
      $email:String,
      $avatar:String,
      $profiletype: Int!
      $datavisibility: Int
      $roleId:Int!
      $businessId: ID
      $locationId: ID
      $isHideBusinessDetails: Boolean
      $websiteurl: String
    ) {
      createUser(
         usertype:$usertype,firstname: $firstname, lastname: $lastname, phonenumber: $phonenumber,
        email: $email, avatar: $avatar,profiletype:$profiletype,datavisibility:$datavisibility, roleid:$roleId, businessid: $businessId,
        locationid: $locationId, ishidebusinessdetails: $isHideBusinessDetails, websiteurl: $websiteurl
      ){userid}
    }`;
  postDataRequest(config.baseURLApi, {
    query: CREATE_USER_MUTATION,
    variables: {
      usertype: userData.userType,
      firstname: userData.firstName,
      lastname: userData.lastName,
      phonenumber: userData.phonenumber,
      email: userData.email,
      avatar: userData.avatar,
      profiletype: 1,
      datavisibility: userData.datavisibility,
      roleId: userData.roleId,
      locationId: userData.locationId,
      businessId: userData.businessId,
      isHideBusinessDetails: userData.isHideBusinessDetails,
      websiteurl: userData.websiteurl,
    },
  })
    .then((res) => {
      if (res?.data?.createUser) {
        if (userData.roleId === Roles?.AgencyManager) {
          postDataRequest(config.baseURLApi, {
            query: `
                    mutation createuseragency($userId:ID!,$agencyId:ID!){
                      createuseragency(userid:$userId
                        agencyid:$agencyId
                          ){
                            userid
                            agencyid
                          }
                        }
                                  `,
            variables: {
              userId: res?.data?.createUser?.userid,
              agencyId: userData?.agencyId,
            },
          });
        }
        PopupMessage(Message.entityDataChangeMessage);
        handleGetUserId(res.data.createUser.userid);
      } else if (res?.errors) {
        PopupMessage(res?.errors[0]?.message, true);
      }
    })
    .catch(() => { });
}
export function editUser(userData) {
  if (userData?.setDataLoading) { userData.setDataLoading(true); }
  const EDIT_USER_MUTATION = `
  mutation editUser(
      $userid: ID!,
      $firstname:String!,
      $lastname:String,
      $phonenumber:String,
      $email:String,
      $avatar:String,
      $roleId:Int,
      $datavisibility: Int,
      $isHideBusinessDetails: Boolean,
      $isenabletwofactorauth: Boolean,
    ) {
      editUser(
        userid: $userid, firstname: $firstname, lastname: $lastname, phonenumber: $phonenumber,
        email: $email, avatar: $avatar,datavisibility: $datavisibility, roleid:$roleId, ishidebusinessdetails: $isHideBusinessDetails,
        isenabletwofactorauth: $isenabletwofactorauth,
      ) 
    }`;
  postDataRequest(config.baseURLApi, {
    query: EDIT_USER_MUTATION,
    variables: {
      userid: userData.userid,
      firstname: userData.firstname,
      lastname: userData.lastname,
      phonenumber: userData.phonenumber,
      email: userData.email,
      datavisibility: userData.datavisibility,
      avatar: userData.avatar,
      roleId: userData?.roleId,
      isHideBusinessDetails: userData?.isHideBusinessDetails,
      isenabletwofactorauth: userData?.isenabletwofactorauth,
    },
  })
    .then((res) => {
      if (userData.setDataLoading) { userData.setDataLoading(false); }
      if (
        res.errors
        && res.errors[0].extensions
        && res.errors[0].extensions.exception.errors[0].message
      ) {
        PopupMessage(
          res.errors[0].extensions.exception.errors[0].message,
          true,
        );
      }
      if (res.data.editUser) {
        if (userData?.handleEntityStatus) {
          userData.handleEntityStatus(res.data.editUser);
        }
        PopupMessage(Message.entityDataChangeMessage);
      } else if (res?.errors) {
        PopupMessage(res?.errors[0]?.message, true);
      }
    })
    .catch(() => {
      if (userData?.setDataLoading) { userData.setDataLoading(false); }
      if (userData.handleEntityStatus) { userData.handleEntityStatus('error'); }
    });
}
export function editAddressUser(
  addressId,
  address,
  userId,
  countryId,
  stateId,
  handleGetAddress,
) {
  const state = stateId;
  postDataRequest(config.baseURLApi, {
    query: `
    mutation editAddress(
        $addressid: ID!
        $street1: String
        $street2: String
        $city: String
        $stateid: Int
        $state: String
        $countryid: Int!
        $zip: String
        $updatedby: ID
        $userid: ID!
      ){
    editAddress(
        addressid:$addressid,
      street1:$street1,
      street2:$street2,
      city:$city,
      countryid:$countryid,
      stateid:$stateid,
      state:$state,
      zip:$zip,
      userid:$userid
      updatedby:$updatedby
      )
    }`,
    variables: {
      addressid: addressId,
      street1: address?.Address1 || null,
      street2: address?.Address2 || null,
      city: address?.City || null,
      countryid: JSON.parse(countryId),
      stateid: typeof stateId === 'number' ? JSON.parse(stateId) : 0,
      state: typeof stateId !== 'number' ? state : null,
      zip: address.Zip || null,
      userid: userId || null,
      updatedby: userId || null,
    },
  })
    .then((res) => {
      if (res?.errors && res?.errors[0]?.message) {
        PopupMessage(res?.errors[0]?.message, true);
      }
      if (res?.data?.editAddress[0]) {
        handleGetAddress(true);
        PopupMessage(Message.entityDataChangeMessage);
      }
    })
    .catch(() => {
      handleGetAddress('error');
    });
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signUpUser,
  signOut,
  retrivePaymentList,
  retriveInvoice,
};
