import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { postDataRequestPublic } from '../../common/commonfunctions';
import config from '../../config';

export default function ContentPublicUrl() {
  const { content } = useParams();
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      postDataRequestPublic(config.url, {
        query: `
             query urlbycode($sorturlcode: String!){
                  urlbycode(sorturlcode:$sorturlcode){
                      fullurl,
                  }
              }`,
        variables: {
          sorturlcode: `${config.BixUrl}/contents/${content?.replace(/\s/g, '-')?.trim()?.toLocaleLowerCase()}`,
        },
      }).then((res) => {
        if (res?.data?.urlbycode?.fullurl) {
          window.open(res?.data?.urlbycode?.fullurl, '_self');
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [content]);
  return <div />;
}
