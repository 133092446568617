import React, { useState, useEffect } from 'react';
import {
  Typography, TextField, Grid, Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';
import { postDataRequestPublic, handleUndefined } from '../../../common/commonfunctions';
import config from '../../../config';

import {
  useGlobalState,
  setLoginUserInfo,
} from '../../../context/GlobalContext';
import { useEntitiesState } from '../../../context/EntityContext';

const StyledRating = withStyles({
  iconFilled: {
    color: '#0259a8',
  },
  iconHover: {
    color: '#0259a8',
  },
  iconEmpty: {
    color: '#74b6f7',
  },
  sizeLarge: {
    fontSize: '3rem',
  },
})(Rating);
export default function RatingPositiveLanding({
  activeRating,
  brandInfo,
  reviewMsg,
  handleRating,
  handleReviewMsg,
  handletitle,
  title,
  error,
  userName,
}) {
  const classes = useStyles();
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const [entity, setEntity] = useState([]);
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const bid = params.get('bid');
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoginUserInfo(globalContext.setGlobalState);
    }
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (entityContext?.entities?.businessData
        && entityContext?.entities?.businessData[0]?.entityid) {
        postDataRequestPublic(config.baseURLApi, {
          query: `query entity($entityid:ID!){
                entity(entityid:$entityid){
                  entityid
                  businessname
                  brandname
                  firstname
                  lastname
                  email
                  phonenumber
                  entitytypeid
                  businesstypeid
                  jobid
                  tags
                  website
                  shortdesc
                  fulldesc
                  policy
                  terms
                  }}`,
          variables: {
            entityid:
                    entityContext?.entities?.businessData
                    && entityContext?.entities?.businessData[0]?.entityid,
          },
        })
          .then((res) => {
            if (res.data && res.data.entity) {
              setEntity(res.data.entity);
            }
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    entityContext?.entities?.businessData
            && entityContext?.entities?.businessData[0]?.entityid,
  ]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted && bid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `query entity($entityid:ID!){
                entity(entityid:$entityid){
                  entityid
                  businessname
                  brandname
                  firstname
                  lastname
                  email
                  phonenumber
                  entitytypeid
                  businesstypeid
                  jobid
                  tags
                  website
                  shortdesc
                  fulldesc
                  policy
                  terms
                  }}`,
        variables: {
          entityid: bid,
        },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setEntity(res?.data?.entity);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [bid]);

  const handlePolicy = () => {
    window.open(entity.policy, '_blank');
  };
  const handleTerms = () => {
    window.open(entity.terms, '_blank');
  };
  return (
    <>
      <center>
        {brandInfo?.logo ? (
          <>
            <img
              className={classes.large2}
              src={brandInfo?.logo}
              alt="brandImage"
            />
          </>
        ) : ''}
        <br />
        <Typography variant="h6">{brandInfo?.brandname}</Typography>
        <br />
      </center>
      <Box className={classes.positiveExperienceCard}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h1" className={classes.marginZero}>
            <b>
              Review your Experience. Your review matters !!!
            </b>
          </Typography>
        </Box>
      </Box>
      <Grid container justifyContent="center" className={classes.positiveFeedBackBox}>
        <Grid item md={5} xs={12}>
          <Typography
            variant="body2"
            className={classes.addMg}
          >
            <b>Your rating</b>
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <StyledRating
              name="customized-color size-large"
              value={activeRating}
              onChange={(value) => handleRating(value.target.value)}
              precision={1}
              size="large"
              readOnly={!handleUndefined(activeRating)}
            />
          </Box>
        </Grid>
        <Grid item md={2} />
        <Grid item md={5} xs={12}>
          <Typography variant="body2" className={classes.titleText}>
            <b>Title of your review</b>
          </Typography>
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={handletitle}
            className={classes.fieldColor}
          />
          {error && (
            <>
              {!title && (
              <Typography
                variant="subtitle2"
                component="p"
                color="error"
                gutterBottom
              >
                Title is required
              </Typography>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.titleText}>
            <b>Your review</b>
          </Typography>
          <Box>
            <TextField
              id="outlined-multiline-static"
              label="Share your honest experience, and help others make better choices."
              multiline
              placeholder={`You have rated ${entity?.businessname} service ${activeRating} stars. Could you tell them what they did well? Would you recommend them or buy from them again?`}
              minRows={10}
              variant="outlined"
              className={classes.fieldColor}
              fullWidth
              value={reviewMsg}
              onChange={handleReviewMsg}
            />
            {error && (
            <>
              {!reviewMsg && (
              <Typography
                variant="subtitle2"
                className={classes.titleText}
                component="p"
                color="error"
                gutterBottom
              >
                Review Message is required
              </Typography>
              )}
            </>
            )}
          </Box>

          <Box display="flex" alignItems="center" marginTop={2}>
            <Typography variant="body2">
              Your name:
            </Typography>
            &nbsp;
            <Typography variant="body1">
              {userName || '<<customer Name>>'}
            </Typography>
          </Box>

        </Grid>
      </Grid>
      <center>
        <Box
          marginTop={1}
          marginBottom={1}
          display="flex"
          justifyContent="center"
        >
          {handleUndefined(entity.policy) && (
            <>
              <Typography
                variant="subtitle2"
                onClick={handlePolicy}
              >
                {' '}
                Privacy Policy
              </Typography>
              &nbsp;/&nbsp;
            </>
          )}
          {' '}
          {handleUndefined(entity.terms) && (
            <Typography
              variant="subtitle2"
              onClick={handleTerms}
            >
              Terms & Conditions
            </Typography>
          )}
        </Box>
      </center>
    </>
  );
}
