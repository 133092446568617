export const UploadEntityPhoto = `mutation uploadEntityPhoto($entityid: ID!, $logo: Upload!) {
    uploadEntityPhoto(entityid: $entityid, logo: $logo)
  }`;

export const UploadEntityFavicon = `mutation uploadEntityFavicon($entityid: ID!, $favicon: Upload!) {
    uploadEntityFavicon(entityid: $entityid, favicon: $favicon)
  }`;

export const Entity = `query entity($entityid: ID!) {
  entity(entityid: $entityid) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    defaultcountrycode
    defaultphonecountrycode
    address {
      addressid
      street1
      street2
      city
      stateid
      state
      countryid
      zip
      createdby
      updatedby
    }
    ownerid
    totalreview
    nps
    website
    subdomain
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    publiclogotype
    referralcoupon
    referralcode
    referredby
    favicon
    whitelabeltype
    whitelabeldomain
  }
}`;

export const EntityPolicy = `query entity($entityid: ID!) {
  entity(entityid: $entityid) {
    policy
    terms
    whitelabeltype
    businessname
    brandname
    logourl
    favicon
    logourl
    favicon
    businessname
    brandname
    parententityid
  }
}`;

export const BrandSettingByLocation = `query brandSettingByLocation($locationid:ID!)
{brandSettingByLocation(locationid:$locationid){color,dateformat,timeformat,logourl}}
`;
export const Entitybysubdomain = `query entitybysubdomain($subdomain: String) {
  entitybysubdomain(subdomain: $subdomain) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    address {
      addressid
      street1
      street2
      city
      stateid
      state
      countryid
      zip
      createdby
      updatedby
    }
    ownerid
    totalreview
    nps
    website
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    subdomain
  }
}`;

export const Entitiesbyparentid = `query entitiesbyparentid($parententityid: ID!) {
  entitiesbyparentid(parententityid: $parententityid) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    ownerid
    totalreview
    nps
    website
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    subdomain
    whitelabeldomain
    whitelabeltype
  }
}`;

export const CreateAdditionalImages = `mutation createAdditionalImages($createAdditionalImagesLocationid2: ID, $businessid: ID, $documents: [Upload], $createdby: ID) {
  createAdditionalImages(locationid: $createAdditionalImagesLocationid2, businessid: $businessid, documents: $documents, createdby: $createdby) {
    imagesid
    locationid
    businessid
    documentsurl
    filetype
    createdby
    updatedby
  }
}`;

export const Entitiesbytype = `query Entitiesbytype($entitytypeid: Int!) {
  entitiesbytype(entitytypeid: $entitytypeid) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    defaultcountrycode
    address {
      addressid
      street1
      street2
      city
      stateid
      state
      countryid
      zip
      createdby
      updatedby
    }
    ownerid
    totalreview
    nps
    website
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    subdomain
    bixfeepercentage
  }
}`;

export const AdditionalImageslocation = `query additionalImageslocation($locationid: ID!) {
  additionalImageslocation(locationid: $locationid) {
    imagesid
    locationid
    businessid
    documentsurl
    filetype
    createdby
    updatedby
  }
}`;

export const DeleteAdditionalImages = `mutation deleteAdditionalImages($imagesid: ID!) {
  deleteAdditionalImages(imagesid: $imagesid)
}`;

export const CreateCoverpage = `mutation createCoverpage($locationid: ID, $businessid: ID, $timeavailability: [TimeAvailabilityInput!], $createdby: ID) {
  createCoverpage(locationid: $locationid, businessid: $businessid, timeavailability: $timeavailability, createdby: $createdby) {
    coverpageid
    locationid
    businessid
    day
    starttime
    endtime
    createdby
    updatedby
  }
}`;

export const UpdateCoverpage = `mutation updateCoverpage($entityid: ID!, $coverpageurl: [Upload]) {
  updateCoverpage(entityid: $entityid, coverpageurl: $coverpageurl)
}`;

export const Coverpagebylocation = `query coverpagebylocation($locationid: ID!) {
  coverpagebylocation(locationid: $locationid) {
    coverpageid
    locationid
    businessid
    day
    starttime
    endtime
    createdby
    updatedby
  }
}`;

export const Entityaddressbyentityid = `query entityaddressbyentityid($entityid: ID!) {
  entityaddressbyentityid(entityid: $entityid) {
    addressid
    street1
    street2
    city
    stateid
    state
    countryid
    zip
    createdby
    updatedby
    entity {
      entityid
    }
    AddressCountry {
      countryid
      name
    }
    AddressState {
      stateid
      name
    }
  }
}`;

export const EditBrandSetting = `mutation editBrandSetting($brandsettingid: ID!, $updatedby: ID!, $logourl: String, $color: String, $timeformat: String, $dateformat: String, $facebook: String, $twitter: String, $gmb: String, $includedisclaimer: Boolean, $yelp: String, $linkedin: String, $tripadvisor: String, $disclaimer: String) {
  editBrandSetting(brandsettingid: $brandsettingid, updatedby: $updatedby, logourl: $logourl, color: $color, timeformat: $timeformat, dateformat: $dateformat, facebook: $facebook, twitter: $twitter, gmb: $gmb, includedisclaimer: $includedisclaimer, yelp: $yelp, linkedin: $linkedin, tripadvisor: $tripadvisor, disclaimer: $disclaimer)
}`;

export const Entitiesbytypewithoutuser = `query entitiesbytypewithoutuser($entitytypeid: Int!) {
  entitiesbytypewithoutuser(entitytypeid: $entitytypeid) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    ownerid
    totalreview
    nps
    website
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    subdomain
    bixfeepercentage
    publiclogotype
    referredby
    referralcode
    referralcoupon
  }
}`;

export const Businessusagebybusinessidandtype = `query businessusagebybusinessidandtype($businessid: ID!, $plantype: [Int!]) {
  businessusagebybusinessidandtype(businessid: $businessid, plantype: $plantype) {
    businessusageid
    businessid
    locationid
    trialplanid
    curruntplanid
    subscriptionid
    isfreeplan
    plantype
    paymentid
    userslimit
    textsmslimit
    emaillimit
    storagelimit
    calltimelimit
    textsms
    users
    email
    storage
    calltime
    leadgatheringlimit
    leadgathering
    trialplanenddate
    startdate
    enddate
    isfullaccess
    isactive
    deleted
    isunlimitedusers
    isunlimitedtextsms
    isunlimitedemail
    isunlimitedstorage
    isunlimitedcalltime
    isunlimitedleadgathering
    isbusinessverified
    socialpostlimit
    socialpost
    isunlimitedsocialpost
  }
}`;

export const Businessusagebybusinesswithtype = `query businessusagebybusinesswithtype($businessid:ID!,$plantype: [Int!]){
  businessusagebybusinesswithtype(businessid:$businessid, plantype:$plantype){  
    businessid,trialplanid,curruntplanid,subscriptionid,isfreeplan,plantype,paymentid,userslimit,textsmslimit,emaillimit,
    storagelimit,calltimelimit,textsms,users,email,storage,calltime,leadgatheringlimit,leadgathering,trialplanenddate,startdate,enddate,
    isactive,deleted,
          }
  }`;

export const Updatebusinessusagebyid = `mutation updatebusinessusagebyid($businessusageid: ID!, $trialplanid: Int, $curruntplanid: Int, $subscriptionid: ID, $isfreeplan: Boolean, $paymentid: String, $plantype: Int, $isactive: Boolean, $deleted: Boolean, $textsmslimit: Int, $emaillimit: Int, $storagelimit: Int, $calltimelimit: Int, $textsms: Int, $email: Int, $storage: Float, $calltime: Int, $userslimit: Int, $users: Int, $leadgathering: Int, $leadgatheringlimit: Int, $isfullaccess: Boolean, $trialplanenddate: String, $startdate: String, $enddate: String, $isunlimitedusers: Boolean, $isunlimitedtextsms: Boolean, $isunlimitedemail: Boolean, $isunlimitedstorage: Boolean, $isunlimitedcalltime: Boolean, $isunlimitedleadgathering: Boolean, $socialpostlimit: Int, $socialpost: Int, $isunlimitedsocialpost: Boolean) {
  updatebusinessusagebyid(businessusageid: $businessusageid, trialplanid: $trialplanid, curruntplanid: $curruntplanid, subscriptionid: $subscriptionid, isfreeplan: $isfreeplan, paymentid: $paymentid, plantype: $plantype, isactive: $isactive, deleted: $deleted, textsmslimit: $textsmslimit, emaillimit: $emaillimit, storagelimit: $storagelimit, calltimelimit: $calltimelimit, textsms: $textsms, email: $email, storage: $storage, calltime: $calltime, userslimit: $userslimit, users: $users, leadgathering: $leadgathering, leadgatheringlimit: $leadgatheringlimit, isfullaccess: $isfullaccess, trialplanenddate: $trialplanenddate, startdate: $startdate, enddate: $enddate, isunlimitedusers: $isunlimitedusers, isunlimitedtextsms: $isunlimitedtextsms, isunlimitedemail: $isunlimitedemail, isunlimitedstorage: $isunlimitedstorage, isunlimitedcalltime: $isunlimitedcalltime, isunlimitedleadgathering: $isunlimitedleadgathering, socialpostlimit: $socialpostlimit, socialpost: $socialpost, isunlimitedsocialpost: $isunlimitedsocialpost)
}`;

export const UpdateEntity = `mutation updateEntity($entityid: ID!, $businessname: String, $brandname: String, $firstname: String, $policy: String, $terms: String, $displaylocationdoncampaign: Boolean, $lastname: String, $businesstypeid: ID, $updatedby: ID, $email: String, $phonenumber: String, $entitytypeid: Int, $parententityid: ID, $jobid: String, $tags: String, $website: String, $totalreview: Float, $nps: Float, $shortdesc: String, $status: Int, $trialplanid: Int, $paidplanid: Int, $iscreatedagency: Boolean, $isfreebusiness: Boolean, $isbusinessverified: Boolean,$whitelabeltype: String, $trialplanenddate: String, $subdomain: String,$whitelabeldomain: String, $bixfeepercentage: Int, $referredby: ID, $fulldesc: String, $referralcoupon: String, $phonecountryid: ID, $iscallrecording: Boolean, $publiclogotype: String) {
  updateEntity(entityid: $entityid, businessname: $businessname, brandname: $brandname, firstname: $firstname, policy: $policy, terms: $terms, displaylocationdoncampaign: $displaylocationdoncampaign, lastname: $lastname, businesstypeid: $businesstypeid, updatedby: $updatedby, email: $email, phonenumber: $phonenumber, entitytypeid: $entitytypeid, parententityid: $parententityid,whitelabeldomain:$whitelabeldomain, jobid: $jobid, tags: $tags, website: $website, totalreview: $totalreview, nps: $nps, shortdesc: $shortdesc, status: $status, trialplanid: $trialplanid, paidplanid: $paidplanid, iscreatedagency: $iscreatedagency, isfreebusiness: $isfreebusiness, isbusinessverified: $isbusinessverified, trialplanenddate: $trialplanenddate, subdomain: $subdomain, bixfeepercentage: $bixfeepercentage, referredby: $referredby, fulldesc: $fulldesc, referralcoupon: $referralcoupon, phonecountryid: $phonecountryid, iscallrecording: $iscallrecording, publiclogotype: $publiclogotype,whitelabeltype:$whitelabeltype)
}`;

export const BusinesswithLocationlist = `{businesswithlocationlist{
  businessid,bbusinessname,bbrandname,bfirstname,blastname,bemail,bentitytypeid,locationid,lbusinessname,
  lbrandname,lfirstname,llastname,lemail,lentitytypeid,lcreatedby}}`;

export const EditUser = `
  mutation editUser(
      $userid: ID!,
      $firstname:String!,
      $lastname:String,
      $phonenumber:String,
      $email:String,
      $avatar:String,
      $roleId:Int,
      $datavisibility: Int,
      $isHideBusinessDetails: Boolean,
      $isenabletwofactorauth: Boolean,
    ) {
      editUser(
        userid: $userid, firstname: $firstname, lastname: $lastname, phonenumber: $phonenumber,
        email: $email, avatar: $avatar,datavisibility: $datavisibility, roleid:$roleId, ishidebusinessdetails: $isHideBusinessDetails,
        isenabletwofactorauth: $isenabletwofactorauth,
      ) 
    }`;

export const EditBrandSettingById = `mutation EditBrandSetting($brandsettingid: ID!, $updatedby: ID!, $logourl: String, $color: String, $timeformat: String, $dateformat: String, $linkedin: String, $twitter: String, $facebook: String, $gmb: String, $yelp: String, $tripadvisor: String, $disclaimer: String, $includedisclaimer: Boolean) {
  editBrandSetting(brandsettingid: $brandsettingid, updatedby: $updatedby, logourl: $logourl, color: $color, timeformat: $timeformat, dateformat: $dateformat, linkedin: $linkedin, twitter: $twitter, facebook: $facebook, gmb: $gmb, yelp: $yelp, tripadvisor: $tripadvisor, disclaimer: $disclaimer, includedisclaimer: $includedisclaimer)
}`

export const EditEntityAddress = `mutation EditEntityAddress($addressid: ID!, $countryid: Int!, $entityid: ID!, $street1: String, $street2: String, $city: String, $stateid: Int, $state: String, $zip: String, $updatedby: ID) {
  editEntityAddress(addressid: $addressid, countryid: $countryid, entityid: $entityid, street1: $street1, street2: $street2, city: $city, stateid: $stateid, state: $state, zip: $zip, updatedby: $updatedby)
}`;