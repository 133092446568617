/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField as Input,
  Typography,
} from '@material-ui/core';
import {
  Inbox as InboxIcon,
  ExpandMore as ExpandIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import useStyles from './styles';
import useStyles2 from '../../styles';
import Dot from '../Dot';
import { Button, Badge } from '../../../Wrappers';
import Chat from '../../../Chat';
import { checkAccess } from '../../../../common/commonfunctions';
import { useUserState } from '../../../../context/UserContext';

export default function SidebarLink({
  link,
  icon,
  iconFill,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  userPermissions,
  home,
  settings,
  professionalServices,
  reputationCentral,
  prospectCentral,
  marketingCentral,
  promotionCentral,
  productService,
  insightsCentral,
  leadCentral,
  salesCentral,
  toggleDrawer,
  click,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { userInfo } = useUserState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isChat, setChat] = useState(false);
  function onLogin() {
    localStorage.removeItem('token');
    window.location.reload();
  }
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsOpen(props.openSideBar === link);
    }
    return () => {
      isMounted = false;
    };
  }, [props.openSideBar, link]);

  onLogin.clickName = 'onLogin';
  const classes = useStyles({ isOpen });
  const classes2 = useStyles2();
  const isLinkActive = link
    && (location.pathname === link || location.pathname.includes(link));
  if (type === 'title') {
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );
  }
  if (type === 'divider') return <Divider className={classes.divider} />;
  if (type === 'margin') return <section style={{ marginTop: 240 }} />;
  const open = Boolean(anchorEl);
  const id = open ? 'add-section-popover' : undefined;

  function addSectionClick(event) {
    setAnchorEl(event.currentTarget);
  }

  addSectionClick.clickName = 'addSectionClick';

  const addSectionClose = () => {
    setAnchorEl(null);
  };
  function chatSetOpen() {
    setChat(true);
  }
  chatSetOpen.clickName = 'chatSetOpen';

  const chatSetClose = () => {
    setChat(false);
  };
  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
      props.setOpenSideBar(link);
    }
  }
  if (!children) {
    return (
      <>
        <ListItem
          onClick={(e) => {
            if (click) {
              return click(
                e,
                addSectionClick,
                chatSetOpen,
                onLogin,
              );
            }
            return toggleDrawer(e);
          }}
          onKeyPress={toggleDrawer}
          button
          component={link && Link}
          to={link}
          className={classes.link}
          classes={{
            root: classnames(classes.link, {
              [classes.linkActive]: isLinkActive && !nested,
              [classes.linkNested]: nested,
            }),
          }}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
            style={{ margin: nested && -11 }}
          >
            {nested ? (
              <Dot color={isLinkActive && 'primary'} />
            ) : (icon)}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: children,
              }),
            }}
            primary={<span style={{ color: 'red !important' }}>{label}</span>}
          />
        </ListItem>
        <Chat open={isChat} onClose={chatSetClose} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={addSectionClose}
          classes={{ paper: classes2.popover }}
        >
          <Box m={3} display="flex" flexDirection="column">
            <Typography>Add section</Typography>
            <Input
              placeholder="Section Name"
              classes={{ root: classes2.input }}
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                color="secondary"
                variant="contained"
                className={classes2.noBoxShadow}
              >
                Add
              </Button>
              <Button
                classes={{ label: classes2.buttonLabel }}
                onClick={addSectionClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </>
    );
  }
  return (
    <>
      {props.badge ? (
        <ListItem
          button
          component={link && Link}
          onClick={toggleCollapse}
          className={classnames(classes.link, {
            [classes.linkActive]: isLinkActive,
            [classes.nestedMenu]: type === 'nested',
          })}
          to={link}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {(isOpen ? iconFill : icon) || <InboxIcon />}
          </ListItemIcon>
          <Badge badgeContent={props.badge} color="success">
            <ListItemText
              classes={{
                primary: classnames(classes.linkText, {
                  [classes.linkTextActive]: isLinkActive,
                  [classes.linkTextHidden]: !isSidebarOpened,
                }),
              }}
              primary={label}
            />
          </Badge>
          <ExpandIcon
            className={classnames(
              {
                [classes.expand]: isOpen,
                [classes.linkTextHidden]: !isSidebarOpened,
              },
              classes.expandWrapper,
            )}
          />
        </ListItem>
      ) : (
        <div
          style={{ cursor: 'context-menu' }}
        >
          <ListItem
            button
            component={link && Link}
            onClick={toggleCollapse}
            className={classnames(classes.link, {
              [classes.linkActive]: isLinkActive,
              [classes.nestedMenu]: type === 'nested',
            })}
            to={link}
            disableRipple
          >
            <ListItemIcon
              className={classnames(classes.linkIcon, {
                [classes.linkIconActive]: isLinkActive,
              })}
            >
              {(isOpen ? iconFill : icon) || <InboxIcon />}
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classnames(classes.linkText, {
                  [classes.linkTextActive]: isLinkActive,
                  [classes.linkTextHidden]: !isSidebarOpened,
                }),
              }}
              primary={label}
            />
            <ExpandIcon
              className={classnames(
                {
                  [classes.expand]: isOpen,
                  [classes.linkTextHidden]: !isSidebarOpened,
                },
                classes.expandWrapper,
              )}
            />
          </ListItem>
        </div>
      )}
      {children && (
        <>
          <Collapse
            in={isOpen && isSidebarOpened}
            timeout="auto"
            unmountOnExit
            className={classnames(classes.nestedList, {
              [classes.nestedMenuItem]: type === 'nested',
            })}
          >
            <List component="div" disablePadding>
              {children.map((childrenLink) => (
                (childrenLink?.link === "/app/setting" ? true : checkAccess(userPermissions, childrenLink?.sideMenu === 'home' ? home : childrenLink?.sideMenu === 'reputation' ? reputationCentral : childrenLink?.sideMenu === 'crm' ? prospectCentral : childrenLink?.sideMenu === 'insights' ? insightsCentral : childrenLink?.sideMenu === 'leads' ? leadCentral : childrenLink?.sideMenu === 'sales' ? salesCentral : childrenLink?.sideMenu === 'marketing' ? marketingCentral : professionalServices, childrenLink?.sideModule,
                  childrenLink?.sidePermission, userInfo?.roleid)) ? <SidebarLink
                  key={
                    (childrenLink && childrenLink.link)
                    || childrenLink.label
                  }
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  classes={classes}
                  toggleDrawer={toggleDrawer}
                  nested
                  {...childrenLink}
                /> : ''
              ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
}
