import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  brandIcon: {
    width: 50,
    height: 50,
    marginTop: 10,
  },
  backgroundImg: {
    width: 70,
    height: 70,
    marginTop: 5,
  },
  MainTypography: {
    fontSize: 30,
  },
  InnerTypography: {
    fontSize: 19,
    fontWeight: 'normal',
  },
  field: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '7px',
  },
  form: {
    marginTop: '20px',
  },
  buttonSave: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 10,
    width: '100px',
  },
  buttonBack: {
    marginBottom: 10,
    width: '100px',
    float: 'right',
    background: 'gray',
  },
  buttonNext: {
    marginBottom: 10,
    width: '100px',
    float: 'left',
    marginLeft: '10px',
  },
  select: {
    marginTop: 10,
  },
  navPaddingLocation: {
    height: 'calc( 111vh - 82px )',
    overflowY: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
}));
