import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { ContactlistByLocation } from '../../Api Mutation/Contact';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { useGlobalState } from '../../context/GlobalContext';
import config from '../../config';

export default function ContactlistDropDown({
  locationid,
  contactId,
  setContactId,
  setContact,
  setOrganizationName,
  setOrganizationId,
  contact,
  setContactName,
  multiple,
  isDocument,
  isOrderModule,
  orderDialogView,
  label,
}) {
  const globalContext = useGlobalState();
  const [pageNumber, setPageNumber] = useState(1);
  const [contactData, setContactData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const locationId = locationid || globalContext.globalState.currentLocation.entityid;

  const defaultValue =  contactData?.length > 0 ? (handleUndefined(contactId) ? (contactData.find((con) => con?.id === (handleUndefined(contactId))))?.firstname : contactData[1]?.fullname ) : '';

  const getDataByScroll = async (props) => {
    if (locationId) {
      await postDataRequest(config.baseURLApi, {
        query: ContactlistByLocation,
        variables: {
          locationid: locationId,
          pagenumber: inputValue
            ? pageNumber
            : props?.pageNo || pageNumber,
          pagesize: 25,
          searchtext: inputValue === 0 ? null : inputValue,
          contactid: isOrderModule ? (handleUndefined(contactId) ? contactId : (handleUndefined(localStorage.getItem('conMId')) || null)) : null,
        },
      }).then(async (res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.contactlistbylocation?.rows?.length > 0) {
          const contactList = res?.data?.contactlistbylocation?.rows
            ?.map((item) => ({
              id: handleUndefined(item?.contactid),
              email: handleUndefined(item?.primaryemail),
              firstname: handleUndefined(item?.firstname),
              lastname: handleUndefined(item?.lastname),
              phonenumber: handleUndefined(item?.phonenumber),
              fullname: handleUndefined(item?.fullname),
              organizationname: handleUndefined(
                item?.organizationname,
              ),
              organizationid: handleUndefined(
                item?.organizationid,
              ),
            }))
            .concat(contactData);
          const uniqueArray = [
            ...new Set(
              contactList.map((item) => JSON.stringify(item)),
            ),
          ].map((item) => JSON.parse(item));
          if (contactId) {
            if (setContactId) {
              if (!isOrderModule) {
                setContactId(
                  uniqueArray.find((uni) => uni.id === contactId),
                );
              }
            }
          } else if (!props?.searchtext && setContactId) {
            if (!isOrderModule) {
              setContactId(uniqueArray[0]);
            }
            if (setContact) {
              if (!isOrderModule) {
                setContact(uniqueArray[0]);
              }
            }
            if (setContactName) {
              setContactName(
                uniqueArray[0]?.fullname === null
                  ? ''
                  : `${uniqueArray[0]?.fullname}`,
              );
            }
          }
          setContactData(uniqueArray);
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDataByScroll();
    }

    return () => {
      isMounted = false;
    };
  }, [locationId]);

  const loadNextpage = (event) => {
    const listBoxNode = event.currentTarget;
    const numberOfPages = pageNumber + 1;
    const position = listBoxNode.scrollTop + listBoxNode.clientHeight;
    if (listBoxNode.scrollHeight - position <= 1) {
      setPageNumber(numberOfPages);
      getDataByScroll({ pageNo: numberOfPages });
    }
  };

  return (
    <>
      {multiple ? (
        <>
          <Autocomplete
            id="tags-standard"
            multiple
            defaultChecked={defaultValue}
            style={{ width: isOrderModule ? 250 : '' }}
            options={contactData}
            inputValue={inputValue === 0 ? '' : inputValue}
            value={contact?.length > 0
              ? (contactData?.filter((data) => contact?.includes(data?.id))) : (contactId ? contactData?.filter((data) => data?.id === contactId) : [])}
            onChange={(event, newValues) => {
              if (newValues) {
                setContact(newValues?.map(
                  (contactDatas) => contactDatas?.id,
                ));
                setInputValue('');
              }
            }}
            onInputChange={(e) => {
              if (e) {
                setInputValue(e?.target?.value);
              }
            }}
            ListboxProps={{
              onScroll: loadNextpage,
            }}
            getOptionLabel={(option) => `${option?.firstname} ${option?.lastname} (${option?.email === null
                || option?.email === ''
              ? option?.phonenumber
              : option?.email
            })` || option?.firstname}
            renderInput={(paramsAssociation) => (
              <TextField
                {...paramsAssociation}
                label={isDocument ? 'Select Contact' : 'Association'}
                size="small"
                variant="outlined"
              />
            )}
          />
        </>
      ) : isOrderModule ? (
        <Autocomplete
          fullWidth
          defaultChecked={defaultValue}
          style={{ width: isOrderModule ? 250 : '' }}
          id="combo-box-demo"
          disabled={orderDialogView}
          closeIcon={false}
          value={contactData.find((con) => con?.id === (handleUndefined(contactId) || localStorage.getItem('conMId'))) || null}
          options={contactData}
          Inputp
          ListboxProps={{
            onScroll: loadNextpage,
          }}
          inputValue={inputValue === 0 ? '' : inputValue}
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            if (newValue) {
              if (setContactId) {
                setContactId(newValue.id);
              }
              if (setContact) {
                setContact(newValue);
              }
              if (setOrganizationName) {
                setOrganizationName(newValue?.name);
              }
              if (setOrganizationId) {
                setOrganizationId(newValue?.organizationid);
              }
              if (setContactName) {
                let fullName = '';
                if (newValue?.firstname !== null) {
                  fullName = newValue?.firstname;
                }
                if (newValue?.lastname !== null) {
                  fullName += newValue?.lastname;
                }
                setContactName(fullName);
              }
            } else {
              localStorage?.removeItem('conMId');
              if (setContactId) {
                setContactId(null);
              }
              if (setContact) {
                setContact(null);
              }
              if (setOrganizationName) {
                setOrganizationName(null);
              }
              if (setOrganizationId) {
                setOrganizationId(null);
              }
              if (setContactName) {
                setContactName('');
              }
            }
          }}
          getOptionLabel={(option) => (option?.fullname === null
            ? option?.email : option?.fullname)}
          renderOption={(option) => (
            <>
              {option?.fullname}
              {' '}
              (
              {option?.email === null || option?.email === '' ? option?.phonenumber : option?.email}
              )
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label={label || 'Contact'}
              variant="outlined"
              onChange={(e) => {
                localStorage?.removeItem('conMId');
                setContactId(null);
                if (e.target.value) {
                  setPageNumber(1);
                  getDataByScroll({
                    searchtext: e.target.value,
                  });
                }
              }}
            />
          )}
        />
      ) : (
        <Autocomplete
          fullWidth
          defaultChecked={defaultValue}
          style={{ width: isOrderModule ? 250 : '' }}
          id="combo-box-demo"
          value={
            contact !== undefined
              ? contact
              : contactId !== undefined
                ? contactId
                : ''
          }
          options={contactData}
          ListboxProps={{
            onScroll: loadNextpage,
          }}
          inputValue={inputValue === 0 ? '' : inputValue}
          onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
          onChange={(event, newValue) => {
            if (setContactId) {
              setContactId(newValue);
            }
            if (setContact) {
              setContact(newValue);
            }
            if (setOrganizationName) {
              setOrganizationName(newValue?.name);
            }
            if (setOrganizationId) {
              setOrganizationId(newValue?.organizationid);
            }
            if (setContactName) {
              let fullName = '';
              if (newValue?.firstname !== null) {
                fullName = newValue?.firstname;
              }
              if (newValue?.lastname !== null) {
                fullName += newValue?.lastname;
              }
              setContactName(fullName);
            }
          }}
          getOptionLabel={(option) => (option?.fullname === null
            ? option?.email
            : option?.fullname)}
          renderOption={(option) => (
            <>
              {option?.fullname}
              {' '}
              (
              {option?.email === null || option?.email === ''
                ? option?.phonenumber
                : option?.email}
              )
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label={label || 'Contact'}
              variant="outlined"
              onChange={(e) => {
                if (e.target.value) {
                  setPageNumber(1);
                  getDataByScroll({
                    searchtext: e.target.value,
                  });
                }
              }}
            />
          )}
        />
      )}
    </>
  );
}
