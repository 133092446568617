import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PoweredBy from '../../common/PoweredBy';
import useStyles from './styles';

export default function VerifyAccount() {
  const classes = useStyles();
  return (
    <div className={classes.verifyPage}>
      <Paper className={classes.verify}>
        <img
          src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bixpand.png"
          className={classes.logo}
          alt="brand logo"
        />
        <br />
        <EmailIcon color="primary" style={{ fontSize: 120 }} />
        <br />
        <br />
        <Typography variant="h1">Please verify your email...</Typography>
        <br />
        <br />
        <Typography variant="body1">Check email in inbox/spam to Verify Email.</Typography>
      </Paper>
      <br />
      <PoweredBy />
    </div>
  );
}
