import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import useStyles from './style';

function Email(props) {
  const classes = useStyles();
  const { label, validate } = props;
  return (
    <div>
      <Field
        name="email"
        className={classes.field}
        required
        size="small"
        validate={validate || ''}
        variant="outlined"
        component={TextField}
        type="email"
        label={label || 'Email'}
      />
    </div>
  );
}
export default Email;
