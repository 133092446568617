import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { pageSizes } from './const';
import useStyle from './style';

function RowsPerPageCustom({ rowPerPageValue, onPageSizeChanged, disableBoolean }) {
  const classes = useStyle();
  return (
    <>
      <Select
        name="pageSize"
        variant="standard"
        size="small"
        value={rowPerPageValue}
        className={classes.rowsPerPageSelect}
        onChange={onPageSizeChanged}
        disabled={disableBoolean}
      >
        {pageSizes.map((page, index) => (
          <MenuItem value={page} key={index}>
            {`${page} Records Per Page`}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default RowsPerPageCustom;
