export const MailBoxEmailList = `query MailBoxEmailList($locationid: ID!, $emailtype: Int, $pagenumber: Int, $pagesize: Int, $searchtext: String, $ordercolumn: String, $status: [Int!], $orderby: String, $startdate: String, $enddate: String, $subject: String, $notSubject: String, $to: String, $fromemail: String, $emailbody: String) {
    mailBoxEmailList(locationid: $locationid, emailtype: $emailtype, pagenumber: $pagenumber, pagesize: $pagesize, searchtext: $searchtext, ordercolumn: $ordercolumn, orderby: $orderby, status: $status, startdate: $startdate, enddate: $enddate, subject: $subject, notSubject: $notSubject, to: $to, fromemail: $fromemail, emailbody: $emailbody) {
      count
      rows {
        emailid
        to
        cc
        bcc
        fromemail
        fromname
        subject
        emailbody
        emailattachments
        status
        scheduledatetime
        contactid
        ownerid
        threadid
        messageid
        emailreferenceid
        servicesprovider
        emailtype
        parentemailid
        senditnow
        createdby
        updatedby
        servicesproviderid
        deleted
        activityhistory
        signaturetype
        signaturecustomhtml
        signatureuserid
        replyto
        emailread
        createdAt
        updatedAt
        replies {
          emailid
          to
          cc
          bcc
          fromemail
          fromname
          subject
          emailbody
          emailattachments
          status
          scheduledatetime
          contactid
          ownerid
          threadid
          messageid
          emailreferenceid
          servicesprovider
          emailtype
          parentemailid
          senditnow
          createdby
          updatedby
          servicesproviderid
          deleted
          activityhistory
          signaturetype
          signaturecustomhtml
          signatureuserid
          replyto
          emailread
          createdAt
          updatedAt
        }
        contact {
          contactid
          primaryemail
          secondaryemail
          firstname
          lastname
          contactavatar
          officephonenumber
          phonenumber
          homephonenumber
          lifecyclestage
          locationid
          createdby
          updatedby
          deleted
          jobid
          tags
          ownerid
          organizationid
          source
          createdAt
          updatedAt
          status
          subscriptionstatus
          emailsubscriptiondate
          emailunsubscriptiondate
          emailcount
          emailstatus
        }
      }
    }
  }`;

export const SyncMailBox = `query Query($locationid: ID!) {
  syncMailboxEmails(locationid: $locationid)
}`;
