import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { postDataRequestPublic } from '../../../common/commonfunctions';
import config from '../../../config';

export default function ThirdPartyUrl() {
  const { calUrl } = useParams();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      postDataRequestPublic(config.url, {
        query: `
           query urlbycode($sorturlcode: String!){
                urlbycode(sorturlcode:$sorturlcode){
                    fullurl,
                }
            }`,
        variables: {
          sorturlcode: calUrl,
        },
      }).then((res) => {
        if (res?.data?.urlbycode?.fullurl) {
          window.location.replace(res?.data?.urlbycode?.fullurl);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [calUrl]);
  return <div />;
}
