import React, { useEffect, useState } from 'react';
import {
  Button, Box, Typography,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CloseIcon from '@material-ui/icons/Close';
import { useUserState } from '../../context/UserContext';
import config from '../../config';
import { CreateCallMutation } from '../../Api Mutation/Contact';
import { Userprofilebyuser, EditUserProfile } from '../../Api Mutation/Login';
import { postDataRequest, handleUndefined } from '../../common/commonfunctions';
import PhoneNoWithCountryCode from '../Phonenumber/PhoneWithCountryCodeSolo';
import { showNotification } from '../../components/Notification/ToastifyNotifications';

export default function Registerphone({
  onPhone, setModalOpen, verificationCode, setVerificationCode,
}) {
  const { userInfo } = useUserState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [profileData, setProfileData] = useState();

  const userData = () => {
    if (userInfo?.userid) {
      postDataRequest(config.baseURLApi, {
        query: Userprofilebyuser,
        variables: {
          userid: userInfo?.userid,
        },
      })
        .then((res) => {
          if (res?.data?.userprofilebyuser) {
            setPhoneNumber(res?.data?.userprofilebyuser?.twiliophonenumber);
            setProfileData(res?.data?.userprofilebyuser);
          }
        });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      userData();
    }
    return () => {
      isMounted = false;
    };
  }, [userInfo?.userid]);

  const updateUserProfile = () => {
    postDataRequest(config.baseURLApi, {
      query: EditUserProfile,
      variables: {
        userprofileid: profileData?.userprofileid,
        twiliophonenumber: phoneNumber,
        updatedby: userInfo?.userid,
      },
    }).then((res) => {
      const data = res?.data?.editUserProfile;
      if (res?.errors && res?.errors[0]?.message) {
        showNotification(res?.errors && res?.errors[0].message, 'error');
      } else if (data) {
        userData();
      }
    });
  };

  const getVerificationCode = () => {
    const fromNo = `+${phoneNumber}`;
    if (fromNo) {
      const CREATE_CALL_MUTATION = CreateCallMutation;
      postDataRequest(config.callApi, {
        query: CREATE_CALL_MUTATION,
        variables: {
          phoneNumber: fromNo,
        },
      })
        .then((res) => {
          if (res?.errors && res?.errors[0]?.message) {
            showNotification(res?.errors && res?.errors[0].message?.replace('phone_number is not a valid phone number:', 'Phone number is not vaild').replace('Phone number is not vaild', 'Phone number is not vaild'), 'error');
          } else {
            setVerificationCode(res?.data?.createCallId?.validationCode);
          }
        });
    }
  };

  const onRegister = async () => {
    await updateUserProfile();
    await getVerificationCode();
  };
  return (
    <div>
      {(!verificationCode && !onPhone) && (
      <>
        <Typography variant="body1">
          Before the first call, Register and Verify the phone number
        </Typography>
  &nbsp;  &nbsp;  &nbsp;
        <Box display="flex" alignItems="center">
          <PhoneNoWithCountryCode
            setPhoneNumber={setPhoneNumber}
            defaultValue={handleUndefined(phoneNumber)}
            phoneNumber={handleUndefined(
              phoneNumber,
            )}
            style={{
              textalign:
                                                        'center',
              marginBottom: 23,
              marginRight: 55,
            }}
          />
              &nbsp; &nbsp;
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="small"
            startIcon={<VerifiedUserIcon />}
            onClick={() => onRegister()}
          >
            Register
          </Button>
          <CloseIcon onClick={() => setModalOpen(false)} />
        </Box>
      </>
      )}
    </div>
  );
}
