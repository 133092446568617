import React from 'react';
import jwt from 'jsonwebtoken';
import config from '../config';
import { dataRequest } from '../common/commonfunctions';
import { Entityaddressbyentityid } from '../Api Mutation/EntityApi';
import { setCookie } from '../common/const';

const GlobalContext = React.createContext();

const rootReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_LOCATION_INFO': {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          ...payload,
        },
      };
    }
    case 'GET_LOCATION_ADDRESS': {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          address: payload,
        },
      };
    }
    case 'GET_COUNTRY_BY_ID': {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          address: {
            ...state.currentLocation.address,
            country: payload,
          },
        },
      };
    }
    case 'GET_STATE_BY_ID': {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          address: {
            ...state.currentLocation.address,
            state: payload,
          },
        },
      };
    }
    case 'SET_LOGIN_USER_INFO':
      return {
        ...state,
        userInfo: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = React.useReducer(rootReducer, {
    currentLocation: {},
    locationTitle: '',
  });

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalState = () => {
  const context = React.useContext(GlobalContext);
  return context;
};

const setLocationInfo = (locationInfo, dispatch) => {
  dispatch({ type: 'SET_LOCATION_INFO', payload: locationInfo });
};

const setLoginUserInfo = (dispatch) => {
  dispatch({
    type: 'SET_LOGIN_USER_INFO',
    payload: jwt.decode(localStorage.getItem('token')),
  });
};

export function getCountryById(id, dispatch) {
  dataRequest(config.baseURLApi, {
    query: `{country(countryid:${id}){name}}`,
  })
    .then((res) => {
      dispatch({
        type: 'GET_COUNTRY_BY_ID',
        payload: res.data.country.name,
      });
    })
    .catch(() => {});
}

export function getStateById(id, dispatch) {
  if (id) {
    dataRequest(config.baseURLApi, {
      query: `{state(stateid:${id}){name}}`,
    })
      .then((res) => {
        dispatch({ type: 'GET_STATE_BY_ID', payload: res.data.state.name });
      })
      .catch(() => {});
  }
}

export function getLocationAddress(id, dispatch) {
  if (id) {
    dataRequest(config.baseURLApi, {
      query: Entityaddressbyentityid,
      variables: {
        entityid: id,
      },
    })
      .then((res) => {
        getCountryById(res.data.entityaddressbyentityid.countryid, dispatch);
        getStateById(res.data.entityaddressbyentityid.stateid, dispatch);
        dispatch({
          type: 'GET_LOCATION_ADDRESS',
          payload: res.data.entityaddressbyentityid,
        });
      })
      .catch(() => {});
  }
}

export function getCurrentLocationInfo(locationid, dispatch) {
  dataRequest(config.baseURLApi, {
    query: `{entity(entityid: "${locationid}"){entityid,businessname,brandname, parententityid,
        firstname,lastname,email,phonenumber,entitytypeid,displaylocationdoncampaign,businesstypeid,jobid,tags,website,shortdesc,fulldesc,createdAt,subdomain,
      }}`,
  }).then((res) => {
    if (res.data.entity) {
      dispatch({ type: 'SET_LOCATION_INFO', payload: res.data.entity });
      getLocationAddress(res.data.entity.parententityid, dispatch);
      localStorage.setItem('business_Id', JSON.stringify(res.data.entity.parententityid));
      setCookie('business_Id', res.data.entity.parententityid, 365);
      setCookie('location_Id', res.data.entity.entityid, 365);
    }
  });
}

export {
  GlobalStateProvider,
  setLoginUserInfo,
  useGlobalState,
  setLocationInfo,
};
