import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  rootDialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  buttonAccordian: {
    marginBottom: '3rem',
  },
  gridWrapperItem: {
    [theme.breakpoints.up('lg')]: {
      overflow: 'auto',
      height: '78vh',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  themeColor: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  themeIcon: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  linkText: {
    color: localStorage.getItem('theme') === 'dark' ? '#c9c9df' : '#005eb2',
  },
  videoResponsive: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  socialSvgContent: {
    borderRadius: '50%',
    top: 0,
    left: 0,
    margin: 1,
  },
  actionIcon: {
    color: '#005EB2',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  BottomBut: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  socialIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    '&:hover': {
      fill: 'black',
      WebkitTransition: 'fill 170ms ease-in-out',
    },
  },
  margin: {
    marginBottom: 24,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  componentHeading: {
    fontSize: 20,
    paddingTop: 4,
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  backArrow: {
    fontSize: '35px !important',
    fontFamily: 'open sans',
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formImg: {
    width: '35%',
    height: '35%',
  },
  frame: {
    height: '100vh',
    width: '100%',
    border: '3px solid',
    borderColor: '#6e6e6e',
    borderRadius: '10px',
    overflow: 'auto',
  },
  iframe: {
    height: '95vh',
    width: '100%',
  },

  dotRed: {
    background: 'rgb(237, 89, 74)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotYellow: {
    background: 'rgb(253, 216, 0)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotGreen: {
    background: 'rgb(90, 192, 90)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '15px 15px 15px',
    zIndex: '10',
    alignItems: 'center',
    backgroundColor: '#6e6e6e',
    padding: '10px',
    paddingLeft: '1.5rem',
  },
  backMenu: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  mainDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '30px 0',
    [theme.breakpoints.down('xs')]: {
      width: '101%',
    },
  },
  chilDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '85%',
    marginBottom: '10px',
  },
  contentImg: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    aspectRatio: 'auto',
    marginRight: '30px',
  },
  contentImgs: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    backgroundColor: 'white',
    border: '3px solid #005EB2',
    aspectRatio: 'auto',
    marginRight: '30px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
    },
  },
  button: {
    marginRight: '20px',
    borderRadius: '50px',
    color: 'white',
  },
  tabs: {
    minWidth: 200,
    borderBottom: '2px solid #e8e8e8',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '20px',
      fontSize: 10,
    },
  },
  tab: {
    minWidth: 110,
    fontSize: 14,
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      minWidth: 8,
      fontSize: 10,
    },
    '&:hover': {
      backgroundColor: '#f6f7ff',
      color: 'black',
    },
  },
  backButton: {
    height: 'fit-content',
    alignSelf: 'center',
  },
  nextButton: {
    marginLeft: 'auto',
    height: 'fit-content',
    alignSelf: 'center',
  },
  navPaddingLocation: {
    height: 'calc( 111vh - 82px )',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  score: {
    backgroundColor: 'white',
    fontSize: 14,
    color: '#005EB2',
    border: 'solid 2px #005EB2',
  },
  paper: (props) => ({
    position: 'relative',
    zIndex: 1,
    marginTop: '2%',
    width: `${props.formwidth}%`,
    margin: 'auto',
  }),

  appointmentPaper: {
    position: 'relative',
    zIndex: 1,
    marginTop: '1%',
    marginLeft: '50%',
    borderLeft: '1px solid lightgrey',
    [theme.breakpoints.down('md')]: {
      marginLeft: '40%',
      borderLeft: '1px solid lightgrey',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      marginRight: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '2%',
      marginRight: '2%',
    },
  },
  cal: (props) => ({
    width: '100%',
    height: 'auto',
    padding: 37,
    justifyContent: 'center',
    background: props.background,
    [theme.breakpoints.down('xs')]: {
      padding: 15,
      width: '100%',
      height: 'auto',
    },
    // border: props.eventType === 'single-page' ? '1px solid lightgrey' : 'none',
    boxShadow: 'none',
  }),
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 650,
    [theme.breakpoints.down('sm')]: {
      width: 380,
    },
  },
  card: (props) => ({
    background: props.background,
    marginTop: 20,
    padding: '20px 5px',
  }),
  inquiryBox: {
    width: '100%',
    height: 45,
    border: '1px dashed lightgrey',
    paddingLeft: 10,
    paddingTop: 14,
    marginBottom: 10,
    cursor: 'move',
  },
  movePointer: {
    width: '100%',
  },
  pageBox: {
    width: '100%',
    height: 45,
    border: '1px dashed lightgreen',
    paddingLeft: 10,
    paddingTop: 14,
    marginBottom: 10,
    background: 'lightgreen',
  },
  selectedBox: {
    width: '100%',
    height: 45,
    border: '1px dashed #005EB2',
    paddingTop: 14,
    paddingLeft: 10,
    marginBottom: 10,
    background: '#005EB2',
    color: '#fff',
    cursor: 'move',
  },
  accordionDetails: {
    display: 'block',
  },
  field: {
    width: '100%',
    fontFamily: 'open sans',
  },
  stateField: {
    width: '100%',
    fontSize: 12,
    fontFamily: 'Open sans',
  },
  searchfield: {
    margin: '10px 0px',
  },
  elementBox: {
    display: 'flex',
    alignItems: 'center',
  },
  elementEditorBox: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
    border: '1px solid #c4c4c4',
    borderRadius: 4,
  },
  editorBox: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
  },
  Dropzone: {
    height: '150px',
    marginBottom: '30px',
    textAlign: 'center',
    padding: '17px',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
    border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
    [theme.breakpoints.down('md')]: {
      width: 250,
      textAlign: 'center',
      padding: '17px',
      backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
      color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
      border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
      marginBottom: 5,
    },
  },
  UploadDrop: {
    height: '120px',
    marginBottom: 20,
    textAlign: 'center',
    padding: '8px',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
    border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
  },
  note: {
    textAlign: 'justify',
    marginTop: 5,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      textAlign: 'justify',
    },
  },
  docTitle: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  docs: {
    fontSize: 20,
  },
  dropBox: {
    border: '1px dashed #6e6e6e',
    width: '100%',
    height: '400px',
    padding: 10,
    marginTop: 10,
    paddingTop: 28,
    overflow: 'auto',
  },
  blankbox: {
    width: '100%',
    height: '377px',
    backgroundColor: '#f3f3fe',
    margin: '8px',
  },
  blankMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    width: '100%',
    height: '100%',
    fontSize: '25px',
    color: '#949191',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? '#23232d' : '#f3f3fe',
    fontWeight: 400,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  blankMsgR: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '5px',
    width: '100%',
    height: '100%',
    fontSize: '25px',
    color: '#949191',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? '#23232d' : '#f3f3fe',
    fontWeight: 400,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drgIcon: {
    fontSize: '30px',
  },
  placeholderIcon: {
    fontSize: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  requiredField: {
    color: 'red !important',
    position: 'absolute',
    bottom: 30,
    right: 0,
  },
  required: {
    color: 'red',
    marginLeft: 4,
  },
  selectrows: {
    float: 'right',
    marginRight: 15,
    marginBottom: 15,
  },
  previewBtn: {
    float: 'right',
  },
  Cover: (props) => ({
    background: props.background,
  }),
  callOutTextHead: (props) => ({
    color: props.textColor,
    textAlign: props.textPosition,
  }),
  LowWeightText: (props) => ({
    fontWeight: '400 !important',
    color: props.textColor,
    textAlign: props.textPosition,
  }),
  shape: (props) => ({
    backgroundColor: props.color,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  fontShape: (props) => ({
    backgroundColor: props.fontcolor,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  shapeBg: (props) => ({
    backgroundColor: props.background,
    display: 'flex',
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  btnShape: (props) => ({
    backgroundColor: props.btnColor,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  btnShapeText: (props) => ({
    backgroundColor: props.btnText,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  shapeText: (props) => ({
    backgroundColor: props.textColor,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  radiogroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  CalloutButton: (props) => ({
    backgroundColor: props.btnColor,
    color: props.btnText,
    marginTop: 20,
  }),
  AppointmentBtn: (props) => ({
    backgroundColor: props.btnColor,
    color: props.btnText,
    width: '100%',
    borderRadius: 15,
  }),
  guestBtn: {
    width: '100%',
    borderRadius: 15,
    marginTop: 30,
    marginBottom: 15,
  },
  btnPosition: (props) => ({
    textAlign: props.btnPosition,
    marginTop: 5,
  }),
  divider: {
    marginTop: 24,
  },
  previewImage: {
    width: '90%',
    height: 'auto',
    aspectRatio: 'Auto',
  },
  popupCard: (props) => ({
    maxWidth: 350,
    backgroundColor: props.background,
    margin: 'auto',
    width: '50%',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
  }),
  paper1: {
    padding: theme.spacing(3),
    maxWidth: 1000,
    maxHeight: 500,
  },
  result: {
    border: '1px solid #ced4da',
    borderRadius: 5,
    padding: 12,
    marginTop: 10,
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  images: {
    width: 150,
    aspectRatio: 'auto',
  },
  error: {
    marginLeft: 5,
    color: 'red',
    fontSize: 15,
  },
  labelBox: {
    paddingBottom: 5,
    borderBottom: '1px solid grey',
    marginBottom: 10,
  },
  prevBtn: (props) => ({
    backgroundColor: props.btnColor,
    color: props.btnText,
    fontSize: '12px',
    marginRight: '5px',
    padding: '4px 4px',
    fontWeight: 'bold',
    marginTop: 20,
    '&:hover': {
      background: '#d5d5d5',
    },
  }),
  nextBtn: (props) => ({
    backgroundColor: props.btnColor,
    color: props.btnText,
    fontSize: '12px',
    padding: '4px 4px',
    fontWeight: 'bold',
    marginTop: 20,
    '&:hover': {
      background: '#d5d5d5',
    },
  }),
  delete: {
    float: 'right',
    background: 'rgb(0, 94, 178)',
    borderRadius: '8px',
    color: 'white',
    '&:hover': {
      background: 'rgb(0, 94, 178)',
    },
  },
  pageNumber: {
    padding: '10px',
    borderRadius: '6px',
    width: '10%',
    background: 'rgb(0, 94, 178)',
    color: 'white',
    textAlign: 'center',
  },
  emailSend: {
    top: '35%',
    float: 'right',
  },
  responsiveSplit: {
    top: '20%',
    float: 'right',
  },
  contactheader: {
    display: 'flex',
    alignItems: 'center',
  },
  Paper: {
    backgroundColor: '#e8e8ee',
    color: '#000000',
    padding: '0px 0px 0px 0px',
    fontWeight: 500,
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e8e8ee',
    },
  },
  customTooltip: {
    color: 'white',
    height: '1.5rem',
    fontSize: 12,
  },
  urlStyle: {
    color: localStorage.getItem('theme') === 'dark' ? '#c9c9df' : 'blue',
  },
  orderBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: 10,
  },
  btnSubmit: {
    backgroundColor: '#005eb2 !important',
    color: 'white !important',
    borderRadius: '5px !important',
    border: 'none !important',
    height: '30px !important',
  },
  btnClose: {
    backgroundColor: '#ff5c93 !important',
    color: 'white !important',
    borderRadius: '5px !important',
    border: 'none !important',
    height: '30px !important',
  },
  alignContant: {
    display: 'flex',
    alignItems: 'center',
  },
  emailBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
    width: '100%',
  },
  enterBtn: {
    height: 35,
    fontWeight: 600,
    fontSize: 13,
  },
  emptyMsg: {
    width: '100%',
    textAlign: 'center',
    marginTop: 10,
  },
  descriptionBox: {
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    width: '100%',
  },
}));
