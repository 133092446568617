import React from 'react';
import JoditEditor from 'jodit-react';
import './style.css';

function BixJoditEditor({
  joditRef, joditValue, handleEditorChange,
}) {
  return (
    <div>
      <JoditEditor
        ref={joditRef}
        value={joditValue}
        onChange={(content) => handleEditorChange(content)}
      />
    </div>
  );
}

export default BixJoditEditor;
