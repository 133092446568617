import { makeStyles } from '@material-ui/styles';
import { deepOrange } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  buttonSave: {
    marginLeft: '50%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      marginLeft: '70%',
    },
  },
  activeRating: {
    border: '4px solid black',
  },
  fieldColor: {
    background: '#F5FAFF',
    width: '100%',

  },
  fieldTemplate: {
    fontSize: '14px !important',
    width: '88%',
  },
  paper: {
    height: 100,
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      height: 100,
      width: 150,
    },
  },
  templateText: {
    fontWeight: 'bold',
    color: localStorage.getItem('theme') === 'dark' ? '#c9c9df' : '#005eb2',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },

  googleIcon: {
    width: 30,
  },
  shape: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      width: 22,
      height: 22,
      marginRight: theme.spacing(1),
    },
  },
  titleText: {
    marginBottom: '8px',
    marginTop: '8px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: '8px',
    },
  },
  redRating: {
    backgroundColor: '#F04D5D',
  },
  yellowRating: {
    backgroundColor: '#E49E12',
  },
  greenRating: {
    backgroundColor: '#5FBF91',
  },
  marginZero: {
    margin: 0,
    fontSize: '12px',
    color: 'white',
    fontFamily: 'open sans',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  positiveExperienceCard: {
    backgroundColor: '#004482',
    padding: 16,
    boxShadow:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  negativeExperienceCard: {
    backgroundColor: '#004482',
    padding: 16,
    boxShadow:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  buttonSave1: {
    marginLeft: '60%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      marginLeft: '70%',
    },
  },
  buttonSave2: {
    marginLeft: '72%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      marginLeft: '70%',
    },
  },
  positiveFeedBackBox: {
    flexGrow: 1,
    padding: 18,
    backgroundColor: '#FFFFFF',
    boxShadow:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 6px 0 #9A9A9A1A',
  },
  input: {
    marginLeft: theme.spacing(15),
  },
  large: {
    width: '35%',
    aspectRatio: 'auto',
  },
  large2: {
    width: theme.spacing(17),
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(15),
      aspectRatio: 'auto',
    },
  },
  text: {
    width: '50%',
    marginTop: theme.spacing(2),
  },
  darkPink: {
    backgroundColor: '#F04D5D',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  darkYellow: {
    backgroundColor: '#E59E12',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  darkGreen: {
    backgroundColor: '#5FBF91',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  switch: {
    marginTop: theme.spacing(3),
  },
  switchs: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    width: '100%',
  },
  google: {
    display: 'inline-block',
  },
  cardMedia: {
    minWidth: '100%',
  },
  media: {
    height: '140px',
  },
  starIcon: {
    color: '#ffc247',
  },
  cardActions: {
    padding: theme.spacing(2),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],

    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: 'white',
    },
  },

  field: {
    marginLeft: theme.spacing(2),
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  textField: {
    fontSize: 22,
  },
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  buttonBack: {
    float: 'right',
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '10%',
      marginTop: '1%',
      marginRight: 'auto',
      fontSize: 10,
      width: '100%',
    },
  },
  time: {
    display: 'inline-block',
  },
  dis: {
    display: 'inline-block',
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignStandaloneElement: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableWidget: {
    overflowX: 'auto',
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: '100%',
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: `${theme.palette.text.hint}80 !important`,
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: '14px !important',
    marginLeft: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  head: {
    width: '50%',
    color: 'primary',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  fixIconRight: {
    '& .MuiSelect-icon': {
      right: 4,
    },
  },
  checkboxColor: {
    color: 'rgb(185, 185, 185)',
  },
  BrandLogoCaption: {
    fontSize: '12px',
    fontFamily: 'open sans',
    color: '#005EB2',
    fontWeight: 'bold',
  },
  TestCampaignbtn: {
    fontSize: '12px',
  },
  addMg: {
    marginTop: '5px',
    marginBottom: '5px',
  },
}));
