import React, { useState, useEffect } from 'react';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import {
  Drawer, IconButton,
  List, Link, Divider, Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import useStyles from './styles';
import SidebarLink from './components/SidebarLink/SidebarLink';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import { WhiteLabelSettingByBusiness } from '../../Api Mutation/WhiteLabelSettingApi';

function Sidebar({
  location,
  structure,
  getToogleBodyStatus,
  userPermissions,
  home,
  settings,
  professionalServices,
  reputationCentral,
  prospectCentral,
  marketingCentral,
  promotionCentral,
  productService,
  insightsCentral,
  leadCentral,
  salesCentral,
  menuPermissions,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [whiteLabelData, setWhiteLabelData] = useState(null);
  const [filteredStructure, setFilteredStructure] = useState([]);
  const [openOnHover, setOpenOnHover] = useState(false);
  // global
  const {
    isSidebarOpened,
  } = useLayoutState();
  const businessId = handleUndefined(JSON.parse(localStorage?.getItem('business_Id')));
  const layoutDispatch = useLayoutDispatch();
  const logo = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/demoCircle.jpg';
  const sideBarLogo = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/demoSquare.png';
  // local
  const [isPermanent, setPermanent] = useState(true);
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
  const toggleDrawer = (value) => (event) => {
    if (getToogleBodyStatus) { getToogleBodyStatus(false); }
    if (
      event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar(layoutDispatch);
  };
  const [openSideBar, setOpenSideBar] = useState();

  const hasPermission = () => {
    const allPermissions = [
      ...(Array.isArray(menuPermissions.homePermissions) ? menuPermissions.homePermissions : []),
      ...(Array.isArray(menuPermissions.reputationPermissions) ? menuPermissions.reputationPermissions : []),
      ...(Array.isArray(menuPermissions.crmPermissions) ? menuPermissions.crmPermissions : []),
      ...(Array.isArray(menuPermissions.insightsPermissions) ? menuPermissions.insightsPermissions : []),
      ...(Array.isArray(menuPermissions.leadsPermissions) ? menuPermissions.leadsPermissions : []),
      ...(Array.isArray(menuPermissions.salesPermissions) ? menuPermissions.salesPermissions : []),
      ...(Array.isArray(menuPermissions.marketingPermissions) ? menuPermissions.marketingPermissions : []),
      ...(Array.isArray(menuPermissions.professionalPermissions) ? menuPermissions.professionalPermissions : []),
      ...(Array.isArray(menuPermissions.workflowPermissions) ? menuPermissions.workflowPermissions : []),
      ...(Array.isArray(menuPermissions.linkedInPermissions) ? menuPermissions.linkedInPermissions : []),
    ];

    // Return true if at least one ID matches
    return userPermissions?.some((id) => allPermissions?.includes(id));
  };

  const getWhiteLabeleData = () => {
    if (businessId) {
      postDataRequest(config?.baseURLApi, {
        query: WhiteLabelSettingByBusiness,
        variables: {
          businessid: businessId,
        },
      }).then((response) => {
        if (response?.errors && response?.errors[0]?.message) {
          showNotification(response.errors[0].message, 'error');
        } else if (response?.data?.whiteLabelSettingsByBusiness) {
          setWhiteLabelData(response?.data?.whiteLabelSettingsByBusiness);
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (businessId) {
        getWhiteLabeleData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [businessId]);

  useEffect(() => {
    const filteredArr = structure.filter((la) => {
      const permissionsMap = {
        'Home': menuPermissions?.homePermissions?.length > 0 ? menuPermissions?.homePermissions : [],
        'Leads': menuPermissions?.leadsPermissions?.length > 0 ? menuPermissions?.leadsPermissions : [],
        'CRM': menuPermissions?.crmPermissions?.length > 0 ? menuPermissions?.crmPermissions : [],
        'Marketing': menuPermissions?.marketingPermissions?.length > 0 ? menuPermissions?.marketingPermissions : [],
        'Sales': menuPermissions?.salesPermissions?.length > 0 ? menuPermissions?.salesPermissions : [],
        'Reputation': menuPermissions?.reputationPermissions?.length > 0 ? menuPermissions?.reputationPermissions : [],
        'Insights': menuPermissions?.insightsPermissions?.length > 0 ? menuPermissions?.insightsPermissions : [],
        'Professional Services': menuPermissions?.professionalPermissions?.length > 0 ? menuPermissions?.professionalPermissions : [],
        'Workflows': menuPermissions?.workflowPermissions?.length > 0 ? menuPermissions?.workflowPermissions : [],
        'LinkedIn Sequences': menuPermissions?.linkedInPermissions?.length > 0 ? menuPermissions?.linkedInPermissions : [],
      };

      // Get the permissions for the current menu item
      const requiredPermissions = permissionsMap[la?.label] || [];

      // Check if the user has any of the required permissions
      const hasPermission = userPermissions?.some((permissionId) =>
        requiredPermissions.includes(permissionId)
      );

      // Filter out the menu item if the user doesn't have the required permissions
      if (requiredPermissions.length > 0 && !hasPermission) {
        return false; // Filter out this menu item
      }

      return true; // Keep the menu item
    });
    setFilteredStructure(filteredArr);
  }, [structure, userPermissions, menuPermissions]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: !isPermanent
          ? !isSidebarOpened
          : isSidebarOpened,
        [classes.drawerClose]: !isPermanent
          ? isSidebarOpened
          : !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpenPaper]: !isPermanent
            ? !isSidebarOpened
            : isSidebarOpened,
          [classes.drawerClose]: !isPermanent
            ? isSidebarOpened
            : !isSidebarOpened,
        }),
      }}
      open={!isPermanent ? !isSidebarOpened : isSidebarOpened}
      onClose={toggleDrawer(true)}
      onMouseEnter={() => (!isSidebarOpened ? (toggleSidebar(layoutDispatch), setOpenOnHover(true)) : '')}
      onMouseLeave={() => ((isSidebarOpened && openOnHover) ? (toggleSidebar(layoutDispatch), setOpenOnHover(false)) : '')}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(
                classes.headerIcon,
                classes.headerIconCollapse,
              ),
            }}
          />
        </IconButton>
      </div>
      <List
        className={classes.sidebarList}
        classes={{ padding: classes.padding }}
      >
        {filteredStructure.map((link) => {
          return !(link?.label === 'Professional Services' && whiteLabelData?.isdisabledexpertservices === true) ? (
            <SidebarLink
              setOpenSideBar={setOpenSideBar}
              home={home}
              userPermissions={userPermissions}
              settings={settings}
              professionalServices={professionalServices}
              reputationCentral={reputationCentral}
              prospectCentral={prospectCentral}
              marketingCentral={marketingCentral}
              promotionCentral={promotionCentral}
              productService={productService}
              insightsCentral={insightsCentral}
              leadCentral={leadCentral}
              salesCentral={salesCentral}
              openSideBar={openSideBar}
              getToogleBodyStatus={getToogleBodyStatus}
              key={link.id}
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              {...link}
              toggleDrawer={toggleDrawer(true)}
            />
          ) : null;
        })}
        <Divider style={{ marginTop: 20 }} />
        {((whiteLabelData?.isdisabledgetdemo === false) && hasPermission()) ? <div className={classes.sidebarBottom}>
          <center>
            <Link href={handleUndefined(whiteLabelData?.appointmenturl) || "https://app.bixpand.com/appointment/sd/meeting-with-sd@bixpand.com"} target="_blank">
              <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center">
                {logo ? (
                  <img
                    src={logo}
                    className={classes.bixpandLogo}
                    alt="bixpand"
                  />
                ) : ''}
                {sideBarLogo ? (
                  <img
                    src={sideBarLogo}
                    className={classNames({
                      [classes.sidebarLogo]: !isPermanent
                        ? !isSidebarOpened
                        : isSidebarOpened,
                      [classes.sidebarLogoUnvisible]: !isPermanent
                        ? isSidebarOpened
                        : !isSidebarOpened,
                    })}
                    alt="bixpand"
                  />
                ) : ''}
              </Box>
            </Link>
          </center>
        </div> : ''}
      </List>
    </Drawer>
  );
}
export default withRouter(Sidebar);
