import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import './CustomReactPhoneInput.css';
import 'react-phone-input-2/lib/style.css';
import { useGlobalState } from '../../context/GlobalContext';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import { Entity } from '../../Api Mutation/EntityApi';
import useStyle from './style';
import { Typography } from '@material-ui/core';

export default function PhoneNoWithCountryCode({
  setPhoneNumber,
  phoneNumber,
  status,
}) {
  const classes = useStyle();
  const globalContext = useGlobalState();
  const locationId = globalContext.globalState.currentLocation.entityid;
  const entityId = localStorage.getItem('entityLocationId');
  const [defaultCountry, setDefaultCountry] = useState('');
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    let isMounted = true;
    if ((locationId || entityId) && isMounted) {
      postDataRequest(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: locationId || entityId,
        },
      }).then((country) => {
        if (country?.data?.entity?.defaultcountrycode) {
          setDefaultCountry(country?.data?.entity?.defaultcountrycode);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [entityId, locationId]);
  return (
    <div>
      <ReactPhoneInput
        specialLabel={false}
        isValid={(value, country) => {
          setDefaultValue(handleUndefined(value));
          if (value.length < 11 && value.length > 2 && country) {
            return false;
          }
          return true;
        }}
        country={defaultValue?.length > 4 ? '' : `${defaultCountry || 'us'}`}
        variant="outlined"
        disabled={status}
        value={phoneNumber}
        placeholder="Enter Phone Number"
        onChange={(phoneno) => setPhoneNumber(phoneno)}
      />
      <Typography variant="body2" className={classes.disclaimer}>Select the country code from dropdown</Typography>
    </div>
  );
}
